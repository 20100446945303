import React, { useEffect, useState } from "react";
import { Select, Slider } from "antd";
import debounce from "lodash/debounce";
import { CardHeader, CardBody } from "reactstrap";
import get from "lodash/get";
import callApi from "../../utils/callApi";
import "./styles.scss";

const { Option, OptGroup } = Select;

function TagSelector({ onTagsChange, tags, language, className }) {
  const fetchTag = async (page, name) => {
    let url = `v2/list-tag?type[]=OFFICIAL_TAG&type[]=SUGGESTED_TAG&page=${page}`;
    if (name) {
      url = `${url}&name=${name}`;
    }
    return callApi(url)
      .then(res => res)
      .catch(err => console.log(err));
  };
  const findTag = (tag, tags) => {
    return tags.find(e => e.name === tag.name);
  };
  useEffect(() => {
    fetchTag(1).then(res => {
      if (res.data.tags && res.data.tags.officialTag) {
        const { nextPageOfficial, nextPageSuggest } = res.data.tags;
        const { official, suggest } = filterTag(tags, res.data.tags);
        setStateTag({
          officialTag: [...official, ...res.data.tags.officialTag],
          suggestTag: [...suggest, ...res.data.tags.suggestTag],
          nextPageOfficial,
          nextPageSuggest,
          currentPage: 1
        });
      }
    });
  }, []);
  const handleSearchDebounce = debounce(async value => {
    const res = await fetchTag(1, value);
    if (res.data.tags && res.data.tags.officialTag) {
      const { nextPageOfficial, nextPageSuggest } = res.data.tags;
      const { official, suggest } = filterTag(tags, res.data.tags);
      let page = tagsSearch.currentPage;
      if (nextPageOfficial || nextPageSuggest) {
        page += 1;
      }
      setIsSearch(true);
      setTagsSearch({
        officialTag: [...official, ...res.data.tags.officialTag],
        suggestTag: [...suggest, ...res.data.tags.suggestTag],
        nextPageOfficial,
        nextPageSuggest,
        currentPage: page,
        name: value
      });
    }
  }, 500);
  const onSearchTag = async value => {
    if (value.length === 0) {
      return;
    }
    handleSearchDebounce(value);
  };
  function filterTag(arrTag, resTag) {
    const { officialTag = [], suggestTag = [] } = resTag;
    const official = arrTag.filter(e => {
      if (e.type === "OFFICIAL_TAG") {
        const tag = findTag(e, officialTag);
        return tag ? null : e;
      }
    });
    const suggest = arrTag.filter(e => {
      if (e.type === "SUGGESTED_TAG") {
        const tag = findTag(e, suggestTag);
        return tag ? null : e;
      }
    });
    return { official, suggest };
  }
  const onChageRangeOfPriority = (value, e) => {
    if (value) {
      const indexTag = tags.findIndex(e => e.name === value);
      if (indexTag > -1) {
        tags[indexTag].priority = e;
      }
      onTagsChange(tags);
    }
  };
  useEffect(() => {
    if (tags.length === 5) {
      return setOptionDisabled(true);
    }
  }, [tags]);
  const onSelectTagChange = value => {
    const lastItem = value[value.length - 1];
    const newTags = [];
    const filterTag = value.filter(e => {
      const node = tags.find(tag => tag.name === e);
      if (node) {
        newTags.push(node);
        return false;
      }
      return true;
    });

    const temp = filterTag.map(tag => {
      const { officialTag, suggestTag } = getTag();
      const indexTagOfficialTag = officialTag.findIndex(e => e.name === tag);
      if (indexTagOfficialTag > -1) {
        const { name, language, type, id } = officialTag[indexTagOfficialTag];
        return { name, language, type, id, priority: 1 };
      }
      const indexTagSuggestTag = suggestTag.findIndex(e => e.name === tag);
      if (indexTagSuggestTag > -1) {
        const { name, language, type, id } = suggestTag[indexTagSuggestTag];
        return { name, language, type, id, priority: 1 };
      }
      return {
        name: lastItem,
        type: "SUGGESTED_TAG",
        priority: 1,
        id: ""
      };
    });

    const arr = [...newTags, ...temp];
    arr.length === 5 ? setOptionDisabled(true) : setOptionDisabled(false);
    onTagsChange(arr);
    setIsSearch(false);
  };
  const onScrollSelector = async value => {
    const { target } = value;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      let { currentPage, nextPageOfficial, nextPageSuggest } = getTag();
      let page = currentPage;

      if (nextPageOfficial || nextPageSuggest) {
        let res, oldTag;
        console.log("setIsSearch", isSearch);
        if (isSearch) {
          res = await fetchTag(currentPage + 1, tagsSearch.name);
          oldTag = [...tagsSearch.officialTag, ...stateTags.suggestTag];
        } else {
          res = await fetchTag(currentPage + 1);
          oldTag = [...stateTags.officialTag, ...stateTags.suggestTag];
        }
        if (res.data.tags) {
          const {
            nextPageOfficial,
            nextPageSuggest,
            officialTag,
            suggestTag
          } = res.data.tags;
          if (nextPageOfficial || nextPageSuggest) {
            page = currentPage + 1;
          }
          const { official, suggest } = filterTag(oldTag, res.data.tags);
          const updateState = {
            currentPage: page,
            suggestTag: [...suggestTag, ...suggest],
            officialTag: [...officialTag, ...official],
            nextPageOfficial,
            nextPageSuggest
          };
          if (isSearch) {
            updateState.name = tagsSearch.name;
            setTagsSearch(updateState);
          } else {
            setStateTag(updateState);
          }
          target.scrollTo(0, target.scrollHeight - 500);
        }
      }
    }
  };
  function makeOptions(item) {
    return (
      <Option disabled={optionDisabled} key={item.name}>
        {item.name}
        {item.totalOfArticle ? <>({item.totalOfArticle})</> : <></>}
      </Option>
    );
  }
  function getTag() {
    return isSearch ? tagsSearch : stateTags;
  }

  const [tagsSearch, setTagsSearch] = useState({
    officialTag: [],
    suggestTag: [],
    name: "",
    currentPage: 1,
    nextPageOfficial: false,
    nextPageSuggest: false
  });

  const [stateTags, setStateTag] = useState({
    officialTag: [],
    suggestTag: [],
    currentPage: 1,
    nextPageOfficial: false,
    nextPageSuggest: false
  });

  const [optionDisabled, setOptionDisabled] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const { officialTag, suggestTag } = getTag();
  return (
    <div className={`card-plain ${className}`}>
      <CardHeader>
        <div className="d-flex">
          <h4 className="mb-0">Tags</h4>
          <span className="asterisk ml-1">*</span>
          (maximum: 5)
        </div>
      </CardHeader>
      <CardBody style={{ paddingTop: 0 }}>
        <Select
          onPopupScroll={value => onScrollSelector(value)}
          onSearch={value => onSearchTag(value)}
          mode={optionDisabled ? "multiple" : "tags"}
          size="large"
          value={tags && tags.map(e => e.name)}
          filterOption={false}
          style={{ width: "100%" }}
          placeholder="Select Tags"
          dropdownClassName="tag-dropdown"
          onChange={value => onSelectTagChange(value)}
        >
          <OptGroup label="OFFICIAL TAGS">
            {officialTag && officialTag.map(item => makeOptions(item))}
          </OptGroup>
          <OptGroup label="SUGGESTED TAGS">
            {suggestTag && suggestTag.map(item => makeOptions(item))}
          </OptGroup>
        </Select>
        <section className="pt-2">
          {tags &&
            tags.map(item => (
              <div
                className="d-flex justify-content-center align-items-center"
                key={get(item, "_id")}
              >
                <p className="pr-4" style={{ width: "150px" }}>
                  {item.name}
                </p>
                <Slider
                  defaultValue={item.priority}
                  value={item.priority}
                  onChange={e => onChageRangeOfPriority(item.name, e)}
                  className="w-100"
                  marks={{ 1: 1, 5: 5 }}
                  min={1}
                  max={5}
                />
              </div>
            ))}
        </section>
      </CardBody>
    </div>
  );
}

export default TagSelector;
