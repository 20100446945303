import React, { Component } from "react";
import {
  Container
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../components/Headers/CardsHeaderEditor";
import OutlineInProgressTable from "../../components/Tables/OutlineInProgressTable";
class OutlineInProgress extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Outline - In Progress"
          content="outlineInProgress"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <OutlineInProgressTable {...props} />
        </Container>
      </>
    );
  }
}
OutlineInProgress.propTypes = {
  content: PropTypes.string
};
export default OutlineInProgress;
