import * as Types from "../variables/actionTypes.jsx";
import uniqBy from "lodash/uniqBy";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import { DEFAULT_SCHEDULE_SETTING } from "../utils/constants";

var initialState = {
  featureArticleSchedule: {
    loading: false,
    setting: DEFAULT_SCHEDULE_SETTING,
  },
  modalInform: false,
  currentFeatureArticle: {},
  featureArticleGroup: {
    loading: false,
    data: [],
  },
  scheduledDates: [],
  data_search: {
    data: {
      docs: [],
    },
  },
  searchLoading: false,
  saveScheduleLoading: false,
};

const featureArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_LIST_FEATURE_ARTICLE:
      return {
        ...state,
        feature_articles: action.data,
      };
    case Types.GET_SEARCH_FEATURE_ARTICLE_START:
      return {
        ...state,
        searchLoading: true,
      };
    case Types.GET_SEARCH_FEATURE_ARTICLE: {
      return {
        ...state,
        data_search: action.data_search,
        searchLoading: false,
      };
    }

    case Types.GET_SEARCH_FEATURE_ARTICLE_FAIL:
      return {
        ...state,
        searchLoading: false,
      };
    case Types.RESET_SEARCH_FEATURE_ARTICLE:
      return {
        ...state,
        data_search: [],
      };
    // GET GROUP FEATURE ARTICLE GROUP
    case Types.GET_GROUP_FEATURE_ARTICLE_SECTION:
      return {
        ...state,
        featureArticleGroup: {
          loading: true,
          data: [],
        },
      };
    case Types.GET_GROUP_FEATURE_ARTICLE_SECTION_SUCCESS:
      return {
        ...state,
        featureArticleGroup: {
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_GROUP_FEATURE_ARTICLE_SECTION_FAIL:
      return {
        ...state,
        featureArticleGroup: {
          loading: true,
          data: [],
        },
      };
    // Add feature article
    case Types.ADD_FEATURE_ARTICLE_TEMP: {
      return {
        ...state,
        featureArticleSchedule: {
          ...state.featureArticleSchedule,
          setting: state.featureArticleSchedule.setting.map((item) => {
            if (action.key === item.key) {
              item.articles = [...item.articles, action.data];
            }
            return item;
          }),
        },
      };
    }
    // Delete article
    case Types.DELETE_FEATURE_ARTICLE_TEMP: {
      return {
        ...state,
        featureArticleSchedule: {
          ...state.featureArticleSchedule,
          setting: state.featureArticleSchedule.setting.map((item) => {
            if (action.data.group === item.key) {
              if (action.data.group === "cover-story") {
                const array = item.articles.filter(
                  (article) => article.articleId !== action.data.articleId
                );
                item.articles = [...array];
                return item;
              }
              const array = item.articles.filter(
                (article) => article.scheduleTime !== action.data.scheduleTime
              );
              item.articles = [...array];
            }
            return item;
          }),
        },
      };
    }
    case Types.EDIT_FEATURE_ARTICLE_TEMP: {
      return {
        ...state,
        featureArticleSchedule: {
          ...state.featureArticleSchedule,
          setting: state.featureArticleSchedule.setting.map((item) => {
            if (action.data.group === item.key) {
              const index = findIndex(
                item.articles,
                (el) => el.articleId === state.currentFeatureArticle.articleId
              );
              item.articles[index] = action.data;
            }
            return item;
          }),
        },
      };
    }
    case Types.SET_CURRENT_FEATURE_ARTICLE_FOR_EDIT: {
      const dataSearch = get(state, "data_search.docs", []);
      const articleToBeAdded = {
        _id: action.data.articleId,
        angle_title: action.data.angle_title,
        status_article: "",
      };
      const updatedArraySearch = uniqBy(
        [...dataSearch, articleToBeAdded],
        "_id"
      );
      return {
        ...state,
        currentFeatureArticle: action.data,
        data_search: {
          docs: updatedArraySearch,
        },
      };
    }
    case Types.RESET_CURRENT_FEATURE_ARTICLE:
      return {
        ...state,
        currentFeatureArticle: {},
      };
    // Get schedule by date
    case Types.GET_SCHEDULE_BY_DATE:
      return {
        ...state,
        featureArticleSchedule: {
          ...state.featureArticleSchedule,
          loading: true,
        },
      };
    case Types.GET_SCHEDULE_BY_DATE_SUCCESS: {
      return {
        ...state,
        featureArticleSchedule: {
          ...state.featureArticleSchedule,
          ...action.data,
          currentSelectedDate: action.date,
          loading: false,
        },
      };
    }
    case Types.GET_SCHEDULE_BY_DATE_FAIL:
      return {
        ...state,
        featureArticleSchedule: {
          ...state.featureArticleSchedule,
          loading: false,
        },
      };
    case Types.GET_SCHEDULED_DATES_SUCCESS:
      return {
        ...state,
        scheduledDates: action.data,
      };

    case Types.SAVE_SCHEDULE:
      return {
        ...state,
        saveScheduleLoading: true,
      };
    case Types.SAVE_SCHEDULE_SUCCESS:
      return {
        ...state,
        saveScheduleLoading: false,
      };
    case Types.SAVE_SCHEDULE_FAIL:
      return {
        ...state,
        saveScheduleLoading: false,
      };
    case Types.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.date,
      };
    case Types.RESET_SCHEDULE:
      return {
        ...state,
        featureArticleSchedule: {
          ...state.featureArticleSchedule,
          loading: false,
          updatedAt: "",
          modifyBy: {},
          setting: [
            {
              key: "cover-story",
              articles: [],
            },
            {
              key: "featured-news-series-1",
              articles: [],
            },
            {
              key: "featured-news-series-2",
              articles: [],
            },
            {
              key: "listicle-1",
              articles: [],
            },
            {
              key: "listicle-2",
              articles: [],
            },
            {
              key: "listicle-3",
              articles: [],
            },
            {
              key: "listicle-4",
              articles: [],
            },
            {
              key: "listicle-5",
              articles: [],
            },
            {
              key: "listicle-6",
              articles: [],
            },
          ],
        },
      };
    case Types.SHOW_INFORM_MODAL:
      return {
        ...state,
        modalInform: true,
      };
    case Types.CLOSE_INFORM_MODAL:
      return {
        ...state,
        modalInform: false,
      };

    default:
      return { ...state };
  }
};

export default featureArticleReducer;
