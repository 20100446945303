import HOST_URL from "../variables/hostUrl";

export default function renderDropzoneThumbnail(key, url) {
  const dz = document.getElementsByClassName("dropzone")[key];
  const dz_preview = document.getElementsByClassName("dz-preview-single")[key];
  const img = document.createElement("img");
  dz.className =
    "dropzone dropzone-single mb-3 dz-clickable dz-max-files-reached";
  img.src = HOST_URL + url;
  img.className = "dz-preview-img";
  img.id = `dz-preview-img-${key}`;
  if (dz_preview.childNodes[0]) {
    dz_preview.replaceChild(img, dz_preview.childNodes[0]);
  } else {
    dz_preview.appendChild(img);
  }
}
