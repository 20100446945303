import React from "react";
import { get } from "lodash";
import { Upload, message, Typography, Button, Icon } from "antd";
import callApi from "../../utils/callApi";
import HOST_URL from "../../variables/hostUrl";
import "./styles.scss";

const { Text } = Typography;

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt500kb = file.size / 1024 / 1024 < 0.5;
  if (!isLt500kb) {
    message.error("Image must smaller than 500kb!");
  }
  return isJpgOrPng && isLt500kb;
}

class ImageUpload extends React.Component {
  state = {
    loading: false,
    imageUrl: ""
  };

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({
        loading: false
      });
    }
  };

  handleChangeImageUrl = url => {
    this.props.onChangeImageUrl(url);
  };
  uploadToServer = async ({ file, onSuccess }) => {
    let formData = new FormData();
    formData.append("file", file);
    const uploadImage = callApi("images/upload", "POST", formData, true);
    const result = await uploadImage;
    this.props.callback(result.data._id);
    const imagePath = get(result.data, "size.ONE_ONE_368x368.url");
    const imageUrlFromServer = `${HOST_URL}${imagePath}`;
    this.setState(
      {
        imageUrl: imageUrlFromServer
      },
      () => {
        this.handleChangeImageUrl(imageUrlFromServer);
      }
    );
    onSuccess(file);
  };

  removeImage = () => {
    this.setState(
      {
        imageUrl: ""
      },
      () => {
        this.props.onChangeImageUrl("");
        this.props.callback("");
      }
    );
  };

  render() {
    const { maxSize, imageUrl, hideRemove } = this.props;
    const uploadButton = (
      <div>
        {this.state.loading ? (
          <Icon type="loading" />
        ) : (
          <Icon
            type="cloud-upload"
            style={{ fontSize: "25px", marginBottom: "8px" }}
          />
        )}
        <div className="ant-upload-text">
          Click here or drop image to upload <br />
          (Ratio 3:2 recommended)
        </div>
      </div>
    );
    return (
      <>
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={this.uploadToServer}
          beforeUpload={beforeUpload}
          onChange={this.handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
        <div className="d-block">
          {imageUrl && !hideRemove ? (
            <Button
              className="w-100 mb-4 remove-btn"
              onClick={this.removeImage}
              type="danger"
            >
              Remove image
            </Button>
          ) : null}
          {maxSize && (
            <Text type="secondary">{`Maximum size of image is ${maxSize}`}</Text>
          )}
        </div>
      </>
    );
  }
}

export default ImageUpload;
