import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import ChiefWriterProfileTable from "../../../components/Tables/ChiefWriterProfileTable";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
class ChiefWriterProfile extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Writer Profile"
          content="writerProfile"
          parentName="Staff"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <ChiefWriterProfileTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefWriterProfile.propTypes = {
  content: PropTypes.string,
};
export default ChiefWriterProfile;
