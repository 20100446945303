import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorWriterProfileTable from "../../../components/Tables/EditorWriterProfileTable";
class EditorWriterProfile extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Writer Profile"
          content="writerProfile"
          parentName="Staff"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <EditorWriterProfileTable {...props} />
        </Container>
      </>
    );
  }
}
EditorWriterProfile.propTypes = {
  content: PropTypes.string,
};
export default EditorWriterProfile;
