import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorEditingWritingDetail from "../../../components/Detail/EditorEditingWritingDetail";
class EditorEditingWriting extends Component {
  state = {
    pathname: window.location.pathname.split("/")[3],
  };
  render() {
    const props = this.props;
    const { pathname } = this.state;
    return (
      <>
        {pathname === "writer-writing" ? (
          <CardsHeaderEditor
            name="Editor Editing"
            content="editorEditing"
            parentName="Article"
          />
        ) : (
          <CardsHeaderEditor
            name="Published Article Detail"
            content="publishedArticleDetail"
            parentName="Article"
          />
        )}

        <Container className="mt--7" fluid>
          <EditorEditingWritingDetail {...props} />
        </Container>
      </>
    );
  }
}
EditorEditingWriting.propTypes = {
  content: PropTypes.string,
};
export default EditorEditingWriting;
