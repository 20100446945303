import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  mypermission: [],
  count_info: {
    data: []
  },
  count_chiefeditor: {
    data: []
  },
  count_editor: {
    data: []
  }
};

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_MY_PERMISSION:
      state = {
        ...state,
        mypermission: action.data
      };
      return { ...state };
    case Types.GET_COUNT_INFO:
      state = {
        ...state,
        count_info: action.data
      };
      return { ...state };
    case Types.GET_COUNT_INFO_CHIEF:
      state = {
        ...state,
        count_chiefeditor: action.data
      };
      return { ...state };
    case Types.GET_COUNT_INFO_EDITOR:
      state = {
        ...state,
        count_editor: action.data
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default permissionReducer;
