import React, { Component } from "react";
import { get } from "lodash";
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  actSubmitOutlineRequest,
  actSaveDraftOutlineRequest,
  actAutoSaveOutlineRequest,
} from "../../actions/writerArticle.action";
import { actGetFeedbackListRequest } from "../../actions/feedback.action";
import Feedback from "../Feedback/Feedback";
import CardContentBrief from "../Cards/CardContentBrief";
import { actGetOutlineDetailIDRequest } from "../../actions/editorArticle.action";
import VersionSelect from "../Selects/VersionSelect";
import ReactHtmlParser from "react-html-parser";
import CustomEditor from "../TinyMCE";

class WriterOutLineContentTable extends Component {
  state = {
    tabs: 1,
    id: window.location.pathname.split("/")[4],
    data: {
      content: "",
    },
    isWritting: false,
    autoSave: false,
  };

  componentDidMount() {
    this._setLoading();
    this._getOutlineDetailByID(this.state.id);
    this._autoSave();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.outline_detail !== this.props.outline_detail) {
      this.setState({
        data: { content: nextProps.outline_detail.data.outline_content },
        isWritting:
          nextProps.outline_detail.data.status_article === "OUTLINE_WRITING",
      });
      this.props.getFeedbackList(nextProps.outline_detail.data.outlineID);
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
    this.setState({ autoSave: false });
  }

  _getOutlineDetailByID = (id) => {
    this.props.getOutlineDetailByID(id);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  _handleOnSubmitOutline = () => {
    const id = this.props.outline_detail.data.id;
    const data = this.state.data;
    this.props.submitOutline(id, data);
  };

  _handleOnContentChange = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        content: value,
      },
      autoSave: true,
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _handleOnSaveDraftOutline = (e) => {
    e.preventDefault();
    const outlineID = this.props.outline_detail.data.outlineID;
    const data = {
      content: this.state.data.content,
      outlineID: outlineID,
    };
    this.props.Savedraft(data);
  };

  _autoSave = () => {
    this.autoSave = setInterval(() => {
      if (this.state.autoSave) {
        const outline_detail = this.props.outline_detail.data;
        const data = {
          outlineID: outline_detail.outlineID,
          title: outline_detail.angle_title,
          content: this.state.data.content,
        };
        this.setState({ autoSave: false });
        this.props.autoSave(data);
      }
    }, 3000);
  };

  _handleOnVersionSelect = (index) => {
    const url = `/admin/writer/outline-version/${this.state.id}?version=${index}`;
    window.open(url, "_blank");
  };

  handleContentChange = (content) => {
    this.setState({
      data: {
        ...this.state.data,
        content: content,
      },
      autoSave: true,
    });
  };

  render() {
    const { outline_detail, outline_versions } = this.props;
    const isWritting = this.state.isWritting;
    return (
      <>
        <Row>
          <Col xl="9">
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 1,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                    role="tab"
                  >
                    1. Content Brief
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 2,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                    role="tab"
                  >
                    2. Write Outline
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={"tabs" + this.state.tabs}>
              <TabPane tabId="tabs1">
                <CardContentBrief
                  outline_detail={outline_detail}
                  toggleNavs={this.toggleNavs}
                />
              </TabPane>
              <TabPane tabId="tabs2">
                <Card>
                  <CardHeader className="d-flex justify-content-between">
                    <div>
                      <h2>
                        {outline_detail.data &&
                          ReactHtmlParser(outline_detail.data.angle_title)}
                      </h2>
                      {outline_detail.data.status_article &&
                        outline_detail.data.status_article.includes(
                          "CONTENT"
                        ) && (
                          <Link
                            to={`/admin/writer/writer-writing/${this.state.id}`}
                          >
                            <h5 className="text-primary">
                              {"Back to content >>"}
                            </h5>
                          </Link>
                        )}
                    </div>
                    <VersionSelect
                      versions={outline_versions}
                      onVersionSelect={this._handleOnVersionSelect}
                    />
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Row>
                        <Col lg="10">
                          <label
                            className="form-control-label display-inline pb-2"
                            htmlFor="lastname"
                          >
                            Write Outline
                          </label>
                        </Col>
                        <Col lg="2" className="text-right">
                          {this.props.outline_detail.data.status_article ===
                          "OUTLINE_SUBMITTED" ? (
                            ""
                          ) : (
                            <a
                              href="#pablo"
                              style={{ fontWeight: "bold" }}
                              onClick={this._handleOnSaveDraftOutline}
                            >
                              <p>Save draft</p>
                            </a>
                          )}
                        </Col>
                      </Row>
                      <Form>
                        <CustomEditor
                          content={this.state.data.content}
                          onChangeContent={this.handleContentChange}
                          // readOnly={!isWritting}
                        />
                      </Form>
                    </FormGroup>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
          <Col xl="3" className="toolbar-right">
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                className="btn-lg"
                color="success"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
                disabled={!isWritting}
                onClick={this._handleOnSubmitOutline}
              >
                <i className="ni ni-send mr-2"></i>
                Submit Outline
              </Button>
            </div>
            <Feedback
              outline_id={get(outline_detail, "data.outlineID")}
              article_id={this.state.id}
            />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    outline_detail: state.editorArticleReducer.outline_detail_id,
    outline_versions: state.writerArticleReducer.outline_versions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOutlineDetailByID: (id) => {
      dispatch(actGetOutlineDetailIDRequest(id));
    },
    submitOutline: (id, data) => {
      dispatch(actSubmitOutlineRequest(id, data));
    },
    Savedraft: (data) => {
      dispatch(actSaveDraftOutlineRequest(data));
    },
    autoSave: (data) => {
      dispatch(actAutoSaveOutlineRequest(data));
    },
    getFeedbackList: (outlineId) => {
      dispatch(actGetFeedbackListRequest(outlineId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriterOutLineContentTable);
WriterOutLineContentTable.propTypes = {
  outline_detail: PropTypes.object,
  outline_versions: PropTypes.array,
  getOutlineDetailByID: PropTypes.func,
  submitOutline: PropTypes.func,
  Savedraft: PropTypes.func,
  autoSave: PropTypes.func,
  getFeedbackList: PropTypes.func,
};
WriterOutLineContentTable.defaultProps = {};
