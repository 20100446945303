import React, { useState } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Modal, Button, Input } from "antd";
import "./styles.scss";

function ReEvaluateModal({
  title,
  visible,
  loading,
  handleOk,
  handleCancel,
  handleOnChange
}) {
  const [reason, setReason] = useState("");
  const { TextArea } = Input;

  // functions
  function handleChange(e) {
    setReason(e.target.value);
    handleOnChange(e.target.value);
  }

  return (
    <Modal
      className={`revaluate-modal`}
      visible={visible}
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          className="modal-cancel-btn"
          type="ghost"
          key="cancel"
          onClick={handleCancel}
        >
          No
        </Button>,
        <Button
          disabled={isEmpty(reason)}
          className="modal-submit-btn"
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Yes
        </Button>
      ]}
    >
      <TextArea value={reason} onChange={handleChange} rows={6} />
    </Modal>
  );
}
ReEvaluateModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  hideBody: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOnChange: PropTypes.func
};
export default ReEvaluateModal;
