export function makeUrl(key, value) {
  let temp = "";
  for (let index = 0; index < value.length; index++) {
    temp = temp.concat(`&${key}[]=${value[index]}`);
  }
  return temp;
}
export function makeUrlObj(obj) {
  let temp = "";
  let listProps = Object.keys(obj);
  for (let index = 0; index < listProps.length; index++) {
    let key = listProps[index];
    if (index === 0) {
      temp = temp.concat(`${key}=${obj[key]}`);
    }else {
      temp = temp.concat(`&${key}=${obj[key]}`);
    }
  }
  return temp;
}