import React, { Component } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  actRemoveMenuRequest,
  actRemoveMenu
} from "../../actions/topic.action";

class ChiefRemoveMenuModal extends Component {
  state = {};

  toggle = () => {
    this.props.toggle();
  };

  _handleOnRemoveMenu = () => {
    const id = this.props.id;
    const { languagueKey } = this.props;
    if (languagueKey) {
      let params = {
        language: "VN"
      };
      this.props.removeMenu(id, params);
    } else {
      let params = {
        language: "EN"
      };
      this.props.removeMenu(id, params);
    }
    this.toggle();
  };

  render() {
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.props.toggle}>Remove menu</ModalHeader>
          <ModalBody className="p-4">
            Are you sure want to remove this menu?
          </ModalBody>
          <ModalFooter className="d-flex justify-content-flex-end p-3">
            <Button
              onClick={this._handleOnRemoveMenu}
              color="link"
              style={{ color: "#172B4D" }}
            >
              YES
            </Button>
            <Button
              color="link"
              style={{ color: "#fb6340" }}
              onClick={this.toggle}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeMenu: (id, params) => {
      dispatch(actRemoveMenuRequest(id, params));
    },
    removeMenuReduce: index => {
      dispatch(actRemoveMenu(index));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ChiefRemoveMenuModal);

ChiefRemoveMenuModal.propTypes = {
  id: PropTypes.string,
  removeMenu: PropTypes.func,
  removeMenuReduce: PropTypes.func,
  toggle: PropTypes.func,
  modal: PropTypes.func,
  languagueKey: PropTypes.bool
};

ChiefRemoveMenuModal.defaultProps = {};
