import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import WriterArticleInProgressTable from "../../../components/Tables/WriterArticleInProgressTable";
class WriterArticleInProgress extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeader
          name="Article - In Progress"
          content="articleinprogress"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <WriterArticleInProgressTable {...props} />
        </Container>
      </>
    );
  }
}
WriterArticleInProgress.propTypes = {
  content: PropTypes.string,
};
export default WriterArticleInProgress;
