import React, { useState, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Dropdown, Menu, Tooltip, Icon } from "antd";
import { ClipLoader } from "react-spinners";
import NO_DATA from "assets/img/no-data.svg";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import {
  Card,
  Input,
  Table,
  CardFooter,
  InputGroupText,
  InputGroupAddon,
  InputGroup
} from "reactstrap";
import CustomPagination from "../Pagination/Pagination";
import ChiefAddCollectionModal from "../Modals/ChiefAddCollectionModal";
import ChiefAddArticleToCollectionModal from "../Modals/ChiefAddArticleToCollectionModal";
import ChiefRemoveModal from "../Modals/ChiefRemoveModal";
import ChiefMoveToCollectionModal from "../Modals/ChiefMoveToCollectionModal";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  actGetChiefCollectionsRequest,
  actGetChiefDetailCollectionRequest,
  actUpdateCollectionRequest,
  actRemoveArticlesInColletionDetailRequest,
  actSearchChiefDetailCollectionRequest,
  actMoveToCollectionRequest,
  actGetChiefDetailCollection,
  actSearchArticles
} from "../../actions/chief-collection.action";
import ReactHtmlParser from "react-html-parser";

export default function ChiefCollectionTable(props) {
  const dispatch = useDispatch();
  const collections = useSelector(
    state => state.ChiefCollectionReducer.collections
  );

  const collection_detail = useSelector(
    state => state.ChiefCollectionReducer.collection_detail
  );

  const [state, setState] = useState({
    isShowAddModal: false,
    isUpdate: false,
    isShowArticleModale: false,
    isLoading: false,
    isShowMoveToCollection: false,
    isRomoveModal: false,
    isShowMoveToCollectionModal: false
  });

  const [isLanguage, setIsLanguage] = useState(true);

  const [params, setParams] = useState({
    slug: getUrlParam()["slug"] ? getUrlParam()["slug"] : "",
    page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
    limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
    number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
  });

  const [valueSearch, setValueSearch] = useState("");
  const [articleId, setArticleId] = useState("");
  const [selectAll, useSelectAll] = useState(false);

  const [selectedId, setSelectedId] = useState([]);
  const [moveCollection, setMoveCollection] = useState([]);
  const [newCollectionId, setNewCollectionId] = useState("");
  const [collectionArticleId, setCollectionArticleId] = useState("");

  const lang = isLanguage ? "VN" : "EN";

  useEffect(() => {
    handleLoading();
    handleGetCollectionList();
    if (params.slug) {
      let { slug, page, limit, number } = params;
      let data = {
        slug: slug,
        page: page,
        limit: limit,
        number: number,
        language: lang
      };
      dispatch(actGetChiefDetailCollectionRequest(data));
    }
    return () => {
      dispatch(actGetChiefDetailCollection({ docs: [] }));
      dispatch(actSearchArticles({}));
    };
  }, []);

  const handleMoveToCollection = () => {
    let _params = {
      slug: params.slug,
      page: params.page,
      limit: params.limit,
      number: params.number,
      language: lang
    };
    if (collectionArticleId) {
      let data = {
        idOldCol: collection_detail._id,
        list_of_article: [collectionArticleId]
      };
      dispatch(actMoveToCollectionRequest(data, _params, newCollectionId));
    } else {
      let data = {
        idOldCol: collection_detail._id,
        list_of_article: selectedId
      };
      dispatch(actMoveToCollectionRequest(data, _params, newCollectionId));
      useSelectAll(false);
      handleHideMoveToCollection();
    }
    hanleToggleMoveToCollectionModal();
  };

  const handleAddArticleToCollection = selectedId => {
    handleToggleModalArticle();
    let data = {
      list_of_article: selectedId
    };
    let _params = {
      slug: params.slug,
      page: params.page,
      limit: params.limit,
      number: params.number,
      language: lang
    };
    dispatch(actMoveToCollectionRequest(data, _params, collection_detail._id));
  };

  const hanleToggleMoveToCollectionModal = (status, id) => {
    if (status) {
      setCollectionArticleId(id);
    } else {
      setCollectionArticleId("");
    }
    let data = collections.map(item => {
      let child = {
        id: item._id,
        text: item.title
      };
      return child;
    });
    setMoveCollection(data);
    setState({
      ...state,
      isShowMoveToCollectionModal: !state.isShowMoveToCollectionModal
    });
  };

  const handleToggleRemoveModal = id => {
    setArticleId(id);
    setState({
      ...state,
      isRomoveModal: !state.isRomoveModal
    });
  };

  const handleToggleAddCollectionUpdate = () => {
    dispatch(actSearchArticles({}));
    setState({
      ...state,
      isShowAddModal: !state.isShowAddModal,
      isUpdate: !state.isUpdate
    });
  };

  const handleToggleModalAddCollection = () => {
    dispatch(actSearchArticles({}));
    setState({
      ...state,
      isShowAddModal: !state.isShowAddModal
    });
  };

  const handleToggleModalArticle = () => {
    dispatch(actSearchArticles({}));
    setState({ ...state, isShowArticleModale: !state.isShowArticleModale });
  };

  const handleGetCollectionList = () => {
    let data = {
      slug: params.slug,
      page: params.page,
      limit: params.limit,
      number: params.number,
      language: lang
    };
    dispatch(actGetChiefCollectionsRequest(data));
  };

  const handleSelected = arr => {
    handleShowMoveToCollection();
    if (selectedId.includes(arr._id)) {
      setSelectedId(selectedId.filter(prop => prop !== arr._id));
    } else {
      setSelectedId([...selectedId, arr._id]);
    }
  };

  const handleSelectedAll = () => {
    let data = collection_detail.docs.map(item => {
      return item._id;
    });
    if (!selectAll) {
      useSelectAll(true);
      const newSelectedId = selectedId.filter(prop => !data.includes(prop));
      setSelectedId(newSelectedId.concat(data));
    } else {
      useSelectAll(false);
      setSelectedId(selectedId.filter(prop => !data.includes(prop)));
    }
  };

  const handleShowMoveToCollection = () => {
    setState({
      ...state,
      isShowMoveToCollection: true
    });
  };

  const handleHideMoveToCollection = () => {
    setSelectedId([]);
    setState({
      ...state,
      isShowMoveToCollection: false
    });
  };

  const onGroupTopicSelect = item => {
    let data = {
      slug: item._id,
      page: 1,
      limit: params.limit,
      number: params.number,
      language: lang
    };
    setParams({
      ...params,
      slug: item._id
    });
    setState({
      ...state,
      isShowMoveToCollection: false
    });
    setSelectedId([]);
    useSelectAll(false);
    setValueSearch("");
    handleLoading();
    handlePathnamePush(data);
    dispatch(actGetChiefDetailCollectionRequest(data));
  };

  const handleLoading = () => {
    setState({ ...state, isLoading: true });
    setTimeout(() => {
      setState({ ...state, isLoading: false });
    }, 1000);
  };

  const handleChangeSelectMoveToCollection = e => {
    setNewCollectionId(e.target.value);
  };

  const handleChangelanguage = e => {
    setIsLanguage(e.target.checked);
    let data = {
      number: params.number,
      language: e.target.checked ? "VN" : "EN"
    };
    setParams({
      slug: "",
      page: 1,
      limit: 10,
      number: -1
    });
    dispatch(actGetChiefCollectionsRequest(data));
    dispatch(actGetChiefDetailCollection({ docs: [] }));
    props.history.push("/admin/chief/manage-collection");
  };

  const handleSearchArticlesInCollection = e => {
    setValueSearch(e.target.value);
    let _data = {
      search: e.target.value,
      slug: params.slug,
      page: params.page,
      limit: params.limit,
      number: params.number,
      language: lang
    };
    dispatch(actSearchChiefDetailCollectionRequest(_data));
  };

  const handlePageClick = page => {
    let data = {
      slug: params.slug,
      page: page,
      limit: params.limit,
      number: params.number,
      language: lang
    };
    useSelectAll(false);
    setParams({ ...params, page: page });
    handleLoading();
    handlePathnamePush(data);
    dispatch(actGetChiefDetailCollectionRequest(data));
  };

  const handlePathnamePush = params => {
    props.history.push(paramFromData(params));
  };

  const handleRemoveArticle = () => {
    let _params = {
      slug: params.slug,
      page: params.page,
      limit: params.limit,
      number: params.number,
      language: lang
    };

    if (articleId) {
      let data = {
        list_of_article: [articleId]
      };
      dispatch(
        actRemoveArticlesInColletionDetailRequest(
          data,
          _params,
          collection_detail._id
        )
      );
    } else {
      let data = {
        list_of_article: selectedId
      };
      setSelectedId([]);
      handleToggleRemoveModal();
      dispatch(
        actRemoveArticlesInColletionDetailRequest(
          data,
          _params,
          collection_detail._id
        )
      );
    }
  };

  const limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 50) {
      return `${str[0].substring(0, 50)}...`;
    } else {
      return str[0].substring(0, 50);
    }
  };

  const parentActions = () => {
    return (
      <Menu>
        <Menu.Item onClick={handleToggleModalArticle}>Add articles</Menu.Item>
        <Menu.Item onClick={handleToggleAddCollectionUpdate}>Setting</Menu.Item>
      </Menu>
    );
  };

  const childrenActions = prop => (
    <Menu>
      <Menu.Item
        onClick={() => hanleToggleMoveToCollectionModal(true, prop._id)}
      >
        Move collection
      </Menu.Item>
      <Menu.Item onClick={() => handleToggleRemoveModal(prop._id)}>
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {state.isShowAddModal && (
        <ChiefAddCollectionModal
          params={params}
          isUpdate={state.isUpdate}
          collection_detail={collection_detail}
          isLanguage={isLanguage}
          isShowModal={state.isShowAddModal}
          toggleUpdate={handleToggleAddCollectionUpdate}
          toggle={handleToggleModalAddCollection}
        />
      )}
      {state.isShowArticleModale && (
        <ChiefAddArticleToCollectionModal
          handleAddArticleToCollection={handleAddArticleToCollection}
          isShowModal={state.isShowArticleModale}
          toggle={handleToggleModalArticle}
          isLanguage={isLanguage}
          collection_detail={collection_detail}
        />
      )}
      {state.isRomoveModal && (
        <ChiefRemoveModal
          modal={state.isRomoveModal}
          title="Remove"
          type="collection"
          description="Are you sure want to remove this articles?"
          toggle={handleToggleRemoveModal}
          handleRemoveArticle={handleRemoveArticle}
        />
      )}

      {state.isShowMoveToCollectionModal && (
        <ChiefMoveToCollectionModal
          modal={state.isShowMoveToCollectionModal}
          toggle={hanleToggleMoveToCollectionModal}
          handleMoveToCollection={handleMoveToCollection}
          dataSelect={moveCollection}
          handleChangeSelectMoveToCollection={
            handleChangeSelectMoveToCollection
          }
          newCollectionId={newCollectionId}
        />
      )}

      <Card className="parent-select-list">
        <div className="parent-list">
          <ul className="parent-list-ul">
            {collections.map((prop, index) => {
              return (
                <li
                  className={classnames({
                    published: prop.status === "PUBLISHED",
                    active: collection_detail._id === prop._id
                  })}
                  key={index}
                >
                  <div
                    className="published-title"
                    onClick={() => onGroupTopicSelect(prop)}
                  >
                    {prop.title.length > 50 ? (
                      <Tooltip
                        title={ReactHtmlParser(prop.title)}
                        placement="bottom"
                      >
                        <span className="title">
                          {limitTitle(ReactHtmlParser(prop.title))}
                        </span>
                      </Tooltip>
                    ) : (
                      <span className="title">
                        {ReactHtmlParser(prop.title)}
                      </span>
                    )}
                    <span className="number">({prop.numArticle})</span>
                  </div>
                  {collection_detail._id === prop._id && (
                    <div className="more">
                      <Dropdown
                        overlay={() => parentActions(prop._id)}
                        placement="bottomRight"
                      >
                        <Icon type="more" />
                      </Dropdown>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="btn-add" onClick={handleToggleModalAddCollection}>
            <Icon type="more" />
            Add New Collection
          </div>
        </div>

        <div className="children-list">
          <div className="collection-table-header d-flex justify-content-between align-items-center">
            <h3>{collection_detail.title}</h3>
            <div className="collection-table-language d-flex align-items-center mr-3">
              <span className="mr-2">Show articles: </span>
              <label className="custom-toggle custom-toggle-warning mr-1">
                <input
                  name="isLanguage"
                  type="checkbox"
                  value={isLanguage}
                  checked={isLanguage}
                  onChange={handleChangelanguage}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="EN"
                  data-label-on="VN"
                />
              </label>
            </div>
          </div>
          {collection_detail._id ? (
            selectedId.length === 0 || !state.isShowMoveToCollection ? (
              <div className="collection-table-body d-flex justify-content-flex-start align-items-center">
                <div className="collection-table-search">
                  <InputGroup className="input-group-flush">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <span className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search articles"
                      autoComplete="off"
                      type="search"
                      name="valueSearch"
                      value={valueSearch}
                      onChange={handleSearchArticlesInCollection}
                    />
                  </InputGroup>
                </div>
                <div className="collection-table-add">
                  <button
                    type="button"
                    className="btn-icon btn btn-sm btn-primary"
                    onClick={handleToggleModalArticle}
                  >
                    <span className="mr-1">
                      <i className="fa fa-plus-circle"></i>
                    </span>

                    <span className="btn-inner--text">Add Articles</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="collection-table-body-move d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#5E72E4" }}
                >
                  <div
                    className="mr-5 collection-cursor"
                    onClick={() => hanleToggleMoveToCollectionModal(false, "")}
                  >
                    <i className="fas fa-arrow-up mr-1" /> Move to collection
                  </div>
                  <div
                    className="collection-cursor"
                    onClick={() => handleToggleRemoveModal("")}
                  >
                    <i className="fa fa-trash-alt mr-1" /> Remove Selected
                  </div>
                </div>
                <div
                  className="collection-cursor"
                  onClick={handleHideMoveToCollection}
                >
                  Cancel
                </div>
              </div>
            )
          ) : null}

          {state.isLoading ? (
            <div
              className={`children-list-content p-9 text-center`}
              style={{ height: "62vh" }}
            >
              <ClipLoader loading={true} size={50} color={"#3C74F0"} />
            </div>
          ) : !params.slug ? (
            <div
              className={`children-list-content no-data p-9 text-center`}
              style={{ height: "62vh" }}
            >
              <img src={NO_DATA} className="mb-2" alt="" /> <br />
              No data
            </div>
          ) : (
            <div
              className="children-list-content"
              style={{ height: "62vh", overflowX: "scroll" }}
            >
              <div>
                <Table
                  className="align-items-center table-flush"
                  hover
                  style={{ border: "0px" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th>
                        <div className="custom-control custom-checkbox">
                          <input
                            className={classnames("custom-control-input", {
                              indeterminate: false
                            })}
                            id="table-check-all-collection-detail"
                            type="checkbox"
                            value="all"
                            checked={selectAll}
                            onChange={handleSelectedAll}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="table-check-all-collection-detail"
                          />
                        </div>
                      </th>
                      <th
                        className="sort"
                        data-sort="name"
                        scope="col"
                        // onClick={() => this._sortByColumn("name")}
                      >
                        Article name
                      </th>
                      <th
                        className="sort"
                        data-sort="name"
                        scope="col"
                        // onClick={() => this._sortByColumn("name")}
                      >
                        Status
                      </th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {collection_detail.docs.map((prop, key) => {
                      return (
                        <tr key={key}>
                          <th>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id={`table-check${prop._id}`}
                                type="checkbox"
                                checked={selectedId.includes(prop._id)}
                                onChange={() => handleSelected(prop)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`table-check${prop._id}`}
                              />
                            </div>
                          </th>
                          <th>
                            <Tooltip
                              title={ReactHtmlParser(prop.title)}
                              placement="bottom"
                            >
                              <span>
                                {limitTitle(ReactHtmlParser(prop.title))}{" "}
                              </span>
                            </Tooltip>
                          </th>
                          <th scope="row">
                            {prop.status_article === "PUBLISHED" ? (
                              <>
                                <span className="status-completed">●</span>
                                <span>Published</span>
                              </>
                            ) : (
                              <>
                                <span className="status-cancel">●</span>
                                <span>Unpublished</span>
                              </>
                            )}
                          </th>
                          <th scope="row" className="text-right">
                            <Dropdown
                              overlay={() => childrenActions(prop)}
                              placement="bottomRight"
                            >
                              <Icon type="more" />
                            </Dropdown>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          <CardFooter className="py-4 ">
            <div className="float-right mb-2">
              <CustomPagination
                page={collection_detail.page}
                totalPages={collection_detail.totalPages}
                getListByPage={handlePageClick}
              />
            </div>
          </CardFooter>
        </div>
      </Card>
    </div>
  );
}

ChiefCollectionTable.propTypes = {
  getCollectionList: PropTypes.func,
  getDetailCollection: PropTypes.func,
  collection_detail: PropTypes.any,
  collections: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};
