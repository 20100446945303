import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderChief from "../../components/Headers/CardsHeaderChief";
import ChiefMainMenuTable from "../../components/Tables/ChiefMainMenuTable";
class ChiefManageMainMenu extends Component {
  state = {};

  handleGetMenuLength = length => {
    this.setState({ menuLength: length });
  };
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderChief
          name="Main Menu"
          content="AMmainMenu"
          parentName="Main Menu"
          // menuLength={this.state.menuLength}
        />
        <Container className="mt--6" fluid>
          <ChiefMainMenuTable
            // setMenuLength={this.handleGetMenuLength}
            {...props}
          />
        </Container>
      </>
    );
  }
}
ChiefManageMainMenu.propTypes = {
  content: PropTypes.string
};
export default ChiefManageMainMenu;
