import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup
} from "reactstrap";
import MultiCheckboxSelect from "../MultipleCheckboxSelect/MultipleCheckboxSelect";
import { connect } from "react-redux";
import {
  actAddGroupTopicRequest,
  actUpdateGroupTopicRequest,
  actDeleteGroupTopicRequest,
  actGetTopicSelectRequest
} from "../../actions/topic.action";

class GroupTopicAddTopicModal extends Component {
  state = {
    name: "",
    listTopic: [],
    publish: true
  };

  componentDidMount() {
    const language = this.props.language;
    this.props.getTopicSelect({
      language,
      groupID: this.props.group_detail._id
    });
    this.setState({
      id: this.props.group_detail._id,
      name: this.props.group_detail.name,
      listTopic: this.props.group_detail.listTopic,
      publish: this.props.group_detail.visibility === "PUBLISHED"
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.group_detail !== this.props.group_detail) {
      this.setState({
        id: nextProps.group_detail._id,
        name: nextProps.group_detail.name,
        listTopic: nextProps.group_detail.listTopic,
        publish: nextProps.group_detail.visibility === "PUBLISHED"
      });
    }
  }

  _handleOnDelete = () => {
    const id = this.state.id;
    const group_detail = this.props.group_detail;
    const language = this.props.language;

    this.props.deleteGroupTopic(id, group_detail, { language });
    this.props.toggle();
  };

  _handleConfirm = () => {
    const { id, name, listTopic, publish } = this.state;
    const group_detail = this.props.group_detail;
    const language = this.props.language;

    this.props.updateGroupTopic(
      id,
      {
        name,
        listTopic,
        visibility: publish ? "PUBLISHED" : "UNPUBLISHED"
      },
      group_detail,
      { language }
    );

    this.props.toggle();
  };

  render() {
    const { topic_list } = this.props;
    return (
      <>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className="modal-group-topic modal-form"
        >
          <Form onSubmit={this._handleConfirm}>
            <ModalHeader toggle={this.props.toggle}>
              Add/Remove Topic
            </ModalHeader>
            <ModalBody className="py-0">
              <FormGroup>
                {/* <Label>Add/Remove Topic</Label> */}
                <MultiCheckboxSelect
                  options={topic_list}
                  value={this.state.listTopic}
                  searchPlaceholder="Select or search topic name"
                  onChange={value => this.setState({ listTopic: value })}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-flex-end">
              <Button
                color="link"
                style={{ color: "#172B4D" }}
                onClick={this.props.toggle}
              >
                CANCEL
              </Button>
              <Button
                onClick={this._handleConfirm}
                color="link"
                type="submit"
                style={{ color: "#fb6340" }}
              >
                SAVE
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    topic_list: state.topicReducer.all_topic_select,
    group_detail: state.topicReducer.group_detail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTopicSelect: data => dispatch(actGetTopicSelectRequest(data)),
    addGroupTopic: data => dispatch(actAddGroupTopicRequest(data)),
    updateGroupTopic: (id, data, group_detail, params) =>
      dispatch(actUpdateGroupTopicRequest(id, data, group_detail, params)),
    deleteGroupTopic: (id, group_detail, params) =>
      dispatch(actDeleteGroupTopicRequest(id, group_detail, params))
  };
};

GroupTopicAddTopicModal.propTypes = {
  topic_list: PropTypes.array,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  getTopicSelect: PropTypes.func,
  addGroupTopic: PropTypes.func,
  deleteGroupTopic: PropTypes.func,
  group_detail: PropTypes.object,
  isUpdate: PropTypes.bool,
  language: PropTypes.string,
  updateGroupTopic: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupTopicAddTopicModal);
