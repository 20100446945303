import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import WriterOutLineContentDetail from "../../../components/Detail/WriterOutLineContentDetail";
class ContentRequest extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeader
          name="Outline Request"
          content="contentRequest"
          parentName="Article"
        />
        <Container className="mt--7" fluid>
          <WriterOutLineContentDetail {...props} />
        </Container>
      </>
    );
  }
}
ContentRequest.propTypes = {
  content: PropTypes.string,
};
export default ContentRequest;
