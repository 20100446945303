import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  chief_readers: {
    docs: []
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_READERS_CHIEF:
      state = {
        ...state,
        chief_readers: action.data
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default userReducer;
