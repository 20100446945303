import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
import ChiefPublishReviewTable from "../../../components/Tables/ChiefPublishReviewTable";
class ChiefPublishReview extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Publish Articles"
          content="publishArticles"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <ChiefPublishReviewTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefPublishReview.propTypes = {
  content: PropTypes.string,
};
export default ChiefPublishReview;
