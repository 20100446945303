import React, { useEffect, useState } from "react";
import { Table, Row, Col } from "antd";
import DropDownDate from "../DropDownDate";
import FilterDateRanger from "../FilterDateRanger";
import callApi from "../../../utils/callApi";
import { makeUrlObj } from "../../../utils/makeUrl";
import Infor from "./Infor";
import Pie from "../Pie";
import moment from "moment";
import { columns, handlePieChartWriter } from "./utils"


const WriterSection = function() {
  const [writers, setWriters] = useState([]);
  const [query, setQuery] = useState({
    startDate: moment().subtract('days', 14),
    endDate: moment(),
  });
  const [data, setData] = useState({
    "writer": {
      "totalWriter": 0,
      "totalInHouse": 0,
      "totalCommunityWriter": 0,
      "titleOfUser": {
        "Author": 0,
        "Editor": 0,
        "Contributor": 0,
        "Expert": 0
      }
    },
    "dataCompare": {
      "writer": {
          "totalWriter": 0,
          "totalInHouse": 0,
          "totalCommunityWriter": 0
      }
    }
  });
 
  const [queryPieDate, setQueryPieDate] = useState({
    startDate: moment().subtract('months', 3),
    endDate: moment(),
  });
  const [dataPie, setDataPie] = useState({});

  const onChangeDate = function({ startDate, endDate }) {
    setQuery({ startDate, endDate });
  };
  const onChangePieDate = function({ startDate, endDate }) {
    setQueryPieDate({ startDate, endDate });
  };
  const onChangeRangePicker = function(value, dateString) {
    setQuery({ startDate: value[0], endDate: value[1] });
  };
  const fetch = function() {
    callApi(`v2/dashboard/writer-most-view?${makeUrlObj(query)}`, "GET")
      .then((res) => setWriters(res.data))
      .catch((error) => console.log(error));
  };
  const fetch1 = function () {
    callApi(
      `v2/dashboard/writer?${makeUrlObj(queryPieDate)}`,
      "GET"
    ).then((res) => {
      setDataPie(handlePieChartWriter(res));
      setData(res.data);
    });
  }
  useEffect(() => {
    fetch();
    fetch1();
  }, []);
  useEffect(() => {
    fetch();
  }, [query]);
  useEffect(() => {
    fetch1();
  }, [queryPieDate]);

  

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Infor data={data}/>
        <h3 style={{ margin: "40px 0px" }}>Type of Writer</h3>
        <Pie data={dataPie} />

        <div style={{marginBottom: "130px"}}></div>
        <DropDownDate defaultValue="30 days" onChange={onChangePieDate} />
        
      </Col>
      <Col span={12}>
       <h2 style={{  marginBottom: "20px" }} >Top Writers</h2>
        <Table
          dataSource={writers}
          pagination={false}
          columns={columns}
          footer={() => (
            <Row gutter={24}  type="flex" align="middle">
              <Col className="gutter-row" span={6}>
                <DropDownDate defaultValue="14 days" onChange={onChangeDate}  />
              </Col>
              <Col className="gutter-row" span={12}>
                <FilterDateRanger onChange={onChangeRangePicker} />
              </Col>
            </Row>
          )}
        />
      </Col>
    </Row>
  );
};

export default WriterSection;
