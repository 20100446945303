import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
import ChiefPublishArticleDetail from "../../../components/Detail/ChiefPublishArticleDetail";
class ChiefPublishArticle extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderChief
          name="Publish Articles"
          content="publishArticles"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <ChiefPublishArticleDetail {...props} />
        </Container>
      </>
    );
  }
}
ChiefPublishArticle.propTypes = {
  content: PropTypes.string,
};
export default ChiefPublishArticle;
