import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  FormGroup,
  Input
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GeneralInfoModal from "../Modals/GeneralInfoModal";
import { actGetCompanyDetailRequest } from "../../actions/general-info.action";

class CardCompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublishProfile: false,
      languagueKey: true
    };
  }
  async componentDidMount() {
    let params = {
      key: "Profile_key",
      key_language: "VN"
    };
    this.props.getCompanyDetail(params);
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.company_detail &&
      nextProps.company_detail !== this.props.company_detail
    ) {
      this.setState({
        data: this.props.company_detail.details.CompanyProfile
      });
    }
  }

  _handleOnChange = e => {
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data
    });
  };

  _toggleModalPublish = () => {
    this.setState(prevState => ({
      isPublishProfile: !prevState.isPublishProfile
    }));
  };

  _switchLanguage = e => {
    const newLanguage = e.target.checked;
    this.setState({
      languagueKey: newLanguage
    });
    if (newLanguage) {
      let params = {
        key: "Profile_key",
        key_language: "VN"
      };
      this.props.getCompanyDetail(params);
    } else {
      let params = {
        key: "Profile_key",
        key_language: "EN"
      };
      this.props.getCompanyDetail(params);
    }
  };

  render() {
    const { data } = this.state;
    return (
      <>
        <GeneralInfoModal
          modal={this.state.isPublishProfile}
          toggle={this._toggleModalPublish}
          profileDetail={this.state.data}
          title={"Publish information?"}
          message={"Are you sure want to publish information company profile?"}
          type="profile"
          languagueKey={this.state.languagueKey}
        />
        <Card>
          <CardHeader className="border-0 d-flex justify-content-between">
            <h3 className="mb-0">Company Profile</h3>
            <div className="general-info-card d-flex">
              <span className="mr-2">Show contents: </span>
              <label className="custom-toggle custom-toggle-warning mr-1">
                <input
                  checked={this.state.languagueKey}
                  onChange={this._switchLanguage}
                  type="checkbox"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="EN"
                  data-label-on="VN"
                />
              </label>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <h3 style={{ marginBottom: 12 }}>Company Information</h3>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="CompanyName"
                  >
                    Company name
                  </label>
                  <Input
                    id="CompanyName"
                    name="CompanyName"
                    type="text"
                    className="d-inline mr-3"
                    placeholder="Your company name"
                    value={data && data.CompanyName}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="PhoneNumber"
                  >
                    Phone number
                  </label>
                  <Input
                    id="PhoneNumber"
                    name="PhoneNumber"
                    type="text"
                    className="d-inline mr-3"
                    placeholder="Your company phone number"
                    value={data && data.PhoneNumber}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="email">
                    Email
                  </label>
                  <Input
                    id="Email"
                    name="Email"
                    type="email"
                    className="d-inline mr-3"
                    placeholder="Your company email"
                    value={data && data.Email}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="Address"
                  >
                    Address
                  </label>
                  <Input
                    id="Address"
                    name="Address"
                    type="text"
                    className="d-inline mr-3"
                    placeholder="Your company address"
                    value={data && data.Address}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <h3 style={{ marginBottom: 12 }}>Contact</h3>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="Twitter"
                  >
                    Twitter
                  </label>
                  <Input
                    id="Twitter"
                    name="Twitter"
                    type="text"
                    className="d-inline mr-3"
                    placeholder="Twitter link"
                    value={data && data.Twitter}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="Facebook"
                  >
                    Facebook
                  </label>
                  <Input
                    id="Facebook"
                    name="Facebook"
                    type="text"
                    className="d-inline mr-3"
                    placeholder="Facebook link"
                    value={data && data.Facebook}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="Instagram"
                  >
                    Instagram
                  </label>
                  <Input
                    id="Instagram"
                    name="Instagram"
                    type="text"
                    className="d-inline mr-3"
                    placeholder="Instagram link"
                    value={data && data.Instagram}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="Youtube"
                  >
                    Youtube
                  </label>
                  <Input
                    id="Youtube"
                    name="Youtube"
                    type="text"
                    className="d-inline mr-3"
                    placeholder="Youtube link"
                    value={data && data.Youtube}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right">
              <Button
                className="btn btn-success"
                onClick={this._toggleModalPublish}
              >
                Publish
              </Button>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    company_detail: state.generalInfoReducer.company_detail
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getCompanyDetail: data => {
      dispatch(actGetCompanyDetailRequest(data));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardCompanyProfile);
CardCompanyProfile.propTypes = {
  company_detail: PropTypes.object,
  getCompanyDetail: PropTypes.func
};
CardCompanyProfile.defaultProps = {};
