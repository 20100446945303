import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderAdmin from "../../components/Headers/CardsHeaderAdmin";
import WriterPastTable from "../../components/Tables/WriterPastTable";
class WriterPast extends Component {
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderAdmin
          name="Writer Past"
          content="employee"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <WriterPastTable {...props} />
        </Container>
      </>
    );
  }
}
WriterPast.propTypes = {
  content: PropTypes.string,
};
export default WriterPast;
