import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify, actFetchResourceFail } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetNewNotificationListRequest = data => {
  return dispatch => {
    return callApi(
      `getall-notification?limit=${data.limit}&page=${data.page}&sort=-1`,
      "GET"
    )
      .then(res => {
        dispatch(actGetAllNotification(res));
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actGetNewNotificationList = data => {
  return {
    type: Types.GET_NEW_NOTIFICATION,
    data
  };
};

export const actGetAllNotification = data => {
  return {
    type: Types.GET_ALL_NOTIFICATION,
    data
  };
};

export const actGetNotificationNewListRequest = () => {
  return dispatch => {
    return callApi(`getall-notification?sort=-1&limit=5`, "GET")
      .then(res => {
        dispatch(actGetNewNotificationList(res));
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actStatusSeeViewRequest = (id, data) => {
  return dispatch => {
    callApi(`changestatus-notification/${id}`, "PUT", data)
      .then(() => {
        // dispatch(
        //   actNotify({
        //     message: "See notification successfully!",
        //     color: "success"
        //   })
        // );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetReadAllNotificationRequest = () => {
  return dispatch => {
    return callApi(`active-all-noti`, "PUT")
      .then(res => {
        dispatch(actGetNotificationNewListRequest(res));
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};
