/* eslint-disable react/prop-types */
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Form,
  Col
} from "reactstrap";
import { countTime } from "utils/formatDate";
import AVA from "assets/img/theme/default-avatar.png";
import PROFILE from "assets/img/profile.svg";
import LOGOUT from "assets/img/logout.svg";
import { connect } from "react-redux";
import routes from "routes.js";
import { actGetMyProfileListRequest } from "../../actions/myprofile.action";
import {
  actStatusSeeViewRequest,
  actGetReadAllNotificationRequest
} from "../../actions/notification.action";
import HOST_URL from "../../variables/hostUrl";
import { Link } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import * as authenStorage from "../../utils/authen-storage";

class AdminNavbar extends React.Component {
  state = {
    dropdownOpen: false,
    noti: "new",
    new_notification: []
  };
  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  componentDidMount() {
    const key = localStorage.getItem("key");
    if (key) {
      this._getMyProfile();
    }
    this._setStateNewNoTi();
  }

  _getMyProfile() {
    this.props.getMyProfile();
  }

  _setStateNewNoTi = () => {
    this.setState({
      // eslint-disable-next-line react/prop-types
      new_notification: this.props.new_notification
    });
  };

  handleLogOut = () => {
    let is_Remember = localStorage.getItem("is_remember");
    if (is_Remember !== "true") {
      localStorage.clear();
    } else {
      localStorage.removeItem("key");
    }
    authenStorage.deleteToken();
    this.props.history.push("/admin/login");
  };

  handleMyProfile = () => {
    this.props.history.push("/admin/profile/my-profile");
  };

  handleOnSetLanguage = lang => {
    // eslint-disable-next-line react/prop-types
    this.props.setLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  renderRouteName = () => {
    const pathname = "/admin/" + window.location.pathname.split("/")[2];
    var route2 = false;
    var route = routes.find(prop => {
      if (prop.collapse) {
        route2 = prop.views.find(prop2 => {
          return prop2.path === pathname;
        });
        return route2;
      } else {
        return prop.path === pathname;
      }
    });
    if (route) return route2 ? route.name + " > " + route2.name : route.name;
  };

  handleOnNotiClick = (e, id, typestatus, idNoti, type) => {
    e.preventDefault();

    this.handleChangeNotiStatus(idNoti);
    const key = localStorage.getItem("key");
    const data = {
      status: "ACTIVE"
    };
    // eslint-disable-next-line react/prop-types
    this.props.putStatusSeeView(idNoti, data);
    this.toggle();
    setTimeout(() => {
      if (key === "writer") {
        // eslint-disable-next-line react/prop-types
        this.props.setStateFlagNew(false);
        switch (typestatus) {
          case "CONTENT_WRITING":
            return (
              (window.location.href = `/admin/writer/writer-writing/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "CONTENT_SUBMITTED":
            return (
              (window.location.href = `/admin/writer/writer-writing/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "COMPLETED":
            return (
              (window.location.href = `/admin/writer/writer-writing/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "EDITING":
            return (
              (window.location.href = `/admin/writer/writer-writing/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "REJECTED":
            if (type === "PITCH_REJECT_NOTI") {
              return (
                (window.location.href = `/admin/writer/pitch-detail/${id}`),
                this.props.putStatusSeeView(idNoti, data)
              );
            } else {
              return (
                (window.location.href = `/admin/writer/writer-writing/${id}`),
                // this.props.history.push(`/admin/writer/writer-writing/${id}`),
                // eslint-disable-next-line react/prop-types
                this.props.putStatusSeeView(idNoti, data)
              );
            }
          case "UNPUBLISHED":
            return (
              (window.location.href = `/admin/writer/writer-writing/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "OUTLINE_WRITING":
            return (
              (window.location.href = `/admin/writer/outline-content/${id}`),
              // this.props.history.push(`/admin/writer/outline-content/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "OUTLINE_SUBMITTED":
            return (
              (window.location.href = `/admin/writer/outline-content/${id}`),
              // this.props.history.push(`/admin/writer/outline-content/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "PUBLISHED":
            return (
              (window.location.href = `/admin/writer/writer-writing/${id}`),
              // this.props.history.push(/admin/writer/writer-writing/${id}),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "NEW":
            return (
              (window.location.href = `/admin/writer/my-assigned-post`),
              // this.props.history.push(/admin/writer/writer-writing/${id}),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "PITCH_WRITING":
            return (
              (window.location.href = `/admin/writer/pitch-detail/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "PITCH_SUBMITTED":
            return (
              (window.location.href = `/admin/writer/pitch-detail/${id}`),
              // this.props.history.push(`/admin/editor/outline-content/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          default:
        }
      }
      if (key === "editor") {
        switch (typestatus) {
          case "CONTENT_WRITING":
            return (
              (window.location.href = `/admin/editor/content-request/${id}`),
              // this.props.history.push(`/admin/editor/content-request/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "CONTENT_SUBMITTED":
            return (
              (window.location.href = `/admin/editor/content-request/${id}`),
              // this.props.history.push(`/admin/editor/content-request/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "COMPLETED":
            return (
              (window.location.href = `/admin/editor/writer-writing/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "EDITING":
            return (
              (window.location.href = `/admin/editor/writer-writing/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "REJECTED":
            if (type === "PITCH_REJECT_NOTI") {
              console.log("PITCH_REJECT_NOTI");
            } else {
              return (
                (window.location.href = `/admin/editor/content-request/${id}`),
                // this.props.history.push(`/admin/editor/content-request/${id}`),
                // eslint-disable-next-line react/prop-types
                this.props.putStatusSeeView(idNoti, data)
              );
            }
            break;
          case "UNPUBLISHED":
            return (
              (window.location.href = `/admin/editor/content-request/${id}`),
              // this.props.history.push(`/admin/editor/content-request/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "OUTLINE_WRITING":
            return (
              (window.location.href = `/admin/editor/outline-content/${id}`),
              // this.props.history.push(`/admin/editor/outline-content/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "OUTLINE_SUBMITTED":
            return (
              (window.location.href = `/admin/editor/outline-content/${id}`),
              // this.props.history.push(`/admin/editor/outline-content/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "PITCH_SUBMITTED":
            return (
              (window.location.href = `/admin/editor/pitch-detail/${id}`),
              // this.props.history.push(`/admin/editor/outline-content/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          default:
        }
      }
      if (key === "chiefeditor") {
        switch (typestatus) {
          case "CONTENT_WRITING":
            return (
              (window.location.href = `/admin/chief/un-publish-review/${id}`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "CONTENT_SUBMITTED":
            return (
              (window.location.href = `/admin/chief/content-request/${id}`),
              // this.props.history.push(`/admin/chief/un-published-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "COMPLETED":
            return (
              (window.location.href = `/admin/chief/un-published-review/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "EDITING":
            return (
              (window.location.href = `/admin/chief/writer-writing/${id}`),
              // this.props.history.push(`/admin/writer/writer-writing/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "REJECTED":
            if (type === "PITCH_REJECT_NOTI") {
              console.log("PITCH_REJECT_NOTI");
            } else {
              return (
                (window.location.href = `/admin/chief/content-request/${id}`),
                // this.props.history.push(`/admin/chief/content-request/${id}`),
                // eslint-disable-next-line react/prop-types
                this.props.putStatusSeeView(idNoti, data)
              );
            }
            break;
          case "UNPUBLISHED":
            return (
              (window.location.href = `/admin/chief/content-request/${id}`),
              // this.props.history.push(`/admin/chief/content-request/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "OUTLINE_WRITING":
            return (
              (window.location.href = `/admin/chief/outline-content/${id}`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );

          case "OUTLINE_SUBMITTED":
            return (
              (window.location.href = `/admin/chief/outline-content/${id}`),
              // this.props.history.push(`/admin/chief/un-published-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "NEW":
            return (
              (window.location.href = `/admin/chief/new-brief/${id}`),
              // this.props.history.push(`/admin/chief/un-published-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "REJECT_ARTICLE_ASSIGN_NOTI":
            return (
              (window.location.href = `/admin/chief/reject-assigned`),
              // this.props.history.push(`/admin/chief/un-published-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(idNoti, data)
            );
          case "PITCH_WRITING":
            return this.props.putStatusSeeView(idNoti, data);
          default:
        }
      }
    }, 100);
  };

  _handleSubmitActiveGeneral = (e, id, status) => {
    e.preventDefault();
    const key = localStorage.getItem("key");
    this.handleChangeNotiStatus(id);
    const data = {
      status: "ACTIVE"
    };
    this.props.putStatusSeeView(id, data);
    this.toggle();
    setTimeout(() => {
      if (key === "writer") {
        switch (status) {
          case "GUIDELINE_PATH":
            return (
              (window.location.href = `/admin/writer/editorial-guideline`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          case "FAQ_PATH":
            return (
              (window.location.href = `/admin/writer/faq`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          default:
        }
      }
      if (key === "editor") {
        switch (status) {
          case "GUIDELINE_PATH":
            return (
              (window.location.href = `/admin/editor/editorial-guideline`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          case "FAQ_PATH":
            return (
              (window.location.href = `/admin/editor/faq`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          default:
        }
      }
      if (key === "chiefeditor") {
        switch (status) {
          case "GUIDELINE_PATH":
            return (
              // (window.location.href = `/admin/chief/editorial-guideline`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          case "FAQ_PATH":
            return (
              // (window.location.href = `/admin/chief/faq`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          default:
        }
      }
    }, 100);
  };

  handleChangeNotiStatus = idNoti => {
    // eslint-disable-next-line react/prop-types
    const data = this.props.new_notification;
    // eslint-disable-next-line no-unused-vars
    const datanew =
      data &&
      // eslint-disable-next-line react/prop-types
      data.forEach(item => {
        if (item.id === idNoti) {
          item.status = "ACTIVE";
        }
      });
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  renderBackgroundColor = () => {
    if (window.location.pathname.split("/")[2] === "writer") {
      return "#5e72e4";
    } else if (window.location.pathname.split("/")[2] === "editor") {
      return "#008fe8";
    } else if (window.location.pathname.split("/")[2] === "chief") {
      return "#001dbc";
    } else if (window.location.pathname.split("/")[2] === "settings") {
      return "#11cdef";
    } else if (window.location.pathname.split("/")[2] === "profile") {
      return "#11cdef";
    } else if (window.location.pathname.split("/")[2] === "notification") {
      return "#11cdef";
    } else if (
      window.location.pathname.split("/")[2] === "notification-writer"
    ) {
      return "#5E72E4";
    } else if (
      window.location.pathname.split("/")[2] === "notification-editor"
    ) {
      return "#018FE8";
    } else if (
      window.location.pathname.split("/")[2] === "notification-chief"
    ) {
      return "#001DBC";
    }
  };

  _renderNoti = () => {
    return (
      this.props.new_notification &&
      this.props.new_notification.map((prop, key) => {
        if (prop.type === "NEW_OUTLINE_APPROVAL_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data &&
                          prop.data.author &&
                          prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    sent a request to{" "}
                    <span className="text-success">approve</span> the outline
                    for{" "}
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "FEEDBACK_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    wrote <span className="text-success">feedback</span> article
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "OUTLINE_APPROVED_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-success">approved</span> outline your
                    for{" "}
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "OUTLINE_REJECTED_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-danger">rejected</span> outline your
                    for{" "}
                    <span className="text-danger">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "CONTENT_REWRITE_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    has requested to
                    <span className="text-primary"> rewrite </span> the content
                    for{" "}
                    <span className="text-primary">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "CONTENT_APPROVED_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-success">approved</span> your content
                    for{" "}
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "CONTENT_REJECTED_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-danger">rejected </span>
                    article for{" "}
                    <span className="text-danger">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "ARTICLE_PUBLISHED_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-success">completed </span>
                    article for{" "}
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "CONTENT_ASSIGN_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-success">assigned</span> article{" "}
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "NEW_CONTENT_PUBLIC_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-success">completed </span>
                    article for{" "}
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "OUTLINE_REWRITE_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    has requested to
                    <span className="text-warning"> rewrite </span> the outline
                    for{" "}
                    <span className="text-warning">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "NEW_CONTENT_APPROVAL_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    sent a <span className="text-success">request</span> to
                    approve the content for{" "}
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "WRITER_UPDATE_PROFILE") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data && prop.data.staff_name}
                    </span>{" "}
                    has just <span className="text-success">updated</span> his
                    personal information
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "EDITOR_UPDATE_PROFILE") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data && prop.data.staff_name}
                    </span>{" "}
                    has just <span className="text-success">updated</span> his
                    personal information
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "CHIEFEDITOR_UPDATE_PROFILE") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data && prop.data.staff_name}
                    </span>{" "}
                    has just <span className="text-success">updated</span> his
                    personal information
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "PITCH_REJECT_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id,
                  prop.type
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-danger">rejected </span> your pitch
                    for{" "}
                    <span className="text-danger">
                      {prop.data && prop.data.pitchTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "PITCH_REWRITE_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    has requested to{" "}
                    <span className="text-primary">rewrite </span> pitch for{" "}
                    <span className="text-primary">
                      {prop.data && prop.data.pitchTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "PITCH_APPROVE_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-success">approved </span> your pitch
                    for{" "}
                    <span className="text-success">
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "UPDATED_GENERAL_INFO_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e => this._handleSubmitActiveGeneral(e, prop.id)}
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="">{prop && prop.description}</span>{" "}
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "UPDATED_GUIDELINE_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this._handleSubmitActiveGeneral(e, prop.id, prop.data.status)
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    Chief of Editor{" "}
                    <span className="text-info">
                      {prop && prop.data.coeName}
                    </span>{" "}
                    just updated the Editorial Guideline
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "UPDATED_FAQ_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this._handleSubmitActiveGeneral(e, prop.id, prop.data.status)
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    Chief of Editor{" "}
                    <span className="text-info">
                      {prop && prop.data.coeName}
                    </span>{" "}
                    just updated the FAQ
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "COMMENT_PITCH_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data.author && prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data.author && prop.data.author.name}
                    </span>{" "}
                    wrote
                    <span className="text-success"> comment pitch</span>
                    <span className="text-success">
                      {" "}
                      {prop.data && prop.data.pitchTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "PITCH_SUBMITTED_APPROVAL_REQUEST_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data &&
                          prop.data.author &&
                          prop.data.author.avatar
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.createdAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data && prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-success">submitted</span> pitch for
                    <span className="text-success">
                      {" "}
                      {prop.data && prop.data.pitchTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        if (prop.type === "REJECT_ARTICLE_ASSIGN_NOTI") {
          return (
            <ListGroupItem
              key={key}
              className="list-group-item-action"
              href=""
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.articleID,
                  prop.type,
                  prop.id
                )
              }
              tag="a"
              style={
                prop.status === "INACTIVE" ? { backgroundColor: "#E5EAF2" } : {}
              }
            >
              <Row className="align-items-center">
                <Col className="col-auto">
                  <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                    {/* <i className="ni ni-bell-55" /> */}
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={
                          prop.data && prop.data.author
                            ? HOST_URL + prop.data.author.avatar
                            : AVA
                        }
                      />
                    </a>
                  </span>
                </Col>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">
                        {prop.data && prop.data.author
                          ? prop.data.author.name
                          : "User"}
                      </h4>
                    </div>
                    <div className="text-right text-muted">
                      <small>{countTime(prop.updatedAt)}</small>
                    </div>
                  </div>
                  <p className="text-sm mb-0">
                    <span className="text-info">
                      {prop.data && prop.data.author && prop.data.author.name}
                    </span>{" "}
                    <span className="text-danger">rejected</span> assigned brief
                    <span className="text-success">
                      {" "}
                      {prop.data && prop.data.articleTitle}
                    </span>
                  </p>
                </div>
              </Row>
            </ListGroupItem>
          );
        }
        return null;
      })
    );
  };

  _handleReadAllNoti = () => {
    this.props.readAllNoti();
  };

  render() {
    const key = localStorage.getItem("key");
    const filterNoti =
      this.props.new_notification &&
      this.props.new_notification.filter(
        status => status.status === "INACTIVE"
      );

    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark": this.props.theme === "dark" },
            { "navbar-light": this.props.theme === "light" }
          )}
          style={{ backgroundColor: this.renderBackgroundColor() }}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Form>
                <h3 style={{ color: "white" }}>{this.renderRouteName()}</h3>
              </Form>
              <Nav
                className="align-items-center ml-md-auto border-right"
                navbar
              >
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggle}
                  nav
                >
                  <DropdownToggle className="nav-link" color="" tag="a">
                    <i
                      className={`ni ni-bell-55 ${
                        (filterNoti && filterNoti.length > 0) ||
                        // eslint-disable-next-line react/prop-types
                        this.props.flagNew
                          ? "new"
                          : ""
                      }`}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right
                  >
                    <div className="px-3 py-3">
                      <Row>
                        <Col md="9">
                          <h6 className="text-sm text-muted m-0">
                            You have{" "}
                            <strong className="text-info">
                              {this.props.new_notification &&
                                this.props.new_notification.length}
                            </strong>{" "}
                            new notifications.
                          </h6>
                        </Col>
                        <Col md="3">
                          <div className="">
                            <h6 className="text-sm text-muted m-0 ">
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => this._handleReadAllNoti()}
                              >
                                Read all
                              </span>
                            </h6>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <ListGroup flush className="">
                      {this._renderNoti()}
                    </ListGroup>

                    <DropdownItem
                      className="text-center text-info font-weight-bold py-3"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <Link
                        to={
                          key === "writer"
                            ? "/admin/notification-writer"
                            : key === "editor"
                            ? "/admin/notification-editor"
                            : key === "chiefeditor"
                            ? "/admin/notification-chief"
                            : key === "admin"
                            ? "/admin/notification"
                            : ""
                        }
                      >
                        View all
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <Media className="mr-3 d-none d-lg-block text-right">
                        <span className="mb-0 text-sm font-weight-bold">
                          {this.props.myprofile_list.data &&
                            this.props.myprofile_list.data.name}
                        </span>
                        <br />
                        <span
                          className="mb-0 text-sm"
                          style={{ textTransform: "capitalize" }}
                        >
                          {this.props.myprofile_list.data &&
                            this.props.myprofile_list.data.job_position}
                        </span>
                      </Media>
                      <span className="avatar ml-2 avatar-sm rounded-circle">
                        <Avatar src={this.props.myprofile_list.data.avatar} />
                      </span>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                    <DropdownItem onClick={this.handleMyProfile}>
                      <img src={PROFILE} width="18px" height="18px" alt="" />
                      <span>My profile</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={this.handleLogOut}>
                      <img src={LOGOUT} width="24px" height="16px" alt="" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
  lang: "vi"
};

AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
  history: PropTypes.object
};

const mapStateToProps = state => {
  return {
    myprofile_list: state.myProfileReducer.myprofile_list,
    new_notification: state.notificationReducer.new_notification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMyProfile: () => {
      dispatch(actGetMyProfileListRequest());
    },
    putStatusSeeView: (id, data) => {
      dispatch(actStatusSeeViewRequest(id, data));
    },
    readAllNoti: () => {
      dispatch(actGetReadAllNotificationRequest());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
AdminNavbar.defaultProps = {
  myprofile_list: PropTypes.object,
  new_notification: PropTypes.array,
  getMyProfile: PropTypes.func,
  getNotificationNew: PropTypes.func,
  putStatusSeeView: PropTypes.func,
  readAllNoti: PropTypes.func
};
