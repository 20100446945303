const options = [
  {
    label: 'Name',
    placeholder: 'Name',
    props: "name",
    span: 7,
    rules: [
      {
        required: true,
        message: "Please input your Name!",
      },
    ],
    type: 'Input',
    offet: 0,
  },
  {
    label: 'Birthday',
    placeholder: 'Birthday',
    props: "birthday",
    span: 7,
    rules: [
      {
        required: true,
        message: "Please input your Birthday!",
      },
    ],
    type: 'DatePicker',
    offet: 1,
  },
  {
    label: 'Gender',
    placeholder: 'Gender',
    props: "gender",
    span: 7,
    rules: [
      {
        required: true,
        message: "Please input your Gender!",
      },
    ],
    type: 'Gender',
    offet: 1,
  },
  {
    label: 'Email Address',
    placeholder: 'Email Address',
    props: "email",
    span: 7,
    rules: [
      {
        required: true,
        message: "Please input your Email Address!",
      },
    ],
    type: 'Input',
    offet: 0,
  },
  {
    label: 'Mobile Number',
    placeholder: 'Mobile Number',
    props: "phone",
    span: 7,
    rules: [
      {
        required: true,
        message: "Please input your Mobile Number!",
      },
      {
        min: 10,
        message: "Please input your Mobile 10 character min ",
      },
      {
        max: 10,
        message: "Please input your Mobile 10 character max",
      }
    ],
    maxLength: 1,
    type: 'Input',
    offet: 1,
  },
  {
    label: 'Other Contact Address',
    placeholder: 'Other Contact Address',
    props: "addtionalContactInfo",
    span: 7,
    rules: [
      
    ],
    type: 'Input',
    offet: 1,
  },
  {
    label: 'Education Background',
    placeholder: 'Education Background',
    props: "educationBackground",
    span: 11,
    rules: [
      
    ],
    type: 'Input',
    offet: 0,
  },
  {
    label: 'School / University',
    placeholder: 'School / University',
    props: "education",
    span: 11,
    rules: [
      
    ],
    type: 'Input',
    offet: 1,
  },
  {
    label: 'Work Experience',
    placeholder: 'Work Experience',
    props: "experience",
    span: 11,
    rules: [
     
    ],
    type: 'Input',
    offet: 0,
  },
  {
    label: 'Current Employment Status ',
    placeholder: 'Current Employment Status',
    props: "workingStatus",
    span: 11,
    rules: [
     
    ],
    type: 'Input',
    offet: 1,
  },
  {
    label: 'Interested Website ',
    placeholder: 'Interested Website',
    props: "interestedWebsite",
    span: 11,
    rules: [
    
    ],
    type: 'Input',
    offet: 0,
  },
  {
    label: 'Residing City',
    placeholder: 'Residing City',
    props: "address",
    span: 11,
    rules: [
      
    ],
    type: 'Input',
    offet: 1,
  },
  {
    label: 'How do you know about Vietcetera',
    placeholder: 'How do you know about Vietcetera',
    props: "referenceResource",
    span: 11,
    rules: [
      
    ],
    type: 'Input',
  }
]
const options1 = [
  {
    label: 'Joining Date',
    placeholder: 'Joining Date',
    props: "date_joined",
    span: 7,
    rules: [
      {
        required: true,
        message: "Please input your Joining Date!",
      },
    ],
    type: 'DatePicker',
    offet: 0,
  },
  {
    label: 'Job/Position',
    placeholder: 'Job/Position',
    props: "job_position",
    span: 7,
    rules: [
      {
        required: true,
        message: "Please input your Job/Position!",
      },
    ],
    type: 'Jobs',
    offet: 1,
  },
  {
    label: 'Level',
    placeholder: 'Level',
    props: "isSenior",
    span: 7,
    rules: [
      {
        required: true,
        message: "Please input your Job/Position!",
      },
    ], 
    type: 'Level',
    offet: 1,
  },
  {
    label: 'Primary Topic',
    placeholder: 'Primary Topic',
    props: "writerInterestTopics[0].topic.name",
    span: 11,
    rules: [
      
    ],
    type: 'Topic',
    offet: 0,
  },
  {
    label: 'Expertise',
    placeholder: 'Expertise',
    props: "writerInterestTopics[0].expertise",
    span: 11,
    rules: [
      
    ],
    type: 'expertise',
    offet: 1,
  },
  {
    label: 'Secondary Topic',
    placeholder: 'Secondary Topic',
    props: "writerInterestTopics[1].topic.name",
    span: 11,
    rules: [
     
    ],
    type: 'Topic',
    offet: 0,
  },
  {
    label: 'Expertise',
    placeholder: 'Expertise',
    props: "writerInterestTopics[1].expertise",
    span: 11,
    rules: [
     
    ],
    type: 'expertise',
    offet: 1,
  },
  {
    label: 'Interested Topic',
    placeholder: 'Interested Topic',
    props: "interest",
    span: 11,
    rules: [
      {
        required: true,
        message: "Please input your interest!",
      },
    ],
    type: 'Topic',
    offet: 0,
  },

]
export default {
  options,
  options1,
};