import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  Form,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { connect } from "react-redux";
import { actGetArtilceByTopicRequest } from "../../actions/topic.action";
import { Tooltip } from "antd";
import { formatTextLength } from "../../utils/formatText";

class ArticleListModal extends Component {
  componentDidMount() {
    this.getArticleByTopic();
  }

  getArticleByTopic = () => {
    const data = {
      key: this.props.topic_key,
      search: ""
    };
    this.props.getArticleByTopic(data);
  };

  onSearch = e => {
    const data = {
      key: this.props.topic_key,
      search: e.target.value
    };
    this.props.getArticleByTopic(data);
  };

  onActionSelect = article => {
    window.location.href = `/admin/chief/un-published-review/${article.id}`;
  };

  render() {
    const { topic_detail } = this.props;
    return (
      <Modal
        isOpen={this.props.modal}
        toggle={this.props.toggle}
        className="modal-artilce-list"
      >
        <ModalHeader toggle={this.props.toggle}>
          {topic_detail.name}
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="pl-4 pr-4">
              <InputGroup size="sm" className="mb-2 border-0">
                <InputGroupAddon addonType="prepend" className="border-right-0">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Your name"
                  type="text"
                  onChange={this.onSearch}
                />
              </InputGroup>
            </div>
          </Form>
          <div className="table-container">
            <Table
              className="align-items-center table-flush"
              hover
              style={{ border: "0px" }}
            >
              <thead className="thead-light">
                <tr>
                  <th>ARTICLE NAME</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {this.props.articles &&
                  this.props.articles.map((prop, key) => {
                    return (
                      <tr
                        key={key}
                        onClick={() => this.onActionSelect(prop)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>
                          <Tooltip title={prop.angle_title}>
                            {formatTextLength(prop.angle_title, 50)}
                          </Tooltip>
                        </td>
                        <td>
                          {prop.status_article === "PUBLISHED" ? (
                            <span className="published">Published</span>
                          ) : (
                            <span className="unpublished">Unpublished</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    articles: state.topicReducer.article_by_topic,
    topic_detail: state.topicReducer.topic_detail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getArticleByTopic: key => {
      dispatch(actGetArtilceByTopicRequest(key));
    }
  };
};

ArticleListModal.propTypes = {
  topic_detail: PropTypes.object,
  topic_key: PropTypes.string,
  articles: PropTypes.array,
  getArticleByTopic: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleListModal);
