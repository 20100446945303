import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Arrow from "../Arrow"
import callApi from "../../../utils/callApi";
import get from "lodash/get";
import "./styles.scss";
import { makeUrlObj } from "../../../utils/makeUrl";
import { ShowNumner } from "../utils";

const TopViewArticle = function({  queryDate, language, dataArticle }) {
  const [data, setData] = useState({
    view: 0,
    viewCompare: 0,
  });
  const fetchTotalAllArticle = () => {
    callApi(
      `v2/dashboard/total-article?${makeUrlObj({...queryDate, language})}`,
      "GET"
    ).then((res) => {
      setData({
        view: get(res.data, "view", 0),
        viewCompare: get(res.data, "viewCompare", 0),
      });
    });
  }
  useEffect(() => {
    fetchTotalAllArticle()
  }, []);
  useEffect(() => {
    fetchTotalAllArticle()
  }, [queryDate]);
  
  return (
    <Row>
      <Col span="8">
        <div>Published</div>
        <h1>{ShowNumner(dataArticle.totalArticle)}</h1>
        <div className="d-flex align-items-center">
         <Arrow data={dataArticle.totalArticle} dataCompare={dataArticle.totalArticleCompare}></Arrow>
        </div>
      </Col>
      <Col span="8">
        <div>Views</div>
        <h1>{ShowNumner(data.view)}</h1>
        <div className="d-flex align-items-center">
          <Arrow data={data.view} dataCompare={data.viewCompare}></Arrow>
        </div>
      </Col>
     {/*  <Col span="8">
        <div>Avg. Time on Article</div>
        <h1>1m 42s</h1>
        <div className="d-flex align-items-center">
          <Arrow></Arrow>
          <span style={{ marginLeft: "10px" }}>5,1k</span>
        </div>
      </Col> */}
    </Row>
  );
};

export default TopViewArticle;
