import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { PropTypes } from "prop-types";

const renderPageNumber = (getListByPage, current, length) => {
  var jsx = [];
  if (length > 5) {
    if (current > 1)
      jsx.push(
        <React.Fragment key={"fragment1"}>
          <PaginationItem
            onClick={() => {
              getListByPage(1);
            }}
          >
            <PaginationLink>{"|<"}</PaginationLink>
          </PaginationItem>
          <PaginationItem
            onClick={() => {
              getListByPage(current - 1);
            }}
          >
            <PaginationLink>{"<"}</PaginationLink>
          </PaginationItem>
        </React.Fragment>
      );
    if (window.innerWidth > 400) {
      for (let i = current - 2; i < current + 3; i++) {
        if (i > 0 && i <= length)
          jsx.push(
            <PaginationItem
              key={i}
              onClick={() => {
                getListByPage(i);
              }}
              className={i === current ? "active" : ""}
            >
              <PaginationLink>{i}</PaginationLink>
            </PaginationItem>
          );
      }
    } else if (window.innerWidth < 400) {
      jsx.push(
        <PaginationItem
          onClick={() => {
            getListByPage(current);
          }}
          className="active"
        >
          <PaginationLink>{current}</PaginationLink>
        </PaginationItem>
      );
    }
    if (current < length)
      jsx.push(
        <React.Fragment key={"fragment2"}>
          <PaginationItem
            onClick={() => {
              getListByPage(current + 1);
            }}
          >
            <PaginationLink>{">"}</PaginationLink>
          </PaginationItem>
          <PaginationItem
            onClick={() => {
              getListByPage(length);
            }}
          >
            <PaginationLink>{">|"}</PaginationLink>
          </PaginationItem>
        </React.Fragment>
      );
  } else if (length <= 5) {
    for (let i = 0; i < length; i++) {
      jsx.push(
        <PaginationItem
          key={i}
          onClick={() => {
            getListByPage(i + 1);
          }}
          className={i + 1 === current ? "active" : ""}
        >
          <PaginationLink>{i + 1}</PaginationLink>
        </PaginationItem>
      );
    }
  }
  return jsx;
};

const CustomPagination = ({ getListByPage, page, totalPages, linkTo }) => {
  return (
    <React.Fragment>
      <div className="myPagination">
        <Pagination className="pagination mb-0" listClassName="mb-0">
          {renderPageNumber(getListByPage, page, totalPages, linkTo)}
        </Pagination>
      </div>
    </React.Fragment>
  );
};

export default CustomPagination;
CustomPagination.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  linkTo: PropTypes.string,
  getListByPage: PropTypes.func
};
CustomPagination.defaultProps = {
  linkTo: ""
};
