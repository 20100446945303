import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
import EditorOutlineContentDetail from "../../../components/Detail/EditorOutlineContentDetail";
class EditorOutlineContent extends Component {
  state = {
    id: window.location.pathname.split("/")[2],
  };
  render() {
    const props = this.props;
    return (
      <>
        {this.state.id === "editor" ? (
          <CardsHeaderEditor
            name="Outline Content"
            content="outlineContent"
            parentName="Article"
          />
        ) : (
          <CardsHeaderChief
            name="Article in Progress"
            content="articleinProgress"
            parentName="Article"
          />
        )}

        <Container className="mt--7" fluid>
          <EditorOutlineContentDetail {...props} />
        </Container>
      </>
    );
  }
}
EditorOutlineContent.propTypes = {
  content: PropTypes.string,
};
export default EditorOutlineContent;
