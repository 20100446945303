import axios from "axios";
import API_URL from "variables/host";
import * as authenStorage from "../utils/authen-storage";

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

function clearToken() {
  deleteAllCookies();
  localStorage.clear();
}

export default function callApi(
  endpoint,
  method = "GET",
  data = null,
  form_data = false
) {
  return axios({
    method: method,
    url: `${API_URL}/${endpoint}`,
    data: data,
    headers: {
      accessToken: authenStorage.getToken(),
      "content-type": !form_data
        ? "application/json;charset=UTF-8"
        : "multipart/form-data"
    }
  })
    .then(res => {
      // console.log(res.data);
      if (res.data.errorCode !== 0 && res.data.errorCode !== 200) {
        throw res;
      }
      return res.data;
    })
    .catch(err => {
      if (!err.data) {
        // window.location.href = "/admin/login";
      } else {
        const errorCode = err.data.errorCode;
        if (
          (errorCode === 4 || errorCode === 999) &&
          window.location.href !== "/admin/login"
        ) {
          window.location.href = "/login?redirect=true";
          clearToken();
        }
      }
      throw err;
    });
}
