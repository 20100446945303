import React, { Component } from "react";
import capitalize from "lodash/capitalize";
import Dropzone from "react-dropzone";
import get from "lodash/get";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import {
  Form,
  Input as InputAnt,
  Icon,
  Button as ButtonAnt,
  message,
  Tooltip,
} from "antd";
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Spin } from "antd";
import callApi from "../../utils/callApi";
import Select from "react-select";
import { actNotify } from "../../actions/fetch.action";
import TagInput from "../TagSelector";
import { languageOption } from "../../variables/selectOptions";
import { actEditorSaveSettingRequest } from "../../actions/chiefArticle.action";
import { actGetPostInfoRequestV2 } from "../../actions/post.action";
import { actGetPublishedCollectionRequest } from "../../actions/chief-collection.action";
import HOST_URL from "../../variables/hostUrl";
import NewPollManager from "../Poll/NewPollForm";
import EditPollManager from "../Poll/EditPollForm";
// import { ReactComponent as Icon } from "../../assets/img/icons/exclamation-outline.svg";
import {
  featureImageModes,
  MAX_SIZE_IMAGE,
  ARTICLE_TYPES,
} from "../../utils/constants";
import "./style.scss";
import TagValidation from "../../components/TagValidation";

let id = 0;
const { TextArea } = InputAnt;
class EditorPostSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingImage: false,
      uploadingThumbnail: false,
      description: "",
      imageId: "",
      tags: [],
      topic: [],
      series: null,
      author: [],
      slug: "",
      previewImage: "",
      previewThumbnail: "",
      image: "",
      thumbnailImageId: "",
      otherLanguageLink: "",
      publishDate: "",
      excerpt: "",
      language: "",
      errors: {
        description: "",
      },
      featureImage: "",
      featureImageCaption: "",
      imgError: "",
      summaries: [],
      summariesLength: 0,
      featureImageType: null,
      typeOfContent: null,
      excerptWarn: false,
      poll: {},
      pollId: "",
      label: {
        value: "",
        label: "No badge",
      },
    };
  }

  componentDidMount() {
    this._getPostInfo();
    this._getPublishedCollection();
  }
  // sync props to initial value inputs state
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.writing_content &&
      nextProps.writing_content !== this.props.writing_content &&
      nextProps.writing_content.data
    ) {
      const next = nextProps.writing_content.data;
      const languageValue = {
        value: next.language === "VN" ? "VN" : "EN",
        label: next.language === "VN" ? "Vietnamese" : "English",
      };
      const previewImageUrl =
        next.images && next.images.url ? `${HOST_URL}${next.images.url}` : null;
      const { setting } = this.props;
      const featureImageCaption = get(
        nextProps,
        "writing_content.data.images.featureImageCaption",
        ""
      );
      const featureImage = get(
        nextProps,
        "writing_content.data.images.featureImage",
        ""
      );
      const summaries = get(nextProps, "writing_content.data.summaries", []);
      const summariesLength = summaries.length;
      const featureImageType = get(
        nextProps,
        "writing_content.data.featureImageType",
        ""
      );
      const featureImageTypeObject = {
        value: featureImageType,
        label: capitalize(featureImageType),
      };
      const previewThumbnail = get(
        nextProps,
        "writing_content.data.thumbnail.url",
        null
      );
      const previewThumbnailUrl =
        previewThumbnail && `${HOST_URL}${previewThumbnail}`;

      const typeOfContent = get(
        nextProps,
        "writing_content.data.typeOfContent",
        "original"
      );
      const articleTypeObject = {
        value: typeOfContent,
        label: capitalize(typeOfContent),
      };
      const seriesid = get(
        nextProps,
        "writing_content.data.collectionId",
        null
      );
      const series = this.mapSeriesToDropdown(seriesid);
      this.setState(
        {
          description: next.seo && next.seo.description,
          language: languageValue,
          slug: next.slug,
          imageId: next.images && next.images._id,
          thumbnailImageId: next.thumbnail && next.thumbnail.id,
          previewImage: previewImageUrl,
          otherLanguageLink: next.other_lang_link,
          tags: next.tags && next.tags.length > 0 ? next.tags : [],
          topic: next.topic && next.topic.length > 0 ? next.topic : [],
          excerpt: next.excerpt,
          featureImageCaption: featureImageCaption,
          featureImage: featureImage,
          summaries: summaries,
          summariesLength: summariesLength,
          featureImageType: featureImageTypeObject,
          previewThumbnail: previewThumbnailUrl,
          typeOfContent: articleTypeObject,
          pollId: next.pollId,
          series: series,
        },
        () => {
          this.props.onChangeSetting({
            ...setting,
            description: next.seo && next.seo.description,
            language: languageValue,
            slug: next.slug,
            imageId: next.images && next.images._id,
            thumbnailImageId: next.thumbnail && next.thumbnail.id,
            otherLanguageLink: next.other_lang_link,
            tags: next.tags && next.tags.length > 0 ? next.tags : [],
            topic: next.topic && next.topic.length > 0 ? next.topic : [],
            excerpt: next.excerpt,
            featureImageCaption: featureImageCaption,
            featureImage: featureImage,
            featureImageType: featureImageTypeObject,
            typeOfContent: articleTypeObject,
            series: series,
          });
        }
      );
    }
  }

  _handleOnChangeSetting = (label, value) => {
    const { setting } = this.props;
    this.setState(
      {
        [label]: value,
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          [label]: value,
        });
      }
    );
  };

  onExcerptChange = (e) => {
    const value = e.target.value;
    const { setting } = this.props;
    this.setState(
      {
        excerpt: value,
        excerptWarn: value.length > 160,
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          excerpt: value,
        });
      }
    );
  };

  onLanguageChange = (value) => {
    const { setting } = this.props;
    this.setState(
      {
        language: value,
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          language: value.value,
        });
      }
    );
  };

  onFeatureImageModeChange = (value) => {
    const { setting } = this.props;
    this.setState(
      {
        featureImageType: value,
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          featureImageType: value.value,
        });
      }
    );
  };

  // handle topic change
  _handleOnSelectTopicChange = (topickey, topicname) => {
    const { setting } = this.props;
    let tagsFilter = this.props.post_info.data.topics.filter(
      (prop) => prop.key === topickey
    );

    this.setState({
      topic: [
        {
          value: topickey,
          label: topicname,
          key: topickey,
          name: topicname,
          tags: tagsFilter[0].tags,
        },
      ],
    });
    this.setState({}, () => {
      this.props.onChangeSetting({
        ...setting,
        topic: this.state.topic,
      });
    });
  };

  _getPublishedCollection() {
    const data = {
      language: "VN",
    };
    this.props.getPublishedCollection(data);
  }

  _getPostInfo() {
    this.props.getPostInfo();
  }

  onTagsChange = (tags) => {
    this.setState({ tags });
  };

  onPreviewDrop = (files) => {
    this.setState({
      imgError: "",
    });
    let file = files[0];
    if (file.size > MAX_SIZE_IMAGE) {
      this.setState({
        imgError: `Maxium file size should be ${MAX_SIZE_IMAGE / 1000}KB`,
      });
      return;
    }
    this.uploadToServer(files, get(this.state.featureImageType, "value"));
  };

  uploadToServer = async (files, mode) => {
    const { setting } = this.props;
    this.setState({ uploadingImage: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const isPortrait = mode === "portrait";
    const uploadEndpoint = isPortrait
      ? "v2/images/portrait-feature-article"
      : "v2/images/standard-feature-image-article";
    const uploadImage = callApi(uploadEndpoint, "POST", formData, true);
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState(
      {
        imageId: result.data._id,
        previewImage: imageUrl,
        uploadingImage: false,
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          imageId: result.data._id,
        });
      }
    );
  };

  // Upload thumbnail
  handleuploadThumbnail = async (files) => {
    const { setting } = this.props;
    this.setState({ uploadingThumbnail: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const uploadImage = callApi(
      "v2/images/thumbnail-feature-article",
      "POST",
      formData,
      true
    );
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState(
      {
        thumbnailImageId: result.data._id,
        previewThumbnail: imageUrl,
        uploadingThumbnail: false,
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          thumbnailImageId: result.data._id,
        });
      }
    );
  };

  onSubmit = () => {
    const { article_id, title } = this.props;
    const {
      description,
      publishDate,
      tags,
      topic,
      otherLanguageLink,
      excerpt,
      imageId,
      slug,
      featureImageCaption,
      featureImage,
      featureImageType,
      language,
      thumbnailImageId,
      typeOfContent,
      summaries,
      poll,
      series,
    } = this.state;
    let data = {
      title: title,
      tags: tags,
      image: imageId,
      topic: topic,
      slug: slug,
      articleId: article_id,
      excerpt: excerpt,
      language: language.value,
      featureImageCaption: featureImageCaption,
      featureImage: featureImage,
      featureImageType: featureImageType.value,
      thumbnailImageId: thumbnailImageId,
      otherLanguageLink: otherLanguageLink,
      publishDate: publishDate || "",
      seo: {
        description: description,
      },
      typeOfContent: typeOfContent.value,
      summaries: summaries,
      poll: poll,
      label: "",
      collectionId: series && series.value,
    };
    data = omitBy(data, isNil);
    data = omitBy(data, isEmpty);
    if (TagValidation(data.tags)) {
      return;
    }
    if (isEmpty(publishDate)) {
      data.publishDate = "";
    }
    if (series && series.value && series.value === "no-series") {
      data.collectionId = "";
    }
    this.props.editorUpdatePostSetting(data);
  };
  // Add field summary
  add = () => {
    const { form } = this.props;
    const { summariesLength } = this.state;
    const keys = form.getFieldValue("keys");
    if (keys.length >= 3) {
      message.error("Maximum summaries is 3!");
      return;
    }
    this.setState(
      {
        summariesLength: summariesLength + 1,
      },
      () => {
        const nextKeys = keys.concat(summariesLength);
        form.setFieldsValue({
          keys: nextKeys,
        });
      }
    );
  };

  // Remove summary field
  remove = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    if (keys.length === 1) {
      return;
    }
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  };

  // handle submit summary
  handleSubmit = (e) => {
    const { setting } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (values.keys.length === 0) {
        message.error("Please add at least one summary!");
        return;
      }
      if (!err) {
        const { keys, names } = values;
        this.setState({
          summaries: keys.map((key) => names[key]),
        });
        this.props.onChangeSetting({
          ...setting,
          summaries: keys.map((key) => names[key]),
        });
        message.success("Save summaries successfully");
      }
    });
  };

  //Poll handle on change
  _handlePollChange = (poll) => {
    this.setState({
      poll: poll,
    });
  };
  renderArticleTypeNote = () => {
    return (
      <ul className="article-type-note">
        <li>
          <strong>Original:</strong> Content that originates from the in-house
          editorial team.
        </li>
        <li>
          <strong>Branded:</strong> Content written for commercial/branded
          purposes
        </li>
        <li>
          <strong>Outsourced:</strong> Content that originates from a
          third-party (community writer, contributor, experts, etc.)
        </li>
      </ul>
    );
  };

  _handleOnSelectSeriesChange = (series) => {
    const { setting } = this.props;
    this.setState(
      {
        series: series,
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          series: series,
        });
      }
    );
  };

  mapSeriesToDropdown = (id) => {
    if (isNil(id)) {
      return {
        value: "no-series",
        label: "No series",
      };
    }
    const { publishedCollection } = this.props;
    const filter = publishedCollection.filter((item) => item.id === id);
    const result = filter.map((item) => {
      return {
        value: item.id,
        label: item.title,
      };
    });
    return result[0];
  };

  render() {
    const {
      previewImage,
      previewThumbnail,
      uploadingImage,
      uploadingThumbnail,
      description,
      errors,
      slug,
      tags,
      topic,
      otherLanguageLink,
      excerpt,
      language,
      featureImageCaption,
      featureImage,
      featureImageType,
      typeOfContent,
      summaries,
      excerptWarn,
      poll,
      pollId,
    } = this.state;
    // const time_to_publish =
    //   moment(publishDate).diff(moment()) >= 0 ? moment(publishDate) : undefined;
    // const validDate = (current) => {
    //   var yesterday = moment().subtract(1, "day");
    //   return current.isAfter(yesterday);
    // };
    // dynamic form item handling
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { publishedCollection } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
      },
    };
    const arrayIndex = summaries.map((item, index) => index);
    getFieldDecorator("keys", { initialValue: arrayIndex });
    const keys = getFieldValue("keys");
    const formItems = keys.map((k, index) => (
      <Form.Item
        {...formItemLayout}
        className="summary-field-form__item"
        required={false}
        key={k}
      >
        {getFieldDecorator(`names[${k}]`, {
          initialValue: summaries[k],
          validateTrigger: ["onChange", "onBlur"],
          rules: [
            {
              max: 120,
              required: true,
              whitespace: true,
              message: "Invalid field! Please check again!",
            },
          ],
        })(
          <TextArea
            rows={3}
            placeholder="Summary"
            style={{ width: "90%", marginRight: 8 }}
          />
        )}
        {keys.length > 1 ? (
          <Icon
            style={{ color: "red" }}
            onClick={() => this.remove(k)}
            type="delete"
          />
        ) : null}
      </Form.Item>
    ));
    const isPortrait = get(featureImageType, "value") === "portrait";
    const seriesListOptionDynamic =
      publishedCollection &&
      publishedCollection.map((item) => {
        return {
          value: item.id,
          label: item.title,
        };
      });
    const seriesListOption = [
      { value: "no-series", label: "No series" },
      ...seriesListOptionDynamic,
    ];
    return (
      <div className="editor-post-settings">
        {this.state.alert}
        <Card className="mt-3">
          <CardHeader>
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <i className="fa fa-cogs text-primary pr-2"></i>
                <span className="mr-1 font-weight-bold">Article setting</span>
              </div>
              <h5 className="mb-0">
                <em>
                  All fields with (<span className="asterisk">*</span>) is
                  required
                </em>
              </h5>
            </div>
            <span className="d-block mt-2 mr-1">
              This setting is only visible for Editor & COE
            </span>
          </CardHeader>

          <Row>
            <Col xs="4">
              <div className="card-plain">
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0">Article Type</h4>
                    <span className="asterisk ml-1">*</span>
                    <Tooltip
                      className="ml-2"
                      title={() => this.renderArticleTypeNote()}
                    >
                      <Icon type="exclamation-circle" />
                    </Tooltip>
                  </div>
                </CardHeader>
                <CardBody style={{ paddingTop: 0 }}>
                  <Select
                    className="w-100 react-select"
                    classNamePrefix="react-select"
                    size="lg"
                    options={ARTICLE_TYPES}
                    value={typeOfContent}
                    onChange={(value) =>
                      this._handleOnChangeSetting("typeOfContent", value)
                    }
                    closeMenuOnSelect={true}
                  />
                </CardBody>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              {/* Publish time */}
              {/* Hide for editor because of no-role */}
              {/* <div className="card-plain">
                <CardHeader>
                  <h4 className="mb-0">Publish time</h4>
                </CardHeader>
                <CardBody style={{ paddingTop: 0 }}>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <h5 className="mb-0">Choose publish time</h5>
                      <ReactDatetime
                        isValidDate={validDate}
                        className="timming-picker text-primary"
                        inputProps={{
                          placeholder: "Immediately",
                        }}
                        value={time_to_publish}
                        onChange={(value) =>
                          this._handleOnChangeSetting("publishDate", value)
                        }
                        timeFormat={"HH:mm"}
                        dateFormat={"DD/MM/YYYY"}
                      />
                    </div>
                  </div>
                </CardBody>
              </div> */}
              {/* Permalink */}
              <div className="card-plain">
                <CardHeader>
                  <h4 className="mb-0">Permalink</h4>
                </CardHeader>
                <CardBody style={{ paddingTop: 0 }}>
                  <FormGroup>
                    <h5>URL Slug</h5>
                    <Input
                      id="example3cols1Input"
                      value={slug}
                      onChange={(e) =>
                        this._handleOnChangeSetting("slug", e.target.value)
                      }
                      type="text"
                    />
                    <span style={{ fontSize: 13 }}>
                      The last part of the URL.{" "}
                      <a href="#pablo" target="_blank">
                        Read about permalinks
                      </a>
                    </span>
                  </FormGroup>
                </CardBody>
              </div>
            </Col>
            <Col xl="6">
              {/* Thumbnail */}
              <div className="card-plain">
                <CardHeader>
                  <div className="d-flex">
                    <h4 className="mb-0">
                      {isPortrait
                        ? "Portrait thumbail Image"
                        : "Thumbnail Image"}
                    </h4>
                    {isPortrait && <span className="asterisk ml-1">*</span>}
                  </div>
                </CardHeader>
                <CardBody className="px-4 py-0">
                  <div className="justify-content-center dropzone-wrapper">
                    <Dropzone
                      onDrop={this.handleuploadThumbnail}
                      accept="image/*"
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragReject,
                        isDragActive,
                      }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="dropzone-cover">
                            {uploadingThumbnail ? (
                              <div className="loader">
                                <Spin />
                              </div>
                            ) : null}
                            {!previewThumbnail ? (
                              <>
                                {!isDragActive && "Drop a file to upload!"}
                                {isDragActive &&
                                  !isDragReject &&
                                  "Drop it to upload"}
                                {isDragReject &&
                                  "File type not accepted, sorry!"}
                              </>
                            ) : (
                              <img
                                className="w-100 h-100"
                                alt="Preview"
                                src={previewThumbnail}
                              />
                            )}
                          </div>
                          <span className="text-red">
                            {this.state.imgError}
                          </span>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </CardBody>
              </div>
            </Col>
          </Row>
          {/* Feature image */}
          <Row>
            <Col xl="6">
              <div className="card-plain">
                <CardHeader>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4>Image mode:</h4>
                    <Select
                      className="w-50"
                      options={featureImageModes}
                      value={
                        featureImageType || {
                          value: "lanscape",
                          label: "Landscape",
                          name: "Landscape",
                        }
                      }
                      onChange={(value) => this.onFeatureImageModeChange(value)}
                      closeMenuOnSelect={true}
                    ></Select>
                  </div>
                  <div className="d-flex">
                    <h4 className="mb-0">
                      {get(featureImageType, "value") === "portrait"
                        ? "Portait Feature Image"
                        : "Feature Image"}
                    </h4>
                    <span className="asterisk ml-1">*</span>
                  </div>
                </CardHeader>
                <CardBody className="px-4 py-0">
                  <div className="justify-content-center dropzone-wrapper">
                    {!isPortrait && (
                      <h4 className="mb-0">
                        Ratio: 16:9 ( Size: 2000 x 1125 )
                      </h4>
                    )}
                    <Dropzone onDrop={this.onPreviewDrop} accept="image/*">
                      {({
                        getRootProps,
                        getInputProps,
                        isDragReject,
                        isDragActive,
                      }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="dropzone-cover">
                            {uploadingImage ? (
                              <div className="loader">
                                <Spin />
                              </div>
                            ) : null}
                            {!previewImage ? (
                              <>
                                {!isDragActive && "Drop a file to upload!"}
                                {isDragActive &&
                                  !isDragReject &&
                                  "Drop it to upload"}
                                {isDragReject &&
                                  "File type not accepted, sorry!"}
                              </>
                            ) : (
                              <img
                                className="w-100 h-100"
                                alt="Preview"
                                src={previewImage}
                              />
                            )}
                          </div>
                          <span className="text-red">
                            {this.state.imgError}
                          </span>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </CardBody>
              </div>
            </Col>
            <Col xs="6">
              <div className="card-plain">
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0">Alternate text for feature image</h4>
                    <span className="asterisk ml-1">*</span>

                    <Tooltip
                      title={` The alternative text provides alternative information
                        for an image if a user for some reason cannot view it
                        (because of slow connection, or if the user uses a
                        screen reader).`}
                    >
                      <Icon type="exclamation-circle" />
                    </Tooltip>
                  </div>
                </CardHeader>
                <CardBody style={{ paddingTop: 0 }}>
                  <FormGroup>
                    <Input
                      value={featureImage}
                      onChange={(e) =>
                        this._handleOnChangeSetting(
                          "featureImage",
                          e.target.value
                        )
                      }
                      type="text"
                    />
                  </FormGroup>
                </CardBody>
              </div>
              <div className="card-plain">
                <CardHeader>
                  <div className="d-flex">
                    <h4 className="mb-0">Feature image’s caption</h4>
                    <span className="asterisk ml-1">*</span>
                  </div>
                </CardHeader>
                <CardBody style={{ paddingTop: 0 }}>
                  <FormGroup>
                    <Input
                      value={featureImageCaption}
                      onChange={(e) =>
                        this._handleOnChangeSetting(
                          "featureImageCaption",
                          e.target.value
                        )
                      }
                      type="text"
                    />
                  </FormGroup>
                </CardBody>
              </div>
            </Col>
          </Row>
          {/* Summary */}
          <div className="card-plain">
            <CardHeader>
              <div className="d-flex">
                <h4 className="mb-0">Summary</h4>
              </div>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <small className="d-block mb-4">3 summaries are the best</small>
              <Form className="summary-field-form" onSubmit={this.handleSubmit}>
                {formItems}
                <Form.Item
                  className="summary-field-form__item"
                  {...formItemLayoutWithOutLabel}
                >
                  <ButtonAnt
                    className="d-flex justify-content-center align-items-center"
                    type="dashed"
                    onClick={this.add}
                    style={{ width: "30%" }}
                  >
                    <Icon type="plus" /> Add summary
                  </ButtonAnt>
                </Form.Item>
                <Form.Item
                  className="summary-field-form__item"
                  {...formItemLayoutWithOutLabel}
                >
                  <ButtonAnt type="primary" htmlType="submit">
                    Save
                  </ButtonAnt>
                </Form.Item>
              </Form>
            </CardBody>
            {/* Series */}
            <div className="card-plain">
              <CardHeader>
                <div className="d-flex">
                  <h4 className="mb-0">Series</h4>
                  <span className="asterisk ml-1">*</span>
                </div>
              </CardHeader>
              <CardBody style={{ paddingTop: 0 }}>
                <div className="custom-vietcetera-topic">
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    size="lg"
                    closeMenuOnSelect={true}
                    value={this.state.series}
                    options={seriesListOption}
                    onChange={(value) =>
                      this._handleOnSelectSeriesChange(value)
                    }
                  />
                </div>
              </CardBody>
            </div>
          </div>
          <div className="card-plain">
            <CardHeader>
              <div className="d-flex">
                <h4 className="mb-0">Topic</h4>
                <span className="asterisk ml-1">*</span>
              </div>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <div className="custom-vietcetera-topic">
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  size="lg"
                  closeMenuOnSelect={true}
                  value={topic}
                  options={this.props.topics_name}
                  onChange={(value) =>
                    this._handleOnSelectTopicChange(value.key, value.name)
                  }
                />
              </div>
            </CardBody>
          </div>
          {/* Tags */}
          <TagInput
            tags={tags}
            onTagsChange={(tags) => this.onTagsChange(tags)}
          />
          {/* Excerpt */}
          <div className="card-plain">
            <CardHeader>
              <div className="d-flex">
                <h4 className="mb-0">Excerpt</h4>
                <span className="asterisk ml-1">*</span>
              </div>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <p>Write an excerpt ( Ideally 160 characters )</p>
              <Input
                className={`description ${excerptWarn ? "error" : ""}`}
                value={excerpt}
                onChange={this.onExcerptChange}
                rows="5"
                type="textarea"
              />
              <small className="float-right">
                {excerpt ? excerpt.length : "0"}/160
              </small>
            </CardBody>
          </div>
          {/* Language */}
          <div className="card-plain">
            <CardHeader>
              <div className="d-flex">
                <h4 className="mb-0">Language</h4>
                <span className="asterisk ml-1">*</span>
              </div>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <FormGroup>
                <Select
                  className="react-select1"
                  classNamePrefix="react-select"
                  name="interest"
                  isClearable
                  options={languageOption}
                  value={language}
                  onChange={(value) => {
                    this.onLanguageChange(value);
                  }}
                  closeMenuOnSelect={true}
                />
              </FormGroup>
            </CardBody>
          </div>
          {/* Translational slug */}
          <div className="card-plain">
            <CardHeader>
              <h4 className="mb-0">Translational slug</h4>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <Input
                type="text"
                value={otherLanguageLink}
                onChange={(e) =>
                  this._handleOnChangeSetting(
                    "otherLanguageLink",
                    e.target.value
                  )
                }
              ></Input>
            </CardBody>
          </div>
          {/* SEO Section */}
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <i className="fa fa-cogs text-primary pr-2"></i>
                <span className="mr-1 font-weight-bold">SEO</span>
              </div>
            </div>
          </CardHeader>
          <div className="card-plain">
            <CardHeader>
              <div className="d-flex">
                <h4 className="mb-0">Description</h4>
                <span className="asterisk ml-1">*</span>
              </div>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Form>
                      <Input
                        className="description"
                        maxLength="160"
                        value={description}
                        onChange={(e) =>
                          this._handleOnChangeSetting(
                            "description",
                            e.target.value
                          )
                        }
                        rows="5"
                        type="textarea"
                      />
                      <small className="float-right">
                        {description ? description.length : "0"}/160
                      </small>
                    </Form>
                    {errors && errors.description ? (
                      <p className="text-danger mt-2">
                        <small>{errors.description}</small>
                      </p>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </div>
          {/* Poll */}
          <div className="card-plain">
            <CardHeader>
              <h4 className="mb-0">Poll</h4>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              {pollId ? (
                <EditPollManager
                  pollId={pollId}
                  article_id={this.props.article_id}
                />
              ) : (
                <NewPollManager
                  onChangePoll={this._handlePollChange}
                  poll={poll}
                />
              )}
            </CardBody>
            <CardFooter className="text-right">
              <Button
                // disabled={this.props.status === "COMPLETED"}
                onClick={this.onSubmit}
                color="primary"
                type="submit"
              >
                SAVE
              </Button>
            </CardFooter>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    my_information: state.authReducer.my_information,
    post_info: state.postReducer.post_info,
    writing_content: state.writerArticleReducer.writing_content_id,
    topics_name: state.postReducer.topics_name,
    topics_name_select: state.postReducer.topics_name_select,
    publishedCollection: state.ChiefCollectionReducer.publishedCollection,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (data) => {
      dispatch(actNotify(data));
    },
    getPostInfo: (data) => {
      dispatch(actGetPostInfoRequestV2(data));
    },
    editorUpdatePostSetting: (data) => {
      dispatch(actEditorSaveSettingRequest(data));
    },
    getPublishedCollection: (data) => {
      dispatch(actGetPublishedCollectionRequest(data));
    },
  };
};

const WrappedEditorPostSetting = Form.create({ name: "dynamic_form_item" })(
  EditorPostSetting
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEditorPostSetting);

EditorPostSetting.propTypes = {
  article_id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  innerRef: PropTypes.func,
  readOnly: PropTypes.bool,
  isComment: PropTypes.bool,
  notify: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.object,
  topics_name: PropTypes.array,
  post_info: PropTypes.object,
  setting: PropTypes.object,
  getPostInfo: PropTypes.func,
  onChangeSetting: PropTypes.func,
  employee_list: PropTypes.array,
  getPublishedCollection: PropTypes.func,
  editorUpdatePostSetting: PropTypes.func,
};

EditorPostSetting.defaultProps = {};
