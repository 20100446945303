import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Form,
  Input,
} from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actRejectAssignedRequest } from "../../actions/writerArticle.action";
class RejectAssgnedPostModal extends Component {
  state = {
    id: "",
    note: "",
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.modalReject === false) {
      this.setState({
        note: "",
      });
    }
  }

  _handleOnChange = (e) => {
    this.setState({
      note: e.target.value,
    });
  };

  toggleReject = () => {
    this.props.toggleReject();
  };

  _hideAlert = () => {
    this.setState({
      alert: "",
    });
  };

  _handleOnReject = () => {
    const id = this.props.id;
    const params = this.props.params;
    const data = {
      note: this.state.note,
    };
    this.toggleReject();
    this.props.rejectAssgned(id, data, params);
  };

  render() {
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modalReject}
          toggle={this.toggleReject}
          className="modal-dialog-centered"
          size="sm"
        >
          <ModalHeader toggle={this.props.toggleReject}>Reason</ModalHeader>
          <ModalBody>
            {/* <h2 className="mb-0">Title</h2> */}
            <div className="">
              <Row>
                <Col lg="12">
                  <Form>
                    <Input
                      id="exampleFormControlTextarea1"
                      placeholder="Please enter a reason..."
                      rows="3"
                      type="textarea"
                      onChange={this._handleOnChange}
                    />
                  </Form>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              <Button
                disabled={!this.state.note}
                color="danger"
                type="button"
                style={{ height: "45px" }}
                onClick={() => this._handleOnReject()}
              >
                Reject
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    rejectAssgned: (id, data, params) => {
      dispatch(actRejectAssignedRequest(id, data, params));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RejectAssgnedPostModal);

RejectAssgnedPostModal.propTypes = {
  id: PropTypes.string,
  listcontent_byid: PropTypes.object,
  detailContent: PropTypes.object,
  modalReject: PropTypes.bool,
  toggleReject: PropTypes.func,
  uploadImg: PropTypes.func,
  rejectAssgned: PropTypes.func,
  params: PropTypes.object,
};

RejectAssgnedPostModal.defaultProps = {};
