import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  actGetSearchFeatureArticlesRequest,
  actResetSearchFeatureArticles,
  actAddFeatureArticleTemp,
  actEditFeatureArticleTemp,
  actResetFeatureArticle
} from "../../../actions/feature-articles.action";
import { actNotify } from "../../../actions/fetch.action";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import debounce from "lodash/debounce";
import {
  Form,
  Button,
  TimePicker,
  Modal,
  Divider,
  Typography,
  Spin,
  Tag,
  Select,
  message
} from "antd";

import "./styles.scss";
import { omit } from "reactstrap/lib/utils";
const { Text } = Typography;
const { Option } = Select;

const format = "HH:mm A";

const ModalAddFeatureArticle = ({
  handleOk,
  handleCancel,
  visible,
  setEditMode,
  editMode,
  form: {
    getFieldDecorator,
    validateFields,
    setFieldsValue,
    resetFields,
    getFieldValue
  }
}) => {
  const dispatch = useDispatch();
  const featureArticleSerachResult = useSelector(
    state => state.featureArticleReducer.data_search
  );
  const featureArticleGroup = useSelector(
    state => state.featureArticleReducer.featureArticleGroup.data
  );
  const searchLoading = useSelector(
    state => state.featureArticleReducer.searchLoading
  );
  const currentArticle = useSelector(
    state => state.featureArticleReducer.currentFeatureArticle
  );
  const featureArticleSchedule = useSelector(
    state => state.featureArticleReducer.featureArticleSchedule.setting
  );
  const selectedDate = useSelector(
    state => state.featureArticleReducer.selectedDate
  );
  const [isDisableTime, setDisableTime] = useState(false);

  const searchArray = get(featureArticleSerachResult, "docs", []);

  useEffect(() => {
    if (isEmpty(currentArticle)) {
      return;
    }
    const { scheduleTime, articleId, group } = currentArticle;
    // If cover story section disable feature time,
    if (group === "cover-story") {
      setDisableTime(true);
    }
    const scheduleTimeResult = currentArticle.scheduleTime
      ? moment(scheduleTime)
      : undefined;
    setFieldsValue({
      articleId,
      group,
      scheduleTime: scheduleTimeResult
    });
    return () => {
      setDisableTime(false);
      dispatch(actResetFeatureArticle());
      resetFields();
    };
  }, [currentArticle, visible, editMode]);

  const coverStoryArticle = featureArticleSchedule.filter(
    item => item.key === "cover-story"
  );
  const coverStoryArticleArr = coverStoryArticle[0].articles;

  function handleSubmit(e) {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        if (
          values.group === "cover-story" &&
          coverStoryArticleArr.length === 3
        ) {
          dispatch(
            actNotify({
              message: "Cover story section should have maxium 3 articles",
              color: "danger"
            })
          );
          handleCloseModal();
          return;
        }
        const { scheduleTime, articleId } = values;
        const articleName = searchArray.filter(item => item._id === articleId);
        const checkHoursAndMinutes = moment(scheduleTime).format("HH:mm");
        const time = `${selectedDate} ${checkHoursAndMinutes}`;
        let payload = {
          scheduleTime: time,
          status: "pending",
          articleId: articleId,
          angle_title: articleName[0].angle_title,
          group: values.group
        };
        if (values.group === "cover-story") {
          payload = omit(payload, ["scheduleTime"]);
        }
        dispatch(actAddFeatureArticleTemp(payload, values.group));

        message.success(
          `Schedule successfully an article in ${mapKeyToGroupName(
            values.group
          )}`
        );
        dispatch(actResetFeatureArticle());
        handleCloseModal();
      }
    });
  }
  // Validate time slot for article
  function validateTimeSlot(rule, value, callback) {
    const scheduleTime = getFieldValue("scheduleTime");
    const checkHoursAndMinutes = moment(scheduleTime).format("HH:mm");
    const groupValue = getFieldValue("group");
    const time = `${selectedDate} ${checkHoursAndMinutes}`;
    const today = moment().format("YYYY-MM-DD HH:mm");
    const isPastDate = moment(time).isBefore(today);
    const filterGroupItem = featureArticleSchedule.filter(
      item => item.key === groupValue
    );
    // Validate past time
    if (isPastDate) {
      callback("Invalid time. Cannot set article in the past time.");
    }
    for (let i of filterGroupItem[0].articles) {
      if (i.scheduleTime === time) {
        callback("Already had article at this time, please select other time.");
      }
    }
    callback();
  }

  function mapKeyToGroupName(key) {
    if (!key) {
      return;
    }
    const filterArray = featureArticleGroup.filter(item => item.key === key);
    if (filterArray && filterArray.length > 0) {
      return filterArray[0].name;
    }
  }

  function handleSearch(value) {
    if (value) {
      const data = { search: value, language: "VN" };
      dispatch(actGetSearchFeatureArticlesRequest(data));
    } else {
      dispatch(actResetSearchFeatureArticles());
    }
  }

  function editFeatureArticle(e) {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { scheduleTime, articleId } = values;
        const articleName = searchArray.filter(item => item._id === articleId);
        let payload = {
          scheduleTime: moment(scheduleTime).format("YYYY-MM-DD HH:mm"),
          status: "pending",
          articleId: articleId,
          angle_title: articleName[0].angle_title,
          group: values.group
        };

        if (values.group === "cover-story") {
          payload = omit(payload, ["scheduleTime"]);
        }
        dispatch(actEditFeatureArticleTemp(payload));
        setEditMode(false);
        message.success(`Edit successfully`);
        dispatch(actResetFeatureArticle());
        handleCloseModal();
      }
    });
  }

  function handleCloseModal() {
    handleCancel();
    resetFields();
  }

  function handleGroupChange(value) {
    if (value === "cover-story") {
      setDisableTime(true);
      return;
    }
    setDisableTime(false);
  }
  return (
    <Modal
      className={`application-info-modal`}
      visible={visible}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleCloseModal}
      footer={[]}
    >
      <div className="feature-article-setting-section">
        <h3>{editMode ? "Edit article" : "Add article"}</h3>
        <Form onSubmit={handleSubmit} className="add-feature-article-form">
          {/* Article name */}
          <div className="article-name">
            <label>
              Article name <Text type="danger">*</Text>
            </label>
            <Form.Item>
              {getFieldDecorator("articleId", {
                rules: [{ required: true, message: "This field is required!" }]
              })(
                <Select
                  // disabled={editMode && currentArticle.group !== "cover-story"}
                  placeholder="Search & select an article"
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={debounce(handleSearch, 300)}
                  notFoundContent={
                    searchLoading ? (
                      <div className="text-center">
                        <Spin size="small" />
                      </div>
                    ) : (
                      "No article found"
                    )
                  }
                >
                  {searchArray.map(item => {
                    return (
                      <Option key={item._id}>
                        <div className="d-flex justify-content-between align-items-center article-search-opt">
                          <span>{item.angle_title}</span>
                          <Tag
                            className={`${item.status_article.toLowerCase()}`}
                          >
                            {capitalize(item.status_article)}
                          </Tag>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </div>
          {/* Group feature*/}
          <div>
            <label>
              Group <Text type="danger">*</Text>
            </label>
            <Form.Item>
              {getFieldDecorator("group", {
                rules: [{ required: true, message: "This field is required!" }]
              })(
                <Select
                  onChange={handleGroupChange}
                  disabled={editMode}
                  placeholder="Select feature article section"
                >
                  {featureArticleGroup.map(item => (
                    <Option key={item.key} value={item.key}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
              {editMode && (
                <small className="text-red">
                  * If you want to change group or article. Please delete this
                  article
                </small>
              )}
            </Form.Item>
          </div>
          {/* Featured time */}
          <div>
            <label>
              Featured Time <Text type="danger">*</Text>
            </label>
            <Form.Item>
              {getFieldDecorator("scheduleTime", {
                rules: [
                  {
                    type: "object",
                    required: isDisableTime ? false : true,
                    message: "Please select time!"
                  },
                  {
                    validator: !isDisableTime && validateTimeSlot
                  }
                ]
              })(
                <TimePicker
                  defaultValue={undefined}
                  minuteStep={5}
                  disabled={isDisableTime}
                  use12Hours
                  format={format}
                />
              )}
            </Form.Item>
          </div>
          <Divider />
          <div className="btn-group d-flex align-items-center justify-content-end">
            <Form.Item>
              <Button onClick={handleCloseModal} type="danger" className="mr-2">
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              {editMode ? (
                <Button type="primary" onClick={editFeatureArticle}>
                  Update
                </Button>
              ) : (
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
const WrappedLiveUpdateForm = Form.create()(ModalAddFeatureArticle);
export default WrappedLiveUpdateForm;
