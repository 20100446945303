import React, { useState } from "react";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import { useDispatch } from "react-redux";
import { actSendBackContentRequest } from "../../actions/editorArticle.action";

function ContentSendBackModal({
  visible,
  loading,
  handleOk,
  id,
  toggle,
  content
}) {
  const [deadline, setDeadline] = useState("");
  const dispatch = useDispatch();

  const validDate = current => {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  function handleSendBackRequest() {
    const data = {
      status: "REWRITE",
      dead_line: deadline,
      content
    };
    dispatch(actSendBackContentRequest(id, data));
    toggle();
  }

  function handleDeadlineChange(value) {
    setDeadline(value);
  }

  return (
    <Modal
      className="pitch-reject-modal"
      visible={visible}
      title="Send back this article?"
      onOk={handleOk}
      onCancel={toggle}
      footer={[
        <Button
          className="modal-cancel-btn"
          type="ghost"
          key="cancel"
          onClick={toggle}
        >
          No
        </Button>,
        <Button
          disabled={isEmpty(deadline)}
          className="modal-submit-btn"
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleSendBackRequest}
        >
          Send
        </Button>
      ]}
    >
      <div>
        <span className="d-block mb-2">
          <strong>Revision’s Deadline</strong>
          <span className="ml-1 text-red">*</span>
        </span>
        <ReactDatetime
          isValidDate={validDate}
          inputProps={{
            placeholder: "Choose a deadline"
          }}
          value={deadline}
          onChange={value => handleDeadlineChange(value)}
          timeFormat={"HH:mm"}
          dateFormat={"DD/MM/YYYY"}
        />
      </div>
    </Modal>
  );
}
ContentSendBackModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  hideBody: PropTypes.bool,
  handleOk: PropTypes.func,
  sendBackRequest: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOnChange: PropTypes.func,
  pitch_detail: PropTypes.object,
  id: PropTypes.string,
  toggle: PropTypes.func
};
export default ContentSendBackModal;
