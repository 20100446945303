import React, { Component } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import PropTypes from "prop-types";

class ReasonModal extends Component {
  state = {
    reason: ""
  };

  componentDidMount() {}

  // eslint-disable-next-line react/no-deprecated
  //   componentWillReceiveProps(nextProps) {
  //     if (this.props.reason !== nextProps.reason) {
  //       this.setState({
  //         reason: nextProps.reason
  //       });
  //     }
  //   }

  toggle = () => {
    this.props.toggle();
  };

  render() {
    const { reason } = this.props;
    return (
      <>
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.toggle}>REASON:</ModalHeader>
          <ModalBody className="py-0">
            <p>{reason}</p>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-flex-end"></ModalFooter>
        </Modal>
      </>
    );
  }
}

ReasonModal.propTypes = {
  id: PropTypes.string,
  deleteTopic: PropTypes.func,
  toggle: PropTypes.func,
  modal: PropTypes.func,
  reason: PropTypes.string
};

ReasonModal.defaultProps = {};

export default ReasonModal;
