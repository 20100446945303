import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  header_count: {
    data: [{}]
  },
  finished_article: {
    data: []
  },
  outlines_dashboard: {},
  articles_dashboard: {},
  pitches_dashboard: {}
};

const editorDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_EDITOR_HEADER_DASHBOARD:
      state = {
        ...state,
        header_count: action.data
      };
      return { ...state };
    case Types.GET_FINISHED_ARTICLE_DASHBOARD:
      state = {
        ...state,
        finished_article: action.data
      };
      return { ...state };
    case Types.GET_OUTLINE_EDITOR_DASHBOARD:
      state = {
        ...state,
        outlines_dashboard: action.data[0]
      };
      return { ...state };
    case Types.GET_ARTICLE_EDITOR_DASHBOARD:
      state = {
        ...state,
        articles_dashboard: action.data[0]
      };
      return { ...state };
    case Types.GET_PITCH_EDITOR_DASHBOARD:
      state = {
        ...state,
        pitches_dashboard: action.data[0]
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default editorDashboardReducer;
