import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
import ChiefRejectAssignedTable from "../../../components/Tables/ChiefRejectAssignedTable";
class ChiefRejectAssigned extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Reject Assigned"
          content="rejectAssigned"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <ChiefRejectAssignedTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefRejectAssigned.propTypes = {
  content: PropTypes.string,
};
export default ChiefRejectAssigned;
