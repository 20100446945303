import * as Types from "../variables/actionTypes";

var initialState = {
  my_information: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_MY_INFORMATION:
      state = { ...state, my_information: action.data };
      return { ...state };
    default:
      return { ...state };
  }
};

export default authReducer;
