import moment from "moment";
import get from "lodash/get"
export function handleArticlePublish(res, viewMode) {
  let datasets = [
    {
      label: "VN",
      backgroundColor: "#5E72E4",
      data: [],
    },
    {
      label: "EN",
      backgroundColor: "#001DBC",
      data: [],
    },
  ];
  res.data.articles.forEach((e) => {
    e.articles.forEach((item) =>
      item.language === "VN"
        ? datasets[0].data.push(item.count)
        : datasets[1].data.push(item.count)
    );
  });
  return {
    datasets,
    labels: res.data.articles.map((e) => {
      if (viewMode === 'day') {
        return moment(e.publishDate).format("DD-MMM");
      }
      if (viewMode === 'week') {
        const start = moment().day('Monday').year(e.date.year).week(e.date.week).format('DD MMM');
        const end = moment().day('Sunday').year(e.date.year).week(e.date.week + 1).format('DD MMM');;
        return start + ' - ' + end;
      }
      if (viewMode === 'month') {
        return moment(e.publishDate).format("MMM-YYYY")  
      }
    }),
  };
}

export function handleArticleCategory(res) {
  const data = res.data.map((e) => e.count);
  const labels = res.data.map((e) => `${e.name} - ${e.count}`);
  return {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: [
          "#2DCE89",
          "#001DBC",
          "#11CDEF",
          "#5E72E4",
          "#7D8BA4",
        ],
        hoverBackgroundColor: [
          "#2DCE89",
          "#001DBC",
          "#11CDEF",
          "#5E72E4",
          "#7D8BA4",
        ],
      },
    ],
  };
}

export function handleWriter(res) {
  const temp = {
    labels: ["Expert", "Writer", "Contributor", "Editor"],
    datasets: [
      {
        data: [0,0,0,0],
        backgroundColor: ["#2DCE89", "#11CDEF", "#5E72E4", "#7D8BA4"],
        hoverBackgroundColor: ["#2DCE89", "#11CDEF", "#5E72E4", "#7D8BA4"],
      },
    ],
  }
  const data = res.data;
  data.map((item, indexOfItem)=> {
    let index = temp.labels.indexOf(get(item, '_id[0]', ""));
    temp.labels[index] = `${temp.labels[index]} - ${data[indexOfItem].count} `
    if (index >= 0) {
      temp.datasets[0].data[index] = data[indexOfItem].count;
    }
  })
  return temp;
}