import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import {
  CardHeader,
  Card,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  CardFooter,
} from "reactstrap";
import {
  actGetUnPublishedDetailByID,
  actUpdateArticleSEORequest,
} from "../../actions/chiefArticle.action";
import { connect } from "react-redux";

class SEOManager extends React.Component {
  state = {
    tab: 0,
    description: "",
    image: "",
    images: null,
    slug: "",
    tags: [],
    title: "",
  };

  componentDidMount() {
    if (this.props.details && this.props.details.seo) {
      this.setState({
        title: this.props.details.seo.title,
        image: this.props.details.seo.image,
        images: this.props.details.seo.images,
        tags: this.props.details.seo.tags,
        description: this.props.details.seo.description,
        slug: this.props.details.seo.slug,
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.details.seo !== this.props.details.seo) {
      this.setState({
        title: nextProps.details.seo.title,
        image: nextProps.details.seo.image,
        images: nextProps.details.seo.images,
        tags: nextProps.details.seo.tags,
        excerpt: nextProps.details.seo.description,
        slug: nextProps.details.seo.slug,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.details &&
      this.props.details.seo &&
      prevProps.details.seo !== this.props.details.seo
    ) {
      this.setState({
        title: this.props.details.seo.title,
        image: this.props.details.seo.image,
        images: this.props.details.seo.images,
        tags: this.props.details.seo.tags,
        description: this.props.details.seo.description,
        slug: this.props.details.seo.slug,
      });
    }
  }

  onSEOChange = (label, value) => {
    this.setState({
      [label]: value,
    });
  };

  _wrapInTag = (content) => {
    const result = `<p>${content}</p>`;
    return result;
  };

  onSubmit = () => {
    const id = this.props.details._id;
    const { description } = this.state;
    this.props.updateArticleSEORequest(id, {
      seo: {
        description: description,
      },
    });
  };

  onTabSelect = (tab) => {
    this.setState({ tab });
  };

  stripHTMLTag = (string) => {
    if (!string) {
      return "";
    }
    return string.replace(/(<([^>]+)>)/gi, "");
  };

  render() {
    // const details = this.props.details;
    const { description } = this.state;
    return (
      <Card className="seo-manager">
        <CardHeader className="d-flex justify-content-between">
          <h3>SEOs</h3>
          <h5>
            <em>
              All fields with (<span className="asterisk">*</span>) is required
            </em>
          </h5>
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.onSubmit}>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <div className="d-flex">
                    <label htmlFor="">Description</label>
                    <span className="asterisk ml-1">*</span>
                  </div>
                  <Form>
                    <Input
                      className="description"
                      value={this.stripHTMLTag(description)}
                      onChange={(e) =>
                        this.onSEOChange("description", e.target.value)
                      }
                      maxLength="160"
                      placeholder="Description"
                      rows="5"
                      type="textarea"
                    />
                    <small className="float-right">
                      {this.stripHTMLTag(description)
                        ? this.stripHTMLTag(description).length
                        : "0"}
                      /160
                    </small>
                  </Form>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter className="text-left">
          <Button onClick={this.onSubmit} type="primary">
            SAVE
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.chiefArticleReducer.unpublished_detail_by_id.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setArticleDetail: (data) => {
      dispatch(actGetUnPublishedDetailByID(data));
    },
    updateArticleSEORequest: (id, data) => {
      dispatch(actUpdateArticleSEORequest(id, data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SEOManager);

SEOManager.propTypes = {
  details: PropTypes.object,
  seo: PropTypes.object,
  updateArticleSEORequest: PropTypes.func,
};
