import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetReadersRequest = data => {
  return dispatch => {
    return callApi(
      `users/get-list-user-profile?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}:${data.number}` +
        `&search=${`order_num:${data.search}|name:${data.search}|email:${data.search}`}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetReaders(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetReaders = data => {
  return {
    type: Types.GET_READERS_CHIEF,
    data
  };
};
