import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorAssignedPostTable from "../../../components/Tables/EditorAssignedPostTable";
class EditorAssignedPost extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Assigned Briefs"
          content="assignedBriefs"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <EditorAssignedPostTable {...props} />
        </Container>
      </>
    );
  }
}
EditorAssignedPost.propTypes = {
  content: PropTypes.string,
};
export default EditorAssignedPost;
