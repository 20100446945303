import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { Modal, Divider, Spin } from "antd";
import "./styles.scss";

function ApplicationInformationModal({
  title,
  visible,
  handleOk,
  handleCancel,
}) {
  const applicationInfo = useSelector(
    (state) => state.applicationReducer.applicantInformation
  );
  const loading = useSelector((state) => state.applicationReducer.isLoading);

  const expertisesPrimary = get(
    applicationInfo,
    "writerInterestTopics[0].expertise"
  );
  const expertisesSecondary = get(
    applicationInfo,
    "writerInterestTopics[1].expertise"
  );

  if (loading) {
    return (
      <Modal
        className={`application-info-modal`}
        visible={visible}
        title={title}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <div>
          <Spin />
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      className={`application-info-modal`}
      visible={visible}
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <div>
        <Row>
          {/* Topic of interest information block */}
          <Divider className="mt-0" />
          <Col xs={12}>
            <p className="info-heading">Topic of interest</p>
            <Row>
              <Col xs={4}>
                <p className="meta-data meta-data-primary">Primary Topic</p>
              </Col>
              <Col xs={8}>
                <p>
                  {get(applicationInfo, "writerInterestTopics[0].topic.name")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Expertise</p>
              </Col>
              <Col xs={8}>
                {expertisesPrimary &&
                  expertisesPrimary.map((item, index) => {
                    return (
                      <p className="expertise" key={index}>
                        {item}
                      </p>
                    );
                  })}
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Proposed Angle</p>
              </Col>
              <Col xs={8}>
                <p>
                  {get(
                    applicationInfo,
                    "writerInterestTopics[0].suggestContent"
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data meta-data-primary">Secondary Topic</p>
              </Col>
              <Col xs={8}>
                <p>
                  {get(applicationInfo, "writerInterestTopics[1].topic.name")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Expertise</p>
              </Col>
              <Col xs={8}>
                {expertisesSecondary &&
                  expertisesSecondary.map((item, index) => {
                    return (
                      <p className="expertise" key={index}>
                        {item}
                      </p>
                    );
                  })}
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Proposed Angle</p>
              </Col>
              <Col xs={8}>
                <p>
                  {get(
                    applicationInfo,
                    "writerInterestTopics[1].suggestContent"
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data meta-data-primary">Interested Topic</p>
              </Col>
              <Col xs={8}>
                <p>{get(applicationInfo, 'interest[0].name' )}</p>
              </Col>
            </Row>
          </Col>
          <Divider />
          {/* Personal information */}
          <Col xs={12}>
            <p className="info-heading">Personal information</p>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Name</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.name}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Penname</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.requestPenname}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Email address</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.email}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Mobile number</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.phone}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Other Contact Address</p>
              </Col>
              <Col xs={8}>
                <p>
                  {applicationInfo.addtionalContactInfo &&
                    applicationInfo.addtionalContactInfo}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Education background</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.educationBackground}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Work experience</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.experience}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Residing City</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.address}</p>
              </Col>
            </Row>
          </Col>
          <Divider />
          {/* Personal details */}
          <Col xs={12}>
            <p className="info-heading">Personal details</p>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Date of Birth</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.birthday}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Interested Website</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.interestedWebsite}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">Current Employmen Status</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.workingStatus}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">School / University</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.education}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="meta-data">How do you know this program</p>
              </Col>
              <Col xs={8}>
                <p>{applicationInfo.referenceResource}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

ApplicationInformationModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  hideBody: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};
export default ApplicationInformationModal;
