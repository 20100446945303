import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
// import CardsHeaderAdmin from "../../components/Headers/CardsHeaderAdmin.jsx.js";
import CardsHeaderAdmin from "../../components/Headers/CardsHeaderAdmin";
import WriterTable from "components/Tables/WriterTable";
class Writer extends Component {
  componentDidMount() {
    this._verifyRole();
  }
  _verifyRole = () => {
    const key = localStorage.getItem("key");
    key !== "admin" && this.props.history.push("/admin");
  };
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderAdmin
          name="Writer"
          content="employee"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <WriterTable {...props} />
        </Container>
      </>
    );
  }
}
Writer.propTypes = {
  content: PropTypes.string,
};
export default Writer;
