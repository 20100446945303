import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody
  //   FormGroup,
  //   CardText
} from "reactstrap";
// import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class ViewDetailContentWritingWriterModal extends Component {
  state = {
    id: "",
    listcontent_byid: {}
  };

  // eslint-disable-next-line react/no-deprecated
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.listcontent.data.docs !== this.props.id) {
  //     this.setState({
  //       id: nextProps.id
  //     });
  //   }
  // }

  toggle = () => {
    this.props.toggle();
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  createMarkup(text) {
    return { __html: text };
  }

  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  render() {
    const { writing_content } = this.props;
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className={"modal-form"}
        >
          <ModalHeader toggle={this.props.toggle}>
            View Detail Content
          </ModalHeader>
          <ModalBody>
            <h2 className="mb-0">{writing_content.data.angle_title}</h2>
            {/* <div>{this.rawHTML(writing_content.data[0].content_content)}</div> */}
            <Row className="justify-content-center pt-4">
              <Col lg="12">
                <Card>
                  <CardBody>
                    {this.rawHTML(writing_content.data.content_content)}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    writing_content: state.writerArticleReducer.writing_content_id
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDetailContentWritingWriterModal);

ViewDetailContentWritingWriterModal.propTypes = {
  id: PropTypes.string,
  writing_content: PropTypes.object,
  detailContent: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  uploadImg: PropTypes.func
};

ViewDetailContentWritingWriterModal.defaultProps = {};
