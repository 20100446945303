import React, { useState, useEffect } from "react";
import { Card, CardHeader, Table, Col, Row, CardFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactDragListView from "react-drag-listview";
import ChiefRemoveModal from "../Modals/ChiefRemoveModal";
// redux
import ChiefAddCollectionToPinHomeModal from "../Modals/ChiefAddCollectionToPinHomeModal";
import {
  actGetPinnedCollectionRequest,
  actGetCollectionListRequest,
  actUpdateCollectionOrderRequest,
  actGetPinnedCollection,
  actResetCollectionList
} from "../../actions/chief-collection.action";

function ChiefCollectionPinToHomeTable(props) {
  const [state, setState] = useState({
    tabs: 1,
    isLoading: false,
    isRemove: false,
    isAdd: false,
    languagueKey: true,
    collectionID: "",
    params: {
      language: "VN"
    }
  });

  useEffect(() => {
    props.getPinnedCollection(state.params);
    props.getCollectionList(state.params);
  }, []);

  const toggleRemoveModal = id => {
    setState({ ...state, isRemove: !state.isRemove, collectionID: id });
  };

  const _switchLanguage = e => {
    const newLanguage = e.target.checked;
    setState({ ...state, languagueKey: newLanguage });
    if (newLanguage) {
      let params = {
        language: "VN"
      };
      props.getCollectionList(params);
      props.getPinnedCollection(params);
    } else {
      let params = {
        language: "EN"
      };
      props.getCollectionList(params);
      props.getPinnedCollection(params);
    }
  };

  const toggleAddModal = () => {
    setState({ ...state, isAdd: !state.isAdd });
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const item = props.pinned_collections.splice(fromIndex, 1)[0];
      props.pinned_collections.splice(toIndex, 0, item);
      props.resetCollectionList();
      props.setCollectionList(props.pinned_collections);
      let arr = [];
      props.pinned_collections.map((el, index) => {
        let data = {
          order_number: index + 1,
          _id: el.id
        };
        arr.push(data);
        return arr;
      });

      let data = {
        collection_id: arr
      };
      if (state.languagueKey) {
        let params = {
          language: "VN"
        };
        props.updateOrderNumber(data, params);
      } else {
        let params = {
          language: "EN"
        };
        props.updateOrderNumber(data, params);
      }
    },
    nodeSelector: "tr",
    handleSelector: "i"
  };

  const rest = 7 - props.pinned_collections.length;

  return (
    <>
      <ChiefAddCollectionToPinHomeModal
        collectionList={props.collection_list}
        isAdd={state.isAdd}
        toggle={toggleAddModal}
        languagueKey={state.languagueKey}
      />
      <ChiefRemoveModal
        title="Remove collection"
        description="Are you sure want to remove this collection from pin list?"
        modal={state.isRemove}
        toggle={toggleRemoveModal}
        collectionID={state.collectionID}
        type="pinned-collection"
        languagueKey={state.languagueKey}
      />
      <Row
        className="float-right"
        style={{ marginBottom: 20, marginTop: "-4rem" }}
      >
        <Col lg="6 text-right">
          <button
            type="button"
            className="btn-icon btn btn-secondary"
            onClick={toggleAddModal}
          >
            <span className="mr-1">
              <i className="fa fa-plus-circle" style={{ color: "#5e72e4" }}></i>
            </span>

            <span className="btn-inner--text" style={{ color: "#5e72e4" }}>
              Add Collection
            </span>
          </button>
        </Col>
      </Row>

      <Row className="feature-article-table">
        <div className="col">
          <Card>
            <CardHeader className="border-0">
              <div className="general-info-card d-flex float-right">
                <label className="custom-toggle custom-toggle-warning mr-1">
                  <input
                    checked={state.languagueKey}
                    onChange={_switchLanguage}
                    type="checkbox"
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="EN"
                    data-label-on="VN"
                  />
                </label>
              </div>
            </CardHeader>
            <div className="table-responsive">
              {props.pinned_collections &&
                props.pinned_collections.length === 0 && (
                  <div
                    className="text-center"
                    style={{ color: "#4B556F", padding: "8%" }}
                  >
                    <i className="icon-search" style={{ fontSize: "70px" }} />
                    <p>No Data</p>
                    <span
                      className="add-btn d-flex justify-content-center cursor-pointer"
                      style={{ color: "#5E72E4", fontSize: 18 }}
                      onClick={toggleAddModal}
                    >
                      <i className="icon-plus" style={{ marginRight: 6 }} />
                      <span> Add Collection</span>
                    </span>
                  </div>
                )}
              {props.pinned_collections && props.pinned_collections.length > 0 && (
                <ReactDragListView {...dragProps} lineClassName="dragline">
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          // onClick={e => _sortByColumn("angle_title")}
                        >
                          COLLECTION NAME
                        </th>
                        <th scope="col">
                          <span className="float-right">Action</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="list">
                      {props.pinned_collections &&
                        props.pinned_collections.map((prop, key) => {
                          return (
                            <>
                              <tr key={key}>
                                <th scope="row" className="flex-row-table">
                                  <i
                                    className="icon-drag"
                                    style={{
                                      cursor: "move",
                                      fontSize: "1.15rem"
                                    }}
                                  />
                                  <span
                                    style={{
                                      marginBottom: 4.5,
                                      marginLeft: 20
                                    }}
                                  >
                                    {prop.collection_id &&
                                      prop.collection_id.title}
                                  </span>
                                </th>

                                <td className="cursor-pointer">
                                  <i
                                    className="fa fa-trash-alt table-action table-action-delete float-right"
                                    onClick={() => toggleRemoveModal(prop.id)}
                                  ></i>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                </ReactDragListView>
              )}
            </div>
            {props.pinned_collections.length < 7 && (
              <CardFooter className="py-4">
                <div
                  className="more-topic d-flex justify-content-between font-weight-bold"
                  style={{ fontSize: 14 }}
                >
                  <span style={{ color: "#F5365C" }}>
                    {`Choose at least ${rest} more colletions`}
                  </span>
                  <span
                    className="add-btn d-flex justify-content-center cursor-pointer"
                    style={{ color: "#5E72E4" }}
                    onClick={toggleAddModal}
                  >
                    <i className="icon-plus" style={{ marginRight: 6 }} />
                    <span> Add collection</span>
                  </span>
                </div>
              </CardFooter>
            )}
          </Card>
        </div>
      </Row>
    </>
  );
}
const mapStateToProps = state => {
  return {
    pinned_collections: state.ChiefCollectionReducer.pinned_collections,
    collection_list: state.ChiefCollectionReducer.collection_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPinnedCollection: data => {
      dispatch(actGetPinnedCollectionRequest(data));
    },
    getCollectionList: data => {
      dispatch(actGetCollectionListRequest(data));
    },
    updateOrderNumber: (data, params) => {
      dispatch(actUpdateCollectionOrderRequest(data, params));
    },
    setCollectionList: data => {
      dispatch(actGetPinnedCollection(data));
    },
    resetCollectionList: () => {
      dispatch(actResetCollectionList());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefCollectionPinToHomeTable);
ChiefCollectionPinToHomeTable.propTypes = {
  collection_list: PropTypes.array,
  pinned_collections: PropTypes.array,
  getPinnedCollection: PropTypes.func,
  setMenuLength: PropTypes.func,
  updateOrderNumber: PropTypes.func,
  history: PropTypes.object,
  getCollectionList: PropTypes.func,
  isShowModal: PropTypes.bool,
  handleToggleModal: PropTypes.func,
  setCollectionList: PropTypes.func,
  resetCollectionList: PropTypes.func
};
ChiefCollectionPinToHomeTable.defaultProps = {};
