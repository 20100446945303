import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  myprofile_list: {
    data: {
      docs: []
    }
  },
  listKey: {
    docs: []
  }
};

const myProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_MY_PROFILE_LIST:
      state = {
        ...state,
        myprofile_list: action.data
      };
      return { ...state };
    //list key
    case Types.GET_LIST_KEY:
      state = { ...state, listKey: action.data };
      return { ...state };

    //update
    // case Types.UPDATE_MY_PROFILE:
    //   state = {
    //     ...state,
    //     myprofile_list: action.data
    //   };
    //   return { ...state };
    default:
      return { ...state };
  }
};

export default myProfileReducer;
