import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import ApplicationTable from "../../../components/Tables/ApplicationTable/ApplicationTable";

export const EditorApplicationManaging = props => {
  const location = useLocation();
  const lastPath = location.pathname.split("/");
  const status = lastPath[lastPath.length - 1];
  return (
    <>
      <CardsHeaderEditor
        name="Application Management"
        content="Application management"
        parentName="Staff"
        isDetail={true}
      />
      <Container className="mt--6" fluid>
        <ApplicationTable status={status} {...props} />
      </Container>
    </>
  );
};
EditorApplicationManaging.propTypes = {
  content: PropTypes.string,
  status: PropTypes.string
};
export default EditorApplicationManaging;
