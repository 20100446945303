import React, { useState } from "react";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem, NavLink, Col } from "reactstrap";
// components
import CardsHeaderChief from "../../components/Headers/CardsHeaderChief";
import ChiefCollectionTable from "../../components/Tables/ChiefCollectionTable";
import ChiefCollectionPinToHomeTable from "../../components/Tables/ChiefCollectionPinToHomeTable";

function CollectionManage(props) {
  const [activeTab, setActiveTab] = useState(1);

  const toggleNavs = index => {
    if (activeTab !== index) setActiveTab(index);
  };
  return (
    <div>
      <CardsHeaderChief
        name="Collection Managing"
        content="AMmainMenu"
        parentName="Appearance Managing"
        // handleToggleModal={handleToggleModal}
      />
      <Col className="p-4 over">
        <div className="nav-wrapper">
          <Nav className="overview" id="tabs-icons-text" pills role="tablist">
            <NavItem>
              <NavLink
                aria-selected={activeTab === 1}
                className={classnames("mb-sm-3 mb-md-0 tabs1", {
                  active: activeTab === 1
                })}
                onClick={() => {
                  toggleNavs(1);
                }}
                role="tab"
              >
                Collection
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={activeTab === 2}
                className={classnames("mb-sm-3 mb-md-0 tabs2", {
                  active: activeTab === 2
                })}
                onClick={() => {
                  toggleNavs(2);
                }}
                role="tab"
              >
                Pin to homepage
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <TabContent activeTab={`tabs${activeTab}`}>
          {/* TAB 1 */}
          <TabPane tabId="tabs1">
            <ChiefCollectionTable {...props} />
          </TabPane>
          {/* TAB 2 */}
          <TabPane tabId="tabs2">
            <ChiefCollectionPinToHomeTable />
          </TabPane>
        </TabContent>
      </Col>
    </div>
  );
}

export default CollectionManage;
