import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import WriterApprovedArticleTable from "../../../components/Tables/WriterApprovedArticleTable";
class WriterApprovedArticle extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeader
          name="Approved - Article"
          content="approvedarticle"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <WriterApprovedArticleTable {...props} />
        </Container>
      </>
    );
  }
}
WriterApprovedArticle.propTypes = {
  content: PropTypes.string,
};
export default WriterApprovedArticle;
