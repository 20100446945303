import React, { Component } from "react";
import { NavItem, NavLink, Nav, Col, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../components/Headers/CardsHeaderChief";
import CardAboutUs from "../../components/Cards/CardAboutUs";
import CardRecruitment from "../../components/Cards/CardRecruitment";
import CardCompanyProfile from "../../components/Cards/CardCompanyProfile";

class GeneralInformation extends Component {
  state = {
    tabs: 3
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Appearance Manage"
          content="appManage"
          //   parentName="Statistics"
          isDetail={true}
        />
        <Col className="p-4 over">
          <div className="nav-wrapper text-center">
            <Nav className="overview" id="tabs-icons-text" pills role="tablist">
              <NavItem>
                <NavLink
                  aria-selected={this.state.tabs === 1}
                  className={classnames("mb-sm-3 mb-md-0 tab1", {
                    active: this.state.tabs === 1
                  })}
                  onClick={e => this.toggleNavs(e, "tabs", 1)}
                  role="tab"
                >
                  ABOUT US
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.tabs === 2}
                  className={classnames("mb-sm-3 mb-md-0 tab2", {
                    active: this.state.tabs === 2
                  })}
                  onClick={e => this.toggleNavs(e, "tabs", 2)}
                  role="tab"
                >
                  RECRUITMENT
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.tabs === 3}
                  className={classnames("mb-sm-3 mb-md-0 tab2", {
                    active: this.state.tabs === 3
                  })}
                  onClick={e => this.toggleNavs(e, "tabs", 3)}
                  role="tab"
                >
                  COMPANY PROFILE
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={"tabs" + this.state.tabs}>
            <TabPane tabId="tabs1">
              <CardAboutUs {...props} toggleNavs={this.toggleNavs} />
            </TabPane>
            <TabPane tabId="tabs2">
              <CardRecruitment {...props} toggleNavs={this.toggleNavs} />
            </TabPane>
            <TabPane tabId="tabs3">
              <CardCompanyProfile {...props} toggleNavs={this.toggleNavs} />
            </TabPane>
          </TabContent>
        </Col>
      </>
    );
  }
}
GeneralInformation.propTypes = {
  content: PropTypes.string
};
export default GeneralInformation;
