import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderAdmin from "../../components/Headers/CardsHeaderAdmin";
import EditorAndChiefeditorPastTable from "../../components/Tables/EditorAndChiefeditorPastTable";
class EditorAndChiefeditorPast extends Component {
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderAdmin
          name="Editor And Chiefeditor Past"
          content="employee"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <EditorAndChiefeditorPastTable {...props} />
        </Container>
      </>
    );
  }
}
EditorAndChiefeditorPast.propTypes = {
  content: PropTypes.string,
};
export default EditorAndChiefeditorPast;
