import React, { Component } from "react";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { get } from "lodash";
import { Card, CardHeader, CardBody, Button, Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ContentRejectModal from "../Modals/ContentRejectModal";
import ContentApproveModal from "../Modals/ContentApproveModal";
import { actGetWritingContentByIDRequest } from "../../actions/writerArticle.action";
import { actGetFeedbackListRequest } from "../../actions/feedback.action";
import Feedback from "../Feedback/Feedback";
import CustomEditor from "../TinyMCE";
import ContentSendBackModal from "../Modals/ContentSendBackModal";
import VersionSelect from "../Selects/VersionSelect";
import ReactHtmlParser from "react-html-parser";
import { actUpdateArticleDeadlineRequest } from "../../actions/application.action";

class EditorContentRequestTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleDeadline: false,
      newDeadline: "",
      tabs: 1,
      reply: false,
      isReject: false,
      isApprove: false,
      isSendBack: false,
      id: window.location.pathname.split("/")[4],
      data: {
        content: ""
      }
    };
  }

  componentDidMount() {
    this._setLoading();
    this._getWritingContentByID();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.writing_content !== this.props.writing_content) {
      this.setState({
        data: {
          content:
            nextProps.writing_content.data[0] &&
            nextProps.writing_content.data[0].content_content
        },
        isVerifying:
          nextProps.writing_content.data[0] &&
          nextProps.writing_content.data[0].status_article ===
            "CONTENT_SUBMITTED"
      });
      this.props.getFeedbackList(nextProps.writing_content.data[0].outlineID);
    }
  }

  _getWritingContentByID = () => {
    this.props.getWritingContentByID(this.state.id);
  };

  _toggleModalReject = () => {
    this.setState(prevState => ({
      isReject: !prevState.isReject
    }));
  };

  _toggleModalApprove = () => {
    this.setState(prevState => ({
      isApprove: !prevState.isApprove
    }));
  };

  _toggleModalSendBack = () => {
    this.setState(prevState => ({
      isSendBack: !prevState.isSendBack
    }));
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _handleOnClickReply = () => {
    this.setState(prevState => ({
      reply: !prevState.reply
    }));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        content: value
      },
      autoSave: true
    });
  };

  _handleOnVersionSelect = index => {
    const url = `/admin/chief/content-version/${this.state.id}?version=${index}`;
    window.open(url, "_blank");
  };
  handleToggleDeadline = () => {
    this.setState(prevState => ({
      toggleDeadline: !prevState.toggleDeadline
    }));
  };

  handleDeadlineChange = value => {
    this.setState({
      newDeadline: value
    });
  };
  handleExtendDeadline = () => {
    const { writing_content, updateArticleDeadline } = this.props;
    const { newDeadline } = this.state;
    const data = {
      dead_line: newDeadline
    };
    const articleId = get(writing_content, "data[0]._id");
    updateArticleDeadline(articleId, data);
    this.handleToggleDeadline();
  };

  render() {
    const { writing_content, content_versions } = this.props;
    const { isVerifying } = this.state;
    const key = localStorage.getItem("key");
    const validDate = current => {
      var yesterday = moment().subtract(1, "day");
      return current.isAfter(yesterday);
    };
    const articleStatus = get(writing_content, "data[0].status_article");
    const allowExtend =
      key !== "writer" && articleStatus !== "CONTENT_SUBMITTED";

    return (
      <>
        <ContentRejectModal
          visible={this.state.isReject}
          toggle={this._toggleModalReject}
          id={this.state.id}
        />
        <ContentApproveModal
          modal={this.state.isApprove}
          toggle={this._toggleModalApprove}
          id={this.state.id}
          content={this.state.data.content}
        />
        <ContentSendBackModal
          visible={this.state.isSendBack}
          toggle={this._toggleModalSendBack}
          id={this.state.id}
          content={this.state.data.content}
        />
        <Row>
          <Col xl="9">
            <Card style={{ marginTop: "19px" }}>
              <CardHeader className="border-0 d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <h3 className="mb-4">Text Editor</h3>
                  <div className="d-flex align-items-center">
                    {!this.state.toggleDeadline ? (
                      <>
                        <div className="mb-0">
                          Article Deadline:{" "}
                          <strong>
                            {moment(
                              get(writing_content, "data[0].dead_line")
                            ).format("MMMM Do YYYY")}
                          </strong>
                        </div>

                        {allowExtend && (
                          <Button
                            className="ml-3"
                            color="success"
                            outline
                            type="button"
                            size="sm"
                            onClick={this.handleToggleDeadline}
                          >
                            Extend
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <ReactDatetime
                          isValidDate={validDate}
                          inputProps={{
                            placeholder: "Choose new deadline"
                          }}
                          value={this.state.newDeadline}
                          onChange={value => this.handleDeadlineChange(value)}
                          timeFormat={"HH:mm"}
                          dateFormat={"DD/MM/YYYY"}
                        />
                        <Button
                          className="ml-3"
                          color="success"
                          outline
                          type="button"
                          size="sm"
                          onClick={this.handleExtendDeadline}
                        >
                          Save
                        </Button>
                        <Button
                          className="ml-3"
                          color="danger"
                          outline
                          type="button"
                          size="sm"
                          onClick={this.handleToggleDeadline}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <VersionSelect
                  versions={content_versions}
                  onVersionSelect={this._handleOnVersionSelect}
                />
              </CardHeader>
              <CardBody>
                <div className="pb-4">
                  <h2>
                    {writing_content.data &&
                      writing_content.data[0] &&
                      ReactHtmlParser(writing_content.data[0].angle_title)}
                  </h2>
                  <h5 className="text-primary">
                    <a
                      target="blank"
                      href={
                        key === "chiefeditor"
                          ? `/admin/chief/outline-content/${this.state.id}`
                          : `/admin/editor/outline-content/${this.state.id}`
                      }
                    >
                      {"View outline >>"}
                    </a>
                  </h5>
                </div>
                <Form>
                  {isVerifying && isVerifying === true ? (
                    <CustomEditor
                      // enableComment={true}
                      toolbar="false"
                      menubar="false"
                      content={this.state.data.content}
                      onChangeContent={this._handleOnContentChange}
                      readOnly={true}
                    />
                  ) : (
                    <p className="font-italic">Writer is writing...</p>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3" className="toolbar-right">
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                color="warning"
                size="lg"
                style={{ width: "50%" }}
                onClick={() => this._toggleModalSendBack()}
                disabled={!this.state.isVerifying}
              >
                Send Back
              </Button>
              <Button
                type="button"
                color="success"
                size="lg"
                style={{ width: "40%" }}
                onClick={() => this._toggleModalApprove()}
                disabled={!this.state.isVerifying}
              >
                Approve
              </Button>
              <Button
                type="button"
                size="lg"
                color="danger"
                style={{ width: "35%" }}
                onClick={() => this._toggleModalReject()}
                disabled={!this.state.isVerifying}
              >
                Reject
              </Button>
            </div>
            <Feedback
              article_id={this.state.id}
              outline_id={
                writing_content.data[0] && writing_content.data[0].outlineID
              }
            />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writing_content: state.writerArticleReducer.writing_content_id,
    content_versions: state.writerArticleReducer.content_versions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getWritingContentByID: id => {
      dispatch(actGetWritingContentByIDRequest(id));
    },
    getFeedbackList: outlineId => {
      dispatch(actGetFeedbackListRequest(outlineId));
    },
    updateArticleDeadline: (id, data) => {
      dispatch(actUpdateArticleDeadlineRequest(id, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContentRequestTable);
EditorContentRequestTable.propTypes = {
  writing_content: PropTypes.object,
  content_versions: PropTypes.object,
  getWritingContentByID: PropTypes.func,
  getFeedbackList: PropTypes.func,
  updateArticleDeadline: PropTypes.func
};
EditorContentRequestTable.defaultProps = {};
