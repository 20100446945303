import React, { Component } from "react";
import { connect } from "react-redux";
import COMING_SOON from "assets/img/coming_soon.png";

class ComingSoon extends Component {
  render() {
    return (
      <div
        className="comingSoon"
        style={{ backgroundImage: `url(${COMING_SOON})` }}
      ></div>
    );
  }
}
export default connect(
  null,
  null
)(ComingSoon);
