import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import PublishedArticlesTable from "../../../components/Tables/PublishedArticlesTable";
class PublishedArticles extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Completed Articles"
          content="publishedArticles"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <PublishedArticlesTable {...props} />
        </Container>
      </>
    );
  }
}
PublishedArticles.propTypes = {
  content: PropTypes.string,
};
export default PublishedArticles;
