import React from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actRemovePinnedCollectionRequest } from "../../actions/chief-collection.action";

function ChiefRemoveModal(props) {
  const removeSellectedID = () => {
    const { type, collectionID, languagueKey } = props;
    if (type === "pinned-collection") {
      if (languagueKey) {
        let params = {
          language: "VN"
        };
        props.removePinnedCollection(collectionID, params);
      } else {
        let params = {
          language: "EN"
        };
        props.removePinnedCollection(collectionID, params);
      }
    }
    if (type === "collection") {
      props.handleRemoveArticle();
    }
    if (type === "removeCollection") {
      props.handleDeleteCollection();
    }
    if (type === "feature-articles") {
      props.handleRemoveFeatureArticle();
    }
    props.toggle();
  };

  return (
    <Modal isOpen={props.modal} className="modal-dialog-centered">
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody className="p-4">{props.description}</ModalBody>
      <ModalFooter className="d-flex justify-content-flex-end p-3">
        <Button
          onClick={removeSellectedID}
          color="link"
          style={{ color: "#172B4D" }}
        >
          YES
        </Button>
        <Button
          color="link"
          style={{ color: "#fb6340" }}
          onClick={props.toggle}
        >
          NO
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    removePinnedCollection: (id, params) => {
      dispatch(actRemovePinnedCollectionRequest(id, params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ChiefRemoveModal);

ChiefRemoveModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  alert: PropTypes.any,
  id: PropTypes.string,
  collectionID: PropTypes.string,
  type: PropTypes.string,
  removePinnedCollection: PropTypes.func,
  handleRemoveArticle: PropTypes.func,
  handleDeleteCollection: PropTypes.func,
  languagueKey: PropTypes.bool
};

ChiefRemoveModal.defaultProps = {};
