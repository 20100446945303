import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorAvailableBriefsTable from "../../../components/Tables/EditorAvailableBriefsTable";
class EditorAvailableRequest extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Available Briefs"
          content="briefsAvailable"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <EditorAvailableBriefsTable {...props} />
        </Container>
      </>
    );
  }
}
EditorAvailableRequest.propTypes = {
  content: PropTypes.string,
};
export default EditorAvailableRequest;
