import { TreeSelect } from "antd";
import React, { Component } from "react";
import PropTypes from "prop-types";
const TreeNode = TreeSelect.TreeNode;

class MultiCheckboxSelect extends Component {
  state = {
    isReplyInputShow: false
  };

  render() {
    const { options, value, onChange, searchPlaceholder } = this.props;
    return (
      <div className="checkbox-form">
        <TreeSelect
          value={value}
          onChange={onChange}
          treeNodeFilterProp="title"
          treeCheckable
          allowClear
          showSearch
          showCheckedStrategy="SHOW_PARENT"
          searchPlaceholder={searchPlaceholder}
          size="large"
          style={{ width: "100%" }}
        >
          {options.map((item, key) => (
            <TreeNode key={key} title={item.name} value={item._id}></TreeNode>
          ))}
        </TreeSelect>
      </div>
    );
  }
}

export default MultiCheckboxSelect;
MultiCheckboxSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  searchPlaceholder: PropTypes.string
};
MultiCheckboxSelect.defaultProps = {};
