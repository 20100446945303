import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import ChiefAvailableBriefsTable from "../../../components/Tables/ChiefAvailableBriefsTable";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
// import EditorAvailableBriefsTable from "../../components/Tables/EditorAvailableBriefsTable";
class ChiefAvailableBrief extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Available Briefs"
          content="briefsAvailable"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <ChiefAvailableBriefsTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefAvailableBrief.propTypes = {
  content: PropTypes.string,
};
export default ChiefAvailableBrief;
