import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Table, Row, Col, CardBody, CardTitle } from "reactstrap";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
class Chart extends Component {
  state = {
    dashboard_article: [
      {
        number: "700",
        content: "PUBLISHED",
      },
      {
        number: "12",
        content: "ARTICLES UNDER",
      },
      {
        number: "24",
        content: "TOPICS",
      },
      {
        number: "50",
        content: "AVAILABLE BRIEFS",
      },
      {
        number: "10",
        content: "OF ARTICLE S PUBLISHED IN THE LAST 7 DAYS",
      },
    ],
  };
  componentDidMount = () => {
    this._chart1();
    this._chart2();
    this._chart3();
  };
  _chart1 = () => {
    let chart1 = am4core.create("chartdiv1", am4charts.PieChart);
    let pieSeries = chart1.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "article";
    pieSeries.dataFields.category = "type";
    chart1.innerRadius = am4core.percent(30);
    chart1.fontSize = 10;
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer",
      },
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;
    let shadow = pieSeries.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;
    let hoverState = pieSeries.slices.template.states.getKey("hover");

    let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;
    chart1.data = [
      {
        type: "Original",
        article: 363,
        color: am4core.color("#F44234"),
      },
      {
        type: "Sponsored",
        article: 220,
        color: am4core.color("#4051B5"),
      },
      {
        type: "Branded",
        article: 117,
        color: am4core.color("#00A9F4"),
      },
    ];
    pieSeries.slices.template.propertyFields.fill = "color";
  };
  _chart2 = () => {
    let chart2 = am4core.create("chartdiv2", am4charts.PieChart);
    let pieSeries = chart2.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "article";
    pieSeries.dataFields.category = "type";
    chart2.innerRadius = am4core.percent(30);
    chart2.fontSize = 10;
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer",
      },
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;
    let shadow = pieSeries.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;
    let hoverState = pieSeries.slices.template.states.getKey("hover");
    let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;
    chart2.data = [
      {
        type: "Guide",
        article: 333,
        color: am4core.color("#26324F"),
      },
      {
        type: "Profiling",
        article: 175,
        color: am4core.color("#D5423D"),
      },
      {
        type: "P/S Article",
        article: 140,
        color: am4core.color("#EF5540"),
      },
      {
        type: "Report",
        article: 102,
        color: am4core.color("#F47E70"),
      },
      {
        type: "Thought",
        article: 50,
        color: am4core.color("#FCD3CE"),
      },
    ];
    pieSeries.slices.template.propertyFields.fill = "color";
  };
  _chart3 = () => {
    let chart3 = am4core.create("chartdiv", am4charts.PieChart);
    let pieSeries = chart3.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "article";
    pieSeries.dataFields.category = "type";
    chart3.innerRadius = am4core.percent(30);
    chart3.fontSize = 10;
    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer",
      },
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;

    // Create a base filter effect (as if it's not there) for the hover to return to
    let shadow = pieSeries.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;

    // Create hover state
    let hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists
    // Slightly shift the shadow and make it more prominent on hover
    let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;
    // Add a legend
    // chart.legend = new am4charts.Legend();
    chart3.data = [
      {
        type: "Lithuania",
        article: 501.9,
        color: am4core.color("#67B7DC"),
      },
      {
        type: "UK",
        article: 99,
        color: am4core.color("#DB6888"),
      },
      {
        type: "Belgium",
        article: 60,
        color: am4core.color("#DC8C67"),
      },
    ];
    pieSeries.slices.template.propertyFields.fill = "color";
  };
  render() {
    const dashboard_article = this.state.dashboard_article;
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <div className="table-responsive">
                <Table
                  className="align-items-center table-flush"
                  style={{
                    border: "0px",
                    overflow: "hidden",
                    marginBottom: "20px",
                  }}
                >
                  <Row>
                    {dashboard_article.map((prop, key) => {
                      return (
                        <Col key={key} className="chart_dashboard">
                          <Card style={{ boxShadow: "none" }}>
                            <CardBody className="chart_content">
                              <div className="">
                                <CardTitle className="chart_content_h1">
                                  {prop.number}
                                </CardTitle>
                                <span className="chart_content_h2">
                                  {prop.content}
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col>
                      <div
                        id="chartdiv1"
                        style={{ width: "100%", height: "288px" }}
                      ></div>
                    </Col>
                    <Col>
                      <div
                        id="chartdiv2"
                        style={{ width: "100%", height: "288px" }}
                      ></div>
                      <p className="chart_bottom">TYPE</p>
                    </Col>
                    <Col>
                      <div
                        id="chartdiv"
                        style={{ width: "100%", height: "288px" }}
                      ></div>
                      <p className="chart_bottom">FORMAT</p>
                    </Col>
                  </Row>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
export default connect(
  null,
  null
)(Chart);
