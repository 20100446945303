import React, { Component } from "react";
import { Card, CardHeader, Table, Row } from "reactstrap";
import { Tooltip } from "antd";
import Select from "react-select";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { countTimeDeadline } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import Avatar from "../Avatar/Avatar";
import { actGetArticleEditorDashboardRequest } from "../../actions/editordashboard.action";
import { Link } from "react-router-dom";
import CardBodyWithLoading from "../CardBodyWithLoading/CardBody";
import ReactHtmlParser from "react-html-parser";

const filterOption = [
  {
    label: "Waiting for Approval",
    value: "CONTENT_SUBMITTED"
  },
  {
    label: "Needs Editing",
    value: "EDITING"
  }
];

class EditorArticleDashboadTable extends Component {
  state = {
    params: {
      status_article: getUrlParam()["status_article"]
        ? getUrlParam()["status_article"]
        : "CONTENT_SUBMITTED",
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 5,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "updatedAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getArticleDashboard();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 2000);
  };

  _getArticleDashboard = () => {
    const params = this.state.params;
    this.props.getArticleDashboard(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this.props.getArticleDashboard(paramsSort);
  };

  _handleOnSelectChange = (label, value) => {
    let params = this.state.params;
    this.setState({
      ...this.state,
      params: {
        ...params,
        [label]: value
      }
    });
    const paramsFilter = {
      ...params,
      [label]: value
    };
    this._setLoading();
    this.props.getArticleDashboard(paramsFilter);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/editor/content-request/${id}`);
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 80) {
      return `${str[0].substring(0, 80)}...`;
    } else {
      return str[0].substring(0, 80);
    }
  };

  render() {
    const { articles_dashboard } = this.props;
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="header-scroll border-0">
                <Row className="header-top">
                  <div className="table-header">
                    <h3 className="text-header mb-0">Articles</h3>
                  </div>

                  <div className="header-info">
                    <span className="info-approval">
                      Waiting for Approval
                      <p className="__view_more">
                        <Link to={"/admin/editor/article-in-progress"}>
                          View more
                        </Link>
                      </p>
                    </span>
                    <span className="info-number">
                      {articles_dashboard.waitingForApproval}
                    </span>
                  </div>
                  <div className="header-info">
                    <span className="info-approval">
                      Needs Editing
                      <p className="__view_more">
                        <Link to={"/admin/editor/article-editing"}>
                          View more
                        </Link>
                      </p>
                    </span>
                    <span className="info-number">
                      {articles_dashboard.needsEditing}
                    </span>
                  </div>
                </Row>
                <div className="filter-box">
                  <span className="info-approval">Filter: </span>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={filterOption.filter(
                      ({ value }) => value === this.state.params.status_article
                    )}
                    options={filterOption}
                    onChange={value =>
                      this._handleOnSelectChange("status_article", value.value)
                    }
                  />
                </div>
              </CardHeader>
              <CardBodyWithLoading
                isLoading={this.state.isLoading}
                className="p-0"
              >
                <div className="table-responsive" id="__scrollbar-table">
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Title</th>
                        <th>Writer</th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("end_date")}
                        >
                          Deadline
                        </th>
                        <th className="text-center">Dual Language</th>
                        <th>Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {articles_dashboard.docs &&
                        articles_dashboard.docs.length !== 0 &&
                        articles_dashboard.docs.map((prop, key) => {
                          return (
                            <>
                              <tr
                                key={key}
                                style={{ cursor: "pointer" }}
                                onClick={e =>
                                  this._handleOnCellClick(e, prop._id)
                                }
                              >
                                <th scope="row" style={{ width: "40%" }}>
                                  <Tooltip
                                    title={ReactHtmlParser(prop.angle_title)}
                                    placement="bottom"
                                  >
                                    <span>
                                      {this.limitTitle(
                                        ReactHtmlParser(prop.angle_title)
                                      )}
                                    </span>
                                  </Tooltip>
                                </th>
                                <td className="table-user">
                                  <Avatar
                                    src={prop.writer ? prop.writer.avatar : ""}
                                    className="d-inline-block mr-3"
                                  />
                                  <b>
                                    {this._text_truncate(
                                      prop.writer.name,
                                      40,
                                      "..."
                                    )}
                                  </b>
                                </td>
                                <td className="text-danger font-weight-bold">
                                  {countTimeDeadline(prop.end_date)}
                                </td>
                                <td className="text-center text-success">
                                  {prop.dualLanguage !== null &&
                                    prop.dualLanguage && (
                                      <i
                                        className="fa fa-check-circle"
                                        style={{ fontSize: 16 }}
                                      ></i>
                                    )}
                                </td>
                                <td>
                                  {prop
                                    ? prop.topic.map(topic => {
                                      return (
                                        <>
                                          <div className="db_topic">
                                            <label>
                                              {this._text_truncate(
                                                topic.name
                                                  ? topic.name
                                                  : topic.label,
                                                20,
                                                "..."
                                              )}
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })
                                    : ""}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                  {articles_dashboard &&
                    articles_dashboard.docs &&
                    articles_dashboard.docs.length === 0 && (
                      <div
                        className="text-center"
                        style={{ color: "#4B556F", padding: "5%" }}
                      >
                        <i
                          className="icon-search"
                          style={{ fontSize: "70px" }}
                        />
                        <p>No Data</p>
                      </div>
                    )}
                </div>
              </CardBodyWithLoading>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    articles_dashboard: state.editorDashboardReducer.articles_dashboard
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getArticleDashboard: params => {
      dispatch(actGetArticleEditorDashboardRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorArticleDashboadTable);
EditorArticleDashboadTable.propTypes = {
  articles_dashboard: PropTypes.object,
  getArticleDashboard: PropTypes.func,
  history: PropTypes.any
};
EditorArticleDashboadTable.defaultProps = {};
