import React, { Component } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  actGetUpdateAboutUsRequest,
  actGetUpdateRecruitmentRequest,
  actGetUpdateCompanyProfileRequest
} from "../../actions/general-info.action";

class GeneralInfoModal extends Component {
  state = {};

  toggle = () => {
    this.props.toggle();
  };

  _handleOnPublish = () => {
    const {
      type,
      aboutDetail,
      recruitmentDetail,
      profileDetail,
      languagueKey
    } = this.props;
    if (type === "about") {
      let dataAbout = {
        key: "About_key",
        key_language: languagueKey ? "VN" : "EN",
        details: aboutDetail
      };
      this.props.updateAboutUs(dataAbout);
    }
    if (type === "recruitment") {
      let dataRecruit = {
        key: "Recruitment_key",
        key_language: languagueKey ? "VN" : "EN",
        details: recruitmentDetail
      };
      this.props.updateRecruitment(dataRecruit);
    }
    if (type === "profile") {
      let dataProfile = {
        key: "Profile_key",
        key_language: languagueKey ? "VN" : "EN",
        details: {
          CompanyProfile: profileDetail
        }
      };
      this.props.updateCompanyProfile(dataProfile);
    }
    this.toggle();
  };

  render() {
    const { title, message } = this.props;
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.props.toggle}>{title}</ModalHeader>
          <ModalBody className="p-4">{message}</ModalBody>
          <ModalFooter className="d-flex justify-content-flex-end p-3">
            <Button
              onClick={this.toggle}
              color="link"
              style={{ color: "#172B4D" }}
            >
              CANCEL
            </Button>
            <Button
              color="link"
              style={{ color: "#fb6340" }}
              onClick={this._handleOnPublish}
            >
              PUBLISH
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateAboutUs: data => {
      dispatch(actGetUpdateAboutUsRequest(data));
    },
    updateRecruitment: data => {
      dispatch(actGetUpdateRecruitmentRequest(data));
    },
    updateCompanyProfile: data => {
      dispatch(actGetUpdateCompanyProfileRequest(data));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(GeneralInfoModal);

GeneralInfoModal.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  languagueKey: PropTypes.bool,
  updateAboutUs: PropTypes.func,
  updateRecruitment: PropTypes.func,
  updateCompanyProfile: PropTypes.func,
  toggle: PropTypes.func,
  modal: PropTypes.func,
  aboutDetail: PropTypes.object,
  recruitmentDetail: PropTypes.object,
  profileDetail: PropTypes.object
};

GeneralInfoModal.defaultProps = {};
