import React, { Component } from "react";
// import { Typography } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import get from "lodash/get";
import callApi from "../../utils/callApi";
import HOST_URL from "../../variables/hostUrl";
import { TINY_MCE_KEY } from "../../variables/hostUrl";
import { tinyMCEConfig } from "./config";
import { defaultToolbar } from "../../utils/constants";
class CustomEditor extends Component {
  state = {
    characters: 0,
  };
  handleEditorChange = (content, editor) => {
    // const wordcount = editor.plugins.wordcount;
    const html = editor.getContent();
    this.props.onChangeContent(html);
  };

  render() {
    const {
      enableComment,
      readOnly,
      toolbar,
      menubar,
      content,
      id,
      menu,
    } = this.props;
    return (
      <>
        <Editor
          id={id}
          value={content}
          apiKey={TINY_MCE_KEY}
          init={{
            ...tinyMCEConfig,
            readonly: readOnly ? 1 : 0,
            setup: function(ed) {
              ed.on("init", () => {
                if (readOnly) {
                  ed.setMode("readonly");
                }
              });
              ed.on("NodeChange", function(e) {
                if (e) {
                  const imgMode = get(e.element, "childNodes[0].className");
                  if (e.element.tagName === "FIGURE") {
                    e.element.className = imgMode;
                    e.element.setAttribute("class", imgMode);
                  }
                }
              });
              // handle in-text comment
            },
            menu: menu,
            menubar: `${menubar || "edit insert format tools view"}`,
            toolbar: `${toolbar || defaultToolbar} | ${enableComment &&
              "annotate-alpha"}`,
            images_upload_handler: function(blobInfo, success) {
              const formData = new FormData();
              formData.append("file", blobInfo.blob(), blobInfo.filename());
              callApi("v2/images/no-resize", "POST", formData, true).then(
                (res) => {
                  const image = HOST_URL + res.data.url;
                  success(image);
                }
              );
            },
          }}
          onEditorChange={this.handleEditorChange}
        />
      </>
    );
  }
}

export default CustomEditor;

// ed.ui.registry.addButton("annotate-alpha", {
//   text: "Comment",
//   onAction: function() {
//     ed.windowManager.open({
//       title: "In-text Comment",
//       body: {
//         type: "panel",
//         items: [
//           {
//             type: "input",
//             name: "comment",
//             label: "Enter your comment",
//           },
//         ],
//       },
//       buttons: [
//         {
//           type: "cancel",
//           name: "closeButton",
//           text: "Cancel",
//         },
//         {
//           type: "submit",
//           name: "submitButton",
//           text: "Save",
//           primary: true,
//         },
//       ],
//       onSubmit: function(api) {
//         const data = api.getData();
//         ed.annotator.annotate("alpha", {
//           comment: data.comment,
//         });
//         api.close();
//       },
//     });
//   },
// });
// ed.on("init", () => {
//   if (readOnly) {
//     ed.setMode("readonly");
//   }
//   ed.annotator.register("alpha", {
//     persistent: true,
//     decorate: function(uid, data) {
//       return {
//         attributes: {
//           "data-mce-comment": data.comment ? data.comment : "",
//           "data-mce-author": data.author ? data.author : "anonymous",
//         },
//       };
//     },
//   });
//   ed.annotator.annotationChanged("alpha", (state, name, obj) => {
//     if (state) {
//       ed.windowManager.open({
//         title: "In-text Comment",
//         body: {
//           type: "panel",
//           items: [
//             {
//               type: "input",
//               label: "Author",
//               name: "author",
//               disabled: true,
//               borderless: false,
//             },
//             {
//               type: "textarea",
//               name: "comment",
//               label: "Enter your comment",
//             },
//           ],
//         },
//         initialData: {
//           author: authorName,
//           comment: get(obj, "nodes[0].dataset.mceComment"),
//         },
//         buttons: [
//           {
//             type: "cancel",
//             name: "closeButton",
//             text: "Cancel",
//           },
//           {
//             type: "submit",
//             name: "submitButton",
//             text: "Save",
//             primary: true,
//           },
//           {
//             type: "custom",
//             name: "deleteComment",
//             text: "Delete",
//             align: "start",
//           },
//         ],
//         onAction: function(api) {
//           ed.annotator.remove("alpha");
//           api.close();
//         },
//         onSubmit: function(api) {
//           const data = api.getData();
//           ed.annotator.annotate("alpha", {
//             comment: data.comment,
//           });
//           ed.focus();
//           api.close();
//         },
//       });
//     }
//   });
// });
