import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorPitchDetail from "../../../components/Detail/EditorPitchDetail";
class EditorPitches extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Pitch Detail"
          content="pitchDetail"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <EditorPitchDetail {...props} />
        </Container>
      </>
    );
  }
}
EditorPitches.propTypes = {
  content: PropTypes.string,
};
export default EditorPitches;
