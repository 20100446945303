import React, { memo } from "react";
import { Form, Input, Row, Col, Select, DatePicker, Radio } from "antd";
import PropTypes from "prop-types";
import UploadAvatar from "../Upload/UploadAvatar";
import { Card, CardHeader, CardBody, Container } from "reactstrap";
import moment from "moment";
import get from "lodash/get";
import isEmpty from "lodash/get";
import { SwitchComponent } from "./ult";
import CustomEditor from "../TinyMCE";
import Social from "../Item";
const { Option } = Select;
const getDateFormat = employee_profile => {
  var d = moment(get(employee_profile, "birthday"), "DD-MM-YYYY");
  return d.isValid() ? d : "";
};
// const { TextArea } = Input;
class Information extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      avatar: "",
      longShortBio: "",
      shortBio: "",
      form: {}
    };
  }
  onChangeUpload = data => {
    this.setState({
      avatar: data.url
    });
  };
  _goBack = () => {
    // eslint-disable-next-line react/prop-types
    this.props.history.goBack();
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.employee_profile !== this.props.employee_profile) {
      this.setState({
        avatar: nextProps.employee_profile.avatar,
        longShortBio: nextProps.employee_profile.longShortBio,
        shortBio: nextProps.employee_profile.shortBio
      });
    }
  }

  _handleSubmit = e => {
    e.preventDefault();
    let self = this;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.avatar !== "") {
          values.avatar = this.state.avatar;
        }
        if (this.state.longShortBio !== "") {
          values.longShortBio = this.state.longShortBio;
        }
        if (this.state.shortBio !== "") {
          values.shortBio = this.state.shortBio;
        }
        self.props.handleSubmit(values);
      }
    });
  };
  initValues = (employee_profile, e) => {
    if (e.props === "interest") {
      return get(employee_profile, "interest[0].name");
    }
    return get(employee_profile, e.props);
  };
  render() {
    const { employee_profile } = this.props;
    const { getFieldDecorator } = this.props.form;
    const PersonalInformation = this.props.config.options.map(e => {
      return (
        <Col span={e.span} offset={e.offet}>
          <Form.Item label={e.label}>
            {e.props === "birthday"
              ? getFieldDecorator(e.props, {
                  rules: e.rules,
                  initialValue: getDateFormat(employee_profile)
                })(<DatePicker showTime format="DD-MM-YYYY" />)
              : getFieldDecorator(e.props, {
                  rules: e.rules,
                  initialValue: get(employee_profile, e.props)
                })(SwitchComponent(e, this.props.listTopic))}
          </Form.Item>
        </Col>
      );
    });
    const OrtherInformation = this.props.config.options1.map(e => {
      return (
        <Col span={e.span} offset={e.offet}>
          <Form.Item label={e.label}>
            {e.props === "date_joined"
              ? getFieldDecorator(e.props, {
                  rules: e.rules,
                  initialValue: moment(
                    get(employee_profile, "date_joined", moment()),
                    "DD-MM-YYYY"
                  )
                })(<DatePicker showTime format="DD-MM-YYYY" />)
              : getFieldDecorator(e.props, {
                  rules: e.rules,
                  initialValue: this.initValues(employee_profile, e)
                })(SwitchComponent(e, this.props.listTopic))}
          </Form.Item>
        </Col>
      );
    });
    return (
      <Container className="mt--6 view_profile" fluid>
        <Card className="mb-4">
          <CardHeader className="card-information">
            <h3 className="mb-0">Staff Information</h3>
          </CardHeader>
          <CardBody style={{ marginTop: "-30px" }}>
            <br />
            <Form layout="horizontal" onSubmit={this._handleSubmit}>
              <p className="information-title">PERSONAL INFORMATION</p>
              <div>
                <Row>
                  <Col span={6}>
                    <div className="ant-col ant-form-item-label">
                      <label>Avatar</label>
                    </div>
                    <UploadAvatar
                      setAvatar={this.onChangeUpload}
                      avatar={this.state.avatar}
                    />
                  </Col>
                  <Col span={18}>
                    <Row gutter={[24, 16]}>
                      <Col span={12}>
                        <Form.Item label="Penname">
                          {getFieldDecorator("penname", {
                            rules: [
                              {
                                required: true,
                                message: "Please input your Penname!"
                              }
                            ],
                            initialValue: get(employee_profile, "penname")
                          })(<Input placeholder="Penname" />)}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Title">
                          {getFieldDecorator("titleOfUser", {
                            rules: [
                              {
                                required: true,
                                message: "Please input your title!"
                              }
                            ],
                            initialValue: get(employee_profile, "titleOfUser")
                          })(
                            <Select>
                              <Option value="Writer" key="Writer">
                                Writer
                              </Option>
                              <Option value="Editor" key="Editor">
                                Editor
                              </Option>
                              <Option value="Contributor" key="Contributor">
                                Contributor
                              </Option>
                              <Option value="Expert" key="Expert">
                                Expert
                              </Option>
                              <Option value="Brand" key="Brand">
                                  Brand
                              </Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="Short Bio">
                      <CustomEditor
                        id="short-bio"
                        limit={160}
                        toolbar="link"
                        menubar="false"
                        content={get(this.state, "shortBio", "")}
                        onChangeContent={value =>
                          this.setState({ shortBio: value })
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Long Bio">
                      <CustomEditor
                        id="long-bio"
                        toolbar="link"
                        menubar="false"
                        content={get(this.state, "longShortBio", "")}
                        onChangeContent={value =>
                          this.setState({ longShortBio: value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <p className="information-title">Social Network</p>
              <Row>
                <Social
                  form={this.props.form}
                  employee_profile={this.props.employee_profile}
                />
              </Row>
              <Row>{PersonalInformation}</Row>
              <p className="information-title">ORTHER INFORMATION</p>
              <Row>
                {OrtherInformation}
                <Col span={11} offset={1}>
                  <Row>
                    <Col span={11} offset={1}>
                      <Form.Item label="Status">
                        {getFieldDecorator("status", {
                          initialValue: get(employee_profile, "status")
                        })(
                          <Radio.Group>
                            <Radio value="WORKING">Employee</Radio>
                            <Radio value="STOP_WORKING">Past-employee</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                      <Form.Item label="Type of employee">
                        {getFieldDecorator("typeOfEmployee", {
                          initialValue: get(employee_profile, "typeOfEmployee")
                        })(
                          <Radio.Group>
                            <Radio value="COMMUNITY_WRITER">
                              Community Writer
                            </Radio>
                            <Radio value="FULL_TIME">Full-time</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <div className="text-right">
                {this.props.render(this._goBack)}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
const WrappedInformation = Form.create()(Information);
export default memo(WrappedInformation);
Information.propTypes = {
  config: PropTypes.object,
  employee_profile: PropTypes.object,
  getEmployeeInfo: PropTypes.func,
  listKey: PropTypes.object,
  listTopic: PropTypes.object,
  handleSubmit: PropTypes.func,
  listExpertise: PropTypes.object,
  getListKey: PropTypes.func,
  getListTopic: PropTypes.func,
  getListExpertise: PropTypes.func,
  updateEmployee: PropTypes.func,
  createEmployee: PropTypes.func
};
Information.defaultProps = {};
