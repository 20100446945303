import React, { Component } from "react";
import {
  Container
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import ChiefArticleProgressTable from "../../../components/Tables/ChiefArticleProgressTable";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
class ChiefArticleInProgress extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Article - In Progress"
          content="articleProgress"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <ChiefArticleProgressTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefArticleInProgress.propTypes = {
  content: PropTypes.string
};
export default ChiefArticleInProgress;
