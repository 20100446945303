import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorWriterInfoTable from "../../../components/Tables/EditorWriterInfoTable";
class EditorWriterInformation extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Writer Information"
          content="writerInformation"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <EditorWriterInfoTable {...props} />
        </Container>
      </>
    );
  }
}
EditorWriterInformation.propTypes = {
  content: PropTypes.string,
};
export default EditorWriterInformation;
