import callApi from "../utils/callApi";
import { get, omit } from "lodash";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

// Action
export const actGetListTimeline = () => {
  return {
    type: Types.GET_LIST_TIMELINE_START
  };
};
export const actGetListTimelineSuccess = data => {
  return {
    type: Types.GET_LIST_TIMELINE_SUCCESS,
    data
  };
};
export const actGetListTimelineFail = () => {
  return {
    type: Types.GET_LIST_TIMELINE_FAIL
  };
};

export const actGetTimelineDetailSuccess = data => {
  return {
    type: Types.GET_TIMELINE_DETAIL_SUCCESS,
    data
  };
};

export const actGetTimelineDetailStart = () => {
  return {
    type: Types.GET_TIMELINE_DETAIL_START
  };
};
export const actGetTimelineDetailFail = () => {
  return {
    type: Types.GET_TIMELINE_DETAIL_FAIL
  };
};

export const actClearTimelineDetailItem = () => {
  return {
    type: Types.CLEAR_TIMELINE_ITEM
  };
};

export const actEditTimelineItem = () => {
  return {
    type: Types.EDIT_TIMELINE_ITEM
  };
};
export const actAddTimelineItem = () => {
  return {
    type: Types.ADD_TIMELINE_ITEM
  };
};

//Action creators
// List timeline
export const actGetListTimelineRequest = data => {
  return dispatch => {
    dispatch(actGetListTimeline());
    const defaultEndpoint = `v2/timeline?name=covid-19&page=${data.page}&limit=${data.limit}`;
    const englishVersionEndpoint = `v2/timeline?name=covid-19-en&page=${data.page}&limit=${data.limit}`;
    const endpoint = data.isEngVersion
      ? englishVersionEndpoint
      : defaultEndpoint;
    return callApi(endpoint, "GET")
      .then(res => {
        dispatch(actGetListTimelineSuccess(res.data));
      })
      .catch(err => {
        dispatch(actGetListTimelineFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// Timeline item Detail
export const actGetTimelineDetailRequest = id => {
  return dispatch => {
    dispatch(actGetTimelineDetailStart());
    const endpoint = `v2/timeline/${id}`;
    return callApi(endpoint, "GET")
      .then(res => {
        dispatch(actGetTimelineDetailSuccess(res.data.timeline));
      })
      .catch(err => {
        dispatch(actGetListTimelineRequest());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
//Approve application
export const actUpdateTimelineItemRequest = data => {
  return dispatch => {
    const defaultEndpoint = `v2/timeline?name=covid-19`;
    const englishVersionEndpoint = `v2/timeline?name=covid-19-en`;
    const endpoint = data.isEngVersion
      ? englishVersionEndpoint
      : defaultEndpoint;
    const payload = omit(data, "isEngVersion");
    const params = data.isEngVersion
      ? {
          page: 1,
          limit: 10,
          isEngVersion: true
        }
      : {
          page: 1,
          limit: 10
        };
    const action = data.status || "Updated";
    return callApi(endpoint, "PUT", payload)
      .then(() => {
        dispatch(actEditTimelineItem());
        dispatch(
          actNotify({
            message: `${action} succesfully`
          })
        );
        dispatch(actGetListTimelineRequest(params));
      })
      .catch(err => {
        const errorMsg = get(
          err.data.extendedData,
          "errorDetail.details[0].message"
        );
        dispatch(
          actNotify({
            message: renderError(err, errorMsg),
            color: "danger"
          })
        );
      });
  };
};

//Add new timeline item
export const actAddTimelineItemRequest = data => {
  return dispatch => {
    const endpoint = `v2/timeline`;
    const params =
      data.name !== "covid-19"
        ? {
            page: 1,
            limit: 10,
            isEngVersion: true
          }
        : {
            page: 1,
            limit: 10
          };
    return callApi(endpoint, "POST", data)
      .then(() => {
        dispatch(actAddTimelineItem());
        dispatch(
          actNotify({
            message: "Add new update succesfully"
          })
        );
        dispatch(actGetListTimelineRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
