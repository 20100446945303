import React from "react";
import { ReactComponent as ArrowIncreaseIcon } from "./Arrow.svg";
import { ReactComponent as ArrowDecreaseIcon } from "./ArrowDecrease.svg";

const Arrow = ({ data = 0, dataCompare = 0 }) => {
  /* dataCompare = (Math.round(dataCompare * 100) / 100);
  data = (Math.round(data * 100) / 100); */
  let temp  = Math.round((dataCompare - data ) * 100) / 100;
  const show = () => {
    console.log("show", temp, dataCompare, data);
    return Math.round(Math.abs(temp * 100)) + '%';
  };
  return temp >= 0 ? (
    <>
      <ArrowDecreaseIcon />{" "}
      <span style={{ marginLeft: "10px", color: "#5E72E4" }}> {show()} </span>
    </>
  ) : (
    <>
      <ArrowIncreaseIcon />{" "}
      <span style={{ marginLeft: "10px" ,color: "#5E72E4"}}> {show()} </span>
    </>
  );
};

export default Arrow;
