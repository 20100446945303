import translation_en from "locales/en.json";
import translation_vi from "locales/vi.json";
import translation_kr from "locales/kr.json";

const translations = {
  en: translation_en,
  vi: translation_vi,
  kr: translation_kr
};

export default translations;
