import React, { Component } from "react";
import { Card, Table, Row, Col } from "reactstrap";
import { Dropdown, Menu, Icon } from "antd";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import {
  actGetGroupTopicRequest,
  actGetTopicByGroupRequest,
  actGetTopicDetail,
  actGetAllTopicRequest,
} from "../../actions/topic.action";
import classnames from "classnames";
import NO_DATA from "assets/img/no-data.svg";
import PropTypes from "prop-types";
import GroupTopicModal from "../Modals/GroupTopicModal";
import ChiefTopicModal from "../Modals/ChiefTopicModal";
import ArticleListModal from "../Modals/ArticleListModal";
import GroupTopicSettingModal from "../Modals/GroupTopicSettingModal";
import GroupTopicAddTopicModal from "../Modals/GroupTopicAddTopicModal";
import getUrlParam from "../../utils/getUrlParam";
class ParentSelectList extends Component {
  state = {
    isLoading: false,
    isGroupTopicModal: false,
    isArticleModal: false,
    isUpdate: false,
    isLanguage: true,
    params: {
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1",
    },
  };

  componentDidMount() {
    const data = {
      language: this.state.isLanguage ? "VN" : "EN",
      sort: this.state.params,
    };
    this.props.getGroupTopic(data);
    this.props.getTopicList(data);
    this.props.getTopicByGroupAll(data);
  }

  parentActions = (
    <Menu>
      <Menu.Item onClick={() => this.onParentActionSelect(0)}>
        Setting
      </Menu.Item>
      <Menu.Item onClick={() => this.onParentActionSelect(1)}>
        Add/Remove Topic
      </Menu.Item>
    </Menu>
  );

  childrenActions = (topic) => (
    <Menu>
      <Menu.Item onClick={() => this.onChildrenActionSelect(0, topic)}>
        View Article
      </Menu.Item>
      <Menu.Item onClick={() => this.onChildrenActionSelect(1, topic)}>
        Setting
      </Menu.Item>
    </Menu>
  );

  onParentActionSelect = (key) => {
    switch (key) {
      case 0:
        this.toggleGroupTopicSettingModal();
        break;
      default:
        this.toggleGroupTopicAddTopicModal();
        break;
    }
  };

  onChildrenActionSelect = (key, topic) => {
    switch (key) {
      case 0:
        this.toggleArticleModal();
        this.setState({ topic_key: topic.key });
        this.props.getTopicDetail(topic);
        break;
      case 1:
        this.toggleTopicModal();
        this.setState({ topic_update_id: topic._id });
        this.setState({ isUpdate: true });
        this.props.getTopicDetail(topic);
        break;
      default:
        break;
    }
  };

  onGroupTopicSelect = (group) => {
    const data = {
      language: this.state.isLanguage ? "VN" : "EN",
      sort: this.state.params,
    };
    this.onLoading();
    if (!group) {
      this.props.getTopicByGroupAll(data);
    } else {
      this.props.getTopicByGroup(group);
    }
  };

  toggleGroupTopicModal = () => {
    this.setState((prevState) => ({
      isGroupTopicModal: !prevState.isGroupTopicModal,
      isUpdate: !prevState.isGroupTopicModal ? prevState.isUpdate : false,
    }));
  };

  toggleGroupTopicSettingModal = () => {
    this.setState((prevState) => ({
      isGroupTopicSettingModal: !prevState.isGroupTopicSettingModal,
    }));
  };

  toggleGroupTopicAddTopicModal = () => {
    this.setState((prevState) => ({
      isGroupTopicAddTopicModal: !prevState.isGroupTopicAddTopicModal,
    }));
  };

  toggleTopicModal = () => {
    this.setState((prevState) => ({
      isTopicModal: !prevState.isTopicModal,
      isUpdate: !prevState.isTopicModal ? prevState.isUpdate : false,
    }));
  };

  toggleArticleModal = () => {
    this.setState((prevState) => ({
      isArticleModal: !prevState.isArticleModal,
    }));
  };

  onLoading = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  };

  handeChangeLanguage = (e) => {
    this.setState({ isLanguage: e.target.checked });
    const group = {
      ...this.props.group_detail,
      language: e.target.checked ? "VN" : "EN",
    };
    let data = {
      language: e.target.checked ? "VN" : "EN",
    };
    this.props.getGroupTopic(data);
    this.props.getTopicList(data);
    !group._id
      ? this.props.getTopicByGroupAll(data)
      : this.props.getTopicByGroup(group);
  };

  _sortByColumn = (typeColumn) => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1,
        },
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1,
        },
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort,
    };
    const data = {
      language: this.state.isLanguage ? "VN" : "EN",
      sort: paramsSort,
    };
    this.props.getTopicList(data);
  };

  render() {
    const { isLanguage } = this.state;
    const { group_topic_list, topic_list_by_group, topic_list } = this.props;
    return (
      <>
        {this.state.isGroupTopicModal && (
          <GroupTopicModal
            isUpdate={this.state.isUpdate}
            modal={this.state.isGroupTopicModal}
            toggle={this.toggleGroupTopicModal}
            language={this.state.isLanguage ? "VN" : "EN"}
          />
        )}
        {this.state.isGroupTopicSettingModal && (
          <GroupTopicSettingModal
            modal={this.state.isGroupTopicSettingModal}
            toggle={this.toggleGroupTopicSettingModal}
            language={this.state.isLanguage ? "VN" : "EN"}
          />
        )}
        {this.state.isGroupTopicAddTopicModal && (
          <GroupTopicAddTopicModal
            modal={this.state.isGroupTopicAddTopicModal}
            toggle={this.toggleGroupTopicAddTopicModal}
            language={this.state.isLanguage ? "VN" : "EN"}
          />
        )}
        {this.state.isTopicModal && (
          <ChiefTopicModal
            isUpdate={this.state.isUpdate}
            modal={this.state.isTopicModal}
            toggle={this.toggleTopicModal}
            id={this.state.topic_update_id}
            language={this.state.isLanguage ? "VN" : "EN"}
            sort={this.state.params}
          />
        )}
        {this.state.isArticleModal && (
          <ArticleListModal
            topic_key={this.state.topic_key}
            modal={this.state.isArticleModal}
            toggle={this.toggleArticleModal}
          />
        )}
        <Row
          className="float-right"
          style={{ marginBottom: 20, marginTop: "-4rem" }}
        >
          <Col lg="6 text-right">
            <button
              type="button"
              className="btn-icon btn btn-secondary"
              onClick={this.toggleTopicModal}
            >
              <span className="mr-1">
                <i
                  className="fa fa-plus-circle"
                  style={{ color: "#5e72e4" }}
                ></i>
              </span>

              <span className="btn-inner--text" style={{ color: "#5e72e4" }}>
                New Topic
              </span>
            </button>
          </Col>
        </Row>
        <Card className="parent-select-list topic-select-list">
          <div className="parent-list">
            <h3>Topic</h3>
            <ul className="parent-list-ul">
              {/* ------- Group all topic  ---------*/}
              <li
                className={classnames({
                  published: false,
                  active: !this.props.group_detail._id,
                })}
                onClick={() => this.onGroupTopicSelect(null)}
              >
                <div>
                  <span>All topics</span>{" "}
                  <span className="number">({topic_list.length})</span>
                </div>
              </li>
              {group_topic_list.map((prop, key) => {
                return (
                  <li
                    className={classnames({
                      published: prop.visibility === "PUBLISHED",
                      active: this.props.group_detail._id === prop._id,
                    })}
                    onClick={() => this.onGroupTopicSelect(prop)}
                    key={key}
                  >
                    <div>
                      <span>{prop.name}</span>{" "}
                      <span className="number">({prop.listTopic.length})</span>
                    </div>
                    {this.props.group_detail._id === prop._id && (
                      <div className="more">
                        <Dropdown
                          overlay={this.parentActions}
                          placement="bottomRight"
                        >
                          <Icon type="more" />
                        </Dropdown>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
            <div className="btn-add" onClick={this.toggleGroupTopicModal}>
              <Icon type="plus" />
              Add New Group
            </div>
          </div>
          <div className="children-list">
            <div className="collection-table-header d-flex justify-content-between align-items-center">
              <h3>{this.props.group_detail.name}</h3>
              <div className="collection-table-language d-flex align-items-center mr-3">
                <span className="mr-2">Show articles: </span>
                <label className="custom-toggle custom-toggle-warning mr-1">
                  <input
                    name="isLanguage"
                    type="checkbox"
                    value={isLanguage}
                    checked={isLanguage}
                    onChange={this.handeChangeLanguage}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="EN"
                    data-label-on="VN"
                  />
                </label>
              </div>
            </div>
            {this.state.isLoading ? (
              <div className={`children-list-content p-9 text-center`}>
                <ClipLoader loading={true} size={50} color={"#3C74F0"} />
              </div>
            ) : topic_list_by_group.length === 0 ? (
              <div className={`children-list-content no-data p-9 text-center`}>
                <img src={NO_DATA} className="mb-2" alt="" /> <br />
                No data
              </div>
            ) : (
              <div className="children-list-content">
                <div className="table-scroll">
                  <Table
                    className="align-items-center table-flush"
                    hover
                    style={{ border: "0px" }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("name")}
                        >
                          Topic name
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("visibility")}
                        >
                          Status
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("tags")}
                        >
                          Tags
                        </th>
                        <th className="text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {topic_list_by_group.map((prop, key) => {
                        return (
                          <tr key={key}>
                            <th>{prop.name}</th>
                            <td>
                              {prop.visibility === "PUBLISHED" ? (
                                <span className="published">Published</span>
                              ) : (
                                <span className="unpublished">Unpublished</span>
                              )}
                            </td>
                            <td>{prop.tags && prop.tags.length}</td>
                            <td className="text-right">
                              <Dropdown
                                overlay={() => this.childrenActions(prop)}
                                placement="bottomRight"
                              >
                                <Icon type="more" />
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    group_topic_list: state.topicReducer.group_topic_list,
    group_detail: state.topicReducer.group_detail,
    topic_list_by_group: state.topicReducer.topic_list_by_group,
    topic_list: state.topicReducer.topic_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroupTopic: (data) => {
      dispatch(actGetGroupTopicRequest(data));
    },
    getTopicByGroup: (group) => {
      dispatch(actGetTopicByGroupRequest(group));
    },
    getTopicByGroupAll: (data) => {
      dispatch(actGetAllTopicRequest(data));
    },
    getTopicList: (data) => {
      dispatch(actGetAllTopicRequest(data));
    },
    getTopicDetail: (data) => {
      dispatch(actGetTopicDetail(data));
    },
  };
};

ParentSelectList.propTypes = {
  getGroupTopic: PropTypes.func,
  getTopicByGroup: PropTypes.func,
  getTopicByGroupAll: PropTypes.func,
  getTopicDetail: PropTypes.func,
  group_topic_list: PropTypes.array,
  topic_list_by_group: PropTypes.array,
  topic_list: PropTypes.array,
  getTopicList: PropTypes.array,
  group_detail: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentSelectList);
