import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  Badge,
  Card,
  CardHeader,
  Table,
  // Button,
  CardFooter,
  Row,
  // Col
} from "reactstrap";
import CustomPagination from "../Pagination/Pagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetOutlineInProgressListRequest } from "../../actions/writerArticle.action";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import { formatStringToDate } from "../../utils/formatDate";

class WriterOutlineInProgressTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1",
    },
    isLoading: false,
  };

  componentDidMount() {
    this._setLoading();
    this._getOutlineInProgress();
  }

  _getOutlineInProgress = () => {
    const params = this.state.params;
    this.props.getOutlineInProgress(params);
  };

  _handleOnPageClick = (page) => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getOutlineInProgress(params);
  };

  _handlePathnamePush = (params) => {
    // eslint-disable-next-line react/prop-types
    this.props.history.push(paramFromData(params));
  };

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 50) {
      return `${str[0].substring(0, 50)}...`;
    } else {
      return str[0].substring(0, 50);
    }
  };

  _sortByColumn = (typeColumn) => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1,
        },
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1,
        },
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort,
    };
    this._handlePathnamePush(paramsSort);
    this.props.getOutlineInProgress(paramsSort);
  };

  _handleOnLayoutClick = (e, id, status) => {
    e.preventDefault();
    if (status === "OUTLINE_WRITING" || status === "OUTLINE_SUBMITTED") {
      this.props.history.push(`/admin/writer/outline-content/${id}`);
    }
  };

  render() {
    const { outline_in_progress_list } = this.props;
    const page =
      outline_in_progress_list.data && outline_in_progress_list.data.page;
    const totalPages =
      outline_in_progress_list.data && outline_in_progress_list.data.totalPages;
    const totalDocs =
      outline_in_progress_list.data && outline_in_progress_list.data.totalDocs;
    const renderStatus = (status) => {
      switch (status) {
        case "OUTLINE_WRITING":
          return (
            <>
              <span className="status-new">●</span>
              <span>Needs Writing</span>
            </>
          );
        case "OUTLINE_SUBMITTED":
          return (
            <>
              <span className="status-waiting">●</span>
              <span>Submitted for Approval</span>
            </>
          );
        default:
      }
      return null;
    };
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Outline In Progress List</h3>
              </CardHeader>
              <div className="table-responsive">
                {outline_in_progress_list.data &&
                  outline_in_progress_list.data.docs.length !== 0 && (
                    <Table
                      className="align-items-center table-flush"
                      style={{ border: "0px", overflow: "hidden" }}
                      hover
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            onClick={() => this._sortByColumn("angle_title")}
                          >
                            Title
                          </th>
                          <th className="text-center">Dual Language</th>
                          <th
                            className="sort"
                            data-sort="budget"
                            scope="col"
                            onClick={() => this._sortByColumn("start_date")}
                          >
                            Writer Accepts On
                          </th>
                          <th
                            className="sort"
                            data-sort="status"
                            scope="col"
                            onClick={() => this._sortByColumn("dead_line")}
                          >
                            Deadline
                          </th>
                          <th>Status</th>
                          <th scope="col">TOPIC</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {outline_in_progress_list.data
                          ? outline_in_progress_list.data.docs.map(
                              (prop, key) => {
                                return (
                                  <>
                                    <tr
                                      key={key}
                                      className="cursor-pointer"
                                      onClick={(e) =>
                                        this._handleOnLayoutClick(
                                          e,
                                          prop.id,
                                          prop.status_article
                                        )
                                      }
                                    >
                                      <th scope="row">
                                        {this.limitTitle(
                                          ReactHtmlParser(prop.angle_title)
                                        )}
                                      </th>
                                      <td className="text-center text-success">
                                        {prop.dualLanguage_article !== null &&
                                          prop.dualLanguage_article && (
                                            <i
                                              className="fa fa-check-circle"
                                              style={{ fontSize: 16 }}
                                            ></i>
                                          )}
                                      </td>
                                      <td className="budget">
                                        {formatStringToDate(prop.start_date)}
                                      </td>
                                      <td>
                                        {formatStringToDate(prop.dead_line)}
                                      </td>
                                      <td>
                                        <Badge
                                          color=""
                                          className="badge-dot mr-4"
                                        >
                                          {renderStatus(prop.status_article)}
                                        </Badge>
                                      </td>
                                      <td>
                                        {prop
                                          ? prop.topic.map((topic) => {
                                              return (
                                                <>
                                                  <div className="_topic">
                                                    <label>
                                                      {this._text_truncate(
                                                        topic.name
                                                          ? topic.name
                                                          : topic.label,
                                                        20,
                                                        "..."
                                                      )}
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })
                                          : ""}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                          : ""}
                      </tbody>
                    </Table>
                  )}
                {outline_in_progress_list.data &&
                  outline_in_progress_list.data.docs.length === 0 && (
                    <div
                      className="p-7 text-center font-italic font-weight-bold"
                      style={{ color: "#ccc", fontSize: "18px" }}
                    >
                      There are no content available
                    </div>
                  )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    outline_in_progress_list:
      state.writerArticleReducer.outline_in_progress_list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOutlineInProgress: (params) => {
      dispatch(actGetOutlineInProgressListRequest(params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriterOutlineInProgressTable);
WriterOutlineInProgressTable.propTypes = {
  outline_in_progress_list: PropTypes.object,
  getOutlineInProgress: PropTypes.func,
};
WriterOutlineInProgressTable.defaultProps = {};
