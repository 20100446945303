import React from "react";
import { Container, Row, Col } from "reactstrap";
import CardsHeaderChief from "../../components/Headers/CardsHeaderChief.jsx";
import MyProfileModal from "../../components/Modals/MyProfileModal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ChiefOutlineDashboardTable from "../../components/Tables/ChiefOutlineDashboardTable.jsx";
import ChiefPitchesDashboardTable from "../../components/Tables/ChiefPitchesDashboardTable.jsx";
import ChiefArticleDashboardTable from "../../components/Tables/ChiefArticleDashboardTable.jsx";

class DashboardChief extends React.Component {
  state = {
    isModal: true,
    isViewDetail: false,
  };

  _toggleModal = () => {
    this.setState((prevState) => ({
      isViewDetail: !prevState.isViewDetail,
    }));
  };

  componentDidMount() {
    this._verifyRole();
  }

  _verifyRole = () => {
    const key = localStorage.getItem("key");
    key !== "chiefeditor" && this.props.history.push("/admin");
  };

  _toggleModalNotify = () => {
    this.setState((prevState) => ({
      isModal: !prevState.isModal,
    }));
  };

  render() {
    const props = this.props;
    const isSkip = localStorage.getItem("is_skip");
    return (
      <>
        {isSkip === "false" ? (
          <MyProfileModal
            modal={this.state.isModal}
            toggle={this._toggleModalNotify}
          />
        ) : (
          ""
          )}
        <CardsHeaderChief name="Dashboard" parentName="Dashboards" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <ChiefArticleDashboardTable {...props} />
            </Col>
            <Col xl="12">
              <ChiefOutlineDashboardTable {...props} />
            </Col>
            <Col xl="12">
              <ChiefPitchesDashboardTable {...props} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default connect(null, null)(DashboardChief);
DashboardChief.propTypes = {
  chief_today_deadline: PropTypes.object,
  chief_today_published: PropTypes.object,
  getTableDealineDashboard: PropTypes.func,
  getChiefTodayPublished: PropTypes.func,
  getChiefContentByID: PropTypes.func,
  history: PropTypes.any,
};
DashboardChief.defaultProps = {};
