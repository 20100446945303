import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";
// Chief add new article drirectly
export const actAddNewPublishing = () => {
  return {
    type: Types.ADD_NEW_PUBLISHING,
  };
};
export const actAddNewPublishingSuccess = (data) => {
  return {
    type: Types.ADD_NEW_PUBLISHING_SUCCESS,
    data,
  };
};
export const actAddNewPublishingFail = () => {
  return {
    type: Types.ADD_NEW_PUBLISHING_FAIL,
  };
};
// Add new open publishing
export const actAddNewPublishingRequest = (data) => {
  return (dispatch) => {
    dispatch(actAddNewPublishing());
    callApi(`v2/open-publishing-article`, "POST", data)
      .then((res) => {
        dispatch(actAddNewPublishingSuccess());
        dispatch(
          actNotify({
            message: "Created succesfully",
          })
        );
      })
      .catch((err) => {
        dispatch(actAddNewPublishingFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

// Update open publishing
export const actUpdatePublishingRequest = (data) => {
  return (dispatch) => {
    return callApi(`v2/open-publishing-article/setting`, "POST", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Updated setting successfully!",
            color: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};
