import React, { useState } from "react";
import PropTypes from "prop-types";
import NO_DATA from "assets/img/no-data.svg";
// nodejs library that concatenates classes
import classnames from "classnames";
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import ReactHtmlParser from "react-html-parser";

export default function MultipleCheckboxSelectFeature(props) {
  const [state, setState] = useState({
    isSelected: false
  });

  return (
    <div>
      <div className="feature-select">
        <FormGroup>
          <InputGroup
            className={classnames("input-group-merge", {
              focused: state.isSelected
            })}
          >
            <Input
              className="feature-select-input-search"
              name="valueSearch"
              placeholder="Search tags or article's title"
              autoComplete={"off"}
              type="search"
              value={
                props.arrSelect.length > 0 && state.isSelected === false
                  ? `${props.arrSelect.length} selected`
                  : props.valueInput
              }
              onChange={props.handleChange}
              onFocus={() => setState({ ...state, isSelected: true })}
              onBlur={() => setState({ ...state, isSelected: true })}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setState({
                    ...state,
                    isSelected: !state.isSelected
                  })
                }
              >
                {!state.isSelected ? (
                  <i className="ni ni-bold-down" />
                ) : (
                    <i className="ni ni-bold-up" />
                  )}
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          {state.isSelected && (
            <div
              className="feature-select-checkbox"
              onClick={() => setState({ ...state, isSelected: true })}
            >
              {props.hasSelectAll &&
                props.dataSearch.docs &&
                props.dataSearch.docs.length > 0 && (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      borderBottom: "1px solid #E6E8EB",
                      padding: 14
                    }}
                  >
                    <div className="custom-control custom-checkbox">
                      <input
                        id="checkbox-select-all"
                        className="custom-control-input"
                        type="checkbox"
                        value="all"
                        checked={props.selectAll}
                        onChange={props.onSelectAll}
                        indeterminate={false}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox-select-all"
                      >
                        <p className="custom-control-title">Select all</p>
                      </label>
                    </div>
                    <div
                      style={{
                        color:
                          props.selectedId.length > 0 ? "#5E72E4" : "#ADB4BD"
                      }}
                      onClick={props.handleClearAll}
                    >
                      <p style={{ cursor: "pointer" }} className="mb-0">
                        Clear all
                      </p>
                    </div>
                  </div>
                )}

              <div className="feature-select-checkbox-scroll">
                {props.dataSearch.docs && props.dataSearch.docs.length > 0 ? (
                  props.dataSearch.docs.map((item, index) => {
                    return (
                      <div
                        className="custom-control custom-checkbox"
                        key={index}
                      >
                        <input
                          id={item._id}
                          className="custom-control-input"
                          type="checkbox"
                          value={item.angle_title}
                          checked={props.selectedId.includes(item._id)}
                          onChange={() => props.onSelect(item)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={item._id}
                        >
                          <p className="custom-control-title">
                            {ReactHtmlParser(item.angle_title)}
                          </p>
                        </label>
                      </div>
                    );
                  })
                ) : (
                    <div
                      className={`children-list-content no-data p-8 text-center`}
                    >
                      <img src={NO_DATA} className="mb-2" alt="" /> <br />
                    No options
                    </div>
                  )}
              </div>

              {!props.hasSelectAll && props.arrSelect.length > 0 && (
                <div className="feature-select-checkbox-footer text-right">
                  <span
                    onClick={props.handleClearAll}
                    style={{
                      color: props.selectedId.length > 0 ? "#5E72E4" : "#ADB4BD"
                    }}
                  >
                    Clear all
                  </span>
                </div>
              )}
            </div>
          )}
        </FormGroup>
      </div>
    </div>
  );
}

MultipleCheckboxSelectFeature.propTypes = {
  valueInput: PropTypes.string,
  arrSelect: PropTypes.array,
  selectedId: PropTypes.array,
  dataSearch: PropTypes.any,
  handleClearAll: PropTypes.func,
  handleChange: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  hasSelectAll: PropTypes.bool,
  selectAll: PropTypes.bool
};
