import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetArticleChiefDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `article/get-list-article-on-dashboard?` +
        `status_article=${data.status_article}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetArticleChiefDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetArticleChiefDashboard = data => {
  return {
    type: Types.GET_ARTICLE_CHIEF_DASHBOARD,
    data
  };
};

export const actGetOutlineChiefDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `article/get-list-outline-on-dashboard?` +
        `limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetOutlineChiefDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetOutlineChiefDashboard = data => {
  return {
    type: Types.GET_OUTLINE_CHIEF_DASHBOARD,
    data
  };
};

export const actGetPitchChiefDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `pitch/get-list-pitch-on-dashboard?` +
        `limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetPitchesChiefDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPitchesChiefDashboard = data => {
  return {
    type: Types.GET_PITCH_CHIEF_DASHBOARD,
    data
  };
};
