import React, { Component } from "react";
import adjustmentRoute from "../../../routes/adjustment";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class AdjustmentList extends Component {
  state = {};

  render() {
    return (
      <div className="sidelist-container adjustment-sidelist">
        <div className="position-relative">
          <h1>Các mục tùy chỉnh</h1>
          <ul>
            {adjustmentRoute.map((prop, key) => {
              return (
                <NavLink
                  activeClassName="active"
                  key={key}
                  to={prop.layout + prop.path}
                >
                  <li>{prop.name}</li>
                </NavLink>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
AdjustmentList.contextTypes = {
  t: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {};
};
export default connect(mapStateToProps)(AdjustmentList);
AdjustmentList.propTypes = {};
AdjustmentList.defaultProps = {};
