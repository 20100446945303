import React, { Component } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actApproveContentRequest } from "../../actions/editorArticle.action";
import { Link } from "react-router-dom";

class MyProfileModal extends Component {
  state = {};

  componentDidMount() {}

  // eslint-disable-next-line react/no-deprecated
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.mes_blacklist !== this.props.mes_blacklist) {
  //     this._setMesBlacklist(nextProps.mes_blacklist);
  //   }
  // }
  _handleConfirm = () => {
    this.toggle();
  };

  toggle = () => {
    this.props.toggle();
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  render() {
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.props.toggle}>
            Do you want update your profile?
          </ModalHeader>
          <ModalFooter className="d-flex justify-content-flex-end">
            <Button
              color="link"
              style={{ color: "#fb6340" }}
              onClick={this._handleConfirm}
            >
              REMIND LATER
            </Button>
            <Link to={"/admin/profile/my-profile"}>
              <Button
                onClick={this._handleConfirm}
                color="link"
                style={{ color: "#fb6340" }}
              >
                UPDATE NOW
              </Button>
            </Link>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    approveWritingContent: (id, data) => {
      dispatch(actApproveContentRequest(id, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfileModal);

MyProfileModal.propTypes = {
  id: PropTypes.string,
  approveWritingContent: PropTypes.func,
  toggle: PropTypes.func
};

MyProfileModal.defaultProps = {};
