import React, { useState, useEffect } from "react";
import moment from "moment";
import get from "lodash/get";
import has from "lodash/has";
import { DatePicker, Button, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, CardHeader, Row, Col } from "reactstrap";
import CardsHeaderChief from "../../components/Headers/CardsHeaderChief";
import ChiefFeatureArticleListTable from "../../components/FeatureArticle/ListTable";
import ModalAddFeatureArticle from "../../components/FeatureArticle/ModalAddFeatureArticle";
import {
  actGetFeatureArticleGroupSectionRequest,
  actGetScheduleByDateRequest,
  actResetSchedule,
  actResetFeatureArticle,
  actGetScheduledDatesRequest,
  setSelectedDate,
  disableModalInform,
} from "../../actions/feature-articles.action";
import ConfirmModal from "../../components/Modals/ApplicationDetail/Confirm";

import "./styles.scss";
const dateTimeFormat = "YYYY-MM-DD";

function ChiefFeatureArticles() {
  const dispatch = useDispatch();
  const featureArticleSchedule = useSelector(
    (state) => state.featureArticleReducer.featureArticleSchedule
  );
  const scheduledDates = useSelector(
    (state) => state.featureArticleReducer.scheduledDates
  );
  const selectedDate = useSelector(
    (state) => state.featureArticleReducer.selectedDate
  );
  const modalInform = useSelector(
    (state) => state.featureArticleReducer.modalInform
  );
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const arraySchedulesTime = scheduledDates.map((item) =>
    moment(item.date).format(dateTimeFormat)
  );

  const today = moment().format(dateTimeFormat);
  const isPastDate = moment(selectedDate).isBefore(today);

  useEffect(() => {
    const payloadToGetScheduledDate = {
      startDate: moment()
        .startOf("month")
        .format(dateTimeFormat),
      enđDate: moment()
        .endOf("month")
        .format(dateTimeFormat),
    };
    dispatch(actGetScheduledDatesRequest(payloadToGetScheduledDate));
    dispatch(actGetFeatureArticleGroupSectionRequest());
    const data = {
      date: moment().format(dateTimeFormat),
    };
    dispatch(setSelectedDate(data.date));
    dispatch(actGetScheduleByDateRequest(data));
  }, []);

  function handleAddArticle() {
    setEditMode(false);
    setVisible(true);
    dispatch(actResetFeatureArticle());
  }

  function handleChange(date, dateString) {
    if (dateString) {
      const data = {
        date: dateString,
      };
      dispatch(setSelectedDate(dateString));
      dispatch(actGetScheduleByDateRequest(data));
      return;
    }
    dispatch(setSelectedDate(""));
    dispatch(actResetSchedule());
  }

  function handlePanelChange(value, mode) {
    const payloadToGetScheduledDate = {
      startDate: value.startOf("month").format(dateTimeFormat),
      enđDate: value.endOf("month").format(dateTimeFormat),
    };
    dispatch(actGetScheduledDatesRequest(payloadToGetScheduledDate));
  }

  const lastModifyBy = get(featureArticleSchedule, "modifyBy.penname", "");
  const lastUpdatedAt = get(featureArticleSchedule, "updatedAt", "");

  return (
    <div>
      <CardsHeaderChief
        name="Feature Articles"
        content="AMmainMenu"
        parentName="Appearance Managing"
      />
      <Container className="mt--6" fluid>
        <Row className="feature-article-table">
          <Col>
            <Card>
              <CardHeader className="feature-article-action-wrapper">
                <div className="d-flex justify-content-between">
                  {/* Left column action */}
                  <div className="d-flex align-items-center left-column-action">
                    <span>Setting for</span>
                    <DatePicker
                      defaultValue={moment()}
                      onChange={handleChange}
                      onPanelChange={handlePanelChange}
                      className="mx-4 cursor-pointer"
                      format="YYYY-MM-DD"
                      dateRender={(current) => {
                        const style = {};
                        if (
                          arraySchedulesTime.includes(
                            current.format(dateTimeFormat)
                          )
                        ) {
                          style.background = "#E9ECEE";
                          style.border = "1px solid #F4F6F8";
                          style.borderRadius = "4px";
                          return (
                            <Tooltip title="This date has been scheduled">
                              <div className="ant-calendar-date" style={style}>
                                {current.date()}
                              </div>
                            </Tooltip>
                          );
                        }
                        return (
                          <div className="ant-calendar-date" style={style}>
                            {current.date()}
                          </div>
                        );
                      }}
                    />
                    {lastModifyBy && lastUpdatedAt && (
                      <div className="last-edited">
                        Last edited by <strong>{lastModifyBy}</strong> at{" "}
                        <span>
                          {moment(lastUpdatedAt).format("MMMM Do, HH:mm A")}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* Right column action */}
                  <div className="right-column-action">
                    {!isPastDate && (
                      <Button onClick={handleAddArticle} type="primary">
                        Add article
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              {/* Table */}
              <ChiefFeatureArticleListTable
                isPastDate={isPastDate}
                setEditMode={setEditMode}
                setModalVisible={() => setVisible(true)}
              />
              <ModalAddFeatureArticle
                visible={visible}
                editMode={editMode}
                setEditMode={setEditMode}
                handleCancel={() => setVisible(false)}
              />
              <ConfirmModal
                closable={false}
                visible={modalInform}
                handleOk={() => dispatch(disableModalInform())}
                title="Today’s schedule has not been set. Please add your schedule."
                footer={[
                  <Button
                    className="modal-submit-btn"
                    key="submit"
                    type="primary"
                    onClick={() => dispatch(disableModalInform())}
                  >
                    Got it
                  </Button>,
                ]}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ChiefFeatureArticles;
