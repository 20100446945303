import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Arrow from "../Arrow"
import { ShowNumner } from "../utils"
import get from "lodash/get"
const Info = function({ data }) {
  return (
    <Row>
      <Col span="8">
        <div>Total writer </div>
        <h1>{ShowNumner(get(data, 'writer.typeOfEmployee.totalWriter', 0))}</h1>
        <Arrow data={get(data, 'writer.typeOfEmployee.totalWriter', 0)} dataCompare={get(data, 'dataCompare.writer.totalWriter')}/>
      </Col>
      <Col span="8">
        <div>In house</div>
        <h1>{ShowNumner(get(data, 'writer.typeOfEmployee.totalInHouse', 0))}</h1>
        <Arrow data={get(data, 'writer.typeOfEmployee.totalInHouse', 0)} dataCompare={get(data, 'dataCompare.writer.totalInHouse')}/>
      </Col>
      <Col span="8">
        <div>Community Writer</div>
        <h1>{ShowNumner(get(data, 'writer.typeOfEmployee.totalCommunityWriter', 0))}</h1>
        <Arrow data={get(data, 'writer.typeOfEmployee.totalCommunityWriter', 0)} dataCompare={get(data, 'dataCompare.writer.totalCommunityWriter')}/>
      </Col>
    </Row>
  );
};

export default Info;
