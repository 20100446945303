// import ParameterAdjustment from "../views/adjustment/Adjustment/ParameterAdjustment";

const adjustmentRoute = [
  {
    path: "/tuychinhthongsotinh",
    layout: "/admin/tuychinh",
    // component: ParameterAdjustment,
    name: "Tùy chỉnh thông số tính",
    exact: true
  }
];

export default adjustmentRoute;
