import { deleteCookie, getCookie, setCookie } from "./cookies";

export const setToken = accessToken => {
  setCookie("accessToken", accessToken);
  localStorage.setItem("accessToken", accessToken);
};

export const getToken = () => {
  return localStorage.getItem("accessToken") || getCookie("accessToken");
};

export const deleteToken = () => {
  localStorage.removeItem("accessToken");
  deleteCookie("accessToken");
};
