import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderChief from "../../components/Headers/CardsHeaderChief";
import ChiefReadersTable from "../../components/Tables/ChiefReadersTable";

class ChiefReaders extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderChief
          name="View User Profile"
          content="staff"
          parentName="Staff"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <ChiefReadersTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefReaders.propTypes = {
  content: PropTypes.string
};
export default ChiefReaders;
