/* eslint-disable no-case-declarations */
import * as Types from "../variables/actionTypes";

var initialState = {
  new_notification: [],
  all_notification: {}
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_NEW_NOTIFICATION:
      state = { ...state, new_notification: action.data.data.docs };
      return state;
    case Types.GET_ALL_NOTIFICATION:
      state = { ...state, all_notification: action.data };
      return state;
    default:
      return { ...state };
  }
};

export default notificationReducer;
