import React from "react";
import { get } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { filter, omit } from "lodash";
import { Button, message } from "antd";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";
import {
  actGetUnPublishedDetailByIDRequest,
  actSubmitUnPublishedRequest,
  actSubmitPublishRequest,
  actChiefUpdateSlideArticle,
  actGeneratePreviewLinkRequest,
} from "../../../actions/chiefArticle.action";
import ConfirmModal from "../../../components/Modals/ApplicationDetail/Confirm";
import NewArticleSetting from "../../../components/PostSetting/NewArticleSetting";
import NewArticleSEOManager from "../../../components/SEOManager/NewArticleSEOManager";
import SlideItem from "../../../components/Slideshow/SlideItem";
import AddSlideForm from "../../../components/Slideshow/AddSlideForm";
import { ARTICLE_STATUS } from "../../../utils/constants";
import HOST_URL from "../../../variables/hostUrl";
import { HOST_PREVIEW } from "../../../variables/hostClient";
import TagValidation from "../../../components/TagValidation";
import "./styles.scss";

class ChiefUnPublishedSlideArticleDetail extends React.Component {
  state = {
    id: window.location.pathname.split("/")[4],
    title: "",
    slides: [],
    slidesLength: 0,
    visible: false,
    publishModal: false,
    unPublishModal: false,
    deleteModal: false,
    currentSlide: null,
    slidesError: "",
    isPublished: false,
    setting: {
      image: "",
      slug: "",
      previewImage: "",
      seoDescription: "",
      writer: [],
      topic: [],
      tags: [],
      other_lang_link: "",
      publishDate: "",
      excerpt: "",
      language: "",
      featureImageCaption: "",
      featureImage: "",
    },
    confirmModal: false,
    confirmPublishModal: false,
    unpublishConfirm: false,
    publishConfirm: false,
    editConfirm: false,
    isEditting: false,
  };

  stripHTMLTag = (string) => {
    if (!string) {
      return "";
    }
    return string.replace(/(<([^>]+)>)/gi, "");
  };

  // Component life cycle
  componentDidMount() {
    this.props.getUnPublisedByID(this.state.id);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.preview.actionCompleted &&
      this.props.preview.actionCompleted !== prevProps.preview.actionCompleted
    ) {
      const { preview, content_detail } = this.props;
      const pin = get(preview, "previewLink.pin");
      const key = get(preview, "previewLink.key");
      const previewLinkUrl = `${HOST_PREVIEW}${content_detail.data._id}?pin=${pin}&key=${key}`;
      window.open(previewLinkUrl, "_blank");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.content_detail !== this.props.content_detail) {
      // eslint-disable-next-line no-console
      const topic = nextProps.content_detail.data.topic[0];
      const writer = nextProps.content_detail.data.writer;
      const featureImageCaption = get(
        nextProps,
        "content_detail.data.images.featureImageCaption",
        ""
      );
      const featureImage = get(
        nextProps,
        "content_detail.data.images.featureImage",
        ""
      );
      const language = nextProps.content_detail.data.language;
      const writerArray = [
        { ...writer, value: writer && writer.id, label: writer && writer.name },
      ];
      const topicArray = [
        { ...topic, value: topic && topic.key, label: topic && topic.name },
      ];
      const languageValue = {
        value: language === "VN" ? "VN" : "EN",
        label: language === "VN" ? "Vietnamese" : "English",
      };
      const previewImageUrl =
        nextProps.content_detail.data.images &&
        `${HOST_URL}${nextProps.content_detail.data.images.url}`;
      const excerptResult = this.stripHTMLTag(
        nextProps.content_detail.data.excerpt
      );
      const seoResult = {
        description: get(nextProps.content_detail.data, "seo.description"),
      };
      this.setState({
        title: nextProps.content_detail.data.angle_title,
        slides: nextProps.content_detail.data.contentObj.slides,
        status_article: nextProps.content_detail.data.status_article,
        isPublished:
          nextProps.content_detail.data.status_article === "PUBLISHED",
        setting: {
          topic: topicArray,
          writer: writerArray,
          other_lang_link: nextProps.content_detail.data.other_lang_link,
          tags: nextProps.content_detail.data.tags,
          excerpt: excerptResult,
          seoDescription: seoResult,
          previewImage: previewImageUrl,
          featureImageCaption: featureImageCaption,
          featureImage: featureImage,
          imageId:
            nextProps.content_detail.data.images &&
            nextProps.content_detail.data.images._id,
          slug: nextProps.content_detail.data.slug,
          language: languageValue,
          publishDate: moment(nextProps.content_detail.data.publishDate),
        },
      });
    }
  }
  showModal = () => {
    this.setState({
      visible: true,
      currentSlide: null,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };
  // Publish confirm modal
  showPublishConfirm = () => {
    this.setState({
      publishConfirm: true,
    });
  };
  hidePublishConfirm = () => {
    this.setState({
      publishConfirm: false,
    });
  };
  // Unpublish confirm modal
  hideUnpublishConfirmModal = () => {
    this.setState({
      unPublishModal: false,
    });
  };

  showUnpublishConfirmModal = () => {
    this.setState({
      unPublishModal: true,
    });
  };

  showEditConfirm = () => {
    this.setState({
      editConfirm: true,
    });
  };

  hideEditConfirm = () => {
    this.setState({
      editConfirm: false,
    });
  };

  _handleChange = (event) => {
    this.setState({
      title: event.target.value,
      isEditting: true,
      titleWarn: event.target.value.length > 60,
    });
  };

  handleEditSlide = (slide) => {
    // Id to look up for.
    const pos = slide.position;
    const { slides } = this.state;
    const tempSlides = slides.slice(); //copy the array
    const index = tempSlides.findIndex((item) => item.position === pos);
    tempSlides[index] = slide;
    // Replace the item by index.
    this.setState({
      slides: tempSlides,
      isEditting: true,
    });
    message.success("Edit slide successfully!");
  };

  handleShowModalEditSlide = (slide) => {
    this.showModal();
    this.setState({
      currentSlide: slide,
    });
  };

  handleDeleteSlide = (pos) => {
    const { slides } = this.state;
    const result = filter(slides, function(slide) {
      return slide.position !== pos;
    });
    this.setState({
      slides: result,
      isEditting: true,
    });
  };
  // handle setting change
  handleArticleSettingChange = (setting) => {
    this.setState({
      setting: setting,
      isEditting: true,
    });
  };

  _handleSubmitEdit = () => {
    const { title, slides, setting, status_article, id } = this.state;
    const slidesParams = slides.map((slide) => {
      return {
        imageId: slide.imageId || slide.images._id,
        content: slide.content,
        position: slide.position,
        imageAlt: slide.imageAlt || slide.images.alt,
        imageCaption: slide.imageCaption || slide.images.caption,
      };
    });

    const settingParams = omit(setting, [
      "author",
      "audio",
      "imageId",
      "writer",
      "previewImage",
      "time_to_publish",
      "other_lang_link",
    ]);
    const data = {
      articleId: id,
      status: status_article,
      title,
      slides: slidesParams,
      seo: {
        description: setting.seoDescription,
      },
      ...settingParams,
      language: setting.language.value,
      image: setting.image || setting.imageId,
      publishDate: setting.publishDate || moment().format(),
      otherLanguageLink: setting.other_lang_link,
      creatorId:
        setting.writer && setting.writer.length > 0
          ? setting.writer[0].value
          : "",
    };
    this.setState({
      isEditting: false,
    });
    this.hideEditConfirm();
    if (TagValidation(data.tags)) {
      return;
    }
    this.props.updateSlideArticle(data);
  };

  _handleSubmitUnPublished = () => {
    const id = this.state.id;
    this.props.submitUnPublished(id);
    this.hideUnpublishConfirmModal();
  };

  _handleSubmitPublished = () => {
    const id = this.state.id;
    this.props.submitPublished(id);
    this.hidePublishConfirm();
  };

  // Generate preview code
  handleGeneratePreviewLink = (id) => {
    const { generatePreviewLinkCode } = this.props;
    const data = {
      articleId: id,
    };
    generatePreviewLinkCode(data);
  };

  render() {
    const {
      title,
      setting,
      currentSlide,
      visible,
      seo,
      slides,
      titleWarn,
      status_article,
      isEditting,
    } = this.state;
    const { content_detail } = this.props;
    return (
      <section className="slideshow-section">
        {status_article === ARTICLE_STATUS.PUBLISHED && (
          <div className="locked-overlay">
            <div className="locked-overlay__content">
              This article is currently locked. <strong>Unpublish</strong> to
              edit the content & setting.
            </div>
          </div>
        )}
        <Row>
          <Col md="8">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between align-items-center pb-0">
                <div className="d-flex w-100 flex-column">
                  <Input
                    placeholder="Title"
                    className={
                      `chief-add-title-input ` + (titleWarn ? "warn" : "")
                    }
                    value={title}
                    onChange={this._handleChange}
                  />
                  <div
                    className={`d-flex ${
                      titleWarn
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {titleWarn && (
                      <small className="text-red">
                        Title ideally shouldn't be longer than 60 characters,
                        but you can still sumbit
                      </small>
                    )}
                    <small className="text-right">
                      {title ? title.length : "0"}/60
                    </small>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="d-flex flex-wrap">
                  {slides.map((slide, index) => {
                    return (
                      <SlideItem
                        key={index}
                        imageUrl={
                          slide.imageUrl ||
                          `${HOST_URL}${get(
                            slide,
                            "images.size.THREE_TWO.url"
                          )}`
                        }
                        title={`Slide ${get(slide, "position")}`}
                        content={get(slide, "content")}
                        handleEditClick={() =>
                          this.handleShowModalEditSlide(slide)
                        }
                        handleDelete={() =>
                          this.handleDeleteSlide(slide.position)
                        }
                      />
                    );
                  })}
                </div>
                <AddSlideForm
                  currentSlide={currentSlide}
                  visible={visible}
                  addSlide={this.handleAddSlide}
                  editSlide={this.handleEditSlide}
                  handleCancel={this.closeModal}
                  handleOk={this.handleSubmit}
                />
              </CardBody>
              {/* SEO */}
              {/* <NewArticleSEOManager
                onChangeSEO={this.handleSEOChange}
                seo={seo}
              /> */}
            </Card>
          </Col>
          <Col md="4">
            <div className="mt--5 d-flex justify-content-end">
              {this.state.isPublished ? (
                <Button
                  size="large"
                  type="primary"
                  className="mr-2"
                  style={{
                    backgroundColor: "#11cdef",
                    borderColor: "#11cdef",
                  }}
                  onClick={this.showUnpublishConfirmModal}
                >
                  Unpublish
                </Button>
              ) : (
                <>
                  {isEditting ? (
                    <Button
                      className="mr-2"
                      type="primary"
                      size="large"
                      style={{
                        backgroundColor: "#008FE8",
                        borderColor: "#008FE8",
                      }}
                      onClick={this.showEditConfirm}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      className="mx-2"
                      type="primary"
                      size="large"
                      onClick={this.showPublishConfirm}
                    >
                      Publish
                    </Button>
                  )}
                </>
              )}
              {/* {status_article === ARTICLE_STATUS.COMPLETED && (
                <Button
                  style={{ backgroundColor: "#ffc917", borderColor: "#ffc917" }}
                  type="primary"
                  size="large"
                  onClick={this._handleSubmitSendBack}
                >
                  Send back
                </Button>
              )} */}
              <Button
                onClick={() =>
                  this.handleGeneratePreviewLink(content_detail.data._id)
                }
                type="button"
                className="d-flex text-primary text-center btn-lg"
                style={{ height: "40px" }}
              >
                <i className="fa fa-eye text-center"></i>
              </Button>
            </div>
            <NewArticleSetting
              isSlideShow={this.props.type === "slideShow"}
              onChangeSetting={this.handleArticleSettingChange}
              setting={setting}
            />
          </Col>
        </Row>
        <ConfirmModal
          closable={false}
          visible={this.state.unPublishModal}
          handleOk={() => this._handleSubmitUnPublished()}
          handleCancel={this.hideUnpublishConfirmModal}
          title="Are you sure to unpublish this article?"
        />
        <ConfirmModal
          closable={false}
          visible={this.state.publishConfirm}
          handleOk={() => this._handleSubmitPublished()}
          handleCancel={this.hidePublishConfirm}
          title="Are you sure to publish this article?"
        />
        <ConfirmModal
          closable={false}
          visible={this.state.editConfirm}
          handleOk={() => this._handleSubmitEdit()}
          handleCancel={this.hideEditConfirm}
          title="Are you sure to update this article?"
        />
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    content_detail: state.chiefArticleReducer.unpublished_detail_by_id,
    preview: state.chiefArticleReducer.preview,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUnPublisedByID: (data) => {
      dispatch(actGetUnPublishedDetailByIDRequest(data));
    },
    // submitSubmitSendBack: (id, data) => {
    //   dispatch(actSubmitSendBackRequest(id, data));
    // },
    submitPublished: (id, data) => {
      dispatch(actSubmitPublishRequest(id, data));
    },
    submitUnPublished: (id, data) => {
      dispatch(actSubmitUnPublishedRequest(id, data));
    },
    updateSlideArticle: (data) => {
      dispatch(actChiefUpdateSlideArticle(data));
    },
    generatePreviewLinkCode: (data) => {
      dispatch(actGeneratePreviewLinkRequest(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefUnPublishedSlideArticleDetail);
ChiefUnPublishedSlideArticleDetail.propTypes = {
  content_detail: PropTypes.object,
  getUnPublisedByID: PropTypes.func,
  submitUnPublished: PropTypes.func,
  submitPublished: PropTypes.func,
  submitSubmitSendBack: PropTypes.func,
  saveDraft: PropTypes.func,
  autoSave: PropTypes.func,
};
ChiefUnPublishedSlideArticleDetail.defaultProps = {};
