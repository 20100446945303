import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  Container
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetEditorialGuidelineRequest } from "../../actions/writerArticle.action";
import {
  actGetChiefEditorialGuidelineRequest,
  actUpdateChiefEditorialGuidelineRequest
} from "../../actions/chiefArticle.action";
import CustomEditor from "../TinyMCE";
import ViewDetailContentWritingModal from "../Modals/ViewDetailContentWritingWriterModal";
class EditorialGuidelineTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        content: ""
      }
    };
  }
  componentDidMount() {
    this._setLoading();
    this.props.getWriterGuideline();
    this.props.getChiefGuideline();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.writer_guideline !== this.props.writer_guideline ||
      nextProps.chief_guideline !== this.props.chief_guideline
    ) {
      this.setState({
        data: {
          content:
            nextProps.writer_guideline.data || nextProps.chief_guideline.data
        }
      });
    }
  }
  _toggleModalView = () => {
    this.setState(prevState => ({
      idView: !prevState.idView
    }));
  };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        content: value
      }
    });
  };
  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };
  _toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  _handleOnPublish = e => {
    e.preventDefault();
    const data = this.state.data.content;
    this.props.updateChiefGuideline(data);
  };
  createMarkup(text) {
    return { __html: text };
  }
  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }
  render() {
    const key = localStorage.getItem("key");
    const { writer_guideline } = this.props;
    const guideline = Object.assign({}, writer_guideline.data);
    const { chief_guideline } = this.props;
    const chief = Object.assign({}, chief_guideline.data);
    const update_guideline = localStorage.getItem("guideline");
    return (
      <>
        <ViewDetailContentWritingModal
          modal={this.state.idView}
          toggle={this._toggleModalView}
          data={this.state.data}
        />
        {key === "chiefeditor" ? (
          <Row className="justify-content-center">
            <Col xl="9">
              <Card style={{ marginTop: "17px" }}>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <h3 className="mb-0">Text Editor</h3>
                  <Button
                    type="button"
                    size="md"
                    className="btn-success btn-lg"
                    onClick={this._handleOnPublish}
                  >
                    Save
                  </Button>
                </CardHeader>
                <CardBody>
                  <Form>
                    <CustomEditor
                      content={
                        chief.guideline
                          ? chief.guideline
                          : update_guideline
                          ? update_guideline
                          : chief.guideline
                      }
                      onChangeContent={this._handleOnContentChange}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Container className="mt--6" fluid>
            <Row className="justify-content-center">
              <Col lg="9" style={{ marginTop: "20px" }}>
                <Card className="" id="style-1">
                  <CardBody className="ql-editor navbar-admin__scroll-container">
                    {this.rawHTML(guideline && guideline.guideline)}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writer_guideline: state.writerArticleReducer.writer_guideline,
    chief_guideline: state.chiefArticleReducer.chief_guideline
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getWriterGuideline: () => {
      dispatch(actGetEditorialGuidelineRequest());
    },
    getChiefGuideline: () => {
      dispatch(actGetChiefEditorialGuidelineRequest());
    },
    updateChiefGuideline: data => {
      dispatch(actUpdateChiefEditorialGuidelineRequest(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorialGuidelineTable);
EditorialGuidelineTable.propTypes = {
  writer_guideline: PropTypes.object,
  getWriterGuideline: PropTypes.func,
  chief_guideline: PropTypes.object,
  getChiefGuideline: PropTypes.func,
  updateChiefGuideline: PropTypes.func
};
EditorialGuidelineTable.defaultProps = {};
