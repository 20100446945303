import React from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import ApplicationDetail from "../../../components/Detail/ApplicationDetail";

function EditorApplicationDetail() {
  return (
    <>
      <CardsHeaderEditor
        name="Application Detail"
        content="Application Detail"
        parentName="Application Management"
        isDetail={true}
      />
      <Container className="mt--6" fluid>
        <ApplicationDetail />
      </Container>
    </>
  );
}
EditorApplicationDetail.propTypes = {
  content: PropTypes.string
};
export default EditorApplicationDetail;
