import { ErrorKey } from "./constants";

const ErrorDefaultMessage = {
  [ErrorKey.INVALID_PARAMS]: "Some fields is invalid. Please check again",
  [ErrorKey.EXISTED_SLUG]: "This slug is already existed. Please check again",
  [ErrorKey.CAN_NOT_DELETE_REPLIED_COMMENT]:
    "Cannot delete comment which is already replied!",
  [ErrorKey.INVALID_THUMBNAIL_IMAGE]: "Please upload thumbnail image!",
  [ErrorKey.SECTION_REQUIRED_1_ARTICLE]:
    "Please select at least one article for all section!",
  [ErrorKey.COVER_STORY_REQUIRED_3_ARTICLES]:
    "Cover story should have at least 3 articles!",
  [ErrorKey.ARTICLE_EXIST_SCHEDULE]:
    "This article has been scheduled. Cannot perform any action on this!",
};

const renderError = (err = {}, extended) => {
  if (extended) {
    return extended;
  }
  const message =
    err.data && err.data.message
      ? ErrorDefaultMessage[err.data.message]
      : "Error! Please check again.";
  const status = err.data && err.data.errorCode ? "" : "500";
  if (message) {
    return `${status} ${message}`;
  }
  return `${status} ${err.data.message}`;
};

export default renderError;
