import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
// import CardsHeader from "components/Headers/CardsHeader.jsx";
import ChiefWriterWritingDetail from "../../../components/Detail/ChiefWriterWritingDetail";
// import CardsHeaderEditor from "../../components/Headers/CardsHeaderEditor";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
class ChiefWriterWriting extends Component {
  state = {
    pathnameRoutes: window.location.pathname.split("/")[2],
  };
  render() {
    const props = this.props;
    // const { pathnameRoutes } = this.state;
    return (
      <>
        <CardsHeaderChief
          name="Chief Editing"
          content="chiefEditing"
          parentName="Article"
        />

        <Container className="mt--7" fluid>
          <ChiefWriterWritingDetail {...props} />
        </Container>
      </>
    );
  }
}
ChiefWriterWriting.propTypes = {
  content: PropTypes.string,
};
export default ChiefWriterWriting;
