import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Container,
  Row,
  Col,
  // Card,
  // CardBody,
  // CardTitle,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";
// import { Link } from "react-router-dom";
// import Button from "components/CustomButton/CustomButton";
import { connect } from "react-redux";
import { actGetHeaderDashboardWrtiterListRequest } from "../../actions/writerArticle.action";
import { Link } from "react-router-dom";

class CardsHeader extends React.Component {
  state = {
    pathnameRoutes: window.location.pathname.split("/")[3]
  };

  componentDidMount() {
    this.props.getHeaderDashBoardWriter();
  }

  render() {
    const pathnameRoutes = this.state.pathnameRoutes;

    return (
      <>
        <div className="header pb-6" style={{ backgroundColor: "#5e72e4" }}>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  {this.props.name !== "Dashboard" && (
                    <Breadcrumb
                      className="d-none d-md-inline-block ml-md-4"
                      listClassName="breadcrumb-links breadcrumb-dark"
                    >
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="fas fa-home" />
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {this.props.parentName}
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem
                        aria-current="page"
                        className="active"
                        style={{ color: "#adb4bd" }}
                      >
                        {this.props.name}
                      </BreadcrumbItem>
                    </Breadcrumb>
                  )}
                </Col>
                {pathnameRoutes === "my-pitches" ? (
                  <Col lg="6 text-right">
                    <Link to={"/admin/writer/new-pitch"}>
                      <button
                        type="button"
                        className="btn-icon btn btn-secondary"
                      >
                        <span className="mr-1">
                          {/* <i className="fab fa-pinterest"></i> */}
                          <i
                            className="fa fa-plus-circle"
                            style={{ color: "#5e72e4" }}
                          ></i>
                        </span>
                        <span
                          className="btn-inner--text"
                          style={{ color: "#5e72e4" }}
                        >
                          Add a New Pitch
                        </span>
                      </button>
                    </Link>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {/* {pathnameRoutes !== "notification" &&
              pathnameRoutes !== "view-detail-content-writer" &&
              pathnameRoutes !== "content-request" &&
              pathnameRoutes !== "task-management" &&
              pathnameRoutes !== "outline-content" &&
              pathnameRoutes !== "my-content" &&
              pathnameRoutes !== "word-processor" &&
              pathnameRoutes !== "writer-writing" &&
              pathnameRoutes !== "my-assigned-post" &&
              pathnameRoutes !== "outline-in-progress" &&
              pathnameRoutes !== "article-in-progress" &&
              pathnameRoutes !== "approved-articles" &&
              pathnameRoutes !== "new-pitch" &&
              pathnameRoutes !== "my-pitches" &&
              pathnameRoutes !== "pitch-detail" &&
              pathnameRoutes !== "available-briefs" &&
              pathnameRoutes !== "available-briefs" &&
              pathnameRoutes !== "editorial-guideline" &&
              pathnameRoutes !== "content-version" &&
              pathnameRoutes !== "outline-version" &&
              pathnameRoutes !== "faq" ? (
                <Row>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ minHeight: 130 }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-warning mb-0"
                            >
                              <Link to="/admin/writer/outline-in-progress">
                                OUTLINE WRITING
                              </Link>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.header_dashboards_writer &&
                                this.props.header_dashboards_writer.data[0]
                                  .outline_writing}
                            </span>
                          </div>

                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i className="fa fa-pen-fancy"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ minHeight: 130 }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-success mb-0"
                            >
                              <Link to="/admin/writer/outline-in-progress">
                                OUTLINE WAITING FOR APPROVAL
                              </Link>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.header_dashboards_writer &&
                                this.props.header_dashboards_writer.data[0]
                                  .outline_waiting}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                              <i className="fa fa-file-signature"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ minHeight: 130 }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-warning mb-0"
                            >
                              <Link to="/admin/writer/article-in-progress">
                                ARTICLE WRITING
                              </Link>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.header_dashboards_writer &&
                                this.props.header_dashboards_writer.data[0]
                                  .content_writing}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange  text-white rounded-circle shadow">
                              <i className="fa fa-pen-fancy"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ minHeight: 130 }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-success mb-0"
                            >
                              <Link to="/admin/writer/article-in-progress">
                                ARTICLE WAITING FOR APPROVAL
                              </Link>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.header_dashboards_writer &&
                                this.props.header_dashboards_writer.data[0]
                                  .content_waiting}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-success  text-white rounded-circle shadow">
                              <i className="fa fa-file-signature"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                ""
              )} */}
            </div>
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    header_dashboards_writer:
      state.writerArticleReducer.header_dashboards_writer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getHeaderDashBoardWriter: () => {
      dispatch(actGetHeaderDashboardWrtiterListRequest());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsHeader);
CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  content: PropTypes.string,
  header_dashboards_writer: PropTypes.object,
  getHeaderDashBoardWriter: PropTypes.func
};
CardsHeader.defaultProps = {};
