import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input
} from "reactstrap";
import PropTypes from "prop-types";
import CustomEditor from "../TinyMCE";
import { connect } from "react-redux";
import GeneralInfoModal from "../Modals/GeneralInfoModal";
import { actGetRecruitmentDetailRequest } from "../../actions/general-info.action";

class CardRecruitment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublishRecruit: false,
      languagueKey: true,
      data: {
        Recruitment: ""
      }
    };
  }

  componentDidMount() {
    let params = {
      key: "Recruitment_key",
      key_language: "VN"
    };
    this.props.getRecruitmentDetail(params);
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.recruitment_detail &&
      nextProps.recruitment_detail !== this.props.recruitment_detail
    ) {
      this.setState({
        data: this.props.recruitment_detail.details
      });
    }
  }

  _handleOnChange = e => {
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data
    });
  };

  _handleOnContentChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value
      }
    });
  };

  _toggleModalPublish = () => {
    this.setState(prevState => ({
      isPublishRecruit: !prevState.isPublishRecruit
    }));
  };

  _switchLanguage = e => {
    const newLanguage = e.target.checked;
    this.setState({
      languagueKey: newLanguage
    });
    if (newLanguage) {
      let params = {
        key: "Recruitment_key",
        key_language: "VN"
      };
      this.props.getRecruitmentDetail(params);
    } else {
      let params = {
        key: "Recruitment_key",
        key_language: "EN"
      };
      this.props.getRecruitmentDetail(params);
    }
  };

  render() {
    const { data } = this.state;

    return (
      <>
        <GeneralInfoModal
          modal={this.state.isPublishRecruit}
          toggle={this._toggleModalPublish}
          recruitmentDetail={this.state.data}
          title={"Publish information?"}
          message={"Are you sure want to publish information recruitment?"}
          type="recruitment"
          languagueKey={this.state.languagueKey}
        />
        <Card>
          <CardHeader className="border-0 d-flex justify-content-between">
            <h3 className="mb-0">Recruitment</h3>
            <div className="general-info-card d-flex">
              <span className="mr-2">Show contents: </span>
              <label className="custom-toggle custom-toggle-warning mr-1">
                <input
                  checked={this.state.languagueKey}
                  onChange={this._switchLanguage}
                  type="checkbox"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="EN"
                  data-label-on="VN"
                />
              </label>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <FormGroup>
              <label className="form-control-label d-block" htmlFor="email">
                Form URL
              </label>
              <Input
                id="link"
                name="link"
                type="text"
                className="d-inline mr-3"
                placeholder="Your recruitment form url"
                value={data && data.link}
                onChange={this._handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <CustomEditor
                content={data && data.Recruitment}
                onChangeContent={value =>
                  this._handleOnContentChange("Recruitment", value)
                }
              />
            </FormGroup>
            <div className="text-right">
              <Button
                className="btn btn-success"
                onClick={this._toggleModalPublish}
              >
                Publish
              </Button>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    recruitment_detail: state.generalInfoReducer.recruitment_detail
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getRecruitmentDetail: data => {
      dispatch(actGetRecruitmentDetailRequest(data));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardRecruitment);
CardRecruitment.propTypes = {
  recruitment_detail: PropTypes.object,
  getRecruitmentDetail: PropTypes.func
};
CardRecruitment.defaultProps = {};
