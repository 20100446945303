import React, { Component } from "react";
import { Card, CardHeader, CardBody, Button, Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  actGetWritingContentByIDRequest,
  actSubmitWritingContentRequest,
  actSaveWritingContentRequest,
  actAutoSaveWritingContentRequest,
  actSubmitWriterWritingContentRequest,
  actGetContentVersionsRequest
} from "../../actions/writerArticle.action";
import Feedback from "../Feedback/Feedback";
import CustomEditor from "../TinyMCE";

// import { Link } from "react-router-dom";
import ViewDetailContentWritingModal from "../Modals/ViewDetailContentWritingWriterModal";
import { actSubmitEditingRequest } from "../../actions/editorArticle.action";
import VersionSelect from "../Selects/VersionSelect";
import getUrlParam from "../../utils/getUrlParam";

class ContentVersionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      id: window.location.pathname.split("/")[4],
      is_senior: localStorage.getItem("is_senior") === "true",
      data: {
        content: ""
      },
      idView: false,
      autoSave: false,
      isWritting: false,
      version: getUrlParam()["version"]
    };
  }

  componentDidMount() {
    this._setLoading();
    this._getWritingContentByID(this.state.id);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const version = this.state.version;
    if (nextProps.content_versions !== this.props.content_versions) {
      console.log(nextProps.content_versions);
      const versionData =
        nextProps.content_versions && nextProps.content_versions[version - 1];
      this.setState({
        data: versionData
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
  }

  _toggleModalView = () => {
    this.setState(prevState => ({
      idView: !prevState.idView
    }));
  };

  _getWritingContentByID = id => {
    this.props.getWritingContentByID(id);
  };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        content: value
      },
      autoSave: true
    });
  };

  _handleOnSubmitContent = () => {
    const id = this.state.id;
    const data = this.state.data;
    this.props.submitContent(id, data);
  };

  _handleOnSubmitEditingContent = () => {
    const id = this.state.id;
    const data = this.state.data;
    data.status = "APPROVED";
    this.props.submitEditingContent(id, data);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    // this.props.getBannerList(params);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  _handleOnViewDetail = id => {
    this._toggleModalView();
  };

  _handleOnSaveDraftContent = e => {
    e.preventDefault();
    const writing_content = this.props.writing_content.data[0];
    const data = {
      idContent: writing_content.contentID,
      title: writing_content.angle_title,
      content: this.state.data.content
    };
    this.props.saveWritingContent(data);
  };

  _handleOnVersionSelect = index => {
    var url = "";
    const role = window.location.pathname.split("/")[2];
    switch (role) {
      case "writer":
        url = `/admin/writer/content-version/${this.state.id}?version=${index}`;
        break;
      case "editor":
        url = `/admin/editor/content-version/${this.state.id}?version=${index}`;
        break;
      default:
        url = `/admin/chief/content-version/${this.state.id}?version=${index}`;
        break;
    }
    window.open(url, "_blank");
  };

  render() {
    const { writing_content, content_versions } = this.props;
    const currentVersion = "Version " + parseInt(this.state.version);

    return (
      <>
        <ViewDetailContentWritingModal
          modal={this.state.idView}
          toggle={this._toggleModalView}
          data={this.props.writing_content}
        />
        <Row>
          <Col xl="9">
            <Card style={{ marginTop: "17px" }}>
              <CardHeader className="d-flex justify-content-between">
                <h3 className="mb-0">Content Version</h3>
                <VersionSelect
                  label={currentVersion}
                  versions={content_versions}
                  onVersionSelect={this._handleOnVersionSelect}
                />
              </CardHeader>
              <CardBody>
                <div className="pb-4">
                  <h2>
                    {writing_content.data &&
                      writing_content.data[0].angle_title}
                  </h2>
                  <Row>
                    <Col lg="10">
                      <a
                        target="blank"
                        href={`/admin/writer/outline-content/${this.state.id}`}
                      >
                        <h5 className="text-primary">{"View outline >>"}</h5>
                      </a>
                    </Col>
                    <Col lg="2" className="text-right">
                      {this.props.writing_content.data[0].status_article ===
                        "PUBLISHED" ||
                      this.props.writing_content.data[0].status_article ===
                        "REJECTED" ||
                      this.props.writing_content.data[0].status_article ===
                        "EDITING" ||
                      this.props.writing_content.data[0].status_article ===
                        "COMPLETED" ||
                      this.props.writing_content.data[0].status_article ===
                        "CONTENT_SUBMITTED" ? (
                        ""
                      ) : (
                        <>
                          <a
                            href="#pablo"
                            style={{ fontWeight: "bold" }}
                            onClick={this._handleOnSaveDraftContent}
                          >
                            Save draft
                          </a>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
                <CustomEditor
                  content={this.state.data.content}
                  onChangeContent={this._handleOnContentChange}
                  readOnly={!this.state.isWritting}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xl="3" className={"toolbar-right"}>
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                size="lg"
                className="btn-lg"
                color="success"
                style={{ width: "85%" }}
                disabled={
                  this.state.data.content === "" ||
                  this.state.data.content === "<p><br></p>" ||
                  !this.state.isWritting
                }
                onClick={this._handleOnSubmitContent}
              >
                <i className="ni ni-send mr-2"></i>
                Send
              </Button>
              <a
                target="blank"
                href={`/admin/writer/view-detail-content-writer/${this.state.id}`}
              >
                <Button
                  type="button"
                  className="text-primary text-center btn-lg"
                >
                  <i className="fa fa-eye text-center"></i>
                </Button>
              </a>
            </div>
            <Feedback article_id={this.state.id} />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writing_content: state.writerArticleReducer.writing_content_id,
    content_versions: state.writerArticleReducer.content_versions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getWritingContentByID: id => {
      dispatch(actGetWritingContentByIDRequest(id));
    },
    getContentVersions: id => {
      dispatch(actGetContentVersionsRequest(id));
    },
    submitWritingContent: (id, data) => {
      dispatch(actSubmitWritingContentRequest(id, data));
    },
    saveWritingContent: data => {
      dispatch(actSaveWritingContentRequest(data));
    },
    AutoSaveContent: data => {
      dispatch(actAutoSaveWritingContentRequest(data));
    },
    submitEditingContent: (id, data) => {
      dispatch(actSubmitEditingRequest(id, data));
    },
    submitContent: (id, data) => {
      dispatch(actSubmitWriterWritingContentRequest(id, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentVersionDetail);
ContentVersionDetail.propTypes = {
  writing_content: PropTypes.object,
  content_versions: PropTypes.array,
  getWritingContentByID: PropTypes.func,
  submitWritingContent: PropTypes.func,
  saveWritingContent: PropTypes.func,
  AutoSaveContent: PropTypes.func,
  submitEditingContent: PropTypes.func,
  submitContent: PropTypes.func,
  getContentVersions: PropTypes.func
};
ContentVersionDetail.defaultProps = {};
