import callApi from "../utils/callApi";
import moment from "moment";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actResetSearchFeatureArticles = () => {
  return {
    type: Types.RESET_SEARCH_FEATURE_ARTICLE
  };
};

export const actGetSearchFeatureArticlesStart = () => {
  return {
    type: Types.GET_SEARCH_FEATURE_ARTICLE_START
  };
};

export const actGetSearchFeatureArticlesFail = () => {
  return {
    type: Types.GET_SEARCH_FEATURE_ARTICLE_FAIL
  };
};

export const actGetSearchFeatureArticles = data_search => {
  return {
    type: Types.GET_SEARCH_FEATURE_ARTICLE,
    data_search
  };
};

export const actGetSearchFeatureArticlesRequest = data => {
  return dispatch => {
    dispatch(actGetSearchFeatureArticlesStart());
    return callApi(
      `v2/not-feature-article?searchKey=${data.search}&page=1&limit=50&language=${data.language}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetSearchFeatureArticles(res.data));
      })
      .catch(err => {
        dispatch(actGetSearchFeatureArticlesFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// GET GROUP ARTICLE SECTION
export const actGetFeatureArticleGroupSection = () => {
  return {
    type: Types.GET_GROUP_FEATURE_ARTICLE_SECTION
  };
};

export const actGetFeatureArticleGroupSectionSuccess = data => {
  return {
    type: Types.GET_GROUP_FEATURE_ARTICLE_SECTION_SUCCESS,
    data
  };
};

export const actGetFeatureArticleGroupSectionFail = () => {
  return {
    type: Types.GET_GROUP_FEATURE_ARTICLE_SECTION_FAIL
  };
};

export const actGetFeatureArticleGroupSectionRequest = () => {
  return dispatch => {
    dispatch(actGetFeatureArticleGroupSection());
    return callApi(`v2/schedule/group-section`, "GET")
      .then(res => {
        if (res && res.data) {
          const data = get(res, "data.docs", []);
          dispatch(actGetFeatureArticleGroupSectionSuccess(data));
        }
      })
      .catch(err => {
        dispatch(actGetFeatureArticleGroupSectionFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// Add feature arrticle temporary
export const actAddFeatureArticleTemp = (data, key) => {
  return {
    type: Types.ADD_FEATURE_ARTICLE_TEMP,
    data,
    key
  };
};
export const actDeleteFeatureArticleTemp = data => {
  return {
    type: Types.DELETE_FEATURE_ARTICLE_TEMP,
    data
  };
};
export const actEditFeatureArticleTemp = data => {
  return {
    type: Types.EDIT_FEATURE_ARTICLE_TEMP,
    data
  };
};
export const actSetFeatureArticleForEdit = data => {
  return {
    type: Types.SET_CURRENT_FEATURE_ARTICLE_FOR_EDIT,
    data
  };
};
export const actResetFeatureArticle = () => {
  return {
    type: Types.RESET_CURRENT_FEATURE_ARTICLE
  };
};

// Get feature article schedule by date
export const actGetScheduleByDate = () => {
  return {
    type: Types.GET_SCHEDULE_BY_DATE
  };
};

export const actGetScheduleByDateFail = () => {
  return {
    type: Types.GET_SCHEDULE_BY_DATE_FAIL
  };
};

export const actGetScheduleByDateSuccess = data => {
  return {
    type: Types.GET_SCHEDULE_BY_DATE_SUCCESS,
    data
  };
};
export const actResetSchedule = () => {
  return {
    type: Types.RESET_SCHEDULE
  };
};

export const setSelectedDate = date => {
  return {
    type: Types.SET_SELECTED_DATE,
    date
  };
};

export const disableModalInform = () => {
  return {
    type: Types.CLOSE_INFORM_MODAL
  };
};

export const showModalInform = () => {
  return {
    type: Types.SHOW_INFORM_MODAL
  };
};

export const actGetScheduleByDateRequest = data => {
  return dispatch => {
    dispatch(actGetScheduleByDate());
    return callApi(
      `v2/schedule/homepage-feature?date=${data.date}&language=${data.lang ||
        "VN"}`,
      "GET"
    )
      .then(res => {
        // If that date has schedule set to Redux state to perform action
        if (isEmpty(res.data) && data.date === moment().format("YYYY-MM-DD")) {
          dispatch(showModalInform());
        }
        if (res && res.data && res.data.schedule) {
          dispatch(actGetScheduleByDateSuccess(res.data.schedule));
          return;
        }
        dispatch(actResetSchedule());
      })
      .catch(err => {
        dispatch(actGetScheduleByDateFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// Get active schedule between date range
export const actGetScheduledDates = () => {
  return {
    type: Types.GET_SCHEDULED_DATES
  };
};

export const actGetScheduledDatesFail = () => {
  return {
    type: Types.GET_SCHEDULED_DATES_FAIL
  };
};

export const actGetScheduledDatesSuccess = data => {
  return {
    type: Types.GET_SCHEDULED_DATES_SUCCESS,
    data
  };
};
export const actGetScheduledDatesRequest = data => {
  return dispatch => {
    dispatch(actGetScheduledDates());
    return callApi(
      `v2/schedule/date-range?startDate=${data.startDate}&endDate=${
        data.enđDate
      }&language=${data.lang || "VN"}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetScheduledDatesSuccess(res.data.schedules));
      })
      .catch(err => {
        dispatch(actGetScheduledDatesFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSaveScheduleSetting = () => {
  return {
    type: Types.SAVE_SCHEDULE
  };
};
export const actSaveScheduleSettingSuccess = () => {
  return {
    type: Types.SAVE_SCHEDULE_SUCCESS
  };
};
export const actSaveScheduleSettingFail = () => {
  return {
    type: Types.SAVE_SCHEDULE_FAIL
  };
};
//Save schedule
export const actSaveScheduleSettingRequest = data => {
  return dispatch => {
    dispatch(actSaveScheduleSetting());
    return callApi(`v2/schedule/homepage-feature`, "POST", data)
      .then(res => {
        if (res) {
          const payloadToRefetch = {
            date: data.date
          };
          dispatch(actSaveScheduleSettingSuccess());
          dispatch(actGetScheduleByDateRequest(payloadToRefetch));
          dispatch(
            actNotify({
              message: "Schdule has been saved successfully!",
              color: "success"
            })
          );
        }
      })
      .catch(err => {
        dispatch(actSaveScheduleSettingFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
