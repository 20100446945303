import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import ChiefAssignedRequestTable from "../../../components/Tables/ChiefAssignedRequestTable";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
class ChiefAssignedPost extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Assigned Briefs"
          content="assignedBriefs"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <ChiefAssignedRequestTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefAssignedPost.propTypes = {
  content: PropTypes.string,
};
export default ChiefAssignedPost;
