import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import {
  actNotify,
  actFetchResourceSuccess,
  actFetchResourceFail
} from "./fetch.action";
import renderError from "utils/renderServerError";
import {
  actGetCountInfoEditorRequest,
  actGetCountInfoChiefEditorRequest
} from "./permission.action";

export const actGetPostInfoRequest = () => {
  return dispatch => {
    return callApi(`article/get-info-for-create`, "GET")
      .then(res => {
        dispatch(actGetPostInfo(res));
        dispatch(actGetTopicsName(res));
        dispatch(actGetWriterList(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPostInfoRequestV2 = () => {
  return dispatch => {
    return callApi(`v2/article/default-setting`, "GET")
      .then(res => {
        dispatch(actGetPostInfo(res));
        dispatch(actGetTopicsName(res));
        // dispatch(actGetWriterList(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPostInfo = data => {
  return {
    type: Types.GET_POST_INFO,
    data
  };
};

export const actGetTopicsName = data => {
  return {
    type: Types.GET_TOPICS_NAME,
    data
  };
};

export const actGetWriterList = data => {
  return {
    type: Types.GET_WRITER_LIST,
    data
  };
};

export const actGetPostDetailRequest = id => {
  const jobKey = localStorage.getItem("key");
  return dispatch => {
    return callApi(`articles/${id}`, "GET")
      .then(res => {
        dispatch(actGetPostDetail(res));
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        } else {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// V2 get brief detail
export const actGetBriefDetailRequest = id => {
  const jobKey = localStorage.getItem("key");
  return dispatch => {
    return callApi(`v2/brief/${id}`, "GET")
      .then(res => {
        dispatch(actGetPostDetail(res));
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        } else {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPostDetail = data => {
  return {
    type: Types.GET_POST_DETAIL,
    data
  };
};

export const actGetTagsByTopicIDRequest = id => {
  return dispatch => {
    return callApi(`topics/${id}`, "GET")
      .then(res => {
        dispatch(actGetTagsByTopicID(res.data.tags));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetTagsByTopicID = data => {
  return {
    type: Types.GET_TAGS_BY_TOPIC,
    data
  };
};

// V2 create new brief
export const actCreateNewBriefRequest = data => {
  const jobKey = localStorage.getItem("key");
  return dispatch => {
    return callApi(`v2/brief`, "POST", data)
      .then(res => {
        if (data.draft === "SHOW") {
          let url = jobKey === "editor" ? "editor" : "chief";
          if (data.is_on_market === true) {
            dispatch(
              actFetchResourceSuccess({
                message: "Created new brief succesfully",
                confirmTo: "/admin/" + url + "/available-briefs"
              })
            );
            jobKey === "editor"
              ? dispatch(actGetCountInfoEditorRequest())
              : dispatch(actGetCountInfoChiefEditorRequest());
          } else {
            dispatch(
              actFetchResourceSuccess({
                message: "Created new brief succesfully",
                confirmTo: "/admin/" + url + "/assigned-briefs"
              })
            );
            jobKey === "editor"
              ? dispatch(actGetCountInfoEditorRequest())
              : dispatch(actGetCountInfoChiefEditorRequest());
          }
          return;
        } else {
          if (jobKey === "editor") {
            dispatch(
              actFetchResourceSuccess({
                message: "Brief saved as draft",
                confirmTo: "/admin/editor/draft-briefs"
              })
            );
            dispatch(actGetCountInfoEditorRequest());
            return;
          } else {
            dispatch(
              actFetchResourceSuccess({
                message: "Brief saved as draft",
                confirmTo: "/admin/chief/draft-briefs"
              })
            );
            dispatch(actGetCountInfoChiefEditorRequest());
          }
        }
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// V2 Update brief
export const actUpdatePostRequest = (id, data) => {
  const jobKey = localStorage.getItem("key");
  return dispatch => {
    return callApi(`v2/brief/${id}`, "PUT", data)
      .then(res => {
        if (data.draft === "SHOW") {
          let url = jobKey === "editor" ? "editor" : "chief";
          if (data.is_on_market === true) {
            dispatch(
              actFetchResourceSuccess({
                message: "Updated brief succesfully",
                confirmTo: "/admin/" + url + "/available-briefs"
              })
            );
            jobKey === "editor"
              ? dispatch(actGetCountInfoEditorRequest())
              : dispatch(actGetCountInfoChiefEditorRequest());
          } else {
            dispatch(
              actFetchResourceSuccess({
                message: "Updated brief succesfully",
                confirmTo: "/admin/" + url + "/assigned-briefs"
              })
            );
            jobKey === "editor"
              ? dispatch(actGetCountInfoEditorRequest())
              : dispatch(actGetCountInfoChiefEditorRequest());
          }
        } else {
          if (jobKey === "editor") {
            dispatch(
              actFetchResourceSuccess({
                message: "Brief saved as draft",
                confirmTo: "/admin/editor/draft-briefs"
              })
            );
            dispatch(actGetCountInfoEditorRequest());
          } else {
            dispatch(
              actFetchResourceSuccess({
                message: "Brief saved as draft",
                confirmTo: "/admin/chief/draft-briefs"
              })
            );
            dispatch(actGetCountInfoChiefEditorRequest());
          }
        }
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actUpdatePostRequestV1 = (id, data) => {
  const jobKey = localStorage.getItem("key");
  return dispatch => {
    return callApi(`articles/${id}`, "PUT", data)
      .then(res => {
        if (data.draft === "SHOW") {
          let url = jobKey === "editor" ? "editor" : "chief";
          if (data.is_on_market === true) {
            dispatch(
              actFetchResourceSuccess({
                message: "Updated brief succesfully",
                confirmTo: "/admin/" + url + "/available-briefs"
              })
            );
            jobKey === "editor"
              ? dispatch(actGetCountInfoEditorRequest())
              : dispatch(actGetCountInfoChiefEditorRequest());
          } else {
            dispatch(
              actFetchResourceSuccess({
                message: "Updated brief succesfully",
                confirmTo: "/admin/" + url + "/assigned-briefs"
              })
            );
            jobKey === "editor"
              ? dispatch(actGetCountInfoEditorRequest())
              : dispatch(actGetCountInfoChiefEditorRequest());
          }
        } else {
          if (jobKey === "editor") {
            dispatch(
              actFetchResourceSuccess({
                message: "Brief saved as draft",
                confirmTo: "/admin/editor/draft-briefs"
              })
            );
            dispatch(actGetCountInfoEditorRequest());
          } else {
            dispatch(
              actFetchResourceSuccess({
                message: "Brief saved as draft",
                confirmTo: "/admin/chief/draft-briefs"
              })
            );
            dispatch(actGetCountInfoChiefEditorRequest());
          }
        }
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actDeletePostRequest = id => {
  const jobKey = localStorage.getItem("key");
  return dispatch => {
    return callApi(`articles/${id}`, "DELETE")
      .then(res => {
        if (jobKey === "editor") {
          dispatch(
            actFetchResourceSuccess({
              message: "Deleted brief succesfully",
              confirmTo: "/admin/editor/available-briefs"
            })
          );
          dispatch(actGetCountInfoEditorRequest());
        } else {
          dispatch(
            actFetchResourceSuccess({
              message: "Deleted brief succesfully",
              confirmTo: "/admin/chief/available-briefs"
            })
          );
          dispatch(actGetCountInfoChiefEditorRequest());
        }
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetEditorListRequest = () => {
  return dispatch => {
    return callApi(`list-editor-user`, "GET")
      .then(res => {
        dispatch(actGetEditorList(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetEditorList = data => {
  return {
    type: Types.GET_EDITOR_LIST,
    data
  };
};

export const actCreateBriefRequest = data => {
  return dispatch => {
    return callApi(`v2/article/create-brief-for-editor`, "POST", data)
      .then(() => {
        dispatch(
          actFetchResourceSuccess({
            message: "Create Brief successfully !",
            confirmTo: "/admin/editor/dashboard-editor"
          })
        );
      })
      .catch(() => {
        dispatch(
          actFetchResourceFail({
            message: "Error! Please try again later",
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actCreateTranslateBriefRequest = (id, data) => {
  return dispatch => {
    return callApi(`article/coe-create-article-translate/${id}`, "POST", data)
      .then(() => {
        if (data.is_on_market === true) {
          dispatch(
            actFetchResourceSuccess({
              message: "Created new brief succesfully",
              confirmTo: "/admin/chief/available-briefs"
            })
          );
          dispatch(actGetCountInfoChiefEditorRequest());
        } else {
          dispatch(
            actFetchResourceSuccess({
              message: "Created new brief succesfully",
              confirmTo: "/admin/chief/assigned-briefs"
            })
          );
          dispatch(actGetCountInfoChiefEditorRequest());
        }
      })
      .catch(() => {
        dispatch(
          actFetchResourceFail({
            message: "Error! Please try again later",
            confirmTo: window.location.pathname
          })
        );
      });
  };
};
