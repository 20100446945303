import React, { useEffect, useState } from "react";
import callApi from "../../../utils/callApi";
import { makeUrlObj } from "../../../utils/makeUrl";
import DropDownDate from "../DropDownDate"
import FilterLanguage from "../FilterLanguage"
import FilterDateRanger from "../FilterDateRanger"
import moment from "moment";
import get from "lodash/get"
import { Table,  Row, Col } from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "article.title",
    key: "name",
  },
  {
    title: "Topic",
    dataIndex: "article.topic[0].name",
    key: "topic",
  },
  {
    title: "Published Date",
    dataIndex: "article.publishDate",
    key: "Published Date",
    render: (value, row) => {
      const publishDate = get(row, "article.publishDate", "");
      return (
        <div className=" d-flex  align-items-center">
          { moment(publishDate).format('DD-MM-YYYY')}
             {/*  {moment(row.article.publishDate)} */}
        </div>
      )
    }
  },
  {
    title: "Views",
    dataIndex: "total",
    key: "total",
  },
];


function TableTopArticle() {
  const [articles, setArticles] = useState([]);
  const [query, setQuery] = useState({
    startDate: moment().subtract('days', 7),
    endDate: moment(),
    language: "VN",
  });

  const onChangeDate = function ({startDate, endDate}) {
    setQuery({ ...query, startDate, endDate });
  }
  const onChangeLanguage = function (e) {
    setQuery({ ...query, language: e.key });
  }

  const onChangeRangePicker = function (value, dateString) {
    setQuery({ ...query, startDate: value[0],  endDate: value[1]});
  };
  const fetchTopArticle = () => {
    callApi(`v2/dashboard/top-article?${makeUrlObj(query)}`, "GET")
      .then((res) => {
        setArticles(res.data)
    })
    .catch((error) => console.log(error));
  }
  useEffect(() => {
    fetchTopArticle()
  }, []);
  useEffect(() => {
    fetchTopArticle()
  }, [query]);

  return (
    <div>
      <h2 style={{  marginBottom: "20px" }}>Top articles</h2>
      <Table
        dataSource={articles}
        columns={columns}
        pagination={false}
        footer={() => (
          <Row gutter={24}  type="flex"  align="middle">
            <Col className="gutter-row" span={5}>
              <DropDownDate onChange={onChangeDate}/>
            </Col>
            <Col className="gutter-row" span={12}>
              <FilterDateRanger onChange={onChangeRangePicker}/>
            </Col>
            <Col className="gutter-row" span={7}>
              <FilterLanguage query={query} onChangeLanguage={onChangeLanguage} />
            </Col>
          </Row>
        )}
      />
    </div>
  );
}

export default TableTopArticle;
