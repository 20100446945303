import React, { Component } from "react";
import { Card, CardHeader, Table, Row } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { countTimeDeadline } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import Avatar from "../Avatar/Avatar";
import { actGetOutlineChiefDashboardRequest } from "../../actions/chief-dashboard.action";
import { Link } from "react-router-dom";
import CardBodyWithLoading from "../CardBodyWithLoading/CardBody";
import { Tooltip } from "antd";
import ReactHtmlParser from "react-html-parser";

class ChiefOutlineDashboardTable extends Component {
  state = {
    params: {
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 5,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "updatedAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getChiefOutlineDashboard();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 3000);
  };

  _getChiefOutlineDashboard = () => {
    const params = this.state.params;
    this.props.getChiefOutlineDashboard(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _text_truncate = (str, length, end) => {
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this.props.getChiefOutlineDashboard(paramsSort);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/chief/outline-content/${id}`);
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 80) {
      return `${str[0].substring(0, 80)}...`;
    } else {
      return str[0].substring(0, 80);
    }
  };

  render() {
    const { outlines_dashboard } = this.props;

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="header-scroll border-0">
                <Row className="header-top">
                  <div className="table-header">
                    <h3 className="text-header mb-0">Outline</h3>
                  </div>
                  <div className="header-info">
                    <span className="info-approval">
                      Waiting for Approval
                      <p>
                        <Link to={"/admin/chief/outline-in-progress"}>
                          View more
                        </Link>
                      </p>
                    </span>
                    <span className="info-number">
                      {outlines_dashboard.waitingForApproval}
                    </span>
                  </div>
                </Row>
              </CardHeader>
              <CardBodyWithLoading
                isLoading={this.state.isLoading}
                className="p-0"
              >
                <div className="table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "40%" }}>Title</th>

                        <th>Writer</th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("end_date")}
                        >
                          Deadline
                        </th>
                        <th>Editor</th>
                        <th className="text-center">Dual Language</th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {outlines_dashboard.docs &&
                        outlines_dashboard.docs.length !== 0 &&
                        outlines_dashboard.docs.map((prop, key) => {
                          return (
                            <tr
                              key={key}
                              className="cursor-pointer"
                              onClick={e =>
                                this._handleOnCellClick(e, prop._id)
                              }
                            >
                              <th scope="row">
                                <Tooltip
                                  title={ReactHtmlParser(prop.angle_title)}
                                  placement="bottom"
                                >
                                  <span>
                                    {this.limitTitle(
                                      ReactHtmlParser(prop.angle_title)
                                    )}
                                  </span>
                                </Tooltip>
                              </th>

                              <td className="table-user">
                                <Avatar
                                  src={prop.writer.avatar}
                                  className="d-inline-block mr-3"
                                />
                                <b>{prop.writer ? prop.writer.name : ""}</b>
                              </td>
                              <td className="text-danger font-weight-bold">
                                {countTimeDeadline(prop.end_date)}
                              </td>
                              <td className="table-user">
                                <Avatar
                                  src={prop.editor.avatar}
                                  className="d-inline-block mr-3"
                                />
                                <b>{prop.editor ? prop.editor.name : ""}</b>
                              </td>
                              <td className="text-center text-success">
                                {prop.dualLanguage !== null &&
                                  prop.dualLanguage && (
                                    <i
                                      className="fa fa-check-circle"
                                      style={{ fontSize: 16 }}
                                    ></i>
                                  )}
                              </td>
                              <td>
                                {prop
                                  ? prop.topic.map(topic => {
                                    return (
                                      <>
                                        <div className="db_topic">
                                          <label>
                                            {this._text_truncate(
                                              topic.name
                                                ? topic.name
                                                : topic.label,
                                              20,
                                              "..."
                                            )}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {outlines_dashboard &&
                    outlines_dashboard.docs &&
                    outlines_dashboard.docs.length === 0 && (
                      <div
                        className="text-center"
                        style={{ color: "#4B556F", padding: "5%" }}
                      >
                        <i
                          className="icon-search"
                          style={{ fontSize: "70px" }}
                        />
                        <p>No Data</p>
                      </div>
                    )}
                </div>
              </CardBodyWithLoading>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    outlines_dashboard: state.chiefDashboardReducer.outlines_dashboard
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getChiefOutlineDashboard: params => {
      dispatch(actGetOutlineChiefDashboardRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefOutlineDashboardTable);
ChiefOutlineDashboardTable.propTypes = {
  outlines_dashboard: PropTypes.object,
  getChiefOutlineDashboard: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};
ChiefOutlineDashboardTable.defaultProps = {};
