import React from "react";
import { Menu, Dropdown } from "antd";
import { ReactComponent as ArrowDown } from "../DropDownDate/arrowdown.svg";

const FilterLanguage = function({ query, onChangeLanguage }) {
  const Language = (
    <Menu>
      <Menu.Item onClick={onChangeLanguage} key="VN">
        Vietnamese edition
      </Menu.Item>
      <Menu.Item onClick={onChangeLanguage} key="EN">
        English edition
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={Language}>
        <a onClick={(e) => e.preventDefault()}>
          {query.language === "VN" ? "Vietnamese edition" : "English edition"}
          <ArrowDown style={{ marginLeft: "5px" }} />
        </a>
      </Dropdown>
    </>
  );
};

export default FilterLanguage;
