import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardFooter, Button, Input } from "reactstrap";
import CardBody from "components/CardBodyWithLoading/CardBody";
import COMMENT from "../../assets/img/icons/round-comment-24px.svg";
import Comment from "./Comment";
import { connect } from "react-redux";
import {
  actAddFeedbackRequest,
  actAddReplyRequest,
  actChangeFeedbackStatusRequest,
  actResetFeedbackList
} from "../../actions/feedback.action";
import Avatar from "../Avatar/Avatar";
import { COMMENT_STATUS } from "../../utils/constants";

class Feedback extends Component {
  state = {
    isInputCommentShow: false,
    isEdittingCommentShow: false,
    role: localStorage.getItem("key")
  };

  componentWillUnmount() {
    this.props.resetFeedback();
  }

  handleShowInputComment = () => {
    let scrollContainer = document.getElementById("scroll-container");
    setTimeout(() => {
      scrollContainer.scrollTo(0, scrollContainer.scrollHeight);
    }, 200);
    this.setState({ isInputCommentShow: true });
  };

  handleCloseInputComment = () => {
    this.setState({ isInputCommentShow: false });
  };

  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleOnSubmit();
    }
  };

  handleOnSubmit = () => {
    const data = {
      content: this.state.comment,
      outlineId: this.props.outline_id
    };
    if (this.state.comment) {
      this.props.addFeedback(data);
      this.handleCloseInputComment();
      this.setState({ comment: "" });
    }
  };

  render() {
    const props = this.props;
    const { my_information, loading } = props;
    return (
      <Card className="card-feedback mt-3">
        <CardHeader>
          <div className="align-items-center">
            <div className="text-center">
              <img className="cursor-pointer mr-1" src={COMMENT} alt="" />
              <span className="mr-1 font-weight-bold">Feedback</span>
            </div>
          </div>
        </CardHeader>
        <CardBody isLoading={loading} padding={3} id="scroll-container">
          {this.props.feedback_list.length === 0 &&
            !this.state.isInputCommentShow && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "360px",
                  color: "#ccc",
                  fontStyle: "italic"
                }}
              >
                There are no feedbacks in this article.
              </div>
            )}
          {this.props.feedback_list.length > 0 &&
            this.props.feedback_list
              .filter(item => item.status !== COMMENT_STATUS.DELETED)
              .map((prop, key) => {
                return <Comment comment={prop} key={key} {...props} />;
              })}
          {this.state.isInputCommentShow && (
            <div className="comment">
              <div className="input-reply d-flex w-100 justify-content-between align-items-center">
                <Avatar
                  width={30}
                  height={30}
                  src={my_information.avatar}
                  className="mr-2"
                />
                <Input
                  name={"comment"}
                  value={this.state.comment}
                  onChange={this.handleOnChange}
                  placeholder="Comment..."
                  onKeyDown={this.onKeyDown}
                  type="text"
                />
              </div>
              <Button
                className="btn-cancle mt-2"
                outline
                color="primary"
                type="button"
                size="sm"
                onClick={() => this.handleCloseInputComment()}
              >
                Cancel
              </Button>
            </div>
          )}
        </CardBody>
        <CardFooter className="text-center">
          {this.state.role !== "writer" && (
            <Button
              onClick={this.handleShowInputComment}
              style={{ color: "#5e72e4" }}
            >
              Add New Comment
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    feedback_list: state.feedbackReducer.feedback_list,
    loading: state.feedbackReducer.loading,
    my_information: state.authReducer.my_information
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addFeedback: data => {
      dispatch(actAddFeedbackRequest(data));
    },
    addReply: data => {
      dispatch(actAddReplyRequest(data));
    },
    changeFeedbackStatus: (feedback_id, outline_id) => {
      dispatch(actChangeFeedbackStatusRequest(feedback_id, outline_id));
    },
    resetFeedback: () => {
      dispatch(actResetFeedbackList());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
Feedback.propTypes = {
  article_id: PropTypes.string,
  outline_id: PropTypes.string,
  my_information: PropTypes.object,
  feedback_list: PropTypes.array,
  getFeedbackList: PropTypes.func,
  addFeedback: PropTypes.func,
  resetFeedback: PropTypes.func,
  loading: PropTypes.bool
};
Feedback.defaultProps = {};
