import React, { Component } from "react";
import { Card, CardHeader, CardFooter, Table, Col, Row } from "reactstrap";
import CustomPagination from "../Pagination/Pagination";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetAvailablePitchesRequest } from "../../actions/pitch.action";
import Avatar from "../Avatar/Avatar";
import { formatStringToDate } from "../../utils/formatDate";

class EditorAvailablePitchesTable extends Component {
  state = {
    tabs: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1",
    },
    isLoading: false,
    data: {},
  };

  componentDidMount() {
    this._setLoading();
    this._getAvailablePitches();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  _getAvailablePitches = () => {
    const params = this.state.params;
    this.props.getAvailablePitches(params);
  };

  _handleOnPageClick = (page) => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getAvailablePitches(params);
  };

  _handlePathnamePush = (params) => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = (typeColumn) => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1,
        },
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1,
        },
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort,
    };
    this._handlePathnamePush(paramsSort);
    this.props.getAvailablePitches(paramsSort);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/editor/pitch-detail/${id}`);
  };

  render() {
    const { available_pitch } = this.props;
    const page = available_pitch.data && available_pitch.data.page;
    const totalPages = available_pitch.data && available_pitch.data.totalPages;
    const totalDocs = available_pitch.data && available_pitch.data.totalDocs;

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Available Pitches List</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {available_pitch.data.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
                {available_pitch.data.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("writer")}
                        >
                          Writer
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("editor")}
                        >
                          Editor
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("updatedAt")}
                        >
                          Submitted On
                        </th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>

                    <tbody className="list">
                      {available_pitch &&
                        available_pitch.data &&
                        available_pitch.data.docs.map((prop, key) => {
                          return (
                            <>
                              <tr
                                key={key}
                                onClick={(e) =>
                                  this._handleOnCellClick(e, prop._id)
                                }
                                className="cursor-pointer"
                              >
                                <th scope="row">
                                  {this._text_truncate(
                                    prop.angle_title,
                                    50,
                                    "..."
                                  )}
                                </th>
                                <td className="table-user">
                                  <Avatar
                                    src={prop.writer ? prop.writer.avatar : ""}
                                    className="d-inline-block mr-3"
                                  />
                                  <b>{prop.writer ? prop.writer.name : ""}</b>
                                </td>
                                <td className="table-user">
                                  {prop.editor && (
                                    <>
                                      <Avatar
                                        src={
                                          prop.editor ? prop.editor.avatar : ""
                                        }
                                        className="d-inline-block mr-3"
                                      />
                                      <b>
                                        {prop.editor ? prop.editor.name : ""}
                                      </b>
                                    </>
                                  )}
                                </td>
                                <td>{formatStringToDate(prop.updatedAt)}</td>
                                <td>
                                  {prop
                                    ? prop.topic.map((topic) => {
                                        return (
                                          <>
                                            <div className="mt-2  _topic">
                                              {this._text_truncate(
                                                topic.label
                                                  ? topic.label
                                                  : topic.name,
                                                40,
                                                "..."
                                              )}
                                            </div>
                                          </>
                                        );
                                      })
                                    : ""}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    available_pitch: state.pitchReducer.available_pitch,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAvailablePitches: (params) => {
      dispatch(actGetAvailablePitchesRequest(params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorAvailablePitchesTable);
EditorAvailablePitchesTable.propTypes = {
  available_pitch: PropTypes.object,
  getAvailablePitches: PropTypes.func,
};
EditorAvailablePitchesTable.defaultProps = {};
