import React, { useState } from "react";
import PropTypes from "prop-types";

import collapseBefore from "../../assets/img/icons/collapse-before.svg";
import collapseAfter from "../../assets/img/icons/collapse-after.svg";

import "./styles.scss";

function GroupTag({ groupTitle, groupBeingUsed, groupContent }) {
  const [clicked, setClick] = useState(true);

  const collapseRow = title => {
    for (let i = 0; i < document.getElementsByClassName(title).length; i += 1) {
      if (
        document.getElementsByClassName(title)[i]["style"]["visibility"] ===
        "collapse"
      ) {
        document.getElementsByClassName(title)[i]["style"]["visibility"] =
          "visible";
      } else {
        document.getElementsByClassName(title)[i]["style"]["visibility"] =
          "collapse";
      }
    }
  };

  return (
    <>
      <section className="d-flex align-items-center">
        {groupTitle && (
          <>
            <img
              onClick={() => {
                setClick(!clicked);
                collapseRow(
                  groupTitle.replace(/\s+/g, "-").toLowerCase() + "-content"
                );
              }}
              className="mr-2 action-btn"
              src={clicked ? collapseAfter : collapseBefore}
              alt="more"
            />
            <h3 className="h2p-0 pr-2 m-0 font-weight-bold">{groupTitle}</h3>
          </>
        )}
        {groupBeingUsed && (
          <p className="h2 p-0 m-0 font-weight-bold">({groupBeingUsed})</p>
        )}
      </section>

      {groupContent}
    </>
  );
}

GroupTag.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  groupBeingUsed: PropTypes.any.isRequired,
  groupContent: PropTypes.any.isRequired
};

export default GroupTag;
