/* eslint-disable no-console */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form
} from "reactstrap";
import { Button } from "antd";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import {
  actGetMyProfileListRequest,
  actUpdateMyProfileRequest,
  actGetListKeyRequest,
  actUpdateWriterRequest
} from "../../actions/myprofile.action";
import {
  actGetListTopicRequest,
  actGetListExpertiseRequest
} from "../../actions/employee.action";
import Dropzone from "dropzone";
import HOST_URL from "../../variables/hostUrl";
import HOST from "variables/host";
import Select from "react-select";
import { validateEmail, validatePhone } from "../../utils/validate";
import * as authenStorage from "../../utils/authen-storage";

class MyProfileTable extends Component {
  state = {
    data: {
      avatar: "",
      upload_CV: "",
      expertise: "",
      interest: ""
    }
  };
  componentDidMount() {
    this.dropzoneInitial();
    this.dropzoneInitial1();
    this._getMyProfile();
    this.props.getListKey();
    this.props.getListTopic();
    localStorage.getItem("key") === "admin" && this.props.getListExpertise();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.myprofile_list.data !== this.props.myprofile_list.data) {
      this.setState({
        data: nextProps.myprofile_list.data
      });
    }
  }

  _getMyProfile() {
    this.props.getMyProfile();
  }

  _handleOnChange = e => {
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data
    });
  };

  _handleOnChangeCheckbox = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.checked
      }
    });
  };

  _handleOnTagsSelectChange = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  };

  _handleOnUpdate = () => {
    const check = this.state.data;
    if (
      !check.phone ||
      !check.email ||
      !check.name ||
      !check.date_joined ||
      !check.job_position ||
      //
      !check.birthday ||
      !check.country ||
      !check.nationality ||
      !check.education ||
      !check.occupation ||
      !check.contract_ending_date ||
      check.expertise === null ||
      check.expertise.length === 0 ||
      check.interest === null ||
      check.interest.length === 0 ||
      !validatePhone(check.phone) ||
      !validateEmail(check.email)
    ) {
      this.setState({
        alert: "Force admin to fill *",
        alert1: " *",
        alert2: "This field cannot be empty!",
        alert3: "Please input the correct phone number!",
        alert4: "Please input the correct email!"
      });
    } else {
      const id = this.state.data.id;
      const email = this.state.data.email;
      let emailcheck1 = localStorage.getItem("email");
      const phone = this.state.data.phone;
      let phonecheck = localStorage.getItem("phone");
      const penname = this.state.data.penname;
      let pennamecheck = localStorage.getItem("penname");
      let dataa;
      if (email !== emailcheck1) {
        dataa = {
          email: this.state.data.email,
          avatar: this.state.data.avatar,
          name: this.state.data.name,
          address: this.state.data.address,
          country: this.state.data.country,
          nationality: this.state.data.nationality,
          job_position: this.state.data.job_position,
          place_of_birh: this.state.data.place_of_birh,
          identity_card: this.state.data.identity_card,
          contract: this.state.data.contract,
          gender: this.state.data.gender,
          birthday: this.state.data.birthday,
          date_joined: this.state.data.date_joined,
          isSenior: this.state.data.isSenior,
          status: this.state.data.status,
          orther_phone: this.state.data.orther_phone,
          education: this.state.data.education,
          occupation: this.state.data.occupation,
          expertise: this.state.data.expertise,
          note: this.state.data.note,
          upload_CV: this.state.data.upload_CV,
          contract_ending_date: this.state.data.contract_ending_date,
          interest: this.state.data.interest
        };
      } else if (phone !== phonecheck) {
        dataa = {
          avatar: this.state.data.avatar,
          name: this.state.data.name,
          phone: this.state.data.phone,
          address: this.state.data.address,
          country: this.state.data.country,
          nationality: this.state.data.nationality,
          job_position: this.state.data.job_position,
          place_of_birh: this.state.data.place_of_birh,
          identity_card: this.state.data.identity_card,
          contract: this.state.data.contract,
          gender: this.state.data.gender,
          birthday: this.state.data.birthday,
          date_joined: this.state.data.date_joined,
          isSenior: this.state.data.isSenior,
          status: this.state.data.status,
          orther_phone: this.state.data.orther_phone,
          education: this.state.data.education,
          occupation: this.state.data.occupation,
          expertise: this.state.data.expertise,
          note: this.state.data.note,
          upload_CV: this.state.data.upload_CV,
          contract_ending_date: this.state.data.contract_ending_date,
          interest: this.state.data.interest
        };
      } else if (penname !== pennamecheck) {
        dataa = {
          avatar: this.state.data.avatar,
          name: this.state.data.name,
          address: this.state.data.address,
          country: this.state.data.country,
          nationality: this.state.data.nationality,
          penname: this.state.data.penname,
          job_position: this.state.data.job_position,
          place_of_birh: this.state.data.place_of_birh,
          identity_card: this.state.data.identity_card,
          contract: this.state.data.contract,
          gender: this.state.data.gender,
          birthday: this.state.data.birthday,
          date_joined: this.state.data.date_joined,
          isSenior: this.state.data.isSenior,
          status: this.state.data.status,
          orther_phone: this.state.data.orther_phone,
          education: this.state.data.education,
          occupation: this.state.data.occupation,
          expertise: this.state.data.expertise,
          note: this.state.data.note,
          upload_CV: this.state.data.upload_CV,
          contract_ending_date: this.state.data.contract_ending_date,
          interest: this.state.data.interest
        };
      } else {
        dataa = {
          avatar: this.state.data.avatar,
          name: this.state.data.name,
          address: this.state.data.address,
          country: this.state.data.country,
          nationality: this.state.data.nationality,
          job_position: this.state.data.job_position,
          place_of_birh: this.state.data.place_of_birh,
          identity_card: this.state.data.identity_card,
          contract: this.state.data.contract,
          gender: this.state.data.gender,
          birthday: this.state.data.birthday,
          date_joined: this.state.data.date_joined,
          isSenior: this.state.data.isSenior,
          status: this.state.data.status,
          orther_phone: this.state.data.orther_phone,
          education: this.state.data.education,
          occupation: this.state.data.occupation,
          expertise: this.state.data.expertise,
          note: this.state.data.note,
          upload_CV: this.state.data.upload_CV,
          contract_ending_date: this.state.data.contract_ending_date,
          interest: this.state.data.interest
        };
      }
      this.props.updateMyProfile(id, dataa);
    }
  };

  // Update Writer
  _handUpdateWriter = () => {
    const check = this.state.data;
    if (
      !check.phone ||
      !check.email ||
      !check.country ||
      !check.education ||
      !check.occupation ||
      !validatePhone(check.phone) ||
      !validateEmail(check.email)
    ) {
      this.setState({
        alert: "Force admin to fill *",
        alert1: " *",
        alert2: "This field cannot be empty!",
        alert3: "Please input the correct phone number!",
        alert4: "Please input the correct email!"
      });
    } else {
      const id = this.state.data.id;
      const dataa = {
        avatar: this.state.data.avatar,
        upload_CV: this.state.data.upload_CV,
        email: this.state.data.email,
        phone: this.state.data.phone,
        country: this.state.data.country,
        occupation: this.state.data.occupation
      };
      this.props.updateWriter(id, dataa);
    }
  };

  _handleOnDateChange = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  };

  _handleOnCheckChange = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  };

  _handleOnSelectChange = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
      data: {
        ...this.state.data,
        [name]: value.value
      }
    });
  };

  dropzoneInitial = () => {
    let currentSingleFile = undefined;
    const self = this;
    // single dropzone file - accepts only images
    this.Dropzone = new Dropzone(document.getElementById("dropzone-single"), {
      url: HOST + "/v2/user/avatar",
      thumbnailWidth: 780,
      thumbnailHeight: 450,
      headers: { accessToken: authenStorage.getToken() },
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function() {
        this.on("addedfile", function(file) {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      },
      success: (file, response) => {
        const image = response;
        self.setState({
          data: { ...self.state.data, image, avatar: image.data.url }
        });
        self._toogleLoading();
      }
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = ``;
  };
  // UPLOAD CV
  dropzoneInitial1 = () => {
    let currentSingleFile = undefined;
    const self = this;
    this.Dropzone = new Dropzone(document.getElementById("dropzone-single1"), {
      url: HOST + "/uploadfile",
      headers: { accessToken: authenStorage.getToken() },
      previewTemplate: document.getElementsByClassName("dz-preview-single1")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: null,
      init: function() {
        this.on("addedfile", function(file) {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      },
      success: (file, response) => {
        const image = response;
        self.setState({
          data: {
            ...self.state.data,
            file,
            upload_CV: image.data.file
          }
        });
        self._toogleLoading();
      }
    });
    document.getElementsByClassName("dz-preview-single1")[0].innerHTML = ``;
  };
  _toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  delete_file = () => {
    this.setState({
      data: {
        ...this.state.data,
        upload_CV: ""
      }
    });
  };
  _goBack = () => {
    // eslint-disable-next-line react/prop-types
    this.props.history.goBack();
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this._handleOnUpdate();
    }
  };

  render() {
    const { listExpertise } = this.props;
    const expertise = Object.assign({}, listExpertise.data);
    const pathname = localStorage.getItem("key");

    let count1 = this.state.data.interest;
    let max = 0;
    if (count1 != null) {
      max = Object.keys(count1).length;
    }
    let count = this.state.data.interest;
    if (count === null) {
      count = 0;
    }
    delete count[2];
    const renderStatus = status => {
      switch (status) {
        case "OUTLINE_WRITING":
          return (
            <>
              <i className="bg-success" />
              <span className="text-success font-weight-bold">
                OUTLINE_WRITING
              </span>
            </>
          );
        case "OUTLINE_SUBMITTED":
          return (
            <>
              <i className="bg-warning" />
              <span className="text-warning font-weight-bold">
                OUTLINE_SUBMITTED
              </span>
            </>
          );
        case "CONTENT_WRITING":
          return (
            <>
              <i className="bg-info" />
              <span className="text-info font-weight-bold">
                CONTENT_WRITING
              </span>
            </>
          );
        case "COMPLETED":
          return (
            <>
              <i className="bg-danger" />
              <span className="text-danger font-weight-bold">COMPLETED</span>
            </>
          );
        case "WORKING":
          return (
            <>
              <span className="text-success pr-2">●</span>{" "}
              <span className="font-weight-bold" style={{ color: "#172b4d" }}>
                Employed
              </span>
            </>
          );

        default:
      }
      return null;
    };
    return (
      <>
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader className="card-information">
              <h3 className="mb-0">My Profile</h3>
            </CardHeader>
            <CardBody style={{ marginTop: "-30px" }}>
              <Form id="myForm">
                <br />
                <p className="information-title">PERSONAL INFORMATION</p>
                <Row>
                  <Col lg="2" md="6">
                    <FormGroup>
                      <Row>
                        <Col md="8">
                          <label
                            className="form-control-label"
                            htmlFor="lastname"
                          >
                            Avatar
                          </label>
                        </Col>
                      </Row>
                      {this.state.data.avatar && (
                        <div
                          style={{
                            backgroundImage: `url(${HOST_URL +
                              this.state.data.avatar})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "150px",
                            borderRadius: "0.375rem",
                            border: "1px dashed #dee2e6"
                          }}
                        />
                      )}
                      <div
                        className={
                          this.state.data.avatar
                            ? "dropzone dropzone-single mt-2 Ava1"
                            : "dropzone dropzone-single mt-2 Ava"
                        }
                        id="dropzone-single"
                        style={
                          this.state.data.avatar
                            ? {
                                position: " absolute",
                                top: "25px",
                                left: "0",
                                width: "100%",
                                opacity: "0",
                                height: "150px",
                                cursor: "pointer"
                              }
                            : {}
                        }
                      >
                        <div className="dz-default dz-message upload-avatar">
                          <p className="icon icon-upload-file"></p>
                          <p>Upload</p>
                        </div>
                        <div className="fallback">
                          <div
                            className="custom-file"
                            style={{
                              height: "247.7px"
                            }}
                          >
                            <input
                              className="custom-file-input"
                              id="projectCoverUploads"
                              type="file"
                            />
                          </div>
                        </div>
                        <div
                          className="dz-preview dz-preview-single"
                          style={{
                            height: "280px"
                          }}
                        >
                          <div className="dz-preview-cover">
                            <img
                              alt="..."
                              className="dz-preview-img"
                              data-dz-thumbnail=""
                            />
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Name
                      </label>
                      <span
                        style={{
                          color: "red",
                          fontSize: "13px",
                          margin: "8px 0"
                        }}
                      >
                        {this.state.alert1}
                      </span>
                      <Input
                        disabled={pathname === "admin" ? "" : "disabled"}
                        id="name"
                        placeholder="Input staff name"
                        type="text"
                        name="name"
                        value={this.state.data && this.state.data.name}
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example4cols1Input"
                        name="email"
                      >
                        Email Adress
                      </label>
                      <span
                        style={{
                          color: "red",
                          fontSize: "13px",
                          margin: "8px 0"
                        }}
                      >
                        {this.state.alert1}
                      </span>
                      <Input
                        name="email"
                        value={this.state.data && this.state.data.email}
                        placeholder="Name@example.com"
                        type="text"
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                      {!this.state.data.email ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : !validateEmail(this.state.data.email) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert4}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="3" md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example4cols1Input"
                      >
                        Gender
                      </label>
                      <Input
                        disabled={pathname === "admin" ? "" : "disabled"}
                        type="select"
                        name="gender"
                        id="exampleSelect"
                        onChange={this._handleOnChange}
                      >
                        <option
                          selected={
                            this.state.data && this.state.data.gender === 0
                              ? "selected"
                              : ""
                          }
                          value="0"
                        >
                          Male
                        </option>
                        <option
                          selected={
                            this.state.data && this.state.data.gender === 1
                              ? "selected"
                              : ""
                          }
                          value="1"
                        >
                          Female
                        </option>
                        <option
                          selected={
                            this.state.data && this.state.data.gender === -1
                              ? "selected"
                              : ""
                          }
                          value="-1"
                        >
                          Other gender
                        </option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="phone">
                        Phone
                      </label>
                      {!this.state.data.phone ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      <Input
                        name="phone"
                        placeholder="Input phone numbers"
                        type="number"
                        value={this.state.data && this.state.data.phone}
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                      {!this.state.data.phone ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : !validatePhone(this.state.data.phone) ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert3}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="3" md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="birthday">
                        Birthday
                      </label>
                      {!this.state.data.birthday ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      <ReactDatetime
                        name="birthday"
                        inputProps={
                          pathname === "admin"
                            ? { placeholder: "dd-mm-yyyy", readOnly: true }
                            : { disabled: true }
                        }
                        timeFormat={false}
                        onChange={value =>
                          this._handleOnDateChange("birthday", value)
                        }
                        value={
                          this.state.data.birthday &&
                          moment(this.state.data.birthday).format("DD-MM-YYYY")
                        }
                        dateFormat={"DD-MM-YYYY"}
                        closeOnSelect
                      />
                      <div className="icon-calendar"></div>
                      {!this.state.data.birthday ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <p className="information-title">JOB INFORMATION</p>
                <Row>
                  <Col md="6" sm="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols2Input"
                      >
                        Pen name
                      </label>
                      <Input
                        disabled={pathname === "admin" ? "" : "disabled"}
                        name="penname"
                        placeholder="Input pen name"
                        type="text"
                        value={this.state.data && this.state.data.penname}
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                    </FormGroup>
                    <Row>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols2Input"
                          >
                            Job/ Position
                          </label>
                          <span
                            style={{
                              color: "red",
                              fontSize: "13px",
                              margin: "8px 0"
                            }}
                          >
                            {this.state.alert1}
                          </span>
                          <Input
                            name="job_position"
                            disabled
                            type="text"
                            value={
                              this.state.data && this.state.data.job_position
                            }
                            onChange={this._handleOnChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="date_joined"
                          >
                            Joining date
                          </label>
                          {!this.state.data.date_joined ? (
                            <span
                              style={{
                                color: "red",
                                fontSize: "13px",
                                margin: "8px 0"
                              }}
                            >
                              *
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "red",
                                fontSize: "13px",
                                margin: "8px 0"
                              }}
                            >
                              {this.state.alert1}
                            </span>
                          )}
                          <ReactDatetime
                            name="date_joined"
                            inputProps={
                              pathname === "admin"
                                ? { placeholder: "dd-mm-yyyy", readOnly: true }
                                : { disabled: true }
                            }
                            timeFormat={false}
                            onChange={value =>
                              this._handleOnDateChange("date_joined", value)
                            }
                            value={
                              this.state.data.date_joined &&
                              moment(this.state.data.date_joined).format(
                                "DD-MM-YYYY"
                              )
                            }
                            dateFormat={"DD-MM-YYYY"}
                            closeOnSelect
                          />
                          <div className="icon-calendar"></div>
                          {!this.state.data.date_joined ? (
                            <span
                              style={{
                                color: "red",
                                fontSize: "13px",
                                margin: "8px 0"
                              }}
                            >
                              {this.state.alert2}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example4cols1Input"
                          >
                            Level
                          </label>
                          <Input
                            disabled={pathname === "admin" ? "" : "disabled"}
                            type="select"
                            name="gender"
                            id="exampleSelect"
                            onChange={this._handleOnChange}
                            onKeyDown={this.onKeyDown}
                          >
                            <option selected hidden>
                              Select Level
                            </option>
                            <>
                              <option
                                selected={
                                  this.state.data.isSenior === false
                                    ? "selected"
                                    : ""
                                }
                                value="false"
                              >
                                Junior
                              </option>
                              <option
                                selected={
                                  this.state.data.isSenior === true
                                    ? "selected"
                                    : ""
                                }
                                value="true"
                              >
                                Senior
                              </option>
                            </>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <div className="form-check-inline">
                            {renderStatus(this.state.data.status)}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6" sm="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="tags">
                        Topic of Interest
                      </label>
                      {!this.state.data.interest || max === 0 ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      <Select
                        isDisabled={pathname === "admin" ? "" : "isDisabled"}
                        className="react-select1"
                        classNamePrefix="react-select"
                        name="interest"
                        isClearable
                        closeMenuOnSelect={true}
                        isMulti
                        value={this.state.data.interest}
                        options={
                          this.props.listTopic &&
                          this.props.listTopic.map(item => ({
                            value: item.name,
                            label: item.name
                          }))
                        }
                        onChange={value =>
                          this._handleOnTagsSelectChange("interest", value)
                        }
                      />
                      {max > 2 ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          Maximum 2 keywords
                        </span>
                      ) : (
                        <span></span>
                      )}
                      {!this.state.data.interest ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="tags">
                        Expertise
                      </label>
                      {!this.state.data.expertise ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      {pathname === "admin" ? (
                        <Input
                          type="select"
                          name="expertise"
                          onChange={this._handleOnChange}
                        >
                          <option selected hidden>
                            Select expertise
                          </option>
                          {expertise.docs &&
                            expertise.docs.map((list, index) => {
                              return (
                                <option
                                  key={index}
                                  selected={
                                    list.name === this.state.data.expertise[0]
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {list.name}
                                </option>
                              );
                            })}
                        </Input>
                      ) : (
                        <Input
                          name="expertise"
                          disabled
                          type="text"
                          value={this.state.data && this.state.data.expertise}
                          onChange={this._handleOnChange}
                        />
                      )}
                      {!this.state.data.expertise ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <p className="information-title">ORTHER INFORMATION</p>
                <Row>
                  <Col md="6" sm="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols2Input"
                      >
                        Upload CV
                      </label>
                      <div
                        className="dropzone dropzone-single upload_cv"
                        style={{ marginTop: "6px" }}
                        id="dropzone-single1"
                      >
                        <div className="dz-default dz-message cv">
                          {!this.state.data.upload_CV && (
                            <span>
                              Drag and drop file to upload or{" "}
                              <span>Choose </span>
                              file
                            </span>
                          )}
                        </div>
                        <div className="fallback">
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="projectCoverUploads"
                              type="file"
                            />
                          </div>
                        </div>
                        <div className="dz-preview dz-preview-single1">
                          <div className="dz-preview-cover"></div>
                        </div>
                      </div>
                      {this.state.data.upload_CV && (
                        <div className="invalid-feedback d-block file_cv">
                          <div className="icon-upload">
                            <span className="icon-attach">
                              <a
                                href={HOST_URL + this.state.data.upload_CV}
                                target="blank"
                              >
                                {this.state.data.upload_CV}
                              </a>
                            </span>
                            <span
                              className="icon-close"
                              onClick={this.delete_file}
                            ></span>
                          </div>
                        </div>
                      )}
                    </FormGroup>
                    <Row>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols2Input"
                          >
                            Staff ID
                          </label>
                          <Input
                            name="staffId"
                            disabled="disabled"
                            type="text"
                            value={this.state.data && this.state.data.staff_id}
                            onChange={this._handleOnChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example4cols1Input"
                          >
                            Identity card
                          </label>
                          <Input
                            name="identity_card"
                            placeholder="Input Identity Card"
                            type="number"
                            value={this.state.data.identity_card}
                            onChange={this._handleOnChange}
                            onKeyDown={this.onKeyDown}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols2Input"
                          >
                            Other phone or contact details
                          </label>
                          <Input
                            disabled={pathname === "admin" ? "" : "disabled"}
                            name="orther_phone"
                            placeholder="Input phone or contact details"
                            type="text"
                            value={
                              this.state.data && this.state.data.orther_phone
                            }
                            onChange={this._handleOnChange}
                            onKeyDown={this.onKeyDown}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols2Input"
                          >
                            Note
                          </label>
                          <Input
                            disabled={pathname === "admin" ? "" : "disabled"}
                            name="note"
                            placeholder="Input note"
                            type="textarea"
                            value={this.state.data.note}
                            onChange={this._handleOnChange}
                            onKeyDown={this.onKeyDown}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="3" sm="6" className="top">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="contract_ending_date"
                      >
                        Contract ending date
                      </label>
                      {!this.state.data.contract_ending_date ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      <ReactDatetime
                        name="contract_ending_date"
                        inputProps={
                          pathname === "admin"
                            ? { placeholder: "dd-mm-yyyy", readOnly: true }
                            : { disabled: true }
                        }
                        timeFormat={false}
                        onChange={value =>
                          this._handleOnDateChange(
                            "contract_ending_date",
                            value
                          )
                        }
                        value={
                          this.state.data.contract_ending_date &&
                          moment(this.state.data.contract_ending_date).format(
                            "DD-MM-YYYY"
                          )
                        }
                        dateFormat={"DD-MM-YYYY"}
                        closeOnSelect
                      />
                      <div className="icon-calendar"></div>
                      {!this.state.data.contract_ending_date ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols2Input"
                      >
                        Education
                      </label>
                      {!this.state.data.education ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      <Input
                        disabled={pathname === "admin" ? "" : "disabled"}
                        name="education"
                        placeholder="Input Education"
                        type="text"
                        value={this.state.data && this.state.data.education}
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                      {!this.state.data.education ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example4cols1Input"
                      >
                        Place of birth
                      </label>
                      <Input
                        disabled={pathname === "admin" ? "" : "disabled"}
                        name="place_of_birh"
                        placeholder="Input Place of Birth"
                        type="text"
                        value={this.state.data && this.state.data.place_of_birh}
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                      <div className="form-check-inline">
                        <div className="custom-control custom-radio mb-3 dl-flex">
                          <input
                            disabled={pathname === "admin" ? "" : "disabled"}
                            className="custom-control-input"
                            id="customRadio5"
                            name="contract"
                            type="radio"
                            defaultChecked
                            onChange={this._handleOnChange}
                            checked={
                              this.state.data.contract === 0 ||
                              this.state.data.contract === "0"
                                ? "checked"
                                : ""
                            }
                            value="0"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadio5"
                          >
                            In house
                          </label>
                        </div>
                      </div>
                      <div className="form-check-inline">
                        <div className="custom-control custom-radio mb-3">
                          <input
                            disabled={pathname === "admin" ? "" : "disabled"}
                            className="custom-control-input"
                            id="customRadio6"
                            name="contract"
                            type="radio"
                            defaultChecked
                            onChange={this._handleOnChange}
                            // no change from == to ===
                            checked={
                              this.state.data.contract === 1 ||
                              this.state.data.contract === "1"
                                ? "checked"
                                : ""
                            }
                            value="1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadio6"
                          >
                            Freelancer
                          </label>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="6" className="top">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example4cols1Input"
                      >
                        Occupation
                      </label>
                      {!this.state.data.occupation ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      <Input
                        name="occupation"
                        placeholder="Input occupation"
                        type="text"
                        value={this.state.data.occupation}
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                      {!this.state.data.occupation ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example4cols1Input"
                      >
                        Nationality
                      </label>
                      {!this.state.data.nationality ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      <Input
                        disabled={pathname === "admin" ? "" : "disabled"}
                        name="nationality"
                        placeholder="Select nationality"
                        type="text"
                        value={this.state.data && this.state.data.nationality}
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                      {!this.state.data.nationality ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example4cols1Input"
                      >
                        Residing Country
                      </label>
                      {!this.state.data.country ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert1}
                        </span>
                      )}
                      <Input
                        name="country"
                        placeholder="Select residing country"
                        type="text"
                        value={this.state.data && this.state.data.country}
                        onChange={this._handleOnChange}
                        onKeyDown={this.onKeyDown}
                      />
                      {!this.state.data.country ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "8px 0"
                          }}
                        >
                          {this.state.alert2}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <div>
                  <div className="text-right">
                    <Button className="view" onClick={() => this._goBack()}>
                      Cancel
                    </Button>
                    {pathname === "writer" ? (
                      <Button
                        className="add"
                        onClick={this._handUpdateWriter}
                        style={{ marginLeft: "15px" }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="add"
                        onClick={this._handleOnUpdate}
                        style={{ marginLeft: "15px" }}
                      >
                        Update
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    myprofile_list: state.myProfileReducer.myprofile_list,
    listKey: state.employeeReducer.listKey,
    listTopic: state.employeeReducer.expertiseOption,
    listExpertise: state.employeeReducer.listExpertise
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMyProfile: () => {
      dispatch(actGetMyProfileListRequest());
    },
    getListKey: () => {
      dispatch(actGetListKeyRequest());
    },
    getListTopic: () => {
      dispatch(actGetListTopicRequest());
    },
    getListExpertise: () => {
      dispatch(actGetListExpertiseRequest());
    },
    updateMyProfile: (id, data) => {
      dispatch(actUpdateMyProfileRequest(id, data));
    },
    updateWriter: (id, data) => {
      dispatch(actUpdateWriterRequest(id, data));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyProfileTable);
MyProfileTable.propTypes = {
  myprofile_list: PropTypes.object,
  getMyProfile: PropTypes.func,
  updateWriter: PropTypes.func,
  updateMyProfile: PropTypes.func,
  listKey: PropTypes.object,
  listTopic: PropTypes.object,
  getListKey: PropTypes.func,
  getListTopic: PropTypes.func,
  listExpertise: PropTypes.object,
  getListExpertise: PropTypes.func
};
MyProfileTable.defaultProps = {};
