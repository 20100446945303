import React, { Component } from "react";
import {
  Badge,
  Card,
  CardHeader,
  Table,
  // Button,
  CardFooter,
  Row
  // Col
} from "reactstrap";
import CustomPagination from "../Pagination/Pagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetApprovedArticleListRequest } from "../../actions/writerArticle.action";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import { formatStringToDate } from "../../utils/formatDate";

class WriterApprovedArticleTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getApprovedArticle();
  }

  _getApprovedArticle = () => {
    const params = this.state.params;
    this.props.getApprovedArticle(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getApprovedArticle(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };
  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getApprovedArticle(paramsSort);
  };

  _handleOnLayoutClick = (e, id, status) => {
    if (status === "OUTLINE_WRITING" || status === "OUTLINE_SUBMITTED") {
      this.props.history.push(`/admin/writer/outline-content/${id}`);
    } else {
      this.props.history.push(`/admin/writer/view-detail-content-writer/${id}`);
    }
  };
  render() {
    const { approved_article_list } = this.props;

    const page = approved_article_list.data && approved_article_list.data.page;
    const totalPages =
      approved_article_list.data && approved_article_list.data.totalPages;
    const totalDocs =
      approved_article_list.data && approved_article_list.data.totalDocs;
    const renderStatus = status => {
      switch (status) {
        case "COMPLETED":
          return (
            <>
              <span className="status-progress">●</span>
              <span>Ready to Publish</span>
            </>
          );
        case "UNPUBLISHED":
          return (
            <>
              <span className="status-cancel">●</span>
              <span>Unpublished</span>
            </>
          );
        case "PUBLISHED":
          return (
            <>
              <span className="status-completed">●</span>
              <span>Published</span>
            </>
          );
        case "REJECTED":
          return (
            <>
              <span className="status-cancel">●</span>
              <span>Rejected</span>
            </>
          );
        case "EDITING":
          return (
            <>
              <span className="status-progress">●</span>
              <span>Needs Editing</span>
            </>
          );
        default:
      }
      return null;
    };
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Approved Article List</h3>
              </CardHeader>
              <div className="table-responsive">
                {approved_article_list.data &&
                  approved_article_list.data.docs.length !== 0 && (
                    <Table
                      hover
                      className="align-items-center table-flush"
                      style={{ border: "0px", overflow: "hidden" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            onClick={() => this._sortByColumn("angle_title")}
                          >
                            Title
                          </th>
                          <th className="text-center">Dual Language</th>
                          <th
                            className="sort"
                            data-sort="budget"
                            scope="col"
                            onClick={() => this._sortByColumn("start_date")}
                          >
                            Writer Accepts On
                          </th>
                          <th
                            className="sort"
                            data-sort="status"
                            scope="col"
                            onClick={() => this._sortByColumn("dead_line")}
                          >
                            Deadline
                          </th>
                          <th>Status</th>
                          <th scope="col">TOPIC</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {approved_article_list.data
                          ? approved_article_list.data.docs.map((prop, key) => {
                              return (
                                <>
                                  <tr
                                    key={key}
                                    className="cursor-pointer"
                                    onClick={e =>
                                      this._handleOnLayoutClick(
                                        e,
                                        prop.id,
                                        prop.status_article
                                      )
                                    }
                                  >
                                    <th scope="row">
                                      {this._text_truncate(
                                        prop.angle_title,
                                        50,
                                        "..."
                                      )}
                                    </th>
                                    <td className="text-center text-success">
                                      {prop.dualLanguage_article !== null &&
                                        prop.dualLanguage_article && (
                                          <i
                                            className="fa fa-check-circle"
                                            style={{ fontSize: 16 }}
                                          ></i>
                                        )}
                                    </td>
                                    <td className="budget">
                                      {formatStringToDate(prop.start_date)}
                                    </td>
                                    <td>
                                      {formatStringToDate(prop.dead_line)}
                                    </td>
                                    <td>
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                      >
                                        {renderStatus(prop.status_article)}
                                      </Badge>
                                    </td>
                                    <td>
                                      {prop
                                        ? prop.topic.map(topic => {
                                            return (
                                              <>
                                                <div className="_topic">
                                                  <label>
                                                    {this._text_truncate(
                                                      topic.name
                                                        ? topic.name
                                                        : topic.label,
                                                      20,
                                                      "..."
                                                    )}
                                                  </label>
                                                </div>
                                              </>
                                            );
                                          })
                                        : ""}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : ""}
                      </tbody>
                    </Table>
                  )}
                {approved_article_list.data &&
                  approved_article_list.data.docs.length === 0 && (
                    <div
                      className="p-7 text-center font-italic font-weight-bold"
                      style={{ color: "#ccc", fontSize: "18px" }}
                    >
                      There are no content available
                    </div>
                  )}
              </div>

              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    approved_article_list: state.writerArticleReducer.approved_article_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getApprovedArticle: params => {
      dispatch(actGetApprovedArticleListRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriterApprovedArticleTable);
WriterApprovedArticleTable.propTypes = {
  approved_article_list: PropTypes.object,
  getApprovedArticle: PropTypes.func
};
WriterApprovedArticleTable.defaultProps = {};
