import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify, actFetchResourceSuccess } from "./fetch.action";
import renderError from "utils/renderServerError";
import { actGetOutlineDetailIDRequest } from "./editorArticle.action";

export const actGetContentRequestListRequest = data => {
  return dispatch => {
    return callApi(
      `writer-list?page=${data.page}&limit=${data.limit}&search=angle_title:${data.search}${data.type}${data.topic}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetContentRequestList(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetContentRequestList = data => {
  return {
    type: Types.GET_CONTENT_REQUEST_LIST,
    data
  };
};

export const actGetContentRequestDetail = data => {
  return {
    type: Types.GET_CONTENT_REQUEST_DETAIL,
    data
  };
};

export const actGetTopicListRequest = data => {
  return dispatch => {
    return callApi("topics?sort=createdAt|-1&limit=99999999", "GET")
      .then(res => {
        dispatch(actGetTopicList(res.data.docs));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetTopicList = data => {
  return {
    type: Types.GET_TOPICS_LIST,
    data
  };
};

// V2 writer view brief detail
export const actGetContentIDRequest = id => {
  return dispatch => {
    return callApi(`v2/brief/${id}`, "GET")
      .then(res => {
        dispatch(actGetContentByID(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetContentByID = data => {
  return {
    type: Types.GET_CONTENT_REQUEST_BYID,
    data
  };
};

export const actGetMyContentListRequest = (data, type, number) => {
  if (!type && !number) {
    return dispatch => {
      return callApi(
        `my-content-write?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=createdAt|-1`,
        "GET"
      )
        .then(res => {
          dispatch(actGetMyContentList(res));
          dispatch(actGetWriterCountDashboardListRequest());
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  } else {
    return dispatch => {
      return callApi(
        `my-content-write?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=${type}|${number}`,
        "GET"
      )
        .then(res => {
          dispatch(actGetMyContentList(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  }
};

export const actGetMyContentList = data => {
  return {
    type: Types.GET_MY_CONTENT_LIST,
    data
  };
};
export const actGetWritingContentByIDRequestForEditor = id => {
  return dispatch => {
    return callApi(`article/content-detail/${id}`, "GET")
      .then(res => {
        dispatch(actGetWritingContentByID(res));
        dispatch(actGetWriterCountDashboardListRequest());
        dispatch(actGetContentVersionsRequest(res.data.contentID));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetWritingContentByIDRequest = id => {
  return dispatch => {
    return callApi(`content-detail/${id}`, "GET")
      .then(res => {
        dispatch(actGetWritingContentByID(res));
        dispatch(actGetWriterCountDashboardListRequest());
        dispatch(actGetContentVersionsRequest(res.data[0].contentID));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetWritingContentByID = data => {
  return {
    type: Types.GET_WRITING_CONTENT_BY_ID,
    data
  };
};

export const actApplyOutlineRequest = (id, data) => {
  return dispatch => {
    callApi(`apply-articel/${id}`, "PUT", data)
      .then(res => {
        const isSkipOutline = res.data.is_skipOutline;
        if (
          isSkipOutline === true &&
          localStorage.getItem("is_senior") === "true"
        ) {
          dispatch(
            actFetchResourceSuccess({
              message: "Applied to write content!",
              confirmTo: `/admin/writer/writer-writing/${id}`
            })
          );
          dispatch(actGetWriterCountDashboardListRequest());
        } else {
          dispatch(
            actFetchResourceSuccess({
              message: "Applied to write outline!",
              confirmTo: `/admin/writer/outline-content/${id}`
            })
          );
        }
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetSkipOutline = data => {
  return {
    type: Types.GET_SKIP_OUTLINE,
    data
  };
};

export const actSubmitOutlineRequest = (id, data) => {
  return dispatch => {
    callApi(`/v2/writer-submit-outline/${id}`, "PUT", data)
      .then(() => {
        dispatch(actGetOutlineDetailIDRequest(id));
        dispatch(actGetWriterCountDashboardListRequest());
        dispatch(
          actNotify({
            message: "Your article outline has been submited succesfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSubmitWritingContentRequest = (id, data) => {
  return dispatch => {
    callApi(`v2/writer-submit-outline/${id}`, "PUT", data)
      .then(res => {
        dispatch(actGetWritingContentByIDRequest(id));
        dispatch(actGetWriterCountDashboardListRequest());
        dispatch(
          actNotify({
            message: "Your article content has been submited succesfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSubmitWriterWritingContentRequest = (id, data) => {
  return dispatch => {
    callApi(`v2/writer-submit-content/${id}`, "PUT", data)
      .then(res => {
        dispatch(actGetWritingContentByIDRequest(id));
        dispatch(actGetWriterCountDashboardListRequest());
        dispatch(
          actNotify({
            message: "Your article content has been submited succesfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetHeaderDashboardListRequest = () => {
  return dispatch => {
    return callApi(`article/list-status-outline-dashboards-writer`, "GET")
      .then(res => {
        dispatch(actGetHeaderDashboardList(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetHeaderDashboardList = data => {
  return {
    type: Types.GET_HEADER_DASHBOARD,
    data
  };
};

export const actGetWriterCountDashboardListRequest = () => {
  return dispatch => {
    return callApi(`article/count-menu-writer`, "GET")
      .then(res => {
        dispatch(actGetWriterCountDashboardList(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetWriterCountDashboardList = data => {
  return {
    type: Types.GET_WRITER_COUNT_MENU_DASHBOARD,
    data
  };
};

export const actGetDashboardWrtiterListRequest = () => {
  return dispatch => {
    return callApi(`article/article-new-dashboards-writer`, "GET")
      .then(res => {
        dispatch(actGetDashboardWrtiterList(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetDashboardWrtiterList = data => {
  return {
    type: Types.GET_DASHDOARDS_WRITER,
    data
  };
};

export const actGetHeaderDashboardWrtiterListRequest = () => {
  return dispatch => {
    return callApi(`article/header-dashboards-writer`, "GET")
      .then(res => {
        dispatch(actGetHeaderDashboardWrtiterList(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetHeaderDashboardWrtiterList = data => {
  return {
    type: Types.GET_HEADER_DASHBOARDS_WRITER,
    data
  };
};

export const actAutoSaveWritingContentRequest = data => {
  return dispatch => {
    callApi(`content/save-draft-auto`, "PUT", data)
      .then(res => {
        // dispatch(
        //   actNotify({
        //     message: "Auto save !",
        //     color: "info"
        //   })
        // );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSaveWritingContentRequest = data => {
  return dispatch => {
    callApi(`content/save-draft`, "PUT", data)
      .then(res => {
        dispatch(
          actNotify({
            message: "Save draft successfully !",
            color: "info"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSaveDraftOutlineRequest = data => {
  return dispatch => {
    callApi(`outline/save-draft-auto`, "PUT", data)
      .then(res => {
        dispatch(
          actNotify({
            message: "Save draft successfully !",
            color: "info"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actAutoSaveOutlineRequest = data => {
  return dispatch => {
    callApi(`outline/save-draft-auto`, "PUT", data)
      .then(res => {
        // dispatch(
        //   actNotify({
        //     message: "Save draft successfully !",
        //     color: "info"
        //   })
        // );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetMyAssignedPostRequest = data => {
  return dispatch => {
    return callApi(
      `my-post-list?page=${data.page}&limit=${data.limit}&search=angle_title:${data.search}${data.type}${data.topic}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetMyAssignedPost(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetMyAssignedPost = data => {
  return {
    type: Types.GET_MY_ASSIGNED_POST,
    data
  };
};

export const actGetOutlineInProgressListRequest = data => {
  return dispatch => {
    return callApi(
      `list-outline-in-process-editor?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetOutlineInProgressList(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetOutlineInProgressList = data => {
  return {
    type: Types.GET_OUTLINE_IN_PROGRESS,
    data
  };
};

export const actGetArticleInProgressListRequest = data => {
  return dispatch => {
    return callApi(
      `list-article-in-process-editor?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetArticleInProgressList(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetArticleInProgressList = data => {
  return {
    type: Types.GET_ARTICLE_IN_PROGRESS,
    data
  };
};

export const actGetApprovedArticleListRequest = data => {
  return dispatch => {
    return callApi(
      `list-approved-article?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetApprovedArticleList(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetApprovedArticleList = data => {
  return {
    type: Types.GET_APPROVED_ARTICLE,
    data
  };
};

export const actGetDashboardDealineListRequest = (data, type, number) => {
  if (!type && !number) {
    return dispatch => {
      return callApi(
        `article/dead-line-writer?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=createdAt|-1`,
        "GET"
      )
        .then(res => {
          dispatch(actGetDashboardDealineList(res));
          dispatch(actGetWriterCountDashboardListRequest());
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  } else {
    return dispatch => {
      return callApi(
        `article/dead-line-writer?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=${type}|${number}`,
        "GET"
      )
        .then(res => {
          dispatch(actGetDashboardDealineList(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  }
};
export const actGetDashboardDealineList = data => {
  return {
    type: Types.GET_DASHBOARD_DEALINE,
    data
  };
};
//Guideline
export const actGetEditorialGuidelineRequest = data => {
  return dispatch => {
    if (
      localStorage.getItem("key") === "writer" ||
      localStorage.getItem("key") === "editor"
    ) {
      return callApi(`general-information?select=guideline|1`, "GET")
        .then(res => {
          dispatch(actGetEditorialGuideline(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    }
  };
};

export const actGetEditorialGuideline = data => {
  return {
    type: Types.GET_WRITER_GUIDELINE,
    data
  };
};

//FAQ
export const actGetFaqRequest = data => {
  return dispatch => {
    if (
      localStorage.getItem("key") === "writer" ||
      localStorage.getItem("key") === "editor"
    ) {
      return callApi(`general-information?select=faq|1`, "GET")
        .then(res => {
          dispatch(actGetFaq(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    }
  };
};

export const actGetFaq = data => {
  return {
    type: Types.GET_WRITER_FAQ,
    data
  };
};

export const actRejectAssignedRequest = (id, data, params) => {
  return dispatch => {
    callApi(`reject-assign-article/${id}`, "PUT", data)
      .then(() => {
        dispatch(actGetMyAssignedPostRequest(params));
        dispatch(actGetWriterCountDashboardListRequest());

        dispatch(
          actNotify({
            message: "Your reject has been submited succesfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetContentVersionsRequest = id => {
  return dispatch => {
    return callApi(`version-content/${id}`, "GET")
      .then(res => {
        if (res.data) {
          dispatch(actGetContentVersion(res.data.versions));
        }
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetContentVersion = data => {
  return {
    type: Types.GET_CONTENT_VERSION,
    data
  };
};

// export const actGetOutlineVersionsRequest = id => {
//   return dispatch => {
//     return callApi(`version-outline/${id}`, "GET")
//       .then(res => {
//         if (res.data) {
//           dispatch(actGetOutlineVersion(res.data.versions));
//         }
//       })
//       .catch(err => {
//         dispatch(
//           actNotify({
//             message: renderError(err),
//             color: "danger"
//           })
//         );
//       });
//   };
// };

export const actGetOutlineVersion = data => {
  return {
    type: Types.GET_OUTLINE_VERSION,
    data
  };
};
