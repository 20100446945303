import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  chiefpublish: {
    data: {
      docs: [],
    },
  },
  chiefpublished: {
    data: {
      docs: [],
    },
  },
  article_topic: {
    data: {
      docs: [],
    },
  },
  topic_by_id: {},
  publish_detail_by_id: {
    data: {
      angle_title: "",
      content_content: "",
      outline_content: "",
    },
  },
  preview: {
    loading: false,
    actionCompleted: false,
    previewLink: {
      key: "",
      code: "",
    },
  },
  unpublished_detail_by_id: {
    data: {
      id: "",
      angle_title: "",
      content_content: "",
      outline_content: "",
      tags: [],
      writer: {},
      dualLanguage_article: {},
      excerpt: "",
      image: {
        size: {
          ONE_ONE: {},
          TWO_ONE: {},
          TWO_THREE: {},
          THREE_TWO: {},
        },
      },
      publishtime_article: {},
      seo: { tags: [], excerpt: "", image: { size: { THREE_TWO: {} } } },
    },
  },
  assigned_briefs_list: {
    data: {
      docs: [],
    },
  },
  outline_in_progress_list: {
    data: {
      docs: [],
    },
  },
  article_in_progress_list: {
    data: {
      docs: [],
    },
  },
  editing_list: {
    data: {
      docs: [],
    },
  },
  writer_profile_list: {
    data: {
      docs: [],
    },
  },
  writer_profile_detail_table: {
    data: {
      docs: [],
    },
  },
  editor_profile_list: {
    data: {
      docs: [],
    },
  },
  editor_profile_detail_table: {
    data: {
      docs: [],
    },
  },
  available_briefs_list: {
    data: {
      docs: [],
    },
  },
  count_menu_chief: {
    data: [],
  },
  table_dealine_dashboard: {
    data: [],
  },
  article_dashboard_list: {
    data: [],
  },
  content_view_detail: {
    data: [
      {
        angle_title: "",
        topic: [],
        tags: [],
        type_of_article: [],
        topics_of_concern: [],
        objectives: "",
        age_group: [],
        comprehension_level: [],
        references: "",
        steps_to_write: [],
        things_to_avoid: "",
        things_to_mention: "",
        tone_of_writing: [],
        note: "",
        keywords: [],
        outline_content: "",
      },
    ],
  },
  chief_today_deadline: {
    data: [],
  },
  chief_today_published: {
    data: [],
  },
  chief_draft_briefs: {
    data: {
      docs: [],
    },
  },
  chief_guideline: {
    data: "",
  },
  chief_faq: {
    data: "",
  },
  chief_reject_assigned: {
    data: {
      docs: [],
    },
  },
};

const chiefArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_CHIEF_PUBLISH_ARTICLE_LIST_START:
      state = {
        ...state,
        chiefpublish: {
          loading: true,
        },
      };
      return { ...state };
    case Types.GET_CHIEF_PUBLISH_ARTICLE_LIST:
      state = {
        ...state,
        chiefpublish: {
          ...action.data,
          loading: false,
        },
      };
      return { ...state };
    case Types.GET_CHIEF_PUBLISHED_ARTICLE_LIST:
      state = {
        ...state,
        chiefpublished: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_ARTICLE_TOPIC:
      state = {
        ...state,
        article_topic: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_TOPIC_BY_ID:
      state = {
        ...state,
        topic_by_id: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_PUBLISH_BYID:
      state = {
        ...state,
        publish_detail_by_id: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_UNPUBLISH_BYID:
      state = {
        ...state,
        loading: false,
        unpublished_detail_by_id: action.data
      };
      return { ...state };
    case Types.GET_CHIEF_UNPUBLISH_BYID_START:
      state = {
        ...state,
        loading: true
      };
      return { ...state };
    case Types.GET_CHIEF_UNPUBLISH_BYID_FAIL:
      state = {
        ...state,
        loading: false
      };
      return { ...state };
    case Types.RESET_CHIEF_UNPUBLISH_BYID:
      state = {
        ...state,
        unpublished_detail_by_id: initialState.unpublished_detail_by_id,
      };
      return { ...state };
    case Types.GET_CHIEF_ASSIGNED_BRIEFS:
      state = {
        ...state,
        assigned_briefs_list: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_OUTLINE_IN_PROGRESS:
      state = {
        ...state,
        outline_in_progress_list: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_ARTICLE_IN_PROGRESS:
      state = {
        ...state,
        article_in_progress_list: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_EDITING:
      state = {
        ...state,
        editing_list: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_WRITER_PROFILE:
      state = {
        ...state,
        writer_profile_list: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_EDITOR_PROFILE:
      state = {
        ...state,
        editor_profile_list: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_COUNT_MENU_DASHBOARD:
      state = {
        ...state,
        count_menu_chief: action.data,
      };
      return { ...state };
    case Types.GET_TABLE_DEALINE_DASHBOARD:
      state = {
        ...state,
        table_dealine_dashboard: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_AVAILABLE_BRIEFS:
      state = {
        ...state,
        available_briefs_list: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_LIST_ARTICLE:
      state = {
        ...state,
        article_dashboard_list: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_WRITER_PROFILE_TABLE_DETAIL:
      state = {
        ...state,
        writer_profile_detail_table: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_EDITOR_PROFILE_TABLE_DETAIL:
      state = {
        ...state,
        editor_profile_detail_table: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_CONTENT_REQUEST_BYID:
      state = {
        ...state,
        content_view_detail: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_TODAY_DEADLINE:
      state = {
        ...state,
        chief_today_deadline: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_TODAY_PUBLISHED:
      state = {
        ...state,
        chief_today_published: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_DRAFT_BRIEFS:
      state = {
        ...state,
        chief_draft_briefs: action.data,
      };
      return { ...state };
    case Types.GET_CHIEF_GUIDELINE:
      return { ...state, chief_guideline: action.data };
    case Types.GET_CHIEF_FAQ:
      return { ...state, chief_faq: action.data };
    case Types.GET_CHIEF_REJECT_ASSIGNED:
      state = {
        ...state,
        chief_reject_assigned: action.data,
      };
      return { ...state };
    case Types.GENERATE_PREVIEW_LINK:
      state = {
        ...state,
        preview: {
          actionCompleted: false,
          loading: true,
        },
      };
      return { ...state };
    case Types.GENERATE_PREVIEW_LINK_SUCCESS:
      state = {
        ...state,
        preview: {
          loading: false,
          actionCompleted: true,
          previewLink: action.data,
        },
      };
      return { ...state };
    case Types.GENERATE_PREVIEW_LINK_FAIL:
      state = {
        ...state,
        preview: {
          actionCompleted: true,
          loading: false,
        },
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default chiefArticleReducer;
