import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetChiefCollectionsRequest = data => {
  return dispatch => {
    return callApi(
      `v2/collection/list?limit=100&page=1&language=${data.language}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetChiefCollection(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetChiefCollection = data => {
  return {
    type: Types.GET_CHIEF_COLLECTION,
    data
  };
};

export const actGetChiefDetailCollectionRequest = data => {
  return dispatch => {
    return callApi(
      `v2/collection/detail?collectionId=${data.slug}&page=${data.page}&limit=${data.limit}&language=${data.language}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetChiefDetailCollection(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetChiefDetailCollection = data => {
  return {
    type: Types.GET_CHIEF_DETAIL_COLLECTION,
    data
  };
};

export const actSearchChiefDetailCollectionRequest = data => {
  return dispatch => {
    return callApi(
      `v2/collection/detail?&search=${data.search}&collectionId=${data.slug}&page=${data.page}&limit=${data.limit}&language=${data.language}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetChiefDetailCollection(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actRemoveArticlesInColletionDetailRequest = (data, params, id) => {
  return dispatch => {
    return callApi(`v2/collection/article`, "DELETE", {
      articleIds: data.list_of_article,
      collectionId: id
    })
      .then(() => {
        dispatch(actGetChiefCollectionsRequest(params));
        dispatch(actGetChiefDetailCollectionRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actRemoveColletionRequest = id => {
  return dispatch => {
    return callApi(`v2/collection`, "DELETE", {
      collectionId: id
    })
      .then(() => {
        window.location.href = "/admin/chief/manage-collection";
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actMoveToCollectionRequest = (data, params, id) => {
  return dispatch => {
    return callApi(`v2/collection/article`, "POST", {
      articleIds: data.list_of_article,
      collectionId: id
    })
      .then(() => {
        dispatch(actGetChiefCollectionsRequest(params));
        dispatch(actGetChiefDetailCollectionRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actSearchArticlesRequestWithoutId = data => {
  return dispatch => {
    return callApi(
      `article/get-list-article-and-search?search=title:${data.value}|angle_title:${data.value}|tags:${data.value}&limit=50&language=${data.language}`,
      "GET"
    )
      .then(res => {
        dispatch(actSearchArticles(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actSearchArticlesRequest = data => {
  return dispatch => {
    return callApi(
      `article/get-list-article-and-search?search=title:${data.value}|angle_title:${data.value}|tags:${data.value}&limit=50&language=${data.language}&idCol=${data.idCol}`,
      "GET"
    )
      .then(res => {
        dispatch(actSearchArticles(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSearchArticles = data_search => {
  return {
    type: Types.GET_SEARCH_ARTICLE_TO_COLLECTION,
    data_search
  };
};

export const actCreateCollectionRequest = (data, params) => {
  return dispatch => {
    return callApi(`v2/collection`, "POST", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Create collection success!",
            color: "success"
          })
        );
        dispatch(actGetChiefCollectionsRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPinnedCollectionRequest = data => {
  return dispatch => {
    return callApi(
      `v2/homepage-collection?language=${data.language}&isOnHomepage=true`,
      "GET"
    )
      .then(res => {
        const docs = res.data.docs.map(item => {
          return {
            collection_id: item,
            id: item.id,
            order_number: item.orderNumber
          };
        });
        dispatch(actGetPinnedCollection(docs));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPinnedCollection = data => {
  return {
    type: Types.GET_PINNED_COLLECTION,
    data
  };
};

export const actGetCollectionListRequest = data => {
  return dispatch => {
    return callApi(
      `v2/homepage-collection?language=${data.language}&isOnHomepage=false`,
      "GET"
    )
      .then(res => {
        dispatch(actGetCollections(res.data.docs));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetCollections = data => {
  return {
    type: Types.GET_COLLECTION_LIST,
    data
  };
};

export const actUpdateCollectionRequest = (data, params, id) => {
  return dispatch => {
    return callApi(`v2/collection`, "PUT", {
      collectionId: id,
      ...data
    })
      .then(() => {
        dispatch(actGetChiefCollectionsRequest(params));
        dispatch(actGetChiefDetailCollectionRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actPinCollectionToHomeRequest = (data, params) => {
  return dispatch => {
    return callApi(`v2/homepage-collection`, "POST", {
      collectionIds: data.collection_id
    })
      .then(() => {
        dispatch(
          actNotify({
            message: "Success!",
            color: "success"
          })
        );
        dispatch(actGetPinnedCollectionRequest(params));
        dispatch(actGetCollectionListRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actUpdateCollectionOrderRequest = (data, params) => {
  return dispatch => {
    return callApi(`v2/collection/homepage-order`, "PUT", {
      collections: data.collection_id.map(({ order_number, _id }) => ({
        collectionId: _id,
        orderNumber: order_number
      }))
    })
      .then(() => {
        dispatch(
          actNotify({
            message: "Success!",
            color: "success"
          })
        );
        dispatch(actGetPinnedCollectionRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actResetCollectionList = () => {
  return {
    type: Types.RESET_COLLECTION_LIST
  };
};

export const actRemovePinnedCollectionRequest = (id, params) => {
  return dispatch => {
    return callApi(`v2/collection/homepage-status`, "PUT", {
      collectionId: id,
      isOnHomepage: false
    })
      .then(() => {
        dispatch(
          actNotify({
            message: "Success!",
            color: "success"
          })
        );
        dispatch(actGetPinnedCollectionRequest(params));
        dispatch(actGetCollectionListRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// get collection published
export const actGetPublishedCollectionRequest = data => {
  return dispatch => {
    dispatch(actGetPublishedCollection());
    return callApi(
      `v2/collection/published?limit=50&page=1&language=${data.language}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetPublishedCollectionSuccess(res.data.docs));
      })
      .catch(err => {
        dispatch(actGetPublishedCollectionFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetPublishedCollection = () => {
  return {
    type: Types.GET_PUBLISHED_COLLECTION
  };
};
export const actGetPublishedCollectionSuccess = data => {
  return {
    type: Types.GET_PUBLISHED_COLLECTION_SUCCESS,
    data
  };
};
export const actGetPublishedCollectionFail = () => {
  return {
    type: Types.GET_PUBLISHED_COLLECTION_FAIL
  };
};
