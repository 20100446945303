import React, { Component } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Button,
  FormGroup,
  Form,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import { actGetPostInfoRequestV2 } from "../../actions/post.action";
import { actGetMyPitchDetailRequestV2 } from "../../actions/pitch.action";
import { stripHTMLTag } from "../../utils/stripHtml";
import "./styles.scss";
class CardContentMapping extends Component {
  state = {
    data: {
      audience: "",
      angle_title: "",
      titleWarn: false,
      mention_problem: "",
      type_of_article: [],
      topics_of_concern: [],
      steps_to_write: [],
      age_group: [],
      comprehension_level: [],
      tone_of_writing: [],
      topic: [],
      objectives: "",
      note: ""
    },
    isAllInputFilled: false,
    id: window.location.pathname.split("/")[4]
  };

  componentDidMount() {
    this.props.getPostInfo();
    this.state.id && this._getMyPitchDetail();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.writer_my_pitch_detail &&
      nextProps.writer_my_pitch_detail !== this.props.writer_my_pitch_detail &&
      nextProps.writer_my_pitch_detail
    ) {
      let writer_my_pitch_detail = nextProps.writer_my_pitch_detail;
      let topic = nextProps.writer_my_pitch_detail.topic[0];
      const data = {
        ...writer_my_pitch_detail,
        topic: [
          {
            ...topic,
            value: topic && topic.value,
            label: topic && topic.label
          }
        ]
      };
      this.setState({
        data
      });
      if (
        data.steps_to_write &&
        data.steps_to_write[0] &&
        data.steps_to_write[0].key === "other"
      ) {
        this.setState({ isOtherSteps: true });
      }
      if (
        data.tone_of_writing &&
        data.tone_of_writing[0] &&
        data.tone_of_writing[0].key === "other"
      ) {
        this.setState({ isOtherTone: true });
      }
    }
  }

  _getMyPitchDetail = () => {
    const id = this.state.id;
    this.props.getMyPitchDetail(id);
  };

  _checkedValue(arrayFilter, arrayInput, key) {
    var result = arrayFilter.filter(item1 =>
      arrayInput.some(item2 => item2.key === item1.key)
    );
    return result.some(item => item.key === key);
  }

  _handleOnChange = e => {
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data,
      titleWarn: e.target.value.length > 60
    });
    this.props.pitchData(this.state.data);
  };

  _handleOnSelectTopicChange = (topickey, topicname) => {
    let tagsFilter = this.props.post_info.data.topics.filter(
      prop => prop.key === topickey
    );
    this.setState({
      data: {
        ...this.state.data,
        topic: [
          {
            value: topickey,
            label: topicname,
            key: topickey,
            name: topicname,
            tags: tagsFilter[0].tags
          }
        ]
      }
    });
    const data = {
      ...this.state.data,
      topic: [
        {
          value: topickey,
          label: topicname,
          key: topickey,
          name: topicname,
          tags: tagsFilter[0].tags
        }
      ]
    };
    this.props.pitchData(data);
  };

  _handleOnChangeCheckboxArticle = e => {
    const { type_of_article } = this.state.data;
    var obj = {};
    let index;
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "articletypes" && e.target.checked) {
      type_of_article.push(obj);
    } else {
      index = type_of_article.findIndex(i => i.key === e.target.title);
      type_of_article.splice(index, 1);
    }
    this.setState({
      data: {
        ...this.state.data,
        type_of_article: type_of_article
      }
    });
    const data = {
      ...this.state.data,
      type_of_article: type_of_article
    };
    this.props.pitchData(data);
  };

  _handleOnChangeCheckboxConcern = e => {
    const { topics_of_concern } = this.state.data;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "concerntypes" && e.target.checked) {
      topics_of_concern.push(obj);
    } else {
      index = topics_of_concern.findIndex(i => i.key === e.target.title);
      topics_of_concern.splice(index, 1);
    }
    this.setState({
      data: {
        ...this.state.data,
        topics_of_concern: topics_of_concern
      }
    });
    const data = {
      ...this.state.data,
      topics_of_concern: topics_of_concern
    };
    this.props.pitchData(data);
  };

  _handleOnChangeCheckboxAge = e => {
    const age_group = this.state.data.age_group;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "agetypes" && e.target.checked) {
      age_group.push(obj);
    } else {
      index = age_group.findIndex(i => i.key === e.target.title);
      age_group.splice(index, 1);
    }
    this.setState({
      data: {
        ...this.state.data,
        age_group: age_group
      }
    });
    const data = { ...this.state.data, age_group: age_group };
    this.props.pitchData(data);
  };

  _handleOnChangeCheckboxCompreLevels = e => {
    const { comprehension_level } = this.state.data;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "compretypes" && e.target.checked) {
      comprehension_level.push(obj);
    } else {
      index = comprehension_level.findIndex(i => i.key === e.target.title);
      comprehension_level.splice(index, 1);
    }
    this.setState({
      data: {
        ...this.state.data,
        comprehension_level: comprehension_level
      },
      autoSave: true
    });
    const data = {
      ...this.state.data,
      comprehension_level: comprehension_level
    };
    this.props.pitchData(data);
  };

  _handleOnChangeCheckboxStepWrite = e => {
    const { steps_to_write } = this.state.data;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "stepsWrite" && e.target.checked) {
      steps_to_write.push(obj);
    } else {
      index = steps_to_write.findIndex(i => i.key === e.target.title);
      steps_to_write.splice(index, 1);
    }
    this.setState({
      data: { ...this.state.data, steps_to_write: steps_to_write }
    });
    let isOtherStepsCheck = steps_to_write.filter(prop => prop.key === "other");
    if (isOtherStepsCheck[0] && isOtherStepsCheck[0].key === "other") {
      this.setState({
        isOtherSteps: true
      });
    } else {
      this.setState({
        isOtherSteps: false
      });
    }
    const data = { ...this.state.data, steps_to_write: steps_to_write };
    this.props.pitchData(data);
  };

  _handleOnInputStepsOtherChange = e => {
    const data = this.state.data;
    const otherStep = this.state.data.steps_to_write;
    otherStep &&
      otherStep.forEach(prop => {
        if (prop.key === "other") {
          prop.name = e.target.value;
        }
      });
    this.setState({
      data: {
        ...data,
        steps_to_write: otherStep
      }
    });
    const dataState = { ...data, steps_to_write: otherStep };
    this.props.pitchData(dataState);
  };

  _handleOnChangeCheckboxToneWrite = e => {
    const { tone_of_writing } = this.state.data;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "tonetypes" && e.target.checked) {
      tone_of_writing.push(obj);
    } else {
      index = tone_of_writing.findIndex(i => i.key === e.target.title);
      tone_of_writing.splice(index, 1);
    }
    this.setState({
      data: { ...this.state.data, tone_of_writing: tone_of_writing }
    });
    let isOtherToneCheck = tone_of_writing.filter(prop => prop.key === "other");
    if (isOtherToneCheck[0] && isOtherToneCheck[0].key === "other") {
      this.setState({
        isOtherTone: true
      });
    } else {
      this.setState({
        isOtherTone: false
      });
    }
    const data = { ...this.state.data, tone_of_writing: tone_of_writing };

    this.props.pitchData(data);
  };

  _handleOnInputToneOtherChange = e => {
    const data = this.state.data;
    const otherTone = this.state.data.tone_of_writing;
    otherTone &&
      otherTone.forEach(prop => {
        if (prop.key === "other") {
          prop.name = e.target.value;
        }
      });
    this.setState({
      data: {
        ...data,
        tone_of_writing: otherTone
      }
    });
    const dataState = { ...data, tone_of_writing: otherTone };
    this.props.pitchData(dataState);
  };

  _checkAllInputFilled() {
    const {
      angle_title,
      age_group,
      audience,
      topic,
      type_of_article,
      topics_of_concern,
      objectives,
      comprehension_level,
      steps_to_write,
      tone_of_writing
    } = this.state.data;
    if (
      angle_title &&
      age_group &&
      audience &&
      topic &&
      type_of_article &&
      topics_of_concern &&
      objectives &&
      comprehension_level &&
      steps_to_write &&
      tone_of_writing
    ) {
      this.setState({
        isAllInputFilled: true
      });
    }
  }

  render() {
    const { post_info, topics_name, id } = this.props;
    const { data, titleWarn } = this.state;
    const {
      age_group,
      angle_title,
      audience,
      comprehension_level,
      objectives,
      steps_to_write,
      tone_of_writing,
      topic,
      topics_of_concern,
      type_of_article,
      dead_line
    } = data;

    const showDeadline = id && dead_line;

    return (
      <Card className="shadow" style={{ color: "#525f7f" }}>
        {showDeadline && (
          <CardHeader>
            <Row>
              <Col xs="12" className="py-0">
                <div className="deadline-note">
                  <p>
                    Please notice your revision deadline for pitch is:{" "}
                    <strong>{moment(dead_line).format("MMMM Do YYYY")}</strong>
                  </p>
                </div>
              </Col>
            </Row>
          </CardHeader>
        )}
        <CardBody>
          <Form>
            <Row>
              <Col md="7">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="title">
                    Angle/Title
                  </label>
                  <Input
                    id="angle_title"
                    name="angle_title"
                    value={data.angle_title && data.angle_title}
                    type="text"
                    className={`d-inline mr-3 ` + (titleWarn ? "warn" : "")}
                    onChange={this._handleOnChange}
                  />
                  <div
                    className={`d-flex ${
                      titleWarn
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {titleWarn && (
                      <small className="text-red">
                        Title ideally shouldn't be longer than 60 characters,
                        but you can still sumbit
                      </small>
                    )}
                    <small className="text-right">
                      {data.angle_title ? data.angle_title.length : "0"}/60
                    </small>
                  </div>
                </FormGroup>
              </Col>
              <Col md="5">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="topic">
                    Topic
                  </label>
                  <div className="custom-vietcetera-topic">
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      size="lg"
                      closeMenuOnSelect={true}
                      // value={
                      //   this.state.id
                      //     ? topics_name.filter(
                      //         ({ value }) => value === data.topic[0].key
                      //       )
                      //     : data.topic
                      // }
                      value={data.topic}
                      options={topics_name}
                      onChange={value =>
                        this._handleOnSelectTopicChange(value.key, value.name)
                      }
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="mention_problem"
                  >
                    What Is the Main Problem/Question You Would like to Address
                    ?
                  </label>
                  <Input
                    id="mention-problem-input"
                    rows="5"
                    type="textarea"
                    name="mention_problem"
                    value={
                      data.mention_problem && stripHTMLTag(data.mention_problem)
                    }
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ paddingLeft: "15px" }} className="mb-0">
              <label
                className="form-control-label d-block"
                htmlFor="articleType"
              >
                Type of Article (Choose One)
              </label>
            </Row>
            <Row className="mb-3">
              {post_info.data.typesOfArticle &&
                post_info.data.typesOfArticle.map((prop, key) => {
                  return (
                    <Col key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"article" + prop.key}
                          name="articletypes"
                          checked={this._checkedValue(
                            post_info.data.typesOfArticle,
                            data.type_of_article,
                            prop.key
                          )}
                          title={prop.key}
                          pattern={prop.name}
                          value={prop.name}
                          type="checkbox"
                          onChange={this._handleOnChangeCheckboxArticle.bind(
                            this
                          )}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"article" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row style={{ paddingLeft: "15px" }} className="mb-0">
              <label
                className="form-control-label d-block"
                htmlFor="concernType"
              >
                Type of Concerns (Choose One)
              </label>
            </Row>
            <Row className="mb-3">
              {post_info.data.topicsOfConcern &&
                post_info.data.topicsOfConcern.map((prop, key) => {
                  return (
                    <Col key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"concern" + prop.key}
                          type="checkbox"
                          name="concerntypes"
                          checked={this._checkedValue(
                            post_info.data.topicsOfConcern,
                            data.topics_of_concern,
                            prop.key
                          )}
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          onChange={this._handleOnChangeCheckboxConcern.bind(
                            this
                          )}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"concern" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row className="mt-1">
              <Col md="12">
                <label
                  className="form-control-label d-block mt-1"
                  htmlFor="objectives"
                >
                  Objectives / What Can the Readers Get from Reading Your
                  Article?
                </label>
                <Input
                  id="objectives-input"
                  rows="5"
                  type="textarea"
                  value={data.objectives && stripHTMLTag(data.objectives)}
                  name="objectives"
                  onChange={this._handleOnChange}
                />
              </Col>
            </Row>
            <label
              className="form-control-label d-block"
              htmlFor="targetAudience"
              style={{ marginTop: "32px" }}
            >
              Target Audience
            </label>
            <Row className="mt-3">
              <Col md="3">
                <label
                  className="form-control-label mb-0 mt-1"
                  htmlFor="agegroup"
                >
                  Age Group:
                </label>
              </Col>
              {post_info.data.ageGroups &&
                post_info.data.ageGroups.map((prop, key) => {
                  return (
                    <Col key={key}>
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          id={"age" + prop.key}
                          type="checkbox"
                          name="agetypes"
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          checked={this._checkedValue(
                            post_info.data.ageGroups,
                            data.age_group,
                            prop.key
                          )}
                          onChange={this._handleOnChangeCheckboxAge.bind(this)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"age" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row className="mt-3">
              <Col md="3">
                <label
                  className="form-control-label mb-0 mt-1"
                  htmlFor="comLevel"
                >
                  Comprehension Level of Reader:
                </label>
              </Col>
              {post_info.data.comprehensionLevels &&
                post_info.data.comprehensionLevels.map((prop, key) => {
                  return (
                    <Col key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"comlevels" + prop.key}
                          type="checkbox"
                          name="compretypes"
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          checked={this._checkedValue(
                            post_info.data.comprehensionLevels,
                            data.comprehension_level,
                            prop.key
                          )}
                          onChange={this._handleOnChangeCheckboxCompreLevels.bind(
                            this
                          )}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"comlevels" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row className="mt-3">
              <Col md="12">
                <label
                  className="form-control-label mb-0 mt-1"
                  htmlFor="comLevel"
                >
                  Describe Your Audience
                </label>
                <Input
                  id="audience-input"
                  rows="5"
                  type="textarea"
                  value={data.audience && stripHTMLTag(data.audience)}
                  name="audience"
                  onChange={this._handleOnChange}
                />
              </Col>
            </Row>
            <Row className="mt-3" style={{ paddingLeft: "15px" }}>
              <label className="form-control-label d-block" htmlFor="step">
                Steps Required to Write This Article (You Can Choose Many)
              </label>
            </Row>
            <Row className="mb-3">
              {post_info.data.stepsToWrite &&
                post_info.data.stepsToWrite.map((prop, key) => {
                  return (
                    <Col md="3" key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"step" + prop.key}
                          name="stepsWrite"
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          type="checkbox"
                          checked={this._checkedValue(
                            post_info.data.stepsToWrite,
                            data.steps_to_write,
                            prop.key
                          )}
                          onChange={this._handleOnChangeCheckboxStepWrite.bind(
                            this
                          )}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"step" + prop.key}
                        >
                          {prop.key === "other" ? "Other" : prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {this.state.isOtherSteps && (
              <Row className="mt-3">
                <Col md="12">
                  <Input
                    id="stepsOther"
                    name="stepsOther"
                    type="text"
                    // value={this._getOtherValue(data.steps_to_write)}
                    className="d-inline mr-3"
                    placeholder="Insert other steps here"
                    onChange={this._handleOnInputStepsOtherChange}
                  />
                </Col>
              </Row>
            )}
            <Row className="mt-4" style={{ paddingLeft: "15px" }}>
              <label className="form-control-label d-block" htmlFor="tone">
                Tone of Writing (Choose One)
              </label>
            </Row>
            <Row className="mb-3">
              {post_info.data.tonesOfWriting &&
                post_info.data.tonesOfWriting.map((prop, key) => {
                  return (
                    <Col md="3" key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"tone" + prop.key}
                          type="checkbox"
                          name="tonetypes"
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          checked={this._checkedValue(
                            post_info.data.tonesOfWriting,
                            data.tone_of_writing,
                            prop.key
                          )}
                          onChange={this._handleOnChangeCheckboxToneWrite.bind(
                            this
                          )}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"tone" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {this.state.isOtherTone && (
              <Col md="12">
                <Row className="mt-3">
                  <Input
                    id="toneothers"
                    name="toneothers"
                    type="text"
                    className="d-inline"
                    // value={this._getOtherValue(data.tone_of_writing)}
                    placeholder="Describe your desired tone of voice."
                    onChange={this._handleOnInputToneOtherChange}
                  />
                </Row>
              </Col>
            )}
            <Row className="mt-3">
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="note">
                    Note
                  </label>
                  <Input
                    id="note-input"
                    rows="5"
                    value={data.note && stripHTMLTag(data.note)}
                    type="textarea"
                    name="note"
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr className="my-4" />
          </Form>
          <div className="text-right">
            <Link to={"/admin/writer/my-pitches"} className="mr-3">
              <Button color="outline-warning" type="button">
                Cancel
              </Button>
            </Link>

            <Button
              onClick={e => this.props.toggleNavs(e, "tabs", 2)}
              color="success"
              type="button"
              disabled={
                topic.length === 0 ||
                type_of_article.length === 0 ||
                topics_of_concern.length === 0 ||
                age_group.length === 0 ||
                comprehension_level.length === 0 ||
                steps_to_write.length === 0 ||
                tone_of_writing.length === 0 ||
                angle_title === "" ||
                objectives === "" ||
                audience === ""
                  ? true
                  : false
              }
            >
              Write Outline
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    post_info: state.postReducer.post_info,
    writer_my_pitch_detail: state.pitchReducer.writer_my_pitch_detail,
    topics_name: state.postReducer.topics_name
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPostInfo: data => {
      dispatch(actGetPostInfoRequestV2(data));
    },
    getMyPitchDetail: (id, data) => {
      dispatch(actGetMyPitchDetailRequestV2(id, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardContentMapping);
CardContentMapping.propTypes = {
  post_info: PropTypes.object,
  writer_my_pitch_detail: PropTypes.object,
  topics_name: PropTypes.array,
  toggleNavs: PropTypes.func,
  getPostInfo: PropTypes.func,
  pitchData: PropTypes.func,
  getMyPitchDetail: PropTypes.func
};
CardContentMapping.defaultProps = {};
