import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import WriterOutlineInProgressTable from "../../../components/Tables/WriterOutlineInProgressTable";
class WriterOutlineInProgress extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeader
          name="Outline - In Progress"
          content="outlineinprogress"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <WriterOutlineInProgressTable {...props} />
        </Container>
      </>
    );
  }
}
WriterOutlineInProgress.propTypes = {
  content: PropTypes.string,
};
export default WriterOutlineInProgress;
