import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Modal,
  Divider,
  Typography,
} from "antd";
import {
  FormGroup,
  UncontrolledPopover,
  PopoverBody,
  Row,
  Col,
} from "reactstrap";
import CustomEditor from "../../TinyMCE";
import ImageUpload from "../../ImageUpload";
import { ReactComponent as Icon } from "../../../assets/img/icons/exclamation-outline.svg";
import "./styles.scss";
import {
  actAddTimelineItemRequest,
  actGetTimelineDetailRequest,
  actClearTimelineDetailItem,
  actUpdateTimelineItemRequest,
} from "../../../actions/timeline.action";
import HOST_URL from "../../../variables/hostUrl";
const { Text } = Typography;

const LiveUpdateForm = ({
  visible,
  handleCancel,
  handleOk,
  currentId,
  isEngVersion,
  form: {
    getFieldDecorator,
    setFieldsValue,
    resetFields,
    getFieldValue,
    validateFields,
  },
}) => {
  const [description, setDescription] = useState("");
  const timelineDetail = useSelector(
    (state) => state.timelineReducer.timelineDetail
  );
  const dispatch = useDispatch();
  const [quillError, setQuillError] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const title = get(timelineDetail, "title");
  const content = get(timelineDetail, "content");
  const imageCaption = get(timelineDetail, "imageCaption");
  const imageAlt = get(timelineDetail, "imageAlt");
  const publishDate = get(timelineDetail, "publishDate");
  const imagePath = get(timelineDetail, "images.size.ONE_ONE_368x368.url");
  const imgUrlFromServer = imagePath ? `${HOST_URL}${imagePath}` : "";

  // Life cycle

  useEffect(() => {
    if (currentId) {
      dispatch(actGetTimelineDetailRequest(currentId));
    }
    return () => {
      setQuillError(false);
      dispatch(actClearTimelineDetailItem());
      resetFields();
    };
  }, [currentId]);

  useEffect(() => {
    setFieldsValue({
      title,
      imageCaption,
      imageAlt,
      publishDate: moment(publishDate),
    });
    setDescription(content);
    setImageUrl(imgUrlFromServer);
  }, [timelineDetail]);

  const handleOnContentChange = (value) => {
    setQuillError(false);
    setDescription(value);
  };

  const handleChangeImage = (id) => {
    setFieldsValue({
      imageId: id,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!description) {
      setQuillError(true);
      return;
    }
    if (description) {
      const rawContent = description.replace(/<[^>]*>/g, "");
      if (rawContent.length === 0) {
        setQuillError(true);
        return;
      }
    }
    validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values,
          content: description,
          name: !isEngVersion ? "covid-19" : "covid-19-en",
        };
        dispatch(actAddTimelineItemRequest(data));
        dispatch(actClearTimelineDetailItem());
        handleCancel();
        resetFields();
        setDescription("");
        setImageUrl("");
      }
    });
  };

  const onChangeImageUrl = (url) => {
    setImageUrl(url);
  };

  const updateTimeline = () => {
    const title = getFieldValue("title");
    const publishDate = getFieldValue("publishDate");
    const imageCaption = getFieldValue("imageCaption");
    const imageAlt = getFieldValue("imageAlt");
    const imageId = getFieldValue("imageId");
    const rawContent = description.replace(/<[^>]*>/g, "");
    if (rawContent.length === 0) {
      setQuillError(true);
      return;
    }
    const data = !isEngVersion
      ? {
          timelineId: currentId,
          title,
          content: description,
          publishDate,
          imageCaption,
          imageAlt,
          imageId,
        }
      : {
          timelineId: currentId,
          title,
          content: description,
          publishDate,
          imageCaption,
          imageAlt,
          imageId,
          isEngVersion,
        };
    dispatch(actUpdateTimelineItemRequest(data));
    handleCancel();
  };

  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  return (
    <Modal
      className={`application-info-modal`}
      visible={visible}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <div className="live-update-manangement-section">
        <h3>Add new update</h3>
        <Divider />
        <Form
          initialValues={{
            imageId: "",
            content: "",
          }}
          onSubmit={handleSubmit}
          className="live-update-form"
        >
          <label>
            Headline <Text type="danger">*</Text>
          </label>
          <Form.Item>
            {getFieldDecorator("title", {
              rules: [{ required: true, message: "This field is required!" }],
            })(<Input placeholder="Title" />)}
          </Form.Item>
          <label>
            Date & time <Text type="danger">*</Text>
          </label>
          <Form.Item>
            {getFieldDecorator("publishDate", config)(
              <DatePicker showTime format="DD-MM-YYYY HH:mm:ss" />
            )}
          </Form.Item>
          <label>
            Description <Text type="danger">*</Text>
          </label>
          <div className="description-section">
            <CustomEditor
              content={description || ""}
              onChangeContent={(value) => handleOnContentChange(value)}
            />
            {quillError ? (
              <Text type="danger">This field is required!</Text>
            ) : null}
          </div>
          <div className="image-upload-section mt-4">
            <Row className="dropzone-wrapper">
              <Col md="6">
                <Form.Item>
                  {getFieldDecorator("imageId")(
                    <ImageUpload
                      maxSize="500kb"
                      imageUrl={imageUrl}
                      onChangeImageUrl={onChangeImageUrl}
                      callback={handleChangeImage}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col md="6">
                <FormGroup>
                  <div>
                    <label>Feature Image’s Caption</label>
                    <Form.Item>
                      {getFieldDecorator("imageCaption")(
                        <Input placeholder="Feature image caption" />
                      )}
                    </Form.Item>
                  </div>
                  <div className="d-flex align-items-center">
                    <label>Feature Image’s Alternative Text</label>
                    <Icon
                      style={{ marginBottom: "0.5em" }}
                      id="Popover"
                      className="exclamation"
                    />
                  </div>
                  <UncontrolledPopover
                    placement="bottom"
                    target="Popover"
                    trigger="legacy"
                  >
                    <PopoverBody>
                      The alternative text provides alternative information for
                      an image if a user for some reason cannot view it (because
                      of slow connection, or if the user uses a screen reader).
                    </PopoverBody>
                  </UncontrolledPopover>

                  <Form.Item>
                    {getFieldDecorator("imageAlt")(
                      <Input placeholder="Feature image alt" />
                    )}
                  </Form.Item>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="btn-group d-flex align-items-center justify-content-end">
            {title ? (
              <Button type="primary" onClick={updateTimeline} className="mr-2">
                Update
              </Button>
            ) : (
              <Form.Item>
                <Button type="primary" htmlType="submit" className="mr-2">
                  Save
                </Button>
              </Form.Item>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};
const WrappedLiveUpdateForm = Form.create()(LiveUpdateForm);
export default WrappedLiveUpdateForm;
