import React from "react";
import LiveUpdateManagementList from "./LiveUpdateManagementList";
import "./styles.scss";

class LiveUpdateManagement extends React.Component {
  render() {
    return (
      <section>
        <div className="live-update-manangement-section">
          <LiveUpdateManagementList />
        </div>
      </section>
    );
  }
}
export default LiveUpdateManagement;
