import React, { Component } from "react";
import { Card, CardHeader, CardFooter, Table, Col, Row } from "reactstrap";
import CustomPagination from "../Pagination/Pagination";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetChiefAvailableBriefsRequest } from "../../actions/chiefArticle.action";
import { Tooltip } from "antd";
import ReactHtmlParser from "react-html-parser";
import { formatStringToDate } from "../../utils/formatDate";

class ChiefAvailableBriefsTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getChiefAvailableBrief();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getChiefAvailableBrief = () => {
    const params = this.state.params;
    this.props.getChiefAvailableBrief(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getChiefAvailableBrief(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getChiefAvailableBrief(paramsSort);
  };
  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/chief/new-brief/${id}`);
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 50) {
      return `${str[0].substring(0, 50)}...`;
    } else {
      return str[0].substring(0, 50);
    }
  };

  render() {
    const { available_briefs_list } = this.props;
    const page = available_briefs_list.data && available_briefs_list.data.page;
    const totalPages =
      available_briefs_list.data && available_briefs_list.data.totalPages;
    const totalDocs =
      available_briefs_list.data && available_briefs_list.data.totalDocs;
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Available Briefs List</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table
                  className="align-items-center table-flush"
                  style={{ border: "0px", overflow: "hidden" }}
                  hover
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        className="sort"
                        data-sort="name"
                        scope="col"
                        onClick={() => this._sortByColumn("angle_title")}
                      >
                        Title
                      </th>
                      <th
                        className="sort"
                        data-sort="status"
                        scope="col"
                        onClick={() => this._sortByColumn("dead_line")}
                      >
                        Deadline
                      </th>
                      <th scope="col">TOPIC</th>
                    </tr>
                  </thead>

                  <tbody className="list">
                    {available_briefs_list &&
                      available_briefs_list.data &&
                      available_briefs_list.data.docs.map((prop, key) => {
                        return (
                          <tr
                            key={key}
                            className="cursor-pointer"
                            onClick={e => this._handleOnCellClick(e, prop.id)}
                          >
                            <th scope="row">
                              <Tooltip
                                title={ReactHtmlParser(prop.angle_title)}
                                placement="bottom"
                              >
                                <span>
                                  {this.limitTitle(
                                    ReactHtmlParser(prop.angle_title)
                                  )}
                                </span>
                              </Tooltip>
                            </th>
                            <td>
                              {prop.dead_line &&
                                formatStringToDate(prop.dead_line)}
                            </td>

                            <td>
                              {prop
                                ? prop.topic.map(topic => {
                                    return (
                                      <>
                                        <div className="mt-2  _topic">
                                          {this._text_truncate(
                                            topic.label
                                              ? topic.label
                                              : topic.name,
                                            40,
                                            "..."
                                          )}
                                        </div>
                                      </>
                                    );
                                  })
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                    {available_briefs_list.data.length === 0 && (
                      <div
                        className="p-4 text-center font-italic font-weight-bold"
                        style={{ color: "#ccc", fontSize: "18px" }}
                      >
                        There are no content available
                      </div>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    available_briefs_list: state.chiefArticleReducer.available_briefs_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getChiefAvailableBrief: params => {
      dispatch(actGetChiefAvailableBriefsRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefAvailableBriefsTable);
ChiefAvailableBriefsTable.propTypes = {
  available_briefs_list: PropTypes.object,
  getChiefAvailableBrief: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};
ChiefAvailableBriefsTable.defaultProps = {};
