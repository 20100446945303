import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";
// import { Link } from "react-router-dom";
// import Button from "components/CustomButton/CustomButton";

class CardHeaderMyProfile extends React.Component {
  state = {
    pathnameRoutes: window.location.pathname.split("/")[3],
    isAddTopic: false
  };
  _toggleModalAddTopic = () => {
    this.setState(prevState => ({
      isAddTopic: !prevState.isAddTopic
    }));
  };
  render() {
    // const pathname = this.state.pathname;
    const pathnameRoutes = this.state.pathnameRoutes;

    return (
      <>
        {/* <ChiefTopicModal
          modal={this.state.isAddTopic}
          toggle={this._toggleModalAddTopic}
        /> */}
        <div className="header pb-6" style={{ backgroundColor: "#11cdef" }}>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  {this.props.name !== "Writer" && (
                    <Breadcrumb
                      className="d-none d-md-inline-block ml-md-4"
                      listClassName="breadcrumb-links breadcrumb-dark"
                    >
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="fas fa-home" />
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {this.props.parentName}
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem aria-current="page" className="active">
                        {this.props.name}
                      </BreadcrumbItem>
                    </Breadcrumb>
                  )}
                </Col>
                {pathnameRoutes !== "my-profile" ? (
                  <Col lg="6 text-right">
                    {/* <Link to={"/admin/editor/new-brief"}> */}
                    <button
                      type="button"
                      className="btn-icon btn btn-secondary"
                      onClick={this._toggleModalAddTopic}
                    >
                      <span className="btn-inner--icon mr-1">
                        {/* <i className="fab fa-pinterest"></i> */}
                        <i
                          className="fa fa-plus-circle"
                          style={{ color: "#5e72e4" }}
                        ></i>
                      </span>

                      <span
                        className="btn-inner--text"
                        style={{ color: "#5e72e4" }}
                      >
                        Add New User
                      </span>
                    </button>
                    {/* </Link> */}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {pathnameRoutes !== "my-profile" ? (
                <Row>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Pending Approval
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">30</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i className="ni ni-like-2" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-success mr-2">
                            <i className="fa fa-arrow-up" /> 3.48%
                          </span>{" "}
                          <span className="text-nowrap">Since yesterday</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              In Progress
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">45</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i className="ni ni-ruler-pencil" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-success mr-2">
                            <i className="fa fa-arrow-up" /> 3.48%
                          </span>{" "}
                          <span className="text-nowrap">Since last month</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            ></CardTitle>
                            <span className="h2 font-weight-bold mb-0">12</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-info  text-white rounded-circle shadow">
                              <i className="fa fa-copy" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-success mr-2">
                            <i className="fa fa-fire" /> 3.48%
                          </span>{" "}
                          <span className="text-nowrap">Since yesterday</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Outline Content
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">14</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red  text-white rounded-circle shadow">
                              <i className="fa fa-fire" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-success mr-2">
                            <i className="fa fa-arrow-up" /> 3.48%
                          </span>{" "}
                          <span className="text-nowrap">Since last month</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

CardHeaderMyProfile.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  content: PropTypes.string
};

export default CardHeaderMyProfile;
