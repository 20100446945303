import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetArticleWriterDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `dashboard/writer-content?` +
        `&limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetArticleWriterDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetArticleWriterDashboard = data => {
  return {
    type: Types.GET_ARTICLE_WRITER_DASHBOARD,
    data
  };
};

export const actGetOutlineWriterDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `dashboard/writer-outline?` +
        `limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetOutlineWriterDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetOutlineWriterDashboard = data => {
  return {
    type: Types.GET_OUTLINE_WRITER_DASHBOARD,
    data
  };
};

export const actGetPitchWriterDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `dashboard/writer-pitch?` +
        `limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetPitchesWriterDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPitchesWriterDashboard = data => {
  return {
    type: Types.GET_PITCH_WRITER_DASHBOARD,
    data
  };
};
