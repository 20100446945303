import React, { Component } from "react";
import { Card, Table, Row } from "reactstrap";
import { connect } from "react-redux";

class ArticleTable extends Component {
  state = {
    data: [
      {
        topic_name: "BẢO VỆ MÔI TRƯỜNG",
        number_article: 50,
        number_views: "1,353"
      },
      {
        topic_name: "TÀI CHÍNH CÁ NHÂN",
        number_article: 10,
        number_views: "1,200"
      },
      {
        topic_name: "KHỞI NGHIỆP VIỆT NAM",
        number_article: 10,
        number_views: "1,123"
      },
      {
        topic_name: "FINTECH",
        number_article: 10,
        number_views: "1,567"
      },
      {
        topic_name: "NGƯỜI TRẺ VIỆT",
        number_article: 10,
        number_views: "1,345"
      },
      {
        topic_name: "ẨM THỰC",
        number_article: 10,
        number_views: "1,856"
      },
      {
        topic_name: "DU LỊCH",
        number_article: 10,
        number_views: "1,342"
      },
      {
        topic_name: "VĂN HÓA",
        number_article: 10,
        number_views: "1,342"
      }
    ]
  };
  render() {
    const data = this.state.data;
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <div className="table-responsive">
                <Table
                  className="align-items-center table-flush"
                  style={{ border: "0px", overflow: "hidden" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="sort" data-sort="topic">
                        TOPIC
                      </th>
                      <th className="sort" data-sort="name" scope="col">
                        NUMBER OF PUBLISHED ARTICLES
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        NUMBER OF VIEWS
                      </th>
                    </tr>
                  </thead>

                  <tbody className="list">
                    {data.map((prop, key) => {
                      return (
                        <>
                          <tr key={key}>
                            <td>
                              <div className="_topic">{prop.topic_name}</div>
                            </td>
                            <td>
                              <span className="number">
                                {prop.number_article}
                              </span>
                              <span className="number_content"> articles</span>
                            </td>
                            <td>
                              <span className="number">
                                {prop.number_views}
                              </span>
                              <span className="number_content"> views</span>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
export default connect(
  null,
  null
)(ArticleTable);
