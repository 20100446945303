import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  articles_dashboard: {},
  outlines_dashboard: {},
  pitches_dashboard: {}
};

const chiefDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_ARTICLE_CHIEF_DASHBOARD:
      state = {
        ...state,
        articles_dashboard: action.data
      };
      return { ...state };
    case Types.GET_OUTLINE_CHIEF_DASHBOARD:
      state = {
        ...state,
        outlines_dashboard: action.data
      };
      return { ...state };
    case Types.GET_PITCH_CHIEF_DASHBOARD:
      state = {
        ...state,
        pitches_dashboard: action.data
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default chiefDashboardReducer;
