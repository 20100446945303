import callApi from "../utils/callApi";
import { has } from "lodash";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";
import { actGetWritingContentByIDRequest } from "./writerArticle.action";

// Action
export const actGetListApplication = () => {
  return {
    type: Types.GET_LIST_APPLICATION_START
  };
};
export const actGetListApplicationSuccess = data => {
  return {
    type: Types.GET_LIST_APPLICATION_SUCCESS,
    data
  };
};
export const actGetListApplicationFail = () => {
  return {
    type: Types.GET_LIST_APPLICATION_FAIL
  };
};
// Search article
export const actSearchApplication = () => {
  return {
    type: Types.SEARCH_APPLICATION_START
  };
};
export const actSearchApplicationFail = () => {
  return {
    type: Types.SEARCH_APPLICATION_FAIL
  };
};
export const actSearchApplicationSuccess = data => {
  return {
    type: Types.SEARCH_APPLICATION_SUCCESS,
    data
  };
};

export const actGetApplicationDetailSuccess = data => {
  return {
    type: Types.GET_APPLICATION_DETAIL_SUCCESS,
    data
  };
};

export const actGetApplicationDetailStart = () => {
  return {
    type: Types.GET_APPLICATION_DETAIL_START
  };
};
export const actGetApplicationDetailFail = () => {
  return {
    type: Types.GET_APPLICATION_DETAIL_FAIL
  };
};

export const actGetApplicationInformationSuccess = data => {
  return {
    type: Types.GET_APPLICATION_INFO_SUCCESS,
    data
  };
};

export const actApproveApplication = () => {
  return {
    type: Types.APPROVE_APPLICATION_SUCCESS
  };
};
export const actRejectApplication = () => {
  return {
    type: Types.REJECT_APPLICATION_SUCCESS
  };
};
export const actRevaluateApplication = () => {
  return {
    type: Types.REVALUATE_APPLICATION_SUCCESS
  };
};
export const actGetTotalPendingApplicationSuccess = data => {
  return {
    type: Types.GET_TOTAL_PENDING_APPLICATION_SUCCESS,
    data
  };
};
export const actGetTotalProcessedApplicationSuccess = data => {
  return {
    type: Types.GET_TOTAL_PROCESSED_APPLICATION_SUCCESS,
    data
  };
};
//Action creators
// List application
export const actGetListApplicationRequest = data => {
  return dispatch => {
    dispatch(actGetListApplication());
    const rootUrl = `v2/article/application/?page=${data.page}&limit=${data.limit}${data.status}`;
    const filterTopicURl = `${rootUrl}&topicKey=${data.topicKey}`;
    const endpoint = has(data, "topicKey") ? filterTopicURl : rootUrl;
    return callApi(endpoint, "GET")
      .then(res => {
        dispatch(actGetListApplicationSuccess(res.data));
      })
      .catch(err => {
        dispatch(actGetListApplicationFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// Search application
export const actSearchApplicationRequest = data => {
  return dispatch => {
    dispatch(actSearchApplication());
    const endpoint = `/v2/article/application/search`;
    return callApi(endpoint, "POST", data)
      .then(res => {
        dispatch(actSearchApplicationSuccess(res.data));
      })
      .catch(err => {
        dispatch(actSearchApplicationFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// Application Information
export const actGetApplicationInformationRequest = id => {
  return dispatch => {
    const endpoint = `v2/article/applicants-information?userId=${id}`;
    return callApi(endpoint, "GET")
      .then(res => {
        dispatch(actGetApplicationInformationSuccess(res.data.user));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// Application Detail
// Get detail
export const actGetApplicationDetailRequest = id => {
  return dispatch => {
    dispatch(actGetApplicationDetailStart());
    const endpoint = `v2/article/application/${id}`;
    return callApi(endpoint, "GET")
      .then(res => {
        dispatch(actGetApplicationDetailSuccess(res.data.doc));
      })
      .catch(err => {
        dispatch(actGetApplicationDetailFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
//Approve application
export const actApproveApplicationRequest = data => {
  return dispatch => {
    const endpoint = `v2/article/approve-application`;
    const params = {
      page: 1,
      limit: 10,
      status: data.status
    };
    const pendingStatus = "&status[]=NEW";
    const body = {
      articleId: data.articleId
    };
    return callApi(endpoint, "POST", body)
      .then(() => {
        dispatch(actApproveApplication());
        dispatch(actGetTotalPendingApplication(pendingStatus));
        dispatch(actGetTotalProcessedApplication(data.status));
        dispatch(actGetListApplicationRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
//Reject application
export const actRejectApplicationRequest = data => {
  return dispatch => {
    const endpoint = `v2/article/reject-application`;
    const params = {
      page: 1,
      limit: 10,
      status: data.status
    };
    const pendingStatus = "&status[]=NEW";
    const body = {
      articleId: data.articleId
    };
    return callApi(endpoint, "POST", body)
      .then(() => {
        dispatch(actRejectApplication());
        dispatch(actGetTotalPendingApplication(pendingStatus));
        dispatch(actGetTotalProcessedApplication(data.status));
        dispatch(actGetListApplicationRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
//Re-evaluate application
export const actRevaluateApplicationRequest = data => {
  return dispatch => {
    const endpoint = `v2/article/reevaluation-application`;
    const params = {
      page: 1,
      limit: 10,
      status: data.status
    };
    const body = {
      reason: data.reason,
      articleId: data.articleId
    };
    const pendingStatus = "&status[]=NEW";
    return callApi(endpoint, "POST", body)
      .then(() => {
        dispatch(actRevaluateApplication());
        dispatch(actGetTotalPendingApplication(pendingStatus));
        dispatch(actGetTotalProcessedApplication(data.status));
        dispatch(actGetListApplicationRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
//Count number pending application
export const actGetTotalPendingApplication = status => {
  return dispatch => {
    const endpoint = `/v2/article/count-status?${status}`;
    return callApi(endpoint, "GET")
      .then(res => {
        dispatch(actGetTotalPendingApplicationSuccess(res.data.total));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// Count number processed application
export const actGetTotalProcessedApplication = status => {
  return dispatch => {
    const endpoint = `/v2/article/count-status?${status}`;
    return callApi(endpoint, "GET")
      .then(res => {
        dispatch(actGetTotalProcessedApplicationSuccess(res.data.total));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// Extend deadline article
export const actUpdateArticleDeadlineRequest = (id, data) => {
  return dispatch => {
    callApi(`v2/article/dead-line/${id}`, "PUT", data)
      .then(() => {
        dispatch(actGetWritingContentByIDRequest(id));
        dispatch(
          actNotify({
            message: "Deadline has been updated successfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
