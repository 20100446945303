import React, { Component } from "react";
import { Card, CardHeader, CardFooter, Table, Col, Row } from "reactstrap";
import CustomPagination from "../Pagination/Pagination";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetChiefDraftBriefsRequest } from "../../actions/chiefArticle.action";
import Avatar from "../Avatar/Avatar";

class ChiefDraftBriefsTable extends Component {
  state = {
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getDraftBriefs();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getDraftBriefs = () => {
    const params = this.state.params;
    this.props.getChiefDraftBriefs(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getChiefDraftBriefs(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getChiefDraftBriefs(paramsSort);
  };
  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/chief/new-brief/${id}`);
  };
  render() {
    const { chief_draft_briefs } = this.props;
    const page = chief_draft_briefs.data && chief_draft_briefs.data.page;
    const totalPages =
      chief_draft_briefs.data && chief_draft_briefs.data.totalPages;
    const totalDocs =
      chief_draft_briefs.data && chief_draft_briefs.data.totalDocs;
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Draft Briefs List</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {chief_draft_briefs &&
                  chief_draft_briefs.data &&
                  chief_draft_briefs.data.docs.length === 0 && (
                    <div
                      className="p-7 text-center font-italic font-weight-bold"
                      style={{ color: "#ccc", fontSize: "18px" }}
                    >
                      There are no content available
                    </div>
                  )}
                {chief_draft_briefs &&
                  chief_draft_briefs.data &&
                  chief_draft_briefs.data.docs &&
                  chief_draft_briefs.data.docs.length !== 0 && (
                    <Table
                      className="align-items-center table-flush"
                      style={{ border: "0px" }}
                      hover
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            onClick={() => this._sortByColumn("angle_title")}
                          >
                            Title
                          </th>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            onClick={() => this._sortByColumn("editor")}
                          >
                            Editor
                          </th>
                          <th
                            className="sort"
                            data-sort="status"
                            scope="col"
                            onClick={() => this._sortByColumn("time_to_write")}
                          >
                            Time to Write
                          </th>
                          <th>TOPIC</th>
                        </tr>
                      </thead>

                      <tbody className="list">
                        {chief_draft_briefs &&
                          chief_draft_briefs.data &&
                          chief_draft_briefs.data.docs &&
                          chief_draft_briefs.data.docs.map((prop, key) => {
                            return (
                              <>
                                <tr
                                  key={key}
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    this._handleOnCellClick(e, prop.id)
                                  }
                                >
                                  <th scope="row">
                                    {this._text_truncate(
                                      prop.angle_title,
                                      50,
                                      "..."
                                    )}
                                  </th>
                                  <td>
                                    <Avatar
                                      src={prop.editor.avatar}
                                      className="d-inline-block mr-3"
                                    />
                                    {prop.editor.name}
                                  </td>
                                  <td>
                                    {prop.time_to_write &&
                                      prop.time_to_write + " day(s)"}
                                  </td>
                                  <td>
                                    {prop
                                      ? prop.topic.map(topic => {
                                          return (
                                            <>
                                              <div className="mt-2  _topic">
                                                {this._text_truncate(
                                                  topic.label
                                                    ? topic.label
                                                    : topic.name,
                                                  20,
                                                  "..."
                                                )}
                                              </div>
                                            </>
                                          );
                                        })
                                      : ""}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    chief_draft_briefs: state.chiefArticleReducer.chief_draft_briefs
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getChiefDraftBriefs: data => {
      dispatch(actGetChiefDraftBriefsRequest(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefDraftBriefsTable);
ChiefDraftBriefsTable.propTypes = {
  chief_draft_briefs: PropTypes.object,
  getChiefDraftBriefs: PropTypes.func
};
ChiefDraftBriefsTable.defaultProps = {};
