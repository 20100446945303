import React from "react";
import { ReactComponent as ArrowIncreaseIcon } from "./Arrow.svg";
import { ReactComponent as ArrowDecreaseIcon } from "./ArrowDecrease.svg";

const Arrow = ({ data = 0, dataCompare = 0}) => {
  let temp = dataCompare - data;
  const show = () => {
    temp = Math.abs(temp);
    if (dataCompare === 0) {
      return 0;
    }
    if (temp > 999) {
      const number = temp / 1000;
      return Math.round(number * 10) / 10 + "K";
    } else {
      return temp;
    }
  };
  return temp >= 0 ? (
    <>
      <ArrowDecreaseIcon />{" "}
      <span style={{ marginLeft: "10px", color: "#5E72E4" }}> {show()} </span>
    </>
  ) : (
    <>
      <ArrowIncreaseIcon />{" "}
      <span style={{ marginLeft: "10px" , color: "#5E72E4"}}> {show()} </span>
    </>
  );
};

export default Arrow;
