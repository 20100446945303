import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardFooter, Table } from "reactstrap";
import CustomPagination from "components/Pagination/Pagination";
import { connect } from "react-redux";
import moment from "moment";
import {
  actGetNewNotificationListRequest,
  actStatusSeeViewRequest
} from "../../actions/notification.action";
import { paramFromData } from "../../utils/paramFromData";
import CardHeaderNotification from "../../components/Headers/CardHeaderNotification";
import CardHeaderNotificationWriter from "../../components/Headers/CardHeaderNotificationWriter";
import CardHeaderNotificationEditor from "../../components/Headers/CardHeaderNotificationEditor";
import CardHeaderNotificationChief from "../../components/Headers/CardHeaderNotificationChief";
import HOST_URL from "../../variables/hostUrl";
import AVA from "assets/img/theme/default-avatar.png";
import getUrlParam from "../../utils/getUrlParam";
import ReactHtmlParser from "react-html-parser";
import Avatar from "../../components/Avatar/Avatar";
class Notification extends Component {
  state = {
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10
    },
    isLoading: true
  };

  componentDidMount() {
    const params = this.state.params;
    this.props.getAllNotification(params);
    this._setLoader();
  }

  _renderMessage = (model, action) => {
    const t = this.context.t;
    return (
      <p className="text-sm mb-0">
        {t("You have successfully")} {t(action)} {t(model)} {t("and have")}
        &nbsp;
        <span className="text-danger font-weight-bold">
          {this.props.all_notification.length}
        </span>
        &nbsp;{t("errors in the process")}!
      </p>
    );
  };

  _setLoader = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  };

  _getListByPage = page => {
    const params = this.state.params;
    params.page = page;
    this.props.getAllNotification(params);
    this._handlePathnamePush(params);
    this._setLoader();
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  handleOnNotiClick = (id, typestatus, idNoti) => {
    const key = localStorage.getItem("key");
    const data = {
      status: "ACTIVE"
    };
    if (key === "writer") {
      // this.props.setStateFlagNew(false);
      switch (typestatus) {
        case "CONTENT_WRITING":
          return (
            this.props.history.push(`/admin/writer/writer-writing/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );
        case "CONTENT_SUBMITTED":
          return (
            this.props.history.push(`/admin/writer/writer-writing/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "REJECTED":
          return (
            this.props.history.push(`/admin/writer/writer-writing/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "UNPUBLISHED":
          return (
            this.props.history.push(`/admin/writer/writer-writing/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "OUTLINE_WRITING":
          return (
            this.props.history.push(`/admin/writer/outline-content/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "OUTLINE_SUBMITTED":
          return (
            this.props.history.push(`/admin/writer/outline-content/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );
        default:
      }
    }
    if (key === "editor") {
      switch (typestatus) {
        case "CONTENT_WRITING":
          return (
            this.props.history.push(`/admin/editor/content-request/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "CONTENT_SUBMITTED":
          return (
            this.props.history.push(`/admin/editor/content-request/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "REJECTED":
          return (
            this.props.history.push(`/admin/editor/content-request/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "UNPUBLISHED":
          return (
            this.props.history.push(`/admin/editor/content-request/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "OUTLINE_WRITING":
          return (
            this.props.history.push(`/admin/editor/outline-content/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "OUTLINE_SUBMITTED":
          return (
            this.props.history.push(`/admin/editor/outline-content/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );
        case "PITCH_SUBMITTED_APPROVAL_REQUEST_NOTI":
          return (
            this.props.history.push(`/admin/editor/pitch-detail/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        default:
      }
    }
    if (key === "chiefeditor") {
      switch (typestatus) {
        case "CONTENT_WRITING":
          return (
            this.props.history.push(`/admin/chief/un-publish-review/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "CONTENT_SUBMITTED":
          return (
            this.props.history.push(`/admin/chief/un-published-review/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "REJECTED":
          return (
            this.props.history.push(`/admin/chief/content-request/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "UNPUBLISHED":
          return (
            this.props.history.push(`/admin/chief/content-request/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "OUTLINE_WRITING":
          return (
            this.props.history.push(`/admin/chief/outline-content/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        case "OUTLINE_SUBMITTED":
          return (
            this.props.history.push(`/admin/chief/outline-content/${id}`),
            this.props.putStatusSeeView(idNoti, data)
          );

        default:
      }
    }
  };

  capitalize = input => {
    return input.replace(/^./, function(match) {
      return match.toUpperCase();
    });
  };

  _handleSubmitActiveGeneral = (e, id, status) => {
    e.preventDefault();
    const key = localStorage.getItem("key");
    this.handleChangeNotiStatus(id);
    const data = {
      status: "ACTIVE"
    };
    this.props.putStatusSeeView(id, data);
    this.toggle();
    setTimeout(() => {
      if (key === "writer") {
        switch (status) {
          case "GUIDELINE_PATH":
            return (
              (window.location.href = `/admin/writer/editorial-guideline`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          case "FAQ_PATH":
            return (
              (window.location.href = `/admin/writer/faq`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          default:
        }
      }
      if (key === "editor") {
        switch (status) {
          case "GUIDELINE_PATH":
            return (
              (window.location.href = `/admin/editor/editorial-guideline`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          case "FAQ_PATH":
            return (
              (window.location.href = `/admin/editor/faq`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          default:
        }
      }
      if (key === "chiefeditor") {
        switch (status) {
          case "GUIDELINE_PATH":
            return (
              // (window.location.href = `/admin/chief/editorial-guideline`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          case "FAQ_PATH":
            return (
              // (window.location.href = `/admin/chief/faq`),
              // this.props.history.push(`/admin/chief/un-publish-review/${id}`),
              // eslint-disable-next-line react/prop-types
              this.props.putStatusSeeView(id, data)
            );
          default:
        }
      }
    }, 100);
  };

  _renderNoti = () => {
    return (
      this.props.all_notification.data &&
      this.props.all_notification.data.docs.map(prop => {
        if (prop.type === "NEW_OUTLINE_APPROVAL_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                sent a request to <span className="text-success">approve</span>{" "}
                the outline for{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "FEEDBACK_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                wrote <span className="text-success">feedback</span> article
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "OUTLINE_APPROVED_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-success">approved</span> outline your for{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "OUTLINE_REJECTED_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-danger">rejected</span> outline your for{" "}
                <span className="text-danger">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "CONTENT_REWRITE_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                has requested to
                <span className="text-primary"> rewrite </span> the content for{" "}
                <span className="text-primary">
                  {prop.data && prop.data.articleTitle}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "CONTENT_APPROVED_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-success">approved</span> your content for{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "CONTENT_REJECTED_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-danger">rejected </span>
                article for{" "}
                <span className="text-danger">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "ARTICLE_PUBLISHED_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-success">completed </span>
                article for{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "CONTENT_ASSIGN_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-success">assigned</span> article the
                outline for{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "NEW_CONTENT_PUBLIC_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-success">completed </span>
                article for{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "OUTLINE_REWRITE_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <img
                      className="mr-2"
                      alt="..."
                      src={
                        prop.data.author && prop.data.author.avatar
                          ? HOST_URL + prop.data.author.avatar
                          : AVA
                      }
                    />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                has requested to
                <span className="text-warning"> rewrite </span> the outline for{" "}
                <span className="text-warning">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "NEW_CONTENT_APPROVAL_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                sent a <span className="text-success">request</span> to approve
                the content for{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "WRITER_UPDATE_PROFILE") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data && prop.data.staff_name}
                </span>{" "}
                has just <span className="text-success">updated</span> his
                personal information
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "EDITOR_UPDATE_PROFILE") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data && prop.data.staff_name}
                </span>{" "}
                has just <span className="text-success">updated</span> his
                personal information
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "CHIEFEDITOR_UPDATE_PROFILE") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.articleID,
                  prop.data.articleStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data && prop.data.staff_name}
                </span>{" "}
                has just <span className="text-success">updated</span> his
                personal information
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "PITCH_REJECT_NOTI") {
          return (
            <tr
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-danger">rejected </span> your pitch for{" "}
                <span className="text-danger">
                  {prop.data && prop.data.pitchTitle}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "PITCH_REWRITE_NOTI") {
          return (
            <tr
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                has requested to <span className="text-primary">rewrite </span>{" "}
                pitch for{" "}
                <span className="text-primary">
                  {prop.data && prop.data.pitchTitle}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "PITCH_APPROVE_NOTI") {
          return (
            <tr
              onClick={e =>
                this.handleOnNotiClick(
                  e,
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-success">approved </span> your pitch for{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "UPDATED_FAQ_NOTI") {
          return (
            <tr
              onClick={e =>
                this._handleSubmitActiveGeneral(e, prop.id, prop.data.status)
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <img className="mr-2" alt="..." src={AVA} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                Chief of Editor{" "}
                <span className="text-info">{prop && prop.data.coeName}</span>{" "}
                just updated the FAQ
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "UPDATED_GUIDELINE_NOTI") {
          return (
            <tr>
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <img className="mr-2" alt="..." src={AVA} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                Chief of Editor{" "}
                <span className="text-info">{prop && prop.data.coeName}</span>{" "}
                just updated the Editorial Guideline
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "UPDATED_GENERAL_INFO_NOTI") {
          return (
            <tr>
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <img className="mr-2" alt="..." src={AVA} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="">{prop && prop.description}</span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "COMMENT_PITCH_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <img
                      className="mr-2"
                      alt="..."
                      src={
                        prop.data.author && prop.data.author.avatar
                          ? HOST_URL + prop.data.author.avatar
                          : AVA
                      }
                    />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data && prop.data.author.name}
                </span>{" "}
                wrote <span className="text-success">comment </span>pitch{" "}
                <span className="text-success">
                  {prop.data && prop.data.pitchTitle}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "PITCH_SUBMITTED_APPROVAL_REQUEST_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(
                  prop.data.pitchID,
                  prop.data.pitchStatus,
                  prop.id
                )
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data ? prop.data.name : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? prop.data.author.job_position
                  : "User"}
              </td>
              <td>
                <span className="text-info">{prop.data && prop.data.name}</span>{" "}
                <span className="text-success">submitted </span>pitch for{" "}
                <span className="text-success">
                  {prop.data && prop.data.title}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        if (prop.type === "REJECT_ARTICLE_ASSIGN_NOTI") {
          return (
            <tr
              onClick={() =>
                this.handleOnNotiClick(prop.data.articleID, prop.type, prop.id)
              }
            >
              <td>
                <span className="d-flex align-items-center">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <Avatar src={prop.data.author.avatar} />
                  </a>
                  <span className="font-weight-bold">
                    {prop.data && prop.data.author
                      ? prop.data.author.name
                      : "User"}
                  </span>
                </span>
              </td>
              <td>
                {prop.data && prop.data.author
                  ? this.capitalize(prop.data.author.job_position)
                  : "User"}
              </td>
              <td>
                <span className="text-info">
                  {prop.data && prop.data.author && prop.data.author.name}
                </span>{" "}
                <span className="text-danger">rejected </span>assigned brief{" "}
                <span className="text-success">
                  {prop.data && ReactHtmlParser(prop.data.articleTitle)}
                </span>
              </td>
              <td>{moment(prop.createdAt).format("HH:mm DD/MM/YYYY")}</td>
            </tr>
          );
        }
        return null;
      })
    );
  };

  render() {
    const key = localStorage.getItem("key");
    const t = this.context.t;
    const page =
      this.props.all_notification.data && this.props.all_notification.data.page;
    const totalPages =
      this.props.all_notification.data &&
      this.props.all_notification.data.totalPages;
    const totalDocs =
      this.props.all_notification.data &&
      this.props.all_notification.data.totalDocs;
    return (
      <>
        {key === "writer" ? (
          <CardHeaderNotificationWriter
            name="Notification"
            content="notification"
            parentName="Article"
          />
        ) : key === "editor" ? (
          <CardHeaderNotificationEditor
            name="Notification"
            content="notification"
            parentName="Article"
          />
        ) : key === "chiefeditor" ? (
          <CardHeaderNotificationChief
            name="Notification"
            content="notification"
            parentName="Article"
          />
        ) : (
          <CardHeaderNotification
            name="Notification"
            content="notification"
            parentName="Article"
          />
        )}

        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card>
                <div className="title">
                  <div className="border-0 card-header">
                    <h3 className="mb-0">All Notification</h3>
                  </div>
                  <Col className="toggle text-right">
                    {/* <label className="custom-toggle custom-toggle-warning mr-1">
                      <input defaultChecked type="checkbox" />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="ENG"
                        data-label-on="VN"
                      />
                    </label> */}
                  </Col>
                </div>

                <div className="table-responsive">
                  <Table
                    className="align-items-center table-flush table-noti"
                    responsive
                    style={{ border: "none" }}
                  >
                    <thead className="thead-light">
                      <tr className="">
                        {/* <th
                          className="sort"
                          data-sort="mail"
                          scope="col"
                          // onClick={this._sortByMail}
                        >
                          TITLE
                        </th> */}
                        <th>User</th>
                        <th
                          className="sort"
                          scope="col"
                          // onClick={this._sortByJob}
                        >
                          JOB
                        </th>

                        <th
                          className="sort"
                          scope="col"
                          // onClick={this._sortByMail}
                        >
                          MESSAGE
                        </th>
                        <th
                          className="sort"
                          scope="col"
                          // onClick={this._sortByStatus}
                        >
                          CREATED AT
                        </th>
                      </tr>
                    </thead>

                    <tbody className="list">{this._renderNoti()}</tbody>
                  </Table>
                </div>
                <CardFooter className="py-4">
                  <div className="float-right">
                    <CustomPagination
                      page={page}
                      totalPages={totalPages}
                      getListByPage={this._getListByPage}
                    />
                    <div className="font-italic mt-2">
                      ({totalDocs} {t("results in")} {totalPages} {t("pages")})
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Notification.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    all_notification: state.notificationReducer.all_notification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllNotification: data => {
      dispatch(actGetNewNotificationListRequest(data));
    },
    putStatusSeeView: (id, data) => {
      dispatch(actStatusSeeViewRequest(id, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
Notification.propTypes = {
  getAllNotification: PropTypes.func,
  all_notification: PropTypes.array,
  putStatusSeeView: PropTypes.func
};
Notification.defaultProps = {};
