import React, { Component } from "react";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { isWebUri } from "valid-url";
import { connect } from "react-redux";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  Input,
  Modal
} from "reactstrap";

import { Tooltip } from "antd";
import PropTypes from "prop-types";
import {
  actSubmitUnPublishedRequest,
  actSubmitPublishRequest,
  actAutoSaveWritingContentRequest,
  actSaveWritingContentRequest
} from "../../../actions/chiefArticle.action";
import { actAddNewPublishingRequest } from "../../../actions/openPublishing.action";
import CustomEditor from "../../TinyMCE";
import NewArticleSetting from "../../PostSetting/NewArticleSetting";
import { ARTICLE_STATUS } from "../../../utils/constants";
// import "./styles.scss";
class AddNewPublishing extends Component {
  state = {
    publishConfirmModal: false,
    collapse: false,
    uid: null,
    openedCollapses: ["collapseOne"],
    title: "",
    titleWarn: false,
    data: {
      content: ""
    },
    status: "",
    setting: {
      slug: "",
      author: [],
      topic: [],
      tags: [],
      otherLanguageLink: "",
      publishDate: "",
      excerpt: "",
      language: "",
      summaries: [],
      seoDescription: "",
      typeOfContent: {
        value: "original",
        label: "Original"
      }
    },
    autoSave: false
  };

  componentDidMount() {
    this._autoSave();
    this._getNewArticleDataFromLocal();
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
  }

  async _getNewArticleDataFromLocal() {
    const openPublishingArticle = localStorage.getItem("openPublishingArticle");
    if (openPublishingArticle) {
      let parseData = await JSON.parse(openPublishingArticle);
      this.setState({
        title: parseData.title,
        data: {
          content: parseData.content
        }
      });
    }
  }

  _toggleModal = modalName => {
    // if (!this.state.seo.description) {
    //   this.setState({
    //     errors: {
    //       seo: {
    //         description: "This field is required",
    //       },
    //     },
    //   });
    //   return;
    // }
    this.setState({
      [modalName]: !this.state[modalName]
    });
  };
  _wrapInTag = content => {
    const result = `<p>${content}</p>`;
    return result;
  };
  // Create new article
  _handleAddNewArticle = () => {
    const {
      title,
      data: { content },
      setting
    } = this.state;
    let payload = {
      title: title,
      content: content,
      status: ARTICLE_STATUS.COMPLETED,
      slug: setting.slug,
      tags: setting.tags,
      topic: setting.topic,
      excerpt: this._wrapInTag(setting.excerpt),
      language: setting.language.value,
      otherLanguageLink: setting.otherLanguageLink,
      publishDate: setting.publishDate || moment().format(),
      seo: {
        description: setting.seoDescription
      },
      typeOfContent: setting.typeOfContent.value,
      creatorId:
        setting.writer && setting.writer.length > 0
          ? setting.writer[0].key
          : "",
      summaries: setting.summaries
    };
    payload = omitBy(payload, isNil);
    payload = omitBy(payload, isEmpty);
    this.props.addNewPublishing(payload);
    localStorage.removeItem("newArticleData");
  };
  // Create new article & publish right away
  _handleSubmitPublished = () => {
    const {
      title,
      data: { content },
      setting
    } = this.state;
    let payload = {
      title: title,
      content: content,
      status: ARTICLE_STATUS.PUBLISHED,
      slug: setting.slug,
      tags: setting.tags,
      topic: setting.topic,
      excerpt: this._wrapInTag(setting.excerpt),
      language: setting.language.value,
      otherLanguageLink: setting.otherLanguageLink,
      publishDate: setting.publishDate || moment().format(),
      seo: {
        description: setting.seoDescription
      },
      typeOfContent: setting.typeOfContent.value,
      creatorId:
        setting.writer && setting.writer.length > 0
          ? setting.writer[0].key
          : "",
      summaries: setting.summaries
    };
    payload = omitBy(payload, isNil);
    payload = omitBy(payload, isEmpty);
    this.props.addNewPublishing(payload);
    this._toggleModal("publishConfirmModal");
    localStorage.removeItem("newArticleData");
  };

  _handleArticleSettingChange = (setting, invalidAudio) => {
    this.setState({
      setting: setting,
      autoSave: true,
      invalidAudio: invalidAudio
    });
  };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        content: value
      },
      autoSave: true
    });
  };

  _handleOnClickCollapse = () => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  };

  _autoSave = () => {
    this.autoSave = setInterval(() => {
      if (this.state.autoSave) {
        const {
          data: { content },
          title
        } = this.state;
        const data = {
          content: content,
          title: title
        };
        localStorage.setItem("newArticleData", JSON.stringify(data));
        this.setState({ autoSave: false });
      }
    }, 3000);
  };

  _handleChange = event => {
    this.setState({
      title: event.target.value,
      titleWarn: event.target.value.length > 60
    });
  };

  render() {
    const {
      seo,
      setting,
      title,
      invalidAudio,
      titleWarn,
      data: { content }
    } = this.state;
    return (
      <>
        <Row>
          <Col md="8">
            <Card style={{ marginTop: "19px" }}>
              <CardHeader
                className="border-0 d-flex justify-content-between align-items-center"
                role="tab"
              >
                <div className="d-flex w-100 flex-column">
                  <Input
                    placeholder="Title"
                    className={
                      `chief-add-title-input ` + (titleWarn ? "warn" : "")
                    }
                    value={title}
                    onChange={this._handleChange}
                  />
                  <div
                    className={`d-flex ${
                      titleWarn
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {titleWarn && (
                      <small className="text-red">
                        Title ideally shouldn't be longer than 60 characters,
                        but you can still sumbit
                      </small>
                    )}
                    <small className="text-right">
                      {title ? title.length : "0"}/60
                    </small>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <CustomEditor
                  menu={{
                    edit: {
                      title: "Edit",
                      items:
                        "undo redo | cut copy paste | selectall | searchreplace"
                    },
                    insert: {
                      title: "Insert",
                      items: "link | hr"
                    },
                    format: {
                      title: "Format",
                      items:
                        "bold italic underline | formats blockformats align | removeformat"
                    }
                  }}
                  menubar="insert edit format view"
                  toolbar={`undo redo | formatselect | bold italic blockquote underline | hr | \
                  alignleft aligncenter alignright | \
                  bullist numlist outdent indent`}
                  enableComment={false}
                  content={content}
                  onChangeContent={this._handleOnContentChange}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <div className="mt--5 d-flex justify-content-end">
              <Button
                onClick={this._handleAddNewArticle}
                color="primary"
                size="lg"
                type="button"
              >
                Create
              </Button>
              <Button
                type="button"
                size="lg"
                color="success"
                className="btn-success btn-lg"
                onClick={() => this._toggleModal("publishConfirmModal")}
              >
                Create and publish
              </Button>
            </div>
            <NewArticleSetting
              isOpenPublishing={true}
              invalidAudio={invalidAudio}
              onChangeSetting={this._handleArticleSettingChange}
              setting={setting}
            />
          </Col>
        </Row>
        <div
          className="scrollTop"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "35px",
            zIndex: "1"
          }}
        >
          <Tooltip placement="topLeft" title={"Scroll to top"}>
            <Button
              className="btn-icon-only rounded-circle"
              type="button"
              color="primary"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-caret-up"></i>
              </span>
            </Button>
          </Tooltip>
        </div>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.publishConfirmModal}
          toggle={() => this._toggleModal("publishConfirmModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Are you sure to create and publish this article?
            </h5>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => this._toggleModal("publishConfirmModal")}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={this._handleSubmitPublished}
            >
              Yes
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    content_detail: state.chiefArticleReducer.unpublished_detail_by_id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    submitPublished: (id, data) => {
      dispatch(actSubmitPublishRequest(id, data));
    },
    submitUnPublished: (id, data) => {
      dispatch(actSubmitUnPublishedRequest(id, data));
    },
    saveDraft: data => {
      dispatch(actSaveWritingContentRequest(data));
    },
    autoSave: data => {
      dispatch(actAutoSaveWritingContentRequest(data));
    },
    addNewPublishing: data => {
      dispatch(actAddNewPublishingRequest(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPublishing);
AddNewPublishing.propTypes = {
  content_detail: PropTypes.object,
  getUnPublisedByID: PropTypes.func,
  submitUnPublished: PropTypes.func,
  submitPublished: PropTypes.func,
  saveDraft: PropTypes.func,
  autoSave: PropTypes.func
};
