import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Button from "components/CustomButton/CustomButton";
import CreateNewPasswordInfoModal from "../../components/Modals/CreateNewPasswordInfoModal";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { actLoginRequest } from "../../actions/authentication.action";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
class Login extends React.Component {
  state = {
    isLoading: false,
    createNewPasswordInfoModal: false,
  };

  componentDidMount() {
    const isCreateNewPassword =
      this.props.location.search === "?forgotPassword=true";
    this.checkRememberMe();
    if (!isCreateNewPassword) {
      return;
    }
    this.setState({
      createNewPasswordInfoModal: true,
    });
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOnChangeRemember = () => {
    this.setState((prevState) => ({
      isRememberMe: !prevState.isRememberMe,
    }));
  };

  handleOnSubmit = () => {
    const email = this.state.email;
    const password = this.state.password;
    const isRememberMe = this.state.isRememberMe;

    this.toogleLoading();
    this.setTimeoutLoading();
    if (!email || !password) {
      this.setState({
        message: "* Email or password cannot be blank!",
      });
      this.toogleLoading();
    } else {
      const data = {
        email,
        password,
        isRememberMe,
      };
      this.props.login(data);
    }
  };

  checkRememberMe = () => {
    let username = localStorage.getItem("email");
    let localpass = localStorage.getItem("password");
    let isRememberMe = localStorage.getItem("is_remember");
    let password = atob(localpass);
    if (username && password && isRememberMe) {
      this.setState({
        username,
        password,
        isRememberMe,
      });
    }
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleOnSubmit();
    }
  };

  toogleLoading = () => {
    this.setState((prevState) => ({
      isLoading: !prevState.isLoading,
    }));
  };

  setTimeoutLoading = () => {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1500);
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  render() {
    return (
      <>
        <AuthHeader title="" />
        <Container className="login">
          <Row className="justify-content-end">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-muted text-center">
                    <small>SIGN IN</small>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="text"
                          name="email"
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                          onChange={this.handleOnChange}
                          value={this.state.email}
                          onKeyDown={this.onKeyDown}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="password"
                          placeholder="Password"
                          type="password"
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                          onChange={this.handleOnChange}
                          value={this.state.password}
                          onKeyDown={this.onKeyDown}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-danger mt-3 mb-3">
                      {this.state.message}
                    </div>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                        onClick={this.handleOnChangeRemember}
                        checked={this.state.isRememberMe}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">Remember me</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        onClick={this.handleOnSubmit}
                        // color="btn btn-primary"
                        type="button"
                        isLoading={this.state.isLoading}
                        style={{
                          backgroundColor: "#5e72e4",
                          borderColor: "#5e72e4",
                          color: "#ffffff",
                        }}
                      >
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <NavLink to="/admin/forgotPassword">
                    <span className="text-light">
                      <small>Forgot password?</small>
                    </span>
                  </NavLink>
                </Col>
              </Row>
            </Col>
          </Row>
          <CreateNewPasswordInfoModal
            createNewPasswordInfoModal={this.state.createNewPasswordInfoModal}
            toggleModal={this.toggleModal}
            history={this.props.history}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => {
      dispatch(actLoginRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

Login.propTypes = {
  history: PropTypes.object,
  login: PropTypes.func,
};
