import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  available_pitch: {
    data: []
  },
  pitch_detail: {
    data: {}
  },
  writer_my_pitch: {
    data: []
  },
  writer_my_pitch_detail: {
    data: {}
  },
  chief_available_pitch: {
    data: []
  }
};

const pitchReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_AVAILABLE_PITCHES:
      state = {
        ...state,
        available_pitch: action.data
      };
      return { ...state };
    case Types.GET_PITCH_DETAIL:
      state = {
        ...state,
        pitch_detail: action.data
      };
      return { ...state };
    case Types.GET_WRITER_MY_PITCH:
      state = {
        ...state,
        writer_my_pitch: action.data
      };
      return { ...state };
    case Types.GET_WRITER_MY_PITCH_DETAIL:
      state = {
        ...state,
        writer_my_pitch_detail: action.data
      };
      return { ...state };
    case Types.RESET_COMMENT_PITCH:
      state = {
        ...state,
        writer_my_pitch_detail: {
          ...action.data,
          comments: []
        }
      };
      return { ...state };
    case Types.GET_CHIEF_AVAILABLE_PITCHES:
      state = {
        ...state,
        chief_available_pitch: action.data
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default pitchReducer;
