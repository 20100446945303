export const RESET_FETCH_RESOURCE = "RESET_FETCH_RESOURCE";
export const FETCH_RESOURCES_SUCCESS = "FETCH_RESOURCES_SUCCESS";
export const FETCH_RESOURCES_FAIL = "FETCH_RESOURCES_FAIL";
export const FETCH_RESOURCES_LOADING = "FETCH_RESOURCES_LOADING";
export const NOTIFY = "NOTIFY";
export const ADD_IMPORTED_MODULE = "ADD_IMPORTED_MODULE";
// =============== ADMIN =====================
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_LIST_KEY = "GET_LIST_KEY";
export const GET_LIST_TOPIC = "GET_LIST_TOPIC";
export const GET_GROUP_TOPIC = "GET_GROUP_TOPIC";
export const GET_LIST_EXPERTISE = "GET_LIST_EXPERTISE";
export const GET_EMPLOYEE_INFO = "GET_EMPLOYEE_INFO";
export const GET_EMPLOYEE_PROFILE = "GET_EMPLOYEE_PROFILE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const GET_EMPLOYEE_STATISTIC = "GET_EMPLOYEE_STATISTIC";
export const GET_EMPLOYEE_EXPIRED_STATISTIC = "GET_EMPLOYEE_EXPIRED_STATISTIC";
export const DELETE_STAFF = "DELETE_STAFF";
export const GET_LIST_STAFF = "GET_LIST_STAFF";

export const GET_CONTENT_REQUEST_LIST = "GET_CONTENT_REQUEST_LIST";
export const GET_MY_CONTENT_LIST = "GET_MY_CONTENT_LIST";
export const GET_CONTENT_REQUEST_DETAIL = "GET_CONTENT_REQUEST_DETAIL";
export const GET_CONTENT_REQUEST_BYID = "GET_CONTENT_REQUEST_BYID";
export const GET_SKIP_OUTLINE = "GET_SKIP_OUTLINE";
export const GET_WRITING_CONTENT_BY_ID = "GET_WRITING_CONTENT_BY_ID";
export const GET_OUTLINE_IN_PROGRESS = "GET_OUTLINE_IN_PROGRESS";
export const GET_ARTICLE_IN_PROGRESS = "GET_ARTICLE_IN_PROGRESS";
export const GET_APPROVED_ARTICLE = "GET_APPROVED_ARTICLE";
export const GET_DASHBOARD_DEALINE = "GET_DASHBOARD_DEALINE";
export const GET_AVAILABLE_REQUEST_LIST = "GET_AVAILABLE_REQUEST_LIST";
export const GET_AVAILABLE_REQUEST_DETAIL = "GET_AVAILABLE_REQUEST_DETAIL";
export const GET_AVAILABLE_REQUEST_BYID = "GET_AVAILABLE_REQUEST_BYID";
export const GET_EDITOR_REJECT_ASSIGNED = "GET_EDITOR_REJECT_ASSIGNED";
export const GET_POST_INFO = "GET_POST_INFO";
export const GET_POST_DETAIL = "GET_POST_DETAIL";
export const GET_TOPICS_NAME = "GET_TOPICS_NAME";
export const GET_TAGS_BY_TOPIC = "GET_TAGS_BY_TOPIC";
export const GET_TOPICS_LIST = "GET_TOPICS_LIST";

export const GET_OUTLINE_APPROVAL_LIST = "GET_OUTLINE_APPROVAL_LIST";
export const GET_OUTLINE_APPROVAL_DETAIL = "GET_OUTLINE_APPROVAL_DETAIL";
export const GET_CONTENT_APPROVAL_LIST = "GET_CONTENT_APPROVAL_LIST";
export const GET_CONTENT_APPROVAL_DETAIL = "GET_CONTENT_APPROVAL_DETAIL";

export const GET_OUTLINE_DETAIL_BY_ID = "GET_OUTLINE_DETAIL_BY_ID";
export const GET_CHIEF_PUBLISH_ARTICLE_LIST_START =
  "GET_CHIEF_PUBLISH_ARTICLE_LIST_START";
export const GET_CHIEF_PUBLISH_ARTICLE_LIST = "GET_CHIEF_PUBLISH_ARTICLE_LIST";
export const GET_CHIEF_PUBLISHED_ARTICLE_LIST =
  "GET_CHIEF_PUBLISHED_ARTICLE_LIST";
export const GET_CHIEF_ARTICLE_TOPIC = "GET_CHIEF_ARTICLE_TOPIC";
export const GET_IN_PROGRESS_ARTICLE = "GET_IN_PROGRESS_ARTICLE";
export const GET_MY_PERMISSION = "GET_MY_PERMISSION";
export const CHIEF_ADD_NEW_ARTICLE = "CHIEF_ADD_NEW_ARTICLE";
export const GET_CHIEF_TOPIC_BY_ID = "GET_CHIEF_TOPIC_BY_ID";
export const GET_CHIEF_PUBLISH_BYID = "GET_CHIEF_PUBLISH_BYID";

export const GET_CHIEF_UNPUBLISH_BYID_START = "GET_CHIEF_UNPUBLISH_BYID_START";
export const GET_CHIEF_UNPUBLISH_BYID = "GET_CHIEF_UNPUBLISH_BYID";
export const GET_CHIEF_UNPUBLISH_BYID_FAIL = "GET_CHIEF_UNPUBLISH_BYID_FAIL";

export const GET_CHIEF_OUTLINE_IN_PROGRESS = "GET_CHIEF_OUTLINE_IN_PROGRESS";
export const GET_CHIEF_ARTICLE_IN_PROGRESS = "GET_CHIEF_ARTICLE_IN_PROGRESS";
export const GET_CHIEF_EDITING = "GET_CHIEF_EDITING";
export const GET_CHIEF_WRITER_PROFILE = "GET_CHIEF_WRITER_PROFILE";
export const GET_CHIEF_WRITER_PROFILE_TABLE_DETAIL =
  "GET_CHIEF_WRITER_PROFILE_TABLE_DETAIL";
export const GET_CHIEF_EDITOR_PROFILE = "GET_CHIEF_EDITOR_PROFILE";
export const GET_CHIEF_EDITOR_PROFILE_TABLE_DETAIL =
  "GET_CHIEF_EDITOR_PROFILE_TABLE_DETAIL";
export const GET_CHIEF_COUNT_MENU_DASHBOARD = "GET_CHIEF_COUNT_MENU_DASHBOARD";
export const GET_CHIEF_AVAILABLE_BRIEFS = "GET_CHIEF_AVAILABLE_BRIEFS";
export const GET_CHIEF_ASSIGNED_BRIEFS = "GET_CHIEF_ASSIGNED_BRIEFS";
export const GET_CHIEF_LIST_ARTICLE = "GET_CHIEF_LIST_ARTICLE";
export const GET_CHIEF_CONTENT_REQUEST_BYID = "GET_CHIEF_CONTENT_REQUEST_BYID";
export const GET_TABLE_DEALINE_DASHBOARD = "GET_TABLE_DEALINE_DASHBOARD";
export const GET_FEEDBACK_LIST_START = "GET_FEEDBACK_LIST_START";
export const GET_FEEDBACK_LIST = "GET_FEEDBACK_LIST";
export const GET_FEEDBACK_LIST_FAIL = "GET_FEEDBACK_LIST_FAIL";
export const RESET_FEEDBACK_LIST = "RESET_FEEDBACK_LIST";
export const GET_COMMENT_PITCH = "GET_COMMENT_PITCH";
export const RESET_COMMENT_PITCH = "RESET_COMMENT_PITCH";
export const GET_MY_PROFILE_LIST = "GET_MY_PROFILE_LIST";
export const GET_MY_INFORMATION = "GET_MY_INFORMATION";
export const UPDATE_MY_PROFILE = "UPDATE_MY_PROFILE";
export const GET_HEADER_DASHBOARD = "GET_HEADER_DASHBOARD";
export const GET_DASHDOARDS_WRITER = "GET_DASHDOARDS_WRITER";
export const GET_HEADER_DASHBOARDS_WRITER = "GET_HEADER_DASHBOARDS_WRITER";

export const GET_EDITOR_TODAY_DEADLINE = "GET_EDITOR_TODAY_DEADLINE";
export const GET_EDITOR_PUBLISHED_ARTICLES = "GET_EDITOR_PUBLISHED_ARTICLES";
export const GET_EDITOR_EDITING_ARTICLES = "GET_EDITOR_EDITING_ARTICLES";
export const GET_EDITOR_WRITER_PROFILE = "GET_EDITOR_WRITER_PROFILE";
export const GET_EDITOR_WRITER_INFO_BY_ID = "GET_EDITOR_WRITER_INFO_BY_ID";
export const GET_DRAFT_BRIEFS = "GET_DRAFT_BRIEFS";
export const GET_EDITOR_HEADER_DASHBOARD = "GET_EDITOR_HEADER_DASHBOARD";
export const GET_FINISHED_ARTICLE_DASHBOARD = "GET_FINISHED_ARTICLE_DASHBOARD";
export const GET_NEW_NOTIFICATION = "GET_NEW_NOTIFICATION";
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";

export const GET_EDITOR_LIST = "GET_EDITOR_LIST";
export const GET_WRITER_LIST = "GET_WRITER_LIST";
export const GET_ASSIGNED_POST = "GET_ASSIGNED_POST";
export const GET_MY_ASSIGNED_POST = "GET_MY_ASSIGNED_POST";
export const GET_WRITER_GUIDELINE = "GET_WRITER_GUIDELINE";
export const GET_WRITER_FAQ = "GET_WRITER_FAQ";

export const GET_COUNT_INFO = "GET_COUNT_INFO";
export const GET_COUNT_INFO_CHIEF = "GET_COUNT_INFO_CHIEF";
export const GET_COUNT_INFO_EDITOR = "GET_COUNT_INFO_EDITOR";
export const GET_EDITOR_OUTLINE_IN_PROGRESS = "GET_EDITOR_OUTLINE_IN_PROGRESS";
export const GET_EDITOR_ARTICLES_IN_PROGRESS =
  "GET_EDITOR_ARTICLES_IN_PROGRESS";
export const GET_WRITER_COUNT_MENU_DASHBOARD =
  "GET_WRITER_COUNT_MENU_DASHBOARD";

export const GET_CHIEF_TODAY_DEADLINE = "GET_CHIEF_TODAY_DEADLINE";
export const GET_CHIEF_TODAY_PUBLISHED = "GET_CHIEF_TODAY_PUBLISHED";
export const GET_CHIEF_DRAFT_BRIEFS = "GET_CHIEF_DRAFT_BRIEFS";

export const GET_AVAILABLE_PITCHES = "GET_AVAILABLE_PITCHES";
export const GET_PITCH_DETAIL = "GET_PITCH_DETAIL";
export const GET_CHIEF_PITCH_DETAIL = "GET_CHIEF_PITCH_DETAIL";
export const GET_CHIEF_AVAILABLE_PITCHES = "GET_CHIEF_AVAILABLE_PITCHES";

export const GET_WRITER_MY_PITCH = "GET_WRITER_MY_PITCH";
export const GET_WRITER_MY_PITCH_DETAIL = "GET_WRITER_MY_PITCH_DETAIL";
export const GET_CHIEF_GUIDELINE = "GET_CHIEF_GUIDELINE";
export const GET_CHIEF_FAQ = "GET_CHIEF_FAQ";

export const GET_EDITOR_ALL_BRIEFS = "GET_EDITOR_ALL_BRIEFS";
export const GET_CHIEF_REJECT_ASSIGNED = "GET_CHIEF_REJECT_ASSIGNED";

export const GET_CONTENT_VERSION = "GET_CONTENT_VERSION";
export const GET_OUTLINE_VERSION = "GET_OUTLINE_VERSION";

export const GET_MENU_LIST = "GET_MENU_LIST";
export const GET_TOPIC_LIST = "GET_TOPIC_LIST";
export const GET_ALL_TOPIC_LIST = "GET_ALL_TOPIC_LIST";

export const GET_LIST_FEATURE_ARTICLE = "GET_LIST_FEATURE_ARTICLE";
export const GET_SEARCH_FEATURE_ARTICLE = "GET_SEARCH_FEATURE_ARTICLE";
export const GET_SEARCH_FEATURE_ARTICLE_START =
  "GET_SEARCH_FEATURE_ARTICLE_START";
export const GET_SEARCH_FEATURE_ARTICLE_FAIL =
  "GET_SEARCH_FEATURE_ARTICLE_FAIL";
export const RESET_SEARCH_FEATURE_ARTICLE = "RESET_SEARCH_FEATURE_ARTICLE";
export const GET_GROUP_FEATURE_ARTICLE_SECTION =
  "GET_GROUP_FEATURE_ARTICLE_SECTION";
export const GET_GROUP_FEATURE_ARTICLE_SECTION_SUCCESS =
  "GET_GROUP_FEATURE_ARTICLE_SECTION_SUCCESS";

export const GET_GROUP_FEATURE_ARTICLE_SECTION_FAIL =
  "GET_GROUP_FEATURE_ARTICLE_SECTION_FAIL";

export const ADD_FEATURE_ARTICLE_TEMP = "SET_FEATURE_ARTICLE_TEMP";
export const EDIT_FEATURE_ARTICLE_TEMP = "EDIT_FEATURE_ARTICLE_TEMP";
export const DELETE_FEATURE_ARTICLE_TEMP = "DELETE_FEATURE_ARTICLE_TEMP";
export const SET_CURRENT_FEATURE_ARTICLE_FOR_EDIT =
  "SET_CURRENT_FEATURE_ARTICLE_FOR_EDIT";
export const RESET_CURRENT_FEATURE_ARTICLE = "RESET_CURRENT_FEATURE_ARTICLE";

// Get feature article by date
export const GET_SCHEDULE_BY_DATE = "GET_SCHEDULE_BY_DATE";
export const GET_SCHEDULE_BY_DATE_SUCCESS = "GET_SCHEDULE_BY_DATE_SUCCESS";
export const GET_SCHEDULE_BY_DATE_FAIL = "GET_SCHEDULE_BY_DATE_FAIL";
export const RESET_SCHEDULE = "RESET_SCHEDULE";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
// Get scheduled dates
export const GET_SCHEDULED_DATES = "GET_SCHEDULED_DATES";
export const GET_SCHEDULED_DATES_SUCCESS = "GET_SCHEDULED_DATES_SUCCESS";
export const GET_SCHEDULED_DATES_FAIL = "GET_SCHEDULED_DATES_FAIL";

export const SAVE_SCHEDULE = "SAVE_SCHEDULE";
export const SAVE_SCHEDULE_SUCCESS = "SAVE_SCHEDULE_SUCCESS";
export const SAVE_SCHEDULE_FAIL = "SAVE_SCHEDULE_FAIL";

export const CLOSE_INFORM_MODAL = "CLOSE_INFORM_MODAL";
export const SHOW_INFORM_MODAL = "SHOW_INFORM_MODAL";
export const GET_TOPIC_BY_GROUP = "GET_TOPIC_BY_GROUP";
export const GET_GROUP_DETAIL = "GET_GROUP_DETAIL";
export const GET_TOPIC_DETAIL = "GET_TOPIC_DETAIL";
export const GET_ARTICLE_BY_TOPIC = "GET_ARTICLE_BY_TOPIC";
export const RESET_MENU_LIST = "RESET_MENU_LIST";
export const REMOVE_MENU = "REMOVE_MENU";

export const GET_ABOUT_DETAIL = "GET_ABOUT_DETAIL";
export const GET_RECRUITMENT_DETAIL = "GET_RECRUITMENT_DETAIL";
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";

export const GET_CHIEF_COLLECTION = "GET_CHIEF_COLLECTION";
export const GET_CHIEF_DETAIL_COLLECTION = "GET_CHIEF_DETAIL_COLLECTION";
export const GET_PINNED_COLLECTION = "GET_PINNED_COLLECTION";
export const RESET_COLLECTION_LIST = "RESET_COLLECTION_LIST";
export const GET_COLLECTION_LIST = "GET_COLLECTION_LIST";

export const GET_READERS_CHIEF = "GET_READERS_CHIEF";

export const GET_SEARCH_ARTICLE_TO_COLLECTION =
  "GET_SEARCH_ARTICLE_TO_COLLECTION";
export const RESET_CHIEF_UNPUBLISH_BYID = "RESET_CHIEF_UNPUBLISH_BYID";
export const UPLOAD_FILE = "UPLOAD_FILE";

export const GET_OUTLINE_WRITER_DASHBOARD = "GET_OUTLINE_WRITER_DASHBOARD";
export const GET_ARTICLE_WRITER_DASHBOARD = "GET_ARTICLE_WRITER_DASHBOARD";
export const GET_PITCH_WRITER_DASHBOARD = "GET_PITCH_WRITER_DASHBOARD";
export const GET_ARTICLE_CHIEF_DASHBOARD = "GET_ARTICLE_CHIEF_DASHBOARD";
export const GET_OUTLINE_CHIEF_DASHBOARD = "GET_OUTLINE_CHIEF_DASHBOARD";
export const GET_OUTLINE_EDITOR_DASHBOARD = "GET_OUTLINE_EDITOR_DASHBOARD";
export const GET_ARTICLE_EDITOR_DASHBOARD = "GET_ARTICLE_EDITOR_DASHBOARD";
export const GET_PITCH_EDITOR_DASHBOARD = "GET_PITCH_EDITOR_DASHBOARD";
export const GET_PITCH_CHIEF_DASHBOARD = "GET_PITCH_CHIEF_DASHBOARD";
export const GET_TOPIC_SELECT = "GET_TOPIC_SELECT";

// Application management actionn
// List application
export const GET_LIST_APPLICATION_START = "GET_LIST_APPLICATION_START";
export const GET_LIST_APPLICATION_SUCCESS = "GET_LIST_APPLICATION_SUCCESS";
export const GET_LIST_APPLICATION_FAIL = "GET_LIST_APPLICATION_FAIL";
// Application Detail
export const GET_APPLICATION_DETAIL_START = "GET_APPLICATION_DETAIL_START";
export const GET_APPLICATION_DETAIL_SUCCESS = "GET_APPLICATION_DETAIL_SUCCESS";
export const GET_APPLICATION_DETAIL_FAIL = "GET_APPLICATION_DETAIL_FAIL";
// Search application
export const SEARCH_APPLICATION_START = "SEARCH_APPLICATION_START";
export const SEARCH_APPLICATION_SUCCESS = "SEARCH_APPLICATION_SUCCESS";
export const SEARCH_APPLICATION_FAIL = "SEARCH_APPLICATION_FAIL";
// Approve application
export const APPROVE_APPLICATION = "APPROVE_APPLICATION";
export const APPROVE_APPLICATION_SUCCESS = "APPROVE_APPLICATION_SUCCESS";
export const APPROVE_APPLICATION_FAIL = "APPROVE_APPLICATION_FAIL";
// Reject application
export const REJECT_APPLICATION = "REJECT_APPLICATION";
export const REJECT_APPLICATION_SUCCESS = "REJECT_APPLICATION_SUCCESS";
export const REJECT_APPLICATION_FAIL = "REJECT_APPLICATION_FAIL";

// Re-evaluate application
export const REVALUATE_APPLICATION = "REVALUATE_APPLICATION";
export const REVALUATE_APPLICATION_SUCCESS = "REVALUATE_APPLICATION_SUCCESS";
export const REVALUATE_APPLICATION_FAIL = "REVALUATE_APPLICATION_FAIL";

//Application information
export const GET_APPLICATION_INFO_START = "GET_APPLICATION_INFO_START";
export const GET_APPLICATION_INFO_SUCCESS = "GET_APPLICATION_INFO_SUCCESS";
export const GET_APPLICATION_INFO_FAIL = "GET_APPLICATION_INFO_FAIL";
// Count number application
export const GET_TOTAL_PENDING_APPLICATION_SUCCESS =
  "GET_TOTAL_PENDING_APPLICATION_SUCCESS";
export const GET_TOTAL_PROCESSED_APPLICATION_SUCCESS =
  "GET_TOTAL_PROCESSED_APPLICATION_SUCCESS";

// Timeline actions
export const GET_LIST_TIMELINE_START = "GET_LIST_TIMELINE_START";
export const GET_LIST_TIMELINE_SUCCESS = "GET_LIST_TIMELINE_SUCCESS";
export const GET_LIST_TIMELINE_FAIL = "GET_LIST_TIMELINE_FAIL";
export const GET_TIMELINE_DETAIL_START = "GET_TIMELINE_DETAIL_START";
export const GET_TIMELINE_DETAIL_SUCCESS = "GET_TIMELINE_DETAIL_SUCCESS";
export const GET_TIMELINE_DETAIL_FAIL = "GET_TIMELINE_DETAIL_FAIL";
export const EDIT_TIMELINE_ITEM = "EDIT_TIMELINE_ITEM";
export const ADD_TIMELINE_ITEM = "ADD_TIMELINE_ITEM";
export const CLEAR_TIMELINE_ITEM = "CLEAR_TIMELINE_ITEM";
// Add slideshow article
export const CHIEF_ADD_NEW_SLIDE_ARTICLE = "CHIEF_ADD_NEW_SLIDE_ARTICLE";
export const CHIEF_UPDATE_SLIDE_ARTICLE = "CHIEF_UPDATE_SLIDE_ARTICLE";
// Generate preview link
export const GENERATE_PREVIEW_LINK_SUCCESS = "GENERATE_PREVIEW_LINK_SUCCESS";
export const GENERATE_PREVIEW_LINK = "GENERATE_PREVIEW_LINK";
export const GENERATE_PREVIEW_LINK_FAIL = "GENERATE_PREVIEW_LINK_FAIL";
// POLL

export const GET_POLL = "GET_POLL";
export const GET_POLL_SUCCESS = "GET_POLL_SUCCESS";
export const GET_POLL_FAIL = "GET_POLL_FAIL";

export const UPDATE_POLL = "GET_POLL";
export const UPDATE_POLL_SUCCESS = "GET_POLL_SUCCESS";
export const UPDATE_POLL_FAIL = "GET_POLL_FAIL";

export const DELETE_POLL = "GET_POLL";
export const DELETE_POLL_SUCCESS = "GET_POLL_SUCCESS";
export const DELETE_POLL_FAIL = "GET_POLL_FAIL";

//add new open publishing
export const ADD_NEW_PUBLISHING = "ADD_NEW_PUBLISHING";
export const ADD_NEW_PUBLISHING_SUCCESS = "ADD_NEW_PUBLISHING_SUCCESS";
export const ADD_NEW_PUBLISHING_FAIL = "ADD_NEW_PUBLISHING_FAIL";
// Tag Management
export const DECLARE_ACTION = "DECLARE_ACTION";
export const RESET_STATE_TAGS_MANAGEMENT = "RESET_STATE_TAGS_MANAGEMENT";
export const GET_OFFICIAL_TAGS_SUCCESS = "GET_OFFICIAL_TAGS_SUCCESS";
export const GET_SUGGESTED_TAGS_SUCCESS = "GET_SUGGESTED_TAGS_SUCCESS";
export const GET_ALL_TAGS_IN_EACH_GROUP_SUCCESS =
  "GET_ALL_TAGS_IN_EACH_GROUP_SUCCESS";
export const TAG_SEARCHING_SUCCESSFULLY = "TAG_SEARCHING_SUCCESSFULLY";
export const GET_NO_DATA_LEFT = "GET_NO_DATA_LEFT";
export const TAG_SEARCHING_LEFT_EMPTY = "TAG_SEARCHING_LEFT_EMPTY";
export const GET_RELATED_TAG_SUCCESSFULLY = "GET_RELATED_TAG_SUCCESSFULLY";
export const GET_ARTICLE_TAG_SUCCESSFULLY = "GET_ARTICLE_TAG_SUCCESSFULLY";
export const GET_TOTAL_TAG_SUCCESSFULLY = "GET_TOTAL_TAG_SUCCESSFULLY";

// Published colleciton
export const GET_PUBLISHED_COLLECTION_SUCCESS =
  "GET_PUBLISHED_COLLECTION_SUCCESS";
export const GET_PUBLISHED_COLLECTION_FAIL = "GET_PUBLISHED_COLLECTION_FAIL";
export const GET_PUBLISHED_COLLECTION = "GET_PUBLISHED_COLLECTION";
