import React, { Component } from "react";
import { Card, CardHeader, CardFooter, Table, Col, Row } from "reactstrap";
// import getUrlParam from "../../utils/getUrlParam";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactDragListView from "react-drag-listview";
import ChiefRemoveMenuModal from "../Modals/ChiefRemoveMenuModal";
import {
  actGetMenuListRequest,
  actGetTopicListRequest,
  actGetMenuList,
  actResetMenuList,
  actRemoveMenu,
  actUpdateMenuRequest
} from "../../actions/topic.action";
import ChiefMainMenuModal from "../Modals/ChiefMainMenuModal";

class ChiefMainMenuTable extends Component {
  state = {
    menuList: [],
    isLoading: false,
    isRemove: false,
    isAddMenu: false,
    languagueKey: true,
    params: {
      language: "VN"
    }
  };

  componentDidMount() {
    this._setLoading();
    this._getMenuList();
    this._getTopicList();
  }

  _getMenuList = () => {
    const { params } = this.state;
    this.props.getMenuList(params);
  };

  _getTopicList = () => {
    const { params } = this.state;
    this.props.getTopicList(params);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _switchLanguage = e => {
    const newLanguage = e.target.checked;
    this.setState({
      languagueKey: newLanguage
    });
    if (newLanguage) {
      let params = {
        language: "VN"
      };
      this.props.getMenuList(params);

      this.props.getTopicList(params);
    } else {
      let params = {
        language: "EN"
      };
      this.props.getMenuList(params);

      this.props.getTopicList(params);
    }
  };

  _toggleModalMenu = () => {
    this.setState(prevState => ({
      isAddMenu: !prevState.isAddMenu
    }));
  };

  _toggleRemoveMenu = () => {
    this.setState(prevState => ({
      isRemove: !prevState.isRemove
    }));
  };

  _handleOnRemove = (menuID, index) => {
    this.setState({
      id: menuID,
      index: index
    });
    this._toggleRemoveMenu();
  };

  _hanldeOnRemoveWithRedux = index => {
    this.props.removeMenu(index);
  };

  render() {
    const { mainMenu_list } = this.props;
    const restTopics = 4 - mainMenu_list.length;

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const item = mainMenu_list.splice(fromIndex, 1)[0];
        mainMenu_list.splice(toIndex, 0, item);
        that.props.resetMenuList();
        that.props.setMenuList(mainMenu_list);
        mainMenu_list.map((prop, key) => {
          prop.menuPosition = key + 1;
          return prop;
        });
        const updatedList = mainMenu_list.map(prop => {
          return {
            id: prop._id,
            menuPosition: prop.menuPosition
          };
        });
        const data = {
          topics: updatedList
        };
        if (that.state.languagueKey) {
          let params = {
            language: "VN"
          };
          that.props.updateMenu(data, params);
        } else {
          let params = {
            language: "EN"
          };
          that.props.updateMenu(data, params);
        }
      },
      nodeSelector: "tr",
      handleSelector: "i",
      enableScroll: false
    };
    return (
      <>
        <ChiefMainMenuModal
          modal={this.state.isAddMenu}
          toggle={this._toggleModalMenu}
          languagueKey={this.state.languagueKey}
        />
        <ChiefRemoveMenuModal
          modal={this.state.isRemove}
          toggle={this._toggleRemoveMenu}
          id={this.state.id}
          index={this.state.index}
          languagueKey={this.state.languagueKey}
        />
        <Row
          className="float-right"
          style={{ marginBottom: 20, marginTop: "-4rem" }}
        >
          <Col lg="6 text-right">
            <button
              type="button"
              className="btn-icon btn btn-secondary"
              onClick={this._toggleModalMenu}
            >
              <span className="mr-1">
                <i
                  className="fa fa-plus-circle"
                  style={{ color: "#5e72e4" }}
                ></i>
              </span>

              <span className="btn-inner--text" style={{ color: "#5e72e4" }}>
                Add New Menu
              </span>
            </button>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Menu List</h3>
                  </Col>
                  <Col xs="6">
                    <div className="general-info-card d-flex float-right">
                      <label className="custom-toggle custom-toggle-warning mr-1">
                        <input
                          checked={this.state.languagueKey}
                          onChange={this._switchLanguage}
                          type="checkbox"
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="EN"
                          data-label-on="VN"
                        />
                      </label>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {mainMenu_list && mainMenu_list.length === 0 && (
                  <div
                    className="text-center"
                    style={{ color: "#4B556F", padding: "10%" }}
                  >
                    <i className="icon-search" style={{ fontSize: "70px" }} />
                    <p>No Data</p>
                    <span
                      className="add-btn d-flex justify-content-center cursor-pointer"
                      style={{ color: "#5E72E4", fontSize: 18 }}
                    >
                      <i className="icon-plus" style={{ marginRight: 6 }} />
                      <span> Add New Menu</span>
                    </span>
                  </div>
                )}
                {mainMenu_list && mainMenu_list.length !== 0 && (
                  <ReactDragListView {...dragProps} lineClassName="dragline">
                    <Table
                      className="align-items-center table-flush"
                      style={{ border: "0px" }}
                      hover
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            // onClick={() => this._sortByColumn("angle_title")}
                          >
                            ADDED TOPIC
                          </th>
                          <th scope="col">
                            <span className="float-right">ACTION</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="list">
                        {mainMenu_list &&
                          mainMenu_list.map((prop, key) => {
                            return (
                              <>
                                <tr key={key}>
                                  <th scope="row" className="flex-row-table">
                                    <i
                                      className="icon-drag"
                                      style={{
                                        cursor: "move",
                                        fontSize: "1.15rem"
                                      }}
                                    />
                                    <span
                                      style={{
                                        marginBottom: 4.5,
                                        marginLeft: 20
                                      }}
                                    >
                                      {prop._id && prop.name}
                                    </span>
                                  </th>
                                  <td className="cursor-pointer">
                                    <i
                                      className="fa fa-trash-alt table-action table-action-delete float-right"
                                      onClick={() =>
                                        this._handleOnRemove(prop._id, key)
                                      }
                                    ></i>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  </ReactDragListView>
                )}
              </div>
              {mainMenu_list.length < 4 && (
                <CardFooter className="py-4">
                  <div
                    className="more-topic d-flex justify-content-between font-weight-bold"
                    style={{ fontSize: 14 }}
                  >
                    <span style={{ color: "#F5365C" }}>
                      {`Choose at least ${restTopics} more topics`}
                    </span>
                    <span
                      className="add-btn d-flex justify-content-center cursor-pointer"
                      style={{ color: "#5E72E4" }}
                      onClick={this._toggleModalMenu}
                    >
                      <i className="icon-plus" style={{ marginRight: 6 }} />
                      <span> Add topics</span>
                    </span>
                  </div>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    mainMenu_list: state.topicReducer.mainMenu_list,
    modalTopic_list: state.topicReducer.modalTopic_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getMenuList: data => {
      dispatch(actGetMenuListRequest(data));
    },
    getTopicList: data => {
      dispatch(actGetTopicListRequest(data));
    },
    setMenuList: data => {
      dispatch(actGetMenuList(data));
    },
    resetMenuList: () => {
      dispatch(actResetMenuList());
    },
    removeMenu: index => {
      dispatch(actRemoveMenu(index));
    },
    updateMenu: (data, params) => {
      dispatch(actUpdateMenuRequest(data, params));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChiefMainMenuTable);
ChiefMainMenuTable.propTypes = {
  mainMenu_list: PropTypes.array,
  getMenuList: PropTypes.func,
  setMenuLength: PropTypes.func,
  history: PropTypes.object,
  removeMenu: PropTypes.func,
  updateMenu: PropTypes.func,
  getAvailablePitches: PropTypes.func,
  getTopicList: PropTypes.func
};
ChiefMainMenuTable.defaultProps = {};
