import React, { Component } from "react";
import moment from "moment";
import { Button, Input, Badge } from "reactstrap";
import { Menu, Dropdown } from "antd";
import PropTypes from "prop-types";
import ReplyComment from "./ReplyComment";
import Avatar from "../Avatar/Avatar";
import { COMMENT_STATUS } from "../../utils/constants";

class Comment extends Component {
  state = {
    isReplyInputShow: false,
    editComment: "",
    reply: "",
    isEdittingInputShow: false
  };

  _handleOnClickReply = () => {
    this.setState(prevState => ({
      isReplyInputShow: !prevState.isReplyInputShow
    }));
  };

  _handleOnClickEdit = () => {
    this.setState(prevState => ({
      isEdittingInputShow: !prevState.isEdittingInputShow
    }));
  };

  _handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  menu = () => {
    return (
      <Menu>
        <Menu.Item onClick={this._handleResolveFeedback}>Resolved</Menu.Item>
        <Menu.Item onClick={this._handleDeleteFeedback} className="text-danger">
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  onKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this._handleOnSubmit();
    }
  };

  onKeyDownEdit = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this._handleOnSubmitEdit();
    }
  };
  _handleDeleteFeedback = () => {
    const data = {
      outlineId: this.props.outline_id,
      commentId: this.props.comment._id,
      status: COMMENT_STATUS.DELETED
      // article_id: this.props.outline_id
    };
    this.props.changeFeedbackStatus(data);
  };
  _handleResolveFeedback = () => {
    const data = {
      outlineId: this.props.outline_id,
      commentId: this.props.comment._id,
      status: COMMENT_STATUS.RESOLVED
    };
    this.props.changeFeedbackStatus(data);
  };
  _handleOnSubmit = () => {
    const data = {
      outlineId: this.props.outline_id,
      commentId: this.props.comment._id,
      content: this.state.reply
    };
    if (this.state.reply) {
      this.props.addReply(data);
      this._handleOnClickReply();
      this.setState({ reply: "" });
    }
  };

  render() {
    const props = this.props;
    const { isReplyInputShow, reply } = this.state;
    const { comment } = props;
    return (
      <div className="comment">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <div className="d-flex w-100 align-items-center">
              {/* <Avatar
                width="32"
                height="32"
                className="mr-2"
                src={comment.avatar}
              /> */}
              <div>
                <h4 className="mb-1 font-weight-bold">
                  {comment.creator.name}
                </h4>
                <div className="date">
                  {moment(comment.createdAt).format("HH:mm DD/MM/YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className="resolve-container">
            {comment.status !== COMMENT_STATUS.RESOLVED ? (
              <Dropdown overlay={this.menu} trigger={["click", "hover"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                </a>
              </Dropdown>
            ) : (
              <Badge color="success" pill>
                Resolved
              </Badge>
            )}
          </div>
        </div>
        <p className="mt-3 mb-1">{comment.content}</p>
        {comment.status !== COMMENT_STATUS.RESOLVED ? (
          <div>
            {!isReplyInputShow && (
              <small
                className="btn-reply text-primary font-weight-bold cursor-pointer"
                onClick={() => this._handleOnClickReply()}
              >
                Reply
              </small>
            )}
            {isReplyInputShow && (
              <>
                <div className="input-reply d-flex w-100 justify-content-between align-items-center">
                  {/* <Avatar
                    src={my_information.avatar}
                    width={30}
                    height={30}
                    className={"mr-2"}
                  /> */}
                  <Input
                    placeholder="Reply..."
                    name="reply"
                    value={reply}
                    onChange={this._handleOnChange}
                    onKeyDown={this.onKeyDown}
                    type="text"
                  />
                </div>
                <Button
                  className="btn-cancle mt-2"
                  outline
                  color="primary"
                  type="button"
                  size="sm"
                  onClick={() => this._handleOnClickReply()}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        ) : null}

        <div className="replies mt-2">
          {comment.replies &&
            comment.replies.map((prop, key) => {
              if (prop.creator)
                return <ReplyComment reply={prop} key={key} {...props} />;
              return null;
            })}
        </div>
      </div>
    );
  }
}

export default Comment;
Comment.propTypes = {
  comment: PropTypes.object,
  addReply: PropTypes.func,
  outline_id: PropTypes.string,
  changeFeedbackStatus: PropTypes.func,
  my_information: PropTypes.object
};
Comment.defaultProps = {};
