import { combineReducers } from "redux";
import fetchReducer from "./fetch.reducer";
// import importReducer from "./import.reducer";
import { i18nState } from "redux-i18n";
// import uploadReducer from "./upload.reducer";
// import calendarReducer from "./calendar.reducer";
import notificationReducer from "./notification.reducer";
// import userReducer from "./user.reducer";
import writerArticleReducer from "./writerArticle.reducer";
import editorArticleReducer from "./editorArticle.reducer";
import postReducer from "./post.reducer";
import chiefArticleReducer from "./chiefArticle.reducer";
import permissionReducer from "./permission.reducer";
import employeeReducer from "./employee.reducer";
import feedbackReducer from "./feedback.reducer";
import myProfileReducer from "./myprofile.reducer";
import authReducer from "./authentication.reducer";

import editorDashboardReducer from "./editordashboard.reducer";
import chiefDashboardReducer from "./chief-dashboard.reducer";
import pitchReducer from "./pitch.reducer";
import topicReducer from "./topic.reducer";
import featureArticleReducer from "./feature-article.reducer";
import generalInfoReducer from "./general-info.reducer";
import ChiefCollectionReducer from "./chiefCollection.reducer";
import writerDashboardReducer from "./writerdashboard.reducer";
import userReducer from "./user.reducer";
import tagManagementReducer from "./tagmanagement.reducer";
// Application reducer
import applicationReducer from "./application.reducer";
// Timeline reducer
import timelineReducer from "./timeline.reducer";
// Poll reducer
import pollReducer from "./poll.reducer";

const appReducers = combineReducers({
  userReducer,
  fetchReducer,
  i18nState,
  notificationReducer,
  writerArticleReducer,
  editorArticleReducer,
  postReducer,
  chiefArticleReducer,
  permissionReducer,
  employeeReducer,
  feedbackReducer,
  myProfileReducer,
  authReducer,
  editorDashboardReducer,
  chiefDashboardReducer,
  pitchReducer,
  topicReducer,
  featureArticleReducer,
  generalInfoReducer,
  ChiefCollectionReducer,
  tagManagementReducer,
  writerDashboardReducer,
  applicationReducer,
  timelineReducer,
  pollReducer,
});

export default appReducers;
