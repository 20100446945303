import React, { Component } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Collapse
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PLUS from "../../assets/img/icons/plus.svg";
import {
  actGetPublishDetailByIDRequest,
  actSubmitPublishRequest,
  actSubmitUnPublishedRequest,
  actAutoSaveWritingContentRequest,
  actSaveWritingContentRequest
} from "../../actions/chiefArticle.action";
import CustomEditor from "../TinyMCE";
import ContentPublishModal from "../Modals/ContentPublishModal";

class ChiefPublishReviewTable extends Component {
  state = {
    tabs: 1,
    reply: false,
    collapse: false,
    openedCollapses: ["collapseOne"],
    id: window.location.pathname.split("/")[4],
    data: {
      content: ""
    }
  };

  componentDidMount() {
    this._setLoading();
    this._getPublisByID();
    // this._autoSave();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.content_detail !== this.props.content_detail) {
      this.setState({
        data: { content: nextProps.content_detail.data.content_content },
        isPublished:
          nextProps.content_detail.data.status_article === "PUBLISHED"
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
    this.setState({ autoSave: false });
  }

  _getPublisByID() {
    this.props.getPublisByID(this.state.id);
  }

  _handleOnOpenContentPublishModal = () => {
    this.setState({ isModalOpen: true });
  };

  _handleSubmitUnPublish = () => {
    const id = this.state.id;
    const data = this.state.data;
    this.props.submitUnPublish(id, data);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _handleOnClickReply = () => {
    this.setState(prevState => ({
      reply: !prevState.reply
    }));
  };

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  _handleOnClickCollapse = () => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;

    // this.props.getBannerList(params);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        content: value
      },
      autoSave: true
    });
  };

  _toggleModal = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };

  _handleOnSaveDraftContent = e => {
    e.preventDefault();
    const content_detail = this.props.content_detail.data;
    const data = {
      idContent: content_detail.contentID,
      title: content_detail.angle_title,
      content: this.state.data.content
    };
    this.props.saveDraft(data);
  };

  // _autoSave = () => {
  //   this.autoSave = setInterval(() => {
  //     const content_detail = this.props.content_detail.data;
  //     const data = {
  //       idContent: content_detail.contentID,
  //       title: content_detail.angle_title,
  //       content: this.state.data.content
  //     };
  //     this.props.autoSave(data);
  //   }, 5000);
  // };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        content: value
      }
    });
  };

  render() {
    const props = this.props;
    // const { content_detail } = this.props;
    return (
      <>
        <ContentPublishModal
          {...props}
          modal={this.state.isModalOpen}
          toggle={this._toggleModal}
          id={this.state.id}
          // eslint-disable-next-line react/prop-types
          writing_content={this.props.writing_content}
          data={this.state.data}
        />
        <Row>
          <Col xl="9">
            <Card style={{ marginTop: "19px" }}>
              <CardHeader
                className="border-0 d-flex justify-content-between"
                role="tab"
              >
                <h3 className="mb-0">Text Editor</h3>
                <a
                  href="#pablo"
                  style={{ fontWeight: "bold" }}
                  onClick={this._handleOnSaveDraftContent}
                >
                  Save draft
                </a>
              </CardHeader>
              <CardBody>
                {this.state.data.content && (
                  <CustomEditor
                    content={this.state.data.content}
                    onChangeContent={this._handleOnContentChange}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="3">
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                size="lg"
                className="btn-success btn-lg"
                style={{ width: "85%" }}
                onClick={this._handleOnOpenContentPublishModal}
              >
                Publish
              </Button>
              <a
                target="blank"
                href={`/admin/chief/view-detail-content-editor/${this.state.id}`}
              >
                <Button
                  type="button"
                  className="text-primary text-center btn-lg"
                >
                  <i className="fa fa-eye text-center"></i>
                </Button>
              </a>
            </div>
            <Card className="mt-3">
              <CardHeader>
                <div className="align-items-center">
                  <div className="text-center">
                    <i className="fa fa-cogs text-primary pr-2"></i>
                    <span className="mr-1 font-weight-bold">Post setting</span>
                  </div>
                </div>
              </CardHeader>

              <div>
                <div className="accordion">
                  <div className="card-plain">
                    <CardHeader
                      role="tab"
                      onClick={() => this.collapsesToggle("collapseOne")}
                      aria-expanded={this.state.openedCollapses.includes(
                        "collapseOne"
                      )}
                    >
                      <h5 className="mb-0">Status & Visibility</h5>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={this.state.openedCollapses.includes(
                        "collapseOne"
                      )}
                    >
                      <CardBody>
                        <div className="">
                          <Row className="mt-3">
                            <Col lg="6">
                              <p>Visibility</p>
                            </Col>
                            <Col lg="6 text-right">
                              <a href="#pablo">Public</a>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg="12">
                              <Card>
                                <div className="ml-3 mt-3">
                                  <h5>Post Visibility</h5>
                                  <div className="custom-control custom-radio mb-3 ml-1">
                                    <input
                                      className="custom-control-input"
                                      defaultChecked
                                      id="customRadio6"
                                      name="custom-radio-1"
                                      type="radio"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customRadio6"
                                    >
                                      Public
                                    </label>
                                    <p>Visible to everyone.</p>
                                  </div>
                                  <div className="custom-control custom-radio mb-3 ml-1">
                                    <input
                                      className="custom-control-input"
                                      defaultChecked
                                      id="customRadio6"
                                      name="custom-radio-1"
                                      type="radio"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customRadio6"
                                    >
                                      Checked
                                    </label>
                                    <p>
                                      Only visible to site admins and editors.
                                    </p>
                                  </div>
                                  <div className="custom-control custom-radio mb-3 ml-1">
                                    <input
                                      className="custom-control-input"
                                      defaultChecked
                                      id="customRadio6"
                                      name="custom-radio-1"
                                      type="radio"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customRadio6"
                                    >
                                      Checked
                                    </label>
                                    <label>
                                      Protected with a password you choose. Only
                                      those with the password can view this
                                      post.
                                    </label>
                                  </div>
                                  <CardBody className="text-warning text-center">
                                    <label>Move to trash</label>
                                  </CardBody>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Collapse>
                  </div>
                </div>
                <div className="">
                  <CardHeader>
                    <div className="d-flex align-items-center">
                      <i className="fa fa-undo mr-2"></i>
                      <h5 className="mb-0">5 Revisions</h5>
                    </div>
                  </CardHeader>
                </div>
                <div className="accordion">
                  <div className="card-plain">
                    <CardHeader
                      role="tab"
                      onClick={() => this.collapsesToggle("collapseTwo")}
                      aria-expanded={this.state.openedCollapses.includes(
                        "collapseTwo"
                      )}
                    >
                      <h5 className="mb-0">Permalink</h5>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={this.state.openedCollapses.includes(
                        "collapseTwo"
                      )}
                    >
                      <CardBody>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                          >
                            URL Slug
                          </label>
                          <Input id="example3cols1Input" type="text" />
                          The last part of the URL.{" "}
                          <a href="#pablo" target="_blank">
                            Read about permalinks
                          </a>
                        </FormGroup>
                        <h5> Preview</h5>
                        <a href="#pablo">
                          http://staging1.vietcetera.com/a-working-woman-:-thuy-minh
                        </a>
                      </CardBody>
                    </Collapse>
                  </div>
                </div>
                <div className="accordion">
                  <div className="card-plain">
                    <CardHeader
                      role="tab"
                      onClick={() => this.collapsesToggle("collapseThree")}
                      aria-expanded={this.state.openedCollapses.includes(
                        "collapseThree"
                      )}
                    >
                      <h5 className="mb-0">Categories</h5>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={this.state.openedCollapses.includes(
                        "collapseThree"
                      )}
                    >
                      <CardBody>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            defaultChecked
                            id="customCheck2"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Ẩm thực
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            defaultChecked
                            id="customCheck2"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Du lịch
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            defaultChecked
                            id="customCheck2"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Khám phá
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            defaultChecked
                            id="customCheck2"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Kinh doanh
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            defaultChecked
                            id="customCheck2"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Uncategorized @vn
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            defaultChecked
                            id="customCheck2"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Văn hoá
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            defaultChecked
                            id="customCheck2"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Sáng tạo
                          </label>
                        </div>
                        <div className="">
                          <img
                            //   onClick={() => {
                            //     this._handleViewBlacklist(prop.id, prop.employeeID);
                            //   }}
                            src={PLUS}
                            className="cursor-pointer"
                            alt=""
                          />
                          <lable className="ml-2" style={{ fontSize: "14px" }}>
                            Add new category
                          </lable>
                        </div>
                      </CardBody>
                    </Collapse>
                  </div>
                </div>
                <div className="accordion">
                  <div className="card-plain">
                    <CardHeader
                      role="tab"
                      onClick={() => this.collapsesToggle("collapseFour")}
                      aria-expanded={this.state.openedCollapses.includes(
                        "collapseFour"
                      )}
                    >
                      <h5 className="mb-0">Tags</h5>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={this.state.openedCollapses.includes(
                        "collapseFour"
                      )}
                    >
                      <CardBody></CardBody>
                    </Collapse>
                  </div>
                </div>
              </div>
              {/* <CardFooter className="text-center"></CardFooter> */}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    content_detail: state.chiefArticleReducer.publish_detail_by_id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPublisByID: data => {
      dispatch(actGetPublishDetailByIDRequest(data));
    },
    submitPublish: (id, data) => {
      dispatch(actSubmitPublishRequest(id, data));
    },
    submitUnPublish: (id, data) => {
      dispatch(actSubmitUnPublishedRequest(id, data));
    },
    saveDraft: data => {
      dispatch(actSaveWritingContentRequest(data));
    },
    autoSave: data => {
      dispatch(actAutoSaveWritingContentRequest(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefPublishReviewTable);
ChiefPublishReviewTable.propTypes = {
  content_detail: PropTypes.object,
  writing_content: PropTypes.object,
  getPublisByID: PropTypes.func,
  submitPublish: PropTypes.func,
  submitUnPublish: PropTypes.func,
  saveDraft: PropTypes.func,
  autoSave: PropTypes.func
};
ChiefPublishReviewTable.defaultProps = {};
