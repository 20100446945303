import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
import ChiefUnPublishedArticleReviewDetail from "../../../components/Detail/ChiefUnPublishedArticleReviewDetail";
import ChiefUnpublishedSlideArticleDetail from "./ChiefUnPublishedSlideArticleDetail";
class ChiefUnPublishReview extends Component {
  state = {};
  render() {
    const props = this.props;
    const { state } = this.props.location;
    return (
      <>
        <CardsHeaderchief
          name="Published Articles"
          content="publishedArticles"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          {state.type !== "slideShow" ? (
            <ChiefUnPublishedArticleReviewDetail {...props} />
          ) : (
            <ChiefUnpublishedSlideArticleDetail type={state.type} />
          )}
        </Container>
      </>
    );
  }
}
ChiefUnPublishReview.propTypes = {
  content: PropTypes.string,
};
export default ChiefUnPublishReview;
