import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify, actFetchResourceFail } from "./fetch.action";
const form_data = true;

export const actUploadImageRequest = data => {
  return dispatch => {
    return callApi(`images/upload`, "POST", data, form_data)
      .then(res => {
        dispatch(actUploadImage(res));
      })
      .catch(() => {
        dispatch(
          actFetchResourceFail({
            message: "Error! Please try again later",
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actUploadImage = data => {
  return {
    type: Types.UPLOAD_FILE,
    data
  };
};

export const actUpdateImageSizeRequest = (id, data) => {
  return dispatch => {
    return callApi(`update-images/${id}`, "PUT", data, form_data)
      .then(() => {
        dispatch(
          actNotify({
            message: "You have updated image size successfully",
            color: "success"
          })
        );
      })
      .catch(() => {
        dispatch(
          actFetchResourceFail({
            message: "Error! Please try again later",
            confirmTo: window.location.pathname
          })
        );
      });
  };
};
