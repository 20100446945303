import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { Form, Input, Icon, Button, Modal } from "antd";
import { Row, Col } from "reactstrap";
import {
  actGetPollByArticleRequest,
  actUpdatePollByArticleRequest,
  actDeletePollByArticleRequest,
} from "../../../actions/chiefArticle.action";

import "../styles.scss";

const { confirm } = Modal;
class EditPollManager extends React.Component {
  state = {
    poll: {},
  };
  componentDidMount() {
    if (this.props.pollId) {
      this.props.getPollByArticle(this.props.pollId);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.pollId && this.props.pollId !== prevProps.pollId) {
      this.props.getPollByArticle(this.props.pollId);
    }
    if (this.props.poll && this.props.poll !== prevProps.poll) {
      const { form, poll } = this.props;
      const question = get(poll, "question", "");
      form.setFieldsValue({
        question: question,
      });
      this.setState({
        poll: poll,
      });
    }
  }

  // remove = (k) => {
  //   const { form } = this.props;
  //   const { poll } = this.state;
  //   const answers = get(poll, "answers");
  //   this.setState({
  //     poll: {
  //       answers: answers.filter((key) => key.id !== k),
  //     },
  //   });
  //   const answer = form.getFieldValue("answer");
  //   const result = omit(answer, [`${k}`]);
  //   form.setFieldsValue({
  //     answer: result,
  //   });
  // };

  add = () => {
    const {
      poll: { answers },
    } = this.state;
    const nextKeys = answers.concat({
      content: "",
      id: "new_id" + answers.length,
      updateRemove: true,
    });
    this.setState({
      poll: {
        answers: nextKeys,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          poll: { answers },
        } = this.state;
        const { pollId } = this.props;
        const answersResult = Object.keys(values.answer).map((item) => {
          const update = {};
          const findItem = answers.find((e) => e.id === item);
          if (!findItem.updateRemove) {
            update._id = findItem.id;
          }
          update.content = values.answer[item];
          return update;
        });
        const payload = {
          pollId: pollId,
          answers: answersResult,
          question: values.question,
        };
        this.props.updatePollByArticle(payload);
        this.props.rerenderParentCallback();
      }
    });
  };

  // handle delete poll
  handleDelete = () => {
    const { pollId, deletePollByArticle, article_id } = this.props;
    confirm({
      title: "Delete this poll?",
      onOk() {
        const payload = {
          pollId,
        };
        deletePollByArticle(payload, article_id);
      },
      onCancel() {},
    });
  };

  renderAnswerLength = (value) => {
    if (!value || value.length === 0) {
      return `0/100`;
    }
    return `${value.length}/100`;
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { disabled } = this.props;
    const { poll } = this.state;
    const answers = get(poll, "answers", []);
    const answersArray = answers.map((item) => ({
      content: item.content,
      id: item.id,
    }));
    const disableAddAnswer = answers.length >= 5 || disabled;
    const questionValue = getFieldValue("question") || 0;
    const formItems = answersArray.map((k, index) => (
      <Form.Item label={index === 0 ? "Answers" : ""} required={false} key={k}>
        {getFieldDecorator(`answer.${k.id}`, {
          initialValue: k.content,
          validateTrigger: ["onChange", "onBlur"],
          rules: [
            {
              required: true,
              max: 100,
              whitespace: true,
              message: "Invalid field. Please check again!",
            },
          ],
        })(
          <Input
            disabled={disabled}
            placeholder="Answer"
            style={{ width: "50%", marginRight: 8 }}
          />
        )}
        <span className="mx-2">
          {this.renderAnswerLength(getFieldValue(`answer.${k.id}`))}
        </span>
      </Form.Item>
    ));
    if (!this.props.pollId) {
      return <p>There is no poll yet</p>;
    }
    return (
      <Form onSubmit={this.onSubmit}>
        <Row>
          <Col lg="12">
            <Form onSubmit={this.handleSubmit}>
              <Form.Item style={{ width: "50%" }}>
                {getFieldDecorator("question", {
                  rules: [
                    {
                      max: 100,
                      required: true,
                      message: "Invalid field. Please check again!",
                    },
                  ],
                })(<Input disabled={disabled} placeholder="Question" />)}
                <span className="float-right">
                  {`${questionValue.length || 0}/100`}
                </span>
              </Form.Item>
              {formItems}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={this.add}
                  disabled={disableAddAnswer}
                  style={{ width: "50%" }}
                >
                  <Icon type="plus" /> Add anwser
                </Button>
              </Form.Item>
              <Form.Item>
                <div className="d-flex justify-content-between">
                  <Button disabled={disabled} type="primary" htmlType="submit">
                    Save
                  </Button>
                  <Button
                    disabled={disabled}
                    type="danger"
                    onClick={this.handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Form>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getPollByArticle: (pollId) => {
      dispatch(actGetPollByArticleRequest(pollId));
    },
    updatePollByArticle: (data) => {
      dispatch(actUpdatePollByArticleRequest(data));
    },
    deletePollByArticle: (data, article_id) => {
      dispatch(actDeletePollByArticleRequest(data, article_id));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    poll: state.pollReducer.poll,
  };
};

const WrappedPollManager = Form.create({ name: "dynamic_form_item" })(
  EditPollManager
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedPollManager);
