import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import EditorAddNewPostDetail from "../../../components/Detail/EditorAddNewPostDetail";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
class ChiefAddNewPost extends Component {
  state = {
    id: window.location.pathname.split("/")[4],
  };
  render() {
    const props = this.props;
    return (
      <>
        {this.state.id ? (
          <CardsHeaderchief
            name="Brief Detail"
            content="briefDetail"
            parentName="Article"
          />
        ) : (
          <CardsHeaderchief
            name="Add New Brief"
            content="newBrief"
            parentName="Article"
          />
        )}
        <Container className="mt--6" fluid>
          <EditorAddNewPostDetail {...props} />
        </Container>
      </>
    );
  }
}
ChiefAddNewPost.propTypes = {
  content: PropTypes.string,
};
export default ChiefAddNewPost;
