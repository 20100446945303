import React from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
import ApplicationDetail from "../../../components/Detail/ApplicationDetail";

function ChiefApplicationDetail() {
  return (
    <>
      <CardsHeaderchief
        name="Application Detail"
        content="Application Detail"
        parentName="Application Management"
        isDetail={true}
      />
      <Container className="mt--6" fluid>
        <ApplicationDetail />
      </Container>
    </>
  );
}
ChiefApplicationDetail.propTypes = {
  content: PropTypes.string
};
export default ChiefApplicationDetail;
