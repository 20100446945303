import React, { Component } from "react";
import {
  Container
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../components/Headers/CardsHeaderEditor";
import ArticlesInProgressTable from "../../components/Tables/ArticlesInProgressTable";
class ArticlesInProgress extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Article - In Progress"
          content="articlesInProgress"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <ArticlesInProgressTable {...props} />
        </Container>
      </>
    );
  }
}
ArticlesInProgress.propTypes = {
  content: PropTypes.string
};
export default ArticlesInProgress;
