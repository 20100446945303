import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Table,
  Col,
  Row,
  CardFooter,
  Button,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatStringToDate } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import { actGetAllBriefsRequest } from "../../actions/editorArticle.action";
import Avatar from "../Avatar/Avatar";
import Select from "react-select";
import { actGetEditorListRequest } from "../../actions/post.action";
import ReactHtmlParser from "react-html-parser";

// const URL_IMG = "http://35.185.181.184/";
class AllBriefsTable extends Component {
  state = {
    tabs: 1,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1",
      assign_to_editor: getUrlParam()["assign_to_editor"]
        ? getUrlParam()["assign_to_editor"]
        : "",
      angle_title: getUrlParam()["angle_title"]
        ? getUrlParam()["angle_title"]
        : "",
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt"
    },
    search: "",
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getAllBriefs();
    this._getAllEditor();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getAllBriefs = () => {
    const params = this.state.params;
    this.props.getAllBriefs(params);
  };

  _getAllEditor = () => {
    this.props.getAllEditor();
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getAllBriefs(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getAllBriefs(paramsSort);
  };

  _handleOnChange = (label, value) => {
    this.setState({
      [label]: value
    });
  };

  _handleOnClick = () => {
    const { params, search } = this.state;
    params.page = 1;
    params.angle_title = search;
    this.setState({
      params: params
    });
    this.props.getAllBriefs(params);
    this._handlePathnamePush(params);
    this._setLoading();
  };

  _handleOnFilter = (label, value) => {
    const state = this.state;
    const params = {
      ...state.params,
      [label]: value
    };
    this.setState({
      params: {
        ...state.params,
        [label]: value
      }
    });
    this._handlePathnamePush(params);
    this.props.getAllBriefs(params);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/editor/edit-brief/${id}`);
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 50) {
      return `${str[0].substring(0, 50)}...`;
    } else {
      return str[0].substring(0, 50);
    }
  };

  _renderStatus = status => {
    switch (status) {
      case "PUBLISHED":
        return (
          <>
            <div>
              <span className="status-completed">●</span>
              <span>Published</span>
            </div>
          </>
        );
      case "UNPUBLISHED":
        return (
          <>
            <div>
              <span className="status-cancel">●</span>
              <span>Unpublished</span>
            </div>
          </>
        );
      case "REJECTED":
        return (
          <>
            <div>
              <span className="status-cancel">●</span>
              <span>Rejected</span>
            </div>
          </>
        );
      case "NEW":
        return (
          <>
            <div>
              <span className="status-new">●</span>
              <span>New</span>
            </div>
          </>
        );
      case "COMPLETED":
        return (
          <>
            <div>
              <span className="status-progress">●</span>
              <span>Ready to publish</span>
            </div>
          </>
        );
      case "CONTENT_WRITING":
        return (
          <>
            <div>
              <span className="status-progress">●</span>
              <span>Articles - Need Writing</span>
            </div>
          </>
        );
      case "OUTLINE_WRITING":
        return (
          <>
            <div>
              <span className="status-progress">●</span>
              <span>Outlines - Need Writing</span>
            </div>
          </>
        );

      default:
    }
    return null;
  };

  render() {
    const { all_briefs, editor_list } = this.props;
    const page = all_briefs.data && all_briefs.data.page;
    const totalPages = all_briefs.data && all_briefs.data.totalPages;
    const totalDocs = all_briefs.data && all_briefs.data.totalDocs;

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col md="3">
                    <Select
                      className="react-select filter"
                      classNamePrefix="react-select"
                      name="assign_to_editor"
                      placeholder="Select editor"
                      options={editor_list}
                      value={editor_list.filter(
                        ({ value }) =>
                          value === this.state.params.assign_to_editor
                      )}
                      size="lg"
                      onChange={value =>
                        this._handleOnFilter("assign_to_editor", value.value)
                      }
                    />
                  </Col>

                  <Col md="6">
                    <Input
                      type="text"
                      name="search"
                      // value={this.state.params.employeeValue}
                      placeholder={"Search for Title"}
                      onChange={e =>
                        this._handleOnChange("search", e.target.value)
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Button
                      name="btn-search"
                      color="warning"
                      type="button"
                      onClick={() => this._handleOnClick()}
                    >
                      {"Search"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {all_briefs.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th>Editor</th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("start_date")}
                        >
                          Writer accepts on
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("dead_line")}
                        >
                          Deadline
                        </th>
                        <th scope="col">Status</th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {all_briefs.data
                        ? all_briefs.data.docs.map((prop, key) => {
                            return (
                              <>
                                <tr
                                  key={key}
                                  onClick={e =>
                                    this._handleOnCellClick(e, prop.id)
                                  }
                                  className="cursor-pointer"
                                >
                                  <th scope="row">
                                    {this.limitTitle(
                                      ReactHtmlParser(prop.angle_title)
                                    )}
                                  </th>
                                  <td className="table-user">
                                    <Avatar
                                      src={prop.editor && prop.editor.avatar}
                                      className="d-inline-block mr-3"
                                    />
                                    <b>{prop.editor ? prop.editor.name : ""}</b>
                                  </td>
                                  <td>{formatStringToDate(prop.start_date)}</td>
                                  <td>{formatStringToDate(prop.dead_line)}</td>
                                  <td>
                                    {this._renderStatus(prop.status_article)}
                                  </td>
                                  <td>
                                    {prop
                                      ? prop.topic.map(topic => {
                                          return (
                                            <>
                                              <div className="mt-2  _topic">
                                                {this._text_truncate(
                                                  topic.name
                                                    ? topic.name
                                                    : topic.label,
                                                  20,
                                                  "..."
                                                )}
                                              </div>
                                            </>
                                          );
                                        })
                                      : ""}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                )}

                {all_briefs.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    all_briefs: state.editorArticleReducer.all_briefs,
    editor_list: state.postReducer.editor_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllBriefs: params => {
      dispatch(actGetAllBriefsRequest(params));
    },
    getAllEditor: () => {
      dispatch(actGetEditorListRequest());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllBriefsTable);
AllBriefsTable.propTypes = {
  all_briefs: PropTypes.object,
  editor_list: PropTypes.object,
  getAllBriefs: PropTypes.func,
  getAllEditor: PropTypes.func
};
AllBriefsTable.defaultProps = {};
