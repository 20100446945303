import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import WriterAvailablePitchesTable from "../../../components/Tables/WriterAvailablePitchesTable";
class WriterAvailablePitches extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeader
          name="My Pitches"
          content="myPitches"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <WriterAvailablePitchesTable {...props} />
        </Container>
      </>
    );
  }
}
WriterAvailablePitches.propTypes = {
  content: PropTypes.string,
};
export default WriterAvailablePitches;
