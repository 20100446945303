import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import AdjustmentList from "./lists/AdjustmentList";

class SideList extends Component {
  state = {};

  renderSideList = () => {
    const props = this.props;
    const pathname = window.location.pathname.split("/")[2];
    switch (pathname) {
      case "tuychinh":
        return <AdjustmentList {...props} />;
      default:
    }
  };

  render() {
    return (
      <div className={"sidelist " + (this.props.isShow ? "show" : "")}>
        <PerfectScrollbar>{this.renderSideList()}</PerfectScrollbar>
      </div>
    );
  }
}

export default SideList;
SideList.propTypes = {};
SideList.defaultProps = {};
