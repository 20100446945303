import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import RejectAssignedTable from "../../../components/Tables/RejectAssignedTable";
class EditorRejectAssigned extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Reject Assigned"
          content="rejectAssigned"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <RejectAssignedTable {...props} />
        </Container>
      </>
    );
  }
}
EditorRejectAssigned.propTypes = {
  content: PropTypes.string,
};
export default EditorRejectAssigned;
