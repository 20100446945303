import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { Popover, Button, Skeleton, Modal, Tooltip, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import get from "lodash/get";

import {
  actGetSuggestedTags,
  actResetStateTagManagement,
  actDeleteSelectedTag,
  actAddNewTag,
  actGetArticlesTags,
  actGetAllTagsInEachGroup,
  actUpdateTag,
  actConvertToOT,
  actGetTotalTag
} from "../../../actions/tagmanagement.action";

import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
import HeaderSearchBar from "../../../components/HeaderSearchBar";
import GroupTag from "../../../components/GroupTag";
import Avatar from "../../../components/Avatar/Avatar";

import option from "../../../assets/img/icons/option.svg";
import exclamationMarkIcon from "../../../assets/img/icons/exclamation-outline.svg";

import "./styles.scss";

const { Option } = Select;

function ChiefOfficialTag({ history }) {
  const useAsyncState = initialValue => {
    const [value, setValue] = useState(initialValue);
    const setter = x =>
      new Promise(resolve => {
        setValue(x);
        resolve(x);
      });
    return [value, setter];
  };

  const dispatch = useDispatch();

  const tags = useSelector(state => state.tagManagementReducer.tags);

  const allTagsInEachGroup = useSelector(
    state => state.tagManagementReducer.allTagsInEachGroup
  );

  const isLoading = useSelector(state => state.tagManagementReducer.isLoading);

  const tagSearched = useSelector(
    state => state.tagManagementReducer.tagSearched
  );

  const nameOfFetcher = useSelector(
    state => state.tagManagementReducer.nameOfFetcher
  );

  const relatedTags = useSelector(
    state => state.tagManagementReducer.relatedTags
  );

  const articlesTags = useSelector(
    state => state.tagManagementReducer.articlesTags
  );

  const createObjData = {};
  const tableHeaderStaticData = [
    "Tag",
    "#Article",
    "Created by",
    "Created Date",
  ];
  const tableGroupNameStaticData = [
    "Series",
    "Brand",
    "Topics",
    "Need Review",
    "New Tags"
  ];

  const [separateEachCurrentPage, setSeparateEachCurrentPage] = useState({});
  const relatedTagsToUnlink = [];
  const [languageState, setLanguageState] = useState("VN");
  const [visiblePopover, setVisibilityPopover] = useState(false);
  const [popOverUniqueVisibility, setPopoverUniqueVisibility] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalMultipleContentState, setModalMultipleContentState] = useState();
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
  const [modalInputField, setModalInputField] = useState("");
  const [multileDispatchers, setMultipleDispatchers] = useState();
  const [utilityState, setUtilityState] = useState({});
  const [modalFooterChangeButton, setModalFooterChangeButton] = useState(true);
  const [seeMoreContentUtility, setSeeMoreContentUtility] = useState(1);
  const [articlesTagsLocalData, setArticlesTagsLocalData] = useState([]);
  const [groupMainTitleForSelect, setGroupMainTitleForSelect] = useState([]);
  const [placeHolderSelectorState, setPlaceHolderSelectorState] = useState("");
  const [allowedToDelete, setAllowToDelete] = useState(false);
  const [
    allTagDataFilterGroupTitle,
    setTagDataFilterGroupTitle
  ] = useAsyncState([]);
  const [
    modalInputSelectorDefaultValue,
    setModalInputSelectorDefaultValue
  ] = useAsyncState();
  const [modalInputSelector, setModalInputSelector] = useAsyncState("");

  useEffect(() => {
    for (let i = 0; i < tableGroupNameStaticData.length; i += 1) {
      setSeparateEachCurrentPage(state => ({
        ...state,
        [tableGroupNameStaticData[i]]: 1
      }));
    }

    setArticlesTagsLocalData([]);
    dispatch(actGetTotalTag());
    getSuggestTagsManagement(languageState);

    return () => {
      dispatch(actGetTotalTag());
      dispatch(actResetStateTagManagement());
      setArticlesTagsLocalData([]);
    };
  }, []);

  useEffect(() => {
    if (get(articlesTags, "tags")) {
      setArticlesTagsLocalData(state => [...state, ...articlesTags.tags]);
    }
  }, [articlesTags]);

  for (let i = 0; i < tags.length; i += 1) {
    if (get(tags[i], "[tags][0][groupMainTitle]")) {
      createObjData[tags[i]["tags"][0]["groupMainTitle"]] = [];
    }
  }

  useEffect(() => {
    dispatch(
      actGetSuggestedTags({
        groupMainTitle: utilityState.name,
        language: languageState,
        page: separateEachCurrentPage[utilityState.name],
        allowedToWipeData: false
      })
    );
  }, [separateEachCurrentPage]);

  useEffect(() => {
    if (get(allTagsInEachGroup, "tags.suggestTag")) {
      const localData = allTagsInEachGroup.tags.suggestTag
        .filter(filterItem => {
          if (filterItem.name === modalInputField) {
            return true;
          }

          return false;
        })
        .map(mapItem => ({ [mapItem.name]: mapItem.groupMainTitle }));

      setTagDataFilterGroupTitle(localData).then(item =>
        setTagDataFilterGroupTitle(item)
      );
    }
  }, [allTagsInEachGroup, modalMultipleContentState]);

  useEffect(() => {
    if (get(allTagDataFilterGroupTitle, `[0][${modalInputField}]`)) {
      setModalInputSelectorDefaultValue(
        allTagDataFilterGroupTitle[0][modalInputField]
      ).then(item => setModalInputSelectorDefaultValue(item));

      setModalInputSelector(
        allTagDataFilterGroupTitle[0][modalInputField]
      ).then(item => setModalInputSelector(item));
    }

    if (modalMultipleContentState === "convertToOfficialTag") {
      setModalInputSelectorDefaultValue();
    }
  }, [allTagDataFilterGroupTitle, modalMultipleContentState]);

  useEffect(() => {
    for (let i = 0; i < tags.length; i += 1) {
      if (get(tags[i], "[tags][0][groupMainTitle]")) {
        if (tags[i]["isNextpaging"]) {
        } else {
        }

        createObjData[tags[i]["tags"][0]["groupMainTitle"]].push(
          ...tags[i]["tags"]
        );
      }
    }
  }, [tags]);

  for (let i = 0; i < tags.length; i += 1) {
    if (get(tags[i], "[tags][0][groupMainTitle]")) {
      createObjData[tags[i]["tags"][0]["groupMainTitle"]].push(
        ...tags[i]["tags"]
      );
    }
  }

  if (get(relatedTags, "tags")) {
    relatedTags.tags.map(item => relatedTagsToUnlink.push(item));
  }

  const getSuggestTagsManagement = language => {
    for (let i = 0; i < tableGroupNameStaticData.length; i += 1) {
      dispatch(
        actGetSuggestedTags({
          groupMainTitle: tableGroupNameStaticData[i],
          language,
          page: 1,
          allowedToWipeData: true
        })
      );
    }
  };

  const onModalInputFieldChange = e => {
    setModalInputField(e.target.value);
  };

  const onAddNewSuggestTag = () => {
    setPlaceHolderSelectorState("Select Group Tag");
    setGroupMainTitleForSelect(["Need Review", "New Tags"]);
    setModalInputSelectorDefaultValue();
    setModalTitle("Add New Suggest Tag");
    setMultipleDispatchers("onAddNewSuggestTag");
    setModalMultipleContentState("onAddNewSuggestTag");
    setModalVisibility(true);
    setModalFooterChangeButton(true);
  };

  const onFetchNextPageEachDispatcher = name => {
    setUtilityState({ name });
    setSeparateEachCurrentPage(state => ({
      ...state,
      ...(separateEachCurrentPage[name] += 1)
    }));
  };

  const onEditSelectedTag = (id, name) => {
    setPlaceHolderSelectorState("Select Group Tag");
    dispatch(
      actGetAllTagsInEachGroup({
        endpoint: "v2/list-tag?type[]=SUGGESTED_TAG"
      })
    );

    setGroupMainTitleForSelect(["Need Review", "New Tags"]);

    setTimeout(() => {
      setModalInputField(name);
      setUtilityState({ id });
      setModalTitle("Edit Suggest Tag");
      setMultipleDispatchers("onEditSelectedTag");
      setModalMultipleContentState("onEditSelectedTag");
      setModalVisibility(true);
      setVisibilityPopover(false);
      setModalFooterChangeButton(true);

      if (get(allTagDataFilterGroupTitle, `[0][${name}]`)) {
        setModalInputSelectorDefaultValue(allTagDataFilterGroupTitle[0][name]);
        setModalInputSelector(allTagDataFilterGroupTitle[0][name]);
      }
    }, 600);
  };

  const onDeleteSelectedTag = (name, id) => {
    setAllowToDelete(true);
    setModalTitle("Do you want to delete?");
    setMultipleDispatchers("onDeleteSelectedTag");
    setModalMultipleContentState("onDeleteSelectedTag");
    setModalVisibility(true);
    setModalFooterChangeButton(false);
    setVisibilityPopover(false);
    setUtilityState({ id, name });
  };

  const convertToOfficialTag = (id, name) => {
    setPlaceHolderSelectorState("Select Group Tag To Official Tag");
    setModalInputSelector("");
    setModalInputSelectorDefaultValue();

    setTimeout(() => {
      setModalInputField(name);
      setGroupMainTitleForSelect(["Series", "Brand", "Topics"]);
      setModalTitle("Tag will be converted to Official Tag");
      dispatch(actGetTotalTag());
      setMultipleDispatchers("convertToOfficialTag");
      setModalMultipleContentState("convertToOfficialTag");
      setModalVisibility(true);
      setModalFooterChangeButton(true);
      setUtilityState({ id });
    });
  };

  const visiblePopoverHandler = (value, display) => {
    setPopoverUniqueVisibility(value);
    setVisibilityPopover(display);
  };

  const onChangeLanguageProp = value => {
    setLanguageState(value);
    dispatch(actResetStateTagManagement());
    getSuggestTagsManagement(value);
  };

  useEffect(() => {
    if (get(utilityState, "getNumberOfArticle")) {
      dispatch(
        actGetArticlesTags({ id: utilityState.id, page: seeMoreContentUtility })
      );
    }
  }, [seeMoreContentUtility]);

  const onGetNumberOfArticle = value => {
    setUtilityState({ id: value, getNumberOfArticle: true });
    setTimeout(() => {
      setModalMultipleContentState("onGetNumberOfArticle");
      dispatch(actGetArticlesTags({ id: value, page: seeMoreContentUtility }));
      setModalVisibility(true);
      setModalTitle("Articles Tags");
      setModalFooterChangeButton(false);
    }, 300);
  };

  const renderGroupTag = () => {
    let cloneLocalData;

    if (createObjData) {
      cloneLocalData = tableGroupNameStaticData.map(
        item => createObjData[item]
      );
    }

    return (
      <>
        {cloneLocalData.length > 0 &&
          cloneLocalData.map(item => (
            <>
              <GroupTag
                groupTitle={get(item, '[0]["groupMainTitle"]')}
                groupContent={
                  item &&
                  item.map(subItem => (
                    <>
                      <tr
                        className={
                          item[0]["groupMainTitle"]
                            .replace(/\s+/g, "-")
                            .toLowerCase() + "-content"
                        }
                        key={subItem.id}
                      >
                        <td>{subItem.name}</td>
                        <td
                          onClick={
                            get(subItem, "totalOfArticle", "0") > 0
                              ? () => onGetNumberOfArticle(subItem.id)
                              : () => {}
                          }
                          className="text-center d-table-cell btn btn-link"
                        >
                          {get(subItem, "totalOfArticle", "0")}
                        </td>
                        <td>
                          <section className="d-flex justify-content-center align-items-center">
                            <Avatar />
                            <p className="pl-2 h3">
                              {get(subItem, "createdBy.penname")}
                            </p>
                          </section>
                        </td>
                        <td>
                          {moment(subItem.createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          <Popover
                            visible={
                              popOverUniqueVisibility === subItem.id &&
                              visiblePopover
                            }
                            onVisibleChange={value =>
                              visiblePopoverHandler(subItem.id, value)
                            }
                            content={
                              <section className="d-flex align-items-start flex-column">
                                <Button
                                  onClick={() =>
                                    onEditSelectedTag(subItem.id, subItem.name)
                                  }
                                  className="mr-5 pl-0 border-0 shadow-none"
                                  type="primary"
                                  ghost
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() =>
                                    onDeleteSelectedTag(
                                      subItem.name,
                                      subItem.id
                                    )
                                  }
                                  className="mt-2 pl-0 mr-5 border-0 shadow-none"
                                  type="danger"
                                  ghost
                                >
                                  Delete
                                </Button>
                              </section>
                            }
                            trigger="click"
                            placement="bottomRight"
                            arrowPointAtCenter
                          >
                            <Button type="link">
                              <img
                                className="action-btn"
                                src={option}
                                alt="more"
                              />
                            </Button>
                          </Popover>
                        </td>
                        <td className="italic-text">
                          Last update{" "}
                          {moment(subItem.updatedAt).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {get(subItem, "totalOfArticle", "0") < 1 ? (
                            <Tooltip
                              placement="bottom"
                              title={
                                "Number of #Article needs to be 1 or Greater to Enabled Convert"
                              }
                              arrowPointAtCenter
                            >
                              <Button disabled>Convert to OT</Button>
                            </Tooltip>
                          ) : (
                            <Button
                              className="green-border-2DCE89 green-color-2DCE89"
                              ghost
                              type="primary"
                              onClick={() =>
                                convertToOfficialTag(subItem.id, subItem.name)
                              }
                            >
                              Convert to OT
                            </Button>
                          )}
                        </td>
                      </tr>
                    </>
                  ))
                }
              />

              <Button
                name={get(item, '[0]["groupMainTitle"]')}
                className={`
                  ${get(item, '[0]["groupMainTitle"]') &&
                    item[0]["groupMainTitle"]
                      .replace(/\s+/g, "-")
                      .toLowerCase() + "-content"}

                  ${nameOfFetcher.includes(
                    get(item, '[0]["groupMainTitle"]')
                  ) && "d-none"}

                  ${!get(item, '[0]["groupMainTitle"]') && "d-none"}
                `}
                onClick={() => {
                  onFetchNextPageEachDispatcher(
                    get(item, '[0]["groupMainTitle"]')
                  );
                }}
              >
                See More...
              </Button>
            </>
          ))}
      </>
    );
  };

  const onUpdateSelectedTag = () => {
    setModalConfirmLoading(true);

    dispatch(
      actUpdateTag({
        endpoint: "v2/tags/suggest",
        dispatchData: {
          tagId: utilityState.id,
          language: languageState,
          groupMainTitle: modalInputSelector,
          name: modalInputField
        }
      })
    );

    dispatch(actResetStateTagManagement());

    setTimeout(() => {
      setModalVisibility(false);
      setModalConfirmLoading(false);
      dispatch(actResetStateTagManagement());
      getSuggestTagsManagement(languageState);
      modalCloseWipeData();
    }, 2500);
  };

  const AddNewTagModalOnSave = () => {
    setModalConfirmLoading(true);

    dispatch(
      actAddNewTag({
        data: {
          name: modalInputField,
          groupMainTitle: modalInputSelector,
          language: languageState
        },
        endpoint: "v2/tags/suggest"
      })
    );

    dispatch(actResetStateTagManagement());

    setTimeout(() => {
      setModalVisibility(false);
      setModalConfirmLoading(false);
      dispatch(actResetStateTagManagement());
      getSuggestTagsManagement(languageState);
      modalCloseWipeData();
    }, 2500);
  };

  const deleteSelectedTagDispatcher = () => {
    setModalConfirmLoading(true);
    dispatch(
      actDeleteSelectedTag({
        id: utilityState.id,
        endpoint: "v2/remove-tag"
      })
    );
    dispatch(actResetStateTagManagement());

    setTimeout(() => {
      setModalVisibility(false);
      setModalConfirmLoading(false);
      dispatch(actResetStateTagManagement());
      getSuggestTagsManagement(languageState);
    }, 2500);
  };

  const convertToOfficialTagDispatcher = () => {
    setModalConfirmLoading(true);
    
    dispatch(
      actConvertToOT({
        tagId: utilityState.id,
        name: modalInputField,
        groupMainTitle: modalInputSelector,
        language: languageState,
        relatedTags: []
      })
    );
    dispatch(actResetStateTagManagement());

    setTimeout(() => {
      setModalVisibility(false);
      setModalConfirmLoading(false);
      getSuggestTagsManagement(languageState);
    }, 2500);
  };

  const modalArticleTags = () => {
    return (
      <>
        <table className="w-100" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              {["Article", "Author", "Added on"].map(item => (
                <th
                  key={item}
                  className={
                    item === "Added on" || (item === "Author" && "text-center")
                  }
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {articlesTagsLocalData &&
              articlesTagsLocalData.map(item => (
                <tr key={item}>
                  <td
                    onClick={() => {
                      history.push({
                        pathname: `/admin/chief/un-published-review/${item._id}`,
                        state: {}
                      });
                    }}
                    className="btn-link pr-4 cursor-pointer d-inline-block mt-4"
                    style={{ wordWrap: "break-word" }}
                  >
                    {item.title}
                  </td>
                  <td className="p-3">
                    <section
                      className="d-flex align-items-center w-75 space-between h4 justify-content-around"
                      style={{ wordWrap: "break-word" }}
                    >
                      <Avatar className="" src={get(item, "writer.avatar")} />
                      {get(item, "writer.penname")}
                    </section>
                  </td>
                  <td className="italic-text">
                    {moment(item.createdAt).format("DD/MM/YYYY")}
                  </td>
                </tr>
              ))}
            {get(articlesTags, "tags") && articlesTags.isNextpaging && (
              <Button
                onClick={() =>
                  setSeeMoreContentUtility(seeMoreContentUtility + 1)
                }
              >
                See More...
              </Button>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const modalInputFieldUI = () => {
    return (
      <>
        <form>
          <label className="d-flex">Tag Name (<div className="text-danger">*</div>)</label>
          <input
            className="ant-input mb-3"
            onChange={onModalInputFieldChange}
            value={modalInputField}
          />

          <div className="d-flex">
            <label className="m-0 d-flex align-items-center">Group Tag (<div className="text-danger">*</div>)</label>
            <Tooltip
              placement="bottom"
              title={
                "Choose the group that your tag belongs to. This will help you with finding and managing tags on CMS."
              }
              trigger="hover"
            >
              <Button type="link">
                <img src={exclamationMarkIcon} alt="exclamation mark" />
              </Button>
            </Tooltip>
          </div>

          {groupMainTitleForSelect.length > 0 && (
            <Select
              defaultValue={modalInputSelectorDefaultValue}
              showSearch
              className="mb-3 w-100"
              placeholder={placeHolderSelectorState}
              optionFilterProp="children"
              onChange={value => setModalInputSelector(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {groupMainTitleForSelect.map(item => (
                <Option className="my-2" key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          )}

          <p className="h5 text-danger">(*) input fields are required!</p>
        </form>
      </>
    );
  };

  const modalMultipleContents = () => {
    switch (modalMultipleContentState) {
      case "onAddNewSuggestTag":
        return modalInputFieldUI();

      case "onDeleteSelectedTag":
        return <p className="h2">{utilityState.name}</p>;

      case "convertToOfficialTag":
        return modalInputFieldUI();

      case "onGetNumberOfArticle":
        return modalArticleTags();

      case "onEditSelectedTag":
        return modalInputFieldUI();

      default:
        return () => {};
    }
  };

  const onSaveModal = () => {
    switch (multileDispatchers) {
      case "onAddNewSuggestTag":
        return () => AddNewTagModalOnSave();

      case "onDeleteSelectedTag":
        return () => deleteSelectedTagDispatcher();

      case "onEditSelectedTag":
        return () => onUpdateSelectedTag();

      case "convertToOfficialTag":
        return () => convertToOfficialTagDispatcher();

      default:
        return () => {};
    }
  };

  const modalCloseWipeData = () => {
    setAllowToDelete(false);
    setModalTitle();
    setModalInputField("");
    setModalMultipleContentState();
    setModalInputSelectorDefaultValue();
    setModalInputSelector("");
    setModalVisibility();
    setSeeMoreContentUtility(1);
    setArticlesTagsLocalData([]);
    setUtilityState({});
    setGroupMainTitleForSelect([]);
  };

  return (
    <>
      <Modal
        width={720}
        title={modalTitle}
        visible={modalVisibility}
        confirmLoading={modalConfirmLoading}
        onOk={onSaveModal()}
        onCancel={() => modalCloseWipeData()}
        footer={
          modalFooterChangeButton ? (
            <>
              <Button onClick={() => modalCloseWipeData()}>Cancel</Button>
              <Button
                disabled={modalInputField.length > 0 && modalInputSelector.length > 0 ? false : true}
                onClick={onSaveModal()}
                loading={modalConfirmLoading}
                type="primary"
              >
                Save
              </Button>
            </>
          ) : allowedToDelete && <>
            <Button onClick={() => modalCloseWipeData()}>Cancel</Button>
            <Button
              onClick={onSaveModal()}
              loading={modalConfirmLoading}
              type="primary"
            >
              Save
            </Button>
          </>
        }
      >
        {modalMultipleContents()}
      </Modal>
      <section className="chief-official-tag-container">
        <CardsHeaderChief
          name="Suggest Tags"
          content="suggest tags"
          parentName="Tags"
        />
        <Container className="mt--6" fluid>
          <HeaderSearchBar
            mainButtonTitle={"Add Suggest Tag"}
            defaultLanguage={languageState}
            onAddNewTag={onAddNewSuggestTag}
            onChangeLanguageProp={onChangeLanguageProp}
            typeOfTags={"SUGGESTED_TAG"}
          />
          <Row className="rounded-custom-bottom px-5 py-3 position-relative bg-white">
            <table className="w-100">
              <thead>
                <tr>
                  {tableHeaderStaticData.map(item => (
                    <th key={item}>{item}</th>
                  ))}
                </tr>
              </thead>
              {isLoading ? (
                <>
                  <tbody>
                    <tr>
                      <td colSpan={tableHeaderStaticData.length}>
                        <Skeleton active />
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <>
                  <tbody>
                    {tagSearched && get(tagSearched, "[0].data.tags") ? (
                      tagSearched.map(item => (
                        <>
                          {item.data.tags.length >= 1 && (
                            <GroupTag
                              key={item.id}
                              groupTitle={
                                item.data.tags.length >= 1 &&
                                item.data.tags[0]["groupMainTitle"]
                              }
                              groupBeingUsed={item.totalDocs}
                              groupContent={item.data.tags.map(subItem => (
                                <tr
                                  className={
                                    item.data.tags[0].groupMainTitle
                                      .replace(/\s+/g, "-")
                                      .toLowerCase() + "-content"
                                  }
                                  key={subItem._id}
                                >
                                  <td>{subItem.name}</td>
                                  <td
                                    onClick={
                                      get(subItem, "totalOfArticle", "0") > 0
                                        ? () =>
                                            onGetNumberOfArticle(subItem._id)
                                        : () => {}
                                    }
                                    className="text-center d-table-cell btn btn-link"
                                  >
                                    {get(subItem, "totalOfArticle", "0")}
                                  </td>
                                  <td>
                                    <section className="d-flex justify-content-center align-items-center">
                                      <Avatar />
                                      <p className="pl-2 h3">
                                        {get(subItem, "createdBy.penname")}
                                      </p>
                                    </section>
                                  </td>
                                  <td>
                                    {moment(subItem.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <Popover
                                      visible={
                                        popOverUniqueVisibility ===
                                          subItem._id && visiblePopover
                                      }
                                      onVisibleChange={value =>
                                        visiblePopoverHandler(
                                          subItem._id,
                                          value
                                        )
                                      }
                                      content={
                                        <section className="d-flex align-items-start flex-column">
                                          <Button
                                            onClick={() =>
                                              onEditSelectedTag(
                                                subItem._id,
                                                subItem.name
                                              )
                                            }
                                            className="mr-5 pl-0 border-0 shadow-none"
                                            type="primary"
                                            ghost
                                          >
                                            Edit
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              onDeleteSelectedTag(
                                                subItem.name,
                                                subItem._id
                                              )
                                            }
                                            className="mt-2 pl-0 mr-5 border-0 shadow-none"
                                            type="danger"
                                            ghost
                                          >
                                            Delete
                                          </Button>
                                        </section>
                                      }
                                      trigger="click"
                                      placement="bottomRight"
                                      arrowPointAtCenter
                                    >
                                      <Button type="link">
                                        <img
                                          className="action-btn"
                                          src={option}
                                          alt="more"
                                        />
                                      </Button>
                                    </Popover>
                                  </td>
                                  <td className="italic-text">
                                    Last update{" "}
                                    {moment(subItem.updatedAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {get(subItem, "totalOfArticle", "0") < 1 ? (
                                      <Tooltip
                                        placement="bottom"
                                        title={
                                          "Number of #Article needs to be 1 or Greater to Enabled Convert"
                                        }
                                        arrowPointAtCenter
                                      >
                                        <Button disabled>Convert to OT</Button>
                                      </Tooltip>
                                    ) : (
                                      <Button
                                        className="green-border-2DCE89 green-color-2DCE89"
                                        ghost
                                        type="primary"
                                        onClick={() =>
                                          convertToOfficialTag(
                                            subItem.id,
                                            subItem.name
                                          )
                                        }
                                      >
                                        Convert to OT
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            />
                          )}
                        </>
                      ))
                    ) : tags ? (
                      renderGroupTag()
                    ) : (
                      <Spin />
                    )}
                  </tbody>
                </>
              )}
            </table>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ChiefOfficialTag;
