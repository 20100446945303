import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
import ChiefAvailablePitchesTable from "../../../components/Tables/ChiefAvailablePitchesTable";
class ChiefAvailablePitch extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderChief
          name="Available Pitches"
          content="pitchesAvailable"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <ChiefAvailablePitchesTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefAvailablePitch.propTypes = {
  content: PropTypes.string,
};
export default ChiefAvailablePitch;
