import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actChangeNewPasswordRequest } from "../../actions/authentication.action";
import { NavLink } from "react-router-dom";
class ChangeNewPassword extends Component {
  state = {
    email: "",
    code: "",
    passwordnew: ""
  };
  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleOnSubmit = () => {
    const email = localStorage.getItem("email");
    const code = this.state.code;
    const passwordnew = this.state.passwordnew;
    this.toogleLoading();
    this.setTimeoutLoading();
    if (!code || !passwordnew) {
      this.setState({
        message: "* Code cannot be blank!",
        message1: "* New Password cannot be blank!"
      });
      this.toogleLoading();
    } else {
      const data = {
        email,
        code,
        passwordnew
      };
      this.props.changeNewPassword(data);
    }
  };
  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleOnSubmit();
    }
  };
  toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  setTimeoutLoading = () => {
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 1500);
  };
  render() {
    let mail = localStorage.getItem("email");
    return (
      <>
        <AuthHeader title="" />
        <Container className="updatePassword">
          <Row className="justify-content-end">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-muted text-center">
                    <small>FORGOT PASSWORD</small>
                  </div>
                </CardHeader>
                <CardBody className="px-5">
                  <Form role="form">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="email"
                          placeholder="Email"
                          type="text"
                          onChange={this.handleOnChange}
                          onKeyDown={this.onKeyDown}
                          value={mail}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="code"
                          placeholder="Code"
                          type="text"
                          onChange={this.handleOnChange}
                          onKeyDown={this.onKeyDown}
                          // value={this.state.number}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-danger mt-3 mb-3">
                      {this.state.message}
                    </div>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="passwordnew"
                          placeholder="New Password"
                          type="password"
                          onChange={this.handleOnChange}
                          onKeyDown={this.onKeyDown}
                          // value={this.state.number}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-danger mt-3 mb-3">
                      {this.state.message1}
                    </div>
                    <div className="text-center">
                      <NavLink to="/admin/forgotPassword">
                        <span
                          style={{
                            color: "#5e72e4",
                            marginRight: "40px",
                            cursor: "pointer",
                            fontSize: "15px"
                          }}
                        >
                          Back
                        </span>
                      </NavLink>
                      <Button
                        className="my-4"
                        onClick={this.handleOnSubmit}
                        type="button"
                        isLoading={this.state.isLoading}
                        style={{
                          backgroundColor: "#5e72e4",
                          borderColor: "#5e72e4",
                          color: "#ffffff"
                        }}
                      >
                        Send
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    // lang: state.i18nState.lang
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeNewPassword: data => {
      dispatch(actChangeNewPasswordRequest(data));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeNewPassword);
ChangeNewPassword.propTypes = {
  changeNewPassword: PropTypes.func
};
