import React from "react";
import {  DatePicker } from "antd";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";


const FilterDateRanger = function ({onChange}) {
  return (
    <>
       <RangePicker  onChange={onChange} format={dateFormat} />
      </>
  )
}

export default FilterDateRanger;
