import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Table,
  // Button,
  Col,
  Row,
  CardFooter
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatStringToDate } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import { actGetChiefOutlineInProgressListRequest } from "../../actions/chiefArticle.action";
import Avatar from "../Avatar/Avatar";
import { Tooltip } from "antd";
import ReactHtmlParser from "react-html-parser";
// import { Link } from "react-router-dom";
class ChiefOutlineProgressTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getChiefOutlineInProgress();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getChiefOutlineInProgress = () => {
    const params = this.state.params;
    this.props.getChiefOutlineInProgress(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getChiefOutlineInProgress(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getChiefOutlineInProgress(paramsSort);
  };

  _renderStatus = status => {
    switch (status) {
      case "OUTLINE_SUBMITTED":
        return (
          <>
            <div>
              <span className="status-waiting">●</span>
              <span>Waiting for Approval</span>
            </div>
          </>
        );
      case "OUTLINE_WRITING":
        return (
          <>
            <div>
              <span className="status-new">●</span>
              <span>Needs Writing</span>
            </div>
          </>
        );
      default:
    }
    return null;
  };
  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/chief/outline-content/${id}`);
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 50) {
      return `${str[0].substring(0, 50)}...`;
    } else {
      return str[0].substring(0, 50);
    }
  };
  render() {
    const { outline_in_progress_list } = this.props;
    const page =
      outline_in_progress_list.data && outline_in_progress_list.data.page;
    const totalPages =
      outline_in_progress_list.data && outline_in_progress_list.data.totalPages;
    const totalDocs =
      outline_in_progress_list.data && outline_in_progress_list.data.totalDocs;

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Outline in Progress List</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {outline_in_progress_list.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th className="text-center">Dual Language</th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("writer")}
                        >
                          Writer
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("editor")}
                        >
                          Editor
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("start_date")}
                        >
                          Writer Accepts On
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("dead_line")}
                        >
                          Deadline
                        </th>

                        <th
                          className="sort"
                          // data-sort="status"
                          scope="col"
                          // onClick={this._sortByStatus}
                        >
                          Status
                        </th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {outline_in_progress_list.data
                        ? outline_in_progress_list.data.docs.map(
                            (prop, key) => {
                              return (
                                <tr
                                  key={key}
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    this._handleOnCellClick(e, prop.id)
                                  }
                                >
                                  <th scope="row">
                                    <Tooltip
                                      title={ReactHtmlParser(prop.angle_title)}
                                      placement="bottom"
                                    >
                                      <span>
                                        {this.limitTitle(
                                          ReactHtmlParser(prop.angle_title)
                                        )}
                                      </span>
                                    </Tooltip>
                                  </th>
                                  <td className="text-center text-success">
                                    {prop.dualLanguage_article !== null &&
                                      prop.dualLanguage_article && (
                                        <i
                                          className="fa fa-check-circle"
                                          style={{ fontSize: 16 }}
                                        ></i>
                                      )}
                                  </td>
                                  <td className="table-user">
                                    <Avatar
                                      src={prop.writer.avatar}
                                      className="d-inline-block mr-3"
                                    />
                                    <b>{prop.writer ? prop.writer.name : ""}</b>
                                  </td>
                                  <td className="table-user">
                                    {prop.editor && (
                                      <>
                                        <Avatar
                                          src={prop.editor.avatar}
                                          className="d-inline-block mr-3"
                                        />
                                        <b>{prop.editor.name}</b>
                                      </>
                                    )}
                                  </td>
                                  <td className="budget">
                                    {formatStringToDate(prop.start_date)}
                                  </td>
                                  <td>{formatStringToDate(prop.dead_line)}</td>
                                  <td>
                                    {this._renderStatus(prop.status_article)}
                                  </td>
                                  <td>
                                    {prop
                                      ? prop.topic.map(topic => {
                                          return (
                                            <>
                                              <div className="_topic">
                                                <label>
                                                  {this._text_truncate(
                                                    topic.name
                                                      ? topic.name
                                                      : topic.label,
                                                    20,
                                                    "..."
                                                  )}
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })
                                      : ""}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        : ""}
                    </tbody>
                  </Table>
                )}

                {outline_in_progress_list.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    outline_in_progress_list: state.chiefArticleReducer.outline_in_progress_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getChiefOutlineInProgress: params => {
      dispatch(actGetChiefOutlineInProgressListRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefOutlineProgressTable);
ChiefOutlineProgressTable.propTypes = {
  outline_in_progress_list: PropTypes.object,
  getChiefOutlineInProgress: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};
ChiefOutlineProgressTable.defaultProps = {};
