import React from "react";
import { Container, Card, CardHeader } from "reactstrap";
// import EmployeeChart from "../../components/Charts/EmployeeChart";
// import DashboardChief from "../dashboards/DashboardChief";
// import DashboardEditor from "../dashboards/DashboardEditor";
import WriterSection from "../../components/Dashbroads/Writer";
import EditorSection from "../../components/Dashbroads/Editor";
import ArticleSection from "../../components/Dashbroads/Article";
import ReaderSection from "../../components/Dashbroads/Reader";
import { Row } from "antd";
import "./styles.scss";

class Dashboard extends React.Component {
  state = {
    pathNameDashBoard: window.location.pathname.split("/")[2],
  };
  render() {
    const pathNameDashBoard = this.state.pathNameDashBoard;
    return (
      <>
        <Container className="dashbroad-report" fluid>
          <Card>
            <CardHeader className="border-0 d-flex justify-content-between">
              <Row gutter={24}>
                <h1>Articles</h1>
                <ArticleSection />
                <h1 style={{ marginBottom: "40px", marginTop: "70px" }}>
                  Writers
                </h1>
                <WriterSection />
                {/*   <h1 style={{ marginBottom: "40px", marginTop: "70px"}}>Editors</h1>
                <EditorSection /> */}
                <h1 style={{ marginBottom: "40px", marginTop: "70px"}}>Reader</h1>
                <ReaderSection />
              </Row>
            </CardHeader>
          </Card>
        </Container>
      </>
    );
  }
}

export default Dashboard;
