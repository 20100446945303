import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Table,
  // Button,
  Col,
  Row,
  CardFooter,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatStringToDate } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import { actGetChiefEditingListRequest } from "../../actions/chiefArticle.action";
import Avatar from "../Avatar/Avatar";
import { Tooltip } from "antd";

class ChiefEditingTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1",
    },
    isLoading: false,
  };

  componentDidMount() {
    this._setLoading();
    this._getChiefEditing();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  _getChiefEditing = () => {
    const params = this.state.params;
    this.props.getChiefEditing(params);
  };

  _handleOnPageClick = (page) => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getChiefEditing(params);
  };

  _handlePathnamePush = (params) => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str && str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = (typeColumn) => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1,
        },
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1,
        },
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort,
    };
    this._handlePathnamePush(paramsSort);
    this.props.getChiefEditing(paramsSort);
  };
  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/chief/writer-writing/${id}`);
  };

  limitTitle = (str) => {
    if (!str) {
      return "";
    }
    if (str && str.length > 50) {
      return `${str.substring(0, 50)}...`;
    } else {
      return str.substring(0, 50);
    }
  };
  render() {
    const page =
      this.props.editing_list.data && this.props.editing_list.data.page;
    const totalPages =
      this.props.editing_list.data && this.props.editing_list.data.totalPages;
    const totalDocs =
      this.props.editing_list.data && this.props.editing_list.data.totalDocs;
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Editing List</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {this.props.editing_list.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th className="text-center">Dual Language</th>
                        <th
                          className="sort"
                          // data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("writer")}
                        >
                          Writer
                        </th>
                        <th
                          className="sort"
                          // data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("editor")}
                        >
                          Editor
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("start_date")}
                        >
                          Writer Accepts On
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("time_to_write")}
                        >
                          Time to Write
                        </th>
                        <th
                          className="sort"
                          // data-sort="status"
                          scope="col"
                          // onClick={this._sortByTime_to_write}
                        >
                          status
                        </th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {this.props.editing_list.data
                        ? this.props.editing_list.data.docs.map((prop, key) => {
                            return (
                              <tr
                                key={key}
                                className="cursor-pointer"
                                onClick={(e) =>
                                  this._handleOnCellClick(e, prop.id)
                                }
                              >
                                <th scope="row">
                                  <Tooltip
                                    title={prop.angle_title}
                                    placement="bottom"
                                  >
                                    <span>
                                      {this.limitTitle(prop.angle_title || "")}
                                    </span>
                                  </Tooltip>
                                </th>
                                <td className="text-center text-success">
                                  {prop.dualLanguage_article !== null &&
                                    prop.dualLanguage_article && (
                                      <i
                                        className="fa fa-check-circle"
                                        style={{ fontSize: 16 }}
                                      ></i>
                                    )}
                                </td>
                                <td className="table-user">
                                  <Avatar
                                    src={prop.writer && prop.writer.avatar}
                                    className="d-inline-block mr-3"
                                  />
                                  <b>{prop.writer ? prop.writer.name : ""}</b>
                                </td>
                                <td className="table-user">
                                  <Avatar
                                    src={prop.editor && prop.editor.avatar}
                                    className="d-inline-block mr-3"
                                  />
                                  <b>{prop.editor ? prop.editor.name : ""}</b>
                                </td>
                                <td className="budget">
                                  {formatStringToDate(prop.start_date)}
                                </td>
                                <td>{prop.time_to_write} days</td>
                                <td>
                                  {prop.status_article === "EDITING" ? (
                                    <div>
                                      <span className="status-new">●</span>
                                      <span>Needs Editing</span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  {prop
                                    ? prop.topic.map((topic) => {
                                        return (
                                          <>
                                            <div className="_topic">
                                              <label>
                                                {this._text_truncate(
                                                  topic.name
                                                    ? topic.name
                                                    : topic.label,
                                                  20,
                                                  "..."
                                                )}
                                              </label>
                                            </div>
                                          </>
                                        );
                                      })
                                    : ""}
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                )}

                {this.props.editing_list.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    editing_list: state.chiefArticleReducer.editing_list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getChiefEditing: (params) => {
      dispatch(actGetChiefEditingListRequest(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChiefEditingTable);
ChiefEditingTable.propTypes = {
  editing_list: PropTypes.object,
  getChiefEditing: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
ChiefEditingTable.defaultProps = {};
