import React from "react";
import {
  Container,
  Row,
  Col
  // Card,
  // CardHeader,
  // CardBody,
  // ListGroup,
  // ListGroupItem,
  // Table,
  // CardFooter
} from "reactstrap";
import CardsHeaderEditor from "components/Headers/CardsHeaderEditor.jsx";
import MyProfileModal from "../../components/Modals/MyProfileModal";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { actGetFinishedArticleEditorRequest } from "../../actions/editordashboard.action";
import { actGetTodayDeadlineListRequest } from "../../actions/editorArticle.action";
// import { countTimeDeadline } from "../../utils/formatDate";
import EditorOutlineDashboardTable from "../../components/Tables/EditorOutlineDashboardTable";
import EditorArticleDashboadTable from "../../components/Tables/EditorArticleDashboadTable";
import EditorPitchesDashboardTable from "../../components/Tables/EditorPitchesDashboardTable";
let calendar;

class DashboardEditor extends React.Component {
  state = {
    isModal: true
  };
  calendar;
  componentDidMount() {
    // this.props.getFinishedArticleEditor();
    // this._getEditorTodayDeadline();
    this._verifyRole();
  }

  _verifyRole = () => {
    const key = localStorage.getItem("key");
    key !== "editor" && this.props.history.push("/admin");
  };

  _getEditorTodayDeadline() {
    this.props.getEditorTodayDeadline();
  }

  _toggleModalNotify = () => {
    this.setState(prevState => ({
      isModal: !prevState.isModal
    }));
  };
  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };
  changeView = newView => {
    calendar.changeView(newView);
    this.setState({
      currentDate: calendar.view.title
    });
  };
  render() {
    const props = this.props;
    const isSkip = localStorage.getItem("is_skip");
    return (
      <>
        {isSkip === "false" ? (
          <MyProfileModal
            modal={this.state.isModal}
            toggle={this._toggleModalNotify}
          />
        ) : (
          ""
        )}

        <CardsHeaderEditor name="Dashboard" parentName="Dashboards" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xs="12">
              <EditorOutlineDashboardTable {...props} />
            </Col>
            <Col xs="12">
              <EditorArticleDashboadTable {...props} />
            </Col>
            <Col xs="12">
              <EditorPitchesDashboardTable {...props} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    finished_article: state.editorDashboardReducer.finished_article,
    editor_today_deadline: state.editorArticleReducer.editor_today_deadline
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getFinishedArticleEditor: data => {
      dispatch(actGetFinishedArticleEditorRequest(data));
    },
    getEditorTodayDeadline: data => {
      dispatch(actGetTodayDeadlineListRequest(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardEditor);
DashboardEditor.propTypes = {
  post_info: PropTypes.object,
  post_detail: PropTypes.object,
  topics_name: PropTypes.array,
  finished_article: PropTypes.object,
  editor_today_deadline: PropTypes.object,
  getFinishedArticleEditor: PropTypes.func,
  getEditorTodayDeadline: PropTypes.func
};
