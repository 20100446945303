import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
  // Row,
  // Col,
  // FormGroup,
  // CardText
} from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { actApproveContentRequest } from "../../actions/writerArticle.action";
// import { actApproveOutlineRequest } from "../../actions/editorArticle.action";
import { actSubmitPublishRequest } from "../../actions/chiefArticle.action";

class OutlineApproveModal extends Component {
  state = {};

  componentDidMount() {}

  // eslint-disable-next-line react/no-deprecated
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.mes_blacklist !== this.props.mes_blacklist) {
  //     this._setMesBlacklist(nextProps.mes_blacklist);
  //   }
  // }

  toggle = () => {
    this.props.toggle();
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  _handleOnApproveOutline = () => {
    const id = this.props.id;
    const data = this.props.data;
    this.props.submitPublish(id, data);
    this.props.history.push("/admin/chief/published-article");
    this.toggle();
  };

  render() {
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.props.toggle}>
            Are you sure to publish this article ?
          </ModalHeader>
          <ModalFooter className="d-flex justify-content-flex-end">
            <Button
              onClick={this.toggle}
              color="link"
              style={{ color: "#fb6340" }}
            >
              NO
            </Button>
            <Button
              onClick={this._handleOnApproveOutline}
              color="link"
              style={{ color: "#fb6340" }}
            >
              YES
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    submitPublish: (id, data) => {
      dispatch(actSubmitPublishRequest(id, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutlineApproveModal);

OutlineApproveModal.propTypes = {
  id: PropTypes.string,
  approveOutline: PropTypes.func
};

OutlineApproveModal.defaultProps = {};
