import * as Types from "../variables/actionTypes.jsx";

const initialState = {
  loading: false,
  actionCompleted: false,
  poll: {},
};

const pollReducer = (state = initialState, action) => {
  switch (action.type) {
    // Poll
    case Types.GET_POLL:
      state = {
        ...state,
        actionCompleted: false,
        loading: true,
      };
      return { ...state };
    // Poll
    case Types.GET_POLL_SUCCESS:
      state = {
        ...state,
        actionCompleted: true,
        poll: action.data.poll,
        loading: true,
      };
      return { ...state };
    // Poll
    case Types.GET_POLL_FAIL:
      state = {
        ...state,
        actionCompleted: true,
        loading: false,
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default pollReducer;
