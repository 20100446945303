import { API_URL } from "./hostUrl";
var HOST = "";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(
    /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
) {
  // HOST = "https://vietcetera.wejelly.com/cms/api";
  // HOST = "https://cmsvietcetera.wejelly.com/cms/api";
  HOST = API_URL;
} else {
  HOST =
    window.location.protocol + "//" + window.location.hostname + "/cms/api";
}

export default "https://api.vietcetera.com/cms/api";
