import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorArticleWritingDetail from "../../../components/Detail/EditorArticleWritingDetail";
class EditorArticleWriting extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Article Writing"
          content="newBrief"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <EditorArticleWritingDetail {...props} />
        </Container>
      </>
    );
  }
}
EditorArticleWriting.propTypes = {
  content: PropTypes.string,
};
export default EditorArticleWriting;
