import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorDraftBriefsTable from "../../../components/Tables/EditorDraftBriefsTable";
class EditorDraftBriefs extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Draft Briefs"
          content="draftBriefs"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <EditorDraftBriefsTable {...props} />
        </Container>
      </>
    );
  }
}
EditorDraftBriefs.propTypes = {
  content: PropTypes.string,
};
export default EditorDraftBriefs;
