export const ShowNumner = (number) => {
  number = Math.abs(Number(number));
  if (number >= 1.0e9) {
    return Math.round((number / 1.0e9) * 10) / 10 + "B";
  } else {
    if (number >= 1.0e6) {
      return Math.round((number / 1.0e6) * 10) / 10 + "M";
    } else {
      if (number >= 1.0e3) {
        return Math.round((number / 1.0e3) * 10) / 10 + "K";
      } else {
        return number;
      }
    }
  }
};
