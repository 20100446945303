import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import WriterAddNewPitchDetail from "../../../components/Detail/WriterAddNewPitchDetail";

class WriterAddNewPitch extends Component {
  state = {
    id: window.location.pathname.split("/")[4],
  };
  render() {
    const props = this.props;
    const id = this.state.id;
    return (
      <>
        <CardsHeader
          name={id ? "Pitch Detail" : "Add New Pitch"}
          content="addNewPitch"
          parentName="Article"
        />
        <Container className="mt--7" fluid>
          <WriterAddNewPitchDetail {...props} />
        </Container>
      </>
    );
  }
}
WriterAddNewPitch.propTypes = {
  content: PropTypes.string,
};
export default WriterAddNewPitch;
