import React, { Component } from "react";
import { Card, CardHeader, Table, Col, Row, CardFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import { actGetEditorAndChiefeditorPastRequest } from "../../actions/employee.action";
import CustomPagination from "../Pagination/Pagination";
import CardBodyWithLoading from "../../components/CardBodyWithLoading/CardBody";
import Avatar from "../Avatar/Avatar";
class EditorAndChiefeditorPastTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1",
      search: getUrlParam()["search"] ? getUrlParam()["search"] : ""
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getPublishedWriter();
  }

  _getPublishedWriter = () => {
    const params = this.state.params;
    this.props.sort(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.sort(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };
  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.sort(paramsSort);
  };
  _handleOnLayoutClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/settings/information/${id}`);
  };
  _handleOnSearch = (label, value) => {
    const state = this.state;
    const params = {
      ...state.params,
      [label]: value
    };
    this.setState({
      ...state.params,
      params
    });
    this.props.sort(params);
    this._handlePathnamePush(params);
  };
  render() {
    const page = this.props.employee.data && this.props.employee.data.page;
    const totalPages =
      this.props.employee.data && this.props.employee.data.totalPages;
    const totalDocs =
      this.props.employee.data && this.props.employee.data.totalDocs;
    return (
      <>
        <CardBodyWithLoading isLoading={this.state.isLoading} className="p-0">
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="8" md="6" xs="12">
                      <h3 className="mb-0">Editor And Chiefeditor Past List</h3>
                    </Col>
                    <Col className="text-right" lg="4" md="6" xs="12">
                      <div className="input-group search-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-search-input" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Tìm kiếm"
                          onChange={e =>
                            this._handleOnSearch("search", e.target.value)
                          }
                          value={this.state.params.search}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <div className="table-responsive">
                  {this.props.employee.data &&
                    this.props.employee.data.docs.length !== 0 && (
                      <Table
                        className="align-items-center table-flush"
                        style={{ border: "0px" }}
                        hover
                      >
                        <thead className="thead-light">
                          <tr>
                            <th
                              className="sort"
                              data-sort="staffID"
                              scope="col"
                              onClick={() => this._sortByColumn("staff_id")}
                            >
                              STAFF ID
                            </th>
                            <th
                              className="sort"
                              data-sort="writer"
                              scope="col"
                              onClick={() => this._sortByColumn("name")}
                            >
                              FULL NAME
                            </th>
                            <th
                              className="sort"
                              data-sort="penname"
                              scope="col"
                              onClick={() => this._sortByColumn("penname")}
                            >
                              PEN NAME
                            </th>
                            <th
                              className="sort"
                              data-sort="job"
                              scope="col"
                              onClick={() => this._sortByColumn("job_position")}
                            >
                              POSITION
                            </th>
                            <th>MAIL</th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {this.props.employee.data
                            ? this.props.employee.data.docs.map(
                              (list, index) => {
                                return (
                                  <>
                                    <tr
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={e =>
                                        this._handleOnLayoutClick(e, list.id)
                                      }
                                    >
                                      <td>{list.staff_id}</td>
                                      <td>
                                        <Avatar
                                          src={list.avatar}
                                          className="d-inline-block mr-3"
                                        />
                                        <span>
                                          {list.name.charAt(0).toUpperCase() +
                                            list.name.slice(1)}
                                        </span>
                                      </td>
                                      <td>{list.penname}</td>
                                      <td>
                                        {list.job_position
                                          .charAt(0)
                                          .toUpperCase() +
                                          list.job_position.slice(1)}
                                      </td>
                                      <td>{list.email}</td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                            : ""}
                        </tbody>
                      </Table>
                    )}

                  {this.props.employee.data.docs.length === 0 && (
                    <div
                      className="p-7 text-center font-italic font-weight-bold"
                      style={{ color: "#ccc", fontSize: "18px" }}
                    >
                      There are no content available
                    </div>
                  )}
                </div>
                <CardFooter className="py-4">
                  <div className="float-right">
                    <CustomPagination
                      page={page}
                      totalPages={totalPages}
                      getListByPage={this._handleOnPageClick}
                    />
                    <div className="font-italic mt-2">
                      ({totalDocs} results in {totalPages} pages)
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </CardBodyWithLoading>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    employee: state.employeeReducer.employee
  };
};
const mapDispatchToProps = dispatch => {
  return {
    sort: params => {
      dispatch(actGetEditorAndChiefeditorPastRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorAndChiefeditorPastTable);
EditorAndChiefeditorPastTable.propTypes = {
  employee: PropTypes.array,
  sort: PropTypes.func
};
EditorAndChiefeditorPastTable.defaultProps = {};
