import React from "react";
import { Bar } from "react-chartjs-2";
const BarReport = function({ data }) {
  return (
    <div>
      <Bar
        data={data}
        options={{
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
        }}
      />
    </div>
  );
};
export default BarReport;
