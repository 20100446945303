import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import "./styles.scss";

function ConfirmModal({
  title,
  content,
  visible,
  loading,
  handleOk,
  handleCancel,
  hideBody,
  closable,
  footer
}) {
  return (
    <Modal
      closable={closable}
      className={`confirm-modal ${hideBody ? "hide" : ""}`}
      visible={visible}
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        footer || [
          <Button
            className="modal-cancel-btn"
            type="ghost"
            key="cancel"
            onClick={handleCancel}
          >
            No
          </Button>,
          <Button
            className="modal-submit-btn"
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Yes
          </Button>
        ]
      }
    >
      <p
        dangerouslySetInnerHTML={{
          __html: content
        }}
      ></p>
    </Modal>
  );
}
ConfirmModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  closable: PropTypes.bool,
  hideBody: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  footer: PropTypes.array
};
export default ConfirmModal;
