import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import PropTypes from "prop-types";
import CustomEditor from "../TinyMCE";
import { connect } from "react-redux";
import GeneralInfoModal from "../Modals/GeneralInfoModal";
import { actGetAboutDetailRequest } from "../../actions/general-info.action";

class CardAboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublishAbout: false,
      languagueKey: true,
      data: {
        AboutUS: "",
      },
    };
  }
  componentDidMount() {
    let params = {
      key: "About_key",
      key_language: "VN",
    };
    this.props.getAboutDetail(params);
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.about_detail &&
      nextProps.about_detail !== this.props.about_detail
    ) {
      this.setState({
        data: this.props.about_detail.details,
      });
    }
  }

  _handleOnChange = (e) => {
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data,
    });
  };

  _handleOnContentChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value,
      },
    });
  };

  _toggleModalPublish = () => {
    this.setState((prevState) => ({
      isPublishAbout: !prevState.isPublishAbout,
    }));
  };

  _switchLanguage = (e) => {
    const newLanguage = e.target.checked;
    this.setState({
      languagueKey: newLanguage,
    });
    if (newLanguage) {
      let params = {
        key: "About_key",
        key_language: "VN",
      };
      this.props.getAboutDetail(params);
    } else {
      let params = {
        key: "About_key",
        key_language: "EN",
      };
      this.props.getAboutDetail(params);
    }
  };

  render() {
    const { data } = this.state;

    return (
      <>
        <GeneralInfoModal
          modal={this.state.isPublishAbout}
          toggle={this._toggleModalPublish}
          aboutDetail={this.state.data}
          title={"Publish information?"}
          message={"Are you sure want to publish information 'About us'?"}
          type="about"
          languagueKey={this.state.languagueKey}
        />
        <Card>
          <CardHeader className="border-0 d-flex justify-content-between">
            <h3 className="mb-0">About us</h3>
            <div className="general-info-card d-flex">
              <span className="mr-2">Show contents: </span>
              <label className="custom-toggle custom-toggle-warning mr-1">
                <input
                  checked={this.state.languagueKey}
                  onChange={this._switchLanguage}
                  type="checkbox"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="EN"
                  data-label-on="VN"
                />
              </label>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <CustomEditor
                    content={data && data.AboutUS}
                    onChangeContent={(value) =>
                      this._handleOnContentChange("AboutUS", value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right">
              <Button
                className="btn btn-success"
                onClick={this._toggleModalPublish}
              >
                Publish
              </Button>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    about_detail: state.generalInfoReducer.about_detail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAboutDetail: (data) => {
      dispatch(actGetAboutDetailRequest(data));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardAboutUs);
CardAboutUs.propTypes = {
  about_detail: PropTypes.object,
  getAboutDetail: PropTypes.func,
};
CardAboutUs.defaultProps = {};
