import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  // Row,
  // Col,
  // FormGroup,
  // CardText
} from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actApproveContentRequest } from "../../actions/editorArticle.action";

class ContentApproveModal extends Component {
  state = {};

  toggle = () => {
    this.props.toggle();
  };

  _hideAlert = () => {
    this.setState({
      alert: "",
    });
  };

  _handleOnApproveContent = () => {
    const id = this.props.id;
    const data = {
      status: "APPROVED",
      content: this.props.content,
    };
    this.props.approveWritingContent(id, data);
    this.toggle();
  };

  render() {
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.props.toggle}>
            Are You Sure to Approve ?
          </ModalHeader>
          <ModalBody className="py-0">
            <p>Are you sure you want to approve this?</p>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-flex-end">
            <Button color="link" style={{ color: "#fb6340" }}>
              NO
            </Button>
            <Button
              onClick={this._handleOnApproveContent}
              color="link"
              style={{ color: "#fb6340" }}
            >
              YES
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    approveWritingContent: (id, data) => {
      dispatch(actApproveContentRequest(id, data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentApproveModal);

ContentApproveModal.propTypes = {
  id: PropTypes.string,
  content: PropTypes.string,
  approveWritingContent: PropTypes.func,
};

ContentApproveModal.defaultProps = {};
