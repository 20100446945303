import React, { Component } from "react";
import { Button, Input, Badge } from "reactstrap";
import { Menu, Dropdown } from "antd";
import PropTypes from "prop-types";
import ReplyPitchComment from "./ReplyPitchComment";
import moment from "moment";
import Avatar from "../Avatar/Avatar";
import { COMMENT_STATUS } from "../../utils/constants";
class CommentPitch extends Component {
  state = {
    isReplyInputShow: false,
  };

  _handleOnClickReply = () => {
    this.setState((prevState) => ({
      isReplyInputShow: !prevState.isReplyInputShow,
    }));
  };

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleOnSubmit();
    }
  };

  handleResolveFeedback = () => {
    const pitchID = window.location.pathname.split("/")[4];
    const data = {
      pitchId: pitchID,
      commentId: this.props.comment._id,
      status: COMMENT_STATUS.RESOLVED,
    };
    this.props.changeStatus(data);
  };

  handleDeleteFeedback = () => {
    const pitchID = window.location.pathname.split("/")[4];
    const data = {
      pitchId: pitchID,
      commentId: this.props.comment._id,
      status: COMMENT_STATUS.DELETED,
    };
    this.props.changeStatus(data);
  };

  handleOnSubmit = () => {
    const pitchID = window.location.pathname.split("/")[4];
    const data = {
      pitchId: pitchID,
      commentId: this.props.comment._id,
      content: this.state.reply,
    };
    if (this.state.reply) {
      this.props.addReply(data);
      this._handleOnClickReply();
      this.setState({ reply: "" });
    }
  };

  menu = () => {
    return (
      <Menu>
        <Menu.Item onClick={this.handleResolveFeedback}>Resolved</Menu.Item>
        <Menu.Item onClick={this.handleDeleteFeedback} className="text-danger">
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const props = this.props;
    const { comment, my_information } = props;
    return (
      <div className="comment">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <div className="d-flex w-100 align-items-center">
              {/* <Avatar
                width="32"
                height="32"
                className="mr-2"
                src={comment.avatar}
              /> */}
              <div style={{ paddingLeft: "5px" }}>
                <h4 className="mb-1">
                  {comment && comment.creator && comment.creator.name}
                </h4>
                <div className="date">
                  {moment(comment.createdAt).format("HH:mm DD/MM/YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className="resolve-container">
            {comment.status !== COMMENT_STATUS.RESOLVED ? (
              <Dropdown overlay={this.menu} trigger={["click", "hover"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                </a>
              </Dropdown>
            ) : (
              <Badge color="success" pill>
                Resolved
              </Badge>
            )}
          </div>
        </div>
        <p className="mt-3 mb-1">{comment.content}</p>
        {comment.status !== COMMENT_STATUS.RESOLVED ? (
          <div>
            {!this.state.isReplyInputShow && (
              <small
                className="btn-reply text-primary font-weight-bold cursor-pointer"
                onClick={() => this._handleOnClickReply()}
              >
                Reply
              </small>
            )}
            {this.state.isReplyInputShow && (
              <>
                <div className="input-reply d-flex w-100 justify-content-between align-items-center">
                  <Avatar
                    src={my_information.avatar}
                    width={30}
                    height={30}
                    className={"mr-2"}
                  />
                  <Input
                    placeholder="Reply..."
                    name="reply"
                    value={this.state.reply}
                    onChange={this.handleOnChange}
                    onKeyDown={this.onKeyDown}
                    type="text"
                  />
                </div>
                <Button
                  className="btn-cancle mt-2"
                  outline
                  color="primary"
                  type="button"
                  size="sm"
                  onClick={() => this._handleOnClickReply()}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        ) : null}

        <div className="replies mt-2">
          {comment.replies !== null &&
            comment.replies.map((prop, key) => {
              return <ReplyPitchComment reply={prop} key={key} {...props} />;
            })}
        </div>
      </div>
    );
  }
}

export default CommentPitch;
CommentPitch.propTypes = {
  comment: PropTypes.object,
  addReply: PropTypes.func,
  article_id: PropTypes.string,
  changeStatus: PropTypes.func,
  my_information: PropTypes.object,
  userID: PropTypes.string,
  pitchID: PropTypes.string,
};
CommentPitch.defaultProps = {};
