import React, { Component } from "react";
import { omit, omitBy, isNil, isEmpty } from "lodash";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Input
} from "reactstrap";
import ReactDatetime from "react-datetime";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import TagsInput from "react-tagsinput";
import { connect } from "react-redux";
import { actApprovePitchRequestV2 } from "../../actions/pitch.action";

const languageOption = [
  {
    label: "English",
    value: "EN"
  },
  {
    label: "Vietnamese",
    value: "VN"
  }
];

class PitchApproveModal extends Component {
  state = {
    data: {
      keywords: [],
      dead_line: "",
      references: "",
      things_to_avoid: "",
      things_to_mention: ""
    }
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { pitch_detail } = nextProps;
    if (nextProps.pitch_detail !== this.props.pitch_detail) {
      this.setState({
        data: {
          keywords: [],
          references: "",
          things_to_avoid: "",
          things_to_mention: ""
        },
        tagsOption:
          pitch_detail.topic &&
          pitch_detail.topic[0] &&
          pitch_detail.topic[0].tags,
        tagsSelect:
          pitch_detail &&
          pitch_detail.topic &&
          pitch_detail.topic[0] &&
          pitch_detail.topic[0].tags &&
          pitch_detail.topic[0].tags.map(item => ({
            value: item,
            label: item
          }))
      });
    }
  }

  _handleOnChange = e => {
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data
    });
  };

  _handleOnQuillChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value
      }
    });
  };

  handleOnKeywordChange(keywords) {
    this.setState({
      data: {
        ...this.state.data,
        keywords
      }
    });
  }

  _handleOnLanguageChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value
      }
    });
  };

  _handleOnTagsSelectChange = (label, value) => {
    this.setState({
      [label]: value
    });
  };

  toggle = () => {
    this.props.toggle();
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  _handleOnDeadlineChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        dead_line: value
      }
    });
  };

  _handleOnApproveContent = () => {
    const id = this.props.id;
    const data = {
      ...this.state.data,
      status_pitch: "APPROVED"
    };
    if (this.state.tagsSelect) {
      data.tags = this.state.tagsSelect;
      let tagsSelectArr = [];
      data.tags.map(prop => tagsSelectArr.push(prop.value));
      data.tags = tagsSelectArr;
    }
    let payload = { ...data };
    payload = omitBy(payload, isNil);
    payload = omitBy(payload, isEmpty);
    payload = omit(payload, ["tags"]);
    this.props.approvePitch(id, payload);
    this.toggle();
  };

  render() {
    const { data } = this.state;
    const validDate = current => {
      var yesterday = moment().subtract(1, "day");
      return current.isAfter(yesterday);
    };
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
          style={{ width: "800px" }}
        >
          <ModalHeader toggle={this.props.toggle}>
            Are you sure to approve ?
          </ModalHeader>
          <ModalBody className="py-0">
            <h3 className="text-info">
              Please fill the following fields below
            </h3>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="deadline"
                  >
                    Deadline
                    <span className="text-danger font-weight-bold">*</span>
                  </label>
                  <ReactDatetime
                    isValidDate={validDate}
                    value={
                      data.dead_line && data.dead_line.format("DD/MM/YYYY")
                    }
                    inputProps={{
                      placeholder: "Choose a deadline"
                    }}
                    onChange={value => this._handleOnDeadlineChange(value)}
                    timeFormat={"HH:mm"}
                    dateFormat={"DD/MM/YYYY"}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="language"
                  >
                    Language
                  </label>
                  <Select
                    name="language"
                    className="react-select"
                    classNamePrefix="react-select"
                    size="lg"
                    closeMenuOnSelect={true}
                    value={languageOption.filter(
                      ({ value }) => value === data.language
                    )}
                    options={languageOption}
                    onChange={value =>
                      this._handleOnLanguageChange("language", value.value)
                    }
                  />
                </FormGroup>
              </Col>
              {/* <Col md="12">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="tags">
                    Tags
                  </label>
                  <CreatableSelect
                    className="react-select"
                    classNamePrefix="react-select"
                    size="lg"
                    name="tags"
                    isClearable
                    closeMenuOnSelect={true}
                    isMulti
                    value={this.state.tagsSelect}
                    isDisabled
                  />
                </FormGroup>
              </Col> */}
              <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="references"
                  >
                    Referrences
                  </label>
                  <Input
                    id="references-input"
                    rows="5"
                    type="textarea"
                    name="references"
                    value={data.references && data.references}
                    onChange={this._handleOnChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="things_to_avoid"
                  >
                    Things to avoid
                  </label>
                  <Input
                    id="things_to_avoid-input"
                    rows="5"
                    type="textarea"
                    name="things_to_avoid"
                    value={data.things_to_avoid && data.things_to_avoid}
                    onChange={this._handleOnChange}
                  />
                  
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label d-block"
                    htmlFor="things_to_mention"
                  >
                    Things to mention
                  </label>
                  <Input
                    id="things_to_mention-input"
                    rows="5"
                    type="textarea"
                    name="things_to_mention"
                    value={data.things_to_mention && data.things_to_mention}
                    onChange={this._handleOnChange}
                  />
                
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="words">
                    Word counts
                  </label>
                  <Row>
                    <Col md="6">
                      <Input
                        id="min_word"
                        name="min_word"
                        type="number"
                        className="d-inline mr-3"
                        placeholder="Min words"
                        onChange={this._handleOnChange}
                        value={data.min_word && data.min_word}
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        id="max_word"
                        name="max_word"
                        type="number"
                        className="d-inline mr-3"
                        placeholder="Max words"
                        onChange={this._handleOnChange}
                        value={data.max_word && data.max_word}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="words">
                    Keywords
                  </label>
                  <TagsInput
                    id="keyword"
                    name="keyword"
                    value={data.keywords && data.keywords}
                    onChange={keywords => this.handleOnKeywordChange(keywords)}
                    inputProps={{
                      placeholder: "Maximum 3 keywords"
                    }}
                    maxTags="3"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-flex-end">
            <Button
              color="link"
              style={{ color: "#fb6340" }}
              onClick={this.toggle}
            >
              NO
            </Button>
            <Button
              onClick={this._handleOnApproveContent}
              color="link"
              style={{ color: "#fb6340" }}
            >
              YES
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    approvePitch: (id, data) => {
      dispatch(actApprovePitchRequestV2(id, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PitchApproveModal);

PitchApproveModal.propTypes = {
  id: PropTypes.string,
  content: PropTypes.string,
  pitch_detail: PropTypes.object,
  approvePitch: PropTypes.func,
  toggle: PropTypes.func
};

PitchApproveModal.defaultProps = {};
