import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
import ChiefAddNewArticleDetail from "../../../components/Detail/ChiefAddNewArticleDetail";
class ChiefAddNewArticle extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Add New Article"
          content="addNewArticle"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <ChiefAddNewArticleDetail {...props} />
        </Container>
      </>
    );
  }
}
ChiefAddNewArticle.propTypes = {
  content: PropTypes.string
};
export default ChiefAddNewArticle;
