import React, { Component } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetWritingContentByIDRequest } from "../../actions/writerArticle.action";
import CardsHeader from "../Headers/CardsHeader";
import CardsHeaderEditor from "../../components/Headers/CardsHeaderEditor";
import CardsHeaderChief from "../../components/Headers/CardsHeaderChief";
// import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

class ViewContentWriterDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      id: window.location.pathname.split("/")[4],
      key: window.location.pathname.split("/")[2],
      is_senior: localStorage.getItem("is_senior") === "true",
      data: {
        content: ""
      },
      idView: false
    };
  }

  componentDidMount() {
    this._setLoading();
    this._getContentDetailByID(this.state.id);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.writing_content !== this.props.writing_content) {
      this.setState({
        data: { content: nextProps.writing_content.data[0].content_content },
        isWritting:
          nextProps.writing_content.data[0].status_article === "CONTENT_WRITING"
      });
    }
  }

  _toggleModalView = () => {
    this.setState(prevState => ({
      idView: !prevState.idView
    }));
  };

  _getContentDetailByID = id => {
    this.props.getContentDetailByID(id);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  _handleOnViewDetail = id => {
    this._toggleModalView();
  };

  createMarkup(text) {
    return { __html: text };
  }
  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  render() {
    return (
      <>
        {this.state.key === "editor" ? (
          <CardsHeaderEditor
            name="Content"
            content="contentRequest"
            parentName="Article"
          />
        ) : this.state.key === "chief" ? (
          <CardsHeaderChief
            name="Content"
            content="contentRequest"
            parentName="Article"
          />
        ) : (
              <CardsHeader
                name="Content"
                content="contentRequest"
                parentName="Article"
              />
            )}
        <Container className="mt--6">
          <Card>
            <CardBody className="ql-editor ql-editor-preivew navbar-admin__scroll-container">
              <Container>
                <div className="preview-content">
                  <h1 style={{ fontSize: 40 }}>
                    {ReactHtmlParser(
                      this.props.writing_content.data[0].angle_title
                    )}
                  </h1>
                  {this.rawHTML(
                    this.props.writing_content &&
                    this.props.writing_content.data[0].content_content
                  )}
                </div>
              </Container>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writing_content: state.writerArticleReducer.writing_content_id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getContentDetailByID: id => {
      dispatch(actGetWritingContentByIDRequest(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewContentWriterDetail);
ViewContentWriterDetail.propTypes = {
  writing_content: PropTypes.object,
  getContentDetailByID: PropTypes.func
};
ViewContentWriterDetail.defaultProps = {};
