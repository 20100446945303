import React, { Component } from "react";
import get from "lodash/get";
import { Spin } from "antd";
import {
  Form,
  Input as InputAnt,
  Icon,
  Button as ButtonAnt,
  Tooltip,
  message
} from "antd";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import moment from "moment";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Collapse
} from "reactstrap";
import Select from "react-select";
import { actNotify } from "../../actions/fetch.action";
import { languageOption, labelOption } from "../../variables/selectOptions";
import { featureImageModes, ARTICLE_TYPES } from "../../utils/constants";
import ReactDatetime from "react-datetime";
import {
  actGetUnPublishedDetailByID,
  actResetUnPublishedDetailByID,
  actRemoveArticleSettingRequest
} from "../../actions/chiefArticle.action";
import { actGetPostInfoRequestV2 } from "../../actions/post.action";
import { actGetListStaffRequest } from "../../actions/employee.action";
import { actUpdateImageSizeRequest } from "../../actions/upload.action";
import { actGetPublishedCollectionRequest } from "../../actions/chief-collection.action";
import HOST_URL from "../../variables/hostUrl";
import callApi from "../../utils/callApi";
import TagInput from "../TagSelector";
import { MAX_SIZE_IMAGE } from "../../utils/constants";
import AVA from "../../assets/img/AVA.png";
import { ReactComponent as ResetIcon } from "../../assets/img/icons/reset.svg";
// import { ReactComponent as Icon } from "../../assets/img/icons/exclamation-outline.svg";

import "./style.scss";

let id = 0;
const { TextArea } = InputAnt;
class NewArticleSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingImage: false,
      collapse: false,
      openedCollapses: ["collapseStatus, collapseLabel"],
      tags: [],
      imageId: "",
      brandedLogoId: "",
      brandedLogoAlt: "",
      brandedLogoLink: "",
      previewImage: "",
      previewBrandedLogo: "",
      thumbnailImageId: "",
      previewThumbnail: "",
      other_lang_link: "",
      topic: [],
      series: null,
      writer: [],
      excerpt: "",
      slug: "",
      slugChange: false,
      publishDate: "",
      featureImageCaption: "",
      featureImage: "",
      imgError: "",
      summaries: [],
      featureImageType: {
        value: "landscape",
        label: "Landscape"
      },
      typeOfContent: {
        value: "original",
        label: "Original"
      },
      label: {
        value: "",
        label: "No badge"
      },
      excerptWarn: false
    };
  }

  componentDidMount() {
    this._getListStaff();
    this._getPostInfo();
    this._getPublishedCollection();
  }

  stripHTMLTag = string => {
    if (!string) {
      return "";
    }
    return string.replace(/(<([^>]+)>)/gi, "");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.setting && nextProps.setting !== this.props.setting) {
      const topic = nextProps.setting.topic[0];
      const writer = nextProps.setting.writer;
      const featureImageCaption = get(
        nextProps,
        "setting.featureImageCaption",
        ""
      );
      const featureImage = get(nextProps, "setting.featureImage", "");
      const brandedLogoAlt = get(nextProps, "setting.brandedLogoAlt", "");
      const brandedLogoLink = get(nextProps, "setting.brandedLogoLink", "");
      const brandedLogoId = get(nextProps, "setting.brandedLogoId", "");
      const featureImageType = get(nextProps, "setting.featureImageType");
      const language = nextProps.setting.language;
      const topicArray = [
        { ...topic, value: topic && topic.key, label: topic && topic.name }
      ];
      const previewImageUrl = nextProps.setting.previewImage;
      const excerptResult = this.stripHTMLTag(nextProps.setting.excerpt);
      const label = get(nextProps, "setting.label");
      this.setState({
        topic: topicArray,
        writer: writer,
        tags: nextProps.setting.tags,
        other_lang_link: nextProps.setting.other_lang_link,
        excerpt: excerptResult,
        previewImage: previewImageUrl,
        featureImageCaption: featureImageCaption,
        featureImage: featureImage,
        featureImageType: featureImageType,
        imageId: nextProps.setting.imageId,
        thumbnailImageId: nextProps.setting.thumbnailImageId,
        brandedLogoAlt: brandedLogoAlt,
        brandedLogoLink: brandedLogoLink,
        brandedLogoId: brandedLogoId,
        slug: nextProps.setting.slug,
        language: language,
        publishDate: nextProps.setting.publishDate
          ? moment(nextProps.setting.publishDate)
          : "",
        typeOfContent: nextProps.setting.typeOfContent,
        label: label
      });
    }
  }

  _handleOnChangeSetting = (label, value) => {
    const { setting } = this.props;
    if (label === "seoDescription") {
      if (value.length > 160) {
        message.error("Maximum SEO description is 160 characters");
      }
    }
    this.props.onChangeSetting(
      {
        ...setting,
        [label]: value
      },
      ""
    );
  };

  onExcerptChange = e => {
    const value = e.target.value;
    const { setting } = this.props;
    this.setState(
      {
        excerpt: value,
        excerptWarn: value.length > 160
      },
      () => {
        this.props.onChangeSetting(
          {
            ...setting,
            excerpt: value
          },
          ""
        );
      }
    );
  };
  // Handle image mode change
  // handleFeatureModeChange = value => {
  //   this.setState({
  //     featureImageType: value
  //   });
  // };

  _handleAuthorSelect = (authorId, authorName) => {
    const { setting } = this.props;
    this.setState(
      {
        writer: [
          {
            value: authorId,
            label: authorName,
            key: authorId,
            name: authorName
          }
        ]
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          writer: this.state.writer
        });
      }
    );
  };
  // handle topic change
  _handleOnSelectTopicChange = (topickey, topicname) => {
    const { setting } = this.props;
    let tagsFilter = this.props.post_info.data.topics.filter(
      prop => prop.key === topickey
    );

    this.setState({
      topic: [
        {
          value: topickey,
          label: topicname,
          key: topickey,
          name: topicname,
          tags: tagsFilter[0].tags
        }
      ]
    });
    this.setState({}, () => {
      this.props.onChangeSetting({
        ...setting,
        topic: this.state.topic
      });
    });
  };

  // handle topic change
  _handleOnSelectSeriesChange = series => {
    const { setting } = this.props;
    this.setState(
      {
        series: series
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          series: series
        });
      }
    );
  };

  _getPostInfo() {
    this.props.getPostInfo();
  }
  _getListStaff() {
    this.props.getListStaff({ status: "WORKING" });
  }
  _getPublishedCollection() {
    const data = {
      language: "VN"
    };
    this.props.getPublishedCollection(data);
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  onFeatureImageTypeChange = value => {
    this.setState({
      featureImageType: value
    });
  };

  onTagsChange = tags => {
    const { setting } = this.props;
    this.setState(
      {
        tags: tags
      },
      () =>
        this.props.onChangeSetting({
          ...setting,
          tags: tags
        })
    );
  };

  _wrapInTag = content => {
    const result = `<p>${content}</p>`;
    return result;
  };

  onPreviewDrop = files => {
    this.setState({
      imgError: ""
    });
    let file = files[0];
    if (file.size > MAX_SIZE_IMAGE) {
      this.setState({
        imgError: `Maxium file size should be ${MAX_SIZE_IMAGE / 1000}KB`
      });
      return;
    }
    this.uploadToServer(files, get(this.state.featureImageType, "value"));
  };

  uploadBrandedLogo = async files => {
    const { setting } = this.props;
    this.setState({ uploadingImage: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const uploadImage = callApi(
      "v2/images/branded-logo",
      "POST",
      formData,
      true
    );
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState(
      {
        brandedLogoId: result.data._id,
        previewBrandedLogo: imageUrl,
        uploadingImage: false
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          previewBrandedLogo: imageUrl,
          brandedLogoId: result.data._id
        });
      }
    );
  };
  uploadToServer = async (files, mode) => {
    const { setting } = this.props;
    this.setState({ uploadingImage: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const isPortrait = mode === "portrait";
    const uploadEndpoint = isPortrait
      ? "v2/images/portrait-feature-article"
      : "v2/images/standard-feature-image-article";
    const uploadImage = callApi(uploadEndpoint, "POST", formData, true);
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState(
      {
        imageId: result.data._id,
        previewImage: imageUrl,
        uploadingImage: false
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          previewImage: imageUrl,
          image: result.data._id
        });
      }
    );
  };
  handleUploadThumbnail = async files => {
    const { setting } = this.props;
    this.setState({ uploadingImage: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const uploadImage = callApi(
      "v2/images/thumbnail-feature-article",
      "POST",
      formData,
      true
    );
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState(
      {
        thumbnailImageId: result.data._id,
        previewThumbnail: imageUrl,
        uploadingImage: false
      },
      () => {
        this.props.onChangeSetting({
          ...setting,
          previewThumbnail: imageUrl,
          thumbnailImageId: result.data._id
        });
      }
    );
  };

  // Add field summary
  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    if (keys.length >= 3) {
      message.error("Maximum summaries is 3!");
      return;
    }
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  // Remove summary field
  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  // handle submit summary
  handleSubmit = e => {
    const { setting } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (values.keys.length === 0) {
        message.error("Please add at least one summary!");
        return;
      }
      if (!err) {
        const { keys, names } = values;
        this.setState({
          summaries: keys.map(key => names[key])
        });
        this.props.onChangeSetting({
          ...setting,
          summaries: keys.map(key => names[key])
        });
        message.success("Save summaries successfully");
      }
    });
  };

  renderArticleTypeNote = () => {
    return (
      <ul className="article-type-note">
        <li>
          <strong>Original:</strong> Content that originates from the in-house
          editorial team.
        </li>
        <li>
          <strong>Branded:</strong> Content written for commercial/branded
          purposes
        </li>
        <li>
          <strong>Outsourced:</strong> Content that originates from a
          third-party (community writer, contributor, experts, etc.)
        </li>
      </ul>
    );
  };

  render() {
    const {
      setting,
      employee_list,
      invalidAudio,
      isSlideShow,
      isOpenPublishing,
      publishedCollection
    } = this.props;
    const {
      previewImage,
      uploadingImage,
      publishDate,
      language,
      previewBrandedLogo,
      featureImageType,
      previewThumbnail,
      typeOfContent,
      excerptWarn,
      label
    } = this.state;
    const employeeListOption =
      employee_list.data && employee_list.data.docs.length > 0
        ? employee_list.data.docs.map(item => {
            return {
              value: item.id,
              name: item.name,
              key: item.id,
              label: item.name
            };
          })
        : [];
    const seriesListOptionDynamic =
      publishedCollection &&
      publishedCollection.map(item => {
        return {
          value: item.id,
          label: item.title
        };
      });
    const seriesListOption = [
      {
        value: "no-series",
        label: "No series"
      },
      ...seriesListOptionDynamic
    ];
    const formatOptionLabel = ({ label, icon }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "10px" }}>
          <img
            src={icon ? HOST_URL + icon : AVA}
            alt=""
            width="32px"
            height="32px"
            style={{ borderRadius: 50 }}
          />
        </div>
        <div style={{ fontSize: "14px" }}>{label}</div>
      </div>
    );
    // dynamic form item handling
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 }
      }
    };
    getFieldDecorator("keys", { initialValue: [] });
    const keys = getFieldValue("keys");
    const formItems = keys.map((k, index) => (
      <Form.Item
        {...formItemLayout}
        className="summary-field-form__item"
        required={false}
        key={k}
      >
        {getFieldDecorator(`names[${k}]`, {
          validateTrigger: ["onChange", "onBlur"],
          rules: [
            {
              max: 120,
              required: true,
              whitespace: true,
              message: "Invalid field. Please check again!"
            }
          ]
        })(
          <TextArea
            rows={3}
            placeholder="Summary"
            style={{ width: "90%", marginRight: 8 }}
          />
        )}
        {keys.length > 1 ? (
          <Icon
            style={{ color: "red" }}
            onClick={() => this.remove(k)}
            type="delete"
          />
        ) : null}
      </Form.Item>
    ));
    const featureImageTypeValue = get(featureImageType, "value");
    const isPortrait = featureImageTypeValue === "portrait";
    return (
      <div className="post-settings">
        {this.state.alert}
        <Card className="mt-3">
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <i className="fa fa-cogs text-primary pr-2"></i>
                <span className="mr-1 font-weight-bold">Article setting</span>
                <h5 className="mt-4 mb-0">
                  <em>
                    All fields with (<span className="asterisk">*</span>) is
                    required
                  </em>
                </h5>
              </div>
            </div>
          </CardHeader>
          {/* ARticle type */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseType")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseType"
                )}
              >
                <div className="d-flex align-items-center">
                  <h4 className="mb-0">Article Type</h4>
                  <span className="asterisk ml-1">*</span>
                  <Tooltip
                    className="ml-2"
                    title={() => this.renderArticleTypeNote()}
                  >
                    <Icon type="exclamation-circle" />
                  </Tooltip>
                </div>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseType")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <Select
                    className="w-100 react-select"
                    classNamePrefix="react-select"
                    size="lg"
                    options={ARTICLE_TYPES}
                    value={typeOfContent}
                    onChange={value =>
                      this._handleOnChangeSetting("typeOfContent", value)
                    }
                    closeMenuOnSelect={true}
                  />
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Author */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseAuthor")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseAuthor"
                )}
              >
                <div className="d-flex">
                  <h4 className="mb-0">Author</h4>
                  <span className="asterisk ml-1">*</span>
                </div>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseAuthor")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <Select
                    name="assigned_to"
                    className="react-select"
                    classNamePrefix="react-select"
                    size="lg"
                    closeMenuOnSelect={true}
                    value={this.state.writer}
                    options={employeeListOption}
                    formatOptionLabel={formatOptionLabel}
                    onChange={value =>
                      this._handleAuthorSelect(value.value, value.name)
                    }
                  />
                </CardBody>
              </Collapse>
            </div>
          </div>
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseStatus")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseStatus"
                )}
              >
                <h4 className="mb-0">Publish time</h4>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseStatus")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <h5 className="mb-0">Choose publish time</h5>
                      <div className="d-flex align-items-center">
                        <ReactDatetime
                          // isValidDate={validDate}
                          className="timming-picker"
                          value={publishDate}
                          defaultValue={
                            publishDate &&
                            publishDate.format("DD/MM/YYYY HH:mm")
                          }
                          inputProps={{
                            placeholder: "Not Scheduled"
                          }}
                          onChange={value =>
                            this._handleOnChangeSetting(
                              "publishDate",
                              value.format()
                            )
                          }
                          timeFormat={"HH:mm"}
                          dateFormat={"DD/MM/YYYY"}
                        />
                        {publishDate && (
                          <Tooltip
                            placement="bottomRight"
                            title="Reset to publish not scheduled"
                          >
                            <ResetIcon
                              className="reset-icon"
                              onClick={() =>
                                this._handleOnChangeSetting("publishDate", "")
                              }
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Permalink */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseLink")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseLink"
                )}
              >
                <h4 className="mb-0">Permalink</h4>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseLink")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <FormGroup>
                    <h5>URL Slug</h5>
                    <Input
                      id="example3cols1Input"
                      value={setting.slug}
                      onChange={e =>
                        this._handleOnChangeSetting("slug", e.target.value)
                      }
                      type="text"
                    />
                    <span style={{ fontSize: 13 }}>
                      The last part of the URL.{" "}
                      <a href="#pablo" target="_blank">
                        Read about permalinks
                      </a>
                    </span>
                  </FormGroup>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* SERIES */}
          {!isOpenPublishing && (
            <div className="accordion">
              <div className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => this.collapsesToggle("collapseSeries")}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseSeries"
                  )}
                >
                  <div className="d-flex">
                    <h4 className="mb-0">Series</h4>
                    <span className="asterisk ml-1">*</span>
                  </div>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes("collapseSeries")}
                >
                  <CardBody style={{ paddingTop: 0 }}>
                    <div className="custom-vietcetera-topic">
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        size="lg"
                        closeMenuOnSelect={true}
                        value={this.state.series}
                        options={seriesListOption}
                        onChange={value =>
                          this._handleOnSelectSeriesChange(value)
                        }
                      />
                    </div>
                  </CardBody>
                </Collapse>
              </div>
            </div>
          )}

          {/* Topic */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseTopics")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseTopics"
                )}
              >
                <div className="d-flex">
                  <h4 className="mb-0">Topic</h4>
                  <span className="asterisk ml-1">*</span>
                </div>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseTopics")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <div className="custom-vietcetera-topic">
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      size="lg"
                      closeMenuOnSelect={true}
                      value={this.state.topic}
                      options={this.props.topics_name}
                      onChange={value =>
                        this._handleOnSelectTopicChange(value.key, value.name)
                      }
                    />
                  </div>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Tags */}
          {this.state.tags ? (
            <TagInput
              tags={this.state.tags}
              onTagsChange={tags => this.onTagsChange(tags)}
            />
          ) : null}
          {/* <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseImg")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseImg"
                )}
              >
                <div className="d-flex">
                  <h4 className="mb-0">
                    {isPortrait ? "Portait Feature Image" : "Feature Image"}
                  </h4>
                  <span className="asterisk ml-1">*</span>
                </div>
              </CardHeader>
            </div>
          </div> */}
          {!isOpenPublishing && (
            <div className="accordion">
              <div className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => this.collapsesToggle("collapseImg")}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseImg"
                  )}
                >
                  <div className="d-flex">
                    <h4 className="mb-0">
                      {isPortrait ? "Portait Feature Image" : "Feature Image"}
                    </h4>
                    <span className="asterisk ml-1">*</span>
                  </div>
                </CardHeader>

                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes("collapseImg")}
                >
                  <CardBody className="p-0">
                    <div className="px-4 d-flex justify-content-between">
                      <span>Image modes:</span>
                      <Select
                        className="w-50 react-select"
                        classNamePrefix="react-select"
                        size="lg"
                        options={featureImageModes}
                        value={
                          featureImageType || {
                            value: "landscape",
                            label: "Landscape"
                          }
                        }
                        onChange={value =>
                          this._handleOnChangeSetting("featureImageType", value)
                        }
                        closeMenuOnSelect={true}
                      ></Select>
                    </div>
                    <div className="p-4 dropzone-wrapper">
                      {!isPortrait && (
                        <h4>Ratio: 16:9 ( Size: 2000 x 1125 )</h4>
                      )}
                      {uploadingImage ? (
                        <div className="loader-absolute">
                          <Spin />
                        </div>
                      ) : null}
                      <Dropzone onDrop={this.onPreviewDrop} accept="image/*">
                        {({
                          getRootProps,
                          getInputProps,
                          isDragReject,
                          isDragActive
                        }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="dropzone-cover">
                              {!previewImage ? (
                                <>
                                  {!isDragActive && "Drop a file to upload!"}
                                  {isDragActive &&
                                    !isDragReject &&
                                    "Drop it to upload"}
                                  {isDragReject &&
                                    "File type not accepted, sorry!"}
                                </>
                              ) : (
                                <img
                                  className="w-100 h-100"
                                  alt="Preview"
                                  src={previewImage}
                                />
                              )}
                            </div>
                            <span className="text-red">
                              {this.state.imgError}
                            </span>
                          </div>
                        )}
                      </Dropzone>
                      <FormGroup>
                        <div
                          className="d-flex flex-column"
                          style={{ "margin-top": "10px" }}
                        >
                          <h5>Feature Image’s Caption</h5>
                        </div>
                        <Input
                          value={setting.featureImageCaption}
                          onChange={e =>
                            this._handleOnChangeSetting(
                              "featureImageCaption",
                              e.target.value
                            )
                          }
                          type="text"
                        />
                        <div className="d-flex align-items-center">
                          <h5 style={{ "margin-top": "10px" }}>
                            Feature Image’s Alternative Text{" "}
                          </h5>
                          <Tooltip
                            className="ml-2"
                            title={`The alternative text provides alternative information
                          for an image if a user for some reason cannot view it
                          (because of slow connection, or if the user uses a
                          screen reader).`}
                          >
                            <Icon type="exclamation-circle" />
                          </Tooltip>
                        </div>
                        <Input
                          value={setting.featureImage}
                          onChange={e =>
                            this._handleOnChangeSetting(
                              "featureImage",
                              e.target.value
                            )
                          }
                          type="text"
                        />
                      </FormGroup>
                    </div>
                  </CardBody>
                </Collapse>
              </div>
            </div>
          )}

          {/* Thumbnail image upload */}
          {isSlideShow || isOpenPublishing ? null : (
            <div className="accordion">
              <div className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => this.collapsesToggle("collapseThumbnail")}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseThumbnail"
                  )}
                >
                  <div className="d-flex">
                    <h4 className="mb-0">
                      {isPortrait
                        ? "Portait Thumbnail image"
                        : "Thumbnail image"}
                    </h4>
                    <span className="asterisk ml-1">
                      {isPortrait ? "*" : ""}
                    </span>
                  </div>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes(
                    "collapseThumbnail"
                  )}
                >
                  <CardBody className="p-0">
                    <div className="px-4 dropzone-wrapper">
                      {uploadingImage ? (
                        <div className="loader-absolute">
                          <Spin />
                        </div>
                      ) : null}
                      <Dropzone
                        onDrop={this.handleUploadThumbnail}
                        accept="image/*"
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragReject,
                          isDragActive
                        }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="dropzone-cover">
                              {!previewThumbnail ? (
                                <>
                                  {!isDragActive && "Drop a file to upload!"}
                                  {isDragActive &&
                                    !isDragReject &&
                                    "Drop it to upload"}
                                  {isDragReject &&
                                    "File type not accepted, sorry!"}
                                </>
                              ) : (
                                <img
                                  className="w-100 h-100"
                                  alt="Preview"
                                  src={previewThumbnail}
                                />
                              )}
                            </div>
                            <span className="text-red">
                              {this.state.imgError}
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </CardBody>
                </Collapse>
              </div>
            </div>
          )}

          {/* Excerpt */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseExcerpt")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseExcerpt"
                )}
              >
                <div className="d-flex">
                  <h4 className="mb-0">Excerpt</h4>
                  <span className="asterisk ml-1">*</span>
                </div>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseExcerpt")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <p>Write an excerpt ( Ideally 160 characters )</p>
                  <Input
                    className={`description ${excerptWarn ? "error" : ""}`}
                    value={this.state.excerpt}
                    onChange={this.onExcerptChange}
                    rows="5"
                    type="textarea"
                  />
                  <small className="text-right">
                    {setting.excerpt ? setting.excerpt.length : "0"}/160
                  </small>
                </CardBody>
                {/* <CardBody style={{ paddingTop: 0 }}>
                  <p>Write an excerpt</p>
                  <div>
                    <Input
                      className="description"
                      value={setting.excerpt}
                      onChange={(e) =>
                        this._handleOnChangeSetting("excerpt", e.target.value)
                      }
                      rows="5"
                      type="textarea"
                    />
                  </div>
                </CardBody> */}
              </Collapse>
            </div>
          </div>
          {/* SEO */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseSEO")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseSEO"
                )}
              >
                <div className="d-flex">
                  <h4 className="mb-0">SEO</h4>
                  <span className="asterisk ml-1">*</span>
                </div>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseSEO")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <p>Description</p>
                  <div>
                    {/* SEO Description */}
                    <Input
                      className="description"
                      maxLength="160"
                      rows="5"
                      value={setting.seoDescription}
                      onChange={e =>
                        this._handleOnChangeSetting(
                          "seoDescription",
                          e.target.value
                        )
                      }
                      type="textarea"
                    />
                    <small className="float-right">
                      {setting.seoDescription
                        ? setting.seoDescription.length
                        : "0"}
                      /160
                    </small>
                  </div>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Language */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseLanguague")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseLanguague"
                )}
              >
                <div className="d-flex">
                  <h4 className="mb-0">Language</h4>
                  <span className="asterisk ml-1">*</span>
                </div>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(
                  "collapseLanguague"
                )}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <div className="d-flex">
                    <h5>Language</h5>
                    <span className="asterisk ml-1">*</span>
                  </div>
                  <FormGroup>
                    <Select
                      className="react-select1"
                      classNamePrefix="react-select"
                      name="interest"
                      isClearable
                      options={languageOption}
                      value={language}
                      onChange={value =>
                        this._handleOnChangeSetting("language", value)
                      }
                      closeMenuOnSelect={true}
                    />
                  </FormGroup>
                  <>
                    <h5>Translation article slug</h5>
                    <Input
                      type="text"
                      value={setting.otherLanguageLink}
                      onChange={e =>
                        this._handleOnChangeSetting(
                          "otherLanguageLink",
                          e.target.value
                        )
                      }
                    ></Input>
                  </>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Badge */}
          <div className="accordion">
            <div className="card-plain">
              <div className="d-flex" style={{ padding: "1rem 1.5rem" }}>
                <h4 className="mb-0">Label</h4>
              </div>
              <CardBody style={{ paddingTop: 0 }}>
                <FormGroup className="mb-0">
                  <Select
                    className="react-select1"
                    classNamePrefix="react-select"
                    name="badge"
                    options={labelOption}
                    value={
                      label || {
                        label: "No badge",
                        value: ""
                      }
                    }
                    onChange={value =>
                      this._handleOnChangeSetting("label", value)
                    }
                    closeMenuOnSelect={true}
                  />
                </FormGroup>
              </CardBody>
            </div>
          </div>
          {/* Audio field */}
          {isSlideShow || isOpenPublishing ? null : (
            <div className="accordion">
              <div className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => this.collapsesToggle("collapseAudio")}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseAudio"
                  )}
                >
                  <h4 className="mb-0">Audio link</h4>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes("collapseAudio")}
                >
                  <CardBody style={{ paddingTop: 0 }}>
                    <FormGroup>
                      <Input
                        id="audio-field"
                        value={setting.audio}
                        onChange={e =>
                          this._handleOnChangeSetting("audio", e.target.value)
                        }
                        type="text"
                      />
                      {invalidAudio ? (
                        <p className="text-danger mt-2">
                          <small>{invalidAudio}</small>
                        </p>
                      ) : null}
                    </FormGroup>
                  </CardBody>
                </Collapse>
              </div>
            </div>
          )}
          {/* Branded logo field */}
          {isSlideShow || isOpenPublishing ? null : (
            <div className="accordion">
              <div className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => this.collapsesToggle("collapseBrandedLogo")}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseBrandedLogo"
                  )}
                >
                  <div className="d-flex">
                    <h4 className="mb-0">Branded Logo</h4>
                    <span className="ml-1">(Optional)</span>
                  </div>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes(
                    "collapseBrandedLogo"
                  )}
                >
                  <CardBody className="p-0">
                    <div className="px-4 dropzone-wrapper">
                      <small className="d-block mb-2">Size: 1200 x 250</small>
                      {uploadingImage ? (
                        <div className="loader-absolute">
                          <Spin />
                        </div>
                      ) : null}
                      <Dropzone
                        onDrop={this.uploadBrandedLogo}
                        accept="image/*"
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragReject,
                          isDragActive
                        }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="dropzone-cover">
                              {!previewBrandedLogo ? (
                                <>
                                  {!isDragActive && "Drop a file to upload!"}
                                  {isDragActive &&
                                    !isDragReject &&
                                    "Drop it to upload"}
                                  {isDragReject &&
                                    "File type not accepted, sorry!"}
                                </>
                              ) : (
                                <img
                                  className="w-100 h-100"
                                  alt="Preview"
                                  src={previewBrandedLogo}
                                />
                              )}
                            </div>
                            <span className="text-red">
                              {this.state.imgError}
                            </span>
                          </div>
                        )}
                      </Dropzone>
                      <FormGroup>
                        {/* Branded logo alternative text */}
                        <h5 style={{ "margin-top": "10px" }}>
                          Branded logo's Alternative Text{" "}
                        </h5>
                        <Input
                          value={setting.brandedLogoAlt}
                          onChange={e =>
                            this._handleOnChangeSetting(
                              "brandedLogoAlt",
                              e.target.value
                            )
                          }
                          type="text"
                        />
                        {/* Branded logo backlink */}
                        <h5 style={{ "margin-top": "10px" }}>
                          Branded logo's backlink{" "}
                        </h5>
                        <Input
                          value={setting.brandedLogoLink}
                          onChange={e =>
                            this._handleOnChangeSetting(
                              "brandedLogoLink",
                              e.target.value
                            )
                          }
                          type="text"
                        />
                      </FormGroup>
                    </div>
                  </CardBody>
                </Collapse>
              </div>
            </div>
          )}
          {/* Summaries */}
          {isSlideShow || isOpenPublishing ? null : (
            <div className="accordion">
              <div className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => this.collapsesToggle("collapseSummary")}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseSummary"
                  )}
                >
                  <div className="d-flex">
                    <h4 className="mb-0">Summary</h4>
                  </div>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes(
                    "collapseSummary"
                  )}
                >
                  <CardBody style={{ paddingTop: 0 }}>
                    <small className="d-block mb-4">
                      3 summaries are the best
                    </small>
                    <Form
                      className="summary-field-form"
                      onSubmit={this.handleSubmit}
                    >
                      {formItems}
                      <Form.Item
                        className="summary-field-form__item"
                        {...formItemLayoutWithOutLabel}
                      >
                        <ButtonAnt
                          className="d-flex justify-content-center align-items-center"
                          type="dashed"
                          onClick={this.add}
                          style={{ width: "100%" }}
                        >
                          <Icon type="plus" /> Add summary
                        </ButtonAnt>
                      </Form.Item>
                      <Form.Item
                        className="summary-field-form__item"
                        {...formItemLayoutWithOutLabel}
                      >
                        <ButtonAnt type="primary" htmlType="submit">
                          Save
                        </ButtonAnt>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Collapse>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    my_information: state.authReducer.my_information,
    post_info: state.postReducer.post_info,
    topics_name: state.postReducer.topics_name,
    employee_list: state.employeeReducer.employee_list,
    topics_name_select: state.postReducer.topics_name_select,
    publishedCollection: state.ChiefCollectionReducer.publishedCollection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: data => {
      dispatch(actNotify(data));
    },
    getPostInfo: () => {
      dispatch(actGetPostInfoRequestV2());
    },
    getListStaff: query => {
      dispatch(actGetListStaffRequest(query));
    },
    resetArticleDetail: () => {
      dispatch(actResetUnPublishedDetailByID());
    },
    setArticleDetail: data => {
      dispatch(actGetUnPublishedDetailByID(data));
    },
    updateImageSize: (id, data) => {
      dispatch(actUpdateImageSizeRequest(id, data));
    },
    removeArticleRequest: id => {
      dispatch(actRemoveArticleSettingRequest(id));
    },
    getPublishedCollection: data => {
      dispatch(actGetPublishedCollectionRequest(data));
    }
  };
};

const WrappedNewArticleSetting = Form.create({ name: "dynamic_form_item" })(
  NewArticleSetting
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNewArticleSetting);

NewArticleSetting.propTypes = {
  value: PropTypes.string,
  setArticleDetail: PropTypes.func,
  resetArticleDetail: PropTypes.func,
  innerRef: PropTypes.func,
  removeArticleRequest: PropTypes.func,
  readOnly: PropTypes.bool,
  isComment: PropTypes.bool,
  notify: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.object,
  topics_name: PropTypes.array,
  post_info: PropTypes.object,
  setting: PropTypes.object,
  getPostInfo: PropTypes.func,
  employee_list: PropTypes.array,
  getListStaff: PropTypes.func,
  onChangeSetting: PropTypes.func,
  updateImageSize: PropTypes.func,
  getPublishedCollection: PropTypes.func,
  artilceId: PropTypes.string
};

NewArticleSetting.defaultProps = {
  isSlideShow: false,
  isOpenPublishing: false
};
