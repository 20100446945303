import React, { useEffect, useState } from "react";
import { Table, Row, Col } from "antd";
import DropDownDate from "../DropDownDate";
import FilterDateRanger from "../FilterDateRanger";
import callApi from "../../../utils/callApi";
import { makeUrlObj } from "../../../utils/makeUrl";
import moment from "moment";
import { columns, handleTimeReigterUser } from "./utils";
import Info from "./Infor";
import { Line } from "react-chartjs-2";
import get from "lodash/get"

const EditorSection = function() {
  const [topics, setTopics] = useState([]);
  const [data, setData] = useState({
    userTotal: 0,
    userRegisterCompare: 0,
    googleAnalytics: {
      vistor: 0,
      avgPageView: 0
    },
    googleAnalyticsCompare: {
      vistor: 0,
      avgPageView: 0
    } 
  })
  const [config, setConfig] = useState({
    data: {},
    options: {},
  });

  const [queryDate, setQueryDate] = useState({
    startDate: moment().subtract('days', 14),
    endDate: moment(),
  });

  const [queryLineDate, setQueryLineDate] = useState({
    startDate: moment().subtract('days', 14),
    endDate: moment(),
    viewMode: "day",
  });

  const onChangeDate = function({ startDate, endDate }) {
    setQueryDate({ startDate, endDate,  });
  };

  const onChangeLineDate = function({ startDate, endDate, viewMode }) {
    setQueryLineDate({ startDate, endDate , viewMode});
  };
  const onChangeRangePicker = function(value, dateString) {
    setQueryDate({ startDate: value[0], endDate: value[1] });
  };

  const fetchPopularTopics = () => {
    callApi(`v2/dashboard/popular-topics?${makeUrlObj(queryDate)}`, "GET").then(
      (res) => {
        setTopics(res.data);
      } 
    );
  };

  const fetchTimeRegister = () => {
    callApi(
      `v2/dashboard/time-register-user?${makeUrlObj(queryLineDate)}`,
      "GET"
    ).then((res) => {
      // /console.log('res', handleTimeReigterUser(res, queryLineDate.viewMode ))
      setConfig(handleTimeReigterUser(res, queryLineDate.viewMode));
      setData({
        userTotal: get(res, 'data.users', []).reduce((p,{ count }) => p + count, 0),
        userRegisterCompare: get(res, 'data.countUserRegisterCompare[0].countUser', 0),
        googleAnalytics: {
          vistor:  get(res, 'data.googleAnalytics.vistor', 0),
          avgPageView: get(res, 'data.googleAnalytics.avgPageView', 0),
        },
        googleAnalyticsCompare: {
          vistor:  get(res, 'data.googleAnalyticsCompare.vistor', 0),
          avgPageView: get(res, 'data.googleAnalyticsCompare.avgPageView', 0),
        } 
      })
    });
  };
  useEffect(() => {
    fetchPopularTopics();
    fetchTimeRegister();
  }, []);
  useEffect(() => {
    fetchPopularTopics();
  }, [queryDate]);
  useEffect(() => {
    fetchTimeRegister();
  }, [queryLineDate]);

  return (
    <Row gutter={24}>
      <Col span={12}>
      <Info data={data} />
        <h3 style={{ margin: "40px 0px" }}>Registered Users</h3>
        
        <Line data={config.data} options={config.options} />

        <div style={{ marginBottom: "30px" }}></div>
        <DropDownDate onChange={onChangeLineDate} defaultValue="14 days" />
      </Col>
      <Col span={12}>
        <h2 style={{ marginBottom: "20px" }}>Popular Topics</h2>
        <Table
          pagination={false}
          dataSource={topics}
          columns={columns}
          footer={() => (
            <Row gutter={24} type="flex" align="middle">
              <Col className="gutter-row" span={6}>
                <DropDownDate onChange={onChangeDate} defaultValue="14 days" />
              </Col>
              <Col className="gutter-row" span={12}>
                <FilterDateRanger onChange={onChangeRangePicker} />
              </Col>
            </Row>
          )}
        />
      </Col>
    </Row>
  );
};

export default EditorSection;
