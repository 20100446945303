import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Container,
  Row,
  Col,
  // Card,
  // CardBody,
  // CardTitle,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { actGetEditorDashboardHeaderRequest } from "../../actions/editordashboard.action";
// import Button from "components/CustomButton/CustomButton";

class CardsHeaderEditor extends React.Component {
  state = {
    pathnameRoutes: window.location.pathname.split("/")[3]
  };

  componentDidMount() {
    this.props.getDashboardHeader();
  }

  render() {
    // const pathname = this.state.pathname;
    const pathnameRoutes = this.state.pathnameRoutes;
    // const { header_count } = this.props;
    return (
      <>
        <div className="header pb-6" style={{ backgroundColor: "#008fe8" }}>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  {this.props.name !== "Dashboard" && (
                    <Breadcrumb
                      className="d-none d-md-inline-block ml-md-4"
                      listClassName="breadcrumb-links breadcrumb-dark"
                    >
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="fas fa-home" />
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {this.props.parentName}
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem
                        aria-current="page"
                        className="active"
                        style={{ color: "#adb4bd" }}
                      >
                        {this.props.name}
                      </BreadcrumbItem>
                    </Breadcrumb>
                  )}
                </Col>
                {pathnameRoutes === "available-briefs" ||
                pathnameRoutes === "assigned-briefs" ? (
                  <Col lg="6 text-right">
                    <Link to={"/admin/editor/new-brief"}>
                      <button
                        type="button"
                        className="btn-icon btn btn-secondary"
                      >
                        <span className="mr-1">
                          {/* <i className="fab fa-pinterest"></i> */}
                          <i
                            className="fa fa-plus-circle"
                            style={{ color: "#5e72e4" }}
                          ></i>
                        </span>

                        <span
                          className="btn-inner--text"
                          style={{ color: "#5e72e4" }}
                        >
                          Add New Brief
                        </span>
                      </button>
                    </Link>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    header_count: state.editorDashboardReducer.header_count
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getDashboardHeader: data => {
      dispatch(actGetEditorDashboardHeaderRequest(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsHeaderEditor);

CardsHeaderEditor.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  content: PropTypes.string,
  header_count: PropTypes.object,
  getDashboardHeader: PropTypes.func
};
