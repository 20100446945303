import React from "react";
import PropTypes from "prop-types";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
// core components
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import SideList from "../components/SideList/SideList";
import { actResetFetchResource } from "../actions/fetch.action";
import { connect } from "react-redux";
import AlertSuccess from "components/SweetAlert/AlertSuccess";
import AlertError from "components/SweetAlert/AlertError";
import { setLanguage } from "redux-i18n";
import get from "lodash/get";
import {
  actGetMyPermissionRequest,
  actGetCountInfoRequest,
  actGetCountInfoChiefEditorRequest
} from "actions/permission.action";
import { actGetTotalTag } from "../actions/tagmanagement.action";
import { actGetMyInformationRequest } from "../actions/authentication.action";
import {
  actGetTotalPendingApplication,
  actGetTotalProcessedApplication
} from "../actions/application.action";
import {
  handleSocket,
  listenNotiFromServer,
  handleSocketWithEditorAndCOE,
  handleSocketWithCOE,
  handleSocketWithWriterEditor
  // listenNotiMenuCounterMenu
} from "../variables/socket";
import {
  // actGetNewNotificationListRequest,
  actGetNotificationNewListRequest
} from "../actions/notification.action";
import getUrlParam from "../utils/getUrlParam";
import { actGetWriterCountDashboardListRequest } from "../actions/writerArticle.action";
import { actGetCountInfoEditorRequest } from "../actions/permission.action";
import {
  actGetContentRequestListRequest,
  actGetMyAssignedPostRequest
} from "../actions/writerArticle.action";
import * as authenStorage from "../utils/authen-storage";
import {
  role,
  ARRAY_APPLICATION_STATUS_PROCESSED,
  APPLICATION_STATUS
} from "../utils/constants";
import { makeUrl } from "../utils/makeUrl";

const pageHasSidelist = ["tuychinh"];

export class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.setStateFlagNew = this.setStateFlagNew.bind(this);
  }

  state = {
    sidenavOpen: true,
    flagNew: false,
    new_notification: [],
    dataNew_notification: [],
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10
    },
    tagToConvertData: {}
  };

  componentDidMount() {
    const jobKey = localStorage.getItem("key");
    handleSocket();
    handleSocketWithCOE();
    handleSocketWithEditorAndCOE();
    handleSocketWithWriterEditor();
    let checkLogin = this.checkLogin();
    if (checkLogin) {
      this.checkSidelistShow();
      this.setLangToLocalStorage();
      this._getMyPermission();
      this._getMyInformation();
      this._getNewNotification();
      listenNotiFromServer(data => {
        if (data.type) {
          this._getNewNotification();
          this.setState({
            flagNew: true
          });
          this.notify("You have new notifications", "success", "br");
        }
        // this.props.getNewNotification(data);
      });
      if (jobKey === role.WRITER) {
        this._getWriterCountMenuDashboard();
      }
      if (jobKey === role.CHIEFEDITOR || jobKey === role.EDITOR) {
        const pendingStatus = makeUrl("status", [APPLICATION_STATUS.NEW]);
        const processedStatus = makeUrl(
          "status",
          ARRAY_APPLICATION_STATUS_PROCESSED
        );
        this.props.getTotalTag();
        this.props.getTotalPendingApplication(pendingStatus);
        this.props.getTotalProcessedApplication(processedStatus);
      }
      if (jobKey === role.CHIEFEDITOR) {
        this._getCountInfoChiefEditor();
      }
      if (jobKey === role.EDITOR) {
        this._getCountInfoEditor();
      }
    }

    // listenNotiMenuCounterMenu(key => {
    //   switch (key) {
    //     case "writer":
    //       this._getWriterCountMenuDashboard();
    //       this._getListWriterAvailableBriefs();
    //       this._getListWriterAssignedPost();
    //       break;
    //     case "chiefeditor":
    //       this._getCountInfoChiefEditor();
    //       break;
    //     case "editor":
    //       this._getCountInfoEditor();
    //       break;
    //   }
    // });
  }

  _getListWriterAssignedPost = () => {
    const params = this.state.params;
    this.props.getListWriterAssignedPost(params);
  };

  _getListWriterAvailableBriefs = () => {
    const params = this.state.params;
    this.props.getListWriterAvailableBriefs(params);
  };

  _getCountInfo = () => {
    this.props.getCountInfo();
  };

  _getCountInfoChiefEditor = () => {
    this.props.getCountInfoChiefEditor();
  };

  _getCountInfoEditor = () => {
    this.props.getCountInfoEditor();
  };

  _getWriterCountMenuDashboard = () => {
    this.props.getWriterCountMenuDashboard();
  };

  _getNewNotification = () => {
    this.props.getNewNotification();
  };

  setStateFlagNew(flag) {
    this.setState({
      flagNew: flag
    });
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
      this.checkSidelistShow();
    }
  }
  _getMyPermission = () => {
    this.props.getMyPermission();
  };

  _getMyInformation = () => {
    this.props.getMyInformation();
  };

  setLangToLocalStorage = () => {
    const lang = this.props.lang;
    if (!localStorage.getItem("lang")) localStorage.setItem("lang", lang);
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.hasTagToConvertState) {
      this.setState({tagToConvertData: {...nextProps.hasTagToConvertState.tags}})
    }

    if (nextProps.fetchSuccess) {
      this.setState({ fetchSuccess: true });
    } else if (nextProps.fetchSuccess === null) {
      this.setState({ fetchFail: false });
    } else if (nextProps.fetchFail) {
      this.setState({ fetchFail: true });
    } else if (nextProps.fetchFail) {
      this.setState({ fetchFail: false });
    }
    if (nextProps.notify !== null && nextProps.notify !== this.props.notify) {
      this.notify(nextProps.notify.message, nextProps.notify.color);
    }
  }
  notify(message, color, place = "tr") {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: color,
      icon: "ni ni-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  checkSidelistShow = () => {
    const pathname = window.location.pathname.split("/")[2];
    if (pageHasSidelist.includes(pathname) && !this.state.isSidelistShow) {
      this.setState({
        isSidelistShow: pageHasSidelist.includes(pathname)
      });
    } else if (
      !pageHasSidelist.includes(pathname) &&
      this.state.isSidelistShow
    ) {
      this.setState({
        isSidelistShow: pageHasSidelist.includes(pathname)
      });
    }
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.path}
            exact={prop.exact}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };

  checkLogin = () => {
    if (!authenStorage.getToken()) {
      this.props.history.push("/admin/login");
    } else {
      return true;
    }
  };

  render() {
    const props = this.props;
    const key = localStorage.getItem("key");
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        {props.fetchSuccess && (
          <AlertSuccess
            {...props}
            message={props.fetchSuccess.message}
            confirmTo={props.fetchSuccess.confirmTo}
            resetFetchResource={props.resetFetchResource}
          />
        )}
        {props.fetchFail && (
          <AlertError
            {...props}
            message={props.fetchFail.message}
            confirmTo={props.fetchFail.confirmTo}
            resetFetchResource={props.resetFetchResource}
          />
        )}
        <Sidebar
          {...this.props}
          count_official_tags={get(this.state.tagToConvertData, 'officialTag')}
          count_suggest_tags={get(this.state.tagToConvertData, 'suggestTag')}
          hasTagToConvert={get(this.state.tagToConvertData, 'hasTagConvert')}
          count_info={this.props.count_info}
          count_chiefeditor={this.props.count_chiefeditor}
          writer_count_menu_dashboard={this.props.writer_count_menu_dashboard}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          totalPending={this.props.pending}
          totalProcessed={this.props.processed}
          logo={{
            innerLink: "/",
            imgSrc: require("../assets/img/logo.svg"),
            imgAlt: "..."
          }}
        />
        <div
          className="main-content d-flex"
          // eslint-disable-next-line react/no-string-refs
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <SideList
            history={this.props.history}
            isShow={this.state.isSidelistShow}
          />
          <div
            className={
              "main-content-container " +
              (this.state.isSidelistShow && "sidelist-show")
            }
          >
            <AdminNavbar
              {...this.props}
              setStateFlagNew={this.setStateFlagNew}
              flagNew={this.state.flagNew}
              lang={this.props.lang}
              new_notification={this.props.new_notification}
              setLanguage={this.props.setLanguage}
              theme={this.getNavbarTheme()}
              toggleSidenav={this.toggleSidenav}
              sidenavOpen={this.state.sidenavOpen}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
            <Switch>
              {this.getRoutes(routes)}
              {key === "admin" ? (
                <Redirect from="/admin" to="/admin/settings/employee/writer" />
              ) : key === "editor" ? (
                <Redirect from="/admin" to="/admin/editor/dashboard-editor" />
              ) : key === "writer" ? (
                <Redirect from="/admin" to="/admin/writer/dashboard-writer" />
              ) : key === "chiefeditor" ? (
                <Redirect from="/admin" to="/admin/chief/dashboard-chief" />
              ) : (
                <Redirect from="/admin" to="/admin/login" />
              )}
            </Switch>

            <AdminFooter />
          </div>
          {this.state.sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
          ) : null}
        </div>
      </>
    );
  }
}

Admin.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    fetchSuccess: state.fetchReducer.fetchSuccess,
    fetchFail: state.fetchReducer.fetchFail,
    notify: state.fetchReducer.notify,
    lang: state.i18nState.lang,
    mypermission: state.permissionReducer.mypermission,
    count_info: state.permissionReducer.count_info,
    count_chiefeditor: state.permissionReducer.count_chiefeditor,
    writer_count_menu_dashboard:
      state.writerArticleReducer.writer_count_menu_dashboard,
    new_notification: state.notificationReducer.new_notification,
    pending: state.applicationReducer.total.pending,
    processed: state.applicationReducer.total.processed,
    hasTagToConvertState: state.tagManagementReducer.totalTag,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTotalTag: () => {
      dispatch(actGetTotalTag());
    },
    resetFetchResource: () => {
      dispatch(actResetFetchResource());
    },
    setLanguage: lang => {
      dispatch(setLanguage(lang));
    },
    getMyPermission: data => {
      dispatch(actGetMyPermissionRequest(data));
    },
    getMyInformation: data => {
      dispatch(actGetMyInformationRequest(data));
    },
    getNewNotification: data => {
      dispatch(actGetNotificationNewListRequest(data));
    },
    getCountInfo: data => {
      dispatch(actGetCountInfoRequest(data));
    },
    getCountInfoChiefEditor: data => {
      dispatch(actGetCountInfoChiefEditorRequest(data));
    },
    getWriterCountMenuDashboard: data => {
      dispatch(actGetWriterCountDashboardListRequest(data));
    },
    getCountInfoEditor: data => {
      dispatch(actGetCountInfoEditorRequest(data));
    },
    getListWriterAvailableBriefs: params => {
      dispatch(actGetContentRequestListRequest(params));
    },
    getListWriterAssignedPost: params => {
      dispatch(actGetMyAssignedPostRequest(params));
    },
    getTotalPendingApplication: params => {
      dispatch(actGetTotalPendingApplication(params));
    },
    getTotalProcessedApplication: params => {
      dispatch(actGetTotalProcessedApplication(params));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
