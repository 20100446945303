import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify, actFetchResourceFail } from "./fetch.action";
import renderError from "utils/renderServerError";
import {
  actGetWritingContentByIDRequest,
  actGetOutlineVersionsRequest
} from "./writerArticle.action";
import {
  actGetCountInfoChiefEditorRequest,
  actGetCountInfoEditorRequest
} from "./permission.action";

export const actGetArticleListRequest = data => {
  return dispatch => {
    return callApi(
      `article/get-list-pending-approval?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetArticleList(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetArticleList = data => {
  return {
    type: Types.GET_AVAILABLE_REQUEST_LIST,
    data
  };
};

export const actGetContentRequestDetail = data => {
  return {
    type: Types.GET_CONTENT_REQUEST_DETAIL,
    data
  };
};

export const actGetOutlineApprovalListRequest = (data, type, number) => {
  if (!type && !number) {
    return dispatch => {
      return callApi(
        `article/get-list-pending-outline-approval?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=createdAt|-1`,
        "GET"
      )
        .then(res => {
          dispatch(actGetOutlineApprovalList(res));
          dispatch(actGetCountInfoEditorRequest());
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  } else {
    return dispatch => {
      return callApi(
        `article/get-list-pending-outline-approval?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=${type}|${number}`,
        "GET"
      )
        .then(res => {
          dispatch(actGetOutlineApprovalList(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  }
};

export const actGetOutlineApprovalList = data => {
  return {
    type: Types.GET_OUTLINE_APPROVAL_LIST,
    data
  };
};

export const actGetContentApprovalListRequest = (data, type, number) => {
  if (!type && !number) {
    return dispatch => {
      return callApi(
        `article/get-list-pending-content-approval?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=createdAt|-1`,
        "GET"
      )
        .then(res => {
          dispatch(actGetContentApprovalList(res));
          dispatch(actGetCountInfoEditorRequest());
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  } else {
    return dispatch => {
      return callApi(
        `article/get-list-pending-content-approval?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=${type}|${number}`,
        // `|${number}`,

        "GET"
      )
        .then(res => {
          dispatch(actGetContentApprovalList(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  }
};

export const actGetContentApprovalList = data => {
  return {
    type: Types.GET_CONTENT_APPROVAL_LIST,
    data
  };
};

export const actApproveContentRequest = (id, data) => {
  return dispatch => {
    let jobKey = localStorage.getItem("key");
    callApi(`article/approve-content/${id}`, "PUT", data)
      .then(() => {
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
        dispatch(actGetWritingContentByIDRequest(id));
        dispatch(
          actNotify({
            message: "Article content has been approved",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actRejectContentRequest = (id, data) => {
  return dispatch => {
    let jobKey = localStorage.getItem("key");
    callApi(`article/approve-content/${id}`, "PUT", data)
      .then(() => {
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
        dispatch(actGetWritingContentByIDRequest(id));
        dispatch(
          actNotify({
            message: "Article content has been rejected",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSendBackContentRequest = (id, data) => {
  return dispatch => {
    let jobKey = localStorage.getItem("key");
    callApi(`article/approve-content/${id}`, "PUT", data)
      .then(() => {
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
        dispatch(actGetWritingContentByIDRequest(id));
        dispatch(
          actNotify({
            message: "Article content has been sent back to writer",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSubmitEditingRequest = (id, data) => {
  return dispatch => {
    let jobKey = localStorage.getItem("key");
    callApi(`article/approve-content-editing/${id}`, "PUT", data)
      .then(() => {
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
        dispatch(actGetWritingContentByIDRequest(id));
        dispatch(
          actNotify({
            message: "Article content has been submitted",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSubmitEditingRequestV2 = (id, data) => {
  return dispatch => {
    let jobKey = localStorage.getItem("key");
    callApi(`v2/article/approve-content-editing`, "PUT", {
      articleId: id,
      ...data
    })
      .then(() => {
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
        dispatch(actGetWritingContentByIDRequest(id));
        dispatch(
          actNotify({
            message: "Article content has been submitted",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetInProgressListRequest = (data, type, number) => {
  if (!type && !number) {
    return dispatch => {
      return callApi(
        `article/get-list-in-process?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=createdAt|-1`,
        "GET"
      )
        .then(res => {
          dispatch(actGetInProgressList(res));
          dispatch(actGetCountInfoEditorRequest());
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  } else {
    return dispatch => {
      return callApi(
        `article/get-list-in-process?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=${type}|${number}`,
        "GET"
      )
        .then(res => {
          dispatch(actGetInProgressList(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  }
};

export const actApproveOutlineRequest = (id, data) => {
  return dispatch => {
    let jobKey = localStorage.getItem("key");
    callApi(`article/approve-outline/${id}`, "PUT", data)
      .then(res => {
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
        dispatch(actGetOutlineDetailIDRequest(id));
        dispatch(
          actNotify({
            message: "Outline has been approved",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actRejectOutlineRequest = (id, data) => {
  return dispatch => {
    let jobKey = localStorage.getItem("key");
    callApi(`article/approve-outline/${id}`, "PUT", data)
      .then(res => {
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
        dispatch(actGetOutlineDetailIDRequest(id));
        dispatch(
          actNotify({
            message: "Outline has been rejected",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actSendBackOutlineRequest = (id, data) => {
  return dispatch => {
    let jobKey = localStorage.getItem("key");
    callApi(`article/approve-outline/${id}`, "PUT", data)
      .then(res => {
        if (jobKey === "editor") {
          dispatch(actGetCountInfoEditorRequest());
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetCountInfoChiefEditorRequest());
        }
        dispatch(actGetOutlineDetailIDRequest(id));
        dispatch(
          actNotify({
            message: "Outline has been sent back",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetOutlineDetailIDRequest = id => {
  return dispatch => {
    return callApi(`article/outline-detail/${id}`, "GET")
      .then(res => {
        dispatch(actGetOutlineDetailByID(res));
        dispatch(actGetCountInfoEditorRequest());
        // dispatch(actGetOutlineVersionsRequest(res.data.outlineID));
      })
      .catch(err => {
        console.log(err);

        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetOutlineDetailByID = data => {
  return {
    type: Types.GET_OUTLINE_DETAIL_BY_ID,
    data
  };
};
export const actGetInProgressList = data => {
  return {
    type: Types.GET_IN_PROGRESS_ARTICLE,
    data
  };
};

export const actSavedraftOutLineRequest = data => {
  return dispatch => {
    return callApi(`outline/save-draft-auto`, "PUT", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Save draft successfully !",
            color: "info"
          })
        );
      })
      .catch(() => {
        dispatch(
          actFetchResourceFail({
            message: "Error! Please try again later",
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actGetAssignedPostListRequest = data => {
  return dispatch => {
    return callApi(
      `article/get-list-assigned?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,

      "GET"
    )
      .then(res => {
        dispatch(actGetAssignedPostList(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetAssignedPostList = data => {
  return {
    type: Types.GET_ASSIGNED_POST,
    data
  };
};

export const actGetOutlineInProgressListRequest = data => {
  return dispatch => {
    return callApi(
      `article/outline-inprocess?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetOutlineInProgressList(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetOutlineInProgressList = data => {
  return {
    type: Types.GET_EDITOR_OUTLINE_IN_PROGRESS,
    data
  };
};

export const actGetArticlesInProgressListRequest = data => {
  return dispatch => {
    return callApi(
      `article/article-inprocess?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetArticlesInProgressList(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetArticlesInProgressList = data => {
  return {
    type: Types.GET_EDITOR_ARTICLES_IN_PROGRESS,
    data
  };
};

export const actGetTodayDeadlineListRequest = (data, type, number) => {
  if (!type && !number) {
    return dispatch => {
      return callApi(`article/dead-line-editor`, "GET")
        .then(res => {
          dispatch(actGetTodayDeadlineList(res));
          dispatch(actGetCountInfoEditorRequest());
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  } else {
    return dispatch => {
      return callApi(`article/dead-line-editor?&sort=${type}|${number}`, "GET")
        .then(res => {
          dispatch(actGetTodayDeadlineList(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  }
};

export const actGetTodayDeadlineList = data => {
  return {
    type: Types.GET_EDITOR_TODAY_DEADLINE,
    data
  };
};

export const actGetEditorPublishedArticlesRequest = data => {
  return dispatch => {
    return callApi(
      `article/article-publish?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetEditorPublishedArticles(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetEditorPublishedArticles = data => {
  return {
    type: Types.GET_EDITOR_PUBLISHED_ARTICLES,
    data
  };
};

export const actGetEditorEditingArticlesRequest = data => {
  return dispatch => {
    return callApi(
      `article/article-editing?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetEditorEditingArticles(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetEditorEditingArticles = data => {
  return {
    type: Types.GET_EDITOR_EDITING_ARTICLES,
    data
  };
};

export const actGetEditorWriterProfileRequest = data => {
  return dispatch => {
    return callApi(
      `user-followings?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}` +
        `&topic=${data.topic}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetEditorWriterProfile(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetEditorWriterProfile = data => {
  return {
    type: Types.GET_EDITOR_WRITER_PROFILE,
    data
  };
};

export const actGetEditorWriterInfoByIDRequest = (id, data, type, number) => {
  if (!type && !number) {
    return dispatch => {
      return callApi(
        `article-writer-following/${id}?` +
          `page=${data.page}` +
          `&limit=${data.limit}`,
        "GET"
      )
        .then(res => {
          dispatch(actGetEditorWriterInfoByID(res));
          dispatch(actGetCountInfoEditorRequest());
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  } else {
    return dispatch => {
      return callApi(
        `article-writer-following/${id}?` +
          `page=${data.page}` +
          `&limit=${data.limit}` +
          `&sort=${type}|${number}`,
        "GET"
      )
        .then(res => {
          dispatch(actGetEditorWriterInfoByID(res));
        })
        .catch(err => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger"
            })
          );
        });
    };
  }
};

export const actGetEditorWriterInfoByID = data => {
  return {
    type: Types.GET_EDITOR_WRITER_INFO_BY_ID,
    data
  };
};

export const actGetDraftBriefsListRequest = data => {
  return dispatch => {
    return callApi(
      `article-draft?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(acttGetDraftBriefsList(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const acttGetDraftBriefsList = data => {
  return {
    type: Types.GET_DRAFT_BRIEFS,
    data
  };
};

export const actGetAllBriefsRequest = data => {
  return dispatch => {
    return callApi(
      `article/list-brief?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&assign_to_editor=${data.assign_to_editor}` +
        `&angle_title=${data.angle_title}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetAllBriefsList(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetAllBriefsList = data => {
  return {
    type: Types.GET_EDITOR_ALL_BRIEFS,
    data
  };
};

export const actGetRejectAssignedListRequest = data => {
  return dispatch => {
    return callApi(
      `list-reject-assign-article?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetRejectAssignedList(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetRejectAssignedList = data => {
  return {
    type: Types.GET_EDITOR_REJECT_ASSIGNED,
    data
  };
};

// Update outline deadline

export const actUpdateOutlineDeadlineRequest = (id, data) => {
  return dispatch => {
    callApi(`v2/outline/dead-line/${id}`, "PUT", data)
      .then(() => {
        dispatch(actGetOutlineDetailIDRequest(id));
        dispatch(
          actNotify({
            message: "Deadline has been updated successfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// Save inline comment content reviewing article from writer
export const actSaveOutlineContentRequest = data => {
  return dispatch => {
    callApi(`v2/outline/content`, "PUT", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Outline content & comments have been saved successfully!",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
