import React, { Component } from "react";
import { Card, CardHeader, Table, Col, Row, CardFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatStringToDate } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import { actGetEditorPublishedArticlesRequest } from "../../actions/editorArticle.action";
import Avatar from "../Avatar/Avatar";

class PublishedArticlesTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getEditorPublishedArticles();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getEditorPublishedArticles = () => {
    const params = this.state.params;
    this.props.getEditorPublishedArticles(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getEditorPublishedArticles(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getEditorPublishedArticles(paramsSort);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/editor/published-article/${id}`);
  };

  render() {
    const { editor_pulished_articles } = this.props;
    const page =
      editor_pulished_articles.data && editor_pulished_articles.data.page;
    const totalPages =
      editor_pulished_articles.data && editor_pulished_articles.data.totalPages;
    const totalDocs =
      editor_pulished_articles.data && editor_pulished_articles.data.totalDocs;

    const renderStatus = status => {
      switch (status) {
        case "UNPUBLISHED":
          return (
            <>
              <span className="status-cancel">●</span>
              <span>Unpublished</span>
            </>
          );
        case "PUBLISHED":
          return (
            <>
              <span className="status-completed">●</span>
              <span>Published</span>
            </>
          );
        case "COMPLETED":
          return (
            <>
              <span className="status-completed">●</span>
              <span>Completed</span>
            </>
          );
        default:
      }
      return null;
    };

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Completed Articles</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {editor_pulished_articles.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th className="text-center">Dual Language</th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("writer")}
                        >
                          Writer
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("start_date")}
                        >
                          Writer accepts on
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("dead_line")}
                        >
                          Deadline
                        </th>
                        <th scope="col">Status</th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {editor_pulished_articles.data
                        ? editor_pulished_articles.data.docs.map(
                            (prop, key) => {
                              return (
                                <>
                                  <tr
                                    key={key}
                                    onClick={e =>
                                      this._handleOnCellClick(e, prop.id)
                                    }
                                    className="cursor-pointer"
                                  >
                                    <th scope="row">
                                      {this._text_truncate(
                                        prop.angle_title,
                                        50,
                                        "..."
                                      )}
                                    </th>
                                    <td className="text-center text-success">
                                      {prop.dualLanguage_article !== null &&
                                        prop.dualLanguage_article && (
                                          <i
                                            className="fa fa-check-circle"
                                            style={{ fontSize: 16 }}
                                          ></i>
                                        )}
                                    </td>
                                    <td className="table-user">
                                      <Avatar
                                        src={prop.writer.avatar}
                                        className="d-inline-block mr-3"
                                      />
                                      <b>
                                        {prop.writer ? prop.writer.name : ""}
                                      </b>
                                    </td>
                                    <td className="budget">
                                      {formatStringToDate(prop.start_date)}
                                    </td>
                                    <td>
                                      {formatStringToDate(prop.dead_line)}
                                    </td>
                                    <td>{renderStatus(prop.status_article)}</td>
                                    <td>
                                      {prop
                                        ? prop.topic.map(topic => {
                                            return (
                                              <>
                                                <div className="mt-2  _topic">
                                                  {this._text_truncate(
                                                    topic.name
                                                      ? topic.name
                                                      : topic.label,
                                                    20,
                                                    "..."
                                                  )}
                                                </div>
                                              </>
                                            );
                                          })
                                        : ""}
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        : ""}
                    </tbody>
                  </Table>
                )}

                {editor_pulished_articles.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    editor_pulished_articles:
      state.editorArticleReducer.editor_pulished_articles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getEditorPublishedArticles: params => {
      dispatch(actGetEditorPublishedArticlesRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishedArticlesTable);
PublishedArticlesTable.propTypes = {
  editor_pulished_articles: PropTypes.object,
  getEditorPublishedArticles: PropTypes.func
};
PublishedArticlesTable.defaultProps = {};
