import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Row, Col } from "reactstrap";
import LOGO from "assets/img/logo_vietcetera.png";
import LOGIN from "assets/img/login.png";

class AuthHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header" style={{ position: "relative" }}>
          {/* <Container>
            <div className="login mt--8 pb-5"></div>
          </Container> */}
          <Row
            className="justify-content-start"
            style={{ position: "absolute", height: "100%" }}
          >
            <Col
              className="text-center"
              style={{ left: "10%", width: "400px" }}
            >
              <div className="p-5">
                <img src={LOGO} alt="" width="146" />
              </div>
            </Col>
            <Col className="authHeaderContent text-center">
              <div className="text-center">
                <h1 className="text-white">Welcome!</h1>
                <p>Use these awesome forms to login or create new </p>
                <p style={{ marginTop: "-15px" }}>
                  account in your project for free.
                </p>
              </div>
            </Col>
          </Row>
          <div className="">
            <img
              src={LOGIN}
              alt=""
              style={{ width: "100%", height: "100vh" }}
            />
          </div>
        </div>
      </>
    );
  }
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string
};

export default AuthHeader;
