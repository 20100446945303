import React, { Component } from "react";
import { Card, CardHeader, Table, Col, Row, CardFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetWriterProfileListRequest } from "../../actions/chiefArticle.action";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import Avatar from "../Avatar/Avatar";
import { actGetListTopicRequest } from "../../actions/employee.action";
import Select from "react-select";
class ChiefWriterProfileTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : -1,
      topic: getUrlParam()["topic"] ? getUrlParam()["topic"] : ""
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getWriterProfile();
    this.props.getListTopic();
  }

  _getWriterProfile = () => {
    const params = this.state.params;
    this.props.getWriterProfile(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getWriterProfile(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };
  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getWriterProfile(paramsSort);
  };

  _handleOnFilter = (label, value) => {
    const state = this.state;
    const params = {
      ...state.params,
      [label]: value
    };
    this.setState({
      params: {
        ...state.params,
        [label]: value
      }
    });
    this._handlePathnamePush(params);
    this.props.getWriterProfile(params);
  };

  _renderStatus = status => {
    switch (status) {
      case "WORKING":
        return (
          <>
            <span className="status-completed">●</span>
            <span>Working</span>
          </>
        );
      case "STOP_WORKING":
        return (
          <>
            <span className="status-cancel">●</span>
            <span>Not working</span>
          </>
        );
      default:
    }
    return null;
  };
  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/chief/writer-profile-table-detail/${id}`);
  };
  render() {
    const { writer_profile_list } = this.props;
    const page = writer_profile_list.data && writer_profile_list.data.page;
    const totalPages =
      writer_profile_list.data && writer_profile_list.data.totalPages;
    const totalDocs =
      writer_profile_list.data && writer_profile_list.data.totalDocs;
    const { expertiseOption } = this.props;

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col md="3">
                    <Select
                      className="react-select filter"
                      classNamePrefix="react-select"
                      name="topic"
                      placeholder="Select interest"
                      options={expertiseOption}
                      size="lg"
                      value={expertiseOption.filter(
                        ({ value }) => value === this.state.params.topic
                      )}
                      onChange={value =>
                        this._handleOnFilter("topic", value.value)
                      }
                    />
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {writer_profile_list.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("name")}
                        >
                          Writer
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("expertise")}
                        >
                          Level of Expertise
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("articles")}
                        >
                          Article
                        </th>
                        <th
                          className="sort"
                          data-sort="phone"
                          scope="col"
                          onClick={() => this._sortByColumn("phone")}
                        >
                          Phone
                        </th>
                        <th
                          className="sort"
                          data-sort="mail"
                          scope="col"
                          onClick={() => this._sortByColumn("mail")}
                        >
                          MAIL
                        </th>
                        <th className="sort" scope="col">
                          STATUS
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {writer_profile_list.data
                        ? writer_profile_list.data.docs.map((prop, key) => {
                            return (
                              <>
                                <tr
                                  key={key}
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    this._handleOnCellClick(e, prop._id)
                                  }
                                >
                                  <th scope="row">
                                    <Avatar
                                      src={prop.avatar}
                                      className="d-inline-block mr-3"
                                    />
                                    <span>{prop.name}</span>
                                  </th>
                                  <td className="budget">
                                    {prop.expertise[0]}
                                  </td>
                                  <td className="budget">{prop.articles}</td>
                                  <td className="table-user">{prop.phone}</td>
                                  <td className="budget">{prop.email}</td>
                                  <td>{this._renderStatus(prop.status)}</td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                )}

                {writer_profile_list.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writer_profile_list: state.chiefArticleReducer.writer_profile_list,
    expertiseOption: state.employeeReducer.expertiseOption
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getListTopic: () => {
      dispatch(actGetListTopicRequest());
    },
    getWriterProfile: params => {
      dispatch(actGetWriterProfileListRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefWriterProfileTable);
ChiefWriterProfileTable.propTypes = {
  writer_profile_list: PropTypes.object,
  getWriterProfile: PropTypes.func,
  listTopic: PropTypes.object,
  getListTopic: PropTypes.func,
  expertiseOption: PropTypes.array
};
ChiefWriterProfileTable.defaultProps = {};
