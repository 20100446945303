import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "reactstrap";
// redux
import { useDispatch, useSelector } from "react-redux";
import MultipleCheckboxSelectAll from "../MultipleCheckboxSelect/MultipleCheckboxSelectAll";
import { actPinCollectionToHomeRequest } from "../../actions/chief-collection.action";

function ChiefAddCollectionToPinHomeModal(props) {
  const dispatch = useDispatch();
  const { collectionList } = props;
  const [collectionOption, setCollectionOption] = useState([]);
  const [valueSearch, setValues] = useState("");
  const [selectAll, useSelectAll] = useState(false);
  const [arrSelect, setArrSelect] = useState([]);
  const [collectionID, setCollectionID] = useState([]);

  useEffect(() => {
    setCollectionOption(collectionList);
  }, [collectionList]);

  const onSelect = arr => {
    if (collectionID.includes(arr.id)) {
      setCollectionID(collectionID.filter(prop => prop !== arr.id));
      setArrSelect(arrSelect.filter(prop => prop._id !== arr.id));
    } else {
      setCollectionID([...collectionID, arr.id]);
      setArrSelect([...arrSelect, arr]);
    }
  };

  const onSelectAll = () => {
    if (!selectAll) {
      useSelectAll(true);
      setArrSelect(collectionOption);
      let selectId = [];
      collectionOption.map(item => {
        selectId.push(item.id);
        return selectId;
      });
      setCollectionID(selectId);
    } else {
      useSelectAll(false);
      setArrSelect([]);
      setCollectionID([]);
    }
  };

  const change_alias = alias => {
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      " "
    );
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str;
  };

  const handleChange = e => {
    const searchValue = change_alias(e.target.value);
    setValues(e.target.value);
    const optionFor = collectionOption;
    const filterData = optionFor.filter(entry =>
      change_alias(entry.title).includes(searchValue)
    );

    if (searchValue !== "") {
      setCollectionOption(filterData);
    } else {
      setCollectionOption(props.collectionList);
    }
  };

  const handleClearAll = () => {
    setCollectionID([]);
    setArrSelect([]);
    useSelectAll(false);
  };

  const handleRemoveItemSelected = id => {
    setArrSelect(arrSelect.filter(el => el.id !== id));
    setCollectionID(collectionID.filter(el => el !== id));
  };

  const handleRemoveAll = () => {
    setValues("");
    useSelectAll(false);
    setCollectionID([]);
    setArrSelect([]);
  };

  const toggle = () => {
    props.toggle();
    setCollectionID([]);
    setArrSelect([]);
    useSelectAll(false);
    setCollectionOption(collectionList);
  };

  const pinCollection = () => {
    const data = {
      collection_id: collectionID
    };
    if (props.languagueKey) {
      let params = {
        language: "VN"
      };

      dispatch(actPinCollectionToHomeRequest(data, params));
      toggle();
    } else {
      let params = {
        language: "EN"
      };

      dispatch(actPinCollectionToHomeRequest(data, params));
      toggle();
    }
    // props.toggle();
  };

  const pinned_collections = useSelector(
    state => state.ChiefCollectionReducer.pinned_collections
  );

  const restCollection = 7 - pinned_collections.length;
  const totalCollection = collectionID.length + pinned_collections.length;

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={props.isAdd}
      toggle={toggle}
      style={{ minWidth: 608 }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add New Collection
        </h5>
      </div>
      <div className="modal-body">
        <Row>
          <Col md="12">
            <div className="mb-2 d-flex justify-content-between">
              <span className="text-regular">Add Collections</span>
              <span
                className="text-regular"
                style={{
                  color: "#5E72E4",
                  fontSize: "12px",
                  fontWeight: "normal"
                }}
              >
                {totalCollection < 7
                  ? `Choose at least ${restCollection} topic`
                  : ""}
              </span>
            </div>
            <div>
              <MultipleCheckboxSelectAll
                valueInput={valueSearch}
                options={collectionOption}
                selectedId={collectionID}
                arrSelect={arrSelect}
                onSelect={onSelect}
                selectAll={selectAll}
                onSelectAll={onSelectAll}
                handleChange={handleChange}
                handleClearAll={handleClearAll}
                hasSelectAll={true}
              />
              <div className="feature-article-list-select-scroll">
                {arrSelect.map((item, index) => {
                  return (
                    <div className="feature-article-list-select" key={index}>
                      <span className="select-title">{item.title}</span>
                      <span
                        className="select-remove"
                        onClick={() => handleRemoveItemSelected(item.id)}
                      >
                        <i className="fa fa-trash-alt" />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer d-flex justify-content-between">
        <div>
          {arrSelect.length > 0 && (
            <Button
              color="link"
              style={{ color: "#5E72E4", fontWeight: "normal" }}
              data-dismiss="modal"
              type="button"
              onClick={handleRemoveAll}
            >
              Remove selected
            </Button>
          )}
        </div>
        <div>
          <Button
            color="link"
            style={{ paddingRight: "0px", color: "#172B4D" }}
            type="button"
            onClick={toggle}
          >
            CANCEL
          </Button>
          <Button
            color="link"
            type="button"
            style={{ color: "#fb6340" }}
            onClick={pinCollection}
          >
            ADD TO LIST
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ChiefAddCollectionToPinHomeModal;

ChiefAddCollectionToPinHomeModal.propTypes = {
  isAdd: PropTypes.bool,
  toggle: PropTypes.func,
  collectionList: PropTypes.array,
  languagueKey: PropTypes.bool
};
