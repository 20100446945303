import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Form,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import { actGetPostInfoRequestV2 } from "../../actions/post.action";
import { actGetMyPitchDetailRequestV2 } from "../../actions/pitch.action";

class EditorCardContentMapping extends Component {
  state = {
    id: window.location.pathname.split("/")[4],
    data: {
      audience: "",
      angle_title: "",
      type_of_article: [],
      topics_of_concern: [],
      steps_to_write: [],
      age_group: [],
      comprehension_level: [],
      tone_of_writing: [],
      topic: [],
      objectives: ""
    },
    isAllInputFilled: false
  };

  componentDidMount() {
    this.props.getPostInfo();
    this._getPitchDetailID(this.state.id);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    // const data = {};
    if (nextProps.pitch_detail !== this.props.pitch_detail) {
      let pitch_detail = nextProps.pitch_detail.data;
      const data = {
        ...pitch_detail
      };
      this.setState({
        data
      });
      if (
        data.steps_to_write &&
        data.steps_to_write[0] &&
        data.steps_to_write[0].key === "other"
      ) {
        this.setState({ isOtherSteps: true });
      }
      if (
        data.tone_of_writing &&
        data.tone_of_writing[0] &&
        data.tone_of_writing[0].key === "other"
      ) {
        this.setState({ isOtherTone: true });
      }
    }
  }

  _getPitchDetailID = id => {
    this.props.getPitchDetail(id);
  };

  _checkedValue(arrayFilter, arrayInput, key) {
    var result = arrayFilter.filter(item1 =>
      arrayInput.some(item2 => item2.key === item1.key)
    );
    return result.some(item => item.key === key);
  }

  _getOtherValue(arrayOther) {
    var otherValue = arrayOther.filter(value => {
      return value.key === "other";
    });
    return otherValue[0] && otherValue[0].name;
  }

  createMarkup(text) {
    return { __html: text };
  }

  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  render() {
    const { post_info, topics_name } = this.props;
    const { data } = this.state;
    // const {
    //   age_group,
    //   angle_title,
    //   audience,
    //   comprehension_level,
    //   objectives,
    //   steps_to_write,
    //   tone_of_writing,
    //   topic,
    //   topics_of_concern,
    //   type_of_article
    // } = data;

    return (
      <Card
        className="shadow"
        style={{ color: "#525f7f", overflow: "auto", height: "640px" }}
      >
        <CardHeader>
          <Row className="align-items-center"></Row>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="7">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="title">
                    Angle/Title
                  </label>
                  <Input
                    id="angle_title"
                    name="angle_title"
                    value={data.angle_title && data.angle_title}
                    type="text"
                    className="d-inline mr-3"
                    disabled
                  />
                  <small className="float-right">
                    {data.angle_title ? data.angle_title.length : "0"}/60
                  </small>
                </FormGroup>
              </Col>
              <Col md="5">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="topic">
                    Topic
                  </label>
                  <div className="custom-vietcetera-topic">
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      size="lg"
                      closeMenuOnSelect={true}
                      value={data.topic}
                      options={topics_name}
                      //   onChange={value =>
                      //     this._handleOnSelectTopicChange(value.key, value.name)
                      //   }
                      isDisabled
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-0">
                  <label
                    className="form-control-label d-block"
                    htmlFor="mention_problem"
                  >
                    What is the main problem/question you would like to address
                    ?
                  </label>
                  {this.rawHTML(data.mention_problem && data.mention_problem)}
                </FormGroup>
              </Col>
            </Row>
            <hr className="my-2" />
            <Row style={{ paddingLeft: "15px" }} className="mb-0">
              <label
                className="form-control-label d-block"
                htmlFor="articleType"
              >
                Type of article (Choose one)
              </label>
            </Row>
            <Row className="mb-3">
              {post_info.data.typesOfArticle &&
                post_info.data.typesOfArticle.map((prop, key) => {
                  return (
                    <Col key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"article" + prop.key}
                          name="articletypes"
                          checked={this._checkedValue(
                            post_info.data.typesOfArticle,
                            data.type_of_article,
                            prop.key
                          )}
                          title={prop.key}
                          pattern={prop.name}
                          value={prop.name}
                          type="checkbox"
                          disabled
                          //   onChange={this._handleOnChangeCheckboxArticle.bind(
                          //     this
                          //   )}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"article" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row style={{ paddingLeft: "15px" }} className="mb-0">
              <label
                className="form-control-label d-block"
                htmlFor="concernType"
              >
                Type of Concerns (Choose one)
              </label>
            </Row>
            <Row className="mb-3">
              {post_info.data.topicsOfConcern &&
                post_info.data.topicsOfConcern.map((prop, key) => {
                  return (
                    <Col key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"concern" + prop.key}
                          type="checkbox"
                          name="concerntypes"
                          checked={this._checkedValue(
                            post_info.data.topicsOfConcern,
                            data.topics_of_concern,
                            prop.key
                          )}
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          //   onChange={this._handleOnChangeCheckboxConcern.bind(
                          //     this
                          //   )}
                          disabled
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"concern" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row className="mt-1">
              <Col md="12">
                <label
                  className="form-control-label d-block mt-1"
                  htmlFor="objectives"
                >
                  Objectives / What can the readers get from reading your
                  article?
                </label>
                {this.rawHTML(data.objectives && data.objectives)}
              </Col>
            </Row>
            <hr className="my-2" />
            <label
              className="form-control-label d-block"
              htmlFor="targetAudience"
              style={{ marginTop: "32px" }}
            >
              Target Audience
            </label>
            <Row className="mt-3">
              <Col md="3">
                <label
                  className="form-control-label mb-0 mt-1"
                  htmlFor="agegroup"
                >
                  Age Group:
                </label>
              </Col>
              {post_info.data.ageGroups &&
                post_info.data.ageGroups.map((prop, key) => {
                  return (
                    <Col key={key}>
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          id={"age" + prop.key}
                          type="checkbox"
                          name="agetypes"
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          checked={this._checkedValue(
                            post_info.data.ageGroups,
                            data.age_group,
                            prop.key
                          )}
                          disabled
                          //   onChange={this._handleOnChangeCheckboxAge.bind(this)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"age" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row className="mt-3">
              <Col md="3">
                <label
                  className="form-control-label mb-0 mt-1"
                  htmlFor="comLevel"
                >
                  Comprehension Level of reader:
                </label>
              </Col>
              {post_info.data.comprehensionLevels &&
                post_info.data.comprehensionLevels.map((prop, key) => {
                  return (
                    <Col key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"comlevels" + prop.key}
                          type="checkbox"
                          name="compretypes"
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          checked={this._checkedValue(
                            post_info.data.comprehensionLevels,
                            data.comprehension_level,
                            prop.key
                          )}
                          //   onChange={this._handleOnChangeCheckboxCompreLevels.bind(
                          //     this
                          //   )}
                          disabled
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"comlevels" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row className="mt-3">
              <Col md="12">
                <label
                  className="form-control-label mb-0 mt-1"
                  htmlFor="audience"
                >
                  Target Audicence:
                </label>
                {this.rawHTML(data.audience && data.audience)}
              </Col>
            </Row>
            <hr className="my-2" />
            <Row className="mt-3" style={{ paddingLeft: "15px" }}>
              <label className="form-control-label d-block" htmlFor="step">
                Steps required to write this article (You can choose many)
              </label>
            </Row>
            <Row className="mb-3">
              {post_info.data.stepsToWrite &&
                post_info.data.stepsToWrite.map((prop, key) => {
                  return (
                    <Col md="3" key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"step" + prop.key}
                          name="stepsWrite"
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          type="checkbox"
                          checked={this._checkedValue(
                            post_info.data.stepsToWrite,
                            data.steps_to_write,
                            prop.key
                          )}
                          //   onChange={this._handleOnChangeCheckboxStepWrite.bind(
                          //     this
                          //   )}
                          disabled
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"step" + prop.key}
                        >
                          {prop.key === "other" ? "Other" : prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {this.state.isOtherSteps && (
              <Row className="mt-3">
                <Col md="12">
                  <Input
                    id="stepsOther"
                    name="stepsOther"
                    type="text"
                    value={this._getOtherValue(data.steps_to_write)}
                    className="d-inline mr-3"
                    placeholder="Insert other steps here"
                    // onChange={this._handleOnInputStepsOtherChange}
                    disabled
                  />
                </Col>
              </Row>
            )}
            <Row className="mt-4" style={{ paddingLeft: "15px" }}>
              <label className="form-control-label d-block" htmlFor="tone">
                Tone of writing (Choose one)
              </label>
            </Row>
            <Row className="mb-3">
              {post_info.data.tonesOfWriting &&
                post_info.data.tonesOfWriting.map((prop, key) => {
                  return (
                    <Col md="3" key={key}>
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          className="custom-control-input"
                          id={"tone" + prop.key}
                          type="checkbox"
                          name="tonetypes"
                          title={prop.key}
                          value={prop.name}
                          pattern={prop.name}
                          checked={this._checkedValue(
                            post_info.data.tonesOfWriting,
                            data.tone_of_writing,
                            prop.key
                          )}
                          //   onChange={this._handleOnChangeCheckboxToneWrite.bind(
                          //     this
                          //   )}
                          disabled
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"tone" + prop.key}
                        >
                          {prop.name}
                        </label>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {this.state.isOtherTone && (
              <Col md="12">
                <Row className="mt-3">
                  <Input
                    id="toneothers"
                    name="toneothers"
                    type="text"
                    className="d-inline"
                    value={this._getOtherValue(data.tone_of_writing)}
                    placeholder="Describe your desired tone of voice."
                    readOnly
                    disabled
                    // onChange={this._handleOnInputToneOtherChange}
                  />
                </Row>
              </Col>
            )}
            <Row className="mt-3">
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label d-block" htmlFor="note">
                    Note
                  </label>
                  {this.rawHTML(data.note && data.note)}
                </FormGroup>
              </Col>
            </Row>
            <hr className="my-4" />
          </Form>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    pitch_detail: state.pitchReducer.pitch_detail,
    post_info: state.postReducer.post_info,
    // post_detail: state.postReducer.post_detail,
    topics_name: state.postReducer.topics_name
    // topics_name_select: state.postReducer.topics_name_select,
    // tags_by_topic: state.postReducer.tags_by_topic,
    // writer_list: state.postReducer.writer_list,
    // editor_list: state.postReducer.editor_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPostInfo: data => {
      dispatch(actGetPostInfoRequestV2(data));
    },
    getPitchDetail: id => {
      dispatch(actGetMyPitchDetailRequestV2(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorCardContentMapping);
EditorCardContentMapping.propTypes = {
  pitch_detail: PropTypes.object,
  post_info: PropTypes.object,
  topics_name: PropTypes.array,
  toggleNavs: PropTypes.func,
  getPostInfo: PropTypes.func,
  getPitchDetail: PropTypes.func,
  pitchData: PropTypes.func
};
EditorCardContentMapping.defaultProps = {};
