import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetMenuListRequest = data => {
  return dispatch => {
    return callApi(`v2/menu-topic?language=${data.language}`, "GET")
      .then(res => {
        dispatch(actGetMenuList(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetMenuList = data => {
  return {
    type: Types.GET_MENU_LIST,
    data
  };
};

export const actResetMenuList = () => {
  return {
    type: Types.RESET_MENU_LIST
  };
};

export const actRemoveMenu = index => {
  return {
    type: Types.REMOVE_MENU,
    index
  };
};

export const actGetTopicListRequest = data => {
  return dispatch => {
    return callApi(`menu/list-topic?language=${data.language}`, "GET")
      .then(res => {
        dispatch(actGetTopicList(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetTopicList = data => {
  return {
    type: Types.GET_TOPIC_LIST,
    data
  };
};
export const actAddTopicToMenuRequest = (data, params) => {
  return dispatch => {
    const bodyParams = {
      ...data,
      ...params
    };
    return callApi(`v2/menu-topic`, "POST", bodyParams)
      .then(() => {
        dispatch(
          actNotify({
            message: "Success!",
            color: "success"
          })
        );
        dispatch(actGetMenuListRequest(params));
        dispatch(actGetTopicListRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actRemoveMenuRequest = (id, params) => {
  return dispatch => {
    return callApi(`v2/menu-topic/${id}`, "DELETE")
      .then(() => {
        dispatch(
          actNotify({
            message: "Success!",
            color: "success"
          })
        );
        dispatch(actGetMenuListRequest(params));
        dispatch(actGetTopicListRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actUpdateMenuRequest = (data, params) => {
  return dispatch => {
    const bodyParams = {
      ...data,
      ...params
    };
    return callApi(`v2/menu-topic`, "PUT", bodyParams)
      .then(() => {
        dispatch(
          actNotify({
            message: "Success!",
            color: "success"
          })
        );
        dispatch(actGetMenuListRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetGroupTopicRequest = data => {
  return dispatch => {
    return callApi(`grouptopic/list?language=${data.language}`)
      .then(res => {
        dispatch(actGetGroupTopic(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetGroupTopic = data => {
  return {
    type: Types.GET_GROUP_TOPIC,
    data
  };
};

export const actGetTopicByGroupRequest = data => {
  return dispatch => {
    return callApi(
      `topics?sort=createdAt|-1&groupTopic=${data._id}&language=${data.language}&limit=999999999`
    )
      .then(res => {
        dispatch(actGetTopicByGroup(res.data.docs));
        dispatch(actGetGroupDetail(data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetAllTopicRequest = data => {
  let url = `topics?limit=999999999&language=${data.language}&sort=createdAt|-1`;
  if (data.sort) {
    url = `topics?limit=999999999&language=${data.language}&sort=${data.sort.type}|${data.sort.number}`;
  }
  return dispatch => {
    return callApi(
      url
    )
      .then(res => {
        dispatch(actGetTopicByGroup(res.data.docs));
        dispatch(actGetAllTopic(res.data.docs));
        dispatch(
          actGetGroupDetail({
            name: "All topics"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetAllTopic = data => {
  return {
    type: Types.GET_ALL_TOPIC_LIST,
    data
  };
};

export const actGetTopicByGroup = data => {
  return {
    type: Types.GET_TOPIC_BY_GROUP,
    data
  };
};

export const actGetGroupDetail = data => {
  return {
    type: Types.GET_GROUP_DETAIL,
    data
  };
};

export const actAddGroupTopicRequest = (data, params) => {
  return dispatch => {
    return callApi(`grouptopic/add`, "POST", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "You have created group topic successfully!",
            color: "success"
          })
        );
        dispatch(actGetGroupTopicRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actUpdateGroupTopicRequest = (id, data, group_detail, params) => {
  return dispatch => {
    return callApi(`grouptopic/edit/${id}`, "PUT", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "You have updated group topic successfully!",
            color: "success"
          })
        );
        dispatch(actGetGroupTopicRequest(params));
        group_detail._id
          ? dispatch(actGetTopicByGroupRequest(group_detail))
          : dispatch(actGetAllTopicRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actDeleteGroupTopicRequest = (id, group_detail, params) => {
  return dispatch => {
    return callApi(`grouptopic/delete/${id}`, "DELETE")
      .then(() => {
        dispatch(
          actNotify({
            message: "You have deleted group topic successfully!",
            color: "success"
          })
        );
        dispatch(actGetGroupTopicRequest(params));
        group_detail._id
          ? dispatch(actGetTopicByGroupRequest(group_detail))
          : dispatch(actGetAllTopicRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actAddTopicRequest = (data, group_detail, params) => {
  return dispatch => {
    return callApi(`topic/add-new`, "POST", data)
      .then(() => {
        dispatch(actGetGroupTopicRequest(params));
        dispatch(
          actNotify({
            message: "You have created topic successfully!",
            color: "success"
          })
        );
        group_detail._id
          ? dispatch(actGetTopicByGroupRequest(group_detail))
          : dispatch(actGetAllTopicRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetTopicDetailRequest = id => {
  return dispatch => {
    return callApi(`topic/detail/${id}`)
      .then(res => {
        dispatch(actGetTopicDetail(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetTopicDetail = data => {
  return {
    type: Types.GET_TOPIC_DETAIL,
    data
  };
};

export const actUpdateTopicRequest = (id, data, group_detail, params) => {
  return dispatch => {
    return callApi(`topic/update/${id}`, "PUT", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "You have updated topic successfully!",
            color: "success"
          })
        );
        dispatch(actGetGroupTopicRequest(params));
        group_detail._id
          ? dispatch(actGetTopicByGroupRequest(group_detail))
          : dispatch(actGetAllTopicRequest(params));
      })
      .catch(err => {
        console.log(err);
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actDeleteTopicRequest = (id, group_detail, params) => {
  return dispatch => {
    return callApi(`topic/delete/${id}`, "DELETE")
      .then(() => {
        dispatch(
          actNotify({
            message: "You have deleted topic successfully!",
            color: "success"
          })
        );
        dispatch(actGetGroupTopicRequest(params));
        group_detail._id
          ? dispatch(actGetTopicByGroupRequest(group_detail))
          : dispatch(actGetAllTopicRequest(params));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetArtilceByTopicRequest = data => {
  return dispatch => {
    return callApi(
      `topic/articlesbytopic/${data.key}?limit=9999999&angle_title=${data.search}`
    )
      .then(res => {
        dispatch(actGetArtilceByTopic(res.data.docs));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetArtilceByTopic = data => {
  return {
    type: Types.GET_ARTICLE_BY_TOPIC,
    data
  };
};

export const actGetTopicSelectRequest = data => {
  return dispatch => {
    return callApi(`grouptopic/detail/${data.groupID}`)
      .then(res => {
        callApi(`topics/search-topic?language=${data.language}`)
          .then(res1 => {
            const listTopic = res.data.listTopic;
            const listSelect = res1.data;
            dispatch(actGetTopicSelect(listTopic.concat(listSelect)));
          })
          .catch(err => {
            dispatch(
              actNotify({
                message: renderError(err),
                color: "danger"
              })
            );
          });
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetAllTopicSelectRequest = data => {
  return dispatch => {
    return callApi(`topics/search-topic?language=${data.language}`)
      .then(res => {
        const listSelect = res.data;
        dispatch(actGetTopicSelect(listSelect));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetTopicSelect = data => {
  return {
    type: Types.GET_TOPIC_SELECT,
    data
  };
};
