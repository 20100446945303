var handleSocket = async function () {
};

var handleSocketWithEditorAndCOE = async function () {
};

var handleSocketWithCOE = async function () {
};

var handleSocketWithWriterEditor = async function () {
};

var listenNotiFromServer = function (callback) {
};

var listenNotiMenuCounterMenu = function (callback) {
}


export {
  handleSocket,
  listenNotiFromServer,
  handleSocketWithEditorAndCOE,
  handleSocketWithCOE,
  handleSocketWithWriterEditor,
  listenNotiMenuCounterMenu
};
