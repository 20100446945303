import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  outlines_dashboard: {},
  articles_dashboard: {},
  pitches_dashboard: {}
};

const writerDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_OUTLINE_WRITER_DASHBOARD:
      state = {
        ...state,
        outlines_dashboard: action.data[0]
      };
      return { ...state };
    case Types.GET_ARTICLE_WRITER_DASHBOARD:
      state = {
        ...state,
        articles_dashboard: action.data[0]
      };
      return { ...state };
    case Types.GET_PITCH_WRITER_DASHBOARD:
      state = {
        ...state,
        pitches_dashboard: action.data[0]
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default writerDashboardReducer;
