import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import WriterListContentRequestTable from "../../../components/Tables/WriterListContentRequestTable";
class WriterListContentRequest extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeader
          name="Marketplace"
          content="availablebriefs"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <WriterListContentRequestTable {...props} />
        </Container>
      </>
    );
  }
}
WriterListContentRequest.propTypes = {
  content: PropTypes.string,
};
export default WriterListContentRequest;
