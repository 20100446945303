import React, { Component } from "react";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { get } from "lodash";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Button,
  FormGroup,
  Form
} from "reactstrap";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

class CardContentBrief extends Component {
  state = {
    toggleDeadline: false,
    newDeadline: ""
  };
  createMarkup(text) {
    return { __html: text };
  }
  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }
  handleToggleDeadline = () => {
    this.setState(prevState => ({
      toggleDeadline: !prevState.toggleDeadline
    }));
  };

  handleDeadlineChange = value => {
    this.setState({
      newDeadline: value
    });
  };
  handleExtendDeadline = () => {
    const { outline_detail } = this.props;
    const { newDeadline } = this.state;
    const data = {
      dead_line: newDeadline
    };
    this.props.updateDeadline(outline_detail.data._id, data);
    this.handleToggleDeadline();
  };
  render() {
    const { outline_detail } = this.props;
    const validDate = current => {
      var yesterday = moment().subtract(1, "day");
      return current.isAfter(yesterday);
    };
    const jobKey = localStorage.getItem("key");
    const outlineStatus = get(outline_detail, "data.status_article");
    const allowExtend =
      jobKey !== "writer" && outlineStatus !== "OUTLINE_SUBMITTED";
    return (
      <Card className="shadow" style={{ color: "#525f7f" }}>
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="12">
              <h2 className="mb-0 pt-1">
                {outline_detail.data &&
                  ReactHtmlParser(outline_detail.data.angle_title)}
              </h2>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form>
            <div className="">
              {outline_detail.data && outline_detail.data.dualLanguage
                ? outline_detail.data.dualLanguage && (
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Published article title:
                          </label>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        {outline_detail.data &&
                          outline_detail.data.dualLanguage_article && (
                            <a
                              target="blank"
                              href={`/admin/writer/view-detail-content-writer/${outline_detail.data.dualLanguage}`}
                            >
                              {ReactHtmlParser(
                                outline_detail.data.dualLanguage_article
                                  .angle_title
                              )}
                            </a>
                          )}
                      </Col>
                    </Row>
                  )
                : null}
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Topic:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? outline_detail.data.topic.map(topic => {
                          return (
                            <>
                              <div className="_topic">
                                <label>{topic.name}</label>
                              </div>
                            </>
                          );
                        })
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Outline Deadline:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <div className="d-flex align-items-center">
                      {!this.state.toggleDeadline ? (
                        <>
                          <strong>
                            {moment(
                              get(outline_detail.data, "dead_line")
                            ).format("MMMM Do YYYY")}
                          </strong>
                          {allowExtend && (
                            <Button
                              className="ml-3"
                              color="success"
                              outline
                              type="button"
                              size="sm"
                              onClick={this.handleToggleDeadline}
                            >
                              Extend
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          <ReactDatetime
                            isValidDate={validDate}
                            inputProps={{
                              placeholder: "Choose new deadline"
                            }}
                            value={this.state.newDeadline}
                            onChange={value => this.handleDeadlineChange(value)}
                            timeFormat={"HH:mm"}
                            dateFormat={"DD/MM/YYYY"}
                          />
                          <Button
                            className="ml-3"
                            color="success"
                            outline
                            type="button"
                            size="sm"
                            onClick={this.handleExtendDeadline}
                          >
                            Save
                          </Button>
                          <Button
                            className="ml-3"
                            color="danger"
                            outline
                            type="button"
                            size="sm"
                            onClick={this.handleToggleDeadline}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Word Count:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {" "}
                    {outline_detail.data
                      ? outline_detail.data.min_word +
                        " - " +
                        outline_detail.data.max_word +
                        " words"
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Tags:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? outline_detail.data.tags.map(tags => {
                          return (
                            <>
                              <span>{"#" + tags + " "}</span>
                            </>
                          );
                        })
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Key Word:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  {outline_detail.data
                    ? outline_detail.data.keywords.map(keyword => {
                        return (
                          <>
                            <span>{"#" + keyword + " "}</span>
                          </>
                        );
                      })
                    : ""}
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Type of Article:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? outline_detail.data.type_of_article.map(
                          typeofarticle => {
                            return (
                              <>
                                <span>{typeofarticle.name + " "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Topics of Concerns:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? outline_detail.data.topics_of_concern.map(
                          topicsofconcern => {
                            return (
                              <>
                                <span>{topicsofconcern.name + " "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Objectives:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? this.rawHTML(outline_detail.data.objectives)
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Age Group:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? outline_detail.data.age_group.map(age_group => {
                          return (
                            <>
                              <span>{age_group.name + " "}</span>
                            </>
                          );
                        })
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Comprehension Level:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? outline_detail.data.comprehension_level.map(
                          comprehension_level => {
                            return (
                              <>
                                <span>{comprehension_level.name + " "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      References:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? this.rawHTML(outline_detail.data.references)
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Steps Required to Write This Article:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? outline_detail.data.steps_to_write.map(
                          steps_to_write => {
                            return (
                              <>
                                <span>{steps_to_write.name + " "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Things to Avoid:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? this.rawHTML(outline_detail.data.things_to_avoid)
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Things to Mention:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? this.rawHTML(outline_detail.data.things_to_mention)
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Tone of Writing:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? outline_detail.data.tone_of_writing.map(
                          tone_of_writing => {
                            return (
                              <>
                                <span>{tone_of_writing.name + " "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Note:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {outline_detail.data
                      ? this.rawHTML(outline_detail.data.note)
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <hr className="my-4" />
          </Form>
          <div className="text-right">
            <Button
              onClick={e => this.props.toggleNavs(e, "tabs", 2)}
              color="success"
              type="button"
            >
              Write Outline
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CardContentBrief;
CardContentBrief.propTypes = {
  outline_detail: PropTypes.object,
  toggleNavs: PropTypes.func,
  updateDeadline: PropTypes.func
};
CardContentBrief.defaultProps = {};
