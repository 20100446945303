import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetAboutDetailRequest = data => {
  return dispatch => {
    return callApi("general/getInfo", "POST", data)
      .then(res => {
        dispatch(actGetAboutDetail(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetAboutDetail = data => {
  return {
    type: Types.GET_ABOUT_DETAIL,
    data
  };
};

export const actGetRecruitmentDetailRequest = data => {
  return dispatch => {
    return callApi("general/getInfo", "POST", data)
      .then(res => {
        dispatch(actGetRecruitmentDetail(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetRecruitmentDetail = data => {
  return {
    type: Types.GET_RECRUITMENT_DETAIL,
    data
  };
};

export const actGetCompanyDetailRequest = data => {
  return dispatch => {
    return callApi("general/getInfo", "POST", data)
      .then(res => {
        dispatch(actGetCompanytDetail(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetCompanytDetail = data => {
  return {
    type: Types.GET_COMPANY_DETAIL,
    data
  };
};

export const actGetUpdateAboutUsRequest = data => {
  return dispatch => {
    return callApi("general/aboutus", "PUT", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Updated successfully!",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetUpdateRecruitmentRequest = data => {
  return dispatch => {
    return callApi("general/recruitment", "PUT", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Updated successfully!",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetUpdateCompanyProfileRequest = data => {
  return dispatch => {
    return callApi("general/editor-profile", "PUT", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Updated successfully!",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
