import React from "react";
import moment from "moment";
import { Modal } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import ChiefTopicModal from "../Modals/ChiefTopicModal";
import { connect } from "react-redux";
import { actGetCountMenuDashBoardRequest } from "../../actions/chiefArticle.action";
import {
  actSaveScheduleSettingRequest,
  actResetSchedule,
} from "../../actions/feature-articles.action";
import ChiefMainMenuModal from "../Modals/ChiefMainMenuModal";

const { confirm } = Modal;
const dateTimeFormat = "YYYY-MM-DD";

class CardsHeaderchief extends React.Component {
  state = {
    pathnameRoutes: window.location.pathname.split("/")[3],
    isAddTopic: false,
    isAddMenu: false,
  };

  componentDidMount() {
    const { pathnameRoutes } = this.state;
    pathnameRoutes === "dashboard-chief" && this.props.getCountMenuDashBoard();
  }

  _toggleModalAddTopic = () => {
    this.setState((prevState) => ({
      isAddTopic: !prevState.isAddTopic,
    }));
  };

  _toggleModalMenu = () => {
    this.setState((prevState) => ({
      isAddMenu: !prevState.isAddMenu,
    }));
  };

  handleSaveSetting = () => {
    const { featureArticleSchedule, selectedDate } = this.props;
    const { setting } = featureArticleSchedule;
    const sanitizeSetting = setting.map((item) => {
      return {
        key: item.key,
        articles: item.articles.map((article) => {
          return {
            articleId: article.articleId,
            status: article.status,
            scheduleTime: article.scheduleTime,
          };
        }),
      };
    });
    const payload = {
      setting: sanitizeSetting,
      language: "VN",
      date: selectedDate,
    };

    this.props.saveScheduleSetting(payload);
    // this.props.resetSchedule();
  };

  render() {
    // const pathname = this.state.pathname;
    const { selectedDate } = this.props;
    const pathnameRoutes = this.state.pathnameRoutes;
    const today = moment().format(dateTimeFormat);
    const isPastDate = moment(selectedDate).isBefore(today);
    return (
      <>
        {this.state.isAddTopic && (
          <ChiefTopicModal
            modal={this.state.isAddTopic}
            toggle={this._toggleModalAddTopic}
          />
        )}
        <ChiefMainMenuModal
          modal={this.state.isAddMenu}
          toggle={this._toggleModalMenu}
        />
        <div className="header pb-6" style={{ backgroundColor: "#001dbc" }}>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  {this.props.name !== "Dashboard" &&
                    this.props.name !== "Appearance Manage" &&
                    this.props.name !== "Main Menu" && (
                      <Breadcrumb
                        className="d-none d-md-inline-block ml-md-4"
                        listClassName="breadcrumb-links breadcrumb-dark"
                      >
                        <BreadcrumbItem>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <i className="fas fa-home" />
                          </a>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {this.props.parentName}
                          </a>
                        </BreadcrumbItem>
                        <BreadcrumbItem
                          aria-current="page"
                          className="active"
                          style={{ color: "#adb4bd" }}
                        >
                          {this.props.name}
                        </BreadcrumbItem>
                      </Breadcrumb>
                    )}
                </Col>
                {/* {pathnameRoutes === "topic" && (
                  <Col lg="6 text-right">
                    <button
                      type="button"
                      className="btn-icon btn btn-secondary"
                      onClick={this._toggleModalAddTopic}
                    >
                      <span className="mr-1">
                        <i
                          className="fa fa-plus-circle"
                          style={{ color: "#5e72e4" }}
                        ></i>
                      </span>

                      <span
                        className="btn-inner--text"
                        style={{ color: "#5e72e4" }}
                      >
                        New Topic
                      </span>
                    </button>
                  </Col>
                )} */}
                {pathnameRoutes === "manage-feature-articles" && (
                  <Col lg="6 text-right">
                    {!isPastDate && (
                      <button
                        type="button"
                        className="btn-icon btn btn-secondary"
                        onClick={() => {
                          confirm({
                            title: "Save this schedule?",
                            onOk: () => {
                              return this.handleSaveSetting();
                            },
                            onCancel() {},
                          });
                        }}
                      >
                        <span
                          className="btn-inner--text"
                          style={{ color: "#5e72e4" }}
                        >
                          Save schedule
                        </span>
                      </button>
                    )}
                  </Col>
                )}
                {pathnameRoutes === "assigned-briefs" ||
                pathnameRoutes === "available-briefs" ? (
                  <Col lg="6 text-right">
                    <Link to={"/admin/chief/new-brief"}>
                      <button
                        type="button"
                        className="btn-icon btn btn-secondary"
                      >
                        <div className="d-flex align-items-center">
                          <span className="mr-1">
                            <i
                              className="fa fa-plus-circle"
                              style={{ color: "#5e72e4" }}
                            ></i>
                          </span>

                          <span
                            className="btn-inner--text"
                            style={{ color: "#5e72e4" }}
                          >
                            Add New Brief
                          </span>
                        </div>
                      </button>
                    </Link>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {/* {pathnameRoutes === "dashboard-chief" && (
                <Row>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ minHeight: 130 }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-warning mb-0"
                            >
                              <Link to="/admin/chief/outline-in-progress">
                                OUTLINE WRITING
                              </Link>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.count_menu_chief.data[0]
                                ? this.props.count_menu_chief.data[0]
                                    .outline_writing
                                : ""}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i className="fa fa-pen-fancy"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ minHeight: 130 }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-success mb-0"
                            >
                              <Link to="/admin/chief/outline-in-progress">
                                OUTLINE WAITING FOR APPROVAL
                              </Link>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.count_menu_chief.data[0]
                                ? this.props.count_menu_chief.data[0]
                                    .outline_waiting
                                : ""}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                              <i className="fa fa-file-signature"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ minHeight: 130 }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-success mb-0"
                            >
                              <Link to="/admin/chief/article-in-progress">
                                WRITING WAITING FOR APPROVAL
                              </Link>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.count_menu_chief.data[0]
                                ? this.props.count_menu_chief.data[0]
                                    .content_waiting
                                : ""}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-success  text-white rounded-circle shadow">
                              <i className="fa fa-file-signature"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ minHeight: 130 }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-warning mb-0"
                            >
                              <Link to="/admin/chief/editing">EDITING</Link>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.count_menu_chief.data[0]
                                ? this.props.count_menu_chief.data[0].editing
                                : ""}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red  text-white rounded-circle shadow">
                              <i className="fa fa-edit"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )} */}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    count_menu_chief: state.chiefArticleReducer.count_menu_chief,
    saveScheduleLoading: state.featureArticleReducer.saveScheduleLoading,
    featureArticleSchedule: state.featureArticleReducer.featureArticleSchedule,
    selectedDate: state.featureArticleReducer.selectedDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountMenuDashBoard: () => {
      dispatch(actGetCountMenuDashBoardRequest());
    },
    saveScheduleSetting: (data) => {
      dispatch(actSaveScheduleSettingRequest(data));
    },
    resetSchedule: () => {
      dispatch(actResetSchedule());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardsHeaderchief);
CardsHeaderchief.propTypes = {
  name: PropTypes.string,
  featureArticleSchedule: PropTypes.object,
  selectedDate: PropTypes.string,
  parentName: PropTypes.string,
  content: PropTypes.string,
  count_menu_chief: PropTypes.object,
  getCountMenuDashBoard: PropTypes.func,
  menuLength: PropTypes.number,
  handleToggleModal: PropTypes.func,
  saveScheduleSetting: PropTypes.func,
  resetSchedule: PropTypes.func,
};
