import React, { Component } from "react";
import omitBy from "lodash/omitBy";
import { Spin, Tooltip } from "antd";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { Form, Input as InputAnt, Icon, Button, message } from "antd";
import capitalize from "lodash/capitalize";
import { isWebUri } from "valid-url";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import Dropzone from "dropzone";
import Dropzone from "react-dropzone";
import Select from "react-select";
//import TagInput from "../TagInput";
import TagSelector from "../TagSelector";
import TagValidation from "../TagValidation";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Collapse
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { actNotify } from "../../actions/fetch.action";
import ReactDatetime from "react-datetime";
import {
  actGetUnPublishedDetailByID,
  actResetUnPublishedDetailByID,
  actUpdateArticleSettingRequest,
  actRemoveArticleSettingRequest
} from "../../actions/chiefArticle.action";
import { actUpdatePublishingRequest } from "../../actions/openPublishing.action";
import { actGetListStaffRequest } from "../../actions/employee.action";
import { actUpdateImageSizeRequest } from "../../actions/upload.action";
import { actGetPostInfoRequestV2 } from "../../actions/post.action";
import { actGetPublishedCollectionRequest } from "../../actions/chief-collection.action";
import { HOST_PREVIEW } from "variables/hostClient";
import { languageOption, labelOption } from "../../variables/selectOptions";
import callApi from "../../utils/callApi";
import HOST_URL from "../../variables/hostUrl";
import AVA from "../../assets/img/AVA.png";
import { MAX_SIZE_IMAGE, ARTICLE_TYPES } from "../../utils/constants";
import { featureImageModes } from "../../utils/constants";
import get from "lodash/get";
import "./style.scss";
import { ReactComponent as ResetIcon } from "../../assets/img/icons/reset.svg";
// const { Option } = AntSelect;

const { TextArea } = InputAnt;

class PostSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_comment: {},
      setting_detail: {},
      openedCollapses: ["collapseStatus"],
      tags: [],
      topic: [],
      series: null,
      image: null,
      uploadingImage: false,
      previewImage: "",
      previewBrandedLogo: "",
      previewThumbnail: "",
      thumbnailImageId: "",
      imageId: "",
      imageSizes: [],
      time_to_publish: "",
      language: null,
      excerpt: "",
      writer: null,
      slug: "",
      featureImageCaption: "",
      featureImage: "",
      brandedLogoAlt: "",
      brandedLogoId: "",
      brandedLogoLink: "",
      other_lang_link: "",
      collapse: false,
      slugChange: false,
      audioUrl: "",
      errors: {},
      imgError: "",
      featureImageType: null,
      typeOfContent: null,
      summaries: [],
      summariesLength: 0,
      excerptWarn: false,
      label: null
    };
  }

  componentDidMount() {
    // this.dropzoneInitial();
    this._getPostInfo();
    this._getListStaff();
    this._getPublishedCollection();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.details && nextProps.details !== this.props.details) {
      const topic = nextProps.details.data.topic[0];
      const writer = nextProps.details.data.writer;
      const featureImageCaption = get(
        nextProps,
        "details.data.images.featureImageCaption",
        ""
      );
      const featureImage = get(
        nextProps,
        "details.data.images.featureImage",
        ""
      );
      const brandedLogoId = get(nextProps, "details.data.brandedLogo._id", "");
      const brandedLogoAlt = get(nextProps, "details.data.brandedLogoAlt", "");
      const brandedLogoLink = get(
        nextProps,
        "details.data.brandedLogoLink",
        ""
      );
      const featureImageType = get(
        nextProps,
        "details.data.featureImageType",
        ""
      );
      const typeOfContent = get(nextProps, "details.data.typeOfContent", "");
      const otherLangLink = get(nextProps, "details.data.other_lang_link", "");
      const audioUrl = get(nextProps, "details.data.audioUrl", "");
      const labelValue = get(nextProps, "details.data.label", "");
      const language = nextProps.details.data.language;
      const writerArray = [
        { ...writer, value: writer && writer.id, label: writer && writer.name }
      ];
      const topicArray = [
        { ...topic, value: topic && topic.key, label: topic && topic.name }
      ];
      const languageValue = {
        value: language === "VN" ? "VN" : "EN",
        label: language === "VN" ? "Vietnamese" : "English"
      };
      const featureImageTypeObject = {
        value: featureImageType,
        label: capitalize(featureImageType)
      };
      const articleTypeOfContentObject = {
        value: typeOfContent,
        label: capitalize(typeOfContent)
      };
      const labelContentObject = {
        value: labelValue,
        label: ""
      };
      if (labelValue === '18+') {
        labelContentObject.label = '18+ Warning';
      }
      if (labelValue === 'block-ads') {
        labelContentObject.label = 'Block Ads';
      }
      if (labelValue === 'advertising') {
        labelContentObject.label = 'Advertising';
      }
      const previewImageUrl =
        nextProps.details.data.images &&
        `${HOST_URL}${nextProps.details.data.images.url}`;
      const previewBrandedLogoUrl =
        nextProps.details.data.brandedLogo &&
        `${HOST_URL}${nextProps.details.data.brandedLogo.url}`;
      const previewThumbnail =
        nextProps.details.data.thumbnail &&
        `${HOST_URL}${nextProps.details.data.thumbnail.url}`;
      const excerptResult = this.stripHTMLTag(nextProps.details.data.excerpt);
      const summaries = get(nextProps, "details.data.summaries", []);
      const summariesLength = summaries.length;
      const seriesid = get(nextProps, "details.data.collectionId", null);
      const series = this.mapSeriesToDropdown(seriesid);

      this.setState({
        topic: topicArray,
        writer: writerArray,
        tags: nextProps.details.data.tags,
        excerpt: excerptResult,
        previewImage: previewImageUrl,
        brandedLogoAlt: brandedLogoAlt,
        brandedLogoLink: brandedLogoLink,
        brandedLogoId: brandedLogoId,
        previewBrandedLogo: previewBrandedLogoUrl,
        previewThumbnail: previewThumbnail,
        featureImageCaption: featureImageCaption,
        featureImage: featureImage,
        featureImageType: featureImageTypeObject,
        imageId:
          nextProps.details.data.images && nextProps.details.data.images._id,
        thumbnailImageId:
          nextProps.details.data.thumbnail &&
          nextProps.details.data.thumbnail._id,
        slug: nextProps.details.data.slug,
        language: languageValue,
        audioUrl: audioUrl,
        time_to_publish: nextProps.details.data.publishDate
          ? moment(nextProps.details.data.publishDate)
          : "",
        other_lang_link: otherLangLink,
        typeOfContent: articleTypeOfContentObject,
        summaries: summaries,
        summariesLength: summariesLength,
        label: labelContentObject,
        series: series
      });
    }
  }

  mapSeriesToDropdown = id => {
    if (isNil(id)) {
      console.log("helllo");
      return {
        value: "no-series",
        label: "No series"
      };
    }
    const { publishedCollection } = this.props;
    const filter = publishedCollection.filter(item => item.id === id);
    const result = filter.map(item => {
      return {
        value: item.id,
        label: item.title
      };
    });
    return result[0];
  };

  // handle topic change
  _handleOnSelectTopicChange = (topickey, topicname) => {
    let tagsFilter = this.props.post_info.data.topics.filter(
      prop => prop.key === topickey
    );
    this.setState({
      topic: [
        {
          value: topickey,
          label: topicname,
          key: topickey,
          name: topicname,
          tags: tagsFilter[0].tags
        }
      ]
    });
  };

  // handle topic change
  _handleOnSelectSeriesChange = series => {
    this.setState({
      series: series
    });
  };

  _getPostInfo() {
    this.props.getPostInfo();
  }
  _getListStaff() {
    this.props.getListStaff({ status: "WORKING" });
  }
  _getPublishedCollection() {
    const data = {
      language: "VN"
    };
    this.props.getPublishedCollection(data);
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  onTimerOnPublishChange = (value, isReset = false) => {
    // const details = this.props.details;
    if (isReset) {
      this.setState({
        time_to_publish: ""
      });
      return;
    }
    if (moment.isMoment(value) && !isReset) {
      this.setState({
        timePublishChange: value.diff(moment()) != 0,
        time_to_publish: value
      });
    }
  };
  onSlugChange = e => {
    const slug = e.target.value;
    this.setState({
      slug: slug,
      slugChange: true
    });
  };
  onFeatureImageCaption = e => {
    const featureImageCaption = e.target.value;
    this.setState({
      featureImageCaption: featureImageCaption
    });
  };
  onFeatureImage = e => {
    const featureImage = e.target.value;
    this.setState({
      featureImage: featureImage
    });
  };

  handleInputChange = (label, e) => {
    this.setState({
      [label]: e.target.value
    });
  };

  onTagsChange = tags => {
    this.setState({ tags });
  };

  onExcerptChange = e => {
    this.setState({
      excerpt: e.target.value,
      excerptWarn: e.target.value.length > 160
    });
  };

  onAudioChange = e => {
    this.setState({
      audioUrl: e.target.value,
      errors: {}
    });
  };

  onLanguageChange = value => {
    this.setState({
      language: value
    });
  };
  onFeatureImageTypeChange = value => {
    this.setState({
      featureImageType: value
    });
  };
  onBadgeChange = value => {
    this.setState({
      label: value
    });
  };
  onArticleTypeChange = value => {
    this.setState({
      typeOfContent: value
    });
  };

  onDualLanguageUrlChange = e => {
    const slug = e.target.value;
    this.setState({
      other_lang_link: slug
    });
  };

  onCopyPrevewLink = () => {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = `${HOST_PREVIEW}${this.props.details.data.slug}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  };

  createMarkup = text => {
    return { __html: text };
  };

  stripHTMLTag = string => {
    if (!string) {
      return "";
    }
    return string.replace(/(<([^>]+)>)/gi, "");
  };

  _wrapInTag = content => {
    const result = `<p>${content}</p>`;
    return result;
  };

  rawHTML = text => {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  };

  onUpdateImageSize = () => {
    const imageSizes = this.state.imageSizes;
    const id = this.state.image_id;
    imageSizes.forEach(prop => {
      this.props.updateImageSize(id, prop.data);
    });
  };

  onSaveOpenPublishing = () => {
    const details = this.props.details.data;
    const {
      tags,
      topic,
      writer,
      slug,
      excerpt,
      language,
      other_lang_link,
      time_to_publish,
      typeOfContent,
      summaries
    } = this.state;
    let data = {
      tags: tags,
      topic: topic,
      excerpt: this._wrapInTag(excerpt),
      language: language.value,
      otherLanguageLink: other_lang_link,
      publishDate: time_to_publish || "",
      creatorId: writer[0].key,
      articleId: details._id,
      summaries: summaries,
      typeOfContent: typeOfContent.value
    };
    if (this.state.slugChange && this.state.slug !== details.slug) {
      data.slug = slug;
    }
    data = omitBy(data, isNil);
    data = omitBy(data, isEmpty);
    if (isEmpty(time_to_publish)) {
      data.publishDate = "";
    }
    this.setState({ slugChange: false });
    this.props.updateOpenPublishingSettingRequest(data);
  };

  onSave = () => {
    const details = this.props.details.data;
    if (this.state.audioUrl == !"" && !isWebUri(this.state.audioUrl)) {
      this.setState({
        errors: {
          audioUrl: "Please input a valid audio URL"
        }
      });
      return;
    }
    const {
      tags,
      topic,
      writer,
      slug,
      excerpt,
      language,
      other_lang_link,
      time_to_publish,
      featureImageCaption,
      featureImage,
      featureImageType,
      imageId,
      thumbnailImageId,
      audioUrl,
      brandedLogoAlt,
      brandedLogoId,
      brandedLogoLink,
      typeOfContent,
      summaries,
      label,
      series
    } = this.state;
    const { poll } = this.props;
    // TODO: use real tags value instead of array empty
    let data = {
      tags: tags,
      topic: topic,
      excerpt: this._wrapInTag(excerpt),
      language: language.value,
      image: imageId,
      otherLanguageLink: other_lang_link,
      publishDate: time_to_publish || "",
      creatorId: writer[0].key,
      articleId: details._id,
      audioUrl: audioUrl,
      featureImageCaption: featureImageCaption,
      featureImage: featureImage,
      brandedLogoAlt: brandedLogoAlt,
      brandedLogoId: brandedLogoId,
      brandedLogoLink: brandedLogoLink,
      summaries: summaries,
      featureImageType: featureImageType.value,
      thumbnailImageId: thumbnailImageId,
      typeOfContent: typeOfContent.value,
      poll: poll,
      label: label.value,
      collectionId: series && series.value
    };
    if (this.state.slugChange && this.state.slug !== details.slug) {
      data.slug = slug;
    }
    data = omitBy(data, isNil);
    data = omitBy(data, isEmpty);
    this.setState({ slugChange: false });
    if (TagValidation(data.tags)) {
      return;
    }
    if (isEmpty(label.value)) {
      data.label = "";
    }
    if (isEmpty(time_to_publish)) {
      data.publishDate = "";
    }
    if (series && series.value && series.value === "no-series") {
      data.collectionId = "";
    }

    this.props.updateArticleSettingRequest(data);
  };

  onRemove = () => {
    const id = this.props.details.data._id;
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          style={{ display: "block" }}
          title={`Wait`}
          onConfirm={() => {
            this.setState({ alert: "" });
            this.props.removeArticleRequest(id);
          }}
          onCancel={() => {
            this.setState({ alert: "" });
          }}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmButtonText="Yes"
          cancelBtnText="Cancel"
        >
          Are you sure to remove this article?
        </SweetAlert>
      )
    });
  };

  onWriterChange = (writerId, writerName) => {
    this.setState({
      writer: [
        {
          value: writerId,
          label: writerName,
          key: writerId,
          name: writerName
        }
      ]
    });
  };

  onPreviewDrop = files => {
    this.setState({
      imgError: ""
    });
    let file = files[0];
    if (file.size > MAX_SIZE_IMAGE) {
      this.setState({
        imgError: `Maxium file size should be ${MAX_SIZE_IMAGE / 1000}KB`
      });
      return;
    }
    this.uploadToServer(files, this.state.featureImageType.value);
  };

  uploadToServer = async (files, mode) => {
    this.setState({ uploadingImage: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const isPortrait = mode === "portrait";
    const uploadEndpoint = isPortrait
      ? "v2/images/portrait-feature-article"
      : "v2/images/standard-feature-image-article";
    const uploadImage = callApi(uploadEndpoint, "POST", formData, true);
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState({
      imageId: result.data._id,
      previewImage: imageUrl,
      uploadingImage: false
    });
  };

  uploadBrandedLogo = async files => {
    this.setState({ uploadingImage: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const uploadImage = callApi(
      "v2/images/branded-logo",
      "POST",
      formData,
      true
    );
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState({
      brandedLogoId: result.data._id,
      previewBrandedLogo: imageUrl,
      uploadingImage: false
    });
  };

  uploadBrandedLogo = async files => {
    this.setState({ uploadingImage: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const uploadImage = callApi(
      "v2/images/branded-logo",
      "POST",
      formData,
      true
    );
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState({
      brandedLogoId: result.data._id,
      previewBrandedLogo: imageUrl,
      uploadingImage: false
    });
  };
  // Add field summary
  add = () => {
    const { form } = this.props;
    const { summariesLength } = this.state;
    const keys = form.getFieldValue("keys");
    if (keys.length >= 3) {
      message.error("Maximum summaries is 3!");
      return;
    }
    this.setState(
      {
        summariesLength: summariesLength + 1
      },
      () => {
        const nextKeys = keys.concat(summariesLength);
        form.setFieldsValue({
          keys: nextKeys
        });
      }
    );
  };

  // Remove summary field
  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    if (keys.length === 1) {
      return;
    }
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  // handle submit summary
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (values.keys.length === 0) {
        message.error("Please add at least one summary!");
        return;
      }
      if (!err) {
        const { keys, names } = values;
        this.setState({
          summaries: keys.map(key => names[key])
        });
        message.success("Save summaries successfully");
      }
    });
  };

  handleUploadThumbnail = async files => {
    this.setState({ uploadingImage: true });
    let formData = new FormData();
    formData.append("file", files[0]);
    const uploadImage = callApi(
      "v2/images/thumbnail-feature-article",
      "POST",
      formData,
      true
    );
    const result = await uploadImage;
    const imageUrl = `${HOST_URL}${result.data.url}`;
    this.setState({
      thumbnailImageId: result.data._id,
      previewThumbnail: imageUrl,
      uploadingImage: false
    });
  };

  renderArticleTypeNote = () => {
    return (
      <ul className="article-type-note">
        <li>
          <strong>Original:</strong> Content that originates from the in-house
          editorial team.
        </li>
        <li>
          <strong>Branded:</strong> Content written for commercial/branded
          purposes
        </li>
        <li>
          <strong>Outsourced:</strong> Content that originates from a
          third-party (community writer, contributor, experts, etc.)
        </li>
      </ul>
    );
  };

  render() {
    const {
      excerpt,
      other_lang_link,
      slug,
      tags,
      language,
      previewImage,
      uploadingImage,
      featureImageCaption,
      featureImage,
      time_to_publish,
      audioUrl,
      errors,
      brandedLogoAlt,
      brandedLogoLink,
      previewBrandedLogo,
      featureImageType,
      previewThumbnail,
      typeOfContent,
      excerptWarn,
      label
    } = this.state;
    const details = this.props.details.data;
    const { employee_list, isOpenPublishing, publishedCollection } = this.props;
    const employeeListOption =
      employee_list.data && employee_list.data.docs.length > 0
        ? employee_list.data.docs.map(item => {
            return {
              value: item.id,
              name: item.name,
              key: item.id,
              label: item.name
            };
          })
        : [];
    const formatOptionLabel = ({ label, icon }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "10px" }}>
          <img
            src={icon ? HOST_URL + icon : AVA}
            alt=""
            width="32px"
            height="32px"
            style={{ borderRadius: 50 }}
          />
        </div>
        <div style={{ fontSize: "14px" }}>{label}</div>
      </div>
    );
    const isPortrait = get(this.state.featureImageType, "value") === "portrait";
    // const time_to_publish =
    //   moment(details.time_to_publish).diff(moment()) >= 0
    //     ? moment(details.time_to_publish)
    //     : undefined;
    // const validDate = current => {
    //   var yesterday = moment().subtract(1, "day");
    //   return current.isAfter(yesterday);
    // };
    // dynamic form item handling
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const summaries = get(details, "summaries", []);
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 }
      }
    };
    const arrayIndex = summaries.map((item, index) => index);
    getFieldDecorator("keys", { initialValue: arrayIndex });
    const keys = getFieldValue("keys");
    const formItems = keys.map((k, index) => {
      return (
        <Form.Item
          {...formItemLayout}
          className="summary-field-form__item"
          required={false}
          key={k}
        >
          {getFieldDecorator(`names[${k}]`, {
            initialValue: summaries[k],
            validateTrigger: ["onChange", "onBlur"],
            rules: [
              {
                max: 200,
                required: true,
                whitespace: true,
                message: "Invalid field. Please check again!"
              }
            ]
          })(
            <TextArea
              rows={3}
              placeholder="Summary"
              style={{ width: "90%", marginRight: 8 }}
            />
          )}
          {keys.length > 1 ? (
            <Icon
              style={{ color: "red" }}
              onClick={() => this.remove(k)}
              type="delete"
            />
          ) : null}
        </Form.Item>
      );
    });
    const seriesListOptionDynamic =
      publishedCollection &&
      publishedCollection.map(item => {
        return {
          value: item.id,
          label: item.title
        };
      });
    const seriesListOption = [
      { value: "no-series", label: "No series" },
      ...seriesListOptionDynamic
    ];
    return (
      <div className="post-settings">
        {this.state.alert}
        <Card className="mt-3">
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <i className="fa fa-cogs text-primary pr-2"></i>
                <span className="mr-1 font-weight-bold">Article setting</span>
              </div>
              <Button
                onClick={
                  isOpenPublishing ? this.onSaveOpenPublishing : this.onSave
                }
                type="primary"
              >
                {this.state.timePublishChange ? "Save & Publish" : "Save"}
              </Button>
            </div>
          </CardHeader>
          {/* ARticle type */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseType")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseType"
                )}
              >
                <div className="d-flex align-items-center">
                  <h4 className="mb-0">Article Type</h4>
                  <span className="asterisk ml-1">*</span>
                  <Tooltip
                    className="ml-2"
                    title={() => this.renderArticleTypeNote()}
                  >
                    <Icon type="exclamation-circle" />
                  </Tooltip>
                </div>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseType")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <Select
                    className="w-100 react-select"
                    classNamePrefix="react-select"
                    size="lg"
                    options={ARTICLE_TYPES}
                    value={typeOfContent}
                    onChange={value => this.onArticleTypeChange(value)}
                    closeMenuOnSelect={true}
                  />
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Status & Visibility */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseStatus")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseStatus"
                )}
              >
                <h4 className="mb-0">Status & Visibility</h4>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseStatus")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <div className="">
                    {/* Status */}
                    <div className="d-flex justify-content-between">
                      <h5>Status</h5>
                      <a href="#pablo" className="value">
                        {details.status_article !== "PUBLISHED"
                          ? "Unpublished"
                          : "Published"}
                      </a>
                    </div>
                    {/* Timer publish */}
                    {details.status_article !== "PUBLISHED" && (
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <h5 className="mb-0">Publish timer</h5>
                        <div className="d-flex align-items-center">
                          <ReactDatetime
                            // isValidDate={validDate}
                            className="timming-picker"
                            value={time_to_publish || ""}
                            inputProps={{
                              placeholder: "Not Scheduled"
                            }}
                            defaultValue={
                              time_to_publish &&
                              time_to_publish.format("DD/MM/YYYY HH:mm")
                            }
                            onChange={this.onTimerOnPublishChange}
                            timeFormat={"HH:mm"}
                            dateFormat={"DD/MM/YYYY"}
                          />
                        </div>
                        {time_to_publish && (
                          <Tooltip
                            placement="bottomRight"
                            title="Reset to publish not scheduled"
                          >
                            <ResetIcon
                              className="reset-icon"
                              onClick={() =>
                                this.onTimerOnPublishChange("", true)
                              }
                            />
                          </Tooltip>
                        )}
                      </div>
                    )}
                    {/* Remove button */}
                    <Row className="mt-3 text-center">
                      <Col lg="12">
                        <Button type="danger" onClick={this.onRemove}>
                          Remove article
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Author */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseAuthor")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseAuthor"
                )}
              >
                <h4 className="mb-0">Author</h4>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseAuthor")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <Select
                    name="assigned_to"
                    className="react-select"
                    classNamePrefix="react-select"
                    size="lg"
                    closeMenuOnSelect={true}
                    value={this.state.writer}
                    options={employeeListOption}
                    formatOptionLabel={formatOptionLabel}
                    onChange={value =>
                      this.onWriterChange(value.value, value.name)
                    }
                  />
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Permalink */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseLink")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseLink"
                )}
              >
                <h4 className="mb-0">Permalink</h4>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseLink")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <FormGroup>
                    <h5>URL Slug</h5>
                    <Input
                      id="example3cols1Input"
                      value={slug}
                      onChange={this.onSlugChange}
                      type="text"
                    />
                    <span style={{ fontSize: 13 }}>
                      The last part of the URL.{" "}
                      <a href="#pablo" target="_blank">
                        Read about permalinks
                      </a>
                    </span>
                  </FormGroup>
                  <h5> Preview</h5>
                  <a href={`${HOST_PREVIEW}${details._id}`}>
                    {`${HOST_PREVIEW}${details._id}`}
                  </a>
                  <div className="copy-btn mt-2">
                    <Button onClick={this.onCopyPrevewLink} type="ghost">
                      <i className="fa fa-link" />
                      <span>{this.state.copied ? "Copied" : "Copy link"}</span>
                    </Button>
                  </div>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* SERIES */}
          {!isOpenPublishing && (
            <div className="accordion">
              <div className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => this.collapsesToggle("collapseSeries")}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseSeries"
                  )}
                >
                  <div className="d-flex">
                    <h4 className="mb-0">Series</h4>
                    <span className="asterisk ml-1">*</span>
                  </div>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes("collapseSeries")}
                >
                  <CardBody style={{ paddingTop: 0 }}>
                    <div className="custom-vietcetera-topic">
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        size="lg"
                        closeMenuOnSelect={true}
                        value={this.state.series}
                        options={seriesListOption}
                        onChange={value =>
                          this._handleOnSelectSeriesChange(value)
                        }
                      />
                    </div>
                  </CardBody>
                </Collapse>
              </div>
            </div>
          )}

          {/* Topic */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseTopics")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseTopics"
                )}
              >
                <h4 className="mb-0">Topic</h4>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseTopics")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <div className="custom-vietcetera-topic">
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      size="lg"
                      closeMenuOnSelect={true}
                      value={this.state.topic}
                      options={this.props.topics_name}
                      onChange={value =>
                        this._handleOnSelectTopicChange(value.key, value.name)
                      }
                    />
                  </div>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Tags */}
          <TagSelector
            onTagsChange={tags => this.onTagsChange(tags)}
            tags={this.state.tags}
          />
          {/* Feature images */}
          {/* <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseTags")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseTags"
                )}
              >
                <h4 className="mb-0">Tags</h4>
              </CardHeader>
            </div>
          </div> */}
          {!isOpenPublishing && (
            <>
              {/* Feature images */}
              <div className="accordion">
                <div className="card-plain">
                  <CardHeader
                    role="tab"
                    onClick={() => this.collapsesToggle("collapseImg")}
                    aria-expanded={this.state.openedCollapses.includes(
                      "collapseImg"
                    )}
                  >
                    <h4 className="mb-0">
                      {isPortrait ? "Portait Feature Image" : "Feature Image"}
                    </h4>
                  </CardHeader>
                  <Collapse
                    role="tabpanel"
                    isOpen={this.state.openedCollapses.includes("collapseImg")}
                  >
                    <CardBody className="p-0">
                      <div className="px-4 d-flex justify-content-between align-items-center">
                        <span>Image modes:</span>
                        <Select
                          className="w-50"
                          options={featureImageModes}
                          value={featureImageType}
                          onChange={value => {
                            this.onFeatureImageTypeChange(value);
                          }}
                          closeMenuOnSelect={true}
                        ></Select>
                      </div>
                      <div className="p-4 dropzone-wrapper">
                        {!isPortrait && (
                          <h4>Ratio: 16:9 ( Size: 2000 x 1125 )</h4>
                        )}
                        {uploadingImage ? (
                          <div className="loader-absolute">
                            <Spin />
                          </div>
                        ) : null}
                        <Dropzone onDrop={this.onPreviewDrop} accept="image/*">
                          {({
                            getRootProps,
                            getInputProps,
                            isDragReject,
                            isDragActive
                          }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone-cover">
                                {!previewImage ? (
                                  <>
                                    {!isDragActive && "Drop a file to upload!"}
                                    {isDragActive &&
                                      !isDragReject &&
                                      "Drop it to upload"}
                                    {isDragReject &&
                                      "File type not accepted, sorry!"}
                                  </>
                                ) : (
                                  <img
                                    className="w-100 h-100"
                                    alt="Preview"
                                    src={previewImage}
                                  />
                                )}
                              </div>
                              <span className="text-red">
                                {this.state.imgError}
                              </span>
                            </div>
                          )}
                        </Dropzone>
                        <FormGroup>
                          <div
                            className="d-flex"
                            style={{ "margin-top": "10px" }}
                          >
                            <h5>Feature Image’s Caption</h5>
                          </div>
                          <Input
                            value={featureImageCaption}
                            onChange={this.onFeatureImageCaption}
                            type="text"
                          />
                          <div className="d-flex align-items-center">
                            <h5 style={{ "margin-top": "10px" }}>
                              Feature Image’s Alternative Text{" "}
                            </h5>
                            <Tooltip
                              title={` The alternative text provides alternative information
                          for an image if a user for some reason cannot view it
                          (because of slow connection, or if the user uses a
                          screen reader).`}
                            >
                              <Icon type="exclamation-circle" />
                            </Tooltip>
                          </div>

                          <Input
                            value={featureImage}
                            onChange={this.onFeatureImage}
                            type="text"
                          />
                        </FormGroup>
                      </div>
                    </CardBody>
                  </Collapse>
                </div>
              </div>
              {/* Thumbnail image upload */}
              <div className="accordion">
                <div className="card-plain">
                  <CardHeader
                    role="tab"
                    onClick={() => this.collapsesToggle("collapseThumbnail")}
                    aria-expanded={this.state.openedCollapses.includes(
                      "collapseThumbnail"
                    )}
                  >
                    <div className="d-flex">
                      <h4 className="mb-0">
                        {isPortrait
                          ? "Portait Thumbnail image"
                          : "Thumbnail image"}
                      </h4>
                    </div>
                  </CardHeader>
                  <Collapse
                    role="tabpanel"
                    isOpen={this.state.openedCollapses.includes(
                      "collapseThumbnail"
                    )}
                  >
                    <CardBody className="p-0">
                      <div className="px-4 dropzone-wrapper">
                        {uploadingImage ? (
                          <div className="loader-absolute">
                            <Spin />
                          </div>
                        ) : null}
                        <Dropzone
                          onDrop={this.handleUploadThumbnail}
                          accept="image/*"
                        >
                          {({
                            getRootProps,
                            getInputProps,
                            isDragReject,
                            isDragActive
                          }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone-cover">
                                {!previewThumbnail ? (
                                  <>
                                    {!isDragActive && "Drop a file to upload!"}
                                    {isDragActive &&
                                      !isDragReject &&
                                      "Drop it to upload"}
                                    {isDragReject &&
                                      "File type not accepted, sorry!"}
                                  </>
                                ) : (
                                  <img
                                    className="w-100 h-100"
                                    alt="Preview"
                                    src={previewThumbnail}
                                  />
                                )}
                              </div>
                              <span className="text-red">
                                {this.state.imgError}
                              </span>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </CardBody>
                  </Collapse>
                </div>
              </div>
            </>
          )}

          {/* Excerpt */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseExcerpt")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseExcerpt"
                )}
              >
                <h4 className="mb-0">Excerpt</h4>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseExcerpt")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <p>Write an excerpt ( Ideally 160 characters )</p>
                  <Input
                    className={`description ${excerptWarn ? "error" : ""}`}
                    value={excerpt}
                    onChange={this.onExcerptChange}
                    rows="5"
                    type="textarea"
                  />
                  <small className="text-right">
                    {excerpt ? excerpt.length : "0"}/160
                  </small>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Language */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseLanguague")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseLanguague"
                )}
              >
                <h4 className="mb-0">Language</h4>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(
                  "collapseLanguague"
                )}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <FormGroup>
                    <Select
                      className="react-select1"
                      classNamePrefix="react-select"
                      name="interest"
                      isClearable
                      options={languageOption}
                      value={language}
                      onChange={value => {
                        this.onLanguageChange(value);
                      }}
                      closeMenuOnSelect={true}
                    />
                  </FormGroup>
                  <>
                    <h5>Translation article slug</h5>
                    <Input
                      type="text"
                      value={other_lang_link}
                      onChange={this.onDualLanguageUrlChange}
                    ></Input>
                  </>
                </CardBody>
              </Collapse>
            </div>
          </div>
          {/* Audio URL */}
          {!isOpenPublishing && (
            <>
              <div className="accordion">
                <div className="card-plain">
                  <CardHeader
                    role="tab"
                    onClick={() => this.collapsesToggle("collapseAudio")}
                    aria-expanded={this.state.openedCollapses.includes(
                      "collapseAudio"
                    )}
                  >
                    <h4 className="mb-0">Audio Link</h4>
                  </CardHeader>
                  <Collapse
                    role="tabpanel"
                    isOpen={this.state.openedCollapses.includes(
                      "collapseAudio"
                    )}
                  >
                    <CardBody style={{ paddingTop: 0 }}>
                      <FormGroup>
                        <Input
                          id="example3cols1Input"
                          value={audioUrl}
                          onChange={this.onAudioChange}
                          type="text"
                        />
                        {errors && errors.audioUrl ? (
                          <p className="text-danger mt-2">
                            <small>{errors.audioUrl}</small>
                          </p>
                        ) : null}
                      </FormGroup>
                    </CardBody>
                  </Collapse>
                </div>
              </div>
              {/* Branded logo field */}
              <div className="accordion">
                <div className="card-plain">
                  <CardHeader
                    role="tab"
                    onClick={() => this.collapsesToggle("collapseBrandedLogo")}
                    aria-expanded={this.state.openedCollapses.includes(
                      "collapseBrandedLogo"
                    )}
                  >
                    <h4 className="mb-0">Branded Logo (Optional)</h4>
                  </CardHeader>
                  <Collapse
                    role="tabpanel"
                    isOpen={this.state.openedCollapses.includes(
                      "collapseBrandedLogo"
                    )}
                  >
                    <CardBody className="p-0">
                      <div className="px-4 dropzone-wrapper">
                        <small className="d-block mb-2">Size: 1200 x 250</small>
                        {uploadingImage ? (
                          <div className="loader-absolute">
                            <Spin />
                          </div>
                        ) : null}
                        <Dropzone
                          onDrop={this.uploadBrandedLogo}
                          accept="image/*"
                        >
                          {({
                            getRootProps,
                            getInputProps,
                            isDragReject,
                            isDragActive
                          }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone-cover">
                                {!previewBrandedLogo ? (
                                  <>
                                    {!isDragActive && "Drop a file to upload!"}
                                    {isDragActive &&
                                      !isDragReject &&
                                      "Drop it to upload"}
                                    {isDragReject &&
                                      "File type not accepted, sorry!"}
                                  </>
                                ) : (
                                  <img
                                    className="w-100 h-100"
                                    alt="Preview"
                                    src={previewBrandedLogo}
                                  />
                                )}
                              </div>
                              <span className="text-red">
                                {this.state.imgError}
                              </span>
                            </div>
                          )}
                        </Dropzone>
                        <FormGroup>
                          <h5 style={{ "margin-top": "10px" }}>
                            Branded logo's Alternative Text{" "}
                          </h5>
                          <Input
                            value={brandedLogoAlt}
                            onChange={value =>
                              this.handleInputChange("brandedLogoAlt", value)
                            }
                            type="text"
                          />
                          <h5 style={{ "margin-top": "10px" }}>
                            Branded logo's backlink{" "}
                          </h5>
                          <Input
                            value={brandedLogoLink}
                            onChange={value =>
                              this.handleInputChange("brandedLogoLink", value)
                            }
                            type="text"
                          />
                        </FormGroup>
                      </div>
                    </CardBody>
                  </Collapse>
                </div>
              </div>
            </>
          )}

          {/* Summaries */}
          {!isOpenPublishing && (
            <div className="accordion">
              <div className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => this.collapsesToggle("collapseSummary")}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseSummary"
                  )}
                >
                  <div className="d-flex">
                    <h4 className="mb-0">Summary</h4>
                  </div>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes(
                    "collapseSummary"
                  )}
                >
                  <CardBody style={{ paddingTop: 0 }}>
                    <small className="d-block mb-4">
                      3 summaries are the best
                    </small>
                    <Form
                      className="summary-field-form"
                      onSubmit={this.handleSubmit}
                    >
                      {formItems}
                      <Form.Item
                        className="summary-field-form__item"
                        {...formItemLayoutWithOutLabel}
                      >
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          type="dashed"
                          onClick={this.add}
                          style={{ width: "100%" }}
                        >
                          <Icon type="plus" /> Add summary
                        </Button>
                      </Form.Item>
                      <Form.Item
                        className="summary-field-form__item"
                        {...formItemLayoutWithOutLabel}
                      >
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Collapse>
              </div>
            </div>
          )}
          {/* Badge */}
          <div className="accordion">
            <div className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseBadge")}
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseBadge"
                )}
              >
                <div className="d-flex">
                  <h4 className="mb-0">Label</h4>
                </div>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseBadge")}
              >
                <CardBody style={{ paddingTop: 0 }}>
                  <FormGroup>
                    <Select
                      className="react-select1"
                      classNamePrefix="react-select"
                      name="badge"
                      options={labelOption}
                      value={
                        label || {
                          label: "No badge",
                          value: ""
                        }
                      }
                      onChange={value => this.onBadgeChange(value)}
                      closeMenuOnSelect={true}
                    />
                  </FormGroup>
                </CardBody>
              </Collapse>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    my_information: state.authReducer.my_information,
    details: state.chiefArticleReducer.unpublished_detail_by_id,
    post_info: state.postReducer.post_info,
    topics_name: state.postReducer.topics_name,
    employee_list: state.employeeReducer.employee_list,
    topics_name_select: state.postReducer.topics_name_select,
    publishedCollection: state.ChiefCollectionReducer.publishedCollection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: data => {
      dispatch(actNotify(data));
    },
    getPostInfo: data => {
      dispatch(actGetPostInfoRequestV2(data));
    },
    getListStaff: query => {
      dispatch(actGetListStaffRequest(query));
    },
    resetArticleDetail: () => {
      dispatch(actResetUnPublishedDetailByID());
    },
    setArticleDetail: data => {
      dispatch(actGetUnPublishedDetailByID(data));
    },
    updateImageSize: (id, data) => {
      dispatch(actUpdateImageSizeRequest(id, data));
    },
    updateArticleSettingRequest: data => {
      dispatch(actUpdateArticleSettingRequest(data));
    },
    removeArticleRequest: id => {
      dispatch(actRemoveArticleSettingRequest(id));
    },
    updateOpenPublishingSettingRequest: data => {
      dispatch(actUpdatePublishingRequest(data));
    },
    getPublishedCollection: data => {
      dispatch(actGetPublishedCollectionRequest(data));
    }
  };
};

const WrappedPostSetting = Form.create({ name: "dynamic_form_item" })(
  PostSetting
);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedPostSetting);

PostSetting.propTypes = {
  value: PropTypes.string,
  setArticleDetail: PropTypes.func,
  resetArticleDetail: PropTypes.func,
  innerRef: PropTypes.func,
  updateArticleSettingRequest: PropTypes.func,
  updateOpenPublishingSettingRequest: PropTypes.func,
  removeArticleRequest: PropTypes.func,
  readOnly: PropTypes.bool,
  isComment: PropTypes.bool,
  notify: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.object,
  topics_name: PropTypes.array,
  employee_list: PropTypes.array,
  publishedCollection: PropTypes.array,
  post_info: PropTypes.object,
  getPostInfo: PropTypes.func,
  getListStaff: PropTypes.func,
  onChangeSetting: PropTypes.func,
  updateImageSize: PropTypes.func,
  getPublishedCollection: PropTypes.func
};
