import React, { useEffect } from "react";
import TopViewArticle from "./TopViewArticle";
import { Tabs, Dropdown, Menu, DatePicker, Row, Col } from "antd";
import { makeUrlObj } from "../../../utils/makeUrl";
import Pie from "../Pie";
import Bar from "../Bar";
import callApi from "../../../utils/callApi";
import { useState } from "react";
import {
  handleArticlePublish,
  handleArticleCategory,
  handleWriter,
} from "./utils";
import moment from "moment";
import { ReactComponent as ArrowDown } from "../DropDownDate/arrowdown.svg";
import DropDownDate from "../DropDownDate"
import get from "lodash/get"
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const dateFormat = "YYYY-MM-DD";

const BarchartArticle = function() {
  const [categories, setCategories] = useState([]);
  const [writers, setWriters] = useState([]);
  const [currentTab, setCurrentTab] = useState("Time");
  const [dataArticle, setDataArticle] = useState({
    totalArticle: 0,
    totalArticleCompare: 0,
  })
  const [viewMode, setViewMode] = useState('day');
  const [queryDate, setQueryDate] = useState({
    startDate: moment().subtract('days', 7),
      endDate: moment(),
  });
  const [language, setLanguage] = useState("VN");
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const onChangeRangePicker = (value, dateString) => {
    const temp = { startDate: value[0], endDate: value[1]}
    setQueryDate(temp);
  };
  const onChangeLanguage = (item) => {
    setLanguage(item.key);
  };

  const Language = (
    <Menu>
      <Menu.Item onClick={onChangeLanguage} key="VN">
        Vietnamese edition
      </Menu.Item>
      <Menu.Item onClick={onChangeLanguage} key="EN">
        English edition
      </Menu.Item>
    </Menu>
  );

  const onChangeTab = (key) => {
    setCurrentTab(key);
  };
  const onChangeDate = ({ startDate, endDate, viewMode }) => {
    if (currentTab === 'Time') {
      setViewMode(viewMode);
    }
    setQueryDate({ startDate, endDate });
  }
  const fetchListArticlePublish = () => {
    const queryUrl = { ...queryDate, viewMode };
    if (currentTab !== 'Time') {
      queryUrl.language = language;
    }
    callApi(`v2/dashboard/list-article-publish?${makeUrlObj(queryUrl)}`, "GET")
    .then((res) => {
      //setArticles(res.data);
      setData(handleArticlePublish(res, viewMode));
    })
    .catch((error) => console.log(error));
  }

  const fetchArticleTime = () => {
    const queryUrl = { ...queryDate, viewMode };
    if (currentTab !== 'Time') {
      queryUrl.language = language;
    }
    
    callApi(`v2/dashboard/article-publish?${makeUrlObj(queryUrl)}`, "GET")
      .then((res) => {
        setDataArticle({ 
          totalArticle: res.data.totalArticle,
          totalArticleCompare: res.data.totalArticleCompare,
        })
      })
      .catch((error) => console.log(error));
  };
  const fetchArticleTypeWriter = () => {
    callApi(
      `v2/dashboard/article-type-writer?${makeUrlObj({
        language: language,
        ...queryDate,
      })}`,
      "GET"
    ).then((res) => {
      setWriters(handleWriter(res));
    });
  };
  const fetchArticleCategory = () => {
    callApi(
      `v2/dashboard/article-category?${makeUrlObj({
        language: language,
        ...queryDate,
      })}`,
      "GET"
    ).then((res) => {
      setCategories(handleArticleCategory(res));
    });
  };

  useEffect(() => {
    fetchArticleCategory();
  }, []);
  
  useEffect(() => {
    fetchArticleTime();
    if (currentTab === "Writer") {
      fetchArticleTypeWriter();
    }
    if (currentTab === "Categories") {
      fetchArticleCategory();
    }
    if (currentTab === "Time") {
      fetchListArticlePublish();
    }
  }, [currentTab, queryDate, language]);

  return (
    <div>
      <TopViewArticle dataArticle={dataArticle} queryDate={queryDate} language={language} totalArticle={get(data, 'length', 0)}/>

      <Tabs
        defaultActiveKey="Time"
        style={{ marginTop: "30px" }}
        onChange={onChangeTab}
      >
        <TabPane tab="by Time" key="Time">
          <div style={{ marginTop: "60px" }}>
          { currentTab === 'Time' ?  <Bar data={data} /> : null}
          </div>
        </TabPane>
        <TabPane tab="by Categories" key="Categories">
          <div style={{ marginTop: "60px" }}>
          { currentTab === 'Categories' ? <Pie data={categories} /> : null}
          </div>
        </TabPane>
        <TabPane tab="by Type of Writer" key="Writer">
          <div style={{ marginTop: "60px" }}>
          { currentTab === 'Writer' ? <Pie data={writers} />  : null}
          </div>
        </TabPane>
      </Tabs>
      <Row style={{ marginTop: "50px" }}>
        <Col span="5">
          <DropDownDate onChange={onChangeDate} />
        </Col>
        <Col span="12">
          <RangePicker onChange={onChangeRangePicker} format={dateFormat} />
        </Col>
        <Col span="6" offset="1">
          {currentTab !== "Time" ? (
            <Dropdown overlay={Language}>
              <a onClick={(e) => e.preventDefault()}>
                {language === "VN" ? "Vietnamese edition" : "English edition"}
                <ArrowDown style={{ marginLeft: "5px" }} />
              </a>
            </Dropdown>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default BarchartArticle;
