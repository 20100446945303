import React, { Component } from "react";
import { get } from "lodash";
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  // CardText,
  FormGroup,
  Form,
  CardHeader,
  // Input
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  actGetOutlineDetailIDRequest,
  actRejectOutlineRequest,
  actApproveOutlineRequest,
  actSavedraftOutLineRequest,
  actSendBackOutlineRequest,
  actUpdateOutlineDeadlineRequest,
  actSaveOutlineContentRequest,
} from "../../actions/editorArticle.action";
import { actGetFeedbackListRequest } from "../../actions/feedback.action";
import Feedback from "../Feedback/Feedback";
import CustomEditor from "../TinyMCE";
import CardContentBrief from "../Cards/CardContentBrief";
import VersionSelect from "../Selects/VersionSelect";
import ReactHtmlParser from "react-html-parser";
import EditorOutlineRejectModal from "../Modals/EditorOutlineRejectModal";
import EditorOutlineSendBackModal from "../Modals/EditorOutlineSendBackModal";
import EditorOutlineApproveModal from "../Modals/EditorOutlineApproveModal";

class EditorOutlineContentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 2,
      id: window.location.pathname.split("/")[4],
      data: {
        content: "",
      },
      isVerifying: false,
      isReject: false,
      isSendBack: false,
      isApprove: false,
    };
  }

  async componentDidMount() {
    this._setLoading();
    await this._getOutlineDetailByID();
    // await this._getListFeedback();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.outline_detail &&
      nextProps.outline_detail !== this.props.outline_detail
    ) {
      this.setState({
        data: { content: nextProps.outline_detail.data.outline_content },
        isVerifying:
          nextProps.outline_detail.data.status_article === "OUTLINE_SUBMITTED",
      });
      this.props.getFeedbackList(nextProps.outline_detail.data.outlineID);
    }
  }

  _getOutlineDetailByID() {
    this.props.getOutlineDetailByID(this.state.id);
  }

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  _handleOnSaveDraftContent = (e) => {
    e.preventDefault();
    const outline_detail = this.props.outline_detail.data;
    const data = {
      outlineID: outline_detail.outlineID,
      content: this.state.data.content,
    };
    this.props.putSaveDraft(data);
  };

  _handleOnContentChange = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        content: value,
      },
    });
  };
  handleContentChange = (content) => {
    this.setState({
      data: {
        ...this.state.data,
        content: content,
      },
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: "",
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _handleOnVersionSelect = (index) => {
    const url = `/admin/writer/outline-version/${this.state.id}?version=${index}`;
    window.open(url, "_blank");
  };

  _handleApproveRequest = () => {
    const id = this.state.id;
    const data = { status: "APPROVED" };
    this.props.approveOutline(id, data);
    this._closeApprove();
  };

  _showReject = () => {
    this.setState({
      isReject: true,
    });
  };
  _showSendBack = () => {
    this.setState({
      isSendBack: true,
    });
  };
  _showApprove = () => {
    this.setState({
      isApprove: true,
    });
  };
  _closeReject = () => {
    this.setState({
      isReject: false,
    });
  };

  _closeSendBack = () => {
    this.setState({
      isSendBack: false,
    });
  };

  _closeApprove = () => {
    this.setState({
      isApprove: false,
    });
  };

  handleSaveOutlineContent = () => {
    const { outline_detail, saveContentOutline } = this.props;
    const outlineId = get(outline_detail, "data.outlineID");
    const data = { outlineId, content: this.state.data.content };
    saveContentOutline(data);
  };

  render() {
    const { outline_detail, outline_versions } = this.props;
    const { isVerifying } = this.state;

    return (
      <>
        {this.state.alert}
        <Row>
          <Col xl="9">
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 1,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                    role="tab"
                  >
                    1. Content Brief
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 2,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                    role="tab"
                    // style={{ }}
                  >
                    2. Write Outline
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={"tabs" + this.state.tabs}>
              {/* TAB 1 */}
              <TabPane tabId="tabs1">
                <CardContentBrief
                  updateDeadline={this.props.updateDeadline}
                  outline_detail={outline_detail}
                  toggleNavs={this.toggleNavs}
                />
              </TabPane>
              {/* TAB 2 */}
              <TabPane tabId="tabs2">
                <Card>
                  <CardHeader className="border-0 d-flex justify-content-between">
                    <h2>
                      {outline_detail.data &&
                        ReactHtmlParser(outline_detail.data.angle_title)}
                    </h2>
                    <VersionSelect
                      versions={outline_versions}
                      onVersionSelect={this._handleOnVersionSelect}
                    />
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Row>
                        <Col lg="10"></Col>
                        <Col lg="2" className="text-right">
                          <a
                            onClick={this.handleSaveOutlineContent}
                            style={{ fontWeight: "bold" }}
                            className="my-3 d-block"
                            href="#"
                          >
                            Save draft
                          </a>
                        </Col>
                      </Row>
                      <Form>
                        {this.state.data.content &&
                        isVerifying &&
                        isVerifying === true ? (
                          <CustomEditor
                            readOnly={true}
                            toolbar="false"
                            menubar="false"
                            content={this.state.data.content}
                            onChangeContent={this.handleContentChange}
                          />
                        ) : (
                          <p className="font-italic">Writer is writing...</p>
                        )}
                      </Form>
                    </FormGroup>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
          <Col xl="3" className="toolbar-right">
            <div className="mt--5 d-flex">
              <>
                <Button
                  type="button"
                  className="btn-lg"
                  color="warning"
                  style={{ width: "50%" }}
                  disabled={!isVerifying}
                  onClick={this._showSendBack}
                >
                  Sendback
                </Button>
                <Button
                  type="button"
                  className="btn-lg"
                  color="success"
                  style={{ width: "50%" }}
                  disabled={!isVerifying}
                  onClick={this._showApprove}
                >
                  Approve
                </Button>
                <Button
                  type="button"
                  className="btn-lg"
                  color="danger"
                  disabled={!isVerifying}
                  style={{ width: "50%" }}
                  onClick={this._showReject}
                >
                  Reject
                </Button>
              </>
            </div>
            <Feedback
              article_id={this.state.id}
              outline_id={outline_detail.data.outlineID}
            />
          </Col>
        </Row>
        <EditorOutlineRejectModal
          rejectRequest={this.props.rejectOutline}
          visible={this.state.isReject}
          handleCancel={this._closeReject}
          id={this.state.id}
        />
        <EditorOutlineSendBackModal
          sendBackRequest={this.props.sendBackOutline}
          visible={this.state.isSendBack}
          handleCancel={this._closeSendBack}
          id={this.state.id}
          content={this.state.data.content}
        />
        <EditorOutlineApproveModal
          approveRequest={this.props.approveOutline}
          visible={this.state.isApprove}
          handleCancel={this._closeApprove}
          id={this.state.id}
        />
        {/* <ConfirmModal
          visible={this.state.isApprove}
          handleOk={this._handleApproveRequest}
          handleCancel={this._closeApprove}
          title="Approve this article"
        /> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    outline_detail: state.editorArticleReducer.outline_detail_id,
    outline_versions: state.writerArticleReducer.outline_versions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOutlineDetailByID: (data) => {
      dispatch(actGetOutlineDetailIDRequest(data));
    },
    getFeedbackList: (outlineId) => {
      dispatch(actGetFeedbackListRequest(outlineId));
    },
    rejectOutline: (id, data) => {
      dispatch(actRejectOutlineRequest(id, data));
    },
    approveOutline: (id, data) => {
      dispatch(actApproveOutlineRequest(id, data));
    },
    putSaveDraft: (data) => {
      dispatch(actSavedraftOutLineRequest(data));
    },
    sendBackOutline: (id, data) => {
      dispatch(actSendBackOutlineRequest(id, data));
    },
    updateDeadline: (outlineId, data) => {
      dispatch(actUpdateOutlineDeadlineRequest(outlineId, data));
    },
    saveContentOutline: (data) => {
      dispatch(actSaveOutlineContentRequest(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorOutlineContentTable);
EditorOutlineContentTable.propTypes = {
  outline_detail: PropTypes.object,
  outline_versions: PropTypes.array,
  getOutlineDetailByID: PropTypes.func,
  getFeedbackList: PropTypes.func,
  approveOutline: PropTypes.func,
  rejectOutline: PropTypes.func,
  putSaveDraft: PropTypes.func,
  updateDeadline: PropTypes.func,
  sendBackOutline: PropTypes.func,
  saveContentOutline: PropTypes.func,
  isComment: PropTypes.bool,
};
EditorOutlineContentTable.defaultProps = {};
