import React, { Component } from "react";
import PropTypes from "prop-types";
import HOST_URL from "../../variables/hostUrl";
import AVA from "assets/img/default-ava.png";

class Avatar extends Component {
  state = {};

  render() {
    const src = this.props.src;
    return (
      <span
        className={"avatar avatar-sm rounded-circle " + this.props.className}
      >
        <img
          alt="..."
          // src={this.props.src ? HOST_URL + this.props.src : AVA}
          src={
            src && src.indexOf("https://") !== -1
              ? src
              : src
              ? HOST_URL + src
              : AVA
          }
          style={{
            height: this.props.height + "px",
            width: this.props.width + "px"
          }}
        />
      </span>
    );
  }
}

export default Avatar;
Avatar.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};
Avatar.defaultProps = {
  height: 45,
  width: 45
};
