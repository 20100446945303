import React, { Component } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MultiCheckboxSelect from "../MultipleCheckboxSelect/MultipleCheckboxSelect";
import { actAddTopicToMenuRequest } from "../../actions/topic.action";

class ChiefMainMenuModal extends Component {
  state = {
    topicID: []
  };

  componentDidMount() {
    // this.props.getTopicList();
  }

  toggle = () => {
    this.props.toggle();
    this.setState({
      topicID: []
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  _handleOnChange = e => {
    this.setState({
      topic_info: {
        ...this.state.topic_info.data,
        [e.target.name]: e.target.value
      }
    });
  };

  _handleOnSave = () => {
    const data = {
      topicIds: this.state.topicID
    };
    const { languagueKey } = this.props;
    if (languagueKey) {
      let params = {
        language: "VN"
      };
      this.props.addTopicToMenu(data, params);
    } else {
      let params = {
        language: "EN"
      };
      this.props.addTopicToMenu(data, params);
    }

    this.toggle();
  };

  onChange = topicID => {
    this.setState({ topicID });
  };

  render() {
    const { modalTopic_list, mainMenu_list } = this.props;
    const { topicID } = this.state;
    const restTopic = 4 - mainMenu_list.length;
    const totalTopic = topicID.length + mainMenu_list.length;

    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.toggle}>Add New Menu</ModalHeader>
          <ModalBody className="py-0">
            <div
              className="body-top d-flex justify-content-between"
              style={{ marginBottom: 8 }}
            >
              <span className="text-regular">Topic</span>
              <span
                className="text-regular"
                style={{
                  color: "#5E72E4",
                  fontSize: "12px",
                  fontWeight: "normal"
                }}
              >
                {totalTopic < 4 ? `Choose at least ${restTopic} topic` : ""}
              </span>
            </div>

            <MultiCheckboxSelect
              options={modalTopic_list}
              value={topicID}
              onChange={this.onChange}
              placeholder="Select or search topic name"
            />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-flex-end">
            <Button
              color="link"
              style={{ paddingRight: "0px", color: "#172B4D" }}
              onClick={this.toggle}
            >
              CANCEL
            </Button>
            <Button
              color="link"
              style={{ color: "#fb6340" }}
              onClick={this._handleOnSave}
            >
              ADD TO MENU
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    mainMenu_list: state.topicReducer.mainMenu_list,
    modalTopic_list: state.topicReducer.modalTopic_list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTopicToMenu: (data, params) => {
      dispatch(actAddTopicToMenuRequest(data, params));
    }
    // getTopicList: () => {
    //   dispatch(actGetTopicListRequest());
    // }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChiefMainMenuModal);

ChiefMainMenuModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  getTopicList: PropTypes.func,
  addTopicToMenu: PropTypes.func,
  modalTopic_list: PropTypes.array,
  mainMenu_list: PropTypes.array,
  languagueKey: PropTypes.bool
};

ChiefMainMenuModal.defaultProps = {};
