import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Button, Spin } from "antd";
import { Card, CardBody, Row, Col } from "reactstrap";
import ConfirmModal from "../../Modals/ApplicationDetail/Confirm";
import ReEvaluateModal from "../../Modals/ApplicationDetail/ReEvaluate";
import ApplicationInformationModal from "../../Modals/ApplicationInformationModal";
import {
  actGetApplicationDetailRequest,
  actGetApplicationInformationRequest,
  actApproveApplicationRequest,
  actRejectApplicationRequest,
  actRevaluateApplicationRequest,
} from "../../../actions/application.action";

import { makeUrl } from "../../../utils/makeUrl";
import get from "lodash/get";
import { ARRAY_APPLICATION_STATUS_PROCESSED } from "../../../utils/constants";

import "./styles.scss";

function ApplicationDetail() {
  const [approveModal, setApproveModal] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [valuateModal, setValuateModal] = useState(false);
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const applicationDetail = useSelector(
    (state) => state.applicationReducer.applicationDetail
  );
  const loading = useSelector((state) => state.applicationReducer.isLoading);
  const key = localStorage.getItem("key");
  const role = key === "chiefeditor" ? "chief" : "editor";

  // Effect
  useEffect(() => {
    dispatch(actGetApplicationDetailRequest(params.id));
  }, [params.id]);

  function handleApprove() {
    const data = {
      articleId: applicationDetail._id,
      status: makeUrl("status", ARRAY_APPLICATION_STATUS_PROCESSED),
    };
    dispatch(actApproveApplicationRequest(data));
    handleCloseApprove();
    history.push(`/admin/${role}/applications/processed`);
  }
  function handleEvaluate() {
    const data = {
      articleId: applicationDetail._id,
      reason: reason,
      status: makeUrl("status", ARRAY_APPLICATION_STATUS_PROCESSED),
    };
    dispatch(actRevaluateApplicationRequest(data));
    handleCloseEvaluate();
    history.push(`/admin/${role}/applications/processed`);
  }
  // Approve modal
  function showApproveModal() {
    setApproveModal(true);
  }
  function handleCloseApprove() {
    setApproveModal(false);
  }
  // Reject modal
  function showRejectModal() {
    setRejectModal(true);
  }
  function handleCloseReject() {
    setRejectModal(false);
  }
  function handleReject() {
    const data = {
      articleId: applicationDetail._id,
      status: makeUrl("status", ARRAY_APPLICATION_STATUS_PROCESSED),
    };
    dispatch(actRejectApplicationRequest(data));
    handleCloseReject();
    history.push(`/admin/${role}/applications/processed`);
  }
  // Evaluate modal
  function showEvaluateModal() {
    setValuateModal(true);
  }
  function handleCloseEvaluate() {
    setValuateModal(false);
  }
  function handleApplicationModal(applicantId) {
    document.body.classList.add("information-modal-open");
    setApplicationModal(true);
    dispatch(actGetApplicationInformationRequest(applicantId));
  }
  function handleCloseApplication() {
    document.body.classList.remove("information-modal-open");
    setApplicationModal(false);
  }
  function handleOnChange(value) {
    setReason(value);
  }
  if (!applicationDetail) {
    return null;
  }
  if (loading) {
    return (
      <div className="application-detail">
        <Card>
          <CardBody className="text-center loading-placeholder d-flex align-items-center justify-content-center">
            <Spin />
          </CardBody>
        </Card>
      </div>
    );
  }
  return (
    <div className="application-detail">
      <Card>
        <CardBody>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={3}>
                  <p className="meta-data">Article Title</p>
                </Col>
                <Col xs={7}>
                  <p className="article-title">{applicationDetail.title}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <p className="meta-data">Topic</p>
                </Col>
                <Col xs={7}>
                  <p>
                    {applicationDetail.topic &&
                      applicationDetail.topic[0].label}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <p className="meta-data">Prompt</p>
                </Col>
                <Col xs={7}>
                  <p>{applicationDetail.prompt}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <p className="meta-data">Writer</p>
                </Col>
                <Col xs={7}>
                  {get(applicationDetail, 'writer._id') && <a
                    onClick={() =>
                      handleApplicationModal(applicationDetail.writer._id)
                    }
                    className="link"
                  >
                    {get(applicationDetail, 'writer.name') !== '' ? applicationDetail.writer.name : 'No name'}
                  </a>}
                 
                </Col>
              </Row>
              {applicationDetail.articleReference !== null && (
                <Row>
                  <Col xs={12} className="view-old-draft">
                    <p>* This is the second sample writing of this writer.</p>
                    <a
                      onClick={() =>
                        history.push(
                          `/admin/${role}/application-detail/${applicationDetail.articleReference}`
                        )
                      }
                    >
                      View the first sample writing
                    </a>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {/* Article of applicant */}
          <Row>
            <Col xs={10}>
              <div className="applicant-article">
                <div
                  dangerouslySetInnerHTML={{
                    __html: applicationDetail.content,
                  }}
                />
              </div>
            </Col>
          </Row>
          {/* Actions */}
          {applicationDetail.applicationStatus === "NEW" && (
            <Row className="sticky-action">
              <Col xs={10} className="d-flex justify-content-between my-4">
                <div>
                  <Button
                    onClick={showRejectModal}
                    type="link"
                    className="reject-btn"
                  >
                    Reject this applicant
                  </Button>
                </div>
                <div className="approve-btn-wrapper">
                  <Button onClick={showApproveModal} type="ghost">
                    Approve
                  </Button>
                  <Button onClick={showEvaluateModal} type="primary">
                    Re-evaluate
                  </Button>
                </div>
              </Col>
            </Row>
          )}

          <ConfirmModal
            visible={approveModal}
            handleOk={handleApprove}
            handleCancel={handleCloseApprove}
            title="Do you want to approve this applicant?"
            content="Once approved, you can continue to process this article at <strong>Article > Review Articles</strong>"
          />
          <ConfirmModal
            visible={rejectModal}
            handleOk={handleReject}
            handleCancel={handleCloseReject}
            title="Are you sure to reject?"
            content="Once you reject this application, you will not be able to process this article anymore."
          />
          <ReEvaluateModal
            handleOnChange={handleOnChange}
            visible={valuateModal}
            handleOk={handleEvaluate}
            handleCancel={handleCloseEvaluate}
            title="Give the applicant a new instruction for their re-evaluation article?"
          />
          {/* Application information Modal */}
          <ApplicationInformationModal
            visible={applicationModal}
            handleOk={handleApplicationModal}
            handleCancel={handleCloseApplication}
            title="Applicant’s Information"
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default ApplicationDetail;
