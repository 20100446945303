import * as Types from "../variables/actionTypes.jsx";

const initialState = {
  applications: [],
  applicationDetail: {},
  applicantInformation: {},
  total: {
    pending: 0,
    processed: 0
  },
  isLoading: false
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get list
    case Types.GET_LIST_APPLICATION_START:
      state = {
        ...state,
        isLoading: true
      };
      return { ...state };
    case Types.GET_LIST_APPLICATION_SUCCESS:
      state = {
        ...state,
        applications: action.data,
        isLoading: false
      };
      return { ...state };
    case Types.GET_LIST_APPLICATION_FAIL:
      state = {
        ...state,
        isLoading: false
      };
      return { ...state };
    // Search
    case Types.SEARCH_APPLICATION_START:
      state = {
        ...state,
        isLoading: true
      };
      return { ...state };
    case Types.SEARCH_APPLICATION_SUCCESS:
      state = {
        ...state,
        applications: action.data,
        isLoading: false
      };
      return { ...state };
    case Types.SEARCH_APPLICATION_FAIL:
      state = {
        ...state,
        isLoading: false
      };
      return { ...state };
    // Get detail
    case Types.GET_APPLICATION_DETAIL_START:
      state = {
        ...state,
        isLoading: true
      };
      return { ...state };
    case Types.GET_APPLICATION_DETAIL_SUCCESS:
      state = {
        ...state,
        applicationDetail: action.data,
        isLoading: false
      };
      return { ...state };
    case Types.GET_APPLICATION_DETAIL_FAIL:
      state = {
        ...state,
        isLoading: false
      };
      return { ...state };
    // Get application information
    case Types.GET_APPLICATION_INFO_START:
      state = {
        ...state,
        isLoading: true
      };
      return { ...state };
    case Types.GET_APPLICATION_INFO_SUCCESS:
      state = {
        ...state,
        applicantInformation: action.data,
        isLoading: false
      };
      return { ...state };
    case Types.GET_APPLICATION_INFO_FAIL:
      state = {
        ...state,
        isLoading: false
      };
      return { ...state };
    case Types.GET_TOTAL_PENDING_APPLICATION_SUCCESS:
      state = {
        ...state,
        total: {
          ...state.total,
          pending: action.data
        }
      };
      return { ...state };
    case Types.GET_TOTAL_PROCESSED_APPLICATION_SUCCESS:
      state = {
        ...state,
        total: {
          ...state.total,
          processed: action.data
        }
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default applicationReducer;
