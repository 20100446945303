import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Col,
  Row,
  CardFooter
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { actGetWriterProfileTableDetailRequest } from "../../actions/chiefArticle.action";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import moment from "moment";
import Avatar from "../Avatar/Avatar";
import CardsHeaderchief from "../Headers/CardsHeaderChief";
class ChiefWriterProfileDetailTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : -1
    },
    isLoading: false,
    id: window.location.pathname.split("/")[4]
  };

  componentDidMount() {
    this._setLoading();
    this._getWriterProfileTableDetail();
  }

  _getWriterProfileTableDetail = () => {
    const params = this.state.params;
    const id = this.state.id;
    this.props.getWriterProfileTableDetail(id, params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    const id = this.state.id;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getWriterProfileTableDetail(id, params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(params);
    this.props.getWriterProfileTableDetail(this.state.id, paramsSort);
  };

  _renderStatus = status => {
    switch (status) {
      case "WORKING":
        return (
          <>
            <div>
              <span className="status-new">●</span>
              <span>Working</span>
            </div>
          </>
        );
      case "COMPLETED":
        return (
          <>
            <span className="status-progress">●</span>
            <span>Ready to publish</span>
          </>
        );
      case "STOP_WORKING":
        return (
          <>
            <span className="status-new">●</span>
            <span>Stop working</span>
          </>
        );
      case "NEW":
        return (
          <>
            <div>
              <span className="status-new">●</span>
              <span>New</span>
            </div>
          </>
        );
      case "OUTLINE_SUBMITTED":
        return (
          <>
            <span className="status-waiting">●</span>
            <span>Waiting for Approval</span>
          </>
        );
      case "CONTENT_SUBMITTED":
        return (
          <>
            <span className="status-waiting">●</span>
            <span>Waitting for Approval</span>
          </>
        );
      case "PUBLISHED":
        return (
          <>
            <span className="status-completed">●</span>
            <span>Published</span>
          </>
        );
      case "EDITING":
        return (
          <>
            <span className="status-new">●</span>
            <span>Needs Editing</span>
          </>
        );
      case "CONTENT_WRITING":
        return (
          <>
            <span className="status-progress">●</span>
            <span>Articles - Need Writing</span>
          </>
        );
      case "OUTLINE_WRITING":
        return (
          <>
            <span className="status-progress">●</span>
            <span>Outlines - Need Writing</span>
          </>
        );
      case "UNPUBLISHED":
        return (
          <>
            <span className="status-cancel">●</span>
            <span>Unpublished</span>
          </>
        );

      case "REJECTED":
        return (
          <>
            <span className="status-cancel">●</span>
            <span>Rejected</span>
          </>
        );
      default:
    }
    return null;
  };

  render() {
    const { writer_profile_detail_table } = this.props;
    const page =
      writer_profile_detail_table.data && writer_profile_detail_table.data.page;
    const totalPages =
      writer_profile_detail_table.data &&
      writer_profile_detail_table.data.totalPages;
    const totalDocs =
      writer_profile_detail_table.data &&
      writer_profile_detail_table.data.totalDocs;
    return (
      <>
        <CardsHeaderchief
          name="Writer Profile"
          content="writerProfile"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <Avatar
                      src={
                        writer_profile_detail_table.data &&
                        writer_profile_detail_table.data.docs[0] &&
                        writer_profile_detail_table.data.docs[0].writer.avatar
                      }
                      className="d-inline-block mr-3"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <h3
                      className="mb-0 my-auto"
                      style={{
                        display: "inline",
                        width: "50px",
                        height: "50px"
                      }}
                    >
                      {writer_profile_detail_table.data &&
                        writer_profile_detail_table.data.docs[0] &&
                        writer_profile_detail_table.data.docs[0].writer.name}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {writer_profile_detail_table.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("start_date")}
                        >
                          Writer accepts on
                        </th>
                        <th
                          className="sort"
                          // data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("status")}
                        >
                          STATUS
                        </th>
                        <th scope="col">TOPIC</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {writer_profile_detail_table.data
                        ? writer_profile_detail_table.data.docs.map(
                            (prop, key) => {
                              return (
                                <>
                                  <tr key={key}>
                                    <th scope="row">
                                      <span style={{ color: "#4b556f" }}>
                                        {this._text_truncate(
                                          prop.angle_title,
                                          50,
                                          "..."
                                        )}
                                      </span>
                                    </th>
                                    <td className="budget">
                                      {moment(prop.start_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {this._renderStatus(prop.status_article)}
                                    </td>
                                    <td>
                                      {prop
                                        ? prop.topic.map(topic => {
                                            return (
                                              <>
                                                <div className="_topic">
                                                  <label>
                                                    {this._text_truncate(
                                                      topic.name
                                                        ? topic.name
                                                        : topic.label,
                                                      20,
                                                      "..."
                                                    )}
                                                  </label>
                                                </div>
                                              </>
                                            );
                                          })
                                        : ""}
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        : ""}
                    </tbody>
                  </Table>
                )}

                {writer_profile_detail_table.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writer_profile_detail_table:
      state.chiefArticleReducer.writer_profile_detail_table
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getWriterProfileTableDetail: (id, params) => {
      dispatch(actGetWriterProfileTableDetailRequest(id, params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefWriterProfileDetailTable);
ChiefWriterProfileDetailTable.propTypes = {
  writer_profile_detail_table: PropTypes.object,
  getWriterProfileTableDetail: PropTypes.func
};
ChiefWriterProfileDetailTable.defaultProps = {};
