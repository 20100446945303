import React, { Component } from "react";
import { Container } from "reactstrap";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
import TopicSelectList from "../../../components/ParentSelectList/TopicSelectList";

class ChiefTopic extends Component {
  state = {};
  render() {
    return (
      <>
        <CardsHeaderChief
          name="Topic"
          content="topicArticles"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <TopicSelectList />
        </Container>
      </>
    );
  }
}
ChiefTopic.propTypes = {};
export default ChiefTopic;
