export const tinyMCEConfig = {
  branding: false,
  height: 500,
  menu: {
    edit: {
      title: "Edit",
      items: "undo redo | cut copy paste | selectall | searchreplace"
    },
    insert: {
      title: "Insert",
      items: "image link media | hr"
    },
    format: {
      title: "Format",
      items: "bold italic underline | formats blockformats align | removeformat"
    },
    tools: {
      title: "Tools",
      items: "spellchecker spellcheckerlanguage | code wordcount"
    }
  },
  plugins: [
    "lists link image hr preview",
    "media powerpaste fullscreen searchreplace wordcount"
  ],
  powerpaste_word_import: "clean",
  powerpaste_googledocs_import: "clean",
  powerpaste_html_import: "clean",
  image_caption: true,
  image_description: true,
  image_title: true,
  image_class_list: [
    { title: "Fullwidth", value: "full-width" },
    { title: "Fit-content", value: "fit-content" }
  ],
  image_dimensions: true,
  formats: {
    textbox: {
      inline: "span",
      styles: {
        display: "block",
        color: "#292929",
        background: "#FAFAFA",
        border: "1px solid #00773E",
        borderRadius: "8px",
        padding: "20px"
      },
      attributes: { title: "In-text textbox" },
      classes: "in-text-textbox"
    }
  },
  style_formats: [{ title: "In-text textbox", format: "textbox" }],
  block_formats: "Paragraph=p; Heading 2=h2; Heading 3=h3",
  content_style: "figure img { width: 100% }",
  rel_list: [
    { title: "Default", value: "noopener noreferrer" },
    { title: "Do follow", value: "dofollow noopener noreferrer" }
  ]
};
