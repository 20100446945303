import React, { Component } from "react";
import { get } from "lodash";
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Row,
  Badge,
  CardFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Tooltip } from "antd";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import CustomPagination from "../Pagination/Pagination";
import PropTypes from "prop-types";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import { actGetPublishArticleListRequest } from "../../actions/chiefArticle.action";
import { Link } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import ReactHtmlParser from "react-html-parser";
import Select from "react-select";

const statusOption = [
  { label: "All", value: "" },
  { label: "Published", value: "PUBLISHED" },
  { label: "Unpublished", value: "UNPUBLISHED" },
  { label: "Ready to published", value: "COMPLETED" },
];

class ChiefPublishArticleDetailTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "updatedAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1",
      search: getUrlParam()["search"] ? getUrlParam()["search"] : "",
      status_article: getUrlParam()["status_article"]
        ? getUrlParam()["status_article"]
        : "",
    },
    isLoading: false,
  };

  componentDidMount() {
    this._getPublishArticle();
  }

  _getPublishArticle = () => {
    const params = this.state.params;
    this.props.getPublishArticle(params);
  };

  _handleOnPageClick = (page) => {
    const { params } = this.state;
    params.page = page;
    this._handlePathnamePush(params);
    this.props.getPublishArticle(params);
  };

  _handlePathnamePush = (params) => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };
  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };
  _sortByColumn = (typeColumn) => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1,
        },
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1,
        },
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort,
    };
    this._handlePathnamePush(paramsSort);
    this.props.getPublishArticle(paramsSort);
  };

  _handleOnCellClick = (e, id, type) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/admin/chief/un-published-review/${id}`,
      state: {
        type,
      },
    });
  };

  limitTitle = (str) => {
    if (!str) {
      return "";
    }
    if (str[0].length > 50) {
      return `${str[0].substring(0, 50)}...`;
    } else {
      return str[0].substring(0, 50);
    }
  };

  onSearch = (e) => {
    const search = e.target.value;
    const params = this.state.params;
    params.page = 1;
    this.setState({ params: { ...params, search } });
    this._handlePathnamePush({ ...params, search });
    this.props.getPublishArticle({ ...params, search });
  };

  onStatusChange = (value) => {
    const status_article = value.value;
    const params = this.state.params;
    params.page = 1;
    this.setState({ params: { ...params, status_article } });
    this._handlePathnamePush({ ...params, status_article });
    this.props.getPublishArticle({ ...params, status_article });
  };

  render() {
    const page =
      this.props.chiefpublish.data && this.props.chiefpublish.data.page;
    const totalPages =
      this.props.chiefpublish.data && this.props.chiefpublish.data.totalPages;
    const totalDocs =
      this.props.chiefpublish.data && this.props.chiefpublish.data.totalDocs;
    const { loading } = this.props.chiefpublish;
    const renderStatus = (status) => {
      switch (status) {
        case "COMPLETED":
          return (
            <>
              <span className="status-progress">●</span>
              <span>Ready to publish</span>
            </>
          );
        case "UNPUBLISHED":
          return (
            <>
              <span className="status-cancel">●</span>
              <span>Unpublished</span>
            </>
          );
        case "PUBLISHED":
          return (
            <>
              <span className="status-completed">●</span>
              <span>Published</span>
            </>
          );
        default:
      }
      return null;
    };
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-2">Publish Articles List</h3>
                <div className="d-flex">
                  <InputGroup
                    className="input-group-article"
                    style={{ width: "200px", marginRight: "20px" }}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onSearch}
                      placeholder="Article name"
                      type="text"
                      value={this.state.params.search}
                    />
                  </InputGroup>
                  <div style={{ width: "200px" }}>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      style={{ width: "200px" }}
                      placeholder="Article status"
                      value={this.state.statusSelect}
                      options={statusOption}
                      onChange={(value) => this.onStatusChange(value)}
                    />
                  </div>
                </div>
              </CardHeader>
              {loading ? (
                <CardBody>
                  <div className={`p-5 text-center`}>
                    <ClipLoader loading={true} size={50} color={"#3C74F0"} />
                  </div>
                </CardBody>
              ) : (
                <div className="table-responsive">
                  {this.props.chiefpublish.data &&
                  this.props.chiefpublish.data.docs &&
                  this.props.chiefpublish.data.docs.length !== 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      style={{ border: "0px" }}
                      hover
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            onClick={() => this._sortByColumn("angle_title")}
                          >
                            Title
                          </th>
                          <th className="text-center">Dual Language</th>
                          <th>Writer</th>
                          <th>Status</th>
                          <th>TOPIC</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {this.props.chiefpublish.data &&
                          this.props.chiefpublish.data.docs &&
                          this.props.chiefpublish.data.docs.map((prop, key) => {
                            return (
                              <tr
                                key={key}
                                className="cursor-pointer"
                                onClick={(e) =>
                                  this._handleOnCellClick(
                                    e,
                                    prop._id,
                                    get(prop, "type")
                                  )
                                }
                              >
                                <th scope="row" style={{ width: "40%" }}>
                                  <Tooltip
                                    title={ReactHtmlParser(prop.angle_title)}
                                    placement="bottom"
                                  >
                                    {prop.status_article === "UNPUBLISHED" ? (
                                      this.limitTitle(
                                        ReactHtmlParser(prop.angle_title)
                                      )
                                    ) : (
                                      <Link
                                        to={{
                                          pathname: `/admin/chief/un-published-review/ + ${prop.id}`,
                                          state: {
                                            type: get(prop, "type"),
                                          },
                                        }}
                                        style={{
                                          color: "#4b556f",
                                        }}
                                      >
                                        {this.limitTitle(
                                          ReactHtmlParser(prop.angle_title)
                                        )}
                                      </Link>
                                    )}
                                  </Tooltip>
                                </th>
                                <td className="text-center text-success">
                                  {prop.dualLanguage_article !== null &&
                                    prop.dualLanguage_article && (
                                      <i
                                        className="fa fa-check-circle"
                                        style={{ fontSize: 16 }}
                                      ></i>
                                    )}
                                </td>
                                <td>
                                  <Avatar
                                    src={prop.writer ? prop.writer.avatar : ""}
                                    className="d-inline-block mr-3"
                                  />
                                  {prop.writer ? prop.writer.name : ""}
                                </td>
                                <td>
                                  <Badge color="" className="badge-dot mr-4">
                                    {renderStatus(prop.status_article)}
                                  </Badge>
                                </td>
                                <td>
                                  {prop && prop.topic.length > 0
                                    ? prop.topic.map((topic) => {
                                        return (
                                          <>
                                            <div className="mr-1 _topic">
                                              <label>
                                                {this._text_truncate(
                                                  topic.name
                                                    ? topic.name
                                                    : topic.label
                                                )}
                                              </label>
                                            </div>
                                          </>
                                        );
                                      })
                                    : ""}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  ) : (
                    <div
                      className="p-7 text-center font-italic font-weight-bold"
                      style={{ color: "#ccc", fontSize: "18px" }}
                    >
                      There are no content available
                    </div>
                  )}
                </div>
              )}

              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    chiefpublish: state.chiefArticleReducer.chiefpublish,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPublishArticle: (params) => {
      dispatch(actGetPublishArticleListRequest(params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefPublishArticleDetailTable);
ChiefPublishArticleDetailTable.propTypes = {
  chiefpublish: PropTypes.object,
  getPublishArticle: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
ChiefPublishArticleDetailTable.defaultProps = {};
