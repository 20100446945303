import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetMyProfileListRequest = () => {
  return dispatch => {
    return callApi(`profile?`, "GET")
      .then(res => {
        localStorage.setItem("phone", res.data.phone);
        localStorage.setItem("penname", res.data.penname);
        dispatch(actGetMyProfileList(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

//List Key
export const actGetListKeyRequest = data => {
  return dispatch => {
    return callApi("list-key-permission", "GET")
      .then(res => {
        dispatch(actGetListKey(res));
      })
      .catch(err => {});
  };
};

export const actGetListKey = data => {
  return {
    type: Types.GET_LIST_KEY,
    data
  };
};

export const actGetMyProfileList = data => {
  return {
    type: Types.GET_MY_PROFILE_LIST,
    data
  };
};

export const actUpdateMyProfileRequest = (id, data) => {
  return dispatch => {
    callApi(`admin-change-info-staff?id=${id}`, "POST", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Update succesfully"
          })
        );
      })
      .catch(err => {
        const mess = err.data ? err.data.message : "";
        dispatch(
          actNotify({
            message: mess,
            color: "danger"
          })
        );
      });
  };
};

// Update writer
export const actUpdateWriterRequest = (id, data) => {
  return dispatch => {
    callApi(`update-profile-writer?id=${id}`, "PUT", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "Update succesfully"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
