import React, { useEffect, useState } from "react";
import { Table, Row, Col,  } from "antd";
import DropDownDate from "../DropDownDate";
import FilterDateRanger from "../FilterDateRanger";
import callApi from "../../../utils/callApi";
import { makeUrlObj } from "../../../utils/makeUrl";
import Infor from "./Infor";
import Pie from "../Pie";
import get from "lodash/get";
import moment from "moment";
import { columns, handleWriterCategory } from "./utils";


const EditorSection = function() {
  const [editors, setEditors] = useState([]);
  const [query, setQuery] = useState({
    startDate: moment().subtract('days', 7),
    endDate: moment(),
  });
  const [pieData, setPieData] = useState({});
  const [data, setData] = useState({
    total: 0,
    totalCompare: 0,
  });

  const [queryPieDate, setQueryPieDate] = useState({
    startDate: moment(),
    endDate: moment().subtract('days', 7),
  });

  const onChangeDate = function({ startDate, endDate }) {
    setQuery({ startDate, endDate });
  };
  const onChangePieDate = function({ startDate, endDate }) {
    setQueryPieDate({ startDate, endDate });
  };
  const onChangeRangePicker = function(value, dateString) {
    setQuery({ startDate: value[0], endDate: value[1] });
  };
  const fetchEditorCategory = () => {
    callApi(
      `v2/dashboard/editor-category?${makeUrlObj(queryPieDate)}`,
      "GET"
    ).then((res) => {
      setPieData(handleWriterCategory(res));
      setData({
        total: get(res.data, "total", 0),
        totalCompare: get(res.data, "dataCompare", 0),
      });
    });
  }
  const fetchEditorCountArticle = () => {
    callApi(
      `v2/dashboard/editor-count-article?${makeUrlObj(query)}`,
      "GET"
    ).then((res) => {
      setEditors(res.data);
    });
  }
  useEffect(() => {
    fetchEditorCategory()
  }, []);

  useEffect(() => {
    fetchEditorCountArticle()
  }, []);

  useEffect(() => {
    fetchEditorCategory()
  }, [queryPieDate]);

  useEffect(() => {
    fetchEditorCountArticle()
  }, [query]);

  return (
    <Row gutter={24} >
        <Col span={12} >
        <Infor data={data}/>
        <h3 style={{margin: "40px 0px"}}>Editors & Categories</h3>
        <Pie data={pieData} />
        <div style={{marginBottom: "130px"}}></div>
        <DropDownDate onChange={onChangePieDate} />
      </Col>
      <Col span={12} >
        <h2 style={{  marginBottom: "20px" }}>Editor's Output</h2>
        <Table
          pagination={false}
          dataSource={editors}
          columns={columns}
          footer={() => (
            <Row gutter={24}  type="flex"  align="middle">
              <Col className="gutter-row" span={6}>
                <DropDownDate onChange={onChangeDate} />
              </Col>
              <Col className="gutter-row" span={12}>
                <FilterDateRanger onChange={onChangeRangePicker} />
              </Col>
            </Row>
          )}
        />
      </Col>
    </Row>
  );
};

export default EditorSection;
