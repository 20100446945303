import * as Types from "../variables/actionTypes";

var initialState = {
  feedback_list: [],
  comment_pitch: [],
  loading: false,
};

const allowanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_FEEDBACK_LIST_START:
      state = { ...state, loading: true };
      return { ...state };
    case Types.GET_FEEDBACK_LIST:
      state = {
        ...state,
        feedback_list: action.data.outline.comments,
        loading: false,
      };
      return { ...state };
    case Types.GET_FEEDBACK_LIST_FAIL:
      state = { ...state, loading: false };
      return { ...state };
    case Types.RESET_FEEDBACK_LIST:
      state = { ...state, feedback_list: [] };
      return { ...state };
    case Types.GET_COMMENT_PITCH:
      state = { ...state, comment_pitch: action.data };
      return { ...state };
    default:
      return { ...state };
  }
};

export default allowanceReducer;
