/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="" id="footer-main" style={{ marginTop: "-50px" }}>
          <Container>
            <Row className="align-items-center justify-content-xl-end">
              <Col xl="5">
                <div className="copyright text-center text-xl-center text-muted">
                  © {new Date().getFullYear()} Vietcetera
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
