import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../../../components/Headers/CardsHeaderChief";
import AddNewPublishing from "../../../../components/VietceteraOpen/AddNewPublishing";
class VietceteraOpen extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief name="Vietcetera Open" parentName="Article" />
        <Container className="mt--6" fluid>
          <AddNewPublishing {...props} />
        </Container>
      </>
    );
  }
}
VietceteraOpen.propTypes = {
  content: PropTypes.string,
};
export default VietceteraOpen;
