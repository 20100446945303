import * as Types from "../variables/actionTypes";

var initialState = {
  employee: {
    data: {
      docs: []
    }
  },
  listKey: {
    docs: []
  },
  listTopic: [],
  listExpertise: {
    docs: []
  },
  employeelist: {
    docs: []
  },
  employee_info: {},
  employee_list: [],
  employee_profile: {
    docs: []
  },
  expertiseOption: []
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_LIST_STAFF:
      state = { ...state, employee_list: action.data };
      return {
        ...state
      };
    case Types.GET_EMPLOYEE:
      state = { ...state, employee: action.data };
      return { ...state };
    //list key
    case Types.GET_LIST_KEY:
      state = { ...state, listKey: action.data };
      return { ...state };
    //list topic
    case Types.GET_LIST_TOPIC:
      var expertiseOption = action.data.data.docs;
      expertiseOption.map(prop => {
        prop.label = prop.name;
        prop.value = prop.key;
        return prop;
      });
      state = { ...state, expertiseOption };
      return { ...state };
    //list expertise
    case Types.GET_LIST_EXPERTISE:
      state = { ...state, listExpertise: action.data };
      return { ...state };
    case Types.GET_EMPLOYEE_PROFILE:
      state = { ...state, employee_profile: action.data };
      return { ...state };

    //Update employee
    case Types.UPDATE_EMPLOYEE:
      var employee_info = action.data.employee_profile;
      var employeeList = state.employee.data.map(prop => {
        if (prop.id === employee_info.id) {
          prop = employee_info;
        }
        return prop;
      });
      state = {
        ...state,
        employee_info,
        employee: { ...state.employee, data: employeeList }
      };
      return { ...state };

    default:
      return { ...state };
  }
};

export default employeeReducer;
