import React from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";

export default function ChiefMoveToCollectionModal(props) {
  return (
    <div>
      <Modal
        isOpen={props.modal}
        className="modal-dialog-centered modal-move-to-collection"
        style={{ minWidth: 608 }}
      >
        <ModalHeader>Move to collection</ModalHeader>
        <ModalBody className="pt-3">
          <p
            style={{
              color: "#4B556F",
              fontSize: 14,
              marginBottom: 8,
              fontWeight: 600
            }}
          >
            Collection
          </p>
          <Select2
            className="form-control modal-move-to-collection-select"
            options={{
              placeholder: "Select or search collection name"
            }}
            value={props.newCollectionId}
            data={props.dataSelect}
            onChange={props.handleChangeSelectMoveToCollection}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-flex-end p-3">
          <Button
            onClick={props.toggle}
            color="link"
            style={{ color: "#172B4D" }}
          >
            CANCEL
          </Button>
          <Button
            color="link"
            style={{ color: "#fb6340" }}
            onClick={() => props.handleMoveToCollection(false, "")}
          >
            MOVE
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ChiefMoveToCollectionModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  handleMoveToCollection: PropTypes.func,
  handleChangeSelectMoveToCollection: PropTypes.func,
  dataSelect: PropTypes.array,
  newCollectionId: PropTypes.string
};
