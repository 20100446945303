import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  listcontent: {
    data: {
      docs: []
    }
  },
  topic_list: [],
  listcontent_detail: {},
  listcontent_byid: {
    data: [
      {
        angle_title: "",
        topic: [],
        tags: [],
        type_of_article: [],
        topics_of_concern: [],
        objectives: "",
        age_group: [],
        comprehension_level: [],
        references: "",
        steps_to_write: [],
        things_to_avoid: "",
        things_to_mention: "",
        tone_of_writing: [],
        note: "",
        keywords: [],
        outline_content: ""
      }
    ]
  },
  mycontentlist: {
    docs: []
  },
  skip_outline: {},
  writing_content_id: {
    data: [
      {
        angle_title: "",
        content_content: ""
      }
    ]
  },
  header_dashboard: {
    data: []
  },
  dashboards_writer: {},
  header_dashboards_writer: {
    data: [
      {
        today_deadline: "",
        content_approved: "",
        content_rewrite: "",
        outline_approved: ""
      }
    ]
  },
  my_assigned_list: {
    data: {
      docs: []
    }
  },
  writer_guideline: {
    data: ""
  },
  writer_faq: {
    data: ""
  },
  outline_in_progress_list: {
    docs: []
  },
  article_in_progress_list: {
    docs: []
  },
  approved_article_list: {
    docs: []
  },
  dealine_list: {
    data: []
  },
  writer_count_menu_dashboard: {
    data: {}
  },
  content_versions: [],
  outline_versions: [],
  list_topic: []
};

const writerArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_CONTENT_REQUEST_LIST:
      state = {
        ...state,
        listcontent: action.data
      };
      return { ...state };
    case Types.GET_CONTENT_REQUEST_DETAIL:
      state = {
        ...state,
        listcontent_detail: action.data
      };
      return { ...state };
    case Types.GET_CONTENT_REQUEST_BYID:
      return { ...state, listcontent_byid: action.data };
    case Types.GET_MY_CONTENT_LIST:
      return { ...state, mycontentlist: action.data };
    case Types.GET_SKIP_OUTLINE:
      return { ...state, skip_outline: action.data };
    case Types.GET_WRITING_CONTENT_BY_ID:
      return { ...state, writing_content_id: action.data };
    case Types.GET_HEADER_DASHBOARD:
      return { ...state, header_dashboard: action.data };
    case Types.GET_DASHDOARDS_WRITER:
      return { ...state, dashboards_writer: action.data };
    case Types.GET_HEADER_DASHBOARDS_WRITER:
      return { ...state, header_dashboards_writer: action.data };
    case Types.GET_MY_ASSIGNED_POST:
      return { ...state, my_assigned_list: action.data };
    case Types.GET_WRITER_GUIDELINE:
      return { ...state, writer_guideline: action.data };
    case Types.GET_WRITER_FAQ:
      return { ...state, writer_faq: action.data };
    case Types.GET_OUTLINE_IN_PROGRESS:
      return { ...state, outline_in_progress_list: action.data };
    case Types.GET_ARTICLE_IN_PROGRESS:
      return { ...state, article_in_progress_list: action.data };
    case Types.GET_APPROVED_ARTICLE:
      return { ...state, approved_article_list: action.data };
    case Types.GET_DASHBOARD_DEALINE:
      return { ...state, dealine_list: action.data };
    case Types.GET_WRITER_COUNT_MENU_DASHBOARD:
      return { ...state, writer_count_menu_dashboard: action.data };
    case Types.GET_CONTENT_VERSION:
      return { ...state, content_versions: action.data };
    case Types.GET_OUTLINE_VERSION:
      return { ...state, outline_versions: action.data };

    case Types.GET_TOPICS_LIST:
      var list_topic = action.data;
      list_topic.map(prop => {
        prop.label = prop.name;
        prop.value = prop.key;
        return prop;
      });
      state = { ...state, list_topic };
      return { ...state };
    default:
      return { ...state };
  }
};

export default writerArticleReducer;
