import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";
import { actGetCountInfoChiefEditorRequest } from "./permission.action";
import { actGetWritingContentByIDRequestForEditor } from "./writerArticle.action";

export const actGetPublishArticleListRequest = (data) => {
  return (dispatch) => {
    dispatch(actGetPublishArticleListStart());
    return callApi(
      `article/get-list-completed?` +
        `title=${data.search}` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&status_article=${data.status_article}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetPublishArticleList(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};
export const actGetPublishArticleListStart = () => {
  return {
    type: Types.GET_CHIEF_PUBLISH_ARTICLE_LIST_START,
  };
};
export const actGetPublishArticleList = (data) => {
  return {
    type: Types.GET_CHIEF_PUBLISH_ARTICLE_LIST,
    data,
  };
};

export const actGetPublishedArticleListRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `article/get-list-public-unpublic?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetPublishedArticleList(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetPublishedArticleList = (data) => {
  return {
    type: Types.GET_CHIEF_PUBLISHED_ARTICLE_LIST,
    data,
  };
};

export const actGetArticleTopicRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `topics?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetArticleTopic(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetArticleTopic = (data) => {
  return {
    type: Types.GET_CHIEF_ARTICLE_TOPIC,
    data,
  };
};

export const actCreateTopicRequest = (data) => {
  return (dispatch) => {
    callApi(`topics`, "POST", data)
      .then((res) => {
        dispatch(actGetArticleTopicRequest(data));
        dispatch(
          actNotify({
            message: "Created succesfully",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actUpdateTopicRequest = (id, data) => {
  return (dispatch) => {
    callApi(`topics/${id}`, "PUT", data)
      .then((res) => {
        dispatch(actGetArticleTopicRequest(data));
        dispatch(
          actNotify({
            message: "Updated succesfully",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actDeleteTopicRequest = (id, data) => {
  return (dispatch) => {
    callApi(`topics/${id}`, "DELETE")
      .then((res) => {
        dispatch(actGetArticleTopicRequest(data));
        dispatch(
          actNotify({
            message: "Deleted succesfully",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetTopicDetailByIDRequest = (id) => {
  return (dispatch) => {
    return callApi(`topics/${id}`, "GET")
      .then((res) => {
        dispatch(actChiefGetTopicDetailByID(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actChiefGetTopicDetailByID = (data) => {
  return {
    type: Types.GET_CHIEF_TOPIC_BY_ID,
    data,
  };
};

export const actGetPublishDetailByIDRequest = (id) => {
  return (dispatch) => {
    return callApi(`article/content-detail/${id}`, "GET")
      .then((res) => {
        dispatch(actGetPublishDetailByID(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetPublishDetailByID = (data) => {
  return {
    type: Types.GET_CHIEF_PUBLISH_BYID,
    data,
  };
};

export const actSubmitPublishRequest = (id) => {
  return (dispatch) => {
    const params = {
      articleId: id,
    };
    callApi(`v2/publish-article`, "POST", params)
      .then(() => {
        dispatch(actGetUnPublishedDetailByIDRequest(id));
        dispatch(actGetCountInfoChiefEditorRequest());
        dispatch(
          actNotify({
            message: "Article has been publish succesfuly",
            color: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actSubmitSendBackRequest = (id) => {
  return (dispatch) => {
    const params = {
      articleId: id,
    };
    callApi(`/v2/editing-article`, "POST", params)
      .then(() => {
        dispatch(actGetUnPublishedDetailByIDRequest(id));
        dispatch(actGetCountInfoChiefEditorRequest());
        dispatch(
          actNotify({
            message: "Article has been send back succesfuly",
            color: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetUnPublishedDetailByIDRequest = (id) => {
  return (dispatch) => {
    dispatch(actGetUnPublishedDetailByID_START());
    return callApi(`v2/article-detail/${id}`, "GET")
      .then((res) => {
        // Handle SEO object === null
        let seo;
        if (res.data && res.data.article.seo) {
          seo = res.data.article.seo;
        }
        if (!seo) {
          seo = {
            title: res.data.article.angle_title,
            excerpt: res.data.article.excerpt,
            image: res.data.article.image,
            tags: res.data.article.tags,
          };
        }
        const newRes = { ...res, data: { ...res.data.article, seo } };
        dispatch(actGetUnPublishedDetailByID(newRes));
      })
      .catch((err) => {
        dispatch(actGetUnPublishedDetailByID_FAIL());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actResetUnPublishedDetailByID = (data) => {
  return {
    type: Types.RESET_CHIEF_UNPUBLISH_BYID,
    data,
  };
};
export const actGetUnPublishedDetailByID_START = () => {
  return {
    type: Types.GET_CHIEF_UNPUBLISH_BYID_START,
  };
};

export const actGetUnPublishedDetailByID = (data) => {
  return {
    type: Types.GET_CHIEF_UNPUBLISH_BYID,
    data,
  };
};

export const actGetUnPublishedDetailByID_FAIL = () => {
  return {
    type: Types.GET_CHIEF_UNPUBLISH_BYID_FAIL,
  };
};

export const actSubmitUnPublishedRequest = (id) => {
  return (dispatch) => {
    const params = {
      articleId: id,
    };
    callApi(`v2/unpublish-article`, "POST", params)
      .then(() => {
        dispatch(actGetUnPublishedDetailByIDRequest(id));
        dispatch(actGetCountInfoChiefEditorRequest());
        dispatch(
          actNotify({
            message: "Article has been unpublish succesfuly",
            color: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actAutoSaveWritingContentRequest = (data) => {
  return (dispatch) => {
    callApi(`content/save-draft-auto`, "PUT", data)
      .then((res) => {
        // dispatch(
        //   actNotify({
        //     message: "Auto save !",
        //     color: "info"
        //   })
        // );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actSaveWritingContentRequest = (data) => {
  return (dispatch) => {
    callApi(`content/save-draft`, "PUT", data)
      .then((res) => {
        dispatch(
          actNotify({
            message: "Save draft successfully !",
            color: "info",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actSaveDraftOutlineRequest = (data) => {
  return (dispatch) => {
    callApi(`outline/save-draft-auto`, "PUT", data)
      .then((res) => {
        dispatch(
          actNotify({
            message: "Save draft successfully !",
            color: "info",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actAutoSaveOutlineRequest = (data) => {
  return (dispatch) => {
    callApi(`outline/save-draft-auto`, "PUT", data)
      .then((res) => {
        // dispatch(
        //   actNotify({
        //     message: "Save draft successfully !",
        //     color: "info"
        //   })
        // );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetCountMenuDashBoardRequest = () => {
  return (dispatch) => {
    return callApi(`article/header-dashboards-writer`, "GET")
      .then((res) => {
        dispatch(actGetCountMenuDashBoard(res));
        dispatch(actGetCountInfoChiefEditorRequest());
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetCountMenuDashBoard = (data) => {
  return {
    type: Types.GET_CHIEF_COUNT_MENU_DASHBOARD,
    data,
  };
};

export const actGetTableDealineDashboardRequest = () => {
  return (dispatch) => {
    return callApi(`article/dead-line-chiefeditor`, "GET")
      .then((res) => {
        dispatch(actGetTableDealineDashboard(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetTableDealineDashboard = (data) => {
  return {
    type: Types.GET_TABLE_DEALINE_DASHBOARD,
    data,
  };
};

export const actGetArticleListDashboardRequest = () => {
  return (dispatch) => {
    return callApi(`article/out-line-content`, "GET")
      .then((res) => {
        dispatch(actGetArticleListDashboard(res));
        dispatch(actGetCountInfoChiefEditorRequest());
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetArticleListDashboard = (data) => {
  return {
    type: Types.GET_CHIEF_LIST_ARTICLE,
    data,
  };
};

export const actGetWriterProfileListRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `writer-following-chiefeditor?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}` +
        `&topic=${data.topic}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetWriterProfileList(res));
        dispatch(actGetCountInfoChiefEditorRequest());
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetWriterProfileList = (data) => {
  return {
    type: Types.GET_CHIEF_WRITER_PROFILE,
    data,
  };
};

export const actGetWriterProfileTableDetailRequest = (id, data) => {
  return (dispatch) => {
    return callApi(
      `detail-writer-following-chiefeditor/${id}?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetWriterProfileTableDetail(res));
        dispatch(actGetCountInfoChiefEditorRequest());
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetWriterProfileTableDetail = (data) => {
  return {
    type: Types.GET_CHIEF_WRITER_PROFILE_TABLE_DETAIL,
    data,
  };
};

export const actGetEditorProfileListRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `editor-following-chiefeditor?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetEditorProfileList(res));
        dispatch(actGetCountInfoChiefEditorRequest());
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetEditorProfileList = (data) => {
  return {
    type: Types.GET_CHIEF_EDITOR_PROFILE,
    data,
  };
};

export const actGetEditorProfileTableDetailRequest = (id, data) => {
  return (dispatch) => {
    return callApi(
      `detail-editor-following-chiefeditor/${id}?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetEditorProfileTableDetail(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetEditorProfileTableDetail = (data) => {
  return {
    type: Types.GET_CHIEF_EDITOR_PROFILE_TABLE_DETAIL,
    data,
  };
};

export const actGetAssignedBriefsListRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `assigned-post?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetAssignedBriefsList(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetAssignedBriefsList = (data) => {
  return {
    type: Types.GET_CHIEF_ASSIGNED_BRIEFS,
    data,
  };
};

export const actGetChiefOutlineInProgressListRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `article/coe-outline-inprocess?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetChiefOutlineInProgressList(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetChiefOutlineInProgressList = (data) => {
  return {
    type: Types.GET_CHIEF_OUTLINE_IN_PROGRESS,
    data,
  };
};

export const actGetChiefArticleInProgressListRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `article/coe-article-inprocess?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetChiefArticleInProgressList(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetChiefArticleInProgressList = (data) => {
  return {
    type: Types.GET_CHIEF_ARTICLE_IN_PROGRESS,
    data,
  };
};

export const actGetChiefEditingListRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `article/coe-article-editing?` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetChiefEditingList(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetChiefEditingList = (data) => {
  return {
    type: Types.GET_CHIEF_EDITING,
    data,
  };
};

export const actGetChiefContentIDRequest = (id) => {
  return (dispatch) => {
    return callApi(`outline-detail/${id}`, "GET")
      .then((res) => {
        dispatch(actGetChiefContentID(res));
        dispatch(actGetCountInfoChiefEditorRequest());
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetChiefContentID = (data) => {
  return {
    type: Types.GET_CHIEF_CONTENT_REQUEST_BYID,
    data,
  };
};

export const actGetTodayDeadlineListChiefRequest = (data, type, number) => {
  if (!type && !number) {
    return (dispatch) => {
      return callApi(`article/dead-line-chiefeditor`, "GET")
        .then((res) => {
          dispatch(actGetTodayDeadlineListChief(res));
          dispatch(actGetCountInfoChiefEditorRequest());
        })
        .catch((err) => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger",
            })
          );
        });
    };
  } else {
    return (dispatch) => {
      return callApi(
        `article/dead-line-chiefeditor?sort=${type}|${number}`,
        "GET"
      )
        .then((res) => {
          dispatch(actGetTodayDeadlineListChief(res));
        })
        .catch((err) => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger",
            })
          );
        });
    };
  }
};

export const actGetTodayDeadlineListChief = (data) => {
  return {
    type: Types.GET_CHIEF_TODAY_DEADLINE,
    data,
  };
};

export const actGetChiefTodayPublishedRequest = (data, type, number) => {
  if (!type && !number) {
    return (dispatch) => {
      return callApi(`article/publish-today-chiefeditor`, "GET")
        .then((res) => {
          dispatch(actGetChiefTodayPublished(res));
          dispatch(actGetCountInfoChiefEditorRequest());
        })
        .catch((err) => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger",
            })
          );
        });
    };
  } else {
    return (dispatch) => {
      return callApi(
        `article/publish-today-chiefeditor?sort=${type}|${number}`,
        "GET"
      )
        .then((res) => {
          dispatch(actGetChiefTodayPublished(res));
        })
        .catch((err) => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger",
            })
          );
        });
    };
  }
};

export const actGetChiefTodayPublished = (data) => {
  return {
    type: Types.GET_CHIEF_TODAY_PUBLISHED,
    data,
  };
};

export const actGetChiefAvailableBriefsRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `article/coe-available-brief?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetChiefAvailableBriefs(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetChiefAvailableBriefs = (data) => {
  return {
    type: Types.GET_CHIEF_AVAILABLE_BRIEFS,
    data,
  };
};

export const actGetChiefDraftBriefsRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `article/coe-draft-brief?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetChiefDraftBriefs(res));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetChiefDraftBriefs = (data) => {
  return {
    type: Types.GET_CHIEF_DRAFT_BRIEFS,
    data,
  };
};
//Guideline
export const actGetChiefEditorialGuidelineRequest = (data) => {
  return (dispatch) => {
    if (localStorage.getItem("key") === "chiefeditor") {
      return callApi(`general-information-coe?select=guideline|1`, "GET")
        .then((res) => {
          dispatch(actGetChiefEditorialGuideline(res));
        })
        .catch((err) => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger",
            })
          );
        });
    }
  };
};

export const actUpdateChiefEditorialGuidelineRequest = (guideline) => {
  return (dispatch) => {
    if (localStorage.getItem("key") === "chiefeditor") {
      return callApi(`editorial-guideline?select=guideline|1`, "POST", {
        guideline,
      })
        .then((res) => {
          localStorage.setItem("guideline", res.data.guideline);
          dispatch(
            actNotify({
              message: "Update succesfully",
            })
          );
          dispatch(actGetChiefEditorialGuideline(guideline));
        })
        .catch((err) => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger",
            })
          );
        });
    }
  };
};

export const actGetChiefEditorialGuideline = (data) => {
  return {
    type: Types.GET_CHIEF_GUIDELINE,
    data,
  };
};

//Faq
export const actGetChiefFaqRequest = (data) => {
  return (dispatch) => {
    if (localStorage.getItem("key") === "chiefeditor") {
      return callApi(`general-information-coe?select=faq|1`, "GET")
        .then((res) => {
          dispatch(actGetChiefFaq(res));
        })
        .catch((err) => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger",
            })
          );
        });
    }
  };
};

export const actUpdateChiefFaqRequest = (faq) => {
  return (dispatch) => {
    if (localStorage.getItem("key") === "chiefeditor") {
      return callApi(`editorial-guideline?select=faq|1`, "POST", {
        faq,
      })
        .then((res) => {
          localStorage.setItem("faq", res.data.faq);
          dispatch(
            actNotify({
              message: "Update succesfully",
            })
          );
          dispatch(actGetChiefFaq(faq));
        })
        .catch((err) => {
          dispatch(
            actNotify({
              message: renderError(err),
              color: "danger",
            })
          );
        });
    }
  };
};

export const actGetChiefFaq = (data) => {
  return {
    type: Types.GET_CHIEF_FAQ,
    data,
  };
};

export const actGetRejectAssignedListRequest = (data) => {
  return (dispatch) => {
    return callApi(
      `list-reject-assign-article?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then((res) => {
        dispatch(actGetRejectAssignedList(res));
        dispatch(actGetCountInfoChiefEditorRequest());
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actGetRejectAssignedList = (data) => {
  return {
    type: Types.GET_CHIEF_REJECT_ASSIGNED,
    data,
  };
};

export const actUpdateArticleSettingRequest = (data) => {
  return (dispatch) => {
    return callApi(`v2/article/setting`, "POST", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "You have saved article setting successfuly!",
            color: "success",
          })
        );
        dispatch(actGetUnPublishedDetailByIDRequest(data.articleId));
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actEditorSaveSettingRequest = (data) => {
  return (dispatch) => {
    return callApi(`v2/article/setting`, "POST", data)
      .then(() => {
        dispatch(actGetWritingContentByIDRequestForEditor(data.articleId));
        dispatch(
          actNotify({
            message: "You have saved article setting successfuly!",
            color: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actRemoveArticleSettingRequest = (id) => {
  return (dispatch) => {
    const data = {
      articleId: id,
    };
    return callApi(`v2/delete-article`, "POST", data)
      .then(() => {
        dispatch(
          actNotify({
            message: "You have removed article successfuly!",
            color: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

export const actUpdateArticleSEORequest = (id, data) => {
  return (dispatch) => {
    return callApi(`seo/meta/${id}`, "PUT", data)
      .then(() => {
        // dispatch(actGetUnPublishedDetailByIDRequest(id));
        dispatch(
          actNotify({
            message: "You have saved article SEO successfuly!",
            color: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};
// Chief add new article drirectly
export const chiefAddNewArticle = () => {
  return {
    type: Types.CHIEF_ADD_NEW_ARTICLE,
  };
};
export const actChiefAddNewArticle = (data) => {
  return (dispatch) => {
    callApi(`v2/article`, "POST", data)
      .then((res) => {
        dispatch(chiefAddNewArticle());
        dispatch(
          actNotify({
            message: "Created succesfully",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

// Add slideshow article
export const chiefAddNewSlideArticle = () => {
  return {
    type: Types.CHIEF_ADD_NEW_SLIDE_ARTICLE,
  };
};
export const chiefUpdateSlideArticle = () => {
  return {
    type: Types.CHIEF_UPDATE_SLIDE_ARTICLE,
  };
};
export const actChiefAddNewSlideArticle = (data) => {
  return (dispatch) => {
    callApi(`v2/slide-show-article`, "POST", data)
      .then((res) => {
        dispatch(chiefAddNewArticle());
        dispatch(
          actNotify({
            message: "Created succesfully",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};
export const actChiefUpdateSlideArticle = (data) => {
  return (dispatch) => {
    callApi(`v2/slide-show-article`, "PUT", data)
      .then((res) => {
        dispatch(chiefUpdateSlideArticle());
        dispatch(
          actNotify({
            message: "Updated succesfully",
          })
        );
      })
      .catch((err) => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};
export const generatePreviewLinkSuccess = (data) => {
  return {
    type: Types.GENERATE_PREVIEW_LINK_SUCCESS,
    data,
  };
};
export const generatePreviewLink = () => {
  return {
    type: Types.GENERATE_PREVIEW_LINK,
  };
};
export const generatePreviewLinkFail = () => {
  return {
    type: Types.GENERATE_PREVIEW_LINK_FAIL,
  };
};
// Generate expired preview link
export const actGeneratePreviewLinkRequest = (data) => {
  return (dispatch) => {
    dispatch(generatePreviewLink());
    callApi(`v2/preview-article-pin`, "POST", data)
      .then((res) => {
        dispatch(generatePreviewLinkSuccess(res.data));
      })
      .catch((err) => {
        dispatch(generatePreviewLinkFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};

// Get poll for article
export const getPollByArticleSuccess = (data) => {
  return {
    type: Types.GET_POLL_SUCCESS,
    data,
  };
};
export const getPollByArticleFail = (data) => {
  return {
    type: Types.GET_POLL_FAIL,
    data,
  };
};
export const getPollByArticle = (data) => {
  return {
    type: Types.GET_POLL,
    data,
  };
};

// Update poll
export const updatePollByArticleSuccess = (data) => {
  return {
    type: Types.UPDATE_POLL_SUCCESS,
    data,
  };
};
export const updatePollByArticleFail = (data) => {
  return {
    type: Types.UPDATE_POLL_FAIL,
    data,
  };
};
export const updatePollByArticle = (data) => {
  return {
    type: Types.UPDATE_POLL,
    data,
  };
};
// delete
export const deletePollByArticleSuccess = (data) => {
  return {
    type: Types.DELETE_POLL_SUCCESS,
    data,
  };
};
export const deletePollByArticleFail = (data) => {
  return {
    type: Types.DELETE_POLL_FAIL,
    data,
  };
};
export const deletePollByArticle = (data) => {
  return {
    type: Types.DELETE_POLL,
    data,
  };
};
//Get poll
export const actGetPollByArticleRequest = (id) => {
  return (dispatch) => {
    dispatch(getPollByArticle());
    callApi(`v2/poll/${id}`, "GET")
      .then((res) => {
        dispatch(getPollByArticleSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getPollByArticleFail());
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};
// Update poll
export const actUpdatePollByArticleRequest = (data) => {
  return (dispatch) => {
    dispatch(updatePollByArticle());
    callApi(`v2/poll/`, "PUT", data)
      .then((res) => {
        dispatch(updatePollByArticleSuccess(res.data));
        dispatch(
          actNotify({
            message: "Update poll successfully",
          })
        );
      })
      .catch((err) => {
        dispatch(updatePollByArticleFail());
        dispatch(actGetPollByArticleRequest(data.pollId));
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};
//Delete poll
export const actDeletePollByArticleRequest = (data, article_id) => {
  const jobKey = localStorage.getItem("key");
  return (dispatch) => {
    dispatch(deletePollByArticle());
    callApi(`v2/poll/remove`, "PUT", data)
      .then((res) => {
        dispatch(deletePollByArticleSuccess(res.data));
        dispatch(
          actNotify({
            message: "Delete poll successfully",
          })
        );
        if (jobKey === "editor") {
          dispatch(actGetWritingContentByIDRequestForEditor(article_id));
        }
        if (jobKey === "chiefeditor") {
          dispatch(actGetUnPublishedDetailByIDRequest(article_id));
        }
      })
      .catch((err) => {
        dispatch(deletePollByArticleFail());
        dispatch(actGetPollByArticleRequest(data.pollId));
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger",
          })
        );
      });
  };
};
