import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Table, Tag, Menu, Dropdown, Button, Switch } from "antd";
import { Card, CardBody, CardHeader } from "reactstrap";
import option from "../../../assets/img/icons/option.svg";
import LiveUpdateForm from "../LiveUpdateForm";
import ConfirmModal from "../../Modals/ApplicationDetail/Confirm";
import { ARTICLE_STATUS } from "../../../utils/constants";
import { stripHTMLTag } from "../../../utils/stripHtml";
import {
  actGetListTimelineRequest,
  actGetTimelineDetailRequest,
  actUpdateTimelineItemRequest,
  actClearTimelineDetailItem,
} from "../../../actions/timeline.action";

import "./styles.scss";

class LiveUpdateManagementList extends React.Component {
  // State
  state = {
    visible: false,
    publishModal: false,
    unPublishModal: false,
    deleteModal: false,
    currentId: "",
    isEngVersion: false,
  };
  // Life cycle
  componentDidMount() {
    const data = {
      page: 1,
      limit: 10,
    };
    this.props.getTimelineList(data);
  }
  //   Modal function
  showPublishModal = (id) => {
    this.setState({
      publishModal: true,
      currentId: id,
    });
  };
  hidePublishModal = () => {
    this.setState({
      publishModal: false,
    });
  };
  // Unpublish modal
  showUnpublishModal = (id) => {
    this.setState({
      unPublishModal: true,
      currentId: id,
    });
  };
  hideUnpublishModal = () => {
    this.setState({
      unPublishModal: false,
    });
  };

  showDeleteModal = (id) => {
    this.setState({
      deleteModal: true,
      currentId: id,
    });
  };
  hideDeleteModal = () => {
    this.setState({
      deleteModal: false,
    });
  };
  showModal = () => {
    this.props.clearTimelineItem();
    this.setState({
      visible: true,
      currentId: "",
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  showEditModal = (id) => {
    this.setState({
      visible: true,
      currentId: id,
    });
  };

  renderStatus = (status) => {
    if (status === ARTICLE_STATUS.PUBLISHED) {
      return <Tag color="green">{ARTICLE_STATUS.PUBLISHED}</Tag>;
    } else if (status === ARTICLE_STATUS.UNPUBLISHED) {
      return <Tag color="red">{ARTICLE_STATUS.UNPUBLISHED}</Tag>;
    } else {
      return null;
    }
  };
  // handle change pagination
  handleChange = (pagination) => {
    const data = {
      page: pagination.current,
      limit: pagination.pageSize,
      isEngVersion: this.state.isEngVersion,
    };
    this.props.getTimelineList(data);
  };
  //   Submit function
  handlePublish = (id) => {
    const { isEngVersion } = this.state;
    const data = isEngVersion
      ? {
          timelineId: id,
          status: ARTICLE_STATUS.PUBLISHED,
          isEngVersion,
        }
      : {
          timelineId: id,
          status: ARTICLE_STATUS.PUBLISHED,
        };
    this.hidePublishModal();
    this.props.updateTimelineItem(data);
  };
  handleUnpublish = (id) => {
    const { isEngVersion } = this.state;
    const data = isEngVersion
      ? {
          timelineId: id,
          status: ARTICLE_STATUS.UNPUBLISHED,
          isEngVersion,
        }
      : {
          timelineId: id,
          status: ARTICLE_STATUS.UNPUBLISHED,
        };
    this.hideUnpublishModal();
    this.props.updateTimelineItem(data);
  };
  handleDelete = (id) => {
    const { isEngVersion } = this.state;
    const data = isEngVersion
      ? {
          timelineId: id,
          status: ARTICLE_STATUS.DELETED,
          isEngVersion,
        }
      : {
          timelineId: id,
          status: ARTICLE_STATUS.DELETED,
        };
    this.hideDeleteModal();
    this.props.updateTimelineItem(data);
  };
  onSwitchChange = (value) => {
    this.setState({
      isEngVersion: value,
    });
    const data = {
      page: 1,
      limit: 10,
      isEngVersion: value,
    };
    this.props.getTimelineList(data);
  };
  render() {
    const {
      visible,
      publishModal,
      unPublishModal,
      deleteModal,
      currentId,
      isEngVersion,
    } = this.state;
    const { timelineList, isLoading, total } = this.props;
    const menu = (item) => {
      return (
        <Menu onClick={this.handleMenuClick}>
          {item.status === ARTICLE_STATUS.UNPUBLISHED ? (
            <Menu.Item onClick={() => this.showPublishModal(item._id)} key="1">
              Publish
            </Menu.Item>
          ) : (
            <Menu.Item
              onClick={() => this.showUnpublishModal(item._id)}
              key="1"
            >
              Unpublish
            </Menu.Item>
          )}
          <Menu.Item onClick={() => this.showEditModal(item._id)} key="2">
            Edit
          </Menu.Item>
          <Menu.Item onClick={() => this.showDeleteModal(item._id)} key="3">
            Delete
          </Menu.Item>
        </Menu>
      );
    };
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Content",
        dataIndex: "content",
        key: "content",
        ellipsis: true,
        render: (text) => <span>{stripHTMLTag(text)}</span>,
      },
      {
        title: "Date",
        key: "date",
        // sorter: (a, b) => {
        //   return moment(a.publishDate).unix() - moment(b.publishDate).unix();
        // },
        // sortDirections: ["descend", "ascend"],
        render: (item) => {
          return <span>{moment(item.publishDate).format("DD-MM-YYYY")}</span>;
        },
      },
      {
        title: "Status",
        key: "status",
        render: (item) => this.renderStatus(item.status),
      },
      {
        title: "Action",
        key: "action",
        // eslint-disable-next-line react/display-name
        render: (item) => (
          <Dropdown overlay={menu(item)} trigger={["click"]}>
            <img className="action-btn" src={option} />
          </Dropdown>
        ),
      },
    ];
    return (
      <section>
        <div className="live-update-manangement-section">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div>{`Timeline list for ${
                  !isEngVersion ? "Vietnamese" : "English"
                }`}</div>
                <div>
                  <Switch
                    className="mr-4"
                    onChange={this.onSwitchChange}
                    checkedChildren="EN"
                    unCheckedChildren="VN"
                  />
                  <Button onClick={this.showModal} type="primary">
                    Add new update
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Table
                onChange={this.handleChange}
                pagination={{ total: total, defaultCurrent: 1 }}
                loading={isLoading}
                dataSource={timelineList}
                columns={columns}
              />
            </CardBody>
          </Card>
        </div>
        <LiveUpdateForm
          isEngVersion={isEngVersion}
          currentId={currentId}
          visible={visible}
          handleCancel={this.closeModal}
          handleOk={this.handleSubmit}
        />
        <ConfirmModal
          closable={false}
          visible={publishModal}
          handleOk={() => this.handlePublish(currentId)}
          handleCancel={this.hidePublishModal}
          title="Are you sure you want to publish?"
        />
        <ConfirmModal
          closable={false}
          visible={unPublishModal}
          handleOk={() => this.handleUnpublish(currentId)}
          handleCancel={this.hideUnpublishModal}
          title="Are you sure you want to unpublish?"
        />
        <ConfirmModal
          closable={false}
          visible={deleteModal}
          handleOk={() => this.handleDelete(currentId)}
          handleCancel={this.hideDeleteModal}
          title="Are you sure you want to delete?"
        />
      </section>
    );
  }
}
LiveUpdateManagementList.propTypes = {
  timelineList: PropTypes.array,
  getTimelineList: PropTypes.func,
  getTimelineDetail: PropTypes.func,
  updateTimelineItem: PropTypes.func,
  clearTimelineItem: PropTypes.func,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
};
const mapStateToProps = (state) => {
  return {
    timelineList: state.timelineReducer.timelineList.timelines,
    total: state.timelineReducer.timelineList.totalItems,
    isLoading: state.timelineReducer.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTimelineList: (params) => {
      dispatch(actGetListTimelineRequest(params));
    },
    getTimelineDetail: (id) => {
      dispatch(actGetTimelineDetailRequest(id));
    },
    updateTimelineItem: (data) => {
      dispatch(actUpdateTimelineItemRequest(data));
    },
    clearTimelineItem: () => {
      dispatch(actClearTimelineDetailItem());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveUpdateManagementList);
