import React, { useState } from "react";
import PropTypes from "prop-types";
// component
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import MultipleCheckboxSelectFeature from "../MultipleCheckboxSelect/MultipleCheckboxSelectFeature";
// redux
// redux
import { useDispatch, useSelector } from "react-redux";
import { actSearchArticlesRequest } from "../../actions/chief-collection.action";
import ReactHtmlParser from "react-html-parser";

function ChiefAddArticleToCollectionModal(props) {
  const dispatch = useDispatch();
  const data_search = useSelector(
    state => state.ChiefCollectionReducer.data_search
  );
  const [values, setValues] = useState({
    valueSearch: "",
    dataSearch: []
  });
  const [selectAll, useSelectAll] = useState(false);
  const [arrSelect, setArrSelect] = useState([]);
  const [selectedId, setSelectedId] = useState([]);

  const toggle = () => {
    props.toggle();
    setValues({ valueSearch: "", dataSearch: [] });
    setSelectedId([]);
    setArrSelect([]);
  };

  const handleChange = e => {
    let data = {
      value: e.target.value,
      language: props.isLanguage ? "VN" : "EN",
      idCol: props.collection_detail._id
    };
    dispatch(actSearchArticlesRequest(data));
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSelect = arr => {
    if (selectedId.includes(arr._id)) {
      setSelectedId(selectedId.filter(prop => prop !== arr._id));
      setArrSelect(arrSelect.filter(prop => prop._id !== arr._id));
    } else {
      if (selectedId.length < 8) {
        setSelectedId([...selectedId, arr._id]);
        setArrSelect([...arrSelect, arr]);
      } else {
        setSelectedId(selectedId);
        setArrSelect(arrSelect);
      }
    }
  };

  const onSelectAll = () => {
    if (!selectAll) {
      useSelectAll(true);
      setArrSelect(data_search.docs);
      let selectId = [];
      data_search.docs.map(item => {
        selectId.push(item._id);
        return selectId;
      });
      setSelectedId(selectId);
    } else {
      useSelectAll(false);
      setArrSelect([]);
      setSelectedId([]);
    }
  };

  const handleRemoveItemSelected = id => {
    setValues({ ...values, valueSearch: "" });
    setArrSelect(arrSelect.filter(el => el.id !== id));
    setSelectedId(selectedId.filter(el => el !== id));
  };

  const handleClearAll = () => {
    setSelectedId([]);
    setArrSelect([]);
  };

  const handleRemoveAll = () => {
    setValues({ ...values, valueSearch: "" });
    setSelectedId([]);
    setArrSelect([]);
  };

  return (
    <div>
      <Modal
        isOpen={props.isShowModal}
        className="modal-dialog-centered"
        style={{ minWidth: 608 }}
      >
        <ModalHeader toggle={toggle}>Add Articles</ModalHeader>
        <ModalBody className="py-0">
          <div
            className="body-top d-flex justify-content-between"
            style={{ marginBottom: 8 }}
          >
            <span className="text-regular">Add Articles</span>
          </div>

          <MultipleCheckboxSelectFeature
            valueInput={values.valueSearch}
            dataSearch={data_search}
            selectedId={selectedId}
            arrSelect={arrSelect}
            onSelect={onSelect}
            selectAll={selectAll}
            onSelectAll={onSelectAll}
            handleChange={handleChange}
            handleClearAll={handleClearAll}
            hasSelectAll={true}
          />
          <div className="feature-article-list-select-scroll">
            {arrSelect.map((item, index) => {
              return (
                <div className="feature-article-list-select" key={index}>
                  <span className="select-title">
                    {ReactHtmlParser(item.angle_title)}
                  </span>
                  <span
                    className="select-remove"
                    onClick={() => handleRemoveItemSelected(item.id)}
                  >
                    <i className="fa fa-trash-alt" />
                  </span>
                </div>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <div>
            {arrSelect.length > 0 && (
              <Button
                color="link"
                style={{ color: "#5E72E4", fontWeight: "normal" }}
                onClick={handleRemoveAll}
              >
                Remove selected
              </Button>
            )}
          </div>
          <div>
            <Button
              color="link"
              style={{ paddingRight: "0px", color: "#172B4D" }}
              onClick={toggle}
            >
              CANCEL
            </Button>
            <Button
              color="link"
              style={{ color: "#fb6340" }}
              onClick={() => props.handleAddArticleToCollection(selectedId)}
            >
              ADD TO COLLECTION
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ChiefAddArticleToCollectionModal;

ChiefAddArticleToCollectionModal.propTypes = {
  toggle: PropTypes.func,
  data_search: PropTypes.any,
  handleAddArticleToCollection: PropTypes.func,
  isShowModal: PropTypes.bool,
  isLanguage: PropTypes.bool,
  collection_detail: PropTypes.any,
  actSearchFeatureArticles: PropTypes.func
};

ChiefAddArticleToCollectionModal.defaultProps = {};
