import DashboardWriter from "views/dashboards/DashboardWriter.jsx";
import DashboardEditor from "views/dashboards/DashboardEditor.jsx";
import DashboardChief from "views/dashboards/DashboardChief.jsx";
import Login from "./views/login/Login";
import UpdatePassword from "./views/login/UpdatePassword";
import MyProfile from "./views/profile/MyProfile";
import Notification from "./views/notification/Notification";
import ForgotPassword from "./views/login/ForgotPassword";
import ChangeNewPassword from "./views/login/ChangeNewPassword";
//Admin
// import Information from "./views/employee/Information";
import InformationUpdate from "./views/employee/InformationUpdate";
import InformationCreate from "./views/employee/InformationCreate";
// import ProfileAccount from "./views/employee/ProfileAccount";
//Chieft
import ChiefPublishArticle from "./views/article/chief-editor/ChiefPublishArticle";
import ChiefAddNewArticle from "./views/article/chief-editor/ChiefAddNewArticle";
import ChiefTopic from "./views/article/chief-editor/ChiefTopic";
import ChiefPublishReview from "./views/article/chief-editor/ChiefPublishReview";
import ChiefUnPublishReview from "./views/article/chief-editor/ChiefUnPublishReview";
import ChiefAddNewPost from "./views/article/chief-editor/ChieftAddNewPost";
import ChiefAssignedPost from "./views/article/chief-editor/ChiefAssignedPost";
import ChiefOutlineInProgress from "./views/article/chief-editor/ChiefOutlineInProgress";
import ChiefArticleInProgress from "./views/article/chief-editor/ChiefArticleInProgress";
import ChiefEditing from "./views/article/chief-editor/ChiefEditing";
import ChiefWriterProfile from "./views/article/chief-editor/ChiefWriterProfile";
import ChiefEditorProfile from "./views/article/chief-editor/ChiefEditorProfile";
import ChiefWriterProfileDetailTable from "./components/Tables/ChiefWriterProfileDetailTable";
import ChiefEditorProfileDetailTable from "./components/Tables/ChiefEditorProfileDetailTable";
import ChiefWriterWriting from "./views/article/chief-editor/ChiefWriterWriting";
import ChiefDraftBriefs from "./views/article/chief-editor/ChiefDraftBriefs";
import ChiefAvailableBrief from "./views/article/chief-editor/ChiefAvailableBriefs";
import ChiefRejectAssigned from "./views/article/chief-editor/ChiefRejectAssigned";
import ChiefAvailablePitch from "./views/article/chief-editor/ChiefAvailablePitch";
import ChiefPitches from "./views/article/chief-editor/ChiefPitches";
import ChiefBriefTranslate from "./views/article/chief-editor/ChiefBriefTranslate";
import ChiefManageMainMenu from "./views/appearance-managing/MainMenu";
import ChiefFeatureArticles from "./views/feature-article";
import ChiefCollectionManage from "./views/appearance-managing/CollectionManage";
//Editor
import EditorContentRequest from "./views/article/editor/EditorContentRequest";
import EditorAddNewPost from "./views/article/editor/EditorAddNewPost";
import EditorOutlineContent from "./views/article/editor/EditorOutlineContent";
import EditorAvailableRequest from "./views/article/editor/EditorAvailableRequest";
import EditorAssignedPost from "./views/article/editor/EditorAssignedPost";
import EditorAndChiefeditor from "./views/employee/EditorAndChiefeditor";
import EditorAndChiefeditorPast from "./views/employee/EditorAndChiefeditorPast";
import EditorAvailablePitches from "./views/article/editor/EditorAvailablePitches";
import EditorPitches from "./views/article/editor/EditorPitches";
import EditorEditingWriting from "./views/article/editor/EditorEditingWriting";
import EditorialGuideline from "./components/GeneralInformation/EditorialGuideline";
import EditorArticleWriting from "./views/article/editor/EditorArticleWriting";
import EditorRejectAssigned from "./views/article/editor/EditorRejectAssigned";
import EditorPublishedArticle from "./views/article/editor/EditorPublishedArticle";
import EditorPublishedArticles from "./views/article/editor/EditorPublishedArticles";
// Writer
import WriterListContentRequest from "./views/article/writer/WriterListContentRequest";
import WriterWriting from "./views/article/writer/WriterWriting";
import WriterContentRequest from "./views/article/writer/WriterContentRequest";
import WriterAssignedPost from "./views/article/writer/WriterAssignedPost";
import WriterOutlineInProgress from "./views/article/writer/WriterOutlineInProgress";
import WriterArticleInProgress from "./views/article/writer/WriterArticleInProgress";
import WriterApprovedArticle from "./views/article/writer/WriterApprovedArticle";
import EditorWriterProfile from "./views/article/editor/EditorWriterProfile";
import EditorWriterInformation from "./views/article/editor/EditorWriterInformation";
import EditorDraftBriefs from "./views/article/editor/EditorDraftBriefs";
import Writer from "./views/employee/Writer";
import WriterPast from "./views/employee/WriterPast";
import WriterAddNewPitch from "./views/article/writer/WriterAddNewPitch";
import WriterAvailablePitches from "./views/article/writer/WriterAvailablePitches";

import ViewContentWriterDetail from "./components/Detail/ViewContentWriterDetail";
import ViewContentChiefDetail from "./components/Detail/ViewContentChiefDetail";
import OutlineInProgress from "./views/article/OutlineInProgress";
import ArticlesInProgress from "./views/article/ArticleInProgress";
import Editing from "./views/article/editor/EditorEditing";

import Faq from "./components/GeneralInformation/Faq";
import Article from "./views/statistics/Article";
import ComingSoon from "./views/comming-soon/ComingSoon";
import AllBriefs from "./views/article/AllBriefs";
import ContentVersion from "./views/article/ContentVersion";
import OutlineVersion from "./views/article/OutlineVersion";
import GeneralInformation from "./views/appearance-managing/GeneralInformation";
import ChiefReaders from "./views/readers/ChiefReaders";
// Application management components
// Editor views
import EditorApplicationManaging from "./views/application-management/EditorApplicationManaging";
import EditorApplicationDetail from "./views/application-management/EditorApplicationDetail";
// Chief views
import ChiefApplicationManaging from "./views/application-management/ChiefApplicationManaging";
import ChiefApplicationDetail from "./views/application-management/ChiefApplicationDetail";
import ChiefOfficialTag from "./views/application-management/ChiefOfficialTag";
import ChiefSuggestedTag from "./views/application-management/ChiefSuggestedTag";
// Live update news page
import LiveUpdatePage from "./views/live-updates";
// Slideshow article
import Dashbroad from "./views/dashbroadsReport";
import VietceteraOpen from "./views/article/chief-editor/VietceteraOpen";
// import DashbroadReaderShip from "./views/dashbroadsReport/DashbroadReadership";
const routes = [
  {
    name: "Dashboards",
    icon: "icon-dashboard",
    state: "dashboardsCollapse",
    path: "/admin/writer/dashboard-writer",
    layout: "/",
    exact: true,
    component: DashboardWriter,
    permission: "VIEW_WRITER_DASHBOARDS",
  },
  {
    collapse: true,
    name: "Market Place",
    icon: "icon-market",
    state: "marketCollapse",
    permission: "VIEW_WRITER_DASHBOARDS",
    views: [
      {
        path: "/admin/writer/available-briefs",
        name: "View Available Briefs",
        layout: "/",
        exact: true,
        component: WriterListContentRequest,
        permission: "VIEW_CONTENT_REQUEST",
        key: "available_briefs",
      },
      {
        path: "/admin/writer/my-assigned-post",
        name: "View Assigned Briefs",
        layout: "/",
        exact: true,
        component: WriterAssignedPost,
        permission: "VIEW_MY_CONTENT",
        key: "my_assigned_post",
      },
    ],
  },
  {
    collapse: true,
    name: "My Pitches",
    icon: "icon-pitch",
    state: "pitchCollapse",
    permission: "VIEW_WRITER_DASHBOARDS",
    views: [
      {
        name: "Add New Pitch",
        path: "/admin/writer/new-pitch",
        layout: "/",
        icon: "ni ni-bullet-list-67 text-default",
        component: WriterAddNewPitch,
        invisible: true,
      },
      {
        path: "/admin/writer/my-pitches",
        name: "View Pitches",
        layout: "/",
        exact: true,
        component: WriterAvailablePitches,
        permission: "VIEW_MY_CONTENT",
        key: "available_pitches",
      },
      {
        name: "Pitch Detail",
        path: "/admin/writer/pitch-detail/:id",
        layout: "/",
        icon: "ni ni-bullet-list-67 text-default",
        component: WriterAddNewPitch,
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "My Outlines",
    icon: "icon-paper",
    state: "outlineCollapse",
    permission: "VIEW_WRITER_DASHBOARDS",
    views: [
      {
        path: "/admin/writer/writer-writing/:id",
        name: "Writer writing",
        invisible: true,
        layout: "/",
        exact: true,
        component: WriterWriting,
      },
      {
        path: "/admin/writer/content-version/:id",
        layout: "/",
        invisible: true,
        component: ContentVersion,
      },
      {
        path: "/admin/writer/outline-version/:id",
        layout: "/",
        invisible: true,
        component: OutlineVersion,
      },
      {
        path: "/admin/writer/outline-in-progress",
        name: "View Outlines",
        layout: "/",
        exact: true,
        component: WriterOutlineInProgress,
        permission: "OUTLINE_IN_PROCESS_WRITER",
        key: "outline",
      },
    ],
  },
  {
    collapse: true,
    name: "My Articles",
    icon: "icon-article",
    state: "articlemanagementCollapse",
    permission: "VIEW_WRITER_DASHBOARDS",
    views: [
      {
        path: "/admin/writer/writer-writing/:id",
        name: "Writer writing",
        invisible: true,
        layout: "/",
        exact: true,
        component: WriterWriting,
      },
      {
        path: "/admin/writer/article-in-progress",
        name: "Write Articles",
        layout: "/",
        exact: true,
        component: WriterArticleInProgress,
        permission: "ARTICLE_IN_PROCESS_WRITER",
        key: "article",
      },
      {
        path: "/admin/writer/approved-articles",
        name: "View Approved Articles",
        layout: "/",
        exact: true,
        component: WriterApprovedArticle,
        permission: "APPROVED_ARTICLE_WRITER",
        key: "approved",
      },
    ],
  },
  {
    collapse: true,
    name: "General Information",
    icon: "icon-book",
    state: "articlemanagementCollapse1",
    permission: "VIEW_WRITER_DASHBOARDS",
    views: [
      {
        path: "/admin/writer/editorial-guideline",
        name: "Editorial Guideline",
        layout: "/",
        exact: true,
        component: EditorialGuideline,
        permission: "VIEW_CONTENT_REQUEST",
        key: "editorial-guideline",
      },
      {
        path: "/admin/writer/faq",
        name: "FAQ",
        layout: "/",
        exact: true,
        component: Faq,
        permission: "VIEW_CONTENT_REQUEST",
        key: "faq",
      },
    ],
  },
  {
    collapse: true,
    name: "Employee",
    icon: "icon-employee",
    state: "eCollapse",
    permission: "VIEW_EMPLOYEE",
    views: [
      {
        path: "/admin/settings/employee/writer",
        name: "Writer",
        layout: "/",
        exact: true,
        component: Writer,
        permission: "VIEW_EMPLOYEE",
      },
      {
        path: "/admin/settings/employee/EditorAndChiefeditor",
        name: "Editor And Chief Of Editor",
        layout: "/",
        exact: true,
        component: EditorAndChiefeditor,
        permission: "VIEW_EMPLOYEE",
      },
    ],
  },
  {
    collapse: true,
    name: "Past Employee",
    icon: "icon-employee",
    state: "pastEmployeeCollapse",
    permission: "VIEW_EMPLOYEE",
    views: [
      {
        path: "/admin/settings/employee/writerPast",
        name: "Writer",
        layout: "/",
        exact: true,
        component: WriterPast,
        permission: "VIEW_EMPLOYEE",
      },
      {
        path: "/admin/settings/employee/EditorAndChiefeditorPast",
        name: "Editor And Chief Of Editor",
        layout: "/",
        exact: true,
        component: EditorAndChiefeditorPast,
        permission: "VIEW_EMPLOYEE",
      },
    ],
  },
  {
    name: "Task management",
    path: "/admin/task-management",
    layout: "/",
    icon: "ni ni-bullet-list-67 text-default",
  },
  {
    name: "Dashboards",
    icon: "icon-dashboard",
    state: "dashboardsCollapse",
    path: "/admin/editor/dashboard-editor",
    layout: "/",
    exact: true,
    component: DashboardEditor,
    permission: "VIEW_EDITOR_DASHBOARDS",
  },
  {
    collapse: true,
    name: "My Briefs",
    icon: "icon-brief",
    state: "briefCollapse",
    permission: "VIEW_EDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/editor/new-brief",
        name: "Add New Brief",
        layout: "/",
        exact: true,
        component: EditorAddNewPost,
        permission: "VIEW_LIST_BRIEF",
      },
      {
        name: "Edit Brief",
        path: "/admin/editor/edit-brief/:id",
        layout: "/",
        icon: "ni ni-bullet-list-67 text-default",
        component: EditorAddNewPost,
        invisible: true,
      },
      {
        path: "/admin/editor/content-version/:id",
        layout: "/",
        invisible: true,
        component: ContentVersion,
      },
      {
        path: "/admin/editor/all-briefs",
        name: "View All Briefs",
        layout: "/",
        exact: true,
        component: AllBriefs,
        permission: "VIEW_OUTLINE_APPROVAL",
        key: "all_brief",
      },
      {
        path: "/admin/editor/draft-briefs",
        name: "View Draft Briefs",
        layout: "/",
        exact: true,
        component: EditorDraftBriefs,
        permission: "VIEW_AVAILABLE_REQUEST",
        key: "article_draft",
      },
    ],
  },
  {
    collapse: true,
    name: "Market Place",
    icon: "icon-market",
    state: "marketCollapse",
    permission: "VIEW_EDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/editor/available-briefs",
        name: "View Available Briefs",
        layout: "/",
        exact: true,
        component: EditorAvailableRequest,
        permission: "VIEW_AVAILABLE_REQUEST",
        key: "available_briefs",
      },
      {
        path: "/admin/editor/assigned-briefs",
        name: "View Assigned Briefs",
        layout: "/",
        exact: true,
        component: EditorAssignedPost,
        permission: "VIEW_AVAILABLE_REQUEST",
        key: "assigned_briefs",
      },
      {
        path: "/admin/editor/reject-assigned",
        name: "View Rejected Briefs",
        layout: "/",
        exact: true,
        component: EditorRejectAssigned,
        permission: "VIEW_OUTLINE_APPROVAL",
        key: "reject_assigned",
      },
    ],
  },
  {
    collapse: true,
    name: "Pitches",
    icon: "icon-pitch",
    state: "pitchesCollapse",
    permission: "VIEW_EDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/editor/available-pitches",
        name: "View Available Pitches",
        layout: "/",
        exact: true,
        component: EditorAvailablePitches,
        permission: "VIEW_AVAILABLE_REQUEST",
        key: "available_pitches",
      },
      {
        path: "/admin/editor/pitch-detail/:id",
        name: "Pitch Detail",
        layout: "/",
        exact: true,
        component: EditorPitches,
        invisible: true,
        permission: "VIEW_AVAILABLE_REQUEST",
      },
    ],
  },
  {
    collapse: true,
    name: "Outlines",
    icon: "icon-book",
    state: "outlineCollapse",
    permission: "VIEW_EDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/editor/outline-in-progress",
        name: "Review Outlines",
        layout: "/",
        exact: true,
        component: OutlineInProgress,
        permission: "VIEW_OUTLINE_APPROVAL",
        key: "outline_inprocess",
      },
    ],
  },
  {
    collapse: true,
    name: "Articles",
    icon: "icon-article",
    state: "editorarticlemanagementCollapse",
    permission: "VIEW_EDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/editor/article-in-progress",
        name: "Review Articles",
        layout: "/",
        exact: true,
        component: ArticlesInProgress,
        permission: "VIEW_OUTLINE_APPROVAL",
        key: "article_inprocess",
      },
      {
        path: "/admin/editor/article-writing",
        name: "Add New Article",
        layout: "/",
        exact: true,
        component: EditorArticleWriting,
        permission: "VIEW_AVAILABLE_REQUEST",
      },
      {
        path: "/admin/editor/article-editing",
        name: "Edit Articles",
        layout: "/",
        exact: true,
        component: Editing,
        permission: "VIEW_OUTLINE_APPROVAL",
        key: "editing",
      },
      {
        path: "/admin/editor/writer-writing/:id",
        name: "Editor editing",
        invisible: true,
        layout: "/",
        exact: true,
        component: EditorEditingWriting,
      },
      {
        path: "/admin/editor/published-articles",
        name: "View Completed Articles",
        layout: "/",
        exact: true,
        component: EditorPublishedArticles,
        permission: "VIEW_OUTLINE_APPROVAL",
        key: "published_articles",
      },
      {
        path: "/admin/editor/published-article/:id",
        name: "Published Article Detail",
        invisible: true,
        layout: "/",
        exact: true,
        component: EditorPublishedArticle,
      },
    ],
  },
  {
    collapse: true,
    name: "Staff",
    icon: "icon-employee",
    state: "staffCollapse",
    permission: "VIEW_EDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/editor/writer-profile",
        name: "View Writer Profile",
        layout: "/",
        exact: true,
        component: EditorWriterProfile,
        permission: "VIEW_OUTLINE_APPROVAL",
        key: "writer_profile",
      },
      {
        path: "/admin/editor/writer-information/:id",
        name: "Writer Information",
        layout: "/",
        exact: true,
        invisible: true,
        component: EditorWriterInformation,
        permission: "VIEW_OUTLINE_APPROVAL",
      },
    ],
  },
  {
    collapse: true,
    name: "Applications",
    icon: "icon-employee",
    state: "applicationCollapse",
    permission: "VIEW_EDITOR_DASHBOARDS",
    views: [
      // List applications route
      {
        path: "/admin/editor/applications/pending",
        name: "Pending",
        layout: "/",
        exact: true,
        component: EditorApplicationManaging,
        permission: "VIEW_IN_PROGRESS",
      },
      {
        path: "/admin/editor/applications/processed",
        name: "Processed",
        layout: "/",
        exact: true,
        component: EditorApplicationManaging,
        permission: "VIEW_IN_PROGRESS",
      },
      // Application detail route
      {
        path: "/admin/editor/application-detail/:id",
        name: "Application Detail",
        layout: "/",
        exact: true,
        invisible: true,
        component: EditorApplicationDetail,
        permission: "APPROVE_CONTENT",
      },
    ],
  },
  {
    collapse: true,
    name: "General Information",
    icon: "icon-book",
    state: "articlemanagementCollapse1",
    permission: "VIEW_AVAILABLE_REQUEST",
    views: [
      {
        path: "/admin/editor/editorial-guideline",
        name: "Editorial Guideline",
        layout: "/",
        exact: true,
        component: EditorialGuideline,
        permission: "VIEW_AVAILABLE_REQUEST",
        key: "editorial-guideline",
      },
      {
        path: "/admin/editor/faq",
        name: "FAQ",
        layout: "/",
        exact: true,
        component: Faq,
        permission: "VIEW_AVAILABLE_REQUEST",
        key: "faq",
      },
    ],
  },
  {
    name: "Dashboards",
    icon: "icon-dashboard",
    state: "dashboardsCollapse",
    path: "/admin/chief/dashboard-chief",
    layout: "/",
    exact: true,
    component: DashboardChief,
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
  },
  {
    collapse: true,
    name: "My Briefs",
    icon: "icon-brief",
    state: "briefCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        name: "Add New Brief",
        path: "/admin/chief/new-brief",
        layout: "/",
        exact: true,
        component: ChiefAddNewPost,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
      },
      {
        path: "/admin/chief/content-version/:id",
        layout: "/",
        invisible: true,
        component: ContentVersion,
      },
      {
        name: "Edit Brief",
        path: "/admin/chief/new-brief/:id",
        layout: "/",
        icon: "ni ni-bullet-list-67 text-default",
        component: ChiefAddNewPost,
        invisible: true,
      },
      {
        name: "View Draft Briefs",
        path: "/admin/chief/draft-briefs",
        layout: "/",
        exact: true,
        component: ChiefDraftBriefs,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "draft_brief",
      },
    ],
  },
  {
    collapse: true,
    name: "Market Place",
    icon: "icon-market",
    state: "marketCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/chief/available-briefs",
        name: "View Available Briefs",
        layout: "/",
        exact: true,
        component: ChiefAvailableBrief,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "available_briefs",
      },
      {
        path: "/admin/chief/assigned-briefs",
        name: "View Assigned Briefs",
        layout: "/",
        exact: true,
        component: ChiefAssignedPost,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "assigned_briefs",
      },
      {
        path: "/admin/chief/reject-assigned",
        name: "View Rejected Briefs",
        layout: "/",
        exact: true,
        component: ChiefRejectAssigned,
        permission: "VIEW_PUBLISH_ARTICLE",
        key: "reject_assigned",
      },
    ],
  },
  {
    collapse: true,
    name: "Pitches",
    icon: "icon-pitch",
    state: "pitchCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/chief/available-pitches",
        name: "View Available Pitches",
        layout: "/",
        exact: true,
        component: ChiefAvailablePitch,
        permission: "READ_PITCH",
        key: "available_pitches",
      },
      {
        path: "/admin/chief/pitch-detail/:id",
        name: "Pitch Detail",
        layout: "/",
        exact: true,
        component: ChiefPitches,
        invisible: true,
        permission: "READ_PITCH",
      },
    ],
  },
  {
    collapse: true,
    name: "Outlines",
    icon: "icon-paper",
    state: "outlineCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/chief/outline-in-progress",
        name: "Outline - In Progress",
        layout: "/",
        exact: true,
        component: ChiefOutlineInProgress,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "outline_inprocess",
      },
    ],
  },
  {
    collapse: true,
    name: "Article",
    icon: "icon-article",
    state: "chiefarticlemanagementCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/chief/article-in-progress",
        name: "Article - In Progress",
        layout: "/",
        exact: true,
        component: ChiefArticleInProgress,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "article_inprocess",
      },
      {
        path: "/admin/chief/editing",
        name: "Edit Articles",
        layout: "/",
        exact: true,
        component: ChiefEditing,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "editing",
      },
      {
        path: "/admin/chief/publish-article",
        name: "View Publish Articles",
        layout: "/",
        exact: true,
        component: ChiefPublishArticle,
        permission: "VIEW_PUBLISH_ARTICLE",
        key: "publish_article",
      },
      {
        path: "/admin/chief/add-new-article",
        name: "Add New Article",
        layout: "/",
        exact: true,
        component: ChiefAddNewArticle,
        permission: "CREATE_ARTICLE",
        key: "add_new_article",
      },
      // {
      //   path: "/admin/chief/add-slides-article",
      //   name: "Add Slideshow Article",
      //   layout: "/",
      //   exact: true,
      //   component: AddSlideArticlePage,
      //   permission: "CREATE_ARTICLE",
      //   key: "add_new_article",
      // },
    ],
  },
  {
    name: "Vietcetera Open",
    icon: "ni ni-world-2",
    path: "/admin/chief/vietcetera-open",
    layout: "/",
    exact: true,
    component: VietceteraOpen,
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
  },
  {
    name: "Live update",
    icon: "icon-dashboard",
    path: "/admin/chief/live-update",
    layout: "/",
    exact: true,
    component: LiveUpdatePage,
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
  },
  {
    collapse: true,
    name: "Topic",
    icon: "icon-topic",
    state: "topicCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/chief/topic",
        name: "View All Topic",
        layout: "/",
        exact: true,
        component: ChiefTopic,
        permission: "VIEW_TOPIC",
        key: "topic",
      },
    ],
  },
  {
    collapse: true,
    name: "Tags",
    icon: "ni ni-tag",
    state: "tagCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/chief/official-tag-list",
        name: "Official Tag",
        layout: "/",
        exact: true,
        component: ChiefOfficialTag,
        permission: "APPROVE_CONTENT",
      },
      {
        path: "/admin/chief/suggested-tag-list",
        name: "Suggested Tag",
        layout: "/",
        exact: true,
        component: ChiefSuggestedTag,
        permission: "APPROVE_CONTENT",
      },
    ],
  },
  {
    collapse: true,
    name: "Staff",
    icon: "icon-employee",
    state: "staffCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/chief/writer-profile",
        name: "View Writer Profile",
        layout: "/",
        exact: true,
        component: ChiefWriterProfile,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "writer_profile",
      },
      {
        path: "/admin/chief/editor-profile",
        name: "View Editor Profile",
        layout: "/",
        exact: true,
        component: ChiefEditorProfile,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "editor_profile",
      },
    ],
  },
  {
    collapse: true,
    name: "Applications",
    icon: "icon-employee",
    state: "applicationCollapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      // List applications route
      {
        path: "/admin/chief/applications/pending",
        name: "Pending",
        layout: "/",
        exact: true,
        component: ChiefApplicationManaging,
        permission: "APPROVE_CONTENT",
      },
      {
        path: "/admin/chief/applications/processed",
        name: "Processed",
        layout: "/",
        exact: true,
        component: ChiefApplicationManaging,
        permission: "APPROVE_CONTENT",
      },
      // Application detail route
      {
        path: "/admin/chief/application-detail/:id",
        name: "Application Detail",
        layout: "/",
        exact: true,
        invisible: true,
        component: ChiefApplicationDetail,
        permission: "APPROVE_CONTENT",
      },
    ],
  },
  {
    collapse: true,
    name: "Readers",
    icon: "icon-readers",
    state: "readerCollapse",
    permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
    views: [
      {
        path: "/admin/chief/reader",
        name: "View User Profile",
        layout: "/",
        exact: true,
        component: ChiefReaders,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
      },
    ],
  },
  {
    collapse: true,
    name: "General Information",
    icon: "icon-book",
    state: "generalCollapse",
    permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
    views: [
      {
        path: "/admin/chief/editorial-guideline",
        name: "Editorial Guideline",
        layout: "/",
        exact: true,
        component: EditorialGuideline,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
      },
      {
        path: "/admin/chief/faq",
        name: "FAQ",
        layout: "/",
        exact: true,
        component: Faq,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
      },
    ],
  },
  {
    collapse: true,
    name: "Statistics",
    icon: "icon-statistic",
    state: "123Collapse",
    permission: "VIEW_CHIEFEDITOR_DASHBOARDS",
    views: [
      {
        path: "/admin/chief/statistics/overview",
        name: "Overview",
        layout: "/",
        exact: true,
        component: Dashbroad,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "overview",
      },
      {
        path: "/admin/chief/statistics/writer",
        name: "Writer",
        layout: "/",
        exact: true,
        component: ComingSoon,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "writer",
      },
      {
        path: "/admin/chief/statistics/article",
        name: "Article",
        layout: "/",
        exact: true,
        component: Article,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "article",
      },
      {
        path: "/admin/chief/statistics/readership",
        name: "Readership",
        layout: "/",
        exact: true,
        component: ComingSoon,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "readership",
      },
      {
        path: "/admin/chief/statistics/editor",
        name: "Editor",
        layout: "/",
        exact: true,
        component: ComingSoon,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
        key: "editor",
      },
    ],
  },
  {
    collapse: true,
    name: "Appearance Managing",
    icon: "icon-appearance",
    state: "appearanceCollapse",
    permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
    views: [
      {
        path: "/admin/chief/manage-main-menu",
        name: "Main Menu",
        layout: "/",
        exact: true,
        component: ChiefManageMainMenu,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
      },
      {
        path: "/admin/chief/manage-feature-articles",
        name: "Feature Articles",
        layout: "/",
        exact: true,
        component: ChiefFeatureArticles,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
      },
      {
        path: "/admin/chief/manage-collection",
        name: "Collection Managing",
        layout: "/",
        exact: true,
        component: ChiefCollectionManage,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
      },
      {
        path: "/admin/chief/manage-general-information",
        name: "General Information",
        layout: "/",
        exact: true,
        component: GeneralInformation,
        permission: "VIEW_TASK_CHIEFEDITOR_MANAGEMENT",
      },
    ],
  },
  {
    name: "OutLine Content",
    path: "/admin/writer/outline-content/:id",
    layout: "/",
    icon: "ni ni-bullet-list-67 text-default",
    component: WriterContentRequest,
    invisible: true,
  },
  {
    name: "Brief - Translate",
    path: "/admin/chief/brief-translate/:id",
    layout: "/",
    icon: "ni ni-bullet-list-67 text-default",
    component: ChiefBriefTranslate,
    invisible: true,
  },
  {
    path: "/admin/writer/view-detail-content-writer/:id",
    layout: "/",
    component: ViewContentWriterDetail,
    invisible: true,
  },
  {
    path: "/admin/editor/view-detail-content/:id",
    layout: "/",
    component: ViewContentWriterDetail,
    invisible: true,
  },
  {
    path: "/admin/chief/view-detail-content/:id",
    layout: "/",
    component: ViewContentWriterDetail,
    invisible: true,
  },
  {
    path: "/admin/chief/view-detail-content-editor/:id",
    layout: "/",
    component: ViewContentChiefDetail,
    invisible: true,
  },
  {
    path: "/admin/editor/content-request/:id",
    name: "Content Request",
    layout: "/",
    exact: true,
    component: EditorContentRequest,
    invisible: true,
  },
  {
    path: "/admin/editor/outline-content/:id",
    name: "Outline Content",
    layout: "/",
    exact: true,
    component: EditorOutlineContent,
    invisible: true,
  },
  {
    path: "/admin/chief/content-request/:id",
    layout: "/",
    exact: true,
    component: EditorContentRequest,
    invisible: true,
  },
  {
    path: "/admin/chief/outline-content/:id",
    layout: "/",
    exact: true,
    component: EditorOutlineContent,
    invisible: true,
  },
  {
    path: "/admin/chief/writer-writing/:id",
    layout: "/",
    exact: true,
    component: ChiefWriterWriting,
    invisible: true,
  },
  {
    path: "/admin/chief/publish-articles-review/:id",
    name: "Publish articles",
    layout: "/",
    exact: true,
    component: ChiefPublishReview,
    invisible: true,
  },
  {
    path: "/admin/chief/writer-profile-table-detail/:id",
    name: "Publish articles",
    layout: "/",
    exact: true,
    component: ChiefWriterProfileDetailTable,
    invisible: true,
  },
  {
    path: "/admin/chief/editor-profile-table-detail/:id",
    layout: "/",
    exact: true,
    component: ChiefEditorProfileDetailTable,
    invisible: true,
  },
  {
    path: "/admin/chief/un-published-review/:id",
    layout: "/",
    exact: true,
    component: ChiefUnPublishReview,
    invisible: true,
  },
  {
    path: "/admin/notification-writer",
    layout: "/",
    component: Notification,
    invisible: true,
  },
  {
    path: "/admin/notification-editor",
    layout: "/",
    component: Notification,
    invisible: true,
  },
  {
    path: "/admin/notification-chief",
    layout: "/",
    component: Notification,
    invisible: true,
  },
  {
    path: "/admin/notification",
    layout: "/",
    component: Notification,
    invisible: true,
  },
  {
    path: "/admin/notification-chief",
    layout: "/",
    component: Notification,
    invisible: true,
  },
  {
    path: "/admin/profile/my-profile",
    layout: "/",
    component: MyProfile,
  },
  {
    path: "/admin/settings/information/:id",
    layout: "/",
    component: InformationUpdate,
  },
  {
    path: "/admin/settings/information",
    layout: "/",
    component: InformationCreate,
  },
  {
    path: "/admin/updatePassword",
    layout: "/admin/updatePassword",
    component: UpdatePassword,
  },
  {
    path: "/admin/forgotPassword",
    layout: "/admin/forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/admin/ChangeNewPassword",
    layout: "/admin/ChangeNewPassword",
    component: ChangeNewPassword,
  },
  {
    name: "Login",
    path: "/admin",
    layout: "/admin/login",
    invisible: true,
    component: Login,
  },
];

export default routes;
