import React, { Component } from "react";
import {
  Container
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../components/Headers/CardsHeaderEditor";
import AllBriefsTable from "../../components/Tables/AllBriefsTable";
class Edited extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="All Briefs"
          content="all_briefs"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <AllBriefsTable {...props} />
        </Container>
      </>
    );
  }
}
Edited.propTypes = {
  content: PropTypes.string
};
export default Edited;
