import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  post_info: {
    data: {}
  },
  post_detail: {
    data: {}
  },
  topics_name: [],
  tags_by_topic: [],
  writer_list: [],
  editor_list: []
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_POST_INFO:
      state = {
        ...state,
        post_info: action.data
      };
      return { ...state };
    case Types.GET_TOPICS_NAME:
      console.log(action.data);
      var topics_name = action.data.data.topics;
      var topics_name_select = action.data.data.topics;
      topics_name_select.map(prop => {
        prop.label = prop.name;
        prop.value = prop.key;
        return prop;
      });
      state = {
        ...state,
        topics_name,
        topics_name_select
      };
      return { ...state };
    case Types.GET_TAGS_BY_TOPIC:
      state = {
        ...state,
        tags_by_topic: action.data
      };
      return { ...state };
    case Types.GET_POST_DETAIL:
      state = {
        ...state,
        post_detail: action.data
      };
      return { ...state };
    case Types.GET_WRITER_LIST:
      var writer_list = action.data.data.listWriter;
      writer_list.map(prop => {
        prop.label = prop.name;
        prop.value = prop.id;
        prop.icon = prop.avatar;
        return prop;
      });
      state = {
        ...state,
        writer_list
      };
      return { ...state };
    case Types.GET_EDITOR_LIST:
      var editor_list = action.data.data;
      editor_list.map(prop => {
        prop.label = prop.name;
        prop.value = prop.id;
        prop.icon = prop.avatar;
        return prop;
      });
      editor_list.unshift({
        label: "Select...",
        value: ""
      });
      state = {
        ...state,
        editor_list
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default postReducer;
