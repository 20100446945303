import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Modal, Button, Input } from "antd";
import { actRejectPitchRequest } from "../../actions/pitch.action";
// import "./styles.scss";

function PitchRejectModal({ visible, loading, handleOk, id, handleCancel }) {
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const { TextArea } = Input;

  // functions
  function handleChange(e) {
    setReason(e.target.value);
  }

  function handleRejectRequest() {
    const data = {
      status_pitch: "REJECTED",
      reasonOfReject: reason,
    };
    dispatch(actRejectPitchRequest(id, data));
    handleCancel();
  }

  return (
    <Modal
      className="pitch-reject-modal"
      visible={visible}
      title="Reject this pitch?"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          className="modal-cancel-btn"
          type="ghost"
          key="cancel"
          onClick={handleCancel}
        >
          No
        </Button>,
        <Button
          disabled={isEmpty(reason)}
          className="modal-submit-btn"
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleRejectRequest}
        >
          Reject
        </Button>,
      ]}
    >
      <div>
        <span className="d-block mb-2">
          <strong>Reject reason</strong>
        </span>
        <TextArea
          label="Reject Reason"
          value={reason}
          onChange={handleChange}
          rows={6}
        />
      </div>
    </Modal>
  );
}
PitchRejectModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  hideBody: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOnChange: PropTypes.func,
  pitch_detail: PropTypes.object,
  id: PropTypes.string,
};
export default PitchRejectModal;
