import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import {
  actAddGroupTopicRequest,
  actUpdateGroupTopicRequest,
  actDeleteGroupTopicRequest,
  actGetAllTopicRequest
} from "../../actions/topic.action";

class GroupTopicSettingModal extends Component {
  state = {
    name: "",
    listTopic: [],
    publish: true
  };

  componentDidMount() {
    this.setState({
      id: this.props.group_detail._id,
      name: this.props.group_detail.name,
      listTopic: this.props.group_detail.listTopic,
      publish: this.props.group_detail.visibility === "PUBLISHED"
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.group_detail !== this.props.group_detail) {
      this.setState({
        id: nextProps.group_detail._id,
        name: nextProps.group_detail.name,
        listTopic: nextProps.group_detail.listTopic,
        publish: nextProps.group_detail.visibility === "PUBLISHED"
      });
    }
  }

  _handleOnDelete = () => {
    const id = this.state.id;
    const group_detail = this.props.group_detail;
    const language = this.props.language;

    this.props.deleteGroupTopic(id, group_detail, { language });
    this.props.toggle();
  };

  _handleConfirm = () => {
    const { id, name, publish } = this.state;
    const group_detail = this.props.group_detail;
    const language = this.props.language;
    this.props.updateGroupTopic(
      id,
      {
        name,
        visibility: publish ? "PUBLISHED" : "UNPUBLISHED"
      },
      group_detail,
      { language }
    );
    this.props.toggle();
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className="modal-group-topic modal-form"
        >
          <Form onSubmit={this._handleConfirm}>
            <ModalHeader toggle={this.props.toggle}>Update Group</ModalHeader>
            <ModalBody className="py-0">
              <FormGroup>
                <Label>Current Group Name</Label>
                <Input
                  type="text"
                  name="current_name"
                  disabled
                  value={this.props.group_detail.name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>New Group Name</Label>
                <Input
                  type="text"
                  placeholder="Input Group Name"
                  name="name"
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Publish</Label>
                <label className="custom-toggle custom-toggle-success ml-2">
                  <input
                    checked={this.state.publish}
                    onChange={e => this.setState({ publish: e.target.checked })}
                    type="checkbox"
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="OFF"
                    data-label-on="ON"
                  />
                </label>
                <div className="note">
                  * Unpublish group will also unpublish all listTopic within
                  this group.
                </div>
                <div onClick={this._handleOnDelete} className="btn-delete">
                  Delete group
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-flex-end">
              <Button
                color="link"
                style={{ color: "#172B4D" }}
                onClick={this.props.toggle}
              >
                CANCEL
              </Button>
              <Button
                onClick={this._handleConfirm}
                color="link"
                type="submit"
                style={{ color: "#fb6340" }}
              >
                SAVE
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    // topic_list: state.topicReducer.modalTopic_list,
    group_detail: state.topicReducer.group_detail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllTopic: data => dispatch(actGetAllTopicRequest(data)),
    addGroupTopic: data => dispatch(actAddGroupTopicRequest(data)),
    updateGroupTopic: (id, data, group_detail, params) =>
      dispatch(actUpdateGroupTopicRequest(id, data, group_detail, params)),
    deleteGroupTopic: (id, group_detail, params) =>
      dispatch(actDeleteGroupTopicRequest(id, group_detail, params))
  };
};

GroupTopicSettingModal.propTypes = {
  topic_list: PropTypes.array,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  addGroupTopic: PropTypes.func,
  deleteGroupTopic: PropTypes.func,
  getAllTopic: PropTypes.func,
  group_detail: PropTypes.object,
  isUpdate: PropTypes.bool,
  updateGroupTopic: PropTypes.func,
  language: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupTopicSettingModal);
