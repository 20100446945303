import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  collections: [],
  collection_detail: {
    docs: [],
  },
  data_search: {},
  pinned_collections: [],
  collection_list: [],
  publishedCollection: [],
  loading: false,
};

const ChiefCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_CHIEF_COLLECTION:
      state = {
        ...state,
        collections: action.data.docs,
      };
      return { ...state };
    case Types.GET_CHIEF_DETAIL_COLLECTION:
      state = {
        ...state,
        collection_detail: action.data,
      };
      return { ...state };
    case Types.GET_SEARCH_ARTICLE_TO_COLLECTION:
      state = {
        ...state,
        data_search: action.data_search,
      };
      return { ...state };
    case Types.GET_PINNED_COLLECTION:
      state = {
        ...state,
        pinned_collections: action.data,
      };
      return { ...state };
    case Types.RESET_COLLECTION_LIST:
      state = {
        ...state,
        pinned_collections: [],
      };
      return { ...state };
    case Types.GET_COLLECTION_LIST:
      state = {
        ...state,
        collection_list: action.data,
      };
      return { ...state };
    // get published collection
    case Types.GET_PUBLISHED_COLLECTION:
      state = {
        ...state,
        loading: true,
      };
      return { ...state };
    case Types.GET_PUBLISHED_COLLECTION_SUCCESS:
      state = {
        ...state,
        publishedCollection: action.data,
        loading: false,
      };
      return { ...state };
    case Types.GET_PUBLISHED_COLLECTION_FAIL:
      state = {
        ...state,
        loading: false,
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default ChiefCollectionReducer;
