import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Input,
  Icon,
  Button,
  Col,
  Select,
  Row,
  Menu,
  Dropdown
} from "antd";
import { ReactComponent as IconFacebook } from "../../assets/img/icons/Facebook.svg";
import { ReactComponent as IconTwitter } from "../../assets/img/icons/Twitter.svg";
import { ReactComponent as IconLinkedin } from "../../assets/img/icons/linkedin.svg";
import { ReactComponent as IconInstagram } from "../../assets/img/icons/Instagram.svg";
import { ReactComponent as IconHome } from "../../assets/img/icons/home.svg";
import { ReactComponent as IconEmail } from "../../assets/img/icons/email.svg";
import { ReactComponent as Vector } from "../../assets/img/icons/Vector.svg";
import get from "lodash/get";
import _ from "lodash";
import isEqual from "lodash/isEqual";
let id = 0;

class AddSocial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: "",
      social: []
    };
  }
  remove = k => {
    const keys = this.state.social;
    this.setState({
      social: keys.filter(key => key.label !== k.label)
    });
    const social = this.props.form.getFieldValue("social");
    delete social[k.label];
    this.props.form.setFieldsValue({
      social: social
    });
  };

  add = key => {
    const keys = this.state.social;
    const nextKeys = keys.concat({
      label: key
    });

    let checkExist = keys.find(e => e.label === key);
    if (!checkExist) {
      this.setState({
        social: nextKeys
      });
    }
  };
  onClick = ({ key }) => {
    this.add(key);
  };
  componentWillReceiveProps(nextProps) {
    if (Object.keys(this.state.social).length === 0) {
      if (
        !isEqual(
          get(nextProps.employee_profile, "social"),
          get(this.props.employee_profile, "social")
        )
      ) {
        this.setState({
          social: Object.keys(nextProps.employee_profile.social).map(item => ({
            label: item
          }))
        });
      }
    }
  }
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const keys = this.state.social;
    const formItems = keys.map((item, index) => (
      <Col span={8} className="list-social-input">
        <Form.Item
          label={item.label.charAt(0).toUpperCase() + item.label.slice(1)}
          required={false}
          key={item.label}
        >
          {getFieldDecorator(`social.${item.label}`, {
            validateTrigger: ["onChange", "onBlur"],
            initialValue: get(
              this.props.employee_profile,
              `social.${item.label}`
            ),
            rules: [
              {
                required: true,
                message: `Please input ${item.label}`
              }
            ]
          })(
            <Input
              placeholder={`${item.label}`}
              style={{ width: "80%", marginRight: 1 }}
            />
          )}
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => this.remove(item)}
          />
        </Form.Item>
      </Col>
    ));
    const menu = () => {
      return (
        <Menu onClick={this.onClick} className="list-social">
          <Menu.Item key="facebook">
            <IconFacebook />
            Facebook
          </Menu.Item>
          <Menu.Item key="instagram">
            <IconInstagram />
            Instagram
          </Menu.Item>
          <Menu.Item key="twitter">
            <IconTwitter />
            Twitter
          </Menu.Item>
          <Menu.Item key="linkedin">
            <IconLinkedin />
            Linkedin
          </Menu.Item>

          <Menu.Item key="email">
            <IconEmail />
            Email
          </Menu.Item>
          <Menu.Item key="website">
            <IconHome />
            Website
          </Menu.Item>
        </Menu>
      );
    };
    return (
      <div style={{ marginBottom: "30px" }}>
        <Row>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
            >
              <Vector />
              Add New
            </Button>
          </Dropdown>
        </Row>
        <Row span={24} gutter={[0, 24]}>
          {formItems}
        </Row>
      </div>
    );
  }
}

AddSocial.propTypes = {
  form: PropTypes.object,
  employee_profile: PropTypes.object
};
export default memo(AddSocial);
