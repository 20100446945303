import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Table, Badge, Menu, Dropdown, Icon, Modal, Tooltip } from "antd";
import {
  actDeleteFeatureArticleTemp,
  actSetFeatureArticleForEdit,
} from "../../../actions/feature-articles.action";
import "./styles.scss";

const { confirm } = Modal;

function ListTable({ isPastDate }) {
  const dispatch = useDispatch();
  const featureArticleGroup = useSelector(
    (state) => state.featureArticleReducer.featureArticleGroup.data
  );
  const featureArticleGroupLoading = useSelector(
    (state) => state.featureArticleReducer.featureArticleGroup.loading
  );
  const isLoadingSchedule = useSelector(
    (state) => state.featureArticleReducer.featureArticleSchedule.loading
  );
  const featureArticleSchedule = useSelector(
    (state) => state.featureArticleReducer.featureArticleSchedule.setting
  );
  const columns = [
    { title: "Added article", dataIndex: "name", key: "name", width: 500 },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Feature on", dataIndex: "time", key: "time" },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: 100,
    },
  ];

  function warning(article) {
    confirm({
      title: `Delete this article from ${mapKeyToGroupName(article.group)}`,
      onOk() {
        handleDeleteFeatureArticleTemp(article);
      },
      onCancel() {},
    });
  }

  function mapKeyToGroupName(key) {
    if (!key) {
      return;
    }
    const filterArray = featureArticleGroup.filter((item) => item.key === key);
    if (filterArray && filterArray.length > 0) {
      return filterArray[0].name;
    }
  }

  const menu = (article) => {
    return (
      <Menu>
        {/* <Menu.Item onClick={() => toggleModalEdit(article)}>Edit</Menu.Item> */}
        <Menu.Item onClick={() => warning(article)} className="text-red">
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  function handleDeleteFeatureArticleTemp(article) {
    const { articleId, group, scheduleTime } = article;
    const data = {
      scheduleTime,
      articleId,
      group,
    };
    dispatch(actDeleteFeatureArticleTemp(data));
  }

  // function toggleModalEdit(article) {
  //   setEditMode(true);
  //   dispatch(actSetFeatureArticleForEdit(article));
  //   setModalVisible(true);
  // }

  // Map status to badge color
  const mapStatusToBadge = (status) => {
    const mapObj = {
      complete: "success",
      ending: "error",
      pending: "default",
    };
    return mapObj[status.toLowerCase()] || mapObj["pending"];
  };

  const mapArticleStatus = (status) => {
    const mapObj = {
      complete: "Featuring",
      ending: "End",
      default: "In queue",
    };
    return mapObj[status] || mapObj["default"];
  };

  const columnsTable = [
    {
      title: "",
      dataIndex: "angle_title",
      key: "angle_title",
      width: 500,
      ellipsis: true,
      render: function renderName(item) {
        return (
          <Tooltip title={item}>
            <span>
              <strong>{item}</strong>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: function renderStatus(item) {
        return (
          <span className={`article-status ${mapStatusToBadge(item)}`}>
            <Badge status={mapStatusToBadge(item)} />
            {mapArticleStatus(item)}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "scheduleTime",
      key: "scheduleTime",
      render: function renderTime(item) {
        if (!item) {
          return null;
        }
        return (
          <span className="schedule-time">
            {moment(item).format("HH:mm A")}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      width: 100,
      render: function renderAction(item) {
        if (isPastDate) return null;
        return (
          <Icon
            type="delete"
            onClick={() => warning(item)}
            theme="twoTone"
            twoToneColor="#f5222d"
          />
        );
      },
    },
  ];

  function sortByTime(a, b) {
    return moment(a).unix() - moment(b).unix();
  }

  const renderArticleTableByGroup = (record) => {
    const result = featureArticleSchedule.filter(
      (item) => item.key === record.key
    );
    const dataSource = result[0].articles || [];

    const sortedDataSource = dataSource
      .slice()
      .sort((a, b) => sortByTime(a.scheduleTime, b.scheduleTime));

    return (
      <Table
        className="table-list-article"
        dataSource={sortedDataSource}
        defaultExpandAllRows={true}
        columns={columnsTable}
        pagination={false}
        locale={{
          emptyText: "No article yet",
        }}
      />
    );
  };

  return (
    <Table
      className="table-group-article"
      columns={columns}
      loading={featureArticleGroupLoading || isLoadingSchedule}
      defaultExpandAllRows={true}
      expandedRowRender={(record) => renderArticleTableByGroup(record)}
      dataSource={featureArticleGroup}
      pagination={false}
    />
  );
}

export default ListTable;
