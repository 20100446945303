import React from "react";
import { Container } from "reactstrap";
import CardsHeaderChief from "../../components/Headers/CardsHeaderChief.jsx";
import LiveUpdateManagement from "../../components/LiveUpdate";
class LiveUpdatePage extends React.Component {
  render() {
    return (
      <>
        <CardsHeaderChief name="Covid Live Update" parentName="Live Update" />
        <Container className="mt--6" fluid>
          <LiveUpdateManagement />
        </Container>
      </>
    );
  }
}

export default LiveUpdatePage;
