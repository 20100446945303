import React, { Component } from "react";
import Select from "react-select";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Table, Tooltip, Typography, Tag, Input } from "antd";
import { Card, CardHeader, CardBody } from "reactstrap";
import ApplicationInformationModal from "../../Modals/ApplicationInformationModal";
import {
  actGetListApplicationRequest,
  actGetApplicationInformationRequest,
  actSearchApplicationRequest
} from "../../../actions/application.action";
import { actGetPostInfoRequestV2 } from "../../../actions/post.action";

import { makeUrl } from "../../../utils/makeUrl";
import { role } from "../../../utils/constants";
import {
  ARRAY_APPLICATION_STATUS_PROCESSED,
  APPLICATION_STATUS
} from "../../../utils/constants";
import get from 'lodash/get';
import "./styles.scss";

const { Paragraph } = Typography;
const { Search } = Input;

class ApplicationTable extends Component {
  state = {
    applicationModal: false,
    topic: [],
    selectTopic: {}
  };

  componentDidMount() {
    const { status } = this.props;
    const filterStatus =
      status === "pending"
        ? makeUrl("status", [APPLICATION_STATUS.NEW])
        : makeUrl("status", ARRAY_APPLICATION_STATUS_PROCESSED);
    const data = {
      page: 1,
      limit: 10,
      status: filterStatus
    };
    this.props.getPostInfo();
    this.props.getApplicationList(data);
  }

  showModal = applicantId => {
    document.body.classList.add("information-modal-open");
    this.setState(
      {
        applicationModal: true
      },
      () => {
        this.props.getApplicantInfo(applicantId);
      }
    );
  };

  componentWillUnmount() {
    document.body.classList.remove("information-modal-open");
  }

  hideModal = () => {
    this.setState({
      applicationModal: false
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.postInfo.data &&
      props.postInfo.data.topics &&
      props.postInfo.data.topics.length > 0 &&
      props.postInfo.data.topics !== state.topic
    ) {
      return {
        topic: props.postInfo.data.topics
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  handleChange = pagination => {
    const { status } = this.props;
    const filterStatus =
      status === "pending"
        ? makeUrl("status", [APPLICATION_STATUS.NEW])
        : makeUrl("status", ARRAY_APPLICATION_STATUS_PROCESSED);
    const data = {
      page: pagination.current,
      limit: pagination.pageSize,
      status: filterStatus
    };
    this.props.getApplicationList(data);
  };

  handleOnSelectTopicChange = async value => {
    const { status } = this.props;
    const filterStatus =
      status === "pending"
        ? makeUrl("status", [APPLICATION_STATUS.NEW])
        : makeUrl("status", ARRAY_APPLICATION_STATUS_PROCESSED);

    this.setState(
      {
        selectTopic: value
      },
      () => {
        if (!this.state.selectTopic) {
          const data = {
            page: 1,
            limit: 10,
            status: filterStatus
          };
          this.props.getApplicationList(data);
          return;
        }
        const data = {
          page: 1,
          limit: 10,
          status: filterStatus,
          topicKey: this.state.selectTopic.key
        };
        this.props.getApplicationList(data);
      }
    );
  };

  handleSearch = value => {
    const { status } = this.props;
    const filterStatus =
      status === "pending"
        ? [APPLICATION_STATUS.NEW]
        : ARRAY_APPLICATION_STATUS_PROCESSED;
    const data = {
      page: 1,
      limit: 10,
      title: value,
      status: filterStatus
    };
    this.props.searchApplication(data);
  };

  handleSearchChange = e => {
    if (!e.target.value) {
      const { status } = this.props;
      const filterStatus =
        status === "pending"
          ? makeUrl("status", [APPLICATION_STATUS.NEW])
          : makeUrl("status", ARRAY_APPLICATION_STATUS_PROCESSED);
      const data = {
        page: 1,
        limit: 10,
        status: filterStatus
      };
      this.props.getApplicationList(data);
    }
  };

  renderStatus = status => {
    if (status === APPLICATION_STATUS.NEW) {
      return <Tag color="blue">Pending</Tag>;
    }
    if (status === APPLICATION_STATUS.APPROVED) {
      return <Tag color="green">Approved</Tag>;
    }
    if (status === APPLICATION_STATUS.REJECTED) {
      return <Tag color="red">Rejected</Tag>;
    }
    return <Tag color="gold">Re-evaluated</Tag>;
  };

  render() {
    const { history, applications, total, isLoading } = this.props;
    const { applicationModal, topic } = this.state;
    const key = localStorage.getItem("key");
    const rolePath = key === role.CHIEFEDITOR ? "chief" : "editor";
    const columns = [
      {
        title: "Applicant",
        key: "writer",
        // eslint-disable-next-line react/display-name
        render: (item) => (
          <div>
            {get(item, 'writer._id') && (
              <a
                onClick={() => this.showModal(item.writer._id)}
                className="link"
              >
                {get(item, "writer.name") !== ''  ? item.writer.name : "No name"}
              </a>
            )}
          </div>
        ),
      },
      {
        title: "Article Title",
        key: "articleTitle",
        // eslint-disable-next-line react/display-name
        render: item => {
          return (
            <a
              onClick={() =>
                history.push(
                  `/admin/${rolePath}/application-detail/${item._id}`
                )
              }
              className="link"
            >
              {item.title}
            </a>
          );
        }
      },
      {
        title: "Prompt",
        key: "prompt",
        width: 200,
        render: item => (
          <Tooltip title={item.prompt}>
            <Paragraph className="mb-0" ellipsis={{ rows: 2 }}>
              {item.prompt}
            </Paragraph>
          </Tooltip>
        )
      },
      {
        title: "Topic",
        key: "topic",
        render: item => <span>{item.topic && item.topic[0].label}</span>
        // eslint-disable-next-line react/display-name
      },
      {
        title: "Status",
        key: "status",
        render: item => this.renderStatus(item.applicationStatus)
      },
      {
        title: "Submit on",
        key: "submitDate",
        sorter: (a, b) => {
          return moment(a.createdAt).unix() - moment(b.createdAt).unix();
        },
        sortDirections: ["descend", "ascend"],
        render: item => (
          <span>{moment(item.createdAt).format("DD-MM-YYYY")}</span>
        )
      }
    ];
    return (
      <div className="application-table">
        <Card>
          <CardHeader>
            <div className="action-section">
              <div className="action-section_search">
                <Search
                  loading={isLoading}
                  onChange={this.handleSearchChange}
                  placeholder="Enter to search article"
                  onSearch={value => this.handleSearch(value)}
                  style={{ width: 200 }}
                />
              </div>
              <div className="action-section_topic-select">
                <Select
                  isSearchable={true}
                  isClearable={true}
                  className="react-select"
                  classNamePrefix="react-select"
                  size="lg"
                  placeholder="All topic"
                  closeMenuOnSelect={true}
                  options={topic}
                  onChange={value => this.handleOnSelectTopicChange(value)}
                />
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              <Table
                columns={columns}
                loading={isLoading}
                dataSource={applications}
                onChange={this.handleChange}
                pagination={{ total: total, defaultCurrent: 1 }}
              />
            </div>
          </CardBody>
          <ApplicationInformationModal
            visible={applicationModal}
            handleOk={this.showModal}
            handleCancel={this.hideModal}
            title="Applicant’s Information"
          />
        </Card>
      </div>
    );
  }
}
ApplicationTable.propTypes = {
  history: PropTypes.object,
  applications: PropTypes.array,
  postInfo: PropTypes.object,
  status: PropTypes.string,
  total: PropTypes.number,
  push: PropTypes.func,
  isLoading: PropTypes.bool,
  getApplicationList: PropTypes.func,
  getApplicantInfo: PropTypes.func,
  getPostInfo: PropTypes.func,
  searchApplication: PropTypes.func
};
const mapStateToProps = state => {
  return {
    applications: state.applicationReducer.applications.docs,
    isLoading: state.applicationReducer.isLoading,
    total: state.applicationReducer.applications.total,
    postInfo: state.postReducer.post_info
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getApplicationList: params => {
      dispatch(actGetListApplicationRequest(params));
    },
    getApplicantInfo: id => {
      dispatch(actGetApplicationInformationRequest(id));
    },
    getPostInfo: data => {
      dispatch(actGetPostInfoRequestV2(data));
    },
    searchApplication: data => {
      dispatch(actSearchApplicationRequest(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationTable);
