import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  about_detail: {
    details: {
      AboutUS: ""
    }
  },
  recruitment_detail: {},
  company_detail: {}
};

const generalInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_ABOUT_DETAIL:
      state = {
        ...state,
        about_detail: action.data
      };
      return { ...state };
    case Types.GET_RECRUITMENT_DETAIL:
      state = {
        ...state,
        recruitment_detail: action.data
      };
      return { ...state };
    case Types.GET_COMPANY_DETAIL:
      state = {
        ...state,
        company_detail: action.data
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default generalInfoReducer;
