import React, { Component } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormGroup
  // Table,
  // CardFooter
} from "reactstrap";
import CustomPagination from "../Pagination/Pagination";
import CardBodyWithLoading from "../../components/CardBodyWithLoading/CardBody";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  actGetContentIDRequest,
  actApplyOutlineRequest,
  actGetMyAssignedPostRequest,
  actGetTopicListRequest
} from "../../actions/writerArticle.action";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import RejectAssgnedPostModal from "../Modals/RejectAssgnedPostModal";
import DrawerComponent from "../Drawer/Drawer";
import MultiSelect from "../MultiSelect/MultiSelect";
import { Button } from "antd";
import ReactHtmlParser from "react-html-parser";
import ConfirmModal from "../../components/Modals/ApplicationDetail/Confirm";

class WriterAssignedPostTable extends Component {
  state = {
    currentId: "",
    confirmModal: false,
    isViewDetail: false,
    page: 1,
    limit: 10,
    type: "",
    topic: "",
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      search: getUrlParam()["search"] ? getUrlParam()["search"] : "",
      type: getUrlParam()[""] ? getUrlParam()[""] : "",
      topic: getUrlParam()[""] ? getUrlParam()[""] : ""
    },
    isReject: false,
    id: "",
    listTypeArticle: [
      {
        name: "Report",
        key: "report"
      },
      {
        name: "Thought / Opinion",
        key: "thought-opinion"
      },
      {
        name: "Guide",
        key: "guide"
      },
      {
        name: "Profiling",
        key: "profiling"
      },
      {
        name: "Listicle",
        key: "listicle"
      }
    ]
  };

  componentDidMount() {
    this._setLoading();
    this._getMyAssignedPost();
    this.props.getTopicList();
  }

  _getMyAssignedPost() {
    const params = this.state.params;
    this.props.getMyAssignedPost(params);
  }

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getMyAssignedPost(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _toggleModal = () => {
    this.setState(prevState => ({
      isViewDetail: !prevState.isViewDetail
    }));
  };

  _toggleModalReject = () => {
    this.setState(prevStateReject => ({
      isReject: !prevStateReject.isReject
    }));
  };

  _handleViewDetailContent = id => {
    this.props.getContentByID(id);
    this._toggleModal();
  };

  _applyToContent = id => {
    this.props.applyToContent(id);
    this.hideConfirmModal();
  };

  _rejectAssignedPost = id => {
    console.log(id);
    this.setState({ id });
    this._toggleModalReject();
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 80) {
      return `${str[0].substring(0, 80)}...`;
    } else {
      return str[0].substring(0, 80);
    }
  };

  _handleOnSearch = (label, value) => {
    const state = this.state;
    const params = {
      ...state.params,
      [label]: value
    };
    this.setState({
      ...state.params,
      params
    });
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getMyAssignedPost(params);
  };

  concatTypeString = arr => {
    let str = "";
    arr.foreach(element => {
      str += "&type_article[]=" + element.key;
    });
    return str;
  };

  concatTopicString = arr => {
    let str = "";
    arr.foreach(element => {
      str += "&topic[]=" + element.key;
    });
    return str;
  };

  onTopicSelect = list => {
    const temp = this.concatTopicString(list);
    const state = this.state;
    const params = {
      ...state.params,
      topic: temp
    };
    this.setState({
      ...state.params,
      params
    });
    this._setLoading();
    this.props.getMyAssignedPost(params);
  };

  onTypeSelect = list => {
    const temp = this.concatTypeString(list);
    const state = this.state;
    const params = {
      ...state.params,
      type: temp
    };
    this.setState({
      ...state.params,
      params
    });
    this._setLoading();
    this.props.getMyAssignedPost(params);
  };

  onSelectTypeAll = list => {
    const temp = this.concatTypeString(list);
    const state = this.state;
    const params = {
      ...state.params,
      type: temp
    };
    this.setState({
      ...state.params,
      params
    });
    this._setLoading();
    this.props.getMyAssignedPost(params);
  };

  onSelectTopicAll = list => {
    const temp = this.concatTopicString(list);
    const state = this.state;
    const params = {
      ...state.params,
      topic: temp
    };
    this.setState({
      ...state.params,
      params
    });
    this._setLoading();
    this.props.getMyAssignedPost(params);
  };

  showConfirm = id => {
    this.setState({
      confirmModal: true,
      currentId: id
    });
  };
  hideConfirmModal = () => {
    this.setState({
      confirmModal: false
    });
  };
  render() {
    const { my_assigned_list, list_topic } = this.props;
    const page = my_assigned_list.data && my_assigned_list.data.page;
    const totalPages =
      my_assigned_list.data && my_assigned_list.data.totalPages;
    const totalDocs = my_assigned_list.data && my_assigned_list.data.totalDocs;
    const { listcontent_byid } = this.props;
    return (
      <>
        <DrawerComponent
          modal={this.state.isViewDetail}
          toggle={this._toggleModal}
          detailContent={listcontent_byid}
        />
        <RejectAssgnedPostModal
          modalReject={this.state.isReject}
          toggleReject={this._toggleModalReject}
          id={this.state.id}
          params={this.state.params}
        />
        <Row className="view-brief">
          <div className="col">
            <Card>
              <CardHeader className="card-information">
                <Col xs="12">
                  <h3 className="mb-0">View Assigned Briefs</h3>
                </Col>
              </CardHeader>
              <Row className="card-search">
                <Col lg="5" md="6" xs="12">
                  <div className="input-group search-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-search-input" />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control search"
                      placeholder="Tìm kiếm bằng từ khóa"
                      onChange={e =>
                        this._handleOnSearch("search", e.target.value)
                      }
                      value={this.state.params.search}
                    />
                  </div>
                </Col>
                <Col lg="7" md="6" xs="12">
                  <Row className="brief_filter">
                    <Col lg="6" md="12" xs="12" className="multi-select">
                      <h4>Topics:</h4>
                      <MultiSelect
                        list={list_topic}
                        title="All"
                        onSelect={this.onTopicSelect}
                        onSelectAll={this.onSelectTopicAll}
                      />
                    </Col>
                    <Col lg="6" md="12" xs="12" className="multi-select">
                      <h4>Type of article:</h4>
                      <MultiSelect
                        list={this.state.listTypeArticle}
                        title="All"
                        onSelect={this.onTypeSelect}
                        onSelectAll={this.onSelectTypeAll}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {my_assigned_list.data.docs.length === 0 ? (
                <Card>
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                </Card>
              ) : (
                <Col xl="12">
                  <CardBodyWithLoading
                    isLoading={this.state.isLoading}
                    className="p-0"
                  >
                    {my_assigned_list &&
                      my_assigned_list.data.docs.map((prop, key) => {
                        return (
                          <>
                            <Card className="card-brief">
                              <CardBody>
                                <ListGroup className="list my--3" flush>
                                  <ListGroupItem>
                                    <Row>
                                      <Col lg="8" md="6" className="brief-left">
                                        <FormGroup>
                                          {prop
                                            ? prop &&
                                              prop.type_of_article.map(type => {
                                                return (
                                                  // eslint-disable-next-line react/jsx-key
                                                  <h6>
                                                    {type.name.toUpperCase()}
                                                  </h6>
                                                );
                                              })
                                            : ""}
                                          <h5 className="h3 mb-0">
                                            {this.limitTitle(
                                              ReactHtmlParser(prop.angle_title)
                                            )}
                                          </h5>
                                        </FormGroup>
                                        <FormGroup className="topic-day">
                                          {prop
                                            ? prop &&
                                              prop.topic.map(topic => {
                                                return (
                                                  <>
                                                    <div>
                                                      <label>
                                                        {this._text_truncate(
                                                          topic.name
                                                            ? topic.name
                                                            : topic.label,
                                                          20,
                                                          "..."
                                                        )}
                                                      </label>
                                                    </div>
                                                  </>
                                                );
                                              })
                                            : ""}
                                          <li>
                                            Outline's Deadline:{" "}
                                            <strong>
                                              {moment(prop.dead_line).format(
                                                "MMMM Do YYYY"
                                              )}
                                            </strong>
                                          </li>
                                        </FormGroup>
                                      </Col>
                                      <Col
                                        lg="4"
                                        md="6"
                                        className="brief-right"
                                      >
                                        <Button
                                          className="reject"
                                          // style={{ height: "34px" }}
                                          onClick={() =>
                                            this._rejectAssignedPost(prop._id)
                                          }
                                        >
                                          Reject
                                        </Button>
                                        <Button
                                          className="view"
                                          onClick={() =>
                                            this._handleViewDetailContent(
                                              prop._id
                                            )
                                          }
                                        >
                                          View Detail
                                        </Button>
                                        <Button
                                          className="add"
                                          type="button"
                                          onClick={() =>
                                            this.showConfirm(prop._id)
                                          }
                                        >
                                          Apply
                                        </Button>
                                      </Col>
                                    </Row>
                                  </ListGroupItem>
                                </ListGroup>
                              </CardBody>
                            </Card>
                          </>
                        );
                      })}
                  </CardBodyWithLoading>
                  <div className="table-responsive">
                    <div className="mb-5">
                      <div className="float-right">
                        <CustomPagination
                          page={page}
                          totalPages={totalPages}
                          getListByPage={this._handleOnPageClick}
                        />
                      </div>
                      <div className="float-left font-italic">
                        ({totalDocs} results in {totalPages} pages)
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Card>
          </div>
          <ConfirmModal
            closable={false}
            visible={this.state.confirmModal}
            handleOk={() => this._applyToContent(this.state.currentId)}
            handleCancel={this.hideConfirmModal}
            title="Are you sure to apply this brief?"
          />
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    my_assigned_list: state.writerArticleReducer.my_assigned_list,
    listcontent_byid: state.writerArticleReducer.listcontent_byid,
    skip_outline: state.writerArticleReducer.skip_outline,
    list_topic: state.writerArticleReducer.list_topic
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getMyAssignedPost: (data, params) => {
      dispatch(actGetMyAssignedPostRequest(data, params));
    },
    getContentByID: id => {
      dispatch(actGetContentIDRequest(id));
    },
    applyToContent: id => {
      dispatch(actApplyOutlineRequest(id));
    },
    getTopicList: () => {
      dispatch(actGetTopicListRequest());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriterAssignedPostTable);
WriterAssignedPostTable.propTypes = {
  list: PropTypes.array,
  my_assigned_list: PropTypes.object,
  listcontent_byid: PropTypes.object,
  list_topic: PropTypes.array,
  skip_outline: PropTypes.bool,
  getMyAssignedPost: PropTypes.func,
  getContentByID: PropTypes.func,
  applyToContent: PropTypes.func,
  getTopicList: PropTypes.func,
  rejectAssignedPost: PropTypes.func
};
WriterAssignedPostTable.defaultProps = {};
