import React, { Component } from "react";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { isWebUri } from "valid-url";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  Input,
  Modal,
} from "reactstrap";

import { Tooltip } from "antd";
import PropTypes from "prop-types";
import {
  actSubmitUnPublishedRequest,
  actSubmitPublishRequest,
  actAutoSaveWritingContentRequest,
  actSaveWritingContentRequest,
  actChiefAddNewArticle,
} from "../../actions/chiefArticle.action";
import CustomEditor from "../TinyMCE";
import NewArticleSetting from "../PostSetting/NewArticleSetting";
import NewPollManager from "../Poll/NewPollForm";
import { ARTICLE_STATUS } from "../../utils/constants";
import TagValidation from "../TagValidation/index";
import "./styles.scss";
class ChiefAddNewArticleDetail extends Component {
  state = {
    publishConfirmModal: false,
    collapse: false,
    uid: null,
    openedCollapses: ["collapseOne"],
    title: "",
    titleWarn: false,
    data: {
      content: "",
    },
    status: "",
    poll: {},
    setting: {
      image: "",
      brandedLogoId: "",
      brandedLogoAlt: "",
      brandedLogoLink: "",
      seoDescription: "",
      slug: "",
      author: [],
      topic: [],
      tags: [],
      otherLanguageLink: "",
      publishDate: "",
      excerpt: "",
      language: "",
      featureImageCaption: "",
      featureImage: "",
      audio: "",
      summaries: [],
      featureImageType: {
        value: "landscape",
        label: "Landscape",
      },
      typeOfContent: {
        value: "original",
        label: "Original",
      },
      label: {
        value: "",
        label: "No badge",
      },
    },
    invalidAudio: "",
    errors: {
      seo: {
        description: "",
      },
    },

    autoSave: false,
  };

  componentDidMount() {
    this._autoSave();
    this._getNewArticleDataFromLocal();
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
  }

  async _getNewArticleDataFromLocal() {
    const newArticleData = localStorage.getItem("newArticleData");
    if (newArticleData) {
      let parseData = await JSON.parse(newArticleData);
      this.setState({
        title: parseData.title,
        data: {
          content: parseData.content,
        },
      });
    }
  }

  _toggleModal = (modalName) => {
    // if (!this.state.seo.description) {
    //   this.setState({
    //     errors: {
    //       seo: {
    //         description: "This field is required"
    //       }
    //     }
    //   });
    //   return;
    // }
    this.setState({
      [modalName]: !this.state[modalName],
    });
  };
  _wrapInTag = (content) => {
    const result = `<p>${content}</p>`;
    return result;
  };
  // Create new article
  _handleAddNewArticle = () => {
    // if (!this.state.seo.description) {
    //   this.setState({
    //     errors: {
    //       seo: {
    //         description: "This field is required",
    //       },
    //     },
    //   });
    //   return;
    // }
    if (this.state.audioUrl == !"" && !isWebUri(this.state.setting.audio)) {
      this.setState({
        invalidAudio: "Please input a valid audio URL",
      });
      return;
    }
    const {
      title,
      data: { content },
      setting,
      poll,
    } = this.state;
    let payload = {
      title: title,
      content: content,
      status: ARTICLE_STATUS.COMPLETED,
      slug: setting.slug,
      tags: setting.tags,
      topic: setting.topic,
      excerpt: this._wrapInTag(setting.excerpt),
      language: setting.language.value,
      image: setting.image,
      otherLanguageLink: setting.otherLanguageLink,
      featureImageCaption: setting.featureImageCaption,
      featureImage: setting.featureImage,
      brandedLogoAlt: setting.brandedLogoAlt,
      brandedLogoId: setting.brandedLogoId,
      brandedLogoLink: setting.brandedLogoLink,
      featureImageType: setting.featureImageType.value || "landscape",
      thumbnailImageId: setting.thumbnailImageId,
      publishDate: setting.publishDate || "",
      seo: {
        description: setting.seoDescription,
      },
      audioUrl: setting.audio,
      typeOfContent: setting.typeOfContent.value,
      creatorId:
        setting.writer && setting.writer.length > 0
          ? setting.writer[0].key
          : "",
      summaries: setting.summaries,
      poll: poll,
      label: setting.label.value,
      collectionId: setting.series && setting.series.value,
    };
    payload = omitBy(payload, isNil);
    payload = omitBy(payload, isEmpty);
    if (TagValidation(payload.tags)) {
      return;
    }
    if (isEmpty(setting.publishDate)) {
      payload.publishDate = "";
    }
    if (
      setting.series &&
      setting.series.value &&
      setting.series.value === "no-series"
    ) {
      payload.collectionId = "";
    }
    this.props.addNewArticle(payload);
    localStorage.removeItem("newArticleData");
  };
  // Create new article & publish right away
  _handleSubmitPublished = () => {
    const {
      title,
      data: { content },
      setting,
      poll,
    } = this.state;
    let payload = {
      title: title,
      content: content,
      status: ARTICLE_STATUS.PUBLISHED,
      slug: setting.slug,
      tags: setting.tags,
      topic: setting.topic,
      excerpt: this._wrapInTag(setting.excerpt),
      language: setting.language.value,
      image: setting.image,
      otherLanguageLink: setting.otherLanguageLink,
      featureImageCaption: setting.featureImageCaption,
      featureImage: setting.featureImage,
      brandedLogoAlt: setting.brandedLogoAlt,
      brandedLogoLink: setting.brandedLogoLink,
      brandedLogoId: setting.brandedLogoId,
      featureImageType: setting.featureImageType.value,
      thumbnailImageId: setting.thumbnailImageId,
      publishDate: setting.publishDate || "",
      seo: {
        description: setting.seoDescription,
      },
      audioUrl: setting.audio,
      typeOfContent: setting.typeOfContent.value,
      creatorId:
        setting.writer && setting.writer.length > 0
          ? setting.writer[0].key
          : "",
      summaries: setting.summaries,
      poll: poll,
      label: setting.label.value,
      collectionId: setting.series && setting.series.value,
    };
    payload = omitBy(payload, isNil);
    payload = omitBy(payload, isEmpty);
    if (isEmpty(setting.publishDate)) {
      payload.publishDate = "";
    }
    if (
      setting.series &&
      setting.series.value &&
      setting.series.value === "no-series"
    ) {
      payload.collectionId = "";
    }
    this.props.addNewArticle(payload);
    this._toggleModal("publishConfirmModal");
    localStorage.removeItem("newArticleData");
  };

  _handleSEOChange = (seo) => {
    this.setState({
      errors: {
        seo: {
          description: "",
        },
      },
      seo: seo,
      autoSave: true,
    });
  };

  _handlePollChange = (poll) => {
    this.setState({
      poll: poll,
      autoSave: true,
    });
  };

  _handleArticleSettingChange = (setting, invalidAudio) => {
    this.setState({
      setting: setting,
      autoSave: true,
      invalidAudio: invalidAudio,
    });
  };

  _handleOnContentChange = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        content: value,
      },
      autoSave: true,
    });
  };

  _handleOnClickCollapse = () => {
    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  _autoSave = () => {
    this.autoSave = setInterval(() => {
      if (this.state.autoSave) {
        const {
          data: { content },
          title,
        } = this.state;
        const data = {
          content: content,
          title: title,
        };
        localStorage.setItem("newArticleData", JSON.stringify(data));
        this.setState({ autoSave: false });
      }
    }, 3000);
  };

  _handleChange = (event) => {
    this.setState({
      title: event.target.value,
      titleWarn: event.target.value.length > 60,
    });
  };

  render() {
    const {
      setting,
      title,
      invalidAudio,
      titleWarn,
      data: { content },
      poll,
    } = this.state;
    return (
      <>
        <Row>
          <Col md="8">
            <Card style={{ marginTop: "19px" }}>
              <CardHeader
                className="border-0 d-flex justify-content-between align-items-center"
                role="tab"
              >
                <div className="d-flex w-100 flex-column">
                  <Input
                    placeholder="Title"
                    className={
                      `chief-add-title-input ` + (titleWarn ? "warn" : "")
                    }
                    value={title}
                    onChange={this._handleChange}
                  />
                  <div
                    className={`d-flex ${
                      titleWarn
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {titleWarn && (
                      <small className="text-red">
                        Title ideally shouldn't be longer than 60 characters,
                        but you can still sumbit
                      </small>
                    )}
                    <small className="text-right">
                      {title ? title.length : "0"}/60
                    </small>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <CustomEditor
                  enableComment={false}
                  content={content}
                  onChangeContent={this._handleOnContentChange}
                />
              </CardBody>
            </Card>
            {/* <NewArticleSEOManager
              errors={this.state.errors}
              onChangeSEO={this._handleSEOChange}
              seo={seo}
            />
            {/* Add new poll section */}
            <Card className="poll-manager">
              <CardHeader className="d-flex justify-content-between pb-0">
                <h3>Poll</h3>
              </CardHeader>
              <CardBody>
                <NewPollManager
                  onChangePoll={this._handlePollChange}
                  poll={poll}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <div className="mt--5 d-flex justify-content-end">
              <Button
                onClick={this._handleAddNewArticle}
                color="primary"
                size="lg"
                type="button"
              >
                Create
              </Button>
              <Button
                type="button"
                size="lg"
                color="success"
                className="btn-success btn-lg"
                onClick={() => this._toggleModal("publishConfirmModal")}
              >
                Create and publish
              </Button>
            </div>
            <NewArticleSetting
              invalidAudio={invalidAudio}
              onChangeSetting={this._handleArticleSettingChange}
              setting={setting}
            />
          </Col>
        </Row>
        <div
          className="scrollTop"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "35px",
            zIndex: "1",
          }}
        >
          <Tooltip placement="topLeft" title={"Scroll to top"}>
            <Button
              className="btn-icon-only rounded-circle"
              type="button"
              color="primary"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-caret-up"></i>
              </span>
            </Button>
          </Tooltip>
        </div>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.publishConfirmModal}
          toggle={() => this._toggleModal("publishConfirmModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Are you sure to create and publish this article?
            </h5>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => this._toggleModal("publishConfirmModal")}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={this._handleSubmitPublished}
            >
              Yes
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    content_detail: state.chiefArticleReducer.unpublished_detail_by_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submitPublished: (id, data) => {
      dispatch(actSubmitPublishRequest(id, data));
    },
    submitUnPublished: (id, data) => {
      dispatch(actSubmitUnPublishedRequest(id, data));
    },
    saveDraft: (data) => {
      dispatch(actSaveWritingContentRequest(data));
    },
    autoSave: (data) => {
      dispatch(actAutoSaveWritingContentRequest(data));
    },
    addNewArticle: (data) => {
      dispatch(actChiefAddNewArticle(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefAddNewArticleDetail);
ChiefAddNewArticleDetail.propTypes = {
  content_detail: PropTypes.object,
  getUnPublisedByID: PropTypes.func,
  submitUnPublished: PropTypes.func,
  submitPublished: PropTypes.func,
  saveDraft: PropTypes.func,
  autoSave: PropTypes.func,
};
