import React from "react";
import { Pie } from "react-chartjs-2";

const data1 = {
  labels: ["Expert", "Writer", "Contributor", "Editor"],
  datasets: [
    {
      data: [300, 50, 100, 10],
      backgroundColor: ["#2DCE89", "#172B4D", "#5E72E4", "#7D8BA4"],
      hoverBackgroundColor: ["#2DCE89", "#172B4D", "#5E72E4", "#7D8BA4"],
    },
  ],
};

const PieReport = function({ data }) {
  return (
    <Pie
      data={data}
      options={{
        cutoutPercentage: 80,
      }}
    />
  );
};

export default PieReport;
