import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
import ApplicationTable from "../../../components/Tables/ApplicationTable/ApplicationTable";

function ChiefApplicationDetail(props) {
  const location = useLocation();
  const lastPath = location.pathname.split("/");
  const status = lastPath[lastPath.length - 1];
  return (
    <>
      <CardsHeaderchief
        name="Application Management"
        content="Application management"
        parentName="Staff"
        isDetail={true}
      />
      <Container className="mt--6" fluid>
        <ApplicationTable status={status} {...props} />
      </Container>
    </>
  );
}
ChiefApplicationDetail.propTypes = {
  content: PropTypes.string
};
export default ChiefApplicationDetail;
