import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Row,
  Col
} from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dropzone from "dropzone";
import TagsInput from "react-tagsinput";
import Select from "react-select";
import HOST from "../../variables/host";
import HOST_URL from "../../variables/hostUrl";

import {
  actAddTopicRequest,
  actGetTopicDetailRequest,
  actUpdateTopicRequest,
  actDeleteTopicRequest
} from "../../actions/topic.action";
import { languageOption } from "../../variables/selectOptions";
import renderDropzoneThumbnail from "../../utils/renderDropzoneThumbnail";
import * as authenStorage from "../../utils/authen-storage";

class ChiefTopicModal extends Component {
  state = {
    tags: [],
    groupTopicSelect: null,
    image: "",
    imageId: "",
    publish: false,
    language: { value: "VN", label: "Tiếng Việt" },
    pinMenu: false
  };

  componentDidMount() {
    this.dropzone();
    if (this.props.isUpdate) {
      this.props.getTopicDetailRequest(this.props.id);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.topic_detail !== this.props.topic_detail) {
      nextProps.topic_detail.images &&
        renderDropzoneThumbnail(0, nextProps.topic_detail.images.url);
      this.setState({
        name: nextProps.topic_detail.name,
        tags: nextProps.topic_detail.tags,
        image: nextProps.topic_detail.images
          ? nextProps.topic_detail.images.url
          : "",
        publish: nextProps.topic_detail.visibility === "PUBLISHED",
        language: languageOption.find(
          prop => prop.value === nextProps.topic_detail.language
        ),
        pinMenu: nextProps.topic_detail.pinMenu
      });
    }
  }

  dropzone = () => {
    const seft = this;
    let currentSingleFile = undefined;
    new Dropzone(document.getElementById("dropzone-single"), {
      url: HOST + "/images/upload",
      headers: { accessToken: authenStorage.getToken() },
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function() {
        this.on("addedfile", function(file) {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      },
      success: (file, res) => {
        seft.setState({ imageId: res.data._id });
      }
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
  };

  toggle = () => {
    this.props.toggle();
    if (this.state.message) {
      this.setState({
        message: ""
      });
    }
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  _handleOnSave = e => {
    e.preventDefault();
    const {
      name,
      tags,
      groupTopicSelect,
      publish,
      pinMenu,
      imageId
    } = this.state;
    const { language, sort } = this.props;
    const id = this.props.id;
    var group_detail = this.props.group_topic_list.find(
      prop => prop === groupTopicSelect
    );
    if (!name) {
      this.setState({
        message: "Please input topic name before submit!"
      });
    } else {
      group_detail = this.props.group_detail;

      if (this.props.isUpdate) {
        this.props.toggle();
        this.props.updateTopic(
          id,
          {
            name,
            tags,
            image: imageId,
            visibility: publish ? "PUBLISHED" : "UNPUBLISHED",
            pinMenu
          },
          group_detail,
          { language, sort }
        );
      } else {
        if (!groupTopicSelect) {
          this.setState({
            message: "Please choose groupTopic "
          });
          return;
        }
        if (!imageId) {
          this.setState({
            message: "Please upload Thumbnail"
          });
          return;
        }
        this.props.toggle();
        this.props.addTopic(
          [
            {
              name,
              tags,
              language: this.state.language.label === "English" ? "EN" : "VN",
              image: imageId,
              groupTopic: groupTopicSelect ? groupTopicSelect.value : "",
              visibility: publish ? "PUBLISHED" : "UNPUBLISHED",
              pinMenu
            }
          ],
          group_detail,
          { language, sort }
        );
      }
    }
  };

  _handleOnDelete = () => {
    const id = this.props.id;
    const group_detail = this.props.group_detail;
    const language = this.props.language;
    this.props.deleteTopic(id, group_detail, { language });
    this.props.toggle();
  };

  render() {
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="modal-form modal-topic"
        >
          <Form onSubmit={this._handleOnSave}>
            <ModalHeader toggle={this.toggle}>
              {this.props.isUpdate ? "Update Topic" : "Add New Topic"}
            </ModalHeader>
            <ModalBody className="py-0">
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>Topic's Name</label>
                    <Input
                      id="name"
                      name="name"
                      value={this.state.name}
                      type="text"
                      className="d-inline mr-3"
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <label>Thumbnail</label>
                  <div
                    className="dropzone dropzone-single mb-3"
                    id="dropzone-single"
                  >
                    <div className="fallback">
                      <div className="custom-file">
                        <input
                          className="custom-file-input"
                          id="projectCoverUploads"
                          type="file"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="projectCoverUploads"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                    <div className="dz-preview dz-preview-single">
                      <div className="dz-preview-cover">
                        <img
                          src={`${HOST_URL}${this.state.image.url}`}
                          alt="..."
                          className="dz-preview-img"
                          data-dz-thumbnail=""
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <label>Tags</label>
                  <TagsInput
                    id="keyword"
                    name="keyword"
                    value={this.state.tags}
                    onChange={tags => this.setState({ tags })}
                    inputProps={{
                      placeholder: "Input tags"
                    }}
                  />
                </Col>
                {!this.props.isUpdate && (
                  <Col md="12">
                    <label>Group topic</label>
                    <Select
                      className="react-select mb-2"
                      classNamePrefix="react-select"
                      size="lg"
                      closeMenuOnSelect={true}
                      options={this.props.group_topic_list}
                      value={this.state.groupTopicSelect}
                      onChange={value =>
                        this.setState({ groupTopicSelect: value })
                      }
                    />
                  </Col>
                )}
                <Col md="12">
                  <label>Language</label>
                  <Select
                    className="react-select mb-2"
                    classNamePrefix="react-select"
                    size="lg"
                    closeMenuOnSelect={true}
                    options={languageOption}
                    value={this.state.language}
                    onChange={value => this.setState({ language: value })}
                  />
                </Col>
                <Col md="12">
                  <Row>
                    <Col md="6">
                      <label>Publish topic</label>
                      <label className="custom-toggle custom-toggle-success ml-2">
                        <input
                          checked={this.state.publish}
                          onChange={e =>
                            this.setState({ publish: e.target.checked })
                          }
                          type="checkbox"
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="OFF"
                          data-label-on="ON"
                        />
                      </label>
                    </Col>
                    {/* Temporary disable pin topic to menu feature */}
                    {/* <Col md="6 text-right">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="pin-menu"
                          type="checkbox"
                          onChange={(e) => {
                            this.setState({ pinMenu: e.target.checked });
                          }}
                          checked={this.state.pinMenu}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="pin-menu"
                        >
                          <span className="text-muted">Pin menu</span>
                        </label>
                      </div>
                    </Col> */}
                  </Row>
                  <div className="note">
                    * Unpublish group will also unpublish all listTopic within
                    this group.
                  </div>
                </Col>
              </Row>
              <div
                className="message mt-3"
                style={{ color: "red", fontStyle: "italic" }}
              >
                {this.state.message}
              </div>
              {this.props.isUpdate && (
                <div onClick={this._handleOnDelete} className="btn-delete">
                  Delete topic
                </div>
              )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-flex-end">
              <Button
                onClick={this.toggle}
                color="link"
                style={{ color: "#172B4D" }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                onClick={this._handleOnSave}
                color="link"
                style={{ color: "#fb6340" }}
              >
                SAVE
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    group_topic_list: state.topicReducer.group_topic_list,
    group_detail: state.topicReducer.group_detail,
    topic_detail: state.topicReducer.topic_detail
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getTopicDetailRequest: id => {
      dispatch(actGetTopicDetailRequest(id));
    },
    addTopic: (data, group_detail, params) => {
      dispatch(actAddTopicRequest(data, group_detail, params));
    },
    updateTopic: (id, data, group_detail, params) => {
      dispatch(actUpdateTopicRequest(id, data, group_detail, params));
    },
    deleteTopic: (id, group_detail, params) => {
      dispatch(actDeleteTopicRequest(id, group_detail, params));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefTopicModal);
ChiefTopicModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  topic_detail: PropTypes.object,
  group_detail: PropTypes.object,
  addTopic: PropTypes.func,
  getTopicDetailRequest: PropTypes.func,
  updateTopic: PropTypes.func,
  deleteTopic: PropTypes.func,
  group_topic_list: PropTypes.array,
  isUpdate: PropTypes.bool
};
ChiefTopicModal.defaultProps = {};
