import React from 'react';
import Avatar from "../../Avatar/Avatar";
import get from "lodash/get"
export const columns = [
  {
    title: "Writers",
    dataIndex: "article.title",
    render: (value, row, index) => {
      const avatar = get(row, "writer[0].avatar", "");
      const name = get(row, "writer[0].penname", "");
      return (
        <div className=" d-flex  align-items-center">
          <Avatar src={avatar}     className={"mr-2"}/>
          {name}  
        </div>
      );
    },
  },
  {
    title: "Articles",
    dataIndex: "numberArticle",
    key: "topic",
  },
  {
    title: "Views",
    dataIndex: "total",
    key: "Published Date",
  },
];

export const handlePieChartWriter = (res) => {
  const data = {
    labels: ["Expert", "Writer", "Contributor", "Editor"],
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: ["#5E72E4", "#11CDEF", "#62DAA7", "#001DBC"],
        hoverBackgroundColor: ["#5E72E4", "#11CDEF", "#62DAA7", "#001DBC"],
      },
    ],
  };
  let writer = get(res, 'data.writer.titleOfUser', []);
   Object.keys(writer).map(item => { 
    let index = data.labels.indexOf(item);
    if (index >= 0) {
      data.datasets[0].data[index] = writer[item];
    }
   })
  return data;
}