import React from "react";
import {
  CardHeader,
  Card,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";

import "./style.scss";

class NewArticleSEOManager extends React.Component {
  // componentDidMount() {
  //   this.dropzoneInitial();
  // }
  _handleOnSEOChange = (label, value) => {
    const { seo } = this.props;
    this.props.onChangeSEO({
      ...seo,
      [label]: value,
    });
  };

  render() {
    const { seo, errors } = this.props;
    return (
      <Card className="seo-manager">
        <CardHeader className="d-flex justify-content-between">
          <h3>SEOs</h3>
          <h5>
            <em>
              All fields with (<span className="asterisk">*</span>) is required
            </em>
          </h5>
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.onSubmit}>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <div className="d-flex">
                    <label htmlFor="">Description</label>
                    <span className="asterisk ml-1">*</span>
                  </div>
                  <Form>
                    <Input
                      className="description"
                      value={seo.description}
                      onChange={(e) =>
                        this._handleOnSEOChange("description", e.target.value)
                      }
                      maxLength="160"
                      rows="5"
                      type="textarea"
                    />
                    <small className="float-right">
                      {seo.description ? seo.description.length : "0"}/160
                    </small>
                  </Form>
                  {errors && errors.seo.description ? (
                    <p className="text-danger mt-2">
                      <small>{errors.seo.description}</small>
                    </p>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" className="d-none">
                <FormGroup>
                  <div className="d-flex">
                    <label htmlFor="">Image</label>
                    <span className="asterisk ml-1">*</span>
                  </div>
                  <div className="justify-content-center">
                    <div
                      id="dropzone-seo"
                      className="dropzone dropzone-single mb-3"
                    >
                      <div className="fallback">
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="projectCoverUploads"
                            type="file"
                          />
                        </div>
                      </div>
                      <div className="dz-preview dz-preview-single">
                        <div className="dz-preview-cover">
                          <img
                            alt="..."
                            className="dz-preview-img"
                            data-dz-thumbnail=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default NewArticleSEOManager;
