import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dropzone from "dropzone";
import { Modal, Button, Row, Col, Input, Form } from "reactstrap";
import MultipleCheckboxSelectFeature from "../MultipleCheckboxSelect/MultipleCheckboxSelectFeature";
import HOST from "../../variables/host";
import ChiefRemoveModal from "./ChiefRemoveModal";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  actSearchArticlesRequest,
  actCreateCollectionRequest,
  actUpdateCollectionRequest,
  actRemoveColletionRequest,
  actSearchArticlesRequestWithoutId
} from "../../actions/chief-collection.action";
import renderDropzoneThumbnail from "../../utils/renderDropzoneThumbnail";
import ReactHtmlParser from "react-html-parser";
import * as authenStorage from "../../utils/authen-storage";

function ChiefAddCollectionModal(props) {
  const dispatch = useDispatch();
  const data_search = useSelector(
    state => state.ChiefCollectionReducer.data_search
  );

  const [values, setValues] = useState({
    valueSearch: "",
    dataSearch: []
  });
  const [selectAll, useSelectAll] = useState(false);
  const [arrSelect, setArrSelect] = useState([]);
  const [selectedId, setSelectedId] = useState([]);

  const [collectionForm, setCollectionForm] = useState({
    title: "",
    on_homepage: false,
    status: false
  });

  const [messageName, setMessageName] = useState("");

  const [messageImage, setMessageImage] = useState("");

  const [collectionId, setCollectionId] = useState("");

  const [isRemoveCollection, setRemoveCollection] = useState(false);

  const [imageId, setImageId] = useState("");

  useEffect(() => {
    dropzone();
    if (props.isUpdate) {
      props.collection_detail.images &&
        renderDropzoneThumbnail(0, props.collection_detail.images.url);
      // let arrSelect = [];
      // props.collection_detail.docs.map(item => {
      //   arrSelect.push({ angle_title: item.title, _id: item._id });
      //   return arrSelect;
      // });
      // let selectedId = [];
      // props.collection_detail.docs.map(item => {
      //   selectedId.push(item._id);
      //   return selectedId;
      // });
      // setArrSelect(arrSelect);
      // setSelectedId(selectedId);
      setImageId(props.collection_detail.images._id);
      setCollectionForm({
        title: props.collection_detail.title,
        on_homepage: props.collection_detail.on_homepage,
        status: props.collection_detail.status === "PUBLISHED"
      });
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (collectionForm.title.length === 0) {
      setMessageName("Input collection name is empty.");
    }
    if (imageId.length === 0) {
      setMessageImage("Please choose an image.");
    }
    if (collectionForm.title.length !== 0 && imageId.length !== 0) {
      let data = {
        title: collectionForm.title,
        image: imageId,
        isOnHomepage: collectionForm.on_homepage,
        status: collectionForm.status ? "PUBLISHED" : "UNPUBLISHED",
        articleIds: selectedId,
        language: props.isLanguage ? "VN" : "EN"
      };
      let params = {
        slug: props.params.slug,
        page: props.params.page,
        limit: props.params.limit,
        number: props.params.number,
        language: props.isLanguage ? "VN" : "EN"
      };
      if (props.isUpdate) {
        dispatch(
          actUpdateCollectionRequest(data, params, props.collection_detail._id)
        );
        props.toggleUpdate();
      } else {
        dispatch(actCreateCollectionRequest(data, params));
        props.toggle();
      }
    }
  };

  const dropzone = () => {
    let currentSingleFile = undefined;
    new Dropzone(document.getElementById("dropzone-single"), {
      url: HOST + "/images/upload",
      headers: { accessToken: authenStorage.getToken() },
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function() {
        this.on("addedfile", function(file) {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      },
      success: (file, res) => {
        setMessageImage("");
        setImageId(res.data._id);
      }
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
  };

  const onSelect = arr => {
    if (selectedId.includes(arr._id)) {
      setSelectedId(selectedId.filter(prop => prop !== arr._id));
      setArrSelect(arrSelect.filter(prop => prop._id !== arr._id));
    } else {
      setSelectedId([...selectedId, arr._id]);
      setArrSelect([...arrSelect, arr]);
    }
  };

  const onSelectAll = () => {
    if (!selectAll) {
      useSelectAll(true);
      setArrSelect(data_search.docs);
      let selectId = [];
      data_search.docs.map(item => {
        selectId.push(item._id);
        return selectId;
      });
      setSelectedId(selectId);
    } else {
      useSelectAll(false);
      setArrSelect([]);
      setSelectedId([]);
    }
  };

  const handleChangeCollectionForm = e => {
    setCollectionForm({
      ...collectionForm,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value
    });
    if (collectionForm.title.length > 0) {
      setMessageName("");
    }
  };

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
    if (props.collection_detail._id === undefined) {
      const data = {
        value: e.target.value,
        language: props.isLanguage ? "VN" : "EN"
      };
      dispatch(actSearchArticlesRequestWithoutId(data));
      return;
    }
    const data = {
      value: e.target.value,
      language: props.isLanguage ? "VN" : "EN",
      idCol: props.collection_detail._id
    };
    dispatch(actSearchArticlesRequest(data));
  };

  const handleRemoveItemSelected = id => {
    setValues({ ...values, valueSearch: "" });
    setArrSelect(arrSelect.filter(el => el._id !== id));
    setSelectedId(selectedId.filter(el => el !== id));
  };

  const handleClearAll = () => {
    setSelectedId([]);
    setArrSelect([]);
  };

  const handleRemoveAll = () => {
    setValues({ ...values, valueSearch: "" });
    setSelectedId([]);
    setArrSelect([]);
  };

  const handleToggleRemoveCollection = id => {
    setCollectionId(id);
    setRemoveCollection(!isRemoveCollection);
  };

  const handleDeleteCollection = () => {
    dispatch(actRemoveColletionRequest(collectionId));
  };

  return (
    <>
      {isRemoveCollection && (
        <ChiefRemoveModal
          modal={isRemoveCollection}
          title="Delete collection"
          type="removeCollection"
          description="Are you sure want to delete this collection?"
          toggle={handleToggleRemoveCollection}
          handleDeleteCollection={handleDeleteCollection}
        />
      )}
      <Modal
        className="modal-dialog-centered"
        isOpen={props.isShowModal}
        style={{ minWidth: 608 }}
      >
        <Form onSubmit={e => handleSubmit(e)}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.isUpdate ? "Settings" : "Add New Collection"}
            </h5>
          </div>
          <div className="modal-body">
            <Row>
              <Col className="mb-3" md="12">
                <label
                  className="form-control-label"
                  htmlFor="validationFullname"
                >
                  {props.isUpdate ? "Collection Name" : "New Collection Name"}
                </label>
                <Input
                  id="validationFullname"
                  aria-describedby="inputGroupPrepend"
                  name="title"
                  value={collectionForm.title}
                  type="text"
                  placeholder="Input new collection name"
                  onChange={handleChangeCollectionForm}
                />
                <p className="invalid-title ml-2" style={{ color: "#ff0000" }}>
                  <small>{messageName}</small>
                </p>
              </Col>
              <Col md="12">
                <label>Thumbnail</label>
                <div
                  className="dropzone dropzone-single mb-3"
                  id="dropzone-single"
                >
                  <div className="fallback">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        id="projectCoverUploads"
                        type="file"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="projectCoverUploads"
                      >
                        Choose file
                      </label>
                    </div>
                  </div>
                  <div className="dz-preview dz-preview-single">
                    <div className="dz-preview-cover">
                      <img
                        alt=""
                        className="dz-preview-img"
                        data-dz-thumbnail=""
                      />
                    </div>
                  </div>
                </div>
                <p className="invalid-title ml-2" style={{ color: "#ff0000" }}>
                  <small>{messageImage}</small>
                </p>
              </Col>
              <Col md="12">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="mr-2">Publish Collection </span>
                    <label className="custom-toggle custom-toggle-success mr-1">
                      <input
                        name="status"
                        type="checkbox"
                        value={collectionForm.status}
                        checked={collectionForm.status}
                        onChange={handleChangeCollectionForm}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="OFF"
                        data-label-on="ON"
                      />
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="pin-menu"
                        name="on_homepage"
                        type="checkbox"
                        value={collectionForm.on_homepage}
                        onChange={handleChangeCollectionForm}
                        checked={collectionForm.on_homepage}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="pin-menu"
                      >
                        <span className="text-muted">Pin to homepage</span>
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="12">
                <p className="mt-3 mb-3">
                  * Unpublish collection will also unpublish all articles within
                  this collection.
                </p>
              </Col>
              <Col md="12">
                <div className="mb-2">
                  <span className="text-regular">Add Articles</span>
                </div>
                <div>
                  <MultipleCheckboxSelectFeature
                    valueInput={values.valueSearch}
                    dataSearch={data_search}
                    selectedId={selectedId}
                    arrSelect={arrSelect}
                    onSelect={onSelect}
                    selectAll={selectAll}
                    onSelectAll={onSelectAll}
                    handleChange={handleChange}
                    handleClearAll={handleClearAll}
                    hasSelectAll={true}
                  />
                  <div className="feature-article-list-select-scroll">
                    {arrSelect.map((item, index) => {
                      return (
                        <div
                          className="feature-article-list-select"
                          key={index}
                        >
                          <span className="select-title">
                            {ReactHtmlParser(item.angle_title)}
                          </span>
                          <span
                            className="select-remove"
                            onClick={() => handleRemoveItemSelected(item._id)}
                          >
                            <i className="fa fa-trash-alt" />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            {!props.isUpdate ? (
              arrSelect.length > 0 ? (
                <div>
                  <Button
                    color="link"
                    style={{ color: "#5E72E4", fontWeight: "normal" }}
                    data-dismiss="modal"
                    type="button"
                    onClick={handleRemoveAll}
                  >
                    Remove selected
                  </Button>
                </div>
              ) : (
                <div></div>
              )
            ) : (
              <div>
                <Button
                  color="link"
                  style={{ color: "#5E72E4", fontWeight: "normal" }}
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    handleToggleRemoveCollection(props.collection_detail._id)
                  }
                >
                  Delete collection
                </Button>
              </div>
            )}

            <div>
              <Button
                color="link"
                style={{ paddingRight: "0px", color: "#172B4D" }}
                type="button"
                onClick={props.isUpdate ? props.toggleUpdate : props.toggle}
              >
                CANCEL
              </Button>
              <Button color="link" type="submit" style={{ color: "#fb6340" }}>
                {props.isUpdate ? "SAVE" : "CREATE COLLECTION"}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default ChiefAddCollectionModal;

ChiefAddCollectionModal.propTypes = {
  isShowModal: PropTypes.bool,
  isLanguage: PropTypes.bool,
  isUpdate: PropTypes.bool,
  collection_detail: PropTypes.any,
  handleToggleSettings: PropTypes.func,
  toggleUpdate: PropTypes.func,
  params: PropTypes.object,
  toggle: PropTypes.func
};
