import React from "react";
// reactstrap components
import { Button, Modal } from "reactstrap";
import "./styles.scss";

class CreateNewPasswordInfoModal extends React.Component {
  render() {
    const { createNewPasswordInfoModal, history } = this.props;
    return (
      <>
        {/* Modal */}
        <Modal
          className="modal-dialog-centered create-new-passowrd-info-modal"
          isOpen={createNewPasswordInfoModal}
        >
          <div className="modal-body text-center">
            Currently, your CMS account does not have a password. Please create
            a new one.
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => history.push("/admin/forgotPassword")}
              style={{
                backgroundColor: "#5e72e4",
                borderColor: "#5e72e4",
                color: "#ffffff",
              }}
            >
              Create Password
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CreateNewPasswordInfoModal;
