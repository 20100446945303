import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { Form, Input, Button, Modal, Divider, Typography } from "antd";
import {
  FormGroup,
  UncontrolledPopover,
  PopoverBody,
  Row,
  Col
} from "reactstrap";
import ImageUpload from "../../ImageUpload";
import { ReactComponent as Icon } from "../../../assets/img/icons/exclamation-outline.svg";
import "./styles.scss";
import HOST_URL from "../../../variables/hostUrl";
import CustomEditor from "../../TinyMCE";
const { Text } = Typography;

const AddSlideForm = ({
  visible,
  handleCancel,
  handleOk,
  currentSlide,
  addSlide,
  editSlide,
  form: { getFieldDecorator, setFieldsValue, resetFields, validateFields }
}) => {
  const [content, setContent] = useState("");
  const [slideCount, setSlideCount] = useState(1);
  const [quillError, setQuillError] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  // Life cycle
  useEffect(() => {
    if (currentSlide) {
      setFieldsValue({
        imageCaption:
          currentSlide.imageCaption || get(currentSlide, "images.caption"),
        imageAlt: currentSlide.imageAlt || get(currentSlide, "images.alt"),
        imageId: get(currentSlide, "imageId") || get(currentSlide, "images._id")
      });
      setImageUrl(
        currentSlide.imageUrl ||
          `${HOST_URL}${get(currentSlide, "images.size.THREE_TWO_268x178.url")}`
      );
      setContent(currentSlide.content);
    }
    return () => {
      setContent("");
      setQuillError(false);
      setImageUrl("");
      resetFields();
    };
  }, [currentSlide]);

  const handleOnContentChange = value => {
    setQuillError(false);
    setContent(value);
  };

  const handleChangeImage = id => {
    setFieldsValue({
      imageId: id
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const rawContent = content.replace(/<[^>]*>/g, "");
    if (rawContent.length === 0) {
      setQuillError(true);
      return;
    }
    validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values,
          title: `Slide ${slideCount}`,
          imageUrl: imageUrl,
          position: slideCount,
          content
        };
        setSlideCount(slideCount + 1);
        addSlide(data);
        handleCancel();
        resetFields();
        setContent("");
        setImageUrl("");
      }
    });
  };

  const onChangeImageUrl = url => {
    setImageUrl(url);
  };

  const updateSlide = e => {
    const rawContent = content.replace(/<[^>]*>/g, "");
    if (rawContent.length === 0) {
      setQuillError(true);
      return;
    }
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values,
          title: currentSlide.title,
          position: currentSlide.position,
          imageUrl: imageUrl,
          content
        };
        editSlide(data);
        handleCancel();
      }
    });
  };

  return (
    <Modal
      className={`application-info-modal`}
      visible={visible}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <div className="live-update-manangement-section">
        <h3>Add new slide</h3>
        <Divider />
        <Form
          initialValues={{
            imageId: ""
          }}
          onSubmit={handleSubmit}
          className="live-update-form"
        >
          <label>
            Description <Text type="danger">*</Text>
          </label>
          <div className="description-section">
            <CustomEditor
              menubar="false"
              toolbar="`undo redo | formatselect | bold italic blockquote underline | hr | \
              alignleft aligncenter alignright | \
              bullist numlist outdent indent"
              content={content || ""}
              onChangeContent={value => handleOnContentChange(value)}
            />
            {quillError ? (
              <Text type="danger">This field is required!</Text>
            ) : null}
          </div>
          <div className="image-upload-section mt-4">
            <Row className="dropzone-wrapper">
              <Col md="6">
                <Form.Item>
                  {getFieldDecorator("imageId", {
                    rules: [
                      {
                        required: true,
                        message: "Image is required!"
                      }
                    ]
                  })(
                    <ImageUpload
                      hideRemoveImage={true}
                      maxSize="500kb"
                      imageUrl={imageUrl}
                      onChangeImageUrl={onChangeImageUrl}
                      callback={handleChangeImage}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col md="6">
                <FormGroup>
                  <div>
                    <label>Feature Image’s Caption</label>
                    <Form.Item required>
                      {getFieldDecorator("imageCaption", {
                        rules: [
                          {
                            required: true,
                            message: "Image's caption is required!"
                          }
                        ]
                      })(<Input placeholder="Feature image caption" />)}
                    </Form.Item>
                  </div>
                  <div className="d-flex align-items-center">
                    <label>Feature Image’s Alternative Text</label>
                    <Icon
                      style={{ marginBottom: "0.5em" }}
                      id="Popover"
                      className="exclamation"
                    />
                  </div>
                  <UncontrolledPopover
                    placement="bottom"
                    target="Popover"
                    trigger="legacy"
                  >
                    <PopoverBody>
                      The alternative text provides alternative information for
                      an image if a user for some reason cannot view it (because
                      of slow connection, or if the user uses a screen reader).
                    </PopoverBody>
                  </UncontrolledPopover>

                  <Form.Item required>
                    {getFieldDecorator("imageAlt", {
                      rules: [
                        {
                          required: true,
                          message: "Image's alt is required!"
                        }
                      ]
                    })(<Input placeholder="Feature image alt" />)}
                  </Form.Item>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="btn-group d-flex align-items-center justify-content-end">
            {currentSlide ? (
              <Button type="primary" onClick={updateSlide} className="mr-2">
                Update
              </Button>
            ) : (
              <Form.Item>
                <Button type="primary" htmlType="submit" className="mr-2">
                  Save
                </Button>
              </Form.Item>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};
const WrappedAddSlideForm = Form.create()(AddSlideForm);
export default WrappedAddSlideForm;
