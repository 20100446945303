import React from "react";
import { Container, Row, Col } from "reactstrap";
import CardsHeader from "../../components/Headers/CardsHeader.jsx";
import MyProfileModal from "../../components/Modals/MyProfileModal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import WriterViewDetailContentModal from "../../components/Modals/WriterViewDetailContentModal";
import getUrlParam from "../../utils/getUrlParam";
import WriterOutlineDashboardTable from "../../components/Tables/WriterOutlineDashboardTable";
import WriterArticleDashboardTable from "../../components/Tables/WriterArticleDashboardTable";
import WriterPitchesDashboardTable from "../../components/Tables/WriterPitchesDashboardTable";
class DashboardWriter extends React.Component {
  state = {
    isModal: true,
    isViewDetail: false,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10
    }
  };

  componentDidMount() {
    this._verifyRole();
  }

  _verifyRole = () => {
    const key = localStorage.getItem("key");
    key !== "writer" && this.props.history.push("/admin");
  };

  _toggleModalNotify = () => {
    this.setState(prevState => ({
      isModal: !prevState.isModal
    }));
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };
  render() {
    const isSkip = localStorage.getItem("is_skip");
    const props = this.props;

    return (
      <>
        {isSkip === "false" ? (
          <MyProfileModal
            modal={this.state.isModal}
            toggle={this._toggleModalNotify}
          />
        ) : (
          ""
        )}
        <WriterViewDetailContentModal
          modal={this.state.isViewDetail}
          toggle={this._toggleModal}
          detailContent={this.props}
        />
        <CardsHeader name="Dashboard" parentName="Dashboards" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xs="12">
              <WriterOutlineDashboardTable {...props} />
            </Col>
            <Col xs="12">
              <WriterArticleDashboardTable {...props} />
            </Col>
            <Col xs="12">
              <WriterPitchesDashboardTable {...props} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default connect(
  null,
  null
)(DashboardWriter);
DashboardWriter.propTypes = {
  header_dashboard: PropTypes.object,
  dashboards_writer: PropTypes.object,
  dealine_list: PropTypes.object,
  getHeaderDashBoard: PropTypes.func,
  getDashBoardWriter: PropTypes.func,
  getContentByID: PropTypes.func,
  getDealineList: PropTypes.func
};
DashboardWriter.defaultProps = {};
