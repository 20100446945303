import * as Types from "../variables/actionTypes.jsx";

const initialState = {
  tags: [],
  allTagsInEachGroup: null,
  isLoading: false,
  tagSearched: [],
  relatedTags: [],
  articlesTags: [],
  nameOfFetcher: [],
  totalTag: {}
};

const tagManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.DECLARE_ACTION:
      state = {
        ...state,
        isLoading: true
      };

      return state;

    case Types.TAG_SEARCHING_LEFT_EMPTY:
      state = {
        ...state,
        tagSearched: []
      };

      return state;

    case Types.GET_RELATED_TAG_SUCCESSFULLY:
      state = {
        ...state,
        relatedTags: action.data.data
      };

      return state;

    case Types.GET_NO_DATA_LEFT:
      state = {
        ...state,
        nameOfFetcher: [...state.nameOfFetcher, action.data]
      };

      return state;

    case Types.GET_ARTICLE_TAG_SUCCESSFULLY:
      state = {
        ...state,
        articlesTags: action.data.data
      };

      return state;

    case Types.TAG_SEARCHING_SUCCESSFULLY:
      state = {
        ...state,
        tagSearched: [...state.tagSearched, action.data],
        isLoading: false
      };

      return state;

    case Types.RESET_STATE_TAGS_MANAGEMENT:
      state = {
        tags: [],
        allTagsInEachGroup: null,
        isLoading: false,
        tagSearched: [],
        relatedTags: [],
        articlesTags: [],
        nameOfFetcher: []
      };

      return state;

    case Types.GET_ALL_TAGS_IN_EACH_GROUP_SUCCESS:
      state = {
        ...state,
        allTagsInEachGroup: action.data.data
      };

      return state;

    case Types.GET_OFFICIAL_TAGS_SUCCESS:
      state = {
        ...state,
        tags: [...state.tags, action.data.data],
        isLoading: false
      };

      return state;

    
    case Types.GET_TOTAL_TAG_SUCCESSFULLY:
      state = {
        ...state,
        totalTag: {...action.data.data}
      };

      return state;

    case Types.GET_SUGGESTED_TAGS_SUCCESS:
      state = {
        ...state,
        tags: [...state.tags, action.data.data],
        isLoading: false
      };

      return state;

    default:
      return state;
  }
};

export default tagManagementReducer;
