import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetEditorDashboardHeaderRequest = () => {
  return dispatch => {
    return callApi(`article/header-dashboards-editor`, "GET")
      .then(res => {
        dispatch(actGetEditorDashboardHeader(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetEditorDashboardHeader = data => {
  return {
    type: Types.GET_EDITOR_HEADER_DASHBOARD,
    data
  };
};

export const actGetFinishedArticleEditorRequest = () => {
  return dispatch => {
    return callApi(
      `article/list-article-finished-dashboards-editor?limit=20&page=1`,
      "GET"
    )
      .then(res => {
        dispatch(actGetFinishedArticleEditorHeader(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetFinishedArticleEditorHeader = data => {
  return {
    type: Types.GET_FINISHED_ARTICLE_DASHBOARD,
    data
  };
};

export const actGetOutlineEditorDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `dashboard/editor-outline?` +
        `limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetOutlineEditorDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetOutlineEditorDashboard = data => {
  return {
    type: Types.GET_OUTLINE_EDITOR_DASHBOARD,
    data
  };
};

export const actGetArticleEditorDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `dashboard/editor-content?` +
        `status_article=${data.status_article}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetArticleEditorDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetArticleEditorDashboard = data => {
  return {
    type: Types.GET_ARTICLE_EDITOR_DASHBOARD,
    data
  };
};

export const actGetPitchesEditorDashboardRequest = data => {
  return dispatch => {
    return callApi(
      `dashboard/editor-pitch?` +
        `limit=${data.limit}` +
        `&sort=${data.type}:${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetPitchesEditorDashboard(res.data));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPitchesEditorDashboard = data => {
  return {
    type: Types.GET_PITCH_EDITOR_DASHBOARD,
    data
  };
};
