import React, { Component } from "react";
import moment from "moment";
import { omitBy, omit, isNil, isEmpty, get } from "lodash";
import ReactDatetime from "react-datetime";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import { getCookie } from "../../utils/cookies";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import {
  actGetPostInfoRequestV2,
  actGetPostDetailRequest,
  actGetBriefDetailRequest,
  actGetTagsByTopicIDRequest,
  actUpdatePostRequest,
  actDeletePostRequest,
  actGetEditorListRequest,
  actCreateTranslateBriefRequest,
  actCreateNewBriefRequest
} from "../../actions/post.action";
import { actGetListStaffRequest } from "../../actions/employee.action";
import { stripHTMLTag } from "../../utils/stripHtml";
import HOST_URL from "../../variables/hostUrl";
import AVA from "../../assets/img/AVA.png";
import callApi from "../../utils/callApi";
const jwt = require("jsonwebtoken");

const languageOption = [
  {
    label: "English",
    value: "EN"
  },
  {
    label: "Vietnamese",
    value: "VN"
  }
];
class EditorAddNewPostTable extends Component {
  state = {
    id: window.location.pathname.split("/")[4],
    pathName: window.location.pathname.split("/")[3],
    tabs: 1,
    reply: false,
    tagsinput: [],
    titleWarn: false,
    data: {
      audience: "",
      angle_title: "",
      assigned_to: null,
      writerID: null,
      assign_to_editor: "",
      type_of_article: [],
      topics_of_concern: [],
      steps_to_write: [],
      age_group: [],
      comprehension_level: [],
      tone_of_writing: [],
      dead_line: "",
      keywords: [],
      topic: [],
      tags: [],
      objectives: "",
      references: "",
      things_to_avoid: "",
      things_to_mention: "",
      note: "",
      language: "",
      max_word: "",
      min_word: "",
      is_on_market: true
    },
    tagsOption: [],
    tagsSelect: [],
    isOtherSteps: false,
    isOtherTone: false,
    optionCurrentEditor: [],
    autoSave: false,
    isTranslate: false
  };

  async componentDidMount() {
    this._setLoading();
    this._getPostInfo();
    this.props.getListStaff({ status: "WORKING", position: "writer" });
    this.state.id && this.props.getBriefDetail(this.state.id);
    this._getEditorListOrCurrent();
    !this.state.id && this._getBriefDataFromLocal();
    if (!this.state.id) {
      this._autoSave();
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.post_detail &&
      nextProps.post_detail !== this.props.post_detail
    ) {
      const post_data = nextProps.post_detail.data[0];
      let topic = get(post_data, "topic[0]");
      const data = {
        ...post_data,
        dead_line: moment(post_data.dead_line),
        assign_to_editor: post_data.assign_to_editor._id,
        topic: [
          { ...topic, value: topic && topic.key, label: topic && topic.name }
        ]
      };
      this.setState({
        data,
        tagsOption:
          nextProps.post_detail.data.topic &&
          nextProps.post_detail.data.topic[0] &&
          nextProps.post_detail.data.topic[0].tags,
      });
      if (
        data.steps_to_write &&
        data.steps_to_write[data.steps_to_write.length - 1] &&
        data.steps_to_write[data.steps_to_write.length - 1].key === "other"
      ) {
        this.setState({ isOtherSteps: true });
      }
      if (
        data.tone_of_writing &&
        data.tone_of_writing[data.tone_of_writing.length - 1] &&
        data.tone_of_writing[data.tone_of_writing.length - 1].key === "other"
      ) {
        this.setState({ isOtherTone: true });
      }
      var otherLanguage = languageOption.filter(
        ({ value }) => value !== data.language
      );
      this.state.pathName === "brief-translate" &&
        this.setState({
          isTranslate: true,
          data: {
            ...data,
            angle_title:
              data.angle_title + ` - Translate to ${otherLanguage[0].label}`,
            language: otherLanguage[0].value
          }
        });
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
  }

  async _getBriefDataFromLocal() {
    const briefData = localStorage.getItem("briefData");
    if (briefData) {
      let parseData = await JSON.parse(briefData);
      this.setState({
        data: parseData
      });
      if (parseData.tags.length > 0) {
        this.setState({
          tagsSelect: parseData.tags
        });
      }
    }
  }

  async _getCurrentEditorInfo() {
    const data = this.state.data;
    let result = {
      admin: {
        avatar: "",
        _id: "",
        name: "",
      }
    }
    try {
      const res = await callApi(`profile`);
      result.admin = res.data;
    } catch(err) {
      console.log("err", err);
    }
    const { avatar, _id, name } = result.admin;
    this.setState({
      optionCurrentEditor: [
        {
          label: name,
          value: _id,
          icon: avatar
        }
      ],
      data: {
        ...data,
        assign_to_editor: _id
      }
    });
  }

  _getEditorList() {
    this.props.getEditorList();
  }

  _getEditorListOrCurrent() {
    const jobKey = localStorage.getItem("key");
    if (jobKey === "editor") {
      this._getCurrentEditorInfo();
    }
    if (jobKey === "chiefeditor") {
      this._getEditorList();
    }
  }

  _getPostInfo() {
    this.props.getPostInfo();
  }

  _getPostDetail() {
    this.state.id && this.props.getPostDetail(this.state.id);
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _handleOnChange = e => {
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data,
      autoSave: true,
      titleWarn: e.target.value.length > 60
    });
  };

  _handleOnContentChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value
      },
      autoSave: true
    });
  };

  _handleOnInputStepsOtherChange = e => {
    const data = this.state.data;
    const otherStep = this.state.data.steps_to_write;
    otherStep &&
      otherStep.forEach(prop => {
        if (prop.key === "other") {
          prop.name = e.target.value;
        }
      });
    this.setState({
      data: {
        ...data,
        steps_to_write: otherStep
      },
      autoSave: true
    });
  };

  _handleOnInputToneOtherChange = e => {
    const data = this.state.data;
    const otherTone = this.state.data.tone_of_writing;
    otherTone &&
      otherTone.forEach(prop => {
        if (prop.key === "other") {
          prop.name = e.target.value;
        }
      });
    this.setState({
      data: {
        ...data,
        tone_of_writing: otherTone
      },
      autoSave: true
    });
  };

  _handleOnSelectTopicChange = (topickey, topicname) => {
    let tagsFilter = this.props.post_info.data.topics.filter(
      prop => prop.key === topickey
    );
    this.setState({
      data: {
        ...this.state.data,
        topic: [
          {
            value: topickey,
            label: topicname,
            key: topickey,
            name: topicname,
            tags: tagsFilter[0].tags
          }
        ]
      },
      autoSave: true
    });
    const { topics } = this.props.post_info.data;
    let topicsTags = topics && topics.filter(prop => prop.key === topickey);
    this.setState({
      tagsOption: topicsTags[0].tags,
    });
  };

  _handleOnTagsSelectChange = (label, value) => {
    this.setState({
      [label]: value
    });
  };

  _handleOnValueChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value
      },
      autoSave: true
    });
  };

  _handleOnAssignToChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        is_on_market: false,
        [label]: value
      },
      autoSave: true
    });
  };

  _handleOnAssignToEditorChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value
      },
      autoSave: true
    });
  };

  _handleCheckboxChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        assigned_to: null,
        writerID: null,
        [e.target.name]: e.target.checked
      },
      autoSave: true
    });
  };

  _handleOnChangeCheckboxArticle = e => {
    const { type_of_article } = this.state.data;
    var obj = {};
    let index;
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "articletypes" && e.target.checked) {
      type_of_article.push(obj);
    } else {
      index = type_of_article.findIndex(i => i.key === e.target.title);
      type_of_article.splice(index, 1);
    }
    this.setState({
      data: {
        ...this.state.data,
        type_of_article: type_of_article
      },
      autoSave: true
    });
  };

  _handleOnChangeCheckboxConcern = e => {
    const { topics_of_concern } = this.state.data;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "concerntypes" && e.target.checked) {
      topics_of_concern.push(obj);
    } else {
      index = topics_of_concern.findIndex(i => i.key === e.target.title);
      topics_of_concern.splice(index, 1);
    }
    this.setState({
      data: {
        ...this.state.data,
        topics_of_concern: topics_of_concern
      },
      autoSave: true
    });
  };

  _handleOnChangeCheckboxAge = e => {
    const age_group = this.state.data.age_group;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "agetypes" && e.target.checked) {
      age_group.push(obj);
    } else {
      index = age_group.findIndex(i => i.key === e.target.title);
      age_group.splice(index, 1);
    }
    this.setState({
      data: {
        ...this.state.data,
        age_group: age_group
      },
      autoSave: true
    });
  };

  _handleOnChangeCheckboxCompreLevels = e => {
    const { comprehension_level } = this.state.data;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "compretypes" && e.target.checked) {
      comprehension_level.push(obj);
    } else {
      index = comprehension_level.findIndex(i => i.key === e.target.title);
      comprehension_level.splice(index, 1);
    }
    this.setState({
      data: {
        ...this.state.data,
        comprehension_level: comprehension_level
      },
      autoSave: true
    });
  };

  _handleOnChangeCheckboxStepWrite = e => {
    const steps_to_write = this.state.data.steps_to_write;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "stepsWrite" && e.target.checked) {
      steps_to_write.push(obj);
    } else {
      index = steps_to_write.findIndex(i => i.key === e.target.title);
      steps_to_write.splice(index, 1);
    }
    this.setState({
      data: { ...this.state.data, steps_to_write: steps_to_write },
      autoSave: true
    });
    let isOtherStepsCheck = steps_to_write.filter(prop => prop.key === "other");
    if (isOtherStepsCheck[0] && isOtherStepsCheck[0].key === "other") {
      this.setState({
        isOtherSteps: true
      });
    } else {
      this.setState({
        isOtherSteps: false
      });
    }
  };

  _handleOnChangeCheckboxToneWrite = e => {
    const { tone_of_writing } = this.state.data;
    let index;
    var obj = {};
    obj = {
      key: e.target.title,
      name: e.target.pattern
    };
    if (e.target.name === "tonetypes" && e.target.checked) {
      tone_of_writing.push(obj);
    } else {
      index = tone_of_writing.findIndex(i => i.key === e.target.title);
      tone_of_writing.splice(index, 1);
    }
    this.setState({
      data: { ...this.state.data, tone_of_writing: tone_of_writing },
      autoSave: true
    });
    let isOtherToneCheck = tone_of_writing.filter(prop => prop.key === "other");
    if (isOtherToneCheck[0] && isOtherToneCheck[0].key === "other") {
      this.setState({
        isOtherTone: true
      });
    } else {
      this.setState({
        isOtherTone: false
      });
    }
  };

  _checkedValue(arrayFilter, arrayInput, key) {
    var result = arrayFilter.filter(item1 =>
      arrayInput.some(item2 => item2.key === item1.key)
    );
    return result.some(item => item.key === key);
  }

  _getOtherValue(arrayOther) {
    var otherValue = arrayOther.filter(value => {
      return value.key === "other";
    });
    return otherValue[0] && otherValue[0].name;
  }

  handleOnKeywordChange(keywords) {
    this.setState({
      data: {
        ...this.state.data,
        keywords
      },
      autoSave: true
    });
  }

  _handleOnSubmit = draftStatus => {
    const { data } = this.state;
    const id = this.state.id;
    if (this.state.tagsSelect) {
      data.tags = this.state.tagsSelect;
      let tagsArr = [];
      data.tags.map(prop => tagsArr.push(prop));
      data.tags = tagsArr;
    }
    data.draft = draftStatus;
    //Validate trống 3 trường quan trọng
    if (data.topic.length === 0) {
      this._warningAlert("Please fill Topic!");
      return;
    }
    if (
      data.angle_title === "" ||
      data.dead_line === "" ||
      data.assign_to_editor === "" ||
      data.assign_to_editor === null ||
      data.language === ""
    ) {
      this._warningAlert("Please fill * field!");
      return;
    }
    //Thiếu Public/Assign writer sẽ alert
    if (
      data.is_on_market === false &&
      (data.assigned_to === null || data.assigned_to === "")
    ) {
      this._warningAlert("Please fill Assign to writer/Public on market!");
      return;
    }

    let payload = { ...data };
    payload = omitBy(payload, isNil);
    payload = omitBy(payload, isEmpty);
    const payloadUpdate = omit(payload, ["_id"]);
    this.state.id
      ? this.props.updateBrief(id, payloadUpdate)
      : this.props.createNewBrief(payload);
    localStorage.removeItem("briefData");
    this.setState({
      alert: ""
    });
  };

  _handleOnSubmitBriefTranslate = () => {
    const briefData = this.state.data;
    const id = this.state.id;

    if (this.state.tagsSelect) {
      briefData.tags = this.state.tagsSelect;
      let tagsArr = [];
      briefData.tags.map(prop => tagsArr.push(prop.value));
      briefData.tags = tagsArr;
    }
    const data = {
      age_group: briefData.age_group,
      angle_title: briefData.angle_title,
      assign_to_editor: briefData.assign_to_editor,
      assigned_to: briefData.assigned_to,
      audience: briefData.audience,
      comprehension_level: briefData.comprehension_level,
      is_on_market: briefData.is_on_market,
      keywords: briefData.keywords,
      language: briefData.language,
      max_word: briefData.max_word,
      min_word: briefData.min_word,
      note: briefData.note,
      objectives: briefData.objectives,
      references: briefData.references,
      steps_to_write: briefData.steps_to_write,
      tags: briefData.tags,
      things_to_avoid: briefData.things_to_avoid,
      things_to_mention: briefData.things_to_mention,
      tone_of_writing: briefData.tone_of_writing,
      topic: briefData.topic,
      topics_of_concern: briefData.topics_of_concern,
      type_of_article: briefData.type_of_article
    };
    if (
      data.angle_title === "" ||
      data.dead_line === "" ||
      data.assign_to_editor === "" ||
      data.assign_to_editor === null
    ) {
      this._warningAlert(
        "Please fill Title - Time to write - Assign to Editor!"
      );
      return;
    }
    //Thiếu Public/Assign writer sẽ alert
    if (
      data.is_on_market === false &&
      (data.assigned_to === null || data.assigned_to === "")
    ) {
      this._warningAlert("Please fill Assign to writer/Public on market!");
      return;
    }
    // console.log(data);
    this.props.createTranslateBrief(id, data);
  };

  _handleOnDeletePost = id => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={"Attention"}
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnText={"Cancel"}
          cancelBtnBsStyle="default"
          onConfirm={() => {
            this.props.deletePost(id);
            this._hideAlert();
          }}
          onCancel={this._hideAlert}
        >
          Are you sure to delete this post ?
        </SweetAlert>
      )
    });
  };

  _warningAlert = message => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={"Attention"}
          confirmBtnText="OK"
          confirmBtnBsStyle="danger"
          btnSize=""
          onConfirm={() => {
            this._hideAlert();
          }}
          onCancel={this._hideAlert}
        >
          {message}
        </SweetAlert>
      )
    });
  };

  _autoSave = () => {
    this.autoSave = setInterval(() => {
      if (this.state.autoSave) {
        const { data } = this.state;
        if (this.state.tagsSelect && this.state.tagsSelect.length > 0) {
          data.tags = this.state.tagsSelect;
          let tagsArr = [];
          data.tags.map(prop => tagsArr.push(prop));
          data.tags = tagsArr;
        }
        localStorage.setItem("briefData", JSON.stringify(data));
        this.setState({ autoSave: false });
      }
    }, 3000);
  };

  _clearData = () => {
    this.setState({
      data: {
        audience: "",
        angle_title: "",
        assigned_to: null,
        writerID: null,
        assign_to_editor: "",
        type_of_article: [],
        topics_of_concern: [],
        steps_to_write: [],
        age_group: [],
        comprehension_level: [],
        tone_of_writing: [],
        keywords: [],
        topic: [],
        tags: [],
        objectives: "",
        references: "",
        things_to_avoid: "",
        things_to_mention: "",
        note: "",
        language: "",
        max_word: "",
        min_word: "",
        is_on_market: true
      },
      tagsSelect: []
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  _goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { post_info, topics_name, employee_list, editor_list } = this.props;
    const { data, optionCurrentEditor, isTranslate, titleWarn } = this.state;
    const jobKey = localStorage.getItem("key");
    const formatOptionLabel = ({ label, icon }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "10px" }}>
          <img
            src={icon ? HOST_URL + icon : AVA}
            alt=""
            width="32px"
            height="32px"
            style={{ borderRadius: 50 }}
          />
        </div>
        <div style={{ fontSize: "14px" }}>{label}</div>
      </div>
    );
    // Map staff list to react-select value
    const staffOptions =
      employee_list.data &&
      employee_list.data.docs.map(item => {
        return {
          key: item.id,
          value: item.id,
          label: item.name
        };
      });
    const validDate = current => {
      var yesterday = moment().subtract(1, "day");
      return current.isAfter(yesterday);
    };
    return (
      <>
        {this.state.alert}
        <Row>
          <Col md="11">
            <Card style={{ marginTop: "19px" }}>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0">
                  {this.state.id ? "Brief Detail" : "Add New Brief"}
                </h3>
              </CardHeader>
              <hr className="my-0" />
              {/* <div className="modal-seperate"></div> */}
              <CardBody>
                <Row>
                  <Col md="7">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="title"
                      >
                        Angle/Title{" "}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                      <Input
                        id="angle_title"
                        name="angle_title"
                        value={data.angle_title && data.angle_title}
                        type="text"
                        className={`d-inline mr-3 ` + (titleWarn ? "warn" : "")}
                        onChange={this._handleOnChange}
                      />
                      <div
                        className={`d-flex ${
                          titleWarn
                            ? "justify-content-between"
                            : "justify-content-end"
                        }`}
                      >
                        {titleWarn && (
                          <small className="text-red">
                            Title ideally shouldn't be longer than 60
                            characters, but you can still sumbit
                          </small>
                        )}
                        <small className="text-right">
                          {data.angle_title ? data.angle_title.length : "0"}/60
                        </small>
                      </div>
                    </FormGroup>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label d-block"
                            htmlFor="budget"
                          >
                            Budget
                          </label>
                          <Input
                            id="budget"
                            name="budget"
                            type="number"
                            className="d-inline mr-3"
                            value={data.budget && data.budget}
                            onChange={this._handleOnChange}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label d-block"
                            htmlFor="deadline"
                          >
                            Deadline
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <ReactDatetime
                            isValidDate={validDate}
                            value={
                              data.dead_line &&
                              data.dead_line.format &&
                              data.dead_line.format("DD/MM/YYYY")
                            }
                            inputProps={{
                              placeholder: "Choose a deadline"
                            }}
                            onChange={value =>
                              this._handleOnValueChange("dead_line", value)
                            }
                            timeFormat={"HH:mm"}
                            dateFormat={"DD/MM/YYYY"}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="topic"
                      >
                        Topic{" "}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                      <div className="custom-vietcetera-topic">
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          size="lg"
                          closeMenuOnSelect={true}
                          value={data.topic}
                          options={topics_name}
                          onChange={value =>
                            this._handleOnSelectTopicChange(
                              value.key,
                              value.name
                            )
                          }
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="language"
                      >
                        Language{" "}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                      <Select
                        name="language"
                        className="react-select"
                        classNamePrefix="react-select"
                        size="lg"
                        closeMenuOnSelect={true}
                        value={languageOption.filter(
                          ({ value }) => value === data.language
                        )}
                        options={languageOption}
                        onChange={value =>
                          this._handleOnValueChange("language", value.value)
                        }
                      />
                    </FormGroup>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label d-block"
                            htmlFor="assign"
                          >
                            Assign To Writer{" "}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <Select
                            name="assigned_to"
                            className="react-select"
                            classNamePrefix="react-select"
                            size="lg"
                            closeMenuOnSelect={true}
                            value={
                              data.assigned_to
                                ? staffOptions &&
                                  staffOptions.filter(
                                    ({ value }) => value === data.assigned_to
                                  )
                                : staffOptions &&
                                  staffOptions.filter(
                                    ({ value }) => value === data.writerID
                                  )
                            }
                            options={staffOptions}
                            formatOptionLabel={formatOptionLabel}
                            onChange={value =>
                              this._handleOnAssignToChange(
                                "assigned_to",
                                value.value
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label d-block"
                            htmlFor="assign"
                          >
                            Assign To Editor{" "}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          {jobKey === "editor" && (
                            <Select
                              name="assign_to_editor"
                              className="react-select"
                              classNamePrefix="react-select"
                              size="lg"
                              closeMenuOnSelect={true}
                              value={optionCurrentEditor.filter(
                                ({ value }) => value === data.assign_to_editor
                              )}
                              options={optionCurrentEditor}
                              formatOptionLabel={formatOptionLabel}
                              isDisabled
                            />
                          )}
                          {jobKey === "chiefeditor" && (
                            <Select
                              name="assign_to_editor"
                              className="react-select"
                              classNamePrefix="react-select"
                              size="lg"
                              closeMenuOnSelect={true}
                              value={editor_list.filter(
                                ({ value }) => value === data.assign_to_editor
                              )}
                              options={editor_list}
                              formatOptionLabel={formatOptionLabel}
                              onChange={value => {
                                this._handleOnAssignToEditorChange(
                                  "assign_to_editor",
                                  value.value
                                );
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="is_on_market"
                            name="is_on_market"
                            type="checkbox"
                            checked={data.is_on_market && data.is_on_market}
                            // disabled={this.state.disabledPublic}
                            onChange={this._handleCheckboxChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="is_on_market"
                          >
                            Public on Content Market for Freelance Writer
                            <span className="text-danger font-weight-bold">
                              {" "}
                              *
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />
                {/* <div className="modal-seperate"></div> */}
                <Row style={{ paddingLeft: "15px" }} className="mb-0">
                  <label
                    className="form-control-label d-block"
                    htmlFor="articleType"
                  >
                    Type of Article
                  </label>
                </Row>
                <Row className="mb-3">
                  {post_info.data.typesOfArticle &&
                    post_info.data.typesOfArticle.map((prop, key) => {
                      return (
                        <Col md="2" key={key}>
                          <div className="custom-control custom-checkbox mt-2">
                            <input
                              className="custom-control-input"
                              id={"article" + prop.key}
                              name="articletypes"
                              checked={this._checkedValue(
                                post_info.data.typesOfArticle,
                                data.type_of_article,
                                prop.key
                              )}
                              title={prop.key}
                              pattern={prop.name}
                              value={prop.name}
                              type="checkbox"
                              onChange={this._handleOnChangeCheckboxArticle.bind(
                                this
                              )}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"article" + prop.key}
                            >
                              {prop.name}
                            </label>
                          </div>
                        </Col>
                      );
                    })}
                </Row>

                <Row style={{ paddingLeft: "15px" }} className="mb-0">
                  <label
                    className="form-control-label d-block"
                    htmlFor="concernType"
                  >
                    Type of Concerns
                  </label>
                </Row>
                <Row className="mb-3">
                  {post_info.data.topicsOfConcern &&
                    post_info.data.topicsOfConcern.map((prop, key) => {
                      return (
                        <Col md="2" key={key}>
                          <div className="custom-control custom-checkbox mt-2">
                            <input
                              className="custom-control-input"
                              id={"concern" + prop.key}
                              type="checkbox"
                              name="concerntypes"
                              checked={this._checkedValue(
                                post_info.data.topicsOfConcern,
                                data.topics_of_concern,
                                prop.key
                              )}
                              title={prop.key}
                              value={prop.name}
                              pattern={prop.name}
                              onChange={this._handleOnChangeCheckboxConcern.bind(
                                this
                              )}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"concern" + prop.key}
                            >
                              {prop.name}
                            </label>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <Row className="mt-4">
                  <Col md="12">
                    <label
                      className="form-control-label d-block mt-4"
                      htmlFor="objectives"
                    >
                      Objectives
                    </label>
                    <Input
                      id="objectives"
                      name="objectives"
                      type="textarea"
                      rows="5"
                      className="d-inline mr-3"
                      value={data.objectives && data.objectives}
                      placeholder="Describe your objectives"
                      onChange={e =>
                        this._handleOnContentChange(
                          "objectives",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <label
                  className="form-control-label d-block"
                  htmlFor="targetAudience"
                  style={{ marginTop: "32px" }}
                >
                  Target Audience
                </label>
                <Row className="mt-3">
                  <Col md="2">
                    <label
                      className="form-control-label mb-0 mt-1"
                      htmlFor="agegroup"
                    >
                      Age Group:
                    </label>
                  </Col>
                  {post_info.data.ageGroups &&
                    post_info.data.ageGroups.map((prop, key) => {
                      return (
                        <Col md="2" key={key}>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id={"age" + prop.key}
                              type="checkbox"
                              name="agetypes"
                              title={prop.key}
                              value={prop.name}
                              pattern={prop.name}
                              checked={this._checkedValue(
                                post_info.data.ageGroups,
                                data.age_group,
                                prop.key
                              )}
                              onChange={this._handleOnChangeCheckboxAge.bind(
                                this
                              )}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"age" + prop.key}
                            >
                              {prop.name}
                            </label>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <Row className="mt-3">
                  <Col md="2">
                    <label
                      className="form-control-label mb-0 mt-1"
                      htmlFor="comLevel"
                    >
                      Comprehension Level:
                    </label>
                  </Col>
                  {post_info.data.comprehensionLevels &&
                    post_info.data.comprehensionLevels.map((prop, key) => {
                      return (
                        <Col md="2" key={key}>
                          <div className="custom-control custom-checkbox mt-2">
                            <input
                              className="custom-control-input"
                              id={"comlevels" + prop.key}
                              type="checkbox"
                              name="compretypes"
                              title={prop.key}
                              value={prop.name}
                              pattern={prop.name}
                              checked={this._checkedValue(
                                post_info.data.comprehensionLevels,
                                data.comprehension_level,
                                prop.key
                              )}
                              onChange={this._handleOnChangeCheckboxCompreLevels.bind(
                                this
                              )}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"comlevels" + prop.key}
                            >
                              {prop.name}
                            </label>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="audience"
                      >
                        Describe Your Audience
                      </label>
                      <Input
                        id="audience-input"
                        rows="5"
                        type="textarea"
                        value={data.audience && stripHTMLTag(data.audience)}
                        name="audience"
                        onChange={e =>
                          this._handleOnContentChange(
                            "audience",
                            e.target.value
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="references"
                      >
                        References
                      </label>
                      <Input
                        id="references-input"
                        rows="5"
                        type="textarea"
                        value={data.references && stripHTMLTag(data.references)}
                        name="references"
                        onChange={e =>
                          this._handleOnContentChange(
                            "references",
                            e.target.value
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-3" style={{ paddingLeft: "15px" }}>
                  <label className="form-control-label d-block" htmlFor="step">
                    Steps Required To Write This Article
                  </label>
                </Row>
                <Row className="mb-3">
                  {post_info.data.stepsToWrite &&
                    post_info.data.stepsToWrite.map((prop, key) => {
                      return (
                        <Col md="2" key={key}>
                          <div className="custom-control custom-checkbox mt-2">
                            <input
                              className="custom-control-input"
                              id={"step" + prop.key}
                              name="stepsWrite"
                              title={prop.key}
                              value={prop.name}
                              pattern={prop.name}
                              type="checkbox"
                              checked={this._checkedValue(
                                post_info.data.stepsToWrite,
                                data.steps_to_write,
                                prop.key
                              )}
                              onChange={this._handleOnChangeCheckboxStepWrite.bind(
                                this
                              )}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"step" + prop.key}
                            >
                              {prop.key === "other" ? "Other" : prop.name}
                            </label>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                {this.state.isOtherSteps && (
                  <Row className="mt-3">
                    <Col md="12">
                      <Input
                        id="stepsOther"
                        name="stepsOther"
                        type="text"
                        value={this._getOtherValue(data.steps_to_write)}
                        className="d-inline mr-3"
                        placeholder="Insert other steps here"
                        onChange={this._handleOnInputStepsOtherChange}
                      />
                    </Col>
                  </Row>
                )}

                <Row className="mt-4">
                  <Col md="12">
                    <label
                      className="form-control-label d-block"
                      htmlFor="things_to_avoid"
                    >
                      Things To Avoid
                    </label>
                    <Input
                      id="things_to_avoid-input"
                      rows="5"
                      type="textarea"
                      value={
                        data.things_to_avoid &&
                        stripHTMLTag(data.things_to_avoid)
                      }
                      name="things_to_avoid"
                      onChange={e =>
                        this._handleOnContentChange(
                          "things_to_avoid",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="12">
                    <label
                      className="form-control-label d-block"
                      htmlFor="things_to_mention"
                    >
                      Things To Mention
                    </label>
                    <Input
                      id="things_to_mention-input"
                      rows="5"
                      type="textarea"
                      value={
                        data.things_to_mention &&
                        stripHTMLTag(data.things_to_mention)
                      }
                      name="things_to_mention"
                      onChange={e =>
                        this._handleOnContentChange(
                          "things_to_mention",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-4" style={{ paddingLeft: "15px" }}>
                  <label className="form-control-label d-block" htmlFor="tone">
                    Tone of Writing
                  </label>
                </Row>
                <Row className="mb-3">
                  {post_info.data.tonesOfWriting &&
                    post_info.data.tonesOfWriting.map((prop, key) => {
                      return (
                        <Col md="2" key={key}>
                          <div className="custom-control custom-checkbox mt-2">
                            <input
                              className="custom-control-input"
                              id={"tone" + prop.key}
                              type="checkbox"
                              name="tonetypes"
                              title={prop.key}
                              value={prop.name}
                              pattern={prop.name}
                              checked={this._checkedValue(
                                post_info.data.tonesOfWriting,
                                data.tone_of_writing,
                                prop.key
                              )}
                              onChange={this._handleOnChangeCheckboxToneWrite.bind(
                                this
                              )}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"tone" + prop.key}
                            >
                              {prop.name}
                            </label>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                {this.state.isOtherTone && (
                  <Col md="12">
                    <Row className="mt-3">
                      <Input
                        id="toneothers"
                        name="toneothers"
                        type="text"
                        className="d-inline"
                        value={this._getOtherValue(data.tone_of_writing)}
                        placeholder="Describe your desired tone of voice."
                        onChange={this._handleOnInputToneOtherChange}
                      />
                    </Row>
                  </Col>
                )}

                <Row className="mt-3">
                  <Col md="12">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="note"
                      >
                        Note
                      </label>
                      <Input
                        id="note-input"
                        rows="5"
                        type="textarea"
                        value={data.note && stripHTMLTag(data.note)}
                        name="note"
                        onChange={e =>
                          this._handleOnContentChange("note", e.target.value)
                        }
                      />
                      {/* <CustomEditor
                        menubar="false"
                        toolbar="false"
                        content={data.note && data.note}
                        onChangeContent={value =>
                          this._handleOnContentChange("note", value)
                        }
                      /> */}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="words"
                      >
                        Word Counts
                      </label>
                      <Row>
                        <Col md="6">
                          <Input
                            id="min_word"
                            name="min_word"
                            type="number"
                            className="d-inline mr-3"
                            placeholder="Min Words"
                            onChange={this._handleOnChange}
                            value={data.min_word && data.min_word}
                          />
                        </Col>
                        <Col md="6">
                          <Input
                            id="max_word"
                            name="max_word"
                            type="number"
                            className="d-inline mr-3"
                            placeholder="Max Words"
                            onChange={this._handleOnChange}
                            value={data.max_word && data.max_word}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="float-left">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="is_outline_skippable"
                      name="is_outline_skippable"
                      checked={
                        data.is_outline_skippable && data.is_outline_skippable
                      }
                      type="checkbox"
                      onChange={this._handleCheckboxChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="is_outline_skippable"
                    >
                      Senior Writer Skip Outline
                    </label>
                  </div>
                </div>
                <div className="brief-toolbar-right">
                  {/* {this.state.id && (
                    <>
                      <Button
                        type="button"
                        style={{
                          backgroundColor: "transparent",
                          color: "#fb6340",
                          border: "1px solid #fb6340"
                        }}
                        id="tooltip-btn-delete"
                        onClick={() => this._handleOnDeletePost(this.state.id)}
                      >
                        <i className="fa fa-trash-alt"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="tooltip-btn-delete"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </>
                  )} */}
                  {!isTranslate && (
                    <>
                      <Button
                        onClick={() => this._handleOnSubmit("HIDDEN")}
                        type="button"
                        color="info"
                        id="tooltip-btn-draft"
                        className="mr-0 mb-3"
                      >
                        <i className="fa fa-file-alt"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="tooltip-btn-draft"
                      >
                        Save Draft
                      </UncontrolledTooltip>{" "}
                    </>
                  )}

                  {isTranslate ? (
                    <>
                      <Button
                        onClick={() => this._handleOnSubmitBriefTranslate()}
                        type="button"
                        color="success"
                        id="tooltip-btn-submit"
                        className="ml-0"
                      >
                        <i className="fab fa-telegram-plane"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="tooltip-btn-submit"
                        placement="bottom"
                      >
                        Submit
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => this._handleOnSubmit("SHOW")}
                        type="button"
                        color="success"
                        id="tooltip-btn-submit"
                        className="ml-0"
                      >
                        <i className="fab fa-telegram-plane"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="tooltip-btn-submit"
                        placement="bottom"
                      >
                        Submit
                      </UncontrolledTooltip>
                    </>
                  )}
                </div>
                <h5 className="text-center text-danger font-weight-bold my-2">
                  {this.state.message}
                </h5>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    post_info: state.postReducer.post_info,
    post_detail: state.postReducer.post_detail,
    topics_name: state.postReducer.topics_name,
    topics_name_select: state.postReducer.topics_name_select,
    tags_by_topic: state.postReducer.tags_by_topic,
    employee_list: state.employeeReducer.employee_list,
    editor_list: state.postReducer.editor_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPostInfo: data => {
      dispatch(actGetPostInfoRequestV2(data));
    },
    //Get list staff
    getListStaff: query => {
      dispatch(actGetListStaffRequest(query));
    },
    getPostDetail: id => {
      dispatch(actGetPostDetailRequest(id));
    },
    // Get brief detail v2
    getBriefDetail: id => {
      dispatch(actGetBriefDetailRequest(id));
    },
    getTagsByTopic: id => {
      dispatch(actGetTagsByTopicIDRequest(id));
    },
    createNewBrief: data => {
      dispatch(actCreateNewBriefRequest(data));
    },
    updateBrief: (id, data) => {
      dispatch(actUpdatePostRequest(id, data));
    },
    deletePost: id => {
      dispatch(actDeletePostRequest(id));
    },
    getEditorList: () => {
      dispatch(actGetEditorListRequest());
    },
    createTranslateBrief: (id, data) => {
      dispatch(actCreateTranslateBriefRequest(id, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorAddNewPostTable);
EditorAddNewPostTable.propTypes = {
  post_info: PropTypes.object,
  post_detail: PropTypes.object,
  topics_name: PropTypes.array,
  topics_name_select: PropTypes.array,
  tags_by_topic: PropTypes.array,
  employee_list: PropTypes.array,
  editor_list: PropTypes.array,
  getPostInfo: PropTypes.func,
  getPostDetail: PropTypes.func,
  getBriefDetail: PropTypes.func,
  getTagsByTopic: PropTypes.func,
  createNewPost: PropTypes.func,
  createNewBrief: PropTypes.func,
  updateBrief: PropTypes.func,
  deletePost: PropTypes.func,
  getEditorList: PropTypes.func,
  getListStaff: PropTypes.func,
  history: PropTypes.object,
  createTranslateBrief: PropTypes.func
};
EditorAddNewPostTable.defaultProps = {};
