import React, { Component } from "react";
import { get } from "lodash";
import { Card, CardHeader, Table, Col, Row, CardFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatStringToDate } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import { actGetArticlesInProgressListRequest } from "../../actions/editorArticle.action";
import Avatar from "../Avatar/Avatar";

class ArticlesInProgressTable extends Component {
  state = {
    tabs: 1,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getArticlesInProgress();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getArticlesInProgress = () => {
    const params = this.state.params;
    this.props.getArticlesInProgress(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getArticlesInProgress(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }``
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getArticlesInProgress(paramsSort);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/editor/content-request/${id}`);
  };

  render() {
    const { articles_in_progress } = this.props;
    const page = articles_in_progress.data && articles_in_progress.data.page;
    const totalPages =
      articles_in_progress.data && articles_in_progress.data.totalPages;
    const totalDocs =
      articles_in_progress.data && articles_in_progress.data.totalDocs;

    const renderStatus = status => {
      switch (status) {
        case "CONTENT_WRITING":
          return (
            <>
              <span className="status-progress">●</span>
              <span>Needs Writing</span>
            </>
          );
        case "CONTENT_SUBMITTED":
          return (
            <>
              <span className="status-waiting">●</span>
              <span>Waiting for Approval</span>
            </>
          );
        default:
      }
      return null;
    };
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Article In Progress List</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {articles_in_progress.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th className="text-center">Dual Language</th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("writer")}
                        >
                          Writer
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("start_date")}
                        >
                          Writer accepts on
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("dead_line")}
                        >
                          Deadline
                        </th>
                        <th>Status</th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {articles_in_progress.data
                        ? articles_in_progress.data.docs.map((prop, key) => {
                            return (
                              <>
                                <tr
                                  key={key}
                                  onClick={e =>
                                    this._handleOnCellClick(e, prop.id)
                                  }
                                  className="cursor-pointer"
                                >
                                  <th scope="row">
                                    {this._text_truncate(
                                      prop.angle_title,
                                      50,
                                      "..."
                                    )}
                                  </th>
                                  <td className="text-center text-success">
                                    {prop.dualLanguage_article !== null &&
                                      prop.dualLanguage_article && (
                                        <i
                                          className="fa fa-check-circle"
                                          style={{ fontSize: 16 }}
                                        ></i>
                                      )}
                                  </td>
                                  <td className="table-user">
                                    <Avatar
                                      src={get(prop, "writer.avatar")}
                                      className="d-inline-block mr-3"
                                    />
                                    <b>{prop.writer ? prop.writer.name : ""}</b>
                                  </td>
                                  <td>{formatStringToDate(prop.createdAt)}</td>
                                  <td>{formatStringToDate(prop.dead_line)}</td>
                                  <td>{renderStatus(prop.status_article)}</td>
                                  <td>
                                    {prop
                                      ? prop.topic.map(topic => {
                                          return (
                                            <>
                                              <div className="mt-2  _topic">
                                                {this._text_truncate(
                                                  topic.name
                                                    ? topic.name
                                                    : topic.label,
                                                  20,
                                                  "..."
                                                )}
                                              </div>
                                            </>
                                          );
                                        })
                                      : ""}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                )}

                {articles_in_progress.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    articles_in_progress: state.editorArticleReducer.articles_in_progress
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getArticlesInProgress: params => {
      dispatch(actGetArticlesInProgressListRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticlesInProgressTable);
ArticlesInProgressTable.propTypes = {
  articles_in_progress: PropTypes.object,
  getArticlesInProgress: PropTypes.func
};
ArticlesInProgressTable.defaultProps = {};
