import * as Types from "../variables/actionTypes.jsx";

var initialState = {
  menu_list: {},
  mainMenu_list: [],
  modalTopic_list: [],
  group_detail: {},
  topic_detail: {},
  topic_list: [],
  topic_list_by_group: [],
  group_topic_list: [],
  article_by_topic: [],
  all_topic_select: []
};

const topicReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_MENU_LIST:
      return Object.assign({}, state, {
        mainMenu_list: action.data
      });
    case Types.GET_TOPIC_LIST:
      var topicList = action.data;
      topicList.map(prop => {
        prop.title = prop.name;
        prop.value = prop.name;
        prop.key = prop._id;
        return prop;
      });
      state = {
        ...state,
        modalTopic_list: topicList
      };
      return { ...state };
    case Types.GET_ALL_TOPIC_LIST:
      var allTopicList = action.data;
      allTopicList.map(prop => {
        prop.title = prop.name;
        prop.value = prop.name;
        return prop;
      });
      state = {
        ...state,
        topic_list: allTopicList
      };
      return { ...state };
    case Types.GET_GROUP_TOPIC:
      state = {
        ...state,
        group_topic_list: action.data.map(prop => {
          prop.value = prop._id;
          prop.label = prop.name;
          return prop;
        })
      };
      return { ...state };
    case Types.GET_TOPIC_BY_GROUP:
      state = { ...state, topic_list_by_group: action.data };
      return { ...state };
    case Types.GET_GROUP_DETAIL:
      state = { ...state, group_detail: action.data };
      return { ...state };
    case Types.GET_TOPIC_DETAIL:
      state = { ...state, topic_detail: action.data };
      return { ...state };
    case Types.GET_ARTICLE_BY_TOPIC:
      state = { ...state, article_by_topic: action.data };
      return { ...state };
    case Types.RESET_MENU_LIST:
      state = {
        ...state,
        mainMenu_list: []
      };
      return { ...state };
    case Types.GET_TOPIC_SELECT:
      state = {
        ...state,
        all_topic_select: action.data
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default topicReducer;
