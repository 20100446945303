import React, { Component } from "react";
import { Card, CardHeader, Table, Col, Row, CardFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatStringToDate } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import { actGetEditorWriterInfoByIDRequest } from "../../actions/editorArticle.action";
import Avatar from "../Avatar/Avatar";
import ReactHtmlParser from "react-html-parser";

// const URL_IMG = "http://35.185.181.184/";
class EditorWriterInfoTable extends Component {
  state = {
    id: window.location.pathname.split("/")[4],
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getEditorWriterProfile();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getEditorWriterProfile = () => {
    const { params } = this.state;
    this.props.getEditorWriterProfileByID(this.state.id, params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getEditorWriterProfileByID(this.state.id, params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 50) {
      return `${str[0].substring(0, 50)}...`;
    } else {
      return str[0].substring(0, 50);
    }
  };

  _sortByColumn = typeColumn => {
    if (this.state.number === -1) {
      this.setState({
        number: 1
      });
    } else {
      this.setState({
        number: -1
      });
    }
    let type = typeColumn;
    this.props.getEditorWriterProfileByID(
      this.state.id,
      this.state.params,
      type,
      this.state.number
    );
  };

  _renderStatus = status => {
    switch (status) {
      case "WORKING":
        return (
          <>
            <div>
              <span className="status-new">●</span>
              <span>Working</span>
            </div>
          </>
        );
      case "COMPLETED":
        return (
          <>
            <span className="status-progress">●</span>
            <span>Ready to publish</span>
          </>
        );
      case "STOP_WORKING":
        return (
          <>
            <span className="status-new">●</span>
            <span>Stop working</span>
          </>
        );
      case "NEW":
        return (
          <>
            <div>
              <span className="status-new">●</span>
              <span>New</span>
            </div>
          </>
        );
      case "OUTLINE_SUBMITTED":
        return (
          <>
            <span className="status-waiting">●</span>
            <span>Waiting for Approval</span>
          </>
        );
      case "CONTENT_SUBMITTED":
        return (
          <>
            <span className="status-waiting">●</span>
            <span>Waitting for Approval</span>
          </>
        );
      case "PUBLISHED":
        return (
          <>
            <span className="status-completed">●</span>
            <span>Published</span>
          </>
        );
      case "EDITING":
        return (
          <>
            <span className="status-new">●</span>
            <span>Needs Editing</span>
          </>
        );
      case "CONTENT_WRITING":
        return (
          <>
            <span className="status-progress">●</span>
            <span>Articles - Need Writing</span>
          </>
        );
      case "OUTLINE_WRITING":
        return (
          <>
            <span className="status-progress">●</span>
            <span>Outlines - Need Writing</span>
          </>
        );
      case "UNPUBLISHED":
        return (
          <>
            <span className="status-cancel">●</span>
            <span>Unpublished</span>
          </>
        );

      case "REJECTED":
        return (
          <>
            <span className="status-cancel">●</span>
            <span>Rejected</span>
          </>
        );
      default:
    }
    return null;
  };

  render() {
    const { editor_writer_info_id } = this.props;
    const page = editor_writer_info_id.data && editor_writer_info_id.data.page;
    const totalPages =
      editor_writer_info_id.data && editor_writer_info_id.data.totalPages;
    const totalDocs =
      editor_writer_info_id.data && editor_writer_info_id.data.totalDocs;

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6 d-flex align-item-center">
                    <Avatar
                      src={
                        editor_writer_info_id.data &&
                        editor_writer_info_id.data.docs[0] &&
                        editor_writer_info_id.data.docs[0].writer.avatar
                      }
                      className="d-inline-block mr-3"
                    />
                    <h3 className="mb-0 my-auto">
                      {editor_writer_info_id.data &&
                        editor_writer_info_id.data.docs[0] &&
                        editor_writer_info_id.data.docs[0].writer.name}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {editor_writer_info_id.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("start_date")}
                        >
                          Writer accepts on
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("status")}
                        >
                          Status
                        </th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {editor_writer_info_id.data
                        ? editor_writer_info_id.data.docs.map((prop, key) => {
                            return (
                              <>
                                <tr key={key}>
                                  <th>
                                    {this.limitTitle(
                                      ReactHtmlParser(prop.angle_title)
                                    )}
                                  </th>
                                  <td>{formatStringToDate(prop.start_date)}</td>
                                  <td>
                                    {this._renderStatus(prop.status_article)}
                                  </td>
                                  <td>
                                    {prop
                                      ? prop.topic.map(topic => {
                                          return (
                                            <>
                                              {topic ? (
                                                <div className="mt-2  _topic">
                                                  {this._text_truncate(
                                                    topic.label
                                                      ? topic.label
                                                      : topic.name,
                                                    20,
                                                    "..."
                                                  )}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          );
                                        })
                                      : ""}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                )}

                {editor_writer_info_id.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    editor_writer_info_id: state.editorArticleReducer.editor_writer_info_id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getEditorWriterProfileByID: (id, params, type, number) => {
      dispatch(actGetEditorWriterInfoByIDRequest(id, params, type, number));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorWriterInfoTable);
EditorWriterInfoTable.propTypes = {
  editor_writer_info_id: PropTypes.object,
  getEditorWriterProfileByID: PropTypes.func
};
EditorWriterInfoTable.defaultProps = {};
