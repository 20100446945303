import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import WriterWritingDetail from "../../../components/Detail/WriterWritingDetail";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
class WriterWriting extends Component {
  state = {
    pathnameRoutes: window.location.pathname.split("/")[2],
  };
  render() {
    const props = this.props;
    const { pathnameRoutes } = this.state;
    return (
      <>
        {pathnameRoutes === "writer" ? (
          <CardsHeader
            name="My Content"
            content="writerWriting"
            parentName="Article"
          />
        ) : pathnameRoutes === "chief" ? (
          <CardsHeaderChief
            name="Chief Editing"
            content="chiefEditing"
            parentName="Article"
          />
        ) : (
          <CardsHeaderEditor
            name="Editor Editing"
            content="editorEditing"
            parentName="Article"
          />
        )}

        <Container className="mt--7" fluid>
          <WriterWritingDetail {...props} />
        </Container>
      </>
    );
  }
}
WriterWriting.propTypes = {
  content: PropTypes.string,
};
export default WriterWriting;
