import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Input, Select, Button } from "antd";

import {
  actTagSearching,
  actTagSearchingLeftEmpty
} from "../../actions/tagmanagement.action";

import "./styles.scss";

const { Search } = Input;
const { Option } = Select;

function HeaderSearchBar({
  mainButtonTitle,
  onAddNewTag,
  onChangeLanguageProp,
  defaultLanguage,
  typeOfTags
}) {
  const dispatch = useDispatch();

  let timeOutData = null;
  const [languageState, setLanguageState] = useState(defaultLanguage);
  const [searchCharacter, setSearchCharacter] = useState("");
  const [inputDisabled, setInputDisable] = useState(true);
  const [inputFieldLoading, setInputFieldLoading] = useState(false);

  const tableGroupNameStaticData = [
    "Series",
    "Brand",
    "Topics",
    "Need Review",
    "New Tags"
  ];

  useEffect(() => {
    if (searchCharacter.length > 0) {
      setInputFieldLoading(true);
    } else {
      setInputFieldLoading(false);
    }
  }, [searchCharacter]);

  useEffect(() => {
    if (!inputFieldLoading && searchCharacter.length > 0) {
      for (let i = 0; i < tableGroupNameStaticData.length; i += 1) {
        dispatch(
          actTagSearching({
            groupMainTitle: tableGroupNameStaticData[i],
            type: typeOfTags,
            language: languageState,
            searchCharacter
          })
        );
      }
    }
  }, [inputFieldLoading])

  const onChangeLanguage = language => {
    setLanguageState(language);
    onChangeLanguageProp(language);
  };

  const onSearch = e => {
    const { value } = e.target;

    setSearchCharacter(value);
  };

  const onKeyUpSearchField = () => {
    clearTimeout(timeOutData);

    if (searchCharacter.length === 0) {
      dispatch(actTagSearchingLeftEmpty());

      return;
    }

    timeOutData = setTimeout(() => {
      setInputFieldLoading(false);
    }, 2000);
  };

  const onKeyPressSearchField = () => {
    clearTimeout(timeOutData);
    setInputFieldLoading(true);
  };

  return (
    <>
      <Row
        className="header-search-bar-container"
        type="flex"
        justify="space-between"
      >
        <Col span={8}>
          <Search
            disabled={inputDisabled}
            onKeyUp={() => onKeyUpSearchField()}
            onKeyPress={() => onKeyPressSearchField()}
            onChange={e => onSearch(e)}
            onMouseOver={() => setInputDisable(false)}
            onMouseLeave={() => setInputDisable(true)}
            loading={inputFieldLoading}
            value={searchCharacter}
            placeholder="Search tag"
          />
        </Col>

        <Col>
          <Row type="flex">
            <Col>
              <Button onClick={onAddNewTag} type="primary">
                {mainButtonTitle}
              </Button>
            </Col>
            <Col>
              <Select
                className="remove-border-selector"
                defaultValue={languageState}
                onChange={onChangeLanguage}
              >
                {languageState === "VN" ? (
                  <Option value={"EN"}>EN</Option>
                ) : (
                  <Option value={"VN"}>VN</Option>
                )}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default HeaderSearchBar;
