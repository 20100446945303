import * as Types from "../variables/actionTypes.jsx";
import { any } from "prop-types";

var initialState = {
  article: {
    data: {
      docs: []
    }
  },
  article_detail: {},
  article_byid: {},
  outline_approval: {
    data: {
      docs: []
    }
  },
  content_approval: {
    data: {
      docs: []
    }
  },
  outline_detail_id: {
    data: {
      angle_title: "",
      topic: [],
      tags: [],
      type_of_article: [],
      topics_of_concern: [],
      objectives: "",
      age_group: [],
      comprehension_level: [],
      references: "",
      steps_to_write: [],
      things_to_avoid: "",
      things_to_mention: "",
      tone_of_writing: [],
      time_to_write: any,
      note: "",
      keywords: [],
      outline_content: ""
    }
  },
  in_progress: {
    data: {
      docs: []
    }
  },
  assigned_post: {
    data: {
      docs: []
    }
  },
  outline_in_progress: {
    data: {
      docs: []
    }
  },
  articles_in_progress: {
    data: {
      docs: []
    }
  },
  editor_today_deadline: {
    data: []
  },
  editor_pulished_articles: {
    data: {
      docs: []
    }
  },
  editor_editing_articles: {
    data: {
      docs: []
    }
  },
  editor_writer_profile: {
    data: {
      docs: []
    }
  },
  editor_writer_info_id: {
    data: {
      docs: []
    }
  },
  draft_briefs: {
    data: {
      docs: []
    }
  },
  all_briefs: {
    data: {
      docs: []
    }
  },
  reject_assigned: {
    data: {
      docs: []
    }
  }
};

const editorArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_AVAILABLE_REQUEST_LIST:
      state = {
        ...state,
        article: action.data
      };
      return { ...state };
    case Types.GET_AVAILABLE_REQUEST_DETAIL:
      state = {
        ...state,
        article_detail: action.data
      };
      return { ...state };
    case Types.GET_AVAILABLE_REQUEST_BYID:
      return { ...state, article_byid: action.data };
    case Types.GET_OUTLINE_APPROVAL_LIST:
      state = {
        ...state,
        outline_approval: action.data
      };
      return { ...state };
    case Types.GET_CONTENT_APPROVAL_LIST:
      state = {
        ...state,
        content_approval: action.data
      };
      return { ...state };
    case Types.GET_OUTLINE_DETAIL_BY_ID:
      state = {
        ...state,
        outline_detail_id: action.data
      };
      return { ...state };
    case Types.GET_IN_PROGRESS_ARTICLE:
      state = {
        ...state,
        in_progress: action.data
      };
      return { ...state };
    case Types.GET_ASSIGNED_POST:
      state = {
        ...state,
        assigned_post: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_OUTLINE_IN_PROGRESS:
      state = {
        ...state,
        outline_in_progress: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_ARTICLES_IN_PROGRESS:
      state = {
        ...state,
        articles_in_progress: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_TODAY_DEADLINE:
      state = {
        ...state,
        editor_today_deadline: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_PUBLISHED_ARTICLES:
      state = {
        ...state,
        editor_pulished_articles: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_EDITING_ARTICLES:
      state = {
        ...state,
        editor_editing_articles: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_WRITER_PROFILE:
      state = {
        ...state,
        editor_writer_profile: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_WRITER_INFO_BY_ID:
      state = {
        ...state,
        editor_writer_info_id: action.data
      };
      return { ...state };
    case Types.GET_DRAFT_BRIEFS:
      state = {
        ...state,
        draft_briefs: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_ALL_BRIEFS:
      state = {
        ...state,
        all_briefs: action.data
      };
      return { ...state };
    case Types.GET_EDITOR_REJECT_ASSIGNED:
      state = {
        ...state,
        reject_assigned: action.data
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default editorArticleReducer;
