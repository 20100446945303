import get from "lodash/get"
import moment from "moment";
export const columns = [
  {
    title: "Topic",
    dataIndex: "topic.name[0]",
  },
  {
    title: "# Number Articles",
    dataIndex: "numberArticle",
  },
  {
    title: "# Views",
    dataIndex: "total",
  },
];

export const handleTimeReigterUser = function (res, viewMode) {
  let data = [];
  const label = res.data.users.map(e => {
    data.push(e.count);
    if (viewMode === 'day') {
      return moment(e.createdAt).format("DD-MMM");
    }
    if (viewMode === 'week') {
      const start = moment().day('Monday').year(e._id.year).week(e._id.week).format('DD');
      const end = moment().day('Sunday').year(e._id.year).week(e._id.week + 1).format('DD MMM');;
      return start + '-' + end;
    }
    if (viewMode === 'month') {
      return moment(e.createdAt).format("MM-YYYY")  
    }
  })
  const options = {
    responsive: true,
    /* title: {
      display: true,
      text: 'Chart.js Line Chart'
    }, */
    legend: {
      display: false
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
        }
      }]
    }
  }
  const config = {
    data: {
      labels: label,
      datasets: [{
        backgroundColor: '#001DBC',
        borderColor: '#001DBC',
        pointRadius: 0,
        data: data,
        fill: false,
      }]
    },
    options
  };
  return config;
}