import React, { Component } from "react";
import { Card, CardHeader, CardFooter, Table, Col, Row } from "reactstrap";
import CustomPagination from "../Pagination/Pagination";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetArticleListRequest } from "../../actions/editorArticle.action";
import ReactHtmlParser from "react-html-parser";
class EditorAvailableBriefsTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getArticleList();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getArticleList = () => {
    const params = this.state.params;
    this.props.getArticleList(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getArticleList(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 50) {
      return `${str[0].substring(0, 50)}...`;
    } else {
      return str[0].substring(0, 50);
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getArticleList(paramsSort);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/editor/edit-brief/${id}`);
  };

  render() {
    const { article } = this.props;
    const page = article.data && article.data.page;
    const totalPages = article.data && article.data.totalPages;
    const totalDocs = article.data && article.data.totalDocs;
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Available Briefs List</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {this.props.article.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
                {this.props.article.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          onClick={() => this._sortByColumn("angle_title")}
                        >
                          Title
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("time_to_write")}
                        >
                          Time to write
                        </th>
                        <th scope="col">TOPIC</th>
                      </tr>
                    </thead>

                    <tbody className="list">
                      {this.props.article &&
                        this.props.article.data &&
                        this.props.article.data.docs.map((prop, key) => {
                          return (
                            <>
                              <tr
                                key={key}
                                onClick={e =>
                                  this._handleOnCellClick(e, prop.id)
                                }
                                className="cursor-pointer"
                              >
                                <th scope="row">
                                  {this._text_truncate(
                                    ReactHtmlParser(prop.angle_title),
                                    50,
                                    "..."
                                  )}
                                </th>
                                <td>
                                  {prop.time_to_write &&
                                    prop.time_to_write + " day(s)"}
                                </td>
                                <td>
                                  {prop
                                    ? prop.topic.map(topic => {
                                      return (
                                        <>
                                          <div className="mt-2  _topic">
                                            {this._text_truncate(
                                              topic.label
                                                ? topic.label
                                                : topic.name,
                                              40,
                                              "..."
                                            )}
                                          </div>
                                        </>
                                      );
                                    })
                                    : ""}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    article: state.editorArticleReducer.article
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getArticleList: params => {
      dispatch(actGetArticleListRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorAvailableBriefsTable);
EditorAvailableBriefsTable.propTypes = {
  article: PropTypes.object,
  getArticleList: PropTypes.func
};
EditorAvailableBriefsTable.defaultProps = {};
