import React, { useState } from "react";
import moment from "moment";
import { Menu, Dropdown } from "antd";
import { ReactComponent as ArrowDown } from "./arrowdown.svg";


const DropDownDate = function ({ onChange, defaultValue = '7 days' }) {
  const [rangeShow, setRangeShow] = useState(null);
  const onChangeDate = function(e) {
    let startDate = moment();
    let endDate = moment();
    const monthStartDate = startDate.month();
    const weekStartDate = startDate.week();
    const weekEndDate = endDate.week();
    const yearStartDate  = endDate.year();
    const yearEndDate = endDate.year();

    let viewMode = 'day';
    switch (e.key) {
      case "7 days":
        startDate = startDate.subtract("days", 7);
        break;
      case "14 days":
        startDate = startDate.subtract("days", 15);
        break;
      case "30 days":
        viewMode = 'week'
        startDate.day('Monday').year(yearStartDate).week(weekStartDate - 4);
        endDate.day("Sunday").year(yearEndDate).week(weekStartDate - 1);
        break;
      case "3 months":
        viewMode = 'month'
        startDate = startDate.subtract('months', 3)
        break;
      case "6 months":
        viewMode = 'month'
        startDate = startDate.subtract('months', 6)
        break;
      case "12 months":
        viewMode = 'month'
        startDate = startDate.subtract('months', 12)
        break;
    }
    endDate.subtract('days', 1)
    setRangeShow(e.key);
    onChange({ startDate, endDate, viewMode });
  };
  const RangeDate = (
    <Menu>
      <Menu.Item onClick={onChangeDate} key="7 days">
        Last 7 days
      </Menu.Item>
      <Menu.Item onClick={onChangeDate} key="14 days">
        Last 14 days
      </Menu.Item>
      <Menu.Item onClick={onChangeDate} key="30 days">
        Last 30 days
      </Menu.Item>
      <Menu.Item onClick={onChangeDate} key="3 months">
        Last 3 months
      </Menu.Item>
      <Menu.Item onClick={onChangeDate} key="6 months">
        Last 6 months
      </Menu.Item>
      <Menu.Item onClick={onChangeDate} key="12 months">
        Last 12 months
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={RangeDate}>
        <a  onClick={(e) => e.preventDefault()}>
          Last {rangeShow ? rangeShow : defaultValue } <ArrowDown />
        </a>
      </Dropdown>
    </>
  );
};
export default DropDownDate;
