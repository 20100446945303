import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
import ChiefDraftBriefsTable from "../../../components/Tables/ChiefDraftBriefsTable";
class ChiefDraftBriefs extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderChief
          name="Draft Briefs"
          content="draftBriefs"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <ChiefDraftBriefsTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefDraftBriefs.propTypes = {
  content: PropTypes.string,
};
export default ChiefDraftBriefs;
