import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actFetchResourceFail, actNotify } from "./fetch.action";
import { actGetMyPitchDetailRequestV2 } from "./pitch.action";
import renderError from "utils/renderServerError";

export const actGetFeedbackListRequest = outlineId => {
  return dispatch => {
    dispatch(actGetFeedbackListStart());
    return callApi(`v2/feedback?outlineId=${outlineId}`)
      .then(res => {
        if (res.data) {
          dispatch(actGetFeedbackList(res.data));
        }
      })
      .catch(err => {
        dispatch(actGetFeedbackListFail());
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};
export const actGetFeedbackListStart = () => {
  return {
    type: Types.GET_FEEDBACK_LIST_START
  };
};
export const actGetFeedbackListFail = () => {
  return {
    type: Types.GET_FEEDBACK_LIST_FAIL
  };
};
export const actGetFeedbackList = data => {
  return {
    type: Types.GET_FEEDBACK_LIST,
    data
  };
};
export const actResetFeedbackList = () => {
  return {
    type: Types.RESET_FEEDBACK_LIST
  };
};
export const actAddFeedbackRequest = data => {
  return dispatch => {
    return callApi(`v2/feedback`, "POST", data)
      .then(() => {
        dispatch(actGetFeedbackListRequest(data.outlineId));
        dispatch(
          actNotify({
            message: "You have added a new feedback",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actAddReplyRequest = data => {
  return dispatch => {
    return callApi(`v2/feedback/reply`, "POST", data)
      .then(() => {
        dispatch(actGetFeedbackListRequest(data.outlineId));
        dispatch(
          actNotify({
            message: "You have replied feedback successfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};
export const actChangeFeedbackStatusRequest = data => {
  return dispatch => {
    return callApi(`v2/feedback/status`, "POST", data)
      .then(() => {
        dispatch(actGetFeedbackListRequest(data.outlineId));
        dispatch(
          actNotify({
            message: `You have ${data.status.toLowerCase()} feedback successfully`,
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};
// export const actChangeResolveStatusRequest = data => {
//   return dispatch => {
//     return callApi(`feedback/change-resolve-status`, "POST", data)
//       .then(() => {
//         dispatch(actGetFeedbackListRequest(data.article_id));
//         dispatch(
//           actNotify({
//             message: "You have resolved feedback successfully",
//             color: "success"
//           })
//         );
//       })
//       .catch(err => {
//         dispatch(
//           actFetchResourceFail({
//             message: renderError(err),
//             confirmTo: window.location.pathname
//           })
//         );
//       });
//   };
// };

// Comment Pitch
export const actGetCommentPitchListRequest = pitchID => {
  return dispatch => {
    return callApi(`comment-pitch/list-comment-with-idpitch?pitchID=${pitchID}`)
      .then(res => {
        dispatch(actGetCommitPitchList(res.data));
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actGetCommitPitchList = data => {
  return {
    type: Types.GET_COMMENT_PITCH,
    data
  };
};

export const actAddCommentPitchRequest = (data, pitchID) => {
  return dispatch => {
    return callApi(`comment-pitch/create`, "POST", data)
      .then(() => {
        dispatch(actGetCommentPitchListRequest(pitchID));
        dispatch(
          actNotify({
            message: "You have added a new feedback",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

// V2 Add pitch comment
export const actAddCommentPitchRequestV2 = data => {
  return dispatch => {
    return callApi(`v2/pitch/comment`, "POST", data)
      .then(() => {
        dispatch(actGetMyPitchDetailRequestV2(data.pitchId));
        dispatch(
          actNotify({
            message: "You have added a new feedback",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

// V2 Change status comment

export const actChangeStatusCommentPitchRequest = data => {
  return dispatch => {
    return callApi(`v2/pitch/comment-status`, "POST", data)
      .then(() => {
        dispatch(actGetMyPitchDetailRequestV2(data.pitchId));
        dispatch(
          actNotify({
            message: `You have ${data.status} feedback successfully`,
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

// V2 reply pitch comment

export const actCommentPitchAddReplyRequestV2 = data => {
  return dispatch => {
    return callApi(`v2/pitch/comment-reply`, "POST", data)
      .then(() => {
        dispatch(actGetMyPitchDetailRequestV2(data.pitchId));
        dispatch(
          actNotify({
            message: "You have replied feedback successfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actCommentPitchAddReplyRequest = (data, pitchID, id_parent) => {
  return dispatch => {
    return callApi(`comment-pitch/create?id_parent=${id_parent}`, "POST", data)
      .then(() => {
        dispatch(actGetCommentPitchListRequest(pitchID));
        dispatch(
          actNotify({
            message: "You have replied feedback successfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actChangeResolveCommentPitchRequest = (data, pitchID) => {
  return dispatch => {
    return callApi(`comment-pitch/change-resolve-status`, "POST", data)
      .then(() => {
        dispatch(actGetCommentPitchListRequest(pitchID));
        dispatch(
          actNotify({
            message: "You have resolved feedback successfully",
            color: "success"
          })
        );
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};
