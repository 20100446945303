import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify, actFetchResourceSuccess } from "./fetch.action";
import renderError from "utils/renderServerError";
import { actGetCountInfoEditorRequest } from "./permission.action";
import { actGetWriterCountDashboardListRequest } from "./writerArticle.action";

//WRITER - ACTION:
export const actCreateNewPitchRequest = data => {
  return dispatch => {
    return callApi(`pitch/create`, "POST", data)
      .then(() => {
        dispatch(
          actFetchResourceSuccess({
            message: "Submitted Pitch - Outline successfully",
            confirmTo: "/admin/writer/my-pitches"
          })
        );
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actUpdatePitchRequest = (id, data) => {
  return dispatch => {
    return callApi(`pitch/edit/${id}`, "PUT", data)
      .then(() => {
        dispatch(
          actFetchResourceSuccess({
            message: "Submitted Pitch - Outline successfully",
            confirmTo: "/admin/writer/my-pitches"
          })
        );
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetWriterSendBackPitchesRequest = data => {
  return dispatch => {
    return callApi(
      `pitch/send-back-list?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetWriterSendBackPitches(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetWriterSendBackPitches = data => {
  return {
    type: Types.GET_WRITER_MY_PITCH,
    data
  };
};

export const actGetMyPitchDetailRequest = id => {
  return dispatch => {
    return callApi(`pitch/get-detail/${id}`, "GET")
      .then(res => {
        dispatch(actGetMyPitchDetail(res));
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// V2 Pitch Detail
export const actGetMyPitchDetailRequestV2 = id => {
  return dispatch => {
    return callApi(`v2/pitch/${id}`, "GET")
      .then(res => {
        if (res.data) {
          dispatch(actGetMyPitchDetail(res.data.pitch));
        }
        dispatch(actGetWriterCountDashboardListRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetMyPitchDetail = data => {
  return {
    type: Types.GET_WRITER_MY_PITCH_DETAIL,
    data
  };
};

//EDITOR - ACTION:
export const actGetAvailablePitchesRequest = data => {
  return dispatch => {
    return callApi(
      `pitch/available-list?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetAvailablePitches(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetAvailablePitches = data => {
  return {
    type: Types.GET_AVAILABLE_PITCHES,
    data
  };
};

export const actGetPitchDetailRequest = id => {
  return dispatch => {
    return callApi(`pitch/get-detail/${id}`, "GET")
      .then(res => {
        dispatch(actGetPitchDetail(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetPitchDetail = data => {
  return {
    type: Types.GET_PITCH_DETAIL,
    data
  };
};

export const actApprovePitchRequest = (id, data) => {
  return dispatch => {
    return callApi(`pitch/approve/${id}`, "PUT", data)
      .then(() => {
        let mess =
          data.status === "APPROVED"
            ? "Approved pitch successfully"
            : data.status === "REJECTED"
            ? "Rejected pitch successfully"
            : "Pitch sent back to writer";
        dispatch(actGetCountInfoEditorRequest());
        dispatch(
          actFetchResourceSuccess({
            message: mess,
            confirmTo: "/admin/editor/available-pitches"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// V2 Approve pitch writer
export const actApprovePitchRequestV2 = (id, data) => {
  return dispatch => {
    return callApi(`v2/pitch/approve/${id}`, "PUT", data)
      .then(() => {
        let mess =
          data.status === "APPROVED"
            ? "Approved pitch successfully"
            : data.status === "REJECTED"
            ? "Rejected pitch successfully"
            : "Pitch sent back to writer";
        dispatch(actGetCountInfoEditorRequest());
        dispatch(
          actFetchResourceSuccess({
            message: mess,
            confirmTo: "/admin/editor/available-pitches"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// Reject pitch request
export const actRejectPitchRequest = (id, data) => {
  return dispatch => {
    return callApi(`v2/pitch/reject/${id}`, "PUT", data)
      .then(() => {
        let mess =
          data.status === "APPROVED"
            ? "Approved pitch successfully"
            : data.status === "REJECTED"
            ? "Rejected pitch successfully"
            : "Pitch sent back to writer";
        dispatch(actGetCountInfoEditorRequest());
        dispatch(
          actFetchResourceSuccess({
            message: mess,
            confirmTo: "/admin/editor/available-pitches"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
// Send back pitch request
export const actSendBackPitchRequest = (id, data) => {
  return dispatch => {
    return callApi(`v2/pitch/send-back/${id}`, "PUT", data)
      .then(() => {
        let mess =
          data.status === "APPROVED"
            ? "Approved pitch successfully"
            : data.status === "REJECTED"
            ? "Rejected pitch successfully"
            : "Pitch sent back to writer";
        dispatch(actGetCountInfoEditorRequest());
        dispatch(
          actFetchResourceSuccess({
            message: mess,
            confirmTo: "/admin/editor/available-pitches"
          })
        );
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

// CHIEFEDITOR - ACTION
export const actGetChiefAvailablePitchesRequest = data => {
  return dispatch => {
    return callApi(
      `pitch/available-list?` +
        `page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetChiefAvailablePitches(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetChiefAvailablePitches = data => {
  return {
    type: Types.GET_CHIEF_AVAILABLE_PITCHES,
    data
  };
};

export const actGetChiefPitchDetailRequest = id => {
  return dispatch => {
    return callApi(`pitch/get-detail/${id}`, "GET")
      .then(res => {
        dispatch(actGetChiefPitchDetail(res));
        dispatch(actGetCountInfoEditorRequest());
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetChiefPitchDetail = data => {
  return {
    type: Types.GET_CHIEF_PITCH_DETAIL,
    data
  };
};
export const actResetCommentPitch = () => {
  return {
    type: Types.RESET_COMMENT_PITCH
  };
};
