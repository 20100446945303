import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  FormGroup,
  CardText,
} from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actApplyOutlineRequest } from "../../actions/writerArticle.action";
class WriterViewDetailContentModal extends Component {
  state = {
    id: "",
    listcontent_byid: {},
  };

  componentDidMount() {}

  // eslint-disable-next-line react/no-deprecated
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.listcontent.data.docs !== this.props.id) {
  //     this.setState({
  //       id: nextProps.id
  //     });
  //   }
  // }

  toggle = () => {
    this.props.toggle();
  };

  _hideAlert = () => {
    this.setState({
      alert: "",
    });
  };

  _applyToContent = (id) => {
    this.props.applyToContent(id);
    this.toggle();
  };

  createMarkup(text) {
    return { __html: text };
  }
  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  render() {
    const { listcontent_byid } = this.props;
    const id = listcontent_byid.data ? listcontent_byid.data[0]._id : "";
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className={"modal-form"}
        >
          <ModalHeader toggle={this.props.toggle}>
            View Detail Content Request
          </ModalHeader>
          <ModalBody>
            <h2 className="mb-0">
              {listcontent_byid.data
                ? listcontent_byid.data[0].angle_title
                : ""}
            </h2>
            <div className="pt-4">
              {listcontent_byid.data && listcontent_byid.data[0]
                ? listcontent_byid.data[0].dualLanguage && (
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Published article title:
                          </label>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        {listcontent_byid.data &&
                          listcontent_byid.data[0] &&
                          listcontent_byid.data[0].dualLanguage_article && (
                            <a
                              target="blank"
                              href={`/admin/writer/view-detail-content-writer/${listcontent_byid.data[0].dualLanguage}`}
                            >
                              {
                                listcontent_byid.data[0].dualLanguage_article
                                  .angle_title
                              }
                            </a>
                          )}
                      </Col>
                    </Row>
                  )
                : null}
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Topic:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].topic.map((topic) => {
                          return (
                            <>
                              <div className="_topic">
                                <label>
                                  {topic.name ? topic.name : topic.label}
                                </label>
                              </div>
                            </>
                          );
                        })
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Time to Write:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    <CardText>
                      {" "}
                      {listcontent_byid.data
                        ? listcontent_byid.data[0].time_to_write + " days"
                        : ""}
                    </CardText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Word Count:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    <CardText>
                      {" "}
                      {listcontent_byid.data
                        ? listcontent_byid.data[0].min_word +
                          " - " +
                          listcontent_byid.data[0].max_word +
                          " word"
                        : ""}
                    </CardText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Tags:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].tags.map((tags) => {
                          return (
                            <>
                              <span>{"#" + tags + " "}</span>
                            </>
                          );
                        })
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Key Word:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].keywords.map((keywords) => {
                          return (
                            <>
                              <span>{keywords + ", "}</span>
                            </>
                          );
                        })
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Type of Article:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].type_of_article.map(
                          (typeofarticle) => {
                            return (
                              <>
                                <span>{typeofarticle.name + " "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Topics of Concerns:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].topics_of_concern.map(
                          (topicsofconcern) => {
                            return (
                              <>
                                <span>{topicsofconcern.name + " "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Objectives:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {this.rawHTML(
                      listcontent_byid.data
                        ? listcontent_byid.data[0].objectives
                        : ""
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Age Group:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].age_group.map((age_group) => {
                          return (
                            <>
                              <span>{age_group.name + ", "}</span>
                            </>
                          );
                        })
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Comprehension Level:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].comprehension_level.map(
                          (comprehension_level) => {
                            return (
                              <>
                                <span>{comprehension_level.name + ", "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      References:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {this.rawHTML(
                      listcontent_byid.data
                        ? listcontent_byid.data[0].references
                        : ""
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Steps Required to Write This Article:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].steps_to_write.map(
                          (steps_to_write) => {
                            return (
                              <>
                                <span>{steps_to_write.name + ", "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Things to Avoid:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {this.rawHTML(
                      listcontent_byid.data
                        ? listcontent_byid.data[0].things_to_avoid
                        : ""
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Things to Mention:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {this.rawHTML(
                      listcontent_byid.data
                        ? listcontent_byid.data[0].things_to_mention
                        : ""
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Tone of Writing:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {listcontent_byid.data
                      ? listcontent_byid.data[0].tone_of_writing.map(
                          (tone_of_writing) => {
                            return (
                              <>
                                <span>{tone_of_writing.name + ", "}</span>
                              </>
                            );
                          }
                        )
                      : ""}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Note:
                    </label>
                  </FormGroup>
                </Col>
                <Col lg="9">
                  <FormGroup>
                    {this.rawHTML(
                      listcontent_byid.data ? listcontent_byid.data[0].note : ""
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              <Button
                color="success"
                type="button"
                style={{ height: "45px" }}
                onClick={() => this._applyToContent(id)}
              >
                Apply
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listcontent_byid: state.writerArticleReducer.listcontent_byid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    applyToContent: (id) => {
      dispatch(actApplyOutlineRequest(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriterViewDetailContentModal);

WriterViewDetailContentModal.propTypes = {
  id: PropTypes.string,
  listcontent_byid: PropTypes.object,
  detailContent: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  uploadImg: PropTypes.func,
  applyToContent: PropTypes.func,
};

WriterViewDetailContentModal.defaultProps = {};
