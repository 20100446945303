import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import EditorAddNewPostDetail from "../../../components/Detail/EditorAddNewPostDetail";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";

class ChiefBriefTranslate extends Component {
  state = {
    id: window.location.pathname.split("/")[4],
  };
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderChief
          name="Brief Detail"
          content="briefDetail"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <EditorAddNewPostDetail {...props} />
        </Container>
      </>
    );
  }
}
ChiefBriefTranslate.propTypes = {
  content: PropTypes.string,
};
export default ChiefBriefTranslate;
