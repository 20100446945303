import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InformationProfile from "../../components/Information/Information";
import { Button } from "antd";
import {
  actGetEmployeeProfileRequest,
  actUpdateEmployeeRequest,
  actCreateEmployeeRequest,
  actGetListTopicRequest
} from "../../actions/employee.action";
import Data from "./Options";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isBoolean from "lodash/isBoolean";
const dateFormat = "DD-MM-YYYY";

class Information extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const userId = window.location.pathname.split("/")[4];
    this.props.getEmployeeInfo(userId);
    this.props.getListTopic();
  }
  handleSubmit = values => {
    const id = window.location.pathname.split("/")[4];
    let payload = { ...values };
    payload.birthday = payload.birthday.format(dateFormat);
    payload.date_joined = payload.date_joined.format(dateFormat);
    payload = omitBy(payload, isNil);
    payload = omitBy(payload, isEmpty);
    if (isNumber(values.gender)) {
      payload.gender = values.gender;
    }
    if (isBoolean(values.isSenior)) {
      payload.isSenior = values.isSenior;
    }
    if (values.interest) {
      let topic = this.props.listTopic.find(e => e.name === values.interest);
      if (topic) {
        payload.interest = [
          {
            key: topic.key,
            name: topic.name,
            value: topic.key,
          }
        ];
      }
    }
    this.props.updateEmployee({ information: payload, userId: id });
  };

  render() {
    const { employee_profile } = this.props;
    return (
      <InformationProfile
        employee_profile={employee_profile}
        handleSubmit={this.handleSubmit}
        listTopic={this.props.listTopic}
        config={Data}
        render={goBack => (
          <div>
            <Button onClick={() => goBack()}>Cancel</Button>
            <Button
              style={{ marginLeft: "10px" }}
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
          </div>
        )}
      />
    );
  }
}
//const WrappedInformation = Form.create()(Information);

const mapStateToProps = state => {
  return {
    employee_profile: state.employeeReducer.employee_profile.data,
    listKey: state.employeeReducer.listKey,
    listTopic: state.employeeReducer.expertiseOption,
    listExpertise: state.employeeReducer.listExpertise
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getEmployeeInfo: employeeID => {
      dispatch(actGetEmployeeProfileRequest(employeeID));
    },
    getListTopic: () => {
      dispatch(actGetListTopicRequest());
    },
    updateEmployee: data => {
      dispatch(actUpdateEmployeeRequest(data));
    },
    createEmployee: data => {
      dispatch(actCreateEmployeeRequest(data));
    }
  };
};
export default memo(connect(mapStateToProps, mapDispatchToProps)(Information));
Information.propTypes = {
  employee_profile: PropTypes.object,
  getEmployeeInfo: PropTypes.func,
  listKey: PropTypes.object,
  listTopic: PropTypes.object,
  listExpertise: PropTypes.object,
  getListKey: PropTypes.func,
  getListTopic: PropTypes.func,
  getListExpertise: PropTypes.func,
  updateEmployee: PropTypes.func,
  createEmployee: PropTypes.func
};
Information.defaultProps = {};
