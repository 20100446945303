import React, { Component, memo } from "react";

import axios from "axios";
import { Upload, Icon, message } from "antd";
import * as authenStorage from "../../utils/authen-storage";
import HOST from "../../variables/host";
import HOST_URL from "../../variables/hostUrl";
import PropTypes from "prop-types";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class Avatar extends React.Component {
  state = {
    loading: false,
    imageUrl: "",
  };

  handleChange = (info) => {
    console.log("chnage", info)
    console.log("state", this.state)
  };
  upLoadfile = async (e) => {
    var formData = new FormData();
    const file = e.file;
    formData.append("file", file);
    let req = await axios({
              method: "POST",
      url: `${HOST}/v2/images/avatar`,
							data: formData,
							headers: {
								"Content-Type": "multipart/form-data",
								accessToken: authenStorage.getToken(),
							},
    })
    if (req.data.data.url) {
      this.props.setAvatar(req.data.data)
    }
  };
  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={this.upLoadfile}
        onChange={this.handleChange}
      >
        {this.props.avatar ? (
          <img src={HOST_URL + this.props.avatar} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}

Avatar.propTypes = {
  avatar: PropTypes.string,
  setAvatar: PropTypes.func,
};
export default memo(Avatar);
