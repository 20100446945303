// import * as Types from "../variables/actionTypes";
import callApi from "./../utils/callApi";
import { actFetchResourceSuccess, actFetchResourceFail } from "./fetch.action";
import renderError from "../utils/renderServerError";
import * as Types from "../variables/actionTypes";
import * as authenStorage from "../utils/authen-storage";

export const actLoginRequest = data => {
  return dispatch => {
    return callApi("v2/login", "POST", {
      email: data.email,
      password: data.password
    })
      .then(res => {
        const encodedString = new Buffer(data.password).toString("base64");
        authenStorage.setToken(res.data.accesstoken);
        localStorage.setItem("email", data.email);
        localStorage.setItem("password", encodedString);
        localStorage.setItem("is_remember", data.isRememberMe);
        localStorage.setItem("is_newpass", res.data.is_newpass);
        localStorage.setItem("key", res.data.key);
        localStorage.setItem("is_skip", res.data.is_skip);
        localStorage.setItem("is_senior", res.data.is_senior);
        if (res.errorCode === 0) {
          if (res.data.is_newpass === false) {
            dispatch(
              actFetchResourceSuccess({
                message: "Please update new password",
                confirmTo: "/admin/updatePassword"
              })
            );
          } else if (res.data.key === "admin") {
            window.location.href = "/admin/settings/employee/writer";
          } else if (res.data.key === "writer") {
            window.location.href = "/admin/writer/dashboard-writer";
          } else if (res.data.key === "editor") {
            window.location.href = "/admin/editor/dashboard-editor";
          } else if (res.data.key === "chiefeditor") {
            window.location.href = "/admin/chief/dashboard-chief";
          }
        } else {
          const err = res.message;
          dispatch(
            actFetchResourceFail({
              message: err,
              confirmTo: "/admin/login"
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          actFetchResourceFail({
            message:
              "Your email or password is incorrect, please try again later.",
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actChangePasswordRequest = data => {
  return dispatch => {
    return callApi("change-staff-password", "POST", {
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
      token: localStorage.getItem("accessToken")
    })
      .then(res => {
        localStorage.setItem("accessToken", res);
        if (res) {
          dispatch(
            actFetchResourceSuccess({
              message: "",
              confirmTo: "/admin/login"
            })
          );
        } else {
          dispatch(
            actFetchResourceFail({
              message: "Please update your password",
              confirmTo: "/admin/updatePassword"
            })
          );
        }
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: "Please try again!",
            confirmTo: "/admin/updatePassword"
          })
        );
      });
  };
};
//FORGOT PASSWORD
export const actForgotPasswordRequest = data => {
  return dispatch => {
    return callApi("v2/forgot-password", "POST", {
      email: data
    })
      .then(res => {
        localStorage.setItem("email", data);
        window.location.href = "/admin/ChangeNewPassword";
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: "This email address is not exist in database!",
            confirmTo: "/admin/forgotPassword"
          })
        );
      });
  };
};
//Change new passwword
export const actChangeNewPasswordRequest = data => {
  return dispatch => {
    return callApi("v2/reset-password", "POST", {
      email: data.email,
      code: data.code,
      password: data.passwordnew
    })
      .then(res => {
        window.location.href = "/admin/login";
      })
      .catch(err => {
        const mess = err.data.message;
        dispatch(
          actFetchResourceFail({
            message: mess,
            confirmTo: "/admin/ChangeNewPassword"
          })
        );
      });
  };
};
export const actGetMyInformationRequest = () => {
  return dispatch => {
    return callApi(`profile`)
      .then(res => {
        localStorage.setItem("id", res.data.id);
        dispatch(actGetMyInformation(res.data));
      })
      .catch(err => {
        dispatch(
          actFetchResourceFail({
            message: renderError(err),
            confirmTo: window.location.pathname
          })
        );
      });
  };
};

export const actGetMyInformation = data => {
  return {
    type: Types.GET_MY_INFORMATION,
    data
  };
};
