import React, { Component } from "react";
import { NavItem, NavLink, Nav, Col, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import CardsHeaderchief from "../../components/Headers/CardsHeaderChief";
import ArticleTable from "../../components/Tables/ArticleTable";
import ComingSoon from "../comming-soon/ComingSoon";
import Chart from "../../components/Charts/ArticleChart";
class Article extends Component {
  state = {
    tabs: 1
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Article"
          content="briefsAvailable"
          parentName="Statistics"
          isDetail={true}
        />
        <Col className="p-4 over">
          <div className="nav-wrapper">
            <Nav className="overview" id="tabs-icons-text" pills role="tablist">
              <NavItem>
                <NavLink
                  aria-selected={this.state.tabs === 1}
                  className={classnames("mb-sm-3 mb-md-0 tab1", {
                    active: this.state.tabs === 1
                  })}
                  onClick={e => this.toggleNavs(e, "tabs", 1)}
                  role="tab"
                >
                  1. OVERVIEW
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.tabs === 2}
                  className={classnames("mb-sm-3 mb-md-0 tab2", {
                    active: this.state.tabs === 2
                  })}
                  onClick={e => this.toggleNavs(e, "tabs", 2)}
                  role="tab"
                  disabled
                >
                  2. ARTICLE DETAILS
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={"tabs" + this.state.tabs}>
            {/* TAB 1 */}
            <TabPane tabId="tabs1">
              <Chart toggleNavs={this.toggleNavs} />
              <ArticleTable {...props} toggleNavs={this.toggleNavs} />
            </TabPane>
            {/* TAB 2 */}
            <TabPane tabId="tabs2">
              <ComingSoon toggleNavs={this.toggleNavs} />
            </TabPane>
          </TabContent>
        </Col>
      </>
    );
  }
}
Article.propTypes = {
  content: PropTypes.string
};
export default Article;
