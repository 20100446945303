import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { formatStringToTime } from "../../utils/formatDate";

class VersionSelect extends Component {
  render() {
    const { versions, label } = this.props;
    return (
      <div className="version-select">
        <UncontrolledButtonDropdown>
          <DropdownToggle caret>{label ? label : "Versions"}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Versions</DropdownItem>
            {versions &&
              versions.map((prop, key) => {
                return (
                  <DropdownItem
                    key={key}
                    onClick={() => this.props.onVersionSelect(key + 1)}
                  >
                    Version {key + 1} &nbsp; &nbsp;
                    <span>{formatStringToTime(prop.updatedAt)}</span>
                  </DropdownItem>
                );
              })}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    );
  }
}

export default VersionSelect;

VersionSelect.propTypes = {
  versions: PropTypes.array,
  label: PropTypes.string,
  onVersionSelect: PropTypes.func
};
