import React, { Component } from "react";
import { Card, CardHeader, CardBody, Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetWritingContentByIDRequest } from "../../actions/writerArticle.action";
import CardsHeaderchief from "../Headers/CardsHeaderChief";

class ViewContentChiefDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      id: window.location.pathname.split("/")[4],
      is_senior: localStorage.getItem("is_senior") === "true",
      data: {
        content: ""
      },
      idView: false
    };
  }

  componentDidMount() {
    this._setLoading();
    this._getContentDetailByID(this.state.id);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.writing_content !== this.props.writing_content) {
      this.setState({
        data: { content: nextProps.writing_content.data[0].content_content },
        isWritting:
          nextProps.writing_content.data[0].status_article === "CONTENT_WRITING"
      });
    }
  }

  _toggleModalView = () => {
    this.setState(prevState => ({
      idView: !prevState.idView
    }));
  };

  _getContentDetailByID = id => {
    this.props.getContentDetailByID(id);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  _handleOnViewDetail = id => {
    this._toggleModalView();
  };

  createMarkup(text) {
    return { __html: text };
  }
  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  render() {
    const { writing_content } = this.props;

    return (
      <>
        <CardsHeaderchief
          name="Preview"
          content="preview"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col md="12" lg="12">
              <Card>
                <CardHeader>
                  <h1>{writing_content.data[0].angle_title}</h1>
                </CardHeader>
                <CardBody className="ql-editor navbar-admin__scroll-container">
                  <div className="preview-content">
                    <h1 style={{ fontSize: 40, paddingBottom: 20 }}>
                      {this.props.writing_content.data[0].angle_title}
                    </h1>
                    {this.rawHTML(
                      this.props.writing_content &&
                        this.props.writing_content.data[0].content_content
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writing_content: state.writerArticleReducer.writing_content_id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getContentDetailByID: id => {
      dispatch(actGetWritingContentByIDRequest(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewContentChiefDetail);
ViewContentChiefDetail.propTypes = {
  writing_content: PropTypes.object,
  getContentDetailByID: PropTypes.func
};
ViewContentChiefDetail.defaultProps = {};
