import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";
import { ClipLoader } from "react-spinners";

class CardBodyWithLoading extends Component {
  render() {
    const isLoading = this.props.isLoading;
    return (
      <>
        {isLoading ? (
          <CardBody>
            <div className={`p-${this.props.padding} text-center`}>
              <ClipLoader loading={true} size={50} color={"#3C74F0"} />
            </div>
          </CardBody>
        ) : (
          <CardBody id={this.props.id} className={this.props.className}>
            {this.props.children}
          </CardBody>
        )}
      </>
    );
  }
}

export default CardBodyWithLoading;
CardBodyWithLoading.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  padding: PropTypes.number,
  id: PropTypes.string
};
CardBodyWithLoading.defaultProps = {
  padding: 9
};
