import React, { Component } from "react";
import { Card, CardHeader, Table, Row } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatStringToDate } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import Avatar from "../Avatar/Avatar";
import { actGetPitchChiefDashboardRequest } from "../../actions/chief-dashboard.action";
import { Link } from "react-router-dom";
import CardBodyWithLoading from "../CardBodyWithLoading/CardBody";
import { Tooltip } from "antd";
import ReactHtmlParser from "react-html-parser";
class ChiefPitchesDashboardTable extends Component {
  state = {
    params: {
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 5,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "updatedAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getPitchesChiefDashboard();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 3000);
  };

  _getPitchesChiefDashboard = () => {
    const params = this.state.params;
    this.props.getPitchesChiefDashboard(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this.props.getPitchesChiefDashboard(paramsSort);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/chief/pitch-detail/${id}`);
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 80) {
      return `${str[0].substring(0, 80)}...`;
    } else {
      return str[0].substring(0, 80);
    }
  };

  render() {
    const { pitches_dashboard } = this.props;

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="header-scroll border-0">
                <Row className="header-top">
                  <div className="table-header">
                    <h3 className="text-header mb-0">Pitches</h3>
                  </div>
                  <div className="header-info">
                    <span className="info-approval">
                      Waiting for Approval
                      <p>
                        <Link to={"/admin/chief/available-pitches"}>
                          View more
                        </Link>
                      </p>
                    </span>
                    <span className="info-number">
                      {pitches_dashboard.waitingForApproval}
                    </span>
                  </div>
                </Row>
              </CardHeader>
              <CardBodyWithLoading
                isLoading={this.state.isLoading}
                className="p-0"
              >
                <div className="table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          // className="sort"
                          // data-sort="name"
                          // scope="col"
                          // onClick={() => this._sortByColumn("angle_title")}
                          style={{ width: "50%" }}
                        >
                          Title
                        </th>
                        <th
                        // className="sort"
                        // data-sort="status"
                        // scope="col"
                        // onClick={() => this._sortByColumn("writer.name")}
                        >
                          Writer
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("updatedAt")}
                        >
                          Submitted On
                        </th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {pitches_dashboard.docs &&
                        pitches_dashboard.docs.length !== 0 &&
                        pitches_dashboard.docs.map((prop, key) => {
                          return (
                            <>
                              <tr
                                key={key}
                                style={{ cursor: "pointer" }}
                                onClick={e =>
                                  this._handleOnCellClick(e, prop._id)
                                }
                              >
                                <th scope="row">
                                  <Tooltip
                                    title={ReactHtmlParser(prop.angle_title)}
                                    placement="bottom"
                                  >
                                    <span>
                                      {this.limitTitle(
                                        ReactHtmlParser(prop.angle_title)
                                      )}
                                    </span>
                                  </Tooltip>
                                </th>
                                <td className="table-user">
                                  <Avatar
                                    src={prop.writer.avatar}
                                    className="d-inline-block mr-3"
                                  />
                                  <b>{prop.writer ? prop.writer.name : ""}</b>
                                </td>
                                <td className="budget">
                                  {formatStringToDate(prop.updatedAt)}
                                </td>
                                <td>
                                  {prop
                                    ? prop.topic.map(topic => {
                                        return (
                                          <>
                                            <div className="db_topic">
                                              <label>
                                                {this._text_truncate(
                                                  topic.name
                                                    ? topic.name
                                                    : topic.label,
                                                  20,
                                                  "..."
                                                )}
                                              </label>
                                            </div>
                                          </>
                                        );
                                      })
                                    : ""}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                  {pitches_dashboard &&
                    pitches_dashboard.docs &&
                    pitches_dashboard.docs.length === 0 && (
                      <div
                        className="text-center"
                        style={{ color: "#4B556F", padding: "5%" }}
                      >
                        <i
                          className="icon-search"
                          style={{ fontSize: "70px" }}
                        />
                        <p>No Data</p>
                      </div>
                    )}
                </div>
              </CardBodyWithLoading>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    pitches_dashboard: state.chiefDashboardReducer.pitches_dashboard
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPitchesChiefDashboard: params => {
      dispatch(actGetPitchChiefDashboardRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefPitchesDashboardTable);
ChiefPitchesDashboardTable.propTypes = {
  pitches_dashboard: PropTypes.object,
  getPitchesChiefDashboard: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};
ChiefPitchesDashboardTable.defaultProps = {};
