import React, { Component } from "react";
import {
  Container
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import CardsHeaderEditor from "../../components/Headers/CardsHeaderEditor";
import CardsHeaderChief from "../../components/Headers/CardsHeaderChief";
import FaqTable from "./FaqTable";
class Faq extends Component {
  state = {
    pathnameRoutes: window.location.pathname.split("/")[2]
  };
  render() {
    const props = this.props;
    const { pathnameRoutes } = this.state;
    return (
      <>
        {pathnameRoutes === "writer" ? (
          <CardsHeader
            name="FAQ"
            content="FAQ"
            parentName="General Information"
          />
        ) : pathnameRoutes === "chief" ? (
          <CardsHeaderChief
            name="FAQ"
            content="FAQ"
            parentName="General Information"
          />
        ) : (
          <CardsHeaderEditor
            name="FAQ"
            content="FAQ"
            parentName="General Information"
          />
        )}

        <Container className="mt--7" fluid>
          <FaqTable {...props} />
        </Container>
      </>
    );
  }
}
Faq.propTypes = {
  content: PropTypes.string
};
export default Faq;
