import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
// import EditorPitchDetail from "../../components/Detail/EditorPitchDetail";
import ChiefPitchDetail from "../../../components/Detail/ChiefPitchDetail";
class ChiefPitches extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderChief
          name="Pitch detail"
          content="pitchDetail"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <ChiefPitchDetail {...props} />
        </Container>
      </>
    );
  }
}
ChiefPitches.propTypes = {
  content: PropTypes.string,
};
export default ChiefPitches;
