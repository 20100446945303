import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Table,
  Col,
  Row,
  CardFooter,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import Avatar from "../Avatar/Avatar";
import { actGetReadersRequest } from "../../actions/user.action";

class ChiefReadersTable extends Component {
  state = {
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "order_num",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : 1,
      search: getUrlParam()["search"] ? getUrlParam()["search"] : ""
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getReadersProfile();
  }

  _getReadersProfile = () => {
    const params = this.state.params;
    this.props.getReadersProfile(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getReadersProfile(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getReadersProfile(paramsSort);
  };

  _handleOnChange = (label, value) => {
    const params = {
      ...this.state.params,
      [label]: value
    };
    this.setState({
      params: {
        ...this.state.params,
        [label]: value
      }
    });
    this._handlePathnamePush(params);
    this.props.getReadersProfile(params);
  };

  render() {
    const { chief_readers } = this.props;
    const page = chief_readers.data && chief_readers.data.page;
    const totalPages = chief_readers.data && chief_readers.data.totalPages;
    const totalDocs = chief_readers.data && chief_readers.data.totalDocs;

    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused: this.state.isFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search for Username, Email or ID"
                          type="text"
                          name="search"
                          value={this.state.params.search}
                          onChange={e =>
                            this._handleOnChange("search", e.target.value)
                          }
                          onFocus={e => this.setState({ isFocus: true })}
                          onBlur={e => this.setState({ isFocus: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {chief_readers.data &&
                  chief_readers.data.docs &&
                  chief_readers.data.docs.length !== 0 && (
                    <Table
                      className="align-items-center table-flush"
                      style={{ border: "0px" }}
                      hover
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            className="sort"
                            data-sort="status"
                            scope="col"
                            onClick={() => this._sortByColumn("order_num")}
                          >
                            ID
                          </th>
                          <th
                            className="sort"
                            data-sort="status"
                            scope="col"
                            onClick={() => this._sortByColumn("name")}
                          >
                            USER
                          </th>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            onClick={() => this._sortByColumn("email")}
                          >
                            EMAIL
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {chief_readers.data &&
                          chief_readers.data.docs &&
                          chief_readers.data.docs.map((prop, key) => {
                            return (
                              <>
                                <tr
                                  key={key}
                                  style={{ cursor: "pointer" }}
                                  // onClick={e =>
                                  //   this._handleOnCellClick(e, prop._id)
                                  // }
                                >
                                  <th scope="row">{prop.order_num}</th>
                                  <td>
                                    {" "}
                                    <Avatar
                                      src={prop.avatar && prop.avatar}
                                      className="d-inline-block mr-3"
                                    />{" "}
                                    <span>{prop.name}</span>{" "}
                                  </td>
                                  <td>{prop.email}</td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}

                {chief_readers.data &&
                  chief_readers.data.docs &&
                  chief_readers.data.docs.length === 0 && (
                    <div
                      className="text-center"
                      style={{ color: "#4B556F", padding: "5%" }}
                    >
                      <i className="icon-search" style={{ fontSize: "70px" }} />
                      <p>No Users</p>
                    </div>
                  )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    chief_readers: state.userReducer.chief_readers
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getReadersProfile: params => {
      dispatch(actGetReadersRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefReadersTable);
ChiefReadersTable.propTypes = {
  chief_readers: PropTypes.object,
  getReadersProfile: PropTypes.func,
  history: PropTypes.any
};
ChiefReadersTable.defaultProps = {};
