import React, { Component } from "react";
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import CustomEditor from "../TinyMCE";
import {
  actCreateNewPitchRequest,
  actGetPitchDetailRequest,
} from "../../actions/pitch.action";
import EditorCardContentMapping from "../Cards/EditorCardContentMapping";
import PitchRejectModal from "../Modals/PitchRejectModal";
import PitchApproveModal from "../Modals/PitchApproveModal";
import PitchSendBackModal from "../Modals/PitchSendBackModal";
import FeedbackPitch from "../Feedback/FeedbackPitch";
class EditorPitchDetail extends Component {
  state = {
    tabs: 1,
    isReject: false,
    isApprove: false,
    isSendBack: false,
    id: window.location.pathname.split("/")[4],
    data: {
      outline: "",
      references: "",
      things_to_avoid: "",
      things_to_mention: "",
    },
  };

  componentDidMount() {
    this._getPitchDetailID(this.state.id);
    // this._autoSave();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.pitch_detail !== this.props.pitch_detail) {
      this.setState({
        data: nextProps.pitch_detail.data,
        isVerifying: nextProps.pitch_detail.status === "PITCH_SUBMITTED",
      });
    }
  }

  _getPitchDetailID = (id) => {
    this.props.getPitchDetail(id);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _toggleModalReject = () => {
    this.setState((prevState) => ({
      isReject: !prevState.isReject,
    }));
  };

  _toggleModalApprove = () => {
    this.setState((prevState) => ({
      isApprove: !prevState.isApprove,
    }));
  };

  _closeReject = () => {
    this.setState({
      isReject: false,
    });
  };

  _closeSendBack = () => {
    this.setState({
      isSendBack: false,
    });
  };

  _toggleModalSendBack = () => {
    this.setState((prevState) => ({
      isSendBack: !prevState.isSendBack,
    }));
  };

  handlePitchData = (pitchData) => {
    this.setState({ pitchData: pitchData });
  };

  _handleOnContentChange = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        outline: value,
      },
    });
  };
  handleChangeContent = (content) => {
    this.setState({
      data: {
        ...this.state.data,
        outline: content,
      },
    });
  };

  render() {
    return (
      <>
        <PitchRejectModal
          visible={this.state.isReject}
          handleCancel={this._closeReject}
          // toggle={this._toggleModalReject}
          id={this.state.id}
          pitch_detail={this.state.data}
        />
        <PitchApproveModal
          modal={this.state.isApprove}
          toggle={this._toggleModalApprove}
          id={this.state.id}
          content={this.state.data.content}
          pitch_detail={this.state.data}
        />
        <PitchSendBackModal
          visible={this.state.isSendBack}
          handleCancel={this._closeSendBack}
          id={this.state.id}
          pitch_detail={this.state.data}
        />
        <Row>
          <Col md="9">
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 1,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                    role="tab"
                  >
                    1. Content Mapping
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 2,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                    role="tab"
                    // style={{ }}
                  >
                    2. Outline
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={"tabs" + this.state.tabs}>
              <TabPane tabId="tabs1">
                <EditorCardContentMapping
                  pitchData={this.handlePitchData}
                  toggleNavs={this.toggleNavs}
                />
              </TabPane>
              <TabPane tabId="tabs2">
                <Card>
                  <CardBody>
                    <div className="pb-4">
                      <h2>{this.state.data && this.state.data.angle_title}</h2>
                    </div>
                    <FormGroup>
                      <Form>
                        {this.state.data.outline && (
                          <CustomEditor
                            toolbar="false"
                            menubar="false"
                            readOnly={true}
                            content={this.state.data.outline}
                            onChangeContent={this.handleChangeContent}
                          />
                        )}
                      </Form>
                    </FormGroup>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
          <Col xl="3" className="toolbar-right">
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                color="warning"
                size="lg"
                style={{ width: "50%" }}
                onClick={() => this._toggleModalSendBack()}
              >
                Send back
              </Button>
              <Button
                type="button"
                color="success"
                size="lg"
                style={{ width: "40%" }}
                onClick={() => this._toggleModalApprove()}
              >
                Approve
              </Button>
              <Button
                type="button"
                size="lg"
                color="danger"
                style={{ width: "35%" }}
                onClick={() => this._toggleModalReject()}
              >
                Reject
              </Button>
            </div>
            <FeedbackPitch pitchID={this.state.id} />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pitch_detail: state.pitchReducer.pitch_detail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submitPitchOutline: (data) => {
      dispatch(actCreateNewPitchRequest(data));
    },
    getPitchDetail: (id) => {
      dispatch(actGetPitchDetailRequest(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorPitchDetail);
EditorPitchDetail.propTypes = {
  pitch_detail: PropTypes.object,
  getPitchDetail: PropTypes.func,
};
EditorPitchDetail.defaultProps = {};
