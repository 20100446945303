import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  CardHeader,
} from "reactstrap";
import CustomEditor from "../TinyMCE";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  actSubmitOutlineRequest,
  actSaveDraftOutlineRequest,
  actAutoSaveOutlineRequest,
} from "../../actions/writerArticle.action";
import Feedback from "../Feedback/Feedback";
import { actGetOutlineDetailIDRequest } from "../../actions/editorArticle.action";
import VersionSelect from "../Selects/VersionSelect";
import getUrlParam from "../../utils/getUrlParam";

class OutlineVersionDetail extends Component {
  state = {
    tabs: 1,
    id: window.location.pathname.split("/")[4],
    data: {
      content: "",
    },
    isWritting: false,
    autoSave: false,
    version: getUrlParam()["version"],
  };

  componentDidMount() {
    this._setLoading();
    this._getOutlineDetailByID(this.state.id);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const version = this.state.version;
    if (nextProps.outline_versions !== this.props.outline_versions) {
      const versionData =
        nextProps.outline_versions && nextProps.outline_versions[version - 1];
      this.setState({
        data: versionData,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
  }

  _getOutlineDetailByID = (id) => {
    this.props.getOutlineDetailByID(id);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  _handleOnSubmitOutline = () => {
    const id = this.props.outline_detail.data.id;
    const data = this.state.data;
    this.props.submitOutline(id, data);
  };

  _handleOnContentChange = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        content: value,
      },
      autoSave: true,
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _handleOnSaveDraftOutline = (e) => {
    e.preventDefault();
    const outlineID = this.props.outline_detail.data.outlineID;
    const data = {
      content: this.state.data.content,
      outlineID: outlineID,
    };
    this.props.Savedraft(data);
  };

  render() {
    const { outline_detail, outline_versions } = this.props;
    const isWritting = this.state.isWritting;
    const currentVersion = "Version " + parseInt(this.state.version);
    return (
      <>
        <Row>
          <Col xl="9">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <div>
                  <h2>
                    {outline_detail.data && outline_detail.data.angle_title}
                  </h2>
                  {outline_detail.data.status_article &&
                    outline_detail.data.status_article.includes("CONTENT") && (
                      <Link
                        to={`/admin/writer/writer-writing/${this.state.id}`}
                      >
                        <h5 className="text-primary">{"Back to content >>"}</h5>
                      </Link>
                    )}
                </div>
                <VersionSelect
                  label={currentVersion}
                  versions={outline_versions}
                  onVersionSelect={this._handleOnVersionSelect}
                />
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Row>
                    <Col lg="10">
                      <label
                        className="form-control-label display-inline pb-2"
                        htmlFor="lastname"
                      >
                        Write Outline
                      </label>
                    </Col>
                    <Col lg="2" className="text-right">
                      {this.props.outline_detail.data.status_article ===
                      "OUTLINE_SUBMITTED" ? (
                        ""
                      ) : (
                        <a
                          href="#pablo"
                          style={{ fontWeight: "bold" }}
                          onClick={this._handleOnSaveDraftOutline}
                        >
                          <p>Save draft</p>
                        </a>
                      )}
                    </Col>
                  </Row>
                  <Form>
                    <CustomEditor
                      toolbar="false"
                      menubar="false"
                      content={this.state.data.content}
                      onChangeContent={this._handleOnContentChange}
                      readOnly={!isWritting}
                    />
                  </Form>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3" className="toolbar-right">
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                className="btn-lg"
                color="success"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
                disabled={!isWritting}
                onClick={this._handleOnSubmitOutline}
              >
                <i className="ni ni-send mr-2"></i>
                Submit Outline
              </Button>
            </div>
            <Feedback article_id={this.state.id} />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outline_detail: state.editorArticleReducer.outline_detail_id,
    outline_versions: state.writerArticleReducer.outline_versions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOutlineDetailByID: (id) => {
      dispatch(actGetOutlineDetailIDRequest(id));
    },
    submitOutline: (id, data) => {
      dispatch(actSubmitOutlineRequest(id, data));
    },
    Savedraft: (data) => {
      dispatch(actSaveDraftOutlineRequest(data));
    },
    autoSave: (data) => {
      dispatch(actAutoSaveOutlineRequest(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutlineVersionDetail);
OutlineVersionDetail.propTypes = {
  outline_detail: PropTypes.object,
  outline_versions: PropTypes.array,
  getOutlineDetailByID: PropTypes.func,
  submitOutline: PropTypes.func,
  Savedraft: PropTypes.func,
  autoSave: PropTypes.func,
};
OutlineVersionDetail.defaultProps = {};
