import React from "react";
import isEmpty from "lodash/isEmpty";
import { Form, Input, Icon, Button, message } from "antd";
import { Row, Col } from "reactstrap";
import "../styles.scss";

let id = 0;
class NewPollManager extends React.Component {
  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    if (keys.length === 1) {
      return;
    }
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.keys.length <= 1) {
          message.error("Please add more than 2 answers to complete a poll!");
          return;
        }
        const answers = values.names.map(item => {
          return {
            content: item
          };
        });
        const answerSanitize = answers.filter(item => !isEmpty(item));
        const poll = {
          answers: answerSanitize,
          question: values.question
        };
        this.props.onChangePoll(poll);
        message.success(
          "Created poll successfully. Please hit save setting to save poll!"
        );
      }
    });
  };
  renderAnswerLength = value => {
    if (!value || value.length === 0) {
      return `0/100`;
    }
    return `${value.length}/100`;
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator("keys", {
      initialValue: []
    });

    const keys = getFieldValue("keys");
    const disableAddAnswer = keys.length >= 5;
    const questionValue = getFieldValue("question") || 0;
    const formItems = keys.map((k, index) => (
      <Form.Item label={index === 0 ? "Answers" : ""} required={false} key={k}>
        {getFieldDecorator(`names[${k}]`, {
          validateTrigger: ["onChange", "onBlur"],
          rules: [
            {
              required: true,
              max: 100,
              whitespace: true,
              message: "Invalid field. Please check again!"
            }
          ]
        })(
          <div className="d-flex align-items-center">
            <Input
              placeholder="Answer"
              style={{ width: "60%", marginRight: 8 }}
            />
            <span className="mx-2">
              {this.renderAnswerLength(getFieldValue(`names[${k}]`))}
            </span>
            {keys.length > 1 ? (
              <Icon
                style={{ color: "red" }}
                className="dynamic-delete-button"
                type="minus-circle-o"
                onClick={() => this.remove(k)}
              />
            ) : null}
          </div>
        )}
      </Form.Item>
    ));
    return (
      <Form onSubmit={this.onSubmit}>
        <Row>
          <Col lg="12">
            <Form onSubmit={this.handleSubmit}>
              <Form.Item style={{ width: "60%" }}>
                {getFieldDecorator("question", {
                  rules: [
                    {
                      required: true,
                      max: 100,
                      message: "Invalid field. Please check again!"
                    }
                  ]
                })(<Input placeholder="Question" />)}
                <span className="float-right">
                  {`${questionValue.length || 0}/100`}
                </span>
              </Form.Item>
              {formItems}
              <Form.Item>
                <Button
                  type="dashed"
                  disabled={disableAddAnswer}
                  onClick={this.add}
                  style={{ width: "60%" }}
                >
                  <Icon type="plus" /> Add anwser
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedPollManager = Form.create({ name: "dynamic_form_item" })(
  NewPollManager
);
export default WrappedPollManager;
