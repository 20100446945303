import { message } from 'antd';
import React from "react";

function TagValidation(tags) {
  if (!tags || tags && tags.length === 0) {
    message.error('Please choose one tag');
    return true;
  }
  const findNode = tags.find(e => e.type === 'OFFICIAL_TAG');
  if (!findNode) {
    message.error('Please choose one official tag');
    return true;
  }
}
export default TagValidation;