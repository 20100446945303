import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
// core components
import AuthFooter from "components/Footers/AuthFooter.jsx";
import { connect } from "react-redux";
import routes from "routes.js";
import { actResetFetchResource } from "../actions/fetch.action";
import AlertSuccess from "components/SweetAlert/AlertSuccess";
import AlertError from "components/SweetAlert/AlertError";
// import LOGIN from "assets/img/login.png";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
    // document.body.style.backgroundImage = `url(${LOGIN})`;
    // document.body.style.backgroundRepeat = "no-repeat";
    // document.body.style.backgroundSize = "cover";
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.fetchSuccess) {
      this.setState({ fetchSuccess: true });
    } else if (nextProps.fetchSuccess === null) {
      this.setState({ fetchFail: false });
    } else if (nextProps.fetchFail) {
      this.setState({ fetchFail: true });
    } else if (nextProps.fetchFail) {
      this.setState({ fetchFail: false });
    }
    if (nextProps.notify !== null && nextProps.notify !== this.props.notify) {
      this.notify(nextProps.notify.message, nextProps.notify.color);
    }
  }
  notify(message, color, place = "tr") {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: color,
      icon: "ni ni-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin/login") {
        return (
          <Route path={prop.layout} component={prop.component} key={key} />
        );
      }
      if (prop.layout === "/admin/updatePassword") {
        return (
          <Route path={prop.layout} component={prop.component} key={key} />
        );
      }
      if (prop.layout === "/admin/forgotPassword") {
        return (
          <Route path={prop.layout} component={prop.component} key={key} />
        );
      }
      if (prop.layout === "/admin/ChangeNewPassword") {
        return (
          <Route path={prop.layout} component={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    const props = this.props;
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        {props.fetchSuccess && (
          <AlertSuccess
            {...props}
            message={props.fetchSuccess.message}
            confirmTo={props.fetchSuccess.confirmTo}
            resetFetchResource={props.resetFetchResource}
          />
        )}
        {props.fetchFail && (
          <AlertError
            {...props}
            message={props.fetchFail.message}
            confirmTo={props.fetchFail.confirmTo}
            resetFetchResource={props.resetFetchResource}
          />
        )}
        <div className="main-content" ref="mainContent">
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
        <AuthFooter />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    fetchSuccess: state.fetchReducer.fetchSuccess,
    fetchFail: state.fetchReducer.fetchFail,
    notify: state.fetchReducer.notify
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetFetchResource: () => {
      dispatch(actResetFetchResource());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
