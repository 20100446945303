import React, { Component } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import CardsHeaderEditor from "../../../components/Headers/CardsHeaderEditor";
import EditorPublishedArticlesDetail from "../../../components/Detail/EditorPublishedArticlesDetail";
class EditorPublishedArticles extends Component {
  state = {
    pathname: window.location.pathname.split("/")[3],
  };
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderEditor
          name="Published Article Detail"
          content="publishedArticleDetail"
          parentName="Article"
        />

        <Container className="mt--7" fluid>
          <EditorPublishedArticlesDetail {...props} />
        </Container>
      </>
    );
  }
}
EditorPublishedArticles.propTypes = {
  content: PropTypes.string,
};
export default EditorPublishedArticles;
