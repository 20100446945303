const languageOption = [
  { label: "Vietnamese", value: "VN" },
  { label: "English", value: "EN" },
];

const labelOption = [
  { label: "No badge", value: "" },
  { label: "18+ Warning", value: "18+" },
  { label: "Block Ads", value: "block-ads" },
  { label: "Advertising", value: "advertising" },
];

export { languageOption, labelOption };
