import React, { Component } from "react";
import { Card, CardHeader, Table, Col, Row, CardFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import { actGetEditorWriterProfileRequest } from "../../actions/editorArticle.action";
import Avatar from "../Avatar/Avatar";
import { actGetListTopicRequest } from "../../actions/employee.action";
import Select from "react-select";
// const URL_IMG = "http://35.185.181.184/";
class EditorWriterProfileTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : -1,
      topic: getUrlParam()["topic"] ? getUrlParam()["topic"] : ""
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._handlePathnamePush(this.state.params);
    this._getEditorWriterProfile();
    this.props.getListTopic();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getEditorWriterProfile = () => {
    const params = this.state.params;
    this.props.getEditorWriterProfile(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getEditorWriterProfile(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getEditorWriterProfile(paramsSort);
  };

  _handleOnFilter = (label, value) => {
    const state = this.state;
    const params = {
      ...state.params,
      [label]: value
    };
    this.setState({
      params: {
        ...state.params,
        [label]: value
      }
    });
    this._handlePathnamePush(params);
    this.props.getEditorWriterProfile(params);
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/editor/writer-information/${id}`);
  };

  render() {
    const { editor_writer_profile } = this.props;
    const page = editor_writer_profile.data && editor_writer_profile.data.page;
    const totalPages =
      editor_writer_profile.data && editor_writer_profile.data.totalPages;
    const totalDocs =
      editor_writer_profile.data && editor_writer_profile.data.totalDocs;
    const { expertiseOption } = this.props;

    const renderStatus = status => {
      switch (status) {
        case "WORKING":
          return (
            <>
              <span className="status-completed">●</span>
              <span>Working</span>
            </>
          );
        case "STOP_WORKING":
          return (
            <>
              <span className="status-cancel">●</span>
              <span>Not working</span>
            </>
          );
        default:
      }
      return null;
    };
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col md="3">
                    <Select
                      className="react-select filter"
                      classNamePrefix="react-select"
                      name="topic"
                      placeholder="Select interest"
                      options={expertiseOption}
                      value={expertiseOption.filter(
                        ({ value }) => value === this.state.params.topic
                      )}
                      size="lg"
                      onChange={value =>
                        this._handleOnFilter("topic", value.value)
                      }
                    />
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {editor_writer_profile.data.docs.length !== 0 && (
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("name")}
                        >
                          Writer
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("expertise")}
                        >
                          Level of Expertise
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("articles")}
                        >
                          Article
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("phone")}
                        >
                          Phone
                        </th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("mail")}
                        >
                          Mail
                        </th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {editor_writer_profile.data
                        ? editor_writer_profile.data.docs.map((prop, key) => {
                            return (
                              <>
                                <tr
                                  key={key}
                                  onClick={e =>
                                    this._handleOnCellClick(e, prop._id)
                                  }
                                  className="cursor-pointer"
                                >
                                  <td className="table-user">
                                    <Avatar
                                      src={prop.avatar}
                                      className="d-inline-block mr-3"
                                    />
                                    <b>{prop.name}</b>
                                  </td>
                                  <td className="budget">
                                    {prop.expertise[0]}
                                  </td>
                                  <td>{prop.articles}</td>
                                  <td>{prop.phone}</td>
                                  <td>{prop.email}</td>
                                  <td>{renderStatus(prop.status)}</td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                )}

                {editor_writer_profile.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    editor_writer_profile: state.editorArticleReducer.editor_writer_profile,
    expertiseOption: state.employeeReducer.expertiseOption
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getListTopic: () => {
      dispatch(actGetListTopicRequest());
    },
    getEditorWriterProfile: params => {
      dispatch(actGetEditorWriterProfileRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorWriterProfileTable);
EditorWriterProfileTable.propTypes = {
  editor_writer_profile: PropTypes.object,
  getEditorWriterProfile: PropTypes.func,
  listTopic: PropTypes.object,
  getListTopic: PropTypes.func,
  expertiseOption: PropTypes.array
};
EditorWriterProfileTable.defaultProps = {};
