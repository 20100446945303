import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// i18n
import I18n from "redux-i18n";
import translations from "translations";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
//antd
import "antd/dist/antd.css";
// core styles
import "antd/dist/antd.css";
import "react-input-range/lib/css/index.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import appReducers from "./reducers/index.reducer";
// redux init

const actionSanitizer = action => {
  const uiRouterActions = /@ui-router.+/g;
  return uiRouterActions.test(action.type)
    ? {
        type: action.type,
        transition: sanitizeUIRouterTransition(action.transition)
      }
    : action;
};

const stateSanitizer = (state: AppState): any => {
  if (state.router && state.router.last && state.router.last) {
    return {
      ...state,
      router: sanitizeUIRouterTransition(state.router.last)
    };
  }
  return state;
};

const sanitizeUIRouterTransition = (transition: Transition): any => ({
  params:
    transition.router &&
    transition.router.globals &&
    transition.router.globals.params,
  current:
    transition.router &&
    transition.router.globals &&
    transition.router.globals.current,
  targetState: transition.targetState && transition.targetState().state(),
  from: transition.from && transition.from(),
  to: transition.to && transition.to()
});

const reduxDevtoolsExtensionOptions = {
  actionSanitizer,
  stateSanitizer
};

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
      reduxDevtoolsExtensionOptions
    )) ||
  compose;

const store = createStore(
  appReducers,
  composeEnhancers(applyMiddleware(thunk))
);

const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route
          path="/admin/login"
          render={props => <AuthLayout {...props} />}
        />
        <Route
          path="/admin/updatePassword"
          render={props => <AuthLayout {...props} />}
        />
        <Route
          path="/admin/forgotPassword"
          render={props => <AuthLayout {...props} />}
        />
        <Route
          path="/admin/ChangeNewPassword"
          render={props => <AuthLayout {...props} />}
        />
        <Route
          path="/admin"
          render={props => (
            <I18n translations={translations} initialLang={lang}>
              <AdminLayout {...props} />
            </I18n>
          )}
        />
        <Redirect from="*" to="/admin" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
