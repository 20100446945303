import React, { useState, useEffect } from "react";
import { Button, Card, Icon, Avatar, Dropdown, Menu } from "antd";
import option from "../../../assets/img/icons/option.svg";
import { stripHTMLTag } from "../../../utils/stripHtml";

import "./styles.scss";

const SlideItem = ({
  title,
  content,
  imageUrl,
  handleEditClick,
  handleDelete,
}) => {
  const menu = () => {
    return (
      <Menu>
        <Menu.Item onClick={handleEditClick} key="1">
          Edit
        </Menu.Item>
        <Menu.Item onClick={handleDelete} className="text-red" key="2">
          Delete
        </Menu.Item>
      </Menu>
    );
  };
  const desc = stripHTMLTag(content);
  const descriptionValue =
    desc.length > 100 ? `${desc.slice(0, 100)} ...` : desc;
  return (
    <Card
      className="slide-item"
      cover={
        <img
          alt="slide-image-cover"
          src={
            imageUrl ||
            "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          }
        />
      }
    >
      <div className="slide-content-wrapper">
        <div className="d-flex justify-content-between align-content-center mb-2">
          <p className="slide-content-wrapper__title">{title}</p>
          <Dropdown overlay={menu} trigger={["click"]}>
            <img className="action-btn" src={option} />
          </Dropdown>
        </div>
        <p className="slide-content-wrapper__desc">{descriptionValue}</p>
      </div>
    </Card>
  );
};
export default SlideItem;
