import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Container, Row } from "reactstrap";
import {
  Popover,
  Button,
  Skeleton,
  Modal,
  Tooltip,
  Select,
  Spin,
  Slider
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import get from "lodash/get";
import debounce from "lodash/debounce";

import {
  actGetOfficialTags,
  actResetStateTagManagement,
  actDeleteSelectedTag,
  actAddNewTag,
  actGetAllTagsInEachGroup,
  actGetReletedTags,
  actUnlinkSelected,
  actGetArticlesTags,
  actUpdateTag,
  actGetTotalTag
} from "../../../actions/tagmanagement.action";

import CardsHeaderChief from "../../../components/Headers/CardsHeaderChief";
import HeaderSearchBar from "../../../components/HeaderSearchBar";
import GroupTag from "../../../components/GroupTag";
import Avatar from "../../../components/Avatar/Avatar";

import option from "../../../assets/img/icons/option.svg";
import exclamationMarkIcon from "../../../assets/img/icons/exclamation-outline.svg";

import "./styles.scss";

const { Option, OptGroup } = Select;

function ChiefOfficialTag({ history }) {
  const useAsyncState = initialValue => {
    const [value, setValue] = useState(initialValue);
    const setter = x =>
      new Promise(resolve => {
        setValue(x);
        resolve(x);
      });
    return [value, setter];
  };

  const debouncedSearchTagByName = useRef(debounce(nextValue => dispatch(
    actGetAllTagsInEachGroup({
      endpoint: `v2/list-tag?type[]=OFFICIAL_TAG&name=${nextValue}`
    })), 1000)).current;

  const dispatch = useDispatch();

  const tags = useSelector(state => state.tagManagementReducer.tags);

  const allTagsInEachGroup = useSelector(
    state => state.tagManagementReducer.allTagsInEachGroup
  );

  const nameOfFetcher = useSelector(
    state => state.tagManagementReducer.nameOfFetcher
  );

  const isLoading = useSelector(state => state.tagManagementReducer.isLoading);

  const tagSearched = useSelector(
    state => state.tagManagementReducer.tagSearched
  );

  const relatedTags = useSelector(
    state => state.tagManagementReducer.relatedTags
  );

  const articlesTags = useSelector(
    state => state.tagManagementReducer.articlesTags
  );

  const createObjData = {};
  const tableHeaderStaticData = [
    "Tag",
    "Number of Article",
    "Related Tags",
    "Created Date"
  ];
  const tableGroupNameStaticData = [
    "Series",
    "Brand",
    "Topics",
    "Need Review",
    "New Tags"
  ];
  const officialTagsToSelect = [];
  const relatedTagsToUnlink = [];

  const [separateEachCurrentPage, setSeparateEachCurrentPage] = useState({});
  const [languageState, setLanguageState] = useState("VN");
  const [visiblePopover, setVisibilityPopover] = useState(false);
  const [popOverUniqueVisibility, setPopoverUniqueVisibility] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalMultipleContentState, setModalMultipleContentState] = useState();
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
  const [modalInputField, setModalInputField] = useState("");
  const [multileDispatchers, setMultipleDispatchers] = useState();
  const [utilityState, setUtilityState] = useState({});
  const [modalFooterChangeButton, setModalFooterChangeButton] = useState(true);
  const [seeMoreContentUtility, setSeeMoreContentUtility] = useState(1);
  const [articlesTagsLocalData, setArticlesTagsLocalData] = useState([]);
  const [priorityChangeVisible, setVisibilityPriorityChange] = useState(false);
  const [modalInputSelector, setModalInputSelector] = useAsyncState("");
  const [relatedTagData, setRelatedData] = useState([]);
  const [selectorDefaultValue, setSelectorDefaultValue] = useState([]);
  const [allowedToDelete, setAllowToDelete] = useState(false);
  const [
    modalInputSelectorDefaultValue,
    setModalInputSelectorDefaultValue
  ] = useAsyncState("");
  const [
    allTagDataFilterGroupTitle,
    setTagDataFilterGroupTitle
  ] = useAsyncState([]);
  const [
    displayPriorityMultipleModals,
    setDisplayPriorityMultipleModals
  ] = useState(true);

  useEffect(() => {
    for (let i = 0; i < tableGroupNameStaticData.length; i += 1) {
      setSeparateEachCurrentPage(state => ({
        ...state,
        [tableGroupNameStaticData[i]]: 1
      }));
    }

    setArticlesTagsLocalData([]);
    dispatch(actGetTotalTag());
    getOfficialTagsManagement(languageState);
    dispatch(
      actGetAllTagsInEachGroup({
        endpoint: "v2/list-tag?type[]=OFFICIAL_TAG"
      })
    );

    return () => {
      dispatch(actResetStateTagManagement());
      setArticlesTagsLocalData([]);
      dispatch(actGetTotalTag());
    };
  }, []);

  useEffect(() => {
    dispatch(
      actGetOfficialTags({
        groupMainTitle: utilityState.name,
        language: languageState,
        page: separateEachCurrentPage[utilityState.name],
        allowedToWipeData: false
      })
    );
  }, [separateEachCurrentPage]);

  useLayoutEffect(() => {
    if (get(relatedTags, "tags")) {
      setRelatedData([...relatedTags.tags]);
    }
  }, [relatedTags]);

  useLayoutEffect(() => {
    if (get(relatedTags, "tags")) {
      setSelectorDefaultValue([...relatedTags.tags.map(item => item.name)]);
    }
  }, [relatedTags]);

  useEffect(() => {
    if (get(articlesTags, "tags")) {
      setArticlesTagsLocalData(state => [...state, ...articlesTags.tags]);
    }
  }, [articlesTags]);

  for (let i = 0; i < tags.length; i += 1) {
    if (get(tags[i], "[tags][0][groupMainTitle]")) {
      createObjData[tags[i]["tags"][0]["groupMainTitle"]] = [];
    }
  }

  useEffect(() => {
    if (get(allTagsInEachGroup, "tags.officialTag")) {
      const localData = allTagsInEachGroup.tags.officialTag
        .filter(filterItem => {
          if (filterItem.name === modalInputField) {
            return true;
          }

          return false;
        })
        .map(mapItem => ({ [mapItem.name]: mapItem.groupMainTitle }));

      setTagDataFilterGroupTitle(localData).then(item =>
        setTagDataFilterGroupTitle(item)
      );
    }
  }, [allTagsInEachGroup, modalMultipleContentState]);

  useEffect(() => {
    if (get(allTagDataFilterGroupTitle, `[0][${modalInputField}]`)) {
      setModalInputSelectorDefaultValue(
        allTagDataFilterGroupTitle[0][modalInputField]
      ).then(item => setModalInputSelectorDefaultValue(item));

      setModalInputSelector(
        allTagDataFilterGroupTitle[0][modalInputField]
      ).then(item => setModalInputSelector(item));
    }
  }, [allTagDataFilterGroupTitle, modalMultipleContentState]);

  useEffect(() => {
    for (let i = 0; i < tags.length; i += 1) {
      if (get(tags[i], "[tags][0][groupMainTitle]")) {
        if (tags[i]["isNextpaging"]) {
        } else {
        }

        createObjData[tags[i]["tags"][0]["groupMainTitle"]].push(
          ...tags[i]["tags"]
        );
      }
    }
  }, [tags]);

  for (let i = 0; i < tags.length; i += 1) {
    if (get(tags[i], "[tags][0][groupMainTitle]")) {
      createObjData[tags[i]["tags"][0]["groupMainTitle"]].push(
        ...tags[i]["tags"]
      );
    }
  }

  if (get(relatedTags, "tags")) {
    relatedTags.tags.map(item => relatedTagsToUnlink.push(item));
  }

  if (get(allTagsInEachGroup, "tags.officialTag")) {
    officialTagsToSelect.push(
      ...allTagsInEachGroup.tags.officialTag.map(item => {
        return (
          <Option
            disabled={item.name === modalInputField ? true : false}
            key={item.name}
          >
            {item.name}
          </Option>
        );
      })
    );
  }

  const getOfficialTagsManagement = language => {
    for (let i = 0; i < tableGroupNameStaticData.length; i += 1) {
      dispatch(
        actGetOfficialTags({
          groupMainTitle: tableGroupNameStaticData[i],
          language,
          page: 1,
          allowedToWipeData: true
        })
      );
    }
  };

  const onModalInputFieldChange = e => {
    setModalInputField(e.target.value);
  };

  const onFetchNextPageEachDispatcher = name => {
    setUtilityState({ name });
    setSeparateEachCurrentPage(state => ({
      ...state,
      ...(separateEachCurrentPage[name] += 1)
    }));
  };

  const onAddNewOfficialTag = () => {
    setModalInputSelector("");
    setModalInputSelectorDefaultValue("");
    setModalTitle("Add New Official Tag");
    setMultipleDispatchers("onAddNewOfficialTag");
    setModalMultipleContentState("onAddNewOfficialTag");
    setModalVisibility(true);
    setModalFooterChangeButton(true);
    setVisibilityPriorityChange(true);
  };

  const onEditSelectedTag = (id, name) => {
    dispatch(actGetReletedTags({ id }));

    dispatch(
      actGetAllTagsInEachGroup({
        endpoint: "v2/list-tag?type[]=OFFICIAL_TAG"
      })
    );

    setModalInputField(name);
    setUtilityState({ id });
    setModalTitle("Edit Tag");
    setVisibilityPriorityChange(true);
    setMultipleDispatchers("onEditSelectedTag");
    setModalMultipleContentState("onEditSelectedTag");
    setModalVisibility(true);
    setVisibilityPopover(false);
    setModalFooterChangeButton(true);
  };

  const onDeleteSelectedTag = (name, id) => {
    setAllowToDelete(true);
    setModalTitle("Do you want to delete?");
    setMultipleDispatchers("onDeleteSelectedTag");
    setModalMultipleContentState("onDeleteSelectedTag");
    setModalVisibility(true);
    setModalFooterChangeButton(false);
    setVisibilityPopover(false);
    setUtilityState({ id, name });
  };

  const visiblePopoverHandler = (value, display) => {
    setPopoverUniqueVisibility(value);
    setVisibilityPopover(display);
  };

  const onChangeLanguageProp = value => {
    setLanguageState(value);
    dispatch(actResetStateTagManagement());
    getOfficialTagsManagement(value);
  };

  useEffect(() => {
    if (get(utilityState, "getNumberOfArticle")) {
      dispatch(
        actGetArticlesTags({
          id: utilityState.id,
          page: seeMoreContentUtility
        })
      );
    }
  }, [seeMoreContentUtility]);

  const onGetNumberOfArticle = value => {
    setUtilityState({ id: value, getNumberOfArticle: true });
    setTimeout(() => {
      setModalMultipleContentState("onGetNumberOfArticle");
      dispatch(actGetArticlesTags({ id: value, page: seeMoreContentUtility }));
      setModalVisibility(true);
      setModalTitle("Articles Tags");
      setModalFooterChangeButton(false);
    }, 300);
  };

  const onGetNumberOfRelatedTag = value => {
    setDisplayPriorityMultipleModals(false);

    setTimeout(() => {
      dispatch(actGetReletedTags({ id: value }));
      setModalVisibility(true);
      setModalTitle("Related Tags");
      setModalFooterChangeButton(false);
      setModalMultipleContentState("onGetNumberOfRelatedTag");
      setUtilityState({ id: value });
    }, 300);
  };

  const renderGroupTag = () => {
    let cloneLocalData;

    if (createObjData) {
      cloneLocalData = tableGroupNameStaticData.map(
        item => createObjData[item]
      );
    }

    return (
      <>
        {cloneLocalData.length > 0 &&
          cloneLocalData.map(item => (
            <>
              <GroupTag
                groupTitle={get(item, '[0]["groupMainTitle"]')}
                groupContent={
                  item &&
                  item.map(subItem => (
                    <>
                      <tr
                        className={
                          item[0]["groupMainTitle"]
                            .replace(/\s+/g, "-")
                            .toLowerCase() + "-content"
                        }
                        key={subItem._id}
                      >
                        <td>{subItem.name}</td>
                        <td
                          onClick={
                            get(subItem, "totalOfArticle", "0") > 0
                              ? () => onGetNumberOfArticle(subItem._id)
                              : () => {}
                          }
                          className="text-center btn btn-link d-table-cell"
                        >
                          {get(subItem, "totalOfArticle", "0")}
                        </td>
                        <td
                          onClick={
                            get(subItem, "numberOfRelatedTag", "0") > 0
                              ? () => onGetNumberOfRelatedTag(subItem._id)
                              : () => {}
                          }
                          className="text-center btn btn-link d-table-cell"
                        >
                          {get(subItem, "numberOfRelatedTag", "0")}
                        </td>
                        <td>
                          {moment(subItem.createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          <Popover
                            visible={
                              popOverUniqueVisibility === subItem._id &&
                              visiblePopover
                            }
                            onVisibleChange={value =>
                              visiblePopoverHandler(subItem._id, value)
                            }
                            content={
                              <section className="d-flex align-items-start flex-column">
                                <Button
                                  onClick={() =>
                                    onEditSelectedTag(subItem._id, subItem.name)
                                  }
                                  className="mr-5 pl-0 border-0 shadow-none"
                                  type="primary"
                                  ghost
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() =>
                                    onDeleteSelectedTag(
                                      subItem.name,
                                      subItem._id
                                    )
                                  }
                                  className="mt-2 pl-0 mr-5 border-0 shadow-none"
                                  type="danger"
                                  ghost
                                >
                                  Delete
                                </Button>
                              </section>
                            }
                            trigger="click"
                            placement="bottomRight"
                            arrowPointAtCenter
                          >
                            <Button type="link">
                              <img
                                className="action-btn"
                                src={option}
                                alt="more"
                              />
                            </Button>
                          </Popover>
                        </td>
                      </tr>
                    </>
                  ))
                }
              />
              <Button
                name={get(item, '[0]["groupMainTitle"]')}
                className={`
                  ${get(item, '[0]["groupMainTitle"]') &&
                    item[0]["groupMainTitle"]
                      .replace(/\s+/g, "-")
                      .toLowerCase() + "-content"}

                  ${nameOfFetcher.includes(
                    get(item, '[0]["groupMainTitle"]')
                  ) && "d-none"}

                  ${!get(item, '[0]["groupMainTitle"]') && "d-none"}
                `}
                onClick={() => {
                  onFetchNextPageEachDispatcher(
                    get(item, '[0]["groupMainTitle"]')
                  );
                }}
              >
                See More...
              </Button>
            </>
          ))}
      </>
    );
  };

  const onChageRangeOfPriority = (value, e) => {
    const localData = JSON.parse(JSON.stringify(relatedTagData));

    for (let i = 0; i < localData.length; i += 1) {
      if (localData[i]["name"] === value) {
        localData[i]["priority"] = e;
      }
    }

    setRelatedData([...localData]);
  };

  const onSelectForSelector = value => {
    if (value === modalInputField) {
      return;
    }

    if (get(allTagsInEachGroup, "tags.officialTag")) {
      for (let i = 0; i < allTagsInEachGroup.tags.officialTag.length; i += 1) {
        if (allTagsInEachGroup.tags.officialTag[i]["name"] === value) {
          const localData = JSON.parse(JSON.stringify(relatedTagData));

          localData.push({
            name: allTagsInEachGroup.tags.officialTag[i]["name"],
            _id: allTagsInEachGroup.tags.officialTag[i]["id"],
            priority: 1
          });

          setRelatedData([...localData]);
        }
      }
    }
  };

  const onDeselectSelector = value => {
    const index = relatedTagData.findIndex(item => item["name"] === value);

    if (index !== -1) {
      relatedTagData.splice(index, 1);

      setRelatedData([...relatedTagData]);
    }
  };

  const AddNewTagModalOnSave = () => {
    const relatedTagsDataClone = JSON.parse(
      JSON.stringify(
        relatedTagData.map(item => ({
          tagId: item._id,
          priority: item.priority
        }))
      )
    );

    setModalConfirmLoading(true);

    dispatch(
      actAddNewTag({
        data: {
          name: modalInputField,
          groupMainTitle: modalInputSelector,
          language: languageState,
          relatedTags: [
            ...relatedTagsDataClone.map(item => {
              if (item.name) {
                delete item.name;
              }

              return item;
            })
          ]
        },
        endpoint: "v2/tags/"
      })
    );

    dispatch(actResetStateTagManagement());

    setTimeout(() => {
      setModalVisibility(false);
      setModalConfirmLoading(false);
      dispatch(actResetStateTagManagement());
      getOfficialTagsManagement(languageState);
      modalCloseWipeData();
    }, 2500);
  };

  const onUpdateSelectedTag = () => {
    const relatedTagsDataClone = JSON.parse(
      JSON.stringify(
        relatedTagData.map(item => ({
          tagId: item._id,
          priority: item.priority
        }))
      )
    );

    setModalConfirmLoading(true);

    dispatch(
      actUpdateTag({
        endpoint: "v2/tags/",
        dispatchData: {
          tagId: utilityState.id,
          language: languageState,
          groupMainTitle: modalInputSelector,
          name: modalInputField,
          relatedTags: [
            ...relatedTagsDataClone.map(item => {
              if (item.name) {
                delete item.name;
              }

              return item;
            })
          ]
        }
      })
    );

    dispatch(actResetStateTagManagement());

    setTimeout(() => {
      setModalVisibility(false);
      setModalConfirmLoading(false);
      dispatch(actResetStateTagManagement());
      getOfficialTagsManagement(languageState);
      modalCloseWipeData();
    }, 2500);
  };

  const onUnlinkSelectedTag = value => {
    dispatch(
      actUnlinkSelected({
        tagId: utilityState.id,
        relatedTagId: value
      })
    );

    setVisibilityPopover(false);

    setModalConfirmLoading(true);

    dispatch(actResetStateTagManagement());

    setTimeout(() => {
      setModalConfirmLoading(false);
      setModalVisibility(false);

      getOfficialTagsManagement(languageState);
    }, 500);
  };

  const deleteSelectedTagDispatcher = () => {
    setModalConfirmLoading(true);
    dispatch(
      actDeleteSelectedTag({
        id: utilityState.id,
        endpoint: "v2/remove-tag"
      })
    );
    dispatch(actResetStateTagManagement());

    setTimeout(() => {
      setModalVisibility(false);
      setModalConfirmLoading(false);
      dispatch(actResetStateTagManagement());
      getOfficialTagsManagement(languageState);
    }, 2500);
  };

  const modalArticleTags = () => {
    return (
      <>
        <table className="w-100" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              {["Article", "Author", "Added on"].map(item => (
                <th
                  key={item}
                  className={
                    item === "Added on" || (item === "Author" && "text-center")
                  }
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {articlesTagsLocalData &&
              articlesTagsLocalData.map(item => (
                <tr key={item}>
                  <td
                    onClick={() => {
                      history.push({
                        pathname: `/admin/chief/un-published-review/${item._id}`,
                        state: {}
                      });
                    }}
                    className="btn-link pr-4 cursor-pointer d-inline-block mt-4"
                    style={{ wordWrap: "break-word" }}
                  >
                    {item.title}
                  </td>
                  <td className="p-3">
                    <section className="d-flex align-items-center w-75 space-between h4 justify-content-around">
                      <Avatar className="" src={get(item, "writer.avatar")} />
                      {get(item, "writer.penname")}
                    </section>
                  </td>
                  <td className="italic-text">
                    {moment(item.createdAt).format("DD/MM/YYYY")}
                  </td>
                </tr>
              ))}
            {get(articlesTags, "tags") && articlesTags.isNextpaging && (
              <Button
                onClick={() =>
                  setSeeMoreContentUtility(seeMoreContentUtility + 1)
                }
              >
                See More...
              </Button>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const modalRelatedTags = () => {
    return (
      <>
        <table className="w-100" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              {["Tag", "Number of Article", "Added date"].map(item => (
                <th
                  key={item}
                  className={
                    item === "Added date" ||
                    (item === "Number of Article" && "text-center")
                  }
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {relatedTagsToUnlink.map(item => (
              <tr key={item}>
                <td style={{ wordWrap: "break-word" }} className="h4">
                  {item.name}
                </td>
                <td className="text-center blue-color-001DC">
                  {item.totalOfArticle}
                </td>
                <td className="italic-text">
                  {moment(item.createdAt).format("DD/MM/YYYY")}
                </td>
                <td>
                  <Popover
                    visible={
                      popOverUniqueVisibility === item._id + "sub" &&
                      visiblePopover
                    }
                    onVisibleChange={value =>
                      visiblePopoverHandler(item._id + "sub", value)
                    }
                    content={
                      <section className="d-flex align-items-start flex-column">
                        <Button
                          onClick={() => onUnlinkSelectedTag(item._id)}
                          className="mt-2 pl-0 mr-5 border-0 shadow-none"
                          type="danger"
                          ghost
                        >
                          Unlink
                        </Button>
                      </section>
                    }
                    trigger="click"
                    placement="bottomRight"
                    arrowPointAtCenter
                  >
                    <Button type="link">
                      <img className="action-btn" src={option} alt="more" />
                    </Button>
                  </Popover>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const modalInputFieldUI = () => {
    return (
      <>
        <form>
          <label className="d-flex">
            Tag Name (<div className="text-danger">*</div>)
          </label>
          <input
            className="ant-input mb-3"
            onChange={onModalInputFieldChange}
            value={modalInputField}
          />

          <div className="d-flex align-items-center">
            <label className="m-0 d-flex">Related Tag</label>
            <Tooltip
              placement="bottom"
              title={
                "These tags will be recommended to readers when they are viewing the tag you are creating."
              }
              trigger="hover"
            >
              <Button type="link">
                <img src={exclamationMarkIcon} alt="exclamation mark" />
              </Button>
            </Tooltip>
          </div>

          {selectorDefaultValue.length === 0 && (
            <Select
              onSearch={(value) => debouncedSearchTagByName(value)}
              className="mb-3 w-100 h-100"
              mode="multiple"
              placeholder="Add Related Tag"
              onDeselect={value => onDeselectSelector(value)}
              onSelect={value => onSelectForSelector(value)}
              onMouseEnter={() => {
                setTimeout(() => {
                  dispatch(
                    actGetAllTagsInEachGroup({
                      endpoint: "v2/list-tag?type[]=OFFICIAL_TAG"
                    })
                  );
                }, 300);
              }}
            >
              <OptGroup label="OFFICIAL TAGS">{officialTagsToSelect}</OptGroup>
            </Select>
          )}
          {selectorDefaultValue.length > 0 && (
            <Select
              onSearch={(value) => debouncedSearchTagByName(value)}
              className="mb-3 w-100 h-100"
              mode="multiple"
              placeholder="Add Related Tag"
              onDeselect={value => onDeselectSelector(value)}
              defaultValue={selectorDefaultValue}
              onSelect={value => onSelectForSelector(value)}
              onMouseEnter={() => {
                setTimeout(() => {
                  dispatch(
                    actGetAllTagsInEachGroup({
                      endpoint: "v2/list-tag?type[]=OFFICIAL_TAG"
                    })
                  );
                }, 300);
              }}
            >
              <OptGroup label="OFFICIAL TAGS">{officialTagsToSelect}</OptGroup>
            </Select>
          )}

          <div className="d-flex align-items-center">
            <label className="m-0 d-flex">
              Group Tag (<div className="text-danger">*</div>)
            </label>
            <Tooltip
              placement="bottom"
              title={
                "Choose the group that your tag belongs to. This will help you with finding and managing tags on CMS."
              }
              trigger="hover"
            >
              <Button type="link">
                <img src={exclamationMarkIcon} alt="exclamation mark" />
              </Button>
            </Tooltip>
          </div>

          {modalInputSelectorDefaultValue.length === 0 && (
            <Select
              showSearch
              className="mb-3 w-100"
              placeholder="Select Group Tag"
              optionFilterProp="children"
              onChange={value => setModalInputSelector(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {["Series", "Brand", "Topics"].map(item => (
                <Option className="my-2" key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          )}

          {modalInputSelectorDefaultValue.length > 0 && (
            <Select
              showSearch
              defaultValue={modalInputSelectorDefaultValue}
              className="mb-3 w-100"
              placeholder="Select Group Tag"
              optionFilterProp="children"
              onChange={value => setModalInputSelector(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {["Series", "Brand", "Topics"].map(item => (
                <Option className="my-2" key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          )}

          <p className="h5 text-danger">(*) input fields are required!</p>
        </form>
      </>
    );
  };

  const modalMultipleContents = () => {
    switch (modalMultipleContentState) {
      case "onAddNewOfficialTag":
        return modalInputFieldUI();

      case "onDeleteSelectedTag":
        return <p className="h2">{utilityState.name}</p>;

      case "onGetNumberOfRelatedTag":
        return modalRelatedTags();

      case "onGetNumberOfArticle":
        return modalArticleTags();

      case "onEditSelectedTag":
        return modalInputFieldUI();

      default:
        return () => {};
    }
  };

  const onSaveModal = () => {
    switch (multileDispatchers) {
      case "onAddNewOfficialTag":
        return () => AddNewTagModalOnSave();

      case "onDeleteSelectedTag":
        return () => deleteSelectedTagDispatcher();

      case "onEditSelectedTag":
        return () => onUpdateSelectedTag();

      default:
        return () => {};
    }
  };

  const modalCloseWipeData = () => {
    setAllowToDelete(false);
    setModalInputSelectorDefaultValue("");
    setSelectorDefaultValue([]);
    setRelatedData([]);
    setDisplayPriorityMultipleModals(true);
    setModalTitle();
    setModalInputField("");
    setModalMultipleContentState();
    setModalVisibility();
    setModalInputSelector("");
    setSeeMoreContentUtility(1);
    setArticlesTagsLocalData([]);
    setUtilityState({});
    setVisibilityPriorityChange(false);
  };

  return (
    <>
      <Modal
        width={720}
        title={modalTitle}
        visible={modalVisibility}
        confirmLoading={modalConfirmLoading}
        onOk={onSaveModal()}
        onCancel={() => modalCloseWipeData()}
        footer={
          modalFooterChangeButton ? (
            <>
              <Button onClick={() => modalCloseWipeData()}>Cancel</Button>
              <Button
                disabled={
                  modalInputField.length > 0 && modalInputSelector.length > 0
                    ? false
                    : true
                }
                onClick={onSaveModal()}
                loading={modalConfirmLoading}
                type="primary"
              >
                Save
              </Button>
            </>
          ) : (
            allowedToDelete && (
              <>
                <Button onClick={() => modalCloseWipeData()}>Cancel</Button>
                <Button
                  onClick={onSaveModal()}
                  loading={modalConfirmLoading}
                  type="primary"
                >
                  Save
                </Button>
              </>
            )
          )
        }
      >
        {modalMultipleContents()}
        {priorityChangeVisible &&
          displayPriorityMultipleModals &&
          relatedTagData.length > 0 &&
          modalInputField && (
            <p className="h3">
              {`How related is this tag to “${modalInputField}”?`}
            </p>
          )}

        {priorityChangeVisible &&
          displayPriorityMultipleModals &&
          relatedTagData.length > 0 &&
          relatedTagData.map(item => (
            <div
              className="d-flex justify-content-center align-items-center"
              key={get(item, "_id")}
            >
              <p className="pr-4" style={{ width: "150px" }}>
                {item.name}
              </p>
              <Slider
                defaultValue={item.priority}
                value={item.priority}
                onChange={e => onChageRangeOfPriority(item.name, e)}
                className="w-100"
                marks={{ 1: 1, 5: 5 }}
                min={1}
                max={5}
              />
            </div>
          ))}

        {priorityChangeVisible &&
          displayPriorityMultipleModals &&
          relatedTagData.length === 0 &&
          relatedTagData.map(item => (
            <div
              className="d-flex justify-content-center align-items-center"
              key={get(item, "_id")}
            >
              <p className="pr-4" style={{ width: "150px" }}>
                {item.name}
              </p>
              <Slider
                value={item.priority}
                onChange={e => onChageRangeOfPriority(item.name, e)}
                className="w-100"
                marks={{ 1: 1, 5: 5 }}
                min={1}
                max={5}
              />
            </div>
          ))}
      </Modal>
      <section className="chief-official-tag-container">
        <CardsHeaderChief
          name="Official Tags"
          content="official tags"
          parentName="Tags"
        />
        <Container className="mt--6" fluid>
          <HeaderSearchBar
            mainButtonTitle={"Add Official Tag"}
            defaultLanguage={languageState}
            onAddNewTag={onAddNewOfficialTag}
            onChangeLanguageProp={onChangeLanguageProp}
            typeOfTags={"OFFICIAL_TAG"}
          />
          <Row className="rounded-custom-bottom px-5 py-3 position-relative bg-white">
            <table className="w-100" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  {tableHeaderStaticData.map(item => (
                    <th style={{ wordWrap: "break-word" }} key={item}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              {isLoading ? (
                <>
                  <tbody>
                    <tr>
                      <td colSpan={tableHeaderStaticData.length}>
                        <Skeleton active />
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <>
                  <tbody>
                    {tagSearched && get(tagSearched, "[0].data.tags") ? (
                      tagSearched.map(item => (
                        <>
                          {item.data.tags.length >= 1 && (
                            <GroupTag
                              key={item.id}
                              groupTitle={
                                item.data.tags.length >= 1 &&
                                item.data.tags[0]["groupMainTitle"]
                              }
                              groupBeingUsed={item.totalDocs}
                              groupContent={item.data.tags.map(subItem => (
                                <tr
                                  style={{ wordWrap: "break-word" }}
                                  className={
                                    item.data.tags[0].groupMainTitle
                                      .replace(/\s+/g, "-")
                                      .toLowerCase() + "-content"
                                  }
                                  key={subItem._id}
                                >
                                  <td style={{ wordWrap: "break-word" }}>
                                    {subItem.name}
                                  </td>
                                  <td
                                    onClick={
                                      get(subItem, "totalOfArticle", "0") > 0
                                        ? () =>
                                            onGetNumberOfArticle(subItem._id)
                                        : () => {}
                                    }
                                    className="text-center btn btn-link d-table-cell"
                                  >
                                    {get(subItem, "totalOfArticle", "0")}
                                  </td>
                                  <td
                                    onClick={
                                      get(subItem, "numberOfRelatedTag", "0") >
                                      0
                                        ? () =>
                                            onGetNumberOfRelatedTag(subItem._id)
                                        : () => {}
                                    }
                                    className="text-center btn btn-link d-table-cell"
                                  >
                                    {get(subItem, "numberOfRelatedTag", "0")}
                                  </td>
                                  <td>
                                    {moment(subItem.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <Popover
                                      visible={
                                        popOverUniqueVisibility ===
                                          subItem._id && visiblePopover
                                      }
                                      onVisibleChange={value =>
                                        visiblePopoverHandler(
                                          subItem._id,
                                          value
                                        )
                                      }
                                      content={
                                        <section className="d-flex align-items-start flex-column">
                                          <Button
                                            onClick={() =>
                                              onEditSelectedTag(
                                                subItem._id,
                                                subItem.name
                                              )
                                            }
                                            className="mr-5 pl-0 border-0 shadow-none"
                                            type="primary"
                                            ghost
                                          >
                                            Edit
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              onDeleteSelectedTag(
                                                subItem.name,
                                                subItem._id
                                              )
                                            }
                                            className="mt-2 pl-0 mr-5 border-0 shadow-none"
                                            type="danger"
                                            ghost
                                          >
                                            Delete
                                          </Button>
                                        </section>
                                      }
                                      trigger="click"
                                      placement="bottomRight"
                                      arrowPointAtCenter
                                    >
                                      <Button type="link">
                                        <img
                                          className="action-btn"
                                          src={option}
                                          alt="more"
                                        />
                                      </Button>
                                    </Popover>
                                  </td>
                                </tr>
                              ))}
                            />
                          )}
                        </>
                      ))
                    ) : tags ? (
                      renderGroupTag()
                    ) : (
                      <Spin />
                    )}
                  </tbody>
                </>
              )}
            </table>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ChiefOfficialTag;
