import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import PropTypes from "prop-types";
import TagInput from "../TagSelector";
import TagValidation from "../TagValidation";
// import CreatableSelect from "react-select/creatable";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import Select from "react-select";
import CustomEditor from "../TinyMCE";
import { connect } from "react-redux";
import {
  actGetPostInfoRequestV2,
  actCreateBriefRequest,
} from "../../actions/post.action";

const languageOption = [
  {
    label: "English",
    value: "EN",
  },
  {
    label: "Vietnamese",
    value: "VN",
  },
];
class EditorArticleWritingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // tagsOption: [],
      tagsSelect: [],
      titleWarn: false,
      data: {
        angle_title: "",
        language: "",
        content: "",
        topic: [],
        tags: [],
      },
      autoSave: false,
    };
  }
  async componentDidMount() {
    this.props.getPostInfo();
    this._getBriefDataFromLocal();
    this._autoSave();
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.post_detail &&
      nextProps.post_detail !== this.props.post_detail
    ) {
      let post_data = nextProps.post_detail.data;
      let topic = nextProps.post_detail.data.topic[0];
      const data = {
        ...post_data,
        topic: [
          {
            ...topic,
            value: topic && topic.value,
            label: topic && topic.label,
          },
        ],
      };
      this.setState({
        data,
        // tagsOption:
        //   nextProps.post_detail.data.topic &&
        //   nextProps.post_detail.data.topic[0] &&
        //   nextProps.post_detail.data.topic[0].tags,
        tagsSelect: nextProps.post_detail.data.tags,
      });
      if (
        data.steps_to_write &&
        data.steps_to_write[0] &&
        data.steps_to_write[0].key === "other"
      ) {
        this.setState({ isOtherSteps: true });
      }
      if (
        data.tone_of_writing &&
        data.tone_of_writing[0] &&
        data.tone_of_writing[0].key === "other"
      ) {
        this.setState({ isOtherTone: true });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
  }

  _autoSave = () => {
    this.autoSave = setInterval(() => {
      if (this.state.autoSave) {
        const { data } = this.state;
        if (this.state.tagsSelect && this.state.tagsSelect.length > 0) {
          data.tags = this.state.tagsSelect;
          let tagsArr = [];
          data.tags.map((prop) => tagsArr.push(prop));
          data.tags = tagsArr;
        }
        localStorage.setItem("articleData", JSON.stringify(data));
        this.setState({ autoSave: false });
      }
    }, 3000);
  };

  async _getBriefDataFromLocal() {
    const articleData = localStorage.getItem("articleData");
    if (articleData) {
      let parseData = await JSON.parse(articleData);
      this.setState({
        data: parseData,
      });
      if (parseData.tags.length > 0) {
        this.setState({
          tagsSelect: parseData.tags,
        });
      }
    }
  }

  _handleOnChange = (e) => {
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data,
      autoSave: true,
      titleWarn: e.target.value.length > 60,
    });
  };

  _handleOnSelectTopicChange = (topickey, topicname) => {
    let tagsFilter = this.props.post_info.data.topics.filter(
      (prop) => prop.key === topickey
    );
    this.setState({
      data: {
        ...this.state.data,
        topic: [
          {
            value: topickey,
            label: topicname,
            key: topickey,
            name: topicname,
            tags: tagsFilter[0].tags,
          },
        ],
      },
      autoSave: true,
    });
  };

  _handleOnTagsSelectChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value,
      },
    });
  };

  _handleOnLanguageChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value,
      },
      autoSave: true,
    });
  };

  _handleOnContentChange = (label, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [label]: value,
      },
      autoSave: true,
    });
  };

  _handleOnSubmit = () => {
    const { data, tagsSelect } = this.state;
    if (
      data.angle_title === "" ||
      data.content === "" ||
      data.language === "" ||
      data.topic.length === 0 ||
      tagsSelect.length === 0
    ) {
      this._warningAlert("Please fill * field before Submit");
    } else {
      const dataSubmit = {
        angle_title: data.angle_title,
        topic: data.topic,
        tags: tagsSelect,
        language: data.language,
        content: data.content,
      };
      if (TagValidation(dataSubmit.tags)) {
        return;
      }
      this.props.createBrief(dataSubmit);
      localStorage.removeItem("articleData");
    }
  };

  _warningAlert = (message) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={"Attention"}
          confirmBtnText="OK"
          confirmBtnBsStyle="danger"
          btnSize=""
          onConfirm={() => {
            this._hideAlert();
          }}
          onCancel={this._hideAlert}
        >
          {message}
        </SweetAlert>
      ),
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: "",
    });
  };

  handleChange(tags) {
    this.setState({ tagsSelect: tags, autoSave: true });
  }

  render() {
    const { topics_name } = this.props;
    const { data, titleWarn } = this.state;
    return (
      <>
        {this.state.alert}
        <Row>
          <Col>
            <Card style={{ marginTop: "19px" }}>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0">Article Writing</h3>
              </CardHeader>
              <hr className="my-0" />
              <CardBody>
                <Row>
                  <Col md="7">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="title"
                      >
                        Angle/Title{" "}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                      <Input
                        id="angle_title"
                        name="angle_title"
                        type="text"
                        className={`d-inline mr-3 ` + (titleWarn ? "warn" : "")}
                        value={data.angle_title && data.angle_title}
                        onChange={this._handleOnChange}
                      />
                      <div
                        className={`d-flex ${
                          titleWarn
                            ? "justify-content-between"
                            : "justify-content-end"
                        }`}
                      >
                        {titleWarn && (
                          <small className="text-red">
                            Title ideally shouldn't be longer than 60
                            characters, but you can still sumbit
                          </small>
                        )}
                        <small className="text-right">
                          {data.angle_title ? data.angle_title.length : "0"}/60
                        </small>
                      </div>
                    </FormGroup>
                    <TagInput
                      className="editor-add-new"
                      tags={this.state.tagsSelect}
                      onTagsChange={(tags) => this.handleChange(tags)}
                    />
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="topic"
                      >
                        Topic{" "}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                      <div className="custom-vietcetera-topic">
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          size="lg"
                          closeMenuOnSelect={true}
                          value={data.topic}
                          options={topics_name}
                          onChange={(value) =>
                            this._handleOnSelectTopicChange(
                              value.key,
                              value.name
                            )
                          }
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="language"
                      >
                        Language{" "}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                      <Select
                        name="language"
                        className="react-select"
                        classNamePrefix="react-select"
                        size="lg"
                        closeMenuOnSelect={true}
                        value={languageOption.filter(
                          ({ value }) => value === data.language
                        )}
                        options={languageOption}
                        onChange={(value) =>
                          this._handleOnLanguageChange("language", value.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label
                        className="form-control-label d-block"
                        htmlFor="content"
                      >
                        Content{" "}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                      <CustomEditor
                        content={data.content && data.content}
                        onChangeContent={(value) =>
                          this._handleOnContentChange("content", value)
                        }
                        isComment={false}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <h5 className="text-center text-danger font-weight-bold my-2">
                  {this.state.message}
                </h5>
                <div className="text-right">
                  <Button
                    className="btn btn-success"
                    onClick={this._handleOnSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    post_info: state.postReducer.post_info,
    topics_name: state.postReducer.topics_name,
    topics_name_select: state.postReducer.topics_name_select,
    post_detail: state.postReducer.post_detail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPostInfo: (data) => {
      dispatch(actGetPostInfoRequestV2(data));
    },
    createBrief: (data) => {
      dispatch(actCreateBriefRequest(data));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorArticleWritingDetail);
EditorArticleWritingDetail.propTypes = {
  post_info: PropTypes.object,
  content_versions: PropTypes.array,
  topics_name: PropTypes.array,
  topics_name_select: PropTypes.array,
  post_detail: PropTypes.object,
  getPostInfo: PropTypes.func,
  createBrief: PropTypes.func,
};
EditorArticleWritingDetail.defaultProps = {};
