import * as Types from "../variables/actionTypes";
import callApi from "../utils/callApi";
import { actNotify } from "./fetch.action";

export const actDeclareAction = () => ({
  type: Types.DECLARE_ACTION
});

export const actResetStateTagManagement = () => ({
  type: Types.RESET_STATE_TAGS_MANAGEMENT
});

export const actGetOfficialTagsSuccess = data => ({
  type: Types.GET_OFFICIAL_TAGS_SUCCESS,
  data
});

export const actGetSuggestedTagsSuccess = data => ({
  type: Types.GET_SUGGESTED_TAGS_SUCCESS,
  data
});

export const actGetAllTagsInEachGroupSuccess = data => ({
  type: Types.GET_ALL_TAGS_IN_EACH_GROUP_SUCCESS,
  data
});

export const actTagSearchingSuccessfully = data => ({
  type: Types.TAG_SEARCHING_SUCCESSFULLY,
  data
});

export const actNoDataLeft = data => ({
  type: Types.GET_NO_DATA_LEFT,
  data
});

export const actTagSearchingLeftEmpty = () => ({
  type: Types.TAG_SEARCHING_LEFT_EMPTY
});

export const actGetReletedTagSuccessfully = data => ({
  type: Types.GET_RELATED_TAG_SUCCESSFULLY,
  data
});

export const actGetArticlesTagSuccessfully = data => ({
  type: Types.GET_ARTICLE_TAG_SUCCESSFULLY,
  data
});

export const actGetTotalTagSuccessfully = data => ({
  type: Types.GET_TOTAL_TAG_SUCCESSFULLY,
  data
});

export const actConvertToOT = data => dispatch => {
  const endpoint = `v2/tag/convert-tag`

  return callApi(endpoint, "POST", data)
    .then(() => {
      dispatch(
        actNotify({
          message: "Tag Converted!",
          color: "success"
        })
      );
    })
    .catch((err) => {
      dispatch(
        actNotify({
          message: "Cannot Convert!",
          color: "danger"
        })
      );

      console.log(err);
    });
};

export const actDeleteSelectedTag = data => dispatch => {
  return callApi(data.endpoint, "PUT", {
    tagId: data.id
  })
    .then(() => {
      dispatch(
        actNotify({
          message: "Deleted Successfully",
          color: "success"
        })
      );
    })
    .catch(err => {
      dispatch(
        actNotify({
          message: "Cannot Delete Selected Item",
          color: "danger"
        })
      );

      console.log(err);
    });
};

export const actGetOfficialTags = data => dispatch => {
  data.allowedToWipeData && dispatch(actDeclareAction());

  const endpoint = `v2/tags?groupMainTitle=${data.groupMainTitle}&language=${data.language}&page=${data.page}`;

  return callApi(endpoint, "GET")
    .then(res => {
      dispatch(actGetOfficialTagsSuccess(res));

      if (res) {
        if (res.data.tags.length === 0) {
          dispatch(actNoDataLeft(data.groupMainTitle));
        }
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const actGetSuggestedTags = data => dispatch => {
  data.allowedToWipeData && dispatch(actDeclareAction());

  const endpoint = `v2/tags/suggest?groupMainTitle=${data.groupMainTitle}&language=${data.language}&page=${data.page}`;

  return callApi(endpoint, "GET")
    .then(res => {
      dispatch(actGetSuggestedTagsSuccess(res));

      if (res) {
        if (res.data.tags.length === 0) {
          dispatch(actNoDataLeft(data.groupMainTitle));
        }
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const actGetTotalTag = () => dispatch => {
  const endpoint = `v2/total-tag`;

  return callApi(endpoint, "GET")
    .then(res => {
      dispatch(actGetTotalTagSuccessfully(res));
    })
    .catch(err => {
      console.log(err);
    }); 
};

export const actUnlinkSelected = data => dispatch => {
  const endpoint = `v2/unlink-related-tag`;
  
  return callApi(endpoint, "POST", {
    tagId: data.tagId,
    relatedTagId: data.relatedTagId
  })
    .then(() => {
      dispatch(
        actNotify({
          message: "Unlink Related Tag Successfully",
          color: "success"
        })
      );
    })
    .catch(err => {
      console.log(err);
    });
};

export const actGetReletedTags = data => dispatch => {
  const endpoint = `v2/tags/related/${data.id}`;

  return callApi(endpoint, "GET")
    .then(res => {
      console.log(res);
      dispatch(actGetReletedTagSuccessfully(res));
    })
    .catch(err => {
      console.log(err);
    });
};

export const actGetArticlesTags = data => dispatch => {
  const endpoint = `v2/tags/article/${data.id}?page=${data.page}`;

  return callApi(endpoint, "GET")
    .then(res => {
      dispatch(actGetArticlesTagSuccessfully(res));
    })
    .catch(err => {
      console.log(err);
    });
};

export const actTagSearching = data => dispatch => {
  dispatch(actTagSearchingLeftEmpty());

  const endpoint = `v2/search-tag?language=${data.language}&type=${data.type}&groupMainTitle[]=${data.groupMainTitle}&name=${data.searchCharacter}`;

  return callApi(endpoint, "GET")
    .then(res => {
      dispatch(actTagSearchingSuccessfully(res));
    })
    .catch(err => {});
};

export const actAddNewTag = data => dispatch => {
  return callApi(data.endpoint, "POST", data.data)
    .then((res) => {
      dispatch(
        actNotify({
          message: "New Tag Added Successfully",
          color: "success"
        })
      );

      return res;
    })
    .catch(err => {
      dispatch(
        actNotify({
          message: "Tag Already Exists!",
          color: "danger"
        })
      );

      console.log(err);
    });
};

export const actUpdateTag = data => dispatch => {
  return callApi(data.endpoint, "PUT", data.dispatchData)
    .then(() => {
      dispatch(
        actNotify({
          message: "Update Successfully!",
          color: "success"
        })
      );
    })
    .catch(err => {
      console.log(err);
    });
};

export const actGetAllTagsInEachGroup = data => dispatch => {
  return callApi(data.endpoint, "GET")
    .then(res => {
      dispatch(actGetAllTagsInEachGroupSuccess(res));

      return res;
    })
    .catch(err => {
      console.log(err);
    });
};
