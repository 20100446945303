import React, { Component } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  Badge,
  Input,
  FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  actGetWritingContentByIDRequestForEditor,
  actSubmitWritingContentRequest,
  actSaveWritingContentRequest
  // actAutoSaveWritingContentRequest
} from "../../actions/writerArticle.action";
import { actGeneratePreviewLinkRequest } from "../../actions/chiefArticle.action";
import { actGetFeedbackListRequest } from "../../actions/feedback.action";
import Feedback from "../Feedback/Feedback";
import CustomEditor from "../TinyMCE";
import ViewDetailContentWritingModal from "../Modals/ViewDetailContentWritingWriterModal";
import { actSubmitEditingRequestV2 } from "../../actions/editorArticle.action";
import EditorPostSetting from "../PostSetting/EditorPostSetting";
import { HOST_PREVIEW } from "../../variables/hostClient";
class EditorEditingWritingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      id: window.location.pathname.split("/")[4],
      is_senior: localStorage.getItem("is_senior") === "true",
      data: {
        content: "",
        angle_title: ""
      },
      setting: {
        description: "",
        imageId: "",
        tags: [],
        topic: [],
        slug: "",
        image: "",
        otherLanguageLink: "",
        publishDate: "",
        excerpt: "",
        language: ""
      },
      idView: false,
      // autoSave: false,
      titleWarn: false
    };
  }

  componentDidMount() {
    this._setLoading();
    this._getWritingContentByID(this.state.id);
    // this._autoSave();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.writing_content !== this.props.writing_content &&
      nextProps.writing_content.data
    ) {
      const imageId = get(nextProps.writing_content.data, "images._id");
      const excerpt = get(nextProps.writing_content.data, "excerpt");
      const description = get(
        nextProps.writing_content.data,
        "seo.description"
      );
      const language = get(nextProps.writing_content.data, "language");
      const topic = get(nextProps.writing_content.data, "topic");
      const tags = get(nextProps.writing_content.data, "tags");

      this.setState({
        data: {
          content: nextProps.writing_content.data.content_content,
          angle_title: nextProps.writing_content.data.angle_title
        },
        setting: {
          imageId,
          excerpt,
          description,
          language,
          topic,
          tags
        },
        isEditing: nextProps.writing_content.data.status_article === "EDITING"
      });
      this.props.getFeedbackList(nextProps.writing_content.data.outlineID);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.preview.actionCompleted &&
      this.props.preview.actionCompleted !== prevProps.preview.actionCompleted
    ) {
      const { preview, writing_content } = this.props;
      const pin = get(preview, "previewLink.pin");
      const key = get(preview, "previewLink.key");
      const previewLinkUrl = `${HOST_PREVIEW}${writing_content.data._id}?pin=${pin}&key=${key}`;
      window.open(previewLinkUrl, "_blank");
    }
  }

  // componentWillUnmount() {
  //   clearInterval(this.autoSave);
  // }

  _toggleModalView = () => {
    this.setState(prevState => ({
      idView: !prevState.idView
    }));
  };

  _getWritingContentByID = id => {
    this.props.getWritingContentByID(id);
  };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        content: value
      }
      // autoSave: true
    });
  };
  handleChangeAngleTitle = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      },
      titleWarn: e.target.value.length > 60
      // autoSave: true
    });
  };

  // _autoSave = () => {
  //   this.autoSave = setInterval(() => {
  //     if (this.state.autoSave) {
  //       const writing_content = this.props.writing_content.data;
  //       const data = {
  //         idContent: writing_content.contentID,
  //         title: this.state.data.angle_title,
  //         content: this.state.data.content
  //       };
  //       this.setState({ autoSave: false });
  //       this.props.AutoSaveContent(data);
  //     }
  //   }, 3000);
  // };

  _handleOnSubmitContent = () => {
    const id = this.state.id;
    const data = this.state.data;
    this.props.submitWritingContent(id, data);
  };

  _handleOnSubmitEditingContent = () => {
    const id = this.state.id;
    const data = this.state.data;
    this.props.submitEditingContent(id, data);
    this.props.history.push("/admin/editor/article-editing");
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  _handleOnViewDetail = () => {
    this._toggleModalView();
  };

  _handleOnSaveDraftContent = e => {
    e.preventDefault();
    const writing_content = this.props.writing_content.data;
    const data = {
      idContent: writing_content.contentID,
      title: this.state.data.angle_title,
      content: this.state.data.content
    };
    this.props.saveWritingContent(data);
  };

  _handleArticleSettingChange = setting => {
    this.setState({
      setting: setting
    });
  };

  // Generate preview link for editor
  handleGeneratePreviewLink = id => {
    const { generatePreviewLinkCode } = this.props;
    generatePreviewLinkCode({
      articleId: id
    });
  };

  render() {
    const { data, id, setting, titleWarn } = this.state;
    const { writing_content } = this.props;

    const isDisableSendButton =
      !setting.imageId ||
      !setting.language ||
      !setting.description ||
      !setting.excerpt ||
      setting.topic.length === 0;
    // setting.tags.length === 0;
    const badgeClass =
      this.props.writing_content.data.status_article === "COMPLETED"
        ? "success"
        : "info";
    return (
      <>
        <ViewDetailContentWritingModal
          modal={this.state.idView}
          toggle={this._toggleModalView}
          data={this.props.writing_content}
        />
        <Row>
          <Col xl="9">
            <Card style={{ marginTop: "19px" }}>
              <div className="d-flex justify-content-between">
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Text Editor</h3>
                </CardHeader>
                <div className="d-flex justify-content-between align-items-center pr-4">
                  <Badge color={badgeClass}>
                    {this.props.writing_content.data &&
                      this.props.writing_content.data.status_article}
                  </Badge>
                </div>
              </div>
              <CardBody>
                <div className="pb-4">
                  <FormGroup className="mb-5">
                    <Input
                      className={titleWarn ? "warn" : ""}
                      type="text"
                      name="angle_title"
                      value={this.state.data.angle_title}
                      onChange={this.handleChangeAngleTitle}
                      disabled={!this.state.isEditing}
                    />
                    <div
                      className={`d-flex ${
                        titleWarn
                          ? "justify-content-between"
                          : "justify-content-end"
                      }`}
                    >
                      {titleWarn && (
                        <small className="text-red">
                          Title ideally shouldn't be longer than 60 characters,
                          but you can still sumbit
                        </small>
                      )}
                      <small className="float-right">
                        {this.state.data.angle_title
                          ? this.state.data.angle_title.length
                          : "0"}
                        /60
                      </small>
                    </div>
                  </FormGroup>
                  <Row>
                    <Col lg="2">
                      <a
                        target="blank"
                        href={`/admin/editor/outline-content/${this.state.id}`}
                      >
                        <h5 className="text-primary">{"View outline >>"}</h5>
                      </a>
                    </Col>
                    <Col lg="10" className="text-right">
                      {(this.props.writing_content.data &&
                        this.props.writing_content.data.status_article ===
                          "PUBLISHED") ||
                      (this.props.writing_content.data &&
                        this.props.writing_content.data.status_article ===
                          "REJECTED") ||
                      (this.props.writing_content.data &&
                        this.props.writing_content.data.status_article ===
                          "CONTENT_SUBMITTED") ? (
                        ""
                      ) : (
                        <a
                          href="#pablo"
                          style={{ fontWeight: "bold" }}
                          onClick={this._handleOnSaveDraftContent}
                        >
                          Save draft
                        </a>
                      )}
                    </Col>
                  </Row>
                </div>
                <CustomEditor
                  content={get(this.state, "data.content")}
                  onChangeContent={this._handleOnContentChange}
                  // readOnly={!this.state.isEditing}
                  // isComment={false}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xl="3" className={"toolbar-right"}>
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                size="lg"
                className="text-primary btn-lg"
                style={{ width: "85%" }}
                disabled={
                  isDisableSendButton ||
                  isEmpty(data.angle_title) ||
                  isEmpty(this.state.data.content) ||
                  !this.state.isEditing
                }
                onClick={this._handleOnSubmitEditingContent}
              >
                <i className="ni ni-send mr-2"></i>
                Send
              </Button>
              <Button
                onClick={() => this.handleGeneratePreviewLink(id)}
                type="button"
                className="text-primary text-center btn-lg"
              >
                {/* {loading ? <Spin /> : <i className="fa fa-eye text-center"></i>} */}
                <i className="fa fa-eye text-center"></i>
              </Button>
            </div>
            <Feedback
              outline_id={
                writing_content.data[0] && writing_content.data[0].outlineID
              }
              article_id={this.state.id}
            />
          </Col>
        </Row>
        {/* Editor Post Setting */}
        <Row>
          <Col xl="9">
            <EditorPostSetting
              onChangeSetting={this._handleArticleSettingChange}
              article_id={id}
              setting={setting}
              title={data.angle_title}
              status={
                this.props.writing_content.data &&
                this.props.writing_content.data.status_article
              }
            />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writing_content: state.writerArticleReducer.writing_content_id,
    preview: state.chiefArticleReducer.preview
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getWritingContentByID: id => {
      dispatch(actGetWritingContentByIDRequestForEditor(id));
    },
    submitWritingContent: (id, data) => {
      dispatch(actSubmitWritingContentRequest(id, data));
    },
    saveWritingContent: data => {
      dispatch(actSaveWritingContentRequest(data));
    },
    // AutoSaveContent: data => {
    //   dispatch(actAutoSaveWritingContentRequest(data));
    // },
    submitEditingContent: (id, data) => {
      dispatch(actSubmitEditingRequestV2(id, data));
    },
    getFeedbackList: outlineId => {
      dispatch(actGetFeedbackListRequest(outlineId));
    },
    generatePreviewLinkCode: data => {
      dispatch(actGeneratePreviewLinkRequest(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorEditingWritingDetail);
EditorEditingWritingDetail.propTypes = {
  writing_content: PropTypes.object,
  preview: PropTypes.object,
  getWritingContentByID: PropTypes.func,
  submitWritingContent: PropTypes.func,
  saveWritingContent: PropTypes.func,
  AutoSaveContent: PropTypes.func,
  getFeedbackList: PropTypes.func,
  submitEditingContent: PropTypes.func,
  generatePreviewLinkCode: PropTypes.func
};
EditorEditingWritingDetail.defaultProps = {};
