import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  Input,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  actGetWritingContentByIDRequest,
  actSubmitWritingContentRequest,
  actSaveWritingContentRequest,
  actAutoSaveWritingContentRequest,
} from "../../actions/writerArticle.action";
import { actGetFeedbackListRequest } from "../../actions/feedback.action";
import Feedback from "../Feedback/Feedback";
import CustomEditor from "../TinyMCE";
import ViewDetailContentWritingModal from "../Modals/ViewDetailContentWritingWriterModal";
import { actSubmitEditingRequest } from "../../actions/editorArticle.action";

class EditorPublishedArticlesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      id: window.location.pathname.split("/")[4],
      is_senior: localStorage.getItem("is_senior") === "true",
      data: {
        content: "",
        angle_title: "",
      },
      idView: false,
      // autoSave: false,
    };
  }

  componentDidMount() {
    this._setLoading();
    this._getWritingContentByID(this.state.id);
    // this._autoSave();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.writing_content !== this.props.writing_content &&
      nextProps.writing_content.data
    ) {
      this.setState({
        data: {
          content: nextProps.writing_content.data[0].content_content,
          angle_title: nextProps.writing_content.data[0].angle_title,
        },
        isEditing:
          nextProps.writing_content.data[0].status_article === "EDITING",
      });
      this.props.getFeedbackList(nextProps.writing_content.data[0].outlineID);
    }
  }

  // componentWillUnmount() {
  //   clearInterval(this.autoSave);
  // }

  _toggleModalView = () => {
    this.setState((prevState) => ({
      idView: !prevState.idView,
    }));
  };

  _getWritingContentByID = (id) => {
    this.props.getWritingContentByID(id);
  };

  _handleOnContentChange = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        content: value,
      },
      // autoSave: true,
    });
  };
  handleChangeAngleTitle = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      // autoSave: true,
    });
  };

  // _autoSave = () => {
  //   this.autoSave = setInterval(() => {
  //     if (this.state.autoSave) {
  //       const writing_content = this.props.writing_content.data[0];
  //       const data = {
  //         idContent: writing_content.contentID,
  //         title: this.state.data.angle_title,
  //         content: this.state.data.content,
  //       };
  //       this.setState({ autoSave: false });
  //       this.props.AutoSaveContent(data);
  //     }
  //   }, 3000);
  // };

  _handleOnSubmitContent = () => {
    const id = this.state.id;
    const data = this.state.data;
    this.props.submitWritingContent(id, data);
  };

  _handleOnSubmitEditingContent = () => {
    const id = this.state.id;
    const data = this.state.data;
    data.status = "APPROVED";
    this.props.submitEditingContent(id, data);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  _handleOnPageClick = (page) => {
    const { params } = this.state;
    params.page = page;
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _toogleLoading = () => {
    this.setState((prevState) => ({
      isLoading: !prevState.isLoading,
    }));
  };

  _handleOnViewDetail = () => {
    this._toggleModalView();
  };

  _handleOnSaveDraftContent = (e) => {
    e.preventDefault();
    const writing_content = this.props.writing_content.data[0];
    const data = {
      idContent: writing_content.contentID,
      title: this.state.data.angle_title,
      content: this.state.data.content,
    };
    this.props.saveWritingContent(data);
  };

  render() {
    return (
      <>
        <ViewDetailContentWritingModal
          modal={this.state.idView}
          toggle={this._toggleModalView}
          data={this.props.writing_content}
        />
        <Row>
          <Col xl="9">
            <Card style={{ marginTop: "19px" }}>
              <CardHeader className="d-flex justify-content-between">
                <h3 className="mb-0">Text Editor</h3>
              </CardHeader>
              <CardBody>
                <div className="pb-4">
                  <FormGroup>
                    <Input
                      type="text"
                      name="angle_title"
                      value={this.state.data.angle_title}
                      readOnly
                    />
                  </FormGroup>
                  <Row>
                    <Col lg="10">
                      <a
                        target="blank"
                        href={`/admin/editor/outline-content/${this.state.id}`}
                      >
                        <h5 className="text-primary">{"View outline >>"}</h5>
                      </a>
                    </Col>
                    <Col lg="2" className="text-right"></Col>
                  </Row>
                </div>
                <Form>
                  {this.state.data.content && (
                    <CustomEditor
                      readOnly={true}
                      content={this.state.data.content}
                      onChange={this._handleOnContentChange}
                    />
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3" className={"toolbar-right"}>
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                size="lg"
                className="text-primary btn-lg"
                style={{ width: "85%" }}
                disabled={
                  this.state.data.content === "" || !this.state.isEditing
                }
                onClick={this._handleOnSubmitEditingContent}
              >
                <i className="ni ni-send mr-2"></i>
                Send
              </Button>
              <a
                target="blank"
                href={`/admin/editor/view-detail-content/${this.state.id}`}
              >
                <Button
                  type="button"
                  className="text-primary text-center btn-lg"
                >
                  <i className="fa fa-eye text-center"></i>
                </Button>
              </a>
            </div>
            <Feedback article_id={this.state.id} />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    writing_content: state.writerArticleReducer.writing_content_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWritingContentByID: (id) => {
      dispatch(actGetWritingContentByIDRequest(id));
    },
    submitWritingContent: (id, data) => {
      dispatch(actSubmitWritingContentRequest(id, data));
    },
    saveWritingContent: (data) => {
      dispatch(actSaveWritingContentRequest(data));
    },
    // AutoSaveContent: (data) => {
    //   dispatch(actAutoSaveWritingContentRequest(data));
    // },
    submitEditingContent: (id, data) => {
      dispatch(actSubmitEditingRequest(id, data));
    },
    getFeedbackList: (outlineId) => {
      dispatch(actGetFeedbackListRequest(outlineId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorPublishedArticlesDetail);
EditorPublishedArticlesDetail.propTypes = {
  writing_content: PropTypes.object,
  getWritingContentByID: PropTypes.func,
  submitWritingContent: PropTypes.func,
  saveWritingContent: PropTypes.func,
  AutoSaveContent: PropTypes.func,
  submitEditingContent: PropTypes.func,
};
EditorPublishedArticlesDetail.defaultProps = {};
