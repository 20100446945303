import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";

export const actGetMyPermissionRequest = data => {
  return dispatch => {
    return callApi(`role/me`, "GET")
      .then(res => {
        dispatch(actGetMyPermission(res.data[0].permissions));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetMyPermission = data => {
  return {
    type: Types.GET_MY_PERMISSION,
    data
  };
};

export const actGetCountInfoRequest = data => {
  return dispatch => {
    return callApi(`article/header-dashboards-editor`, "GET")
      .then(res => {
        dispatch(actGetCountInfo(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetCountInfo = data => {
  return {
    type: Types.GET_COUNT_INFO,
    data
  };
};

export const actGetCountInfoChiefEditorRequest = data => {
  return dispatch => {
    return callApi(`article/count-menu-chief-editor`, "GET")
      .then(res => {
        dispatch(actGetCountInfoChiefEditor(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetCountInfoChiefEditor = data => {
  return {
    type: Types.GET_COUNT_INFO_CHIEF,
    data
  };
};

export const actGetCountInfoEditorRequest = data => {
  return dispatch => {
    return callApi(`article/count-menu-editor`, "GET")
      .then(res => {
        dispatch(actGetCountInfoEditor(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetCountInfoEditor = data => {
  return {
    type: Types.GET_COUNT_INFO_EDITOR,
    data
  };
};
