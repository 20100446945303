import React, { Component } from "react";
import { get } from "lodash";
import moment from "moment";
import { Drawer, Button, Icon, Tooltip } from "antd";
import { Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actApplyOutlineRequest } from "../../actions/writerArticle.action";

class Drawer_Component extends Component {
  state = { id: "", listcontent_byid: {} };

  toggle = () => {
    this.props.toggle();
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  createMarkup(text) {
    return { __html: text };
  }

  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  _applyToContent = id => {
    this.props.applyToContent(id);
  };

  render() {
    const { detailContent } = this.props;
    const editorEmail = get(detailContent.data[0], "assign_to_editor.email");
    return (
      <div>
        <Drawer
          title="View Detail Content Request"
          width={720}
          onClose={this.toggle}
          visible={this.props.modal}
          detailContent={this.props.detailContent}
        >
          <Table className="align-items-center table-flush drawer-list">
            <tbody>
              <tr>
                <th scope="row">Article title</th>
                <td className="article-title">
                  {this.rawHTML(detailContent.data[0].angle_title)}
                </td>
              </tr>
              <tr>
                <th scope="row">Topic</th>
                <td className="topics">
                  <button>
                    {detailContent.data[0].topic.map(item => {
                      // eslint-disable-next-line react/jsx-key
                      return <span>{item.name}</span>;
                    })}
                  </button>
                </td>
              </tr>
              <tr>
                <th scope="row">Tags</th>
                <td className="tags">
                  {detailContent.data[0].tags.map((item, key) => {
                    return (
                      <span key={key} className="tags">
                        {item}
                      </span>
                    );
                  })}
                </td>
              </tr>
              {/* Deadline */}
              <tr>
                <th scope="row">Outline Deadline</th>
                <td className="outline-deadline">
                  <div className="d-flex justify-content-start align-items-center">
                    {detailContent.data[0].dead_line && (
                      <>
                        <strong>
                          {moment(detailContent.data[0].dead_line).format(
                            "MMMM Do YYYY"
                          )}
                        </strong>
                        <Tooltip title="If you would like to extend the deadline, please contact the editor via email. Do not click “Apply” until the editor has updated a new deadline.">
                          <Icon className="ml-2" type="info-circle" />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </td>
              </tr>
              {/* Editor information */}
              <tr>
                <th scope="row">Editor</th>
                <td>
                  <div>
                    <span>
                      {get(detailContent.data[0], "assign_to_editor.name")}
                    </span>{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${editorEmail}`}
                    >
                      {`(${editorEmail})`}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">Type of Arrticle</th>
                <td>
                  {detailContent.data[0].type_of_article &&
                    detailContent.data[0].type_of_article.map((item, key) => {
                      return <span key={key}>{this.rawHTML(item.name)}</span>;
                    })}
                </td>
              </tr>
              <tr>
                <th scope="row">Type of Concerns</th>
                <td>
                  {detailContent.data[0].topics_of_concern &&
                    detailContent.data[0].topics_of_concern.map((item, key) => {
                      return <span key={key}>{this.rawHTML(item.name)}</span>;
                    })}
                </td>
              </tr>
              <tr>
                <th scope="row">Objectives</th>
                <td>{this.rawHTML(detailContent.data[0].objectives)}</td>
              </tr>
              <tr>
                <th scope="row">Age Group</th>
                <td>
                  {this.rawHTML(
                    detailContent.data[0].age_group[0] &&
                      detailContent.data[0].age_group[0].name
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">Comprehension Level</th>
                <td>
                  {detailContent.data[0].comprehension_level &&
                    detailContent.data[0].comprehension_level.map(
                      (item, key) => {
                        return <span key={key}>{this.rawHTML(item.name)}</span>;
                      }
                    )}
                </td>
              </tr>
              <tr>
                <th scope="row">Steps Required to Write This Article</th>
                <td>
                  {detailContent.data[0].steps_to_write &&
                    detailContent.data[0].steps_to_write.map((item, key) => {
                      return <span key={key}>{this.rawHTML(item.name)}</span>;
                    })}
                </td>
              </tr>
              <tr>
                <th scope="row">Things to Avoid</th>
                <td>{this.rawHTML(detailContent.data[0].things_to_avoid)}</td>
              </tr>
              <tr>
                <th scope="row">Things to Mention</th>
                <td>{this.rawHTML(detailContent.data[0].things_to_mention)}</td>
              </tr>
              <tr>
                <th scope="row">Tone of Writing</th>
                <td>
                  {detailContent.data[0].tone_of_writing &&
                    detailContent.data[0].tone_of_writing.map((item, key) => {
                      return <span key={key}>{this.rawHTML(item.name)}</span>;
                    })}
                </td>
              </tr>
              <tr>
                <th scope="row">Note</th>
                <td>{this.rawHTML(detailContent.data[0].note)}</td>
              </tr>
              <tr>
                <th scope="row">Word Count</th>
                <td>
                  {detailContent.data[0].min_word} - {""}
                  {detailContent.data[0].max_word} word
                </td>
              </tr>
              <tr>
                <th scope="row">Key Word</th>
                <td>
                  {detailContent.data[0].keywords &&
                    detailContent.data[0].keywords.map((item, key) => {
                      return (
                        <span key={key} className="type">
                          {this.rawHTML(item)}
                        </span>
                      );
                    })}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="drawer-footer">
            <Button
              className="add"
              onClick={() => this._applyToContent(detailContent.data[0]._id)}
            >
              Apply
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    writing_content: state.writerArticleReducer.writing_content_id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    applyToContent: id => {
      dispatch(actApplyOutlineRequest(id));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Drawer_Component);
Drawer_Component.propTypes = {
  id: PropTypes.string,
  writing_content: PropTypes.object,
  detailContent: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  uploadImg: PropTypes.func,
  applyToContent: PropTypes.func
};

Drawer_Component.defaultProps = {};
