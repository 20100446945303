import React, { Component } from "react";
import { get } from "lodash";
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  FormGroup,
  Form
} from "reactstrap";
import CustomEditor from "../TinyMCE";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import CardContentMapping from "../Cards/CardContentMapping";
import {
  actCreateNewPitchRequest,
  actGetMyPitchDetailRequestV2,
  actGetMyPitchDetailRequest,
  actUpdatePitchRequest
} from "../../actions/pitch.action";
import FeedbackPitch from "../Feedback/FeedbackPitch";
class WriterAddNewPitchDetail extends Component {
  state = {
    tabs: 1,
    id: window.location.pathname.split("/")[4],
    data: {
      outline: "",
      audience: "",
      angle_title: "",
      type_of_article: [],
      topics_of_concern: [],
      steps_to_write: [],
      age_group: [],
      comprehension_level: [],
      tone_of_writing: [],
      topic: [],
      objectives: ""
    },
    pitchData: {}
  };

  componentDidMount() {
    this._setLoading();
    // this.state.id && this._getMyPitchDetail(this.state.id);
    this.state.id && this._getMyPitchDetailV2(this.state.id);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.writer_my_pitch_detail !== this.props.writer_my_pitch_detail &&
      nextProps.writer_my_pitch_detail
    ) {
      this.setState({
        data: nextProps.writer_my_pitch_detail
      });
    }
  }

  // _getMyPitchDetail = id => {
  //   this.props.getMyPitchDetail(id);
  // };

  _getMyPitchDetailV2 = id => {
    this.props.getMyPitchDetailV2(id);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _handleOnSubmitPitchOutline = () => {
    const data = this.state.data;
    const id = this.state.id;
    this.state.id
      ? this.props.updatePitchOutline(id, data)
      : this.props.submitPitchOutline(data);
  };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        outline: value
      }
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  handlePitchData = pitchData => {
    // const outline = this.state.data.outline;
    this.setState({ data: { outline: this.state.data.outline, ...pitchData } });
  };

  render() {
    const { data } = this.state;
    return (
      <>
        <Row>
          <Col xl="9">
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 1)}
                    role="tab"
                  >
                    1. Content Mapping
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 2
                    })}
                    // onClick={e => this.toggleNavs(e, "tabs", 2)}
                    role="tab"
                  >
                    2. Write Outline
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={"tabs" + this.state.tabs}>
              <TabPane tabId="tabs1">
                <CardContentMapping
                  id={this.state.id}
                  pitchData={this.handlePitchData}
                  toggleNavs={this.toggleNavs}
                />
              </TabPane>
              <TabPane tabId="tabs2">
                <Card>
                  <CardBody>
                    <div className="pb-4">
                      <h2>{data && data.angle_title}</h2>
                    </div>
                    <FormGroup>
                      <Row>
                        <Col lg="10">
                          <label
                            className="form-control-label display-inline pb-2"
                            htmlFor="lastname"
                          >
                            Write Outline for Your Pitch
                          </label>
                        </Col>
                      </Row>
                      <Form>
                        <CustomEditor
                          content={data.outline && data.outline}
                          onChangeContent={this._handleOnContentChange}
                          // isComment={true}
                        />
                      </Form>
                    </FormGroup>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
          <Col xl="3" className="toolbar-right">
            <div className="mt--5 d-flex justify-content-between">
              <Button
                type="button"
                className="btn-lg"
                color="success"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "14px"
                }}
                onClick={this._handleOnSubmitPitchOutline}
                disabled={
                  (data.topic && data.topic.length === 0) ||
                  (data.type_of_article && data.type_of_article.length === 0) ||
                  (data.topics_of_concern &&
                    data.topics_of_concern.length === 0) ||
                  (data.age_group && data.age_group.length === 0) ||
                  (data.comprehension_level &&
                    data.comprehension_level.length === 0) ||
                  (data.steps_to_write && data.steps_to_write.length === 0) ||
                  (data.tone_of_writing && data.tone_of_writing.length === 0) ||
                  (data.angle_title && data.angle_title === "") ||
                  (data.objectives && data.objectives === "") ||
                  (data.audience && data.audience === "") ||
                  data.outline === "" ||
                  data.outline === "<p><br></p>" ||
                  data.status_pitch === "PITCH_SUBMITTED" ||
                  data.status_pitch === "REJECTED" ||
                  data.status_pitch === "APPROVED"
                    ? true
                    : false
                }
              >
                <i className="ni ni-send mr-2"></i>
                Submit Pitch
              </Button>
            </div>
            <FeedbackPitch pitchID={this.state.id} />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    outline_detail: state.editorArticleReducer.outline_detail_id,
    writer_my_pitch_detail: state.pitchReducer.writer_my_pitch_detail
  };
};
const mapDispatchToProps = dispatch => {
  return {
    submitPitchOutline: data => {
      dispatch(actCreateNewPitchRequest(data));
    },
    // getMyPitchDetail: (id, data) => {
    //   dispatch(actGetMyPitchDetailRequest(id, data));
    // },
    getMyPitchDetailV2: (id, data) => {
      dispatch(actGetMyPitchDetailRequestV2(id, data));
    },
    updatePitchOutline: (id, data) => {
      dispatch(actUpdatePitchRequest(id, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriterAddNewPitchDetail);
WriterAddNewPitchDetail.propTypes = {
  outline_detail: PropTypes.object,
  writer_my_pitch_detail: PropTypes.object,
  getOutlineDetailByID: PropTypes.func,
  submitPitchOutline: PropTypes.func,
  Savedraft: PropTypes.func,
  autoSave: PropTypes.func,
  getMyPitchDetail: PropTypes.func,
  updatePitchOutline: PropTypes.func
};
WriterAddNewPitchDetail.defaultProps = {};
