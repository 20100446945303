import React, { Component } from "react";
import {
  Container,
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import ChiefOutlineProgressTable from "../../../components/Tables/ChiefOutlineProgressTable";
import CardsHeaderchief from "../../../components/Headers/CardsHeaderChief";
class ChiefOutlineInProgress extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardsHeaderchief
          name="Outline - In Progress"
          content="outlineinProgress"
          parentName="Article"
          isDetail={true}
        />
        <Container className="mt--6" fluid>
          <ChiefOutlineProgressTable {...props} />
        </Container>
      </>
    );
  }
}
ChiefOutlineInProgress.propTypes = {
  content: PropTypes.string,
};
export default ChiefOutlineInProgress;
