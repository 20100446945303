import callApi from "../utils/callApi";
import * as Types from "../variables/actionTypes";
import { actFetchResourceSuccess, actNotify } from "./fetch.action";
import renderError from "utils/renderServerError";
import { makeUrlObj } from "../utils/makeUrl";

// const form_data = true;
//LẤY DANH SÁCH WRITER
export const actGetEmployeeRequest = data => {
  return dispatch => {
    return callApi(
      `list-staff?status=WORKING` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}` +
        `&name=${data.search}` +
        `&penname=${data.search}` +
        `&job_position=${data.search}` +
        `&email=${data.search}` +
        `&staff_id=${data.search}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetEmployee(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

//LẤY DANH SÁCH EDITOR AND CHIEFEDITOR
export const actGetEditorAndChiefeditorRequest = data => {
  return dispatch => {
    return callApi(
      `list-editor-coe?status=WORKING` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}` +
        `&name=${data.search}` +
        `&penname=${data.search}` +
        `&job_position=${data.search}` +
        `&email=${data.search}` +
        `&staff_id=${data.search}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetEmployee(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
//Writer with status "STOP_WORKING"
export const actGetWriterPastRequest = data => {
  return dispatch => {
    return callApi(
      `list-staff?status=STOP_WORKING` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}` +
        `&name=${data.search}` +
        `&penname=${data.search}` +
        `&job_position=${data.search}` +
        `&email=${data.search}` +
        `&staff_id=${data.search}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetEmployee(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
//Editor and Chiefeditor with status "STOP_WORKING"
export const actGetEditorAndChiefeditorPastRequest = data => {
  return dispatch => {
    return callApi(
      `list-editor-coe?status=STOP_WORKING` +
        `&page=${data.page}` +
        `&limit=${data.limit}` +
        `&sort=${data.type}|${data.number}` +
        `&name=${data.search}` +
        `&penname=${data.search}` +
        `&job_position=${data.search}` +
        `&email=${data.search}` +
        `&staff_id=${data.search}`,
      "GET"
    )
      .then(res => {
        dispatch(actGetEmployee(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetEmployee = data => {
  return {
    type: Types.GET_EMPLOYEE,
    data
  };
};
//Thông tin chi tiết
export const actGetEmployeeProfileRequest = employeeID => {
  return dispatch => {
    return callApi(`v2/information-user/${employeeID}`, "GET")
      .then(res => {
        dispatch(actGetEmployeeProfile(res));
      })
      .catch(err => {});
  };
};

export const actGetEmployeeProfile = data => {
  return {
    type: Types.GET_EMPLOYEE_PROFILE,
    data
  };
};
//List Key
export const actGetListKeyRequest = data => {
  return dispatch => {
    return callApi("list-key-permission", "GET")
      .then(res => {
        dispatch(actGetListKey(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};

export const actGetListKey = data => {
  return {
    type: Types.GET_LIST_KEY,
    data
  };
};

//LIST TOPIC
export const actGetListTopicRequest = data => {
  return dispatch => {
    return callApi("topics?sort=createdAt|-1&limit=99999999", "GET")
      .then(res => {
        dispatch(actGetListTopic(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetListTopic = data => {
  return {
    type: Types.GET_LIST_TOPIC,
    data
  };
};

//Cập nhật thông tin user
export const actUpdateEmployeeRequest = (data) => {
  return dispatch => {
    return callApi(`v2/admin-change-info-staff`, "POST", data)
      .then(res => {
        if (res) {
          dispatch(
            actFetchResourceSuccess({
              message: "You have updated employee successfully!",
              confirmTo: "/admin/settings/employee/writer"
            })
          );
        }
        
        /* if (data.job_position === "writer" && data.status === "WORKING") {
          dispatch(
            actFetchResourceSuccess({
              message: "You have updated employee successfully!",
              confirmTo: "/admin/settings/employee/writer"
            })
          );
        } else if (
          data.job_position === "writer" &&
          data.status === "STOP_WORKING"
        ) {
          dispatch(
            actFetchResourceSuccess({
              message: "You have updated employee successfully!",
              confirmTo: "/admin/settings/employee/writerPast"
            })
          );
        } else if (
          (data.job_position === "editor" && data.status === "STOP_WORKING") ||
          (data.job_position === "chiefeditor" &&
            data.status === "STOP_WORKING")
        ) {
          dispatch(
            actFetchResourceSuccess({
              message: "You have updated employee successfully!",
              confirmTo: "/admin/settings/employee/EditorAndChiefeditorPast"
            })
          );
        } else if (
          (data.job_position === "editor" && data.status === "WORKING") ||
          (data.job_position === "chiefeditor" && data.status === "WORKING")
        ) {
          dispatch(
            actFetchResourceSuccess({
              message: "You have updated employee successfully!",
              confirmTo: "/admin/settings/employee/EditorAndChiefeditor"
            })
          );
        } */
        dispatch(actUpdateEmployee(data));
      })
      .catch(err => {
        const mess = err.data ? err.data.message : "";
        if (mess) {
          dispatch(
            actNotify({
              message: mess,
              color: "danger"
            })
          );
        }
      });
  };
};

export const actUpdateEmployee = data => {
  return {
    type: Types.UPDATE_EMPLOYEE,
    data
  };
};
//Thêm mới

export const actCreateEmployeeRequest = data => {
  return dispatch => {
    return callApi("v2/create-staff", "POST", data)
      .then(res => {
        if (res) {
          dispatch(
            actFetchResourceSuccess({
              message: "You have created employee successfully!",
              confirmTo: "/admin/settings/employee/writer"
            })
          );
        }
       /*  if (data.job_position === "writer" && data.status === "WORKING") {
          dispatch(
            actFetchResourceSuccess({
              message: "You have created employee successfully!",
              confirmTo: "/admin/settings/employee/writer"
            })
          );
        } else if (
          data.job_position === "writer" &&
          data.status === "STOP_WORKING"
        ) {
          dispatch(
            actFetchResourceSuccess({
              message: "You have created employee successfully!",
              confirmTo: "/admin/settings/employee/writerPast"
            })
          );
        } else if (
          (data.job_position === "editor" && data.status === "STOP_WORKING") ||
          (data.job_position === "chiefeditor" &&
            data.status === "STOP_WORKING")
        ) {
          dispatch(
            actFetchResourceSuccess({
              message: "You have created employee successfully!",
              confirmTo: "/admin/settings/employee/EditorAndChiefeditorPast"
            })
          );
        } else {
          dispatch(
            actFetchResourceSuccess({
              message: "You have created employee successfully!",
              confirmTo: "/admin/settings/employee/EditorAndChiefeditor"
            })
          );
        } */
      })
      .catch(err => {
        const mess = err.data ? err.data.message : "";
        if (mess) {
          dispatch(
            actNotify({
              message: mess,
              color: "danger"
            })
          );
        }
      });
  };
};

//Xóa staff
export const actDeleteStaffRequest = id => {
  return dispatch => {
    return callApi(`admin-delete-staff/${id}`, "POST")
      .then(res => {
        dispatch(
          actFetchResourceSuccess({
            message: res.message,
            confirmTo: "/admin/settings/employee/writer"
          })
        );
      })
      .catch(err => {
        const mess = err.data ? err.data.message : "";
        dispatch(
          actNotify({
            message: mess,
            color: "danger"
          })
        );
      });
  };
};

//LIST EXPERTISE
export const actGetListExpertiseRequest = data => {
  return dispatch => {
    return callApi("expertises", "GET")
      .then(res => {
        dispatch(actGetListExpertise(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
export const actGetListExpertise = data => {
  return {
    type: Types.GET_LIST_EXPERTISE,
    data
  };
};

// V2 staff integration
export const actGetListStaff = data => {
  return {
    type: Types.GET_LIST_STAFF,
    data
  };
};

export const actGetListStaffRequest = (query) => {
  let url = "v2/staff";
  if (query) {
    url = `v2/staff?${makeUrlObj(query)}`
  }
  return dispatch => {
    return callApi(url, "GET")
      .then(res => {
        dispatch(actGetListStaff(res));
      })
      .catch(err => {
        dispatch(
          actNotify({
            message: renderError(err),
            color: "danger"
          })
        );
      });
  };
};
