import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Arrow from "../Arrow"
import ArrowPercent from "../Arrow/ArrowPrecent"
import { ShowNumner } from "../utils"
const TopViewArticle = function ({ data }) {
  useEffect(() => {
  }, []);
  return (
    <Row>
      <Col span="8">
        <div>Visitors</div>
        <h1>{ShowNumner(data.googleAnalytics.vistor)}</h1>
        <div className="d-flex align-items-center">
          <Arrow data={data.googleAnalytics.vistor} dataCompare={data.googleAnalyticsCompare.vistor}/>
        </div>
      </Col>
      <Col span="8">
        <div>Registered</div>
        <h1>{ShowNumner(data.userTotal)}</h1>
        <div className="d-flex align-items-center">
          <Arrow data={data.userTotal} dataCompare={data.userRegisterCompare}/>
        </div>
      </Col>
      <Col span="8">
        <div>Avg. Pages View</div>
        <h1>{ Math.round(data.googleAnalytics.avgPageView *  100) / 100}</h1>
        <div className="d-flex align-items-center">
          <ArrowPercent data={data.googleAnalytics.avgPageView} dataCompare={data.googleAnalyticsCompare.avgPageView}/>
        </div>
      </Col>
    </Row>
  );
};

export default TopViewArticle;
