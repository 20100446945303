import React, { Component } from "react";
import {
  Container
  // Col,
  // Button
} from "reactstrap";
import PropTypes from "prop-types";
import CardHeaderMyProfile from "components/Headers/CardHeaderMyProfile.jsx";
import MyProfileTable from "../../components/Tables/MyProfileTable";
class MyProfile extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <>
        <CardHeaderMyProfile
          name="My Profile"
          content="myProfile"
          parentName="Article"
        />
        <Container className="mt--6" fluid>
          <MyProfileTable {...props} />
        </Container>
      </>
    );
  }
}
MyProfile.propTypes = {
  content: PropTypes.string
};
export default MyProfile;
