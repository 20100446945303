import React, { Component } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Spin } from "antd";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  actGetUnPublishedDetailByIDRequest,
  actSubmitSendBackRequest,
  actSubmitUnPublishedRequest,
  actSubmitPublishRequest,
  // actAutoSaveWritingContentRequest,
  actSaveWritingContentRequest,
  actGeneratePreviewLinkRequest,
} from "../../actions/chiefArticle.action";
import CustomEditor from "../TinyMCE";
import ViewDetailContentChiefEditorModal from "../Modals/ViewDetailContentChiefEditorModal";
import PostSetting from "../PostSetting/PostSetting";
import { HOST_PREVIEW } from "../../variables/hostClient";
import { Tooltip } from "antd";
import SEOManager from "../SEOManager/SEOManager";
import EditPollManager from "../Poll/EditPollForm";
import NewPollManager from "../Poll/NewPollForm";
import { defaultToolbar } from "../../utils/constants";
import "./styles.scss";
class ChiefUnPublishedArticleReviewTable extends Component {
  state = {
    tabs: 1,
    reply: false,
    collapse: false,
    openedCollapses: ["collapseOne"],
    id: window.location.pathname.split("/")[4],
    pathName: window.location.pathname.split("/")[3],
    title: "",
    status_article: "",
    data: {
      content: "",
    },
    idView: false,
    pollId: "",
    poll: {},
  };

  componentDidMount() {
    this._setLoading();
    this._getUnPublisedByID();
    // this._autoSave();
    const {
      content_detail: { data },
    } = this.props;
    if (data && data.angle_title) {
      this.setState({
        title: data.angle_title,
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.content_detail !== this.props.content_detail) {
      this.setState({
        title: nextProps.content_detail.data.angle_title,
        data: { content: nextProps.content_detail.data.content_content },
        status_article: nextProps.content_detail.data.status_article,
        pollId: nextProps.content_detail.data.pollId,
        isPublished:
          nextProps.content_detail.data.status_article === "PUBLISHED",
      });
    }
  }

  // componentWillUnmount() {
  //   clearInterval(this.autoSave);
  //   this.setState({ autoSave: false });
  // }

  componentDidUpdate(prevProps) {
    if (
      this.props.preview.actionCompleted &&
      this.props.preview.actionCompleted !== prevProps.preview.actionCompleted
    ) {
      const { preview, content_detail } = this.props;
      const pin = get(preview, "previewLink.pin");
      const key = get(preview, "previewLink.key");
      const previewLinkUrl = `${HOST_PREVIEW}${content_detail.data._id}?pin=${pin}&key=${key}`;
      window.open(previewLinkUrl, "_blank");
    }
  }

  _getUnPublisedByID() {
    this.props.getUnPublisedByID(this.state.id);
  }

  _handleSubmitUnPublished = () => {
    const id = this.state.id;
    this.props.submitUnPublished(id);
  };

  _handleSubmitPublished = () => {
    const id = this.state.id;
    this.props.submitPublished(id);
  };

  _handleSubmitSendBack = () => {
    const id = this.state.id;
    this.props.submitSubmitSendBack(id);
  };

  _setLoading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  _handleOnClickReply = () => {
    this.setState((prevState) => ({
      reply: !prevState.reply,
    }));
  };

  _handleOnContentChange = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        content: value,
      },
      // autoSave: true
    });
  };

  _handleOnViewDetail = () => {
    this._toggleModalView();
  };

  _handleOnClickCollapse = () => {
    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  _handleOnPageClick = (page) => {
    const { params } = this.state;
    params.page = page;

    // this.props.getBannerList(params);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  _toggleModalView = () => {
    this.setState((prevState) => ({
      idView: !prevState.idView,
    }));
  };
  // _autoSave = () => {
  //   this.autoSave = setInterval(() => {
  //     if (this.state.autoSave) {
  //       //Do not autosave if content or title is null
  //       if (isEmpty(this.state.title) || isEmpty(this.state.data.content)) {
  //         return;
  //       }
  //       const content_detail = this.props.content_detail.data;
  //       const data = {
  //         idContent: content_detail.contentID,
  //         title: this.state.title,
  //         content: this.state.data.content
  //       };
  //       this.setState({ autoSave: false });
  //       this.props.autoSave(data);
  //     }
  //   }, 3000);
  // };

  _handleOnSaveDraftContent = (e) => {
    e.preventDefault();
    const content_detail = this.props.content_detail.data;
    const data = {
      idContent: content_detail.contentID,
      title: this.state.title,
      content: this.state.data.content,
    };
    this.props.saveDraft(data);
  };

  _handleChange = (event) => {
    this.setState({
      title: event.target.value,
      titleWarn: event.target.value.length > 60,
      // autoSave: true,
    });
  };

  // Generate preview code
  handleGeneratePreviewLink = (id) => {
    const { generatePreviewLinkCode } = this.props;
    const data = {
      articleId: id,
    };
    generatePreviewLinkCode(data);
  };
  // Handle poll change
  _handlePollChange = (poll) => {
    this.setState({
      poll: poll,
      autoSave: true,
    });
  };

  render() {
    const { content_detail } = this.props;
    const {
      pathName,
      status_article,
      title,
      titleWarn,
      pollId,
      poll,
    } = this.state;
    const loading = get(content_detail, "loading");
    const type = get(content_detail, "data.type");
    const isOpenPublishing = type === "openPublishing";
    return (
      <>
        <ViewDetailContentChiefEditorModal
          modal={this.state.idView}
          toggle={this._toggleModalView}
          data={this.state.data}
        />
        <Row>
          <Col md="9">
            <Card style={{ marginTop: "19px" }}>
              <CardHeader
                className="border-0 d-flex justify-content-between align-items-start"
                role="tab"
              >
                <div className="d-flex flex-column edit-title-input_wrapper">
                  <Input
                    className={`edit-title-input ` + (titleWarn ? "warn" : "")}
                    value={title}
                    onChange={this._handleChange}
                  />
                  <div
                    className={`d-flex ${
                      titleWarn
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {titleWarn && (
                      <small className="text-red">
                        Title ideally shouldn't be longer than 60 characters,
                        but you can still sumbit
                      </small>
                    )}
                    <small className="text-right">
                      {title ? title.length : "0"}/60
                    </small>
                  </div>
                </div>
                {content_detail.data &&
                (content_detail.data.dualLanguage_article === null ||
                  !content_detail.data.dualLanguage_article) &&
                pathName === "un-published-review" ? (
                  <Link to={"/admin/chief/brief-translate/" + this.state.id}>
                    <Button color="info">Translate</Button>
                  </Link>
                ) : (
                  <Button color="success" disabled>
                    Translated
                  </Button>
                )}
              </CardHeader>
              <div style={{ width: "110px", marginLeft: "auto" }}>
                <a
                  href="#pablo"
                  style={{ fontWeight: "bold" }}
                  onClick={this._handleOnSaveDraftContent}
                >
                  Save draft
                </a>
              </div>

              {this.state.data.content ? (
                <CardBody>
                  <CustomEditor
                    menu={{
                      edit: {
                        title: "Edit",
                        items:
                          "undo redo | cut copy paste | selectall | searchreplace",
                      },
                      insert: {
                        title: "Insert",
                        items: "link | hr",
                      },
                      format: {
                        title: "Format",
                        items:
                          "bold italic underline | formats blockformats align | removeformat",
                      },
                    }}
                    toolbar={
                      isOpenPublishing
                        ? `undo redo | formatselect | bold italic blockquote underline | hr | \
                        alignleft aligncenter alignright | \
                        bullist numlist outdent indent`
                        : defaultToolbar
                    }
                    content={this.state.data.content}
                    onChangeContent={this._handleOnContentChange}
                  />
                </CardBody>
              ) : (
                <Spin className="py-4" />
              )}
            </Card>
            <SEOManager />
            {/* Edit poll */}
            <Card className="poll-manager">
              <CardHeader className="d-flex justify-content-between pb-0">
                <h3>Poll</h3>
              </CardHeader>
              <CardBody>
                <p>
                  {status_article === "PUBLISHED" &&
                    `Unpublish to edit poll. If poll is edited after the article is published, the results will reset.`}
                </p>
                {pollId ? (
                  <EditPollManager
                    pollId={pollId}
                    article_id={this.state.id}
                    disabled={status_article === "PUBLISHED"}
                  />
                ) : (
                  <NewPollManager
                    onChangePoll={this._handlePollChange}
                    poll={poll}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <div className="mt--5 d-flex justify-content-between">
              {this.state.isPublished && (
                <Button
                  type="button"
                  size="lg"
                  className="btn-info btn-lg"
                  style={{ width: "85%" }}
                  onClick={this._handleSubmitUnPublished}
                >
                  Unpublish
                </Button>
              )}
              {status_article === "COMPLETED" && !isOpenPublishing ? (
                <Button
                  type="button"
                  size="lg"
                  className="btn-warning btn-lg"
                  style={{ width: "85%" }}
                  onClick={this._handleSubmitSendBack}
                >
                  Send Back
                </Button>
              ) : null}
              {!this.state.isPublished && (
                <Button
                  type="button"
                  size="lg"
                  className="btn-success btn-lg"
                  style={{ width: "85%" }}
                  onClick={this._handleSubmitPublished}
                >
                  Publish now
                </Button>
              )}
              <Button
                onClick={() =>
                  this.handleGeneratePreviewLink(content_detail.data._id)
                }
                type="button"
                className="text-primary text-center btn-lg"
              >
                {loading ? <Spin /> : <i className="fa fa-eye text-center"></i>}
              </Button>
            </div>
            <PostSetting poll={poll} isOpenPublishing={isOpenPublishing} />
          </Col>
        </Row>
        <div
          className="scrollTop"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "35px",
            zIndex: "1",
          }}
        >
          <Tooltip placement="topLeft" title={"Scroll to top"}>
            <Button
              className="btn-icon-only rounded-circle"
              type="button"
              color="primary"
              // style={{ backgroundColor: "white" }}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-caret-up"></i>
              </span>
            </Button>
          </Tooltip>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    content_detail: state.chiefArticleReducer.unpublished_detail_by_id,
    preview: state.chiefArticleReducer.preview,
    loading: state.chiefArticleReducer.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUnPublisedByID: (data) => {
      dispatch(actGetUnPublishedDetailByIDRequest(data));
    },
    submitSubmitSendBack: (id, data) => {
      dispatch(actSubmitSendBackRequest(id, data));
    },
    submitPublished: (id, data) => {
      dispatch(actSubmitPublishRequest(id, data));
    },
    submitUnPublished: (id, data) => {
      dispatch(actSubmitUnPublishedRequest(id, data));
    },
    saveDraft: (data) => {
      dispatch(actSaveWritingContentRequest(data));
    },
    // autoSave: (data) => {
    //   dispatch(actAutoSaveWritingContentRequest(data));
    // },
    generatePreviewLinkCode: (data) => {
      dispatch(actGeneratePreviewLinkRequest(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefUnPublishedArticleReviewTable);
ChiefUnPublishedArticleReviewTable.propTypes = {
  content_detail: PropTypes.object,
  preview: PropTypes.object,
  getUnPublisedByID: PropTypes.func,
  submitUnPublished: PropTypes.func,
  submitPublished: PropTypes.func,
  submitSubmitSendBack: PropTypes.func,
  saveDraft: PropTypes.func,
  autoSave: PropTypes.func,
  generatePreviewLinkCode: PropTypes.func,
};
ChiefUnPublishedArticleReviewTable.defaultProps = {};
