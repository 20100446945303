import React, { Component } from "react";
import { Card, CardHeader, Table, Row } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { countTimeDeadline } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import Avatar from "../Avatar/Avatar";
import { actGetArticleChiefDashboardRequest } from "../../actions/chief-dashboard.action";
import { Link } from "react-router-dom";
import CardBodyWithLoading from "../CardBodyWithLoading/CardBody";
import { Tooltip } from "antd";
import ReactHtmlParser from "react-html-parser";

const filterOption = [
  {
    label: "Ready to Publish",
    value: "COMPLETED"
  },
  {
    label: "Waiting for Approval",
    value: "CONTENT_SUBMITTED"
  },
  {
    label: "Needs Editing",
    value: "EDITING"
  }
];

class ChiefArticleDashboardTable extends Component {
  state = {
    params: {
      status_article: getUrlParam()["status_article"]
        ? getUrlParam()["status_article"]
        : "COMPLETED",
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 5,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "updatedAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getArticleDashboard();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 3000);
  };

  _getArticleDashboard = () => {
    const params = this.state.params;
    this.props.getArticleDashboard(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this.props.getArticleDashboard(paramsSort);
  };

  _handleOnSelectChange = (label, value) => {
    let params = this.state.params;
    this.setState({
      ...this.state,
      params: {
        ...params,
        [label]: value
      }
    });
    const paramsFilter = {
      ...params,
      [label]: value
    };
    this.props.getArticleDashboard(paramsFilter);
  };

  _renderStatus = status => {
    switch (status) {
      case "WORKING":
        return (
          <>
            <div>
              <span className="text-success">●</span>
              <span className="text-success  font-weight-bold"> WORKING</span>
            </div>
          </>
        );
      case "COMPLETED":
        return (
          <>
            <div>
              <span className="text-success">●</span>
              <span className="text-success  font-weight-bold"> COMPLETED</span>
            </div>
          </>
        );
      case "STOP_WORKING":
        return (
          <>
            <div>
              <span className="text-danger">●</span>
              <span className="text-danger font-weight-bold">
                {" "}
                STOP - WORKING
              </span>
            </div>
          </>
        );
      case "NEW":
        return (
          <>
            <div>
              <span className="text-info">●</span>
              <span className="text-info font-weight-bold"> NEW</span>
            </div>
          </>
        );
      case "OUTLINE_SUBMITTED":
        return (
          <>
            <div>
              <span className="text-success">●</span>
              <span className="text-success font-weight-bold">
                {" "}
                OUTLINE - WAITING FOR APPROVAL
              </span>
            </div>
          </>
        );
      case "CONTENT_SUBMITTED":
        return (
          <>
            <div>
              <span className="text-warning">●</span>
              <span className="text-warning font-weight-bold">
                {" "}
                CONTENT - SUBMITTED
              </span>
            </div>
          </>
        );
      case "EDITING":
        return (
          <>
            <div>
              <span className="text-warning">●</span>
              <span className="text-warning font-weight-bold"> EDITING</span>
            </div>
          </>
        );
      case "CONTENT_WRITING":
        return (
          <>
            <div>
              <span className="text-warning">●</span>
              <span className="text-warning  font-weight-bold">
                {" "}
                ARTICLE - WRITING
              </span>
            </div>
          </>
        );
      case "OUTLINE_WRITING":
        return (
          <>
            <div>
              <span className="text-warning">●</span>
              <span className="text-warning  font-weight-bold">
                {" "}
                OUTLINE - WRITING
              </span>
            </div>
          </>
        );
      default:
    }
    return null;
  };

  _handleOnCellClick = (e, id, type) => {
    e.preventDefault();
    let pathname = "";
    const status_article = this.state.params.status_article;
    switch (status_article) {
      case "EDITING":
        pathname = `/admin/chief/writer-writing/${id}`;
        break;
      case "CONTENT_SUBMITTED":
        pathname = `/admin/chief/content-request/${id}`;
        break;
      default:
        pathname = {
          pathname: `/admin/chief/un-published-review/${id}`,
          state: {
            type
          }
        };
        break;
    }
    this.props.history.push(pathname);
  };

  limitTitle = str => {
    if (!str) {
      return "";
    }
    if (str[0].length > 80) {
      return `${str[0].substring(0, 80)}...`;
    } else {
      return str[0].substring(0, 80);
    }
  };

  render() {
    const { articles_dashboard } = this.props;
    return (
      <>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="header-scroll border-0">
                <Row className="header-top">
                  <div className="table-header">
                    <h3 className="text-header mb-0">Articles</h3>
                  </div>
                  <div className="header-info">
                    <span className="info-approval">
                      Ready to Publish
                      <p>
                        <Link to={"/admin/chief/publish-article"}>
                          View more
                        </Link>
                      </p>
                    </span>
                    <span className="info-number">
                      {articles_dashboard.readyToPublish}
                    </span>
                  </div>
                  <div className="header-info">
                    <span className="info-approval">
                      Waiting for Approval
                      <p>
                        <Link to={"/admin/chief/article-in-progress"}>
                          View more
                        </Link>
                      </p>
                    </span>
                    <span className="info-number">
                      {articles_dashboard.waitingForApproval}
                    </span>
                  </div>
                  <div className="header-info">
                    <span className="info-approval">
                      Needs Editing
                      <p>
                        <Link to={"/admin/chief/editing"}>View more</Link>
                      </p>
                    </span>
                    <span className="info-number">
                      {articles_dashboard.needsEditing}
                    </span>
                  </div>
                </Row>
                <div className="filter-box">
                  <span className="info-approval">Filter: </span>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    options={filterOption}
                    value={filterOption.filter(
                      ({ value }) => value === this.state.params.status_article
                    )}
                    onChange={value =>
                      this._handleOnSelectChange("status_article", value.value)
                    }
                  />
                </div>
              </CardHeader>
              <CardBodyWithLoading
                isLoading={this.state.isLoading}
                className="p-0"
              >
                <div className="table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    style={{ border: "0px" }}
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "40%" }}>Title</th>
                        <th>Writer</th>
                        <th
                          className="sort"
                          data-sort="status"
                          scope="col"
                          onClick={() => this._sortByColumn("end_date")}
                        >
                          Deadline
                        </th>
                        <th>Editor</th>
                        <th className="text-center">Dual Language</th>
                        <th scope="col">Topic</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {articles_dashboard.docs &&
                        articles_dashboard.docs.length !== 0 &&
                        articles_dashboard.docs.map((prop, key) => {
                          return (
                            <tr
                              key={key}
                              className="cursor-pointer"
                              onClick={e =>
                                this._handleOnCellClick(e, prop._id, prop.type)
                              }
                            >
                              <th scope="row">
                                <Tooltip
                                  title={ReactHtmlParser(prop.angle_title)}
                                  placement="bottom"
                                >
                                  <span>
                                    {this.limitTitle(
                                      ReactHtmlParser(prop.angle_title)
                                    )}
                                  </span>
                                </Tooltip>
                              </th>

                              <td className="table-user">
                                <Avatar
                                  src={prop.writer.avatar}
                                  className="d-inline-block mr-3"
                                />
                                <b>{prop.writer ? prop.writer.name : ""}</b>
                              </td>
                              <td className="text-danger font-weight-bold">
                                {countTimeDeadline(prop.end_date)}
                              </td>
                              <td className="table-user">
                                <Avatar
                                  src={prop.editor.avatar}
                                  className="d-inline-block mr-3"
                                />
                                <b>{prop.editor ? prop.editor.name : ""}</b>
                              </td>
                              <td className="text-center text-success">
                                {prop.dualLanguage !== null &&
                                  prop.dualLanguage && (
                                    <i
                                      className="fa fa-check-circle"
                                      style={{ fontSize: 16 }}
                                    ></i>
                                  )}
                              </td>
                              <td>
                                {prop.topic && prop.topic.length > 0
                                  ? prop.topic.map(topic => {
                                      return (
                                        <>
                                          <div className="db_topic">
                                            <label>
                                              {this._text_truncate(
                                                topic.name
                                                  ? topic.name
                                                  : topic.label,
                                                20,
                                                "..."
                                              )}
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })
                                  : null}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {articles_dashboard &&
                    articles_dashboard.docs &&
                    articles_dashboard.docs.length === 0 && (
                      <div
                        className="text-center"
                        style={{ color: "#4B556F", padding: "5%" }}
                      >
                        <i
                          className="icon-search"
                          style={{ fontSize: "70px" }}
                        />
                        <p>No Data</p>
                      </div>
                    )}
                </div>
              </CardBodyWithLoading>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    articles_dashboard: state.chiefDashboardReducer.articles_dashboard
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getArticleDashboard: params => {
      dispatch(actGetArticleChiefDashboardRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiefArticleDashboardTable);
ChiefArticleDashboardTable.propTypes = {
  articles_dashboard: PropTypes.object,
  getArticleDashboard: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};
ChiefArticleDashboardTable.defaultProps = {};
