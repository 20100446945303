import React, { memo } from 'react'; 
import {
    Input,
    Select,
    TreeSelect
  } from "antd";

const { Option } = Select;
export const SwitchComponent = (e, listTopic) => {
  switch (e.type) {
    case "Input":
      return <Input placeholder={e.placeholder} />;
    case "Selection":
      return <Select mode="tags"></Select>;
    case "Custom":
      const Render = e.custom;
      return <Render />;
    case "Gender":
      return (
        <Select>
          <Option key={0} value={0}>
            Female
          </Option>
          <Option key={1} value={1}>
            Male
          </Option>
          <Option key={2} value={2}>
            Other
          </Option>
        </Select>
      );
    case "Topic":
      const optionsTopic = listTopic.map((e) => (
        <Option key={e.key} value={e.name}>
          {e.name}
        </Option>
      ));
      return <Select showSearch>{optionsTopic}</Select>;
    case "Level":
      return (
        <Select>
          <Option key={true} value={true}>
            Senior
          </Option>
          <Option key={false} value={false}>
            Junior
          </Option>
        </Select>
      );
    case "Jobs":
      return (
        <Select>
          <Option key="writer"> Writer </Option>
          <Option key="editor"> Editor </Option>
          <Option key="chiefeditor"> Chiefeditor </Option>
        </Select>
      );
    case 'expertise': 
      return <TreeSelect {...tProps} />
    default:
      return <h1>No project match</h1>;
  }
};

const treeData = [
  {
    title: "VOCATIONAL - Tôi đã được đào tạo về chủ đề này trong trường học (cao đẳng, cử nhân, v.v).",
    value: "VOCATIONAL - Tôi đã được đào tạo về chủ đề này trong trường học (cao đẳng, cử nhân, v.v).",
    key: "0",
  },
  {
    title: "VOCATIONAL - Tôi có ít nhất 6 tháng kinh nghiệm làm việc (toàn thời gian) liên quan đến chủ đề này.",
    value: "VOCATIONAL - Tôi có ít nhất 6 tháng kinh nghiệm làm việc (toàn thời gian) liên quan đến chủ đề này.",
    key: "1",
  },
  {
    title:
      "PERSONAL EXPERIENCE - Tôi có những trải nghiệm sâu sắc liên quan đến chủ đề này. Trải nghiệm này giúp tôi tự suy ngẫm để có cái nhìn đa chiều và thực tế về chủ đề này.",
    value:
      "PERSONAL EXPERIENCE - Tôi có những trải nghiệm sâu sắc liên quan đến chủ đề này. Trải nghiệm này giúp tôi tự suy ngẫm để có cái nhìn đa chiều và thực tế về chủ đề này.",
    key: "2",
  },
  {
    title: "PERSONAL EXPERIENCE - Tôi đã thực hành và/hoặc nghiên cứu (sách báo, internet, v.v) về chủ đề này ít nhất 4 tiếng/tuần.",
    value: "PERSONAL EXPERIENCE - Tôi đã thực hành và/hoặc nghiên cứu (sách báo, internet, v.v) về chủ đề này ít nhất 4 tiếng/tuần.",
    key: "3",
  },
  {
    title: "SOCIAL - Tôi biết ít nhất 3 chuyên gia/người am tường về chủ đề này. Họ có thể truyền đạt lại cho tôi kiến thức, kinh nghiệm cũng như quan điểm về chủ đề này",
    value: "SOCIAL - Tôi biết ít nhất 3 chuyên gia/người am tường về chủ đề này. Họ có thể truyền đạt lại cho tôi kiến thức, kinh nghiệm cũng như quan điểm về chủ đề này",
    key: "4",
  },
];

const tProps = {
  treeData,
  treeCheckable: true,
  searchPlaceholder: "Chuyên môn của bạn về chủ đề này",
  className: "input-form",
  dropdownClassName: "line-break-dropdown",
};