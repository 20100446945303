import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "../Avatar/Avatar";

class ReplyComment extends Component {
  render() {
    const reply = this.props.reply;
    return (
      <div className="reply mb-2">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <div className="d-flex w-100 align-items-center">
              {/* <Avatar
                width="32"
                height="32"
                src={reply.avatar}
                className="mr-3"
              /> */}
              <div>
                <h4 className="m-0 font-weight-bold">{reply.creator.name}</h4>
                <div className="date">
                  {moment(reply.createdAt).format("HH:mm DD/MM/YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-3 mb-1">{reply.content}</p>
      </div>
    );
  }
}

export default ReplyComment;
ReplyComment.propTypes = {
  reply: PropTypes.object,
};
ReplyComment.defaultProps = {};
