export const ARTICLE_STATUS = {
  PUBLISHED: "PUBLISHED",
  COMPLETED: "COMPLETED",
  UNPUBLISHED: "UNPUBLISHED",
  DELETED: "DELETED",
};

export const COMMENT_STATUS = {
  DELETED: "DELETED",
  ACTIVE: "ACTIVE",
  RESOLVED: "RESOLVED",
};

export const APPLICATION_STATUS = {
  NEW: "NEW",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  REEVALUATED: "REEVALUATED",
};

export const ARRAY_APPLICATION_STATUS_PROCESSED = [
  APPLICATION_STATUS.APPROVED,
  APPLICATION_STATUS.REJECTED,
  APPLICATION_STATUS.REEVALUATED,
];

export const ErrorKey = {
  INVALID_PARAMS: "INVALID_PARAMS",
  EXISTED_SLUG: "EXISTED_SLUG",
  CAN_NOT_DELETE_REPLIED_COMMENT: "CAN_NOT_DELETE_REPLIED_COMMENT",
  INVALID_THUMBNAIL_IMAGE: "INVALID_THUMBNAIL_IMAGE",
  SECTION_REQUIRED_1_ARTICLE: "SECTION_REQUIRED_1_ARTICLE",
  COVER_STORY_REQUIRED_3_ARTICLES: "COVER_STORY_REQUIRED_3_ARTICLES",
  ARTICLE_EXIST_SCHEDULE: "ARTICLE_EXIST_SCHEDULE",
};

export const role = {
  EDITOR: "editor",
  CHIEFEDITOR: "chiefeditor",
  WRITER: "writer",
};

export const featureImageModes = [
  { label: "Landscape", value: "landscape" },
  { label: "Portrait", value: "portrait" },
];

export const ARTICLE_TYPES = [
  { label: "Original", value: "original" },
  { label: "Branded", value: "branded" },
  { label: "Outsourced", value: "outsourced" },
];

export const DEFAULT_SCHEDULE_SETTING = [
  {
    key: "cover-story",
    articles: [],
  },
  {
    key: "featured-news-series-1",
    articles: [],
  },
  {
    key: "featured-news-series-2",
    articles: [],
  },
  {
    key: "listicle-1",
    articles: [],
  },
  {
    key: "listicle-2",
    articles: [],
  },
  {
    key: "listicle-3",
    articles: [],
  },
  {
    key: "listicle-4",
    articles: [],
  },
  {
    key: "listicle-5",
    articles: [],
  },
  {
    key: "listicle-6",
    articles: [],
  },
];

export const MAX_SIZE_IMAGE = 450000;

export const defaultToolbar = `undo redo | formatselect | bold italic underline blockquote | hr | \
alignleft aligncenter alignright | \
bullist numlist outdent indent | media image`;
