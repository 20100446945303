import React, { Component } from "react";
import { Card, CardHeader, Table, Col, Row, CardFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatStringToDate } from "../../utils/formatDate";
import getUrlParam from "../../utils/getUrlParam";
import { paramFromData } from "../../utils/paramFromData";
import CustomPagination from "../Pagination/Pagination";
import { actGetRejectAssignedListRequest } from "../../actions/editorArticle.action";
import Avatar from "../Avatar/Avatar";
import ReasonModal from "../Modals/ReasonModal";

class RejectAssignedTable extends Component {
  state = {
    tabs: 1,
    page: 1,
    limit: 10,
    number: 1,
    params: {
      page: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      limit: getUrlParam()["limit"] ? getUrlParam()["limit"] : 10,
      type: getUrlParam()["type"] ? getUrlParam()["type"] : "createdAt",
      number: getUrlParam()["number"] ? getUrlParam()["number"] : "-1"
    },
    isLoading: false
  };

  componentDidMount() {
    this._setLoading();
    this._getRejectAssigned();
  }

  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _getRejectAssigned = () => {
    const params = this.state.params;
    this.props.getRejectAssigned(params);
  };

  _handleOnPageClick = page => {
    const { params } = this.state;
    params.page = page;
    this._setLoading();
    this._handlePathnamePush(params);
    this.props.getRejectAssigned(params);
  };

  _handlePathnamePush = params => {
    this.props.history.push(paramFromData(params));
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  _text_truncate = (str, length, end) => {
    if (!str) {
      return "";
    }
    if (length == null) {
      length = 100;
    }
    if (end == null) {
      end = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - end.length) + end;
    } else {
      return str;
    }
  };

  _sortByColumn = typeColumn => {
    const { params } = this.state;
    let numberSort = 1;
    if (params.number === -1) {
      numberSort = 1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: 1
        }
      });
    } else {
      numberSort = -1;
      this.setState({
        params: {
          ...params,
          type: typeColumn,
          number: -1
        }
      });
    }
    const paramsSort = {
      ...params,
      type: typeColumn,
      number: numberSort
    };
    this._handlePathnamePush(paramsSort);
    this.props.getRejectAssigned(paramsSort);
  };

  _toggleModalReason = reason => {
    this.setState(prevState => ({
      isView: !prevState.isView,
      rejectReason: reason
    }));
  };

  _handleOnCellClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin/editor/writer-writing/${id}`);
  };

  render() {
    const { reject_assigned } = this.props;
    const page = reject_assigned.data && reject_assigned.data.page;
    const totalPages = reject_assigned.data && reject_assigned.data.totalPages;
    const totalDocs = reject_assigned.data && reject_assigned.data.totalDocs;

    return (
      <>
        <ReasonModal
          modal={this.state.isView}
          toggle={this._toggleModalReason}
          reason={this.state.rejectReason}
        />
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Reject Assigned List</h3>
                  </Col>
                  <Col className="text-right" xs="6"></Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                {reject_assigned.data &&
                  reject_assigned.data.docs.length !== 0 && (
                    <Table
                      className="align-items-center table-flush"
                      style={{ border: "0px" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            onClick={() => this._sortByColumn("angle_title")}
                          >
                            Title
                          </th>
                          <th
                            className="sort"
                            data-sort="name"
                            scope="col"
                            onClick={() => this._sortByColumn("writer")}
                          >
                            Writer
                          </th>
                          <th
                            className="sort"
                            data-sort="status"
                            scope="col"
                            onClick={() => this._sortByColumn("updatedAt")}
                          >
                            Writer rejects on
                          </th>
                          <th scope="col">Reason</th>
                          <th scope="col">Topic</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {reject_assigned.data
                          ? reject_assigned.data.docs.map((prop, key) => {
                              return (
                                <>
                                  <tr key={key}>
                                    <th scope="row">
                                      {prop.brief &&
                                        prop.brief.angle_title &&
                                        this._text_truncate(
                                          prop.brief.angle_title,
                                          50,
                                          "..."
                                        )}
                                    </th>
                                    <td className="table-user">
                                      <Avatar
                                        src={prop.writer.avatar}
                                        className="d-inline-block mr-3"
                                      />
                                      <b>
                                        {prop.writer ? prop.writer.name : ""}
                                      </b>
                                    </td>
                                    <td className="budget">
                                      {formatStringToDate(prop.updatedAt)}
                                    </td>
                                    <td
                                      className="cursor-pointer"
                                      onClick={() =>
                                        this._toggleModalReason(prop.note)
                                      }
                                    >
                                      {prop.note
                                        ? this._text_truncate(
                                            prop.note && prop.note,
                                            30,
                                            "..."
                                          )
                                        : ""}
                                    </td>
                                    <td>
                                      {prop.brief
                                        ? prop.brief.topic.map(topic => {
                                            return (
                                              <>
                                                <div className="mt-2  _topic">
                                                  {this._text_truncate(
                                                    topic.name
                                                      ? topic.name
                                                      : topic.label,
                                                    20,
                                                    "..."
                                                  )}
                                                </div>
                                              </>
                                            );
                                          })
                                        : ""}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : ""}
                      </tbody>
                    </Table>
                  )}

                {reject_assigned.data.docs.length === 0 && (
                  <div
                    className="p-7 text-center font-italic font-weight-bold"
                    style={{ color: "#ccc", fontSize: "18px" }}
                  >
                    There are no content available
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <div className="float-right">
                  <CustomPagination
                    page={page}
                    totalPages={totalPages}
                    getListByPage={this._handleOnPageClick}
                  />
                  <div className="font-italic mt-2">
                    ({totalDocs} results in {totalPages} pages)
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    reject_assigned: state.editorArticleReducer.reject_assigned
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getRejectAssigned: params => {
      dispatch(actGetRejectAssignedListRequest(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RejectAssignedTable);
RejectAssignedTable.propTypes = {
  reject_assigned: PropTypes.object,
  getRejectAssigned: PropTypes.func
};
RejectAssignedTable.defaultProps = {};
