import React from "react";
import BarchartArticle from "./BarchartArticle";
import TableTopArticle from "./TableTopArticle";
import { Row, Col } from "antd";

const ArticleSection = function() {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <BarchartArticle />
      </Col>
      <Col span={12}>
        <TableTopArticle />
      </Col>
    </Row>
  );
};

export default ArticleSection;