import React, { Component } from "react";
import {
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import Button from "components/CustomButton/CustomButton";
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { actChangePasswordRequest } from "../../actions/authentication.action";
import { actGetMyProfileListRequest } from "../../actions/myprofile.action";
import AvatarUpdatePassword from "../../components/Avatar/AvatarUpdatePassword";
class UpdatePassword extends Component {
  state = {
    newPassword: "",
    confirmPassword: "",
    isLoading: false
  };
  constructor() {
    super();
    this.state = { number: "" };
  }
  componentDidMount() {
    this.props.getMyProfile();
  }
  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    const val = e.target.value;
    if (val.length <= 6 && /^(\s*|\d+)$/.test(val) && /^[a-zA-Z]+$/.test(val)) {
      this.setState({
        number: val
      });
    }
  };
  handleOnSubmit = () => {
    const newPassword = this.state.password;
    const confirmPassword = this.state.confirmPassword;
    this.toogleLoading();
    this.setTimeoutLoading();

    let upercase = /[A-Z]/;
    let lowercase = /[a-z]/;
    let number = /[0-9]/;
    let special = /[!@#$%^&*(),.?":{}|<>]/g;

    let checkUpercase = upercase.test(newPassword);
    let checkLowercase = lowercase.test(newPassword);
    let checknumber = number.test(newPassword);
    let checkSpecial = special.test(newPassword);
   
    if (newPassword && confirmPassword) {
      if (
        checkUpercase === true &&
        checkLowercase === true &&
        checknumber === true &&
        checkSpecial === true
      ) {
        if (newPassword.length >= 6 && confirmPassword.length >= 6) {
          if (newPassword === confirmPassword) {
            const data = {
              newPassword,
              confirmPassword
            };
            this.props.updatePassword(data);
            this.toogleLoading();
          } else {
            this.setState({
              message: "* Mật khẩu không khớp!"
            });
          }
        } else {
          this.setState({
            message: "* Mật khẩu phải trên 6 ký tự "
          });
          //   const data = {
          //     newPassword,
          //     confirmPassword
          //   };
          //   this.props.updatePassword(data);
        }
      } else {
        this.setState({
          message: "* Mật khẩu phải bao gồm chữ hoa, thường, ký tự, số!"
        });
      }
    } else {
      this.setState({
        message: "* Cannot be blank!"
      });
    }
  };
  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleOnSubmit();
      // this.checkPassword();
    }
  };
  toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  setTimeoutLoading = () => {
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 1500);
  };
  render() {
    const key = localStorage.getItem("key");
    return (
      <>
        <AuthHeader title="" />
        <Container className="updatePassword">
          <Row className="justify-content-end">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      {this.props.myprofile_list.data.avatar ? (
                        <span className="rounded-circle border-secondary">
                          <AvatarUpdatePassword
                            src={this.props.myprofile_list.data.avatar}
                          />
                        </span>
                      ) : (
                        <img
                          alt="..."
                          className="rounded-circle border-secondary"
                          src={require("assets/img/user140.svg")}
                          style={{ height: "140px", width: "140px" }}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <CardBody className="pt-7 px-5">
                  <div className="text-center mb-4">
                    <h3>{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="password"
                          placeholder="New password"
                          type="password"
                          onChange={this.handleOnChange}
                          onKeyDown={this.onKeyDown}
                          // value={this.state.number}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-danger mt-3 mb-3">
                      {this.state.message}
                    </div>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="confirmPassword"
                          placeholder="Confirm new password"
                          type="password"
                          onChange={this.handleOnChange}
                          onKeyDown={this.onKeyDown}
                          // value={this.state.number}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-danger mt-3 mb-3">
                      {this.state.message}
                    </div>
                    <div className="text-center">
                      {key === "writer" ? (
                        <NavLink to="/admin/writer/dashboard-writer">
                          <span
                            style={{
                              color: "#5e72e4",
                              marginRight: "40px",
                              cursor: "pointer",
                              fontSize: "15px"
                            }}
                          >
                            Skip
                          </span>
                        </NavLink>
                      ) : null}
                      {key === "editor" ? (
                        <NavLink to="/admin/editor/dashboard-editor">
                          <span
                            style={{
                              color: "#5e72e4",
                              marginRight: "40px",
                              cursor: "pointer",
                              fontSize: "15px"
                            }}
                          >
                            Skip
                          </span>
                        </NavLink>
                      ) : null}
                      {key === "chief" ? (
                        <NavLink to="/admin/chief/dashboard-chief">
                          <span
                            style={{
                              color: "#5e72e4",
                              marginRight: "40px",
                              cursor: "pointer",
                              fontSize: "15px"
                            }}
                          >
                            Skip
                          </span>
                        </NavLink>
                      ) : null}

                      <Button
                        className="my-4"
                        onClick={this.handleOnSubmit}
                        // onClick={this.handleOnCheck}
                        type="button"
                        isLoading={this.state.isLoading}
                        style={{
                          backgroundColor: "#5e72e4",
                          borderColor: "#5e72e4",
                          color: "#ffffff"
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    // lang: state.i18nState.lang
    myprofile_list: state.myProfileReducer.myprofile_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updatePassword: data => {
      dispatch(actChangePasswordRequest(data));
    },
    getMyProfile: () => {
      dispatch(actGetMyProfileListRequest());
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePassword);
UpdatePassword.propTypes = {
  updatePassword: PropTypes.func,
  getMyProfile: PropTypes.func,
  myprofile_list: PropTypes.object
};
