import * as Types from "../variables/actionTypes.jsx";

const initialState = {
  timelineList: [],
  timelineDetail: {},
  isLoading: false,
};

const timelineReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get list
    case Types.GET_LIST_TIMELINE_START:
      state = {
        ...state,
        isLoading: true,
      };
      return { ...state };
    case Types.GET_LIST_TIMELINE_SUCCESS:
      state = {
        ...state,
        timelineList: action.data,
        isLoading: false,
      };
      return { ...state };
    case Types.GET_LIST_TIMELINE_FAIL:
      state = {
        ...state,
        isLoading: false,
      };
      return { ...state };
    // Get detail
    case Types.GET_TIMELINE_DETAIL_START:
      state = {
        ...state,
        isLoading: true,
      };
      return { ...state };
    case Types.GET_TIMELINE_DETAIL_SUCCESS:
      state = {
        ...state,
        timelineDetail: action.data,
        isLoading: false,
      };
      return { ...state };
    case Types.CLEAR_TIMELINE_ITEM:
      state = {
        ...state,
        timelineDetail: {},
      };
      return { ...state };
    case Types.GET_TIMELINE_DETAIL_FAIL:
      state = {
        ...state,
        isLoading: false,
      };
      return { ...state };
    default:
      return { ...state };
  }
};

export default timelineReducer;
