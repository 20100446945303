import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Modal, Button, Input } from "antd";
import { actRejectContentRequest } from "../../actions/editorArticle.action";

function ContentRejectModal({
  visible,
  loading,
  handleOk,
  id,
  toggle,
  handleCancel,
}) {
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const { TextArea } = Input;

  // functions
  function handleChange(e) {
    setReason(e.target.value);
  }

  function handleRejectRequest() {
    const data = {
      status: "REJECTED",
      reasonOfReject: reason,
    };
    dispatch(actRejectContentRequest(id, data));
    toggle();
  }

  return (
    <Modal
      className="pitch-reject-modal"
      visible={visible}
      title="Reject this article?"
      onOk={handleOk}
      onCancel={toggle}
      footer={[
        <Button
          className="modal-cancel-btn"
          type="ghost"
          key="cancel"
          onClick={toggle}
        >
          No
        </Button>,
        <Button
          disabled={isEmpty(reason)}
          className="modal-submit-btn"
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleRejectRequest}
        >
          Reject
        </Button>,
      ]}
    >
      <div>
        <span className="d-block mb-2">
          <strong>Reject reason</strong>
        </span>
        <TextArea
          label="Reject Reason"
          value={reason}
          onChange={handleChange}
          rows={6}
        />
      </div>
    </Modal>
  );
}
ContentRejectModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  hideBody: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOnChange: PropTypes.func,
  toggle: PropTypes.func,
  id: PropTypes.string,
};
export default ContentRejectModal;
