import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  Container
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actGetFaqRequest } from "../../actions/writerArticle.action";
import {
  actGetChiefFaqRequest,
  actUpdateChiefFaqRequest
} from "../../actions/chiefArticle.action";
import CustomEditor from "../TinyMCE";
import ViewDetailContentWritingModal from "../Modals/ViewDetailContentWritingWriterModal";
class FaqTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        content: ""
      }
    };
  }

  componentDidMount() {
    this._setLoading();
    this.props.getWriterFaq();
    this.props.getChiefFaq();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.writer_faq !== this.props.writer_faq ||
      nextProps.chief_faq !== this.props.chief_faq
    ) {
      this.setState({
        data: {
          content: nextProps.writer_faq.data || nextProps.chief_faq.data
        }
      });
    }
  }
  _toggleModalView = () => {
    this.setState(prevState => ({
      idView: !prevState.idView
    }));
  };

  _handleOnContentChange = value => {
    this.setState({
      data: {
        ...this.state.data,
        content: value
      }
    });
  };
  _setLoading = () => {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  };

  _toogleLoading = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  _handleOnViewDetail = id => {
    this._toggleModalView();
  };

  _handleOnPublish = e => {
    e.preventDefault();
    const data = this.state.data.content;
    this.props.updateChiefFaq(data);
  };
  createMarkup(text) {
    return { __html: text };
  }
  rawHTML(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }
  render() {
    const key = localStorage.getItem("key");
    const { writer_faq } = this.props;
    const faq = Object.assign({}, writer_faq.data);
    const { chief_faq } = this.props;
    const chief = Object.assign({}, chief_faq.data);
    const update_faq = localStorage.getItem("faq");
    return (
      <>
        <ViewDetailContentWritingModal
          modal={this.state.idView}
          toggle={this._toggleModalView}
          data={this.props.writer_faq}
        />
        {key === "chiefeditor" ? (
          <Row className="justify-content-center">
            <Col xl="9">
              <Card style={{ marginTop: "17px" }}>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <h3 className="mb-0">Text Editor</h3>
                  <Button
                    type="button"
                    size="md"
                    className="btn-success btn-lg"
                    onClick={this._handleOnPublish}
                  >
                    Save
                  </Button>
                </CardHeader>
                <CardBody>
                  <Form>
                    <CustomEditor
                      content={
                        chief.faq
                          ? chief.faq
                          : update_faq
                          ? update_faq
                          : chief.faq
                      }
                      onChangeContent={this._handleOnContentChange}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Container className="mt--6" fluid>
            <Row className="justify-content-center">
              <Col lg="9" style={{ marginTop: "20px" }}>
                <Card>
                  <CardBody className="ql-editor navbar-admin__scroll-container">
                    {this.rawHTML(faq && faq.faq)}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    writer_faq: state.writerArticleReducer.writer_faq,
    chief_faq: state.chiefArticleReducer.chief_faq
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getWriterFaq: () => {
      dispatch(actGetFaqRequest());
    },
    getChiefFaq: () => {
      dispatch(actGetChiefFaqRequest());
    },
    updateChiefFaq: data => {
      dispatch(actUpdateChiefFaqRequest(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqTable);
FaqTable.propTypes = {
  writer_faq: PropTypes.object,
  getWriterFaq: PropTypes.func,
  chief_faq: PropTypes.object,
  getChiefFaq: PropTypes.func,
  updateChiefFaq: PropTypes.func
};
FaqTable.defaultProps = {};
