import React, { useState } from "react";
import { Menu, Dropdown } from "antd";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
function MultiSelect(props) {
  const CustomCheckbox = withStyles({
    root: {
      color: "#D9D9D9",
      "&$checked": {
        color: "#5E72E4"
      }
    },
    checked: {}
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  const [visible, useVisible] = useState(false);
  const [selectAll, useSelectAll] = useState(false);
  const [selects, useSelects] = useState([]);

  const onSelect = value => {
    if (selects.includes(value)) {
      useSelects(selects.filter(prop => prop !== value));
      props.onSelect(
        selects.filter(prop => prop !== value),
        value
      );
      if (selects.length === 1) {
        useSelectAll(false);
      }
    } else {
      useSelects([...selects, value]);
      props.onSelect([...selects, value], value);
    }
  };

  const onSelectAll = () => {
    if (!selectAll) {
      useSelectAll(true);
      useSelects(props.list);
      props.onSelectAll(props.list);
    } else {
      useSelectAll(false);
      useSelects([]);
      props.onSelectAll([]);
    }
  };

  const menu = () => {
    return (
      <Menu className="multiple-menu-content">
        <Menu.Item key="all">
          <FormControlLabel
            style={{ marginLeft: "0", marginRight: "5px", marginBottom: "0" }}
            control={
              <CustomCheckbox
                onChange={onSelectAll}
                value="all"
                checked={selectAll || selects.length === props.list.length}
                indeterminate={
                  !!selects.length && selects.length < props.list.length
                }
              />
            }
            label="All"
            className="nonanimating-btn"
          />
        </Menu.Item>
        <Menu.Divider />
        <div className="multiple-menu-items">
          {props.list.map((prop, index) => {
            return (
              <li
                key={index}
                className="ant-dropdown-menu-item multiple-menu-item nonanimating-btn"
                onClick={() => onSelect(prop)}
              >
                <FormControlLabel
                  style={{
                    marginLeft: "0",
                    marginRight: "5px",
                    marginBottom: "0"
                  }}
                  control={
                    <CustomCheckbox
                      onChange={() => onSelect(prop)}
                      checked={selects.includes(prop)}
                      value={prop.key}
                    />
                  }
                  label={prop.name}
                  className="nonanimating-btn"
                />
              </li>
            );
          })}
        </div>
      </Menu>
    );
  };

  return (
    <>
      {visible && <div onClick={() => useVisible(false)} className="offset" />}
      <Dropdown
        placement={props.placement}
        disabled={props.disabled}
        onVisibleChange={() => useVisible(!visible)}
        visible={visible}
        overlay={menu}
        trigger={["click"]}
      >
        <span
          className="ant-dropdown-link"
          onClick={() => useVisible(!visible)}
        >
          <div className="multiple-select-content">
            <div
              className={
                selects.length > 0
                  ? "multiple-select-title bg-gray"
                  : "multiple-select-title"
              }
            >
              {selects.length > 0
                ? `${selects.length} ${"Selected"}`
                : props.title}
              <div className="icon-arrow-double"></div>
            </div>
          </div>
        </span>
      </Dropdown>
    </>
  );
}

MultiSelect.propTypes = {
  onSelect: PropTypes.any,
  onSelectAll: PropTypes.any,
  list: PropTypes.array,
  title: PropTypes.string,
  disabled: PropTypes.bool
};

export default MultiSelect;
